import { clone } from 'lodash-es';
// Node Service
import { executeWithFallbackPromise } from '@cohesity/utils';

;(function(angular, undefined) {

  angular.module('C')
    .service('NodeService', nodeServiceFn);

  function nodeServiceFn($http, $q, API, NODE_POSITION, featureFlagsService) {
    var nodeIps = [];
    return {
      isUsedIp: isUsedIp,
      discoverNodes: discoverNodes,
      getClusterNodes: getClusterNodes,
      addNodes: addNodes,
      markNodeForRemoval: markNodeForRemoval,
      getIps: getIps,
      addMissingNodes: addMissingNodes,
    };

    ////////////////////////////////////////
    // PUBLIC METHODS
    ////////////////////////////////////////
    function discoverNodes(scope) {
      const v1Endpoint = API.private('nexus/avahi/discover_nodes');
      const v2Endpoint = API.privateV2('clusters/nodes/free');
      const isPlatformBucket1v2ApiMigration = featureFlagsService.enabled('platformBucket1v2ApiMigration');

      // Check if the newly added nodeModel field exists on the all nodes in the response.
      const evaluateFn = (response) => {
        const freeNodes = response?.data?.freeNodes || [];
        return !freeNodes.some((node) => node.nodeModel) && freeNodes.length > 0;
      };

      const fetchFreeNodesFn = (url, converterFn) => {
        const params = {
          method: 'get',
          url,
        };
        return () => $http(params).then(res => (converterFn ? converterFn(res) : res));
      };

      const converterFn = (response) => ({
        ...response,
        data: {
          ...response.data,
          nodes: [],
          freeNodes: response.data.nodes?.map((node) => ({
            ...node,
            ipAddresses: node?.ips || null,
            maxNodeSlot: node?.numSlotsInChassis || null,
            nodeId: node?.id || null,
            swVersion: node?.softwareVersion || null,
            primaryIP4Address: node?.primaryIPv4Address || null,
            primaryIP6Address: node?.primaryIPv6Address || null,
          })) || [],
        },
      });

      const fetchFreeNodes = isPlatformBucket1v2ApiMigration
        ? executeWithFallbackPromise(fetchFreeNodesFn(v2Endpoint, converterFn), fetchFreeNodesFn(v1Endpoint), evaluateFn)
        : fetchFreeNodesFn(v1Endpoint)();

      return fetchFreeNodes;
    }

    function getClusterNodes(opts) {
      const v1Endpoint = API.private('nodes');
      const v2Endpoint = API.privateV2('clusters/nodes');
      const isPlatformBucket2v2ApiMigration = featureFlagsService.enabled('platformBucket2v2ApiMigration');

      const fetchNodesFn = url => {
        const params = {
          method: 'get',
          url,
          params: opts ? opts : null,
        };
        return () => $http(params);
      };

      const fetchNodes = isPlatformBucket2v2ApiMigration
        ? executeWithFallbackPromise(fetchNodesFn(v2Endpoint), fetchNodesFn(v1Endpoint))
        : fetchNodesFn(v1Endpoint)();

      return fetchNodes;
    }

    function markNodeForRemoval(id) {
      return $http({
        method: 'delete',
        url: API.private('nodes', id),
      });
    }

    /**
     * makes an API call to add nodes to the current cluster
     * and returns a promise
     * @param {Object} data object based on nexus api documentation (clusterAddNodeReq)
     * @returns {promise}
     */
    function addNodes(data) {
      return $http({
        method: 'post',
        url: API.private('nexus/cluster/add_nodes'),
        data: data,
      });
    }

    /**
     * Simple checker if a given IP address is already in used based on our list of IPs
     * @method isUsedIp
     * @param  {String}  ip IP Address
     * @return {Boolean}    Whether the IP is in use or not. True if found.
     */
    function isUsedIp(ip) {
      return nodeIps.indexOf(ip) >= 0;
    }

    /**
     * Returns a new object containing Node IP properties
     * if IPMI IP address, or if IPV4 is present
     *
     * @param  {Object}  node as returned from discover_nodes
     * @return {Object}  Contains IPV4 and/or IPMI Address
     */
    function getIps(node) {
      var i;
      var len;
      var obj = {};
      var sub;

      // Check for ipmiIp property
      if (node.hasOwnProperty('ipmiIp')) {
        obj._ipmiIp = node.ipmiIp;
      }

      // Populate primary ip addresses.
      obj._ipv4 = node.primaryIP4Address || '';
      obj._ipv6 = node.primaryIP6Address || '';

      return obj;
    }

    /**
     * Add fake nodes if there are missing nodes in this chassis.
     * Nexus has 4 nodes in one chassis for now. Add more cases if needed.
     *
     * @method  addMissingNodes
     * @param   {object}    node             The node in discoveredNodes.
     * @param   {object}    discoveredNodes  The all discovered nodes.
     * @return  {integer}   How many missing nodes are added totally.
     */
    function addMissingNodes(node, discoveredNodes) {
      var missingNodeDef = {
        productModel: node.productModel,
        chassisSerial: node.chassisSerial,
        nodeUiSlot: '',
        missingNode: true,
      };
      var nodeSeq = [];
      var missingNodeCounter = 0;
      var missingNodeModel;

      switch (node.maxNodeSlot) {
        case 4:
          nodeSeq = NODE_POSITION.fourNode;
      }

      // Go through node4seq to find the missing position in the chassis.
      nodeSeq.forEach(function eachPos(pos) {
        if (!_slotExists(node.chassisSerial, pos, discoveredNodes)) {
          missingNodeModel = clone(missingNodeDef);
          missingNodeCounter += 1;
          missingNodeModel.nodeUiSlot = pos;
          discoveredNodes.push(missingNodeModel);
        }
      });
      return missingNodeCounter;
    }

    ////////////////////////////////////////
    // PRIVATE METHODS
    ////////////////////////////////////////
    /**
     * General processing of nodes when returned from the server
     * @method processNodes
     * @param  {Object} response from server
     * @return {Object}          the original server response
     */
    function processNodes(response) {
      var data = [].concat(response.data);
      data.forEach(function(node) {
        // Collect unique node IP addresses into a searchable array on the service
        if (node.ip && nodeIps.indexOf(node.ip) < 0) {
          nodeIps.push(node.ip);
        }
      });
      return response;
    }

    /**
     * Check if the slot exists in the chassis.
     *
     * @method  slotExists
     * @param   {string}  chassisSerial   A specific chassis serial.
     * @param   {string}  slotPos         The slot position we are looking for.
     * @param   {object}  discoveredNodes The all discovered nodes.
     */
    function _slotExists(chassisSerial, slotPos, discoveredNodes) {
      return discoveredNodes.some(function eachNode(node) {
        return node.chassisSerial === chassisSerial &&
          node.nodeUiSlot === slotPos;
      });
    }
  }

})(angular);
