import { Component, Input } from '@angular/core';

import { ConnectionStatus } from './connection-status.model';

@Component({
  selector: 'coh-connection-status',
  templateUrl: './connection-status.component.html',
  styleUrls: ['./connection-status.component.scss']
})
export class ConnectionStatusComponent {

  /**
   * Connection status.
   */
  @Input() connectionStatus: ConnectionStatus;

  /**
   * Indicates if the connection status is still updaing.
   */
  @Input() updating: boolean;

  /**
   * Maps Connection Status Icons.
   */
  statusIcons: { [status in ConnectionStatus]: string } = {
    success: 'helix:status-success!success',
    error: 'helix:status-error',
  };

  /**
   * An optional message string to display.
   */
  @Input() message?: string;
}
