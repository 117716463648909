import { Pipe, PipeTransform } from '@angular/core';
import { DcScanMethod } from '@cohesity/api/argus';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'dcScanMethod',
})
export class DcScanMethodPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  /**
   * Transform Dc Scan Method to corresponding text.
   *
   * @param value DcScanMethod
   * @returns Dc Scan Method to corresponding text.
   */
  transform(scanMethod: DcScanMethod): string | null {
    return scanMethod ? this.translateService.instant(`dg.dc.enum.dcScanMethod.${scanMethod}`) : null;
  }
}
