/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityApiConfiguration as __Configuration } from '../cohesity-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ActiveDirectoryEntry } from '../models/active-directory-entry';
import { CreateActiveDirectoryEntryParams } from '../models/create-active-directory-entry-params';
import { ListCentrifyZone } from '../models/list-centrify-zone';
import { DomainControllers } from '../models/domain-controllers';
import { ActiveDirectoryPrincipal } from '../models/active-directory-principal';
import { AddedActiveDirectoryPrincipal } from '../models/added-active-directory-principal';
import { ActiveDirectoryPrincipalsAddParameters } from '../models/active-directory-principals-add-parameters';
import { UpdateTrustedDomainEnableParams } from '../models/update-trusted-domain-enable-params';
import { IdMappingInfo } from '../models/id-mapping-info';
import { UpdateIgnoredTrustedDomainsParams } from '../models/update-ignored-trusted-domains-params';
import { UpdateLdapProviderParams } from '../models/update-ldap-provider-params';
import { UpdateMachineAccountsParams } from '../models/update-machine-accounts-params';
import { PreferredDomainController } from '../models/preferred-domain-controller';
@Injectable({
  providedIn: 'root',
})
class ActiveDirectoryService extends __BaseService {
  static readonly GetActiveDirectoryEntryPath = '/public/activeDirectory';
  static readonly CreateActiveDirectoryEntryPath = '/public/activeDirectory';
  static readonly DeleteActiveDirectoryEntryPath = '/public/activeDirectory';
  static readonly ListCentrifyZonesPath = '/public/activeDirectory/centrifyZones';
  static readonly GetActiveDirectoryDomainControllersPath = '/public/activeDirectory/domainControllers';
  static readonly SearchActiveDirectoryPrincipalsPath = '/public/activeDirectory/principals';
  static readonly AddActiveDirectoryPrincipalsPath = '/public/activeDirectory/principals';
  static readonly EnableTrustedDomainDiscoveryPath = '/public/activeDirectory/{name}/enableTrustedDomainState';
  static readonly UpdateActiveDirectoryIdMappingPath = '/public/activeDirectory/{name}/idMappingInfo';
  static readonly UpdateActiveDirectoryIgnoredTrustedDomainsPath = '/public/activeDirectory/{name}/ignoredTrustedDomains';
  static readonly UpdateActiveDirectoryLdapProviderPath = '/public/activeDirectory/{name}/ldapProvider';
  static readonly UpdateActiveDirectoryMachineAccountsPath = '/public/activeDirectory/{name}/machineAccounts';
  static readonly UpdatePreferredDomainControllersPath = '/public/activeDirectory/{name}/preferredDomainControllers';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Lists the Active Directories that the Cohesity Cluster has joined.
   *
   * After a Cohesity Cluster has been joined to an Active Directory domain,
   * the users and groups in the domain can be authenticated on the Cohesity Cluster
   * using their Active Directory credentials.
   *
   * NOTE: The userName and password fields are not populated by this operation.
   * @param params The `ActiveDirectoryService.GetActiveDirectoryEntryParams` containing the following parameters:
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which objects are to be
   *   returned.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `domains`: Specifies the domains to fetch active directory entries.
   *
   * - `allUnderHierarchy`: AllUnderHierarchy specifies if objects of all the tenants under the
   *   hierarchy of the logged in user's organization should be returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetActiveDirectoryEntryResponse(params: ActiveDirectoryService.GetActiveDirectoryEntryParams): __Observable<__StrictHttpResponse<Array<ActiveDirectoryEntry>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.tenantIds || []).forEach(val => {if (val != null) __params = __params.append('tenantIds', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.domains || []).forEach(val => {if (val != null) __params = __params.append('domains', val.toString())});
    if (params.allUnderHierarchy != null) __params = __params.set('allUnderHierarchy', params.allUnderHierarchy.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/activeDirectory`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ActiveDirectoryEntry>>;
      })
    );
  }
  /**
   * Lists the Active Directories that the Cohesity Cluster has joined.
   *
   * After a Cohesity Cluster has been joined to an Active Directory domain,
   * the users and groups in the domain can be authenticated on the Cohesity Cluster
   * using their Active Directory credentials.
   *
   * NOTE: The userName and password fields are not populated by this operation.
   * @param params The `ActiveDirectoryService.GetActiveDirectoryEntryParams` containing the following parameters:
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which objects are to be
   *   returned.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `domains`: Specifies the domains to fetch active directory entries.
   *
   * - `allUnderHierarchy`: AllUnderHierarchy specifies if objects of all the tenants under the
   *   hierarchy of the logged in user's organization should be returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetActiveDirectoryEntry(params: ActiveDirectoryService.GetActiveDirectoryEntryParams): __Observable<Array<ActiveDirectoryEntry>> {
    return this.GetActiveDirectoryEntryResponse(params).pipe(
      __map(_r => _r.body as Array<ActiveDirectoryEntry>)
    );
  }

  /**
   * Join the Cohesity Cluster to the specified Active Directory.
   *
   * After a Cohesity Cluster has been joined to an Active Directory domain,
   * the users and groups in the domain can be authenticated on the Cohesity Cluster
   * using their Active Directory credentials.
   * @param params The `ActiveDirectoryService.CreateActiveDirectoryEntryParams` containing the following parameters:
   *
   * - `body`: Request to join an Active Directory.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateActiveDirectoryEntryResponse(params: ActiveDirectoryService.CreateActiveDirectoryEntryParams): __Observable<__StrictHttpResponse<ActiveDirectoryEntry>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/activeDirectory`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ActiveDirectoryEntry>;
      })
    );
  }
  /**
   * Join the Cohesity Cluster to the specified Active Directory.
   *
   * After a Cohesity Cluster has been joined to an Active Directory domain,
   * the users and groups in the domain can be authenticated on the Cohesity Cluster
   * using their Active Directory credentials.
   * @param params The `ActiveDirectoryService.CreateActiveDirectoryEntryParams` containing the following parameters:
   *
   * - `body`: Request to join an Active Directory.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateActiveDirectoryEntry(params: ActiveDirectoryService.CreateActiveDirectoryEntryParams): __Observable<ActiveDirectoryEntry> {
    return this.CreateActiveDirectoryEntryResponse(params).pipe(
      __map(_r => _r.body as ActiveDirectoryEntry)
    );
  }

  /**
   * Deletes the join with the Active Directory.
   *
   * Deletes the join of the Cohesity Cluster to the specified
   * Active Directory domain. After the deletion, the Cohesity Cluster
   * no longer has access to the principals on the Active Directory.
   * For example, you can no longer log in to the Cohesity Cluster
   * with a user defined in a principal group of the Active Directory domain.
   * @param params The `ActiveDirectoryService.DeleteActiveDirectoryEntryParams` containing the following parameters:
   *
   * - `body`: Request to delete a join with an Active Directory.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteActiveDirectoryEntryResponse(params: ActiveDirectoryService.DeleteActiveDirectoryEntryParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/public/activeDirectory`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Deletes the join with the Active Directory.
   *
   * Deletes the join of the Cohesity Cluster to the specified
   * Active Directory domain. After the deletion, the Cohesity Cluster
   * no longer has access to the principals on the Active Directory.
   * For example, you can no longer log in to the Cohesity Cluster
   * with a user defined in a principal group of the Active Directory domain.
   * @param params The `ActiveDirectoryService.DeleteActiveDirectoryEntryParams` containing the following parameters:
   *
   * - `body`: Request to delete a join with an Active Directory.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteActiveDirectoryEntry(params: ActiveDirectoryService.DeleteActiveDirectoryEntryParams): __Observable<null> {
    return this.DeleteActiveDirectoryEntryResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Fetches the list centrify zones of an active directory domain.
   * @param params The `ActiveDirectoryService.ListCentrifyZonesParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `domainName`: Specifies the fully qualified domain name (FQDN) of an Active Directory.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  ListCentrifyZonesResponse(params: ActiveDirectoryService.ListCentrifyZonesParams): __Observable<__StrictHttpResponse<Array<ListCentrifyZone>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.domainName != null) __params = __params.set('domainName', params.domainName.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/activeDirectory/centrifyZones`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ListCentrifyZone>>;
      })
    );
  }
  /**
   * Fetches the list centrify zones of an active directory domain.
   * @param params The `ActiveDirectoryService.ListCentrifyZonesParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `domainName`: Specifies the fully qualified domain name (FQDN) of an Active Directory.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  ListCentrifyZones(params: ActiveDirectoryService.ListCentrifyZonesParams): __Observable<Array<ListCentrifyZone>> {
    return this.ListCentrifyZonesResponse(params).pipe(
      __map(_r => _r.body as Array<ListCentrifyZone>)
    );
  }

  /**
   * List the domain controllers for a domain.
   * @param params The `ActiveDirectoryService.GetActiveDirectoryDomainControllersParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `domainName`: Specifies the domain name
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetActiveDirectoryDomainControllersResponse(params: ActiveDirectoryService.GetActiveDirectoryDomainControllersParams): __Observable<__StrictHttpResponse<DomainControllers>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.domainName != null) __params = __params.set('domainName', params.domainName.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/activeDirectory/domainControllers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DomainControllers>;
      })
    );
  }
  /**
   * List the domain controllers for a domain.
   * @param params The `ActiveDirectoryService.GetActiveDirectoryDomainControllersParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `domainName`: Specifies the domain name
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetActiveDirectoryDomainControllers(params: ActiveDirectoryService.GetActiveDirectoryDomainControllersParams): __Observable<DomainControllers> {
    return this.GetActiveDirectoryDomainControllersResponse(params).pipe(
      __map(_r => _r.body as DomainControllers)
    );
  }

  /**
   * List the user and group principals in the Active Directory that match the
   * filter criteria specified using parameters.
   *
   * Optionally limit the search results by specifying security identifiers (SIDs),
   * an object class (user or group) or a substring.
   * You can specify SIDs or a substring but not both.
   * @param params The `ActiveDirectoryService.SearchActiveDirectoryPrincipalsParams` containing the following parameters:
   *
   * - `sids`: Optionally filter by a list of security identifiers (SIDs) found
   *   in the specified domain. Only principals matching
   *   the specified SIDs are returned.
   *   If specified, a 'search' parameter should not be specified.
   *
   * - `search`: Optionally filter by matching a substring. Only principals in
   *   the with a name or sAMAccountName that matches
   *   part or all of the specified substring are returned.
   *   If specified, a 'sids' parameter should not be specified.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `objectClass`: Optionally filter by a principal object class such as 'kGroup' or 'kUser'.
   *   If 'kGroup' is specified, only group principals are returned.
   *   If 'kUser' is specified, only user principals are returned.
   *   If not specified, both group and user principals are returned.
   *   'kUser' specifies a user object class.
   *   'kGroup' specifies a group object class.
   *   'kComputer' specifies a computer object class.
   *   'kWellKnownPrincipal' specifies a well known principal.
   *
   * - `includeComputers`: Specifies if Computer/GMSA accounts need to be included in this search.
   *
   * - `domain`: Specifies the domain name of the principals to search. If specified
   *   the principals in that domain are searched. Domain could be an
   *   Active Directory domain joined by the Cluster or any one of the
   *   trusted domains of the Active Directory domain or the LOCAL domain.
   *   If not specified, all the domains are searched.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  SearchActiveDirectoryPrincipalsResponse(params: ActiveDirectoryService.SearchActiveDirectoryPrincipalsParams): __Observable<__StrictHttpResponse<Array<ActiveDirectoryPrincipal>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.sids || []).forEach(val => {if (val != null) __params = __params.append('sids', val.toString())});
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.objectClass != null) __params = __params.set('objectClass', params.objectClass.toString());
    if (params.includeComputers != null) __params = __params.set('includeComputers', params.includeComputers.toString());
    if (params.domain != null) __params = __params.set('domain', params.domain.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/activeDirectory/principals`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ActiveDirectoryPrincipal>>;
      })
    );
  }
  /**
   * List the user and group principals in the Active Directory that match the
   * filter criteria specified using parameters.
   *
   * Optionally limit the search results by specifying security identifiers (SIDs),
   * an object class (user or group) or a substring.
   * You can specify SIDs or a substring but not both.
   * @param params The `ActiveDirectoryService.SearchActiveDirectoryPrincipalsParams` containing the following parameters:
   *
   * - `sids`: Optionally filter by a list of security identifiers (SIDs) found
   *   in the specified domain. Only principals matching
   *   the specified SIDs are returned.
   *   If specified, a 'search' parameter should not be specified.
   *
   * - `search`: Optionally filter by matching a substring. Only principals in
   *   the with a name or sAMAccountName that matches
   *   part or all of the specified substring are returned.
   *   If specified, a 'sids' parameter should not be specified.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `objectClass`: Optionally filter by a principal object class such as 'kGroup' or 'kUser'.
   *   If 'kGroup' is specified, only group principals are returned.
   *   If 'kUser' is specified, only user principals are returned.
   *   If not specified, both group and user principals are returned.
   *   'kUser' specifies a user object class.
   *   'kGroup' specifies a group object class.
   *   'kComputer' specifies a computer object class.
   *   'kWellKnownPrincipal' specifies a well known principal.
   *
   * - `includeComputers`: Specifies if Computer/GMSA accounts need to be included in this search.
   *
   * - `domain`: Specifies the domain name of the principals to search. If specified
   *   the principals in that domain are searched. Domain could be an
   *   Active Directory domain joined by the Cluster or any one of the
   *   trusted domains of the Active Directory domain or the LOCAL domain.
   *   If not specified, all the domains are searched.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  SearchActiveDirectoryPrincipals(params: ActiveDirectoryService.SearchActiveDirectoryPrincipalsParams): __Observable<Array<ActiveDirectoryPrincipal>> {
    return this.SearchActiveDirectoryPrincipalsResponse(params).pipe(
      __map(_r => _r.body as Array<ActiveDirectoryPrincipal>)
    );
  }

  /**
   * Add multiple groups or users on the Cohesity Cluster for the specified
   * Active Directory principals. In addition, assign Cohesity roles to the
   * users or groups to define their Cohesity privileges.
   *
   * After a group or user has been added to a Cohesity Cluster,
   * the referenced Active Directory principal can be used by the Cohesity Cluster.
   * In addition, this operation maps Cohesity roles with a group or user and
   * this mapping defines the privileges allowed on the Cohesity Cluster for the
   * group or user.
   * For example if an 'management' group is created on the Cohesity Cluster
   * for the Active Directory 'management' principal group and is
   * associated with the Cohesity 'View' role, all users in the
   * referenced Active Directory 'management' principal group can log in to the
   * Cohesity Dashboard but will only have view-only privileges.
   * These users cannot create new Protection Jobs, Policies, Views, etc.
   *
   * NOTE: Local Cohesity users and groups cannot be created by this operation.
   * Local Cohesity users or groups do not have an associated Active Directory
   * principals and are created directly in the default LOCAL domain.
   * @param params The `ActiveDirectoryService.AddActiveDirectoryPrincipalsParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: Request to add groups or users to the Cohesity Cluster.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  AddActiveDirectoryPrincipalsResponse(params: ActiveDirectoryService.AddActiveDirectoryPrincipalsParams): __Observable<__StrictHttpResponse<Array<AddedActiveDirectoryPrincipal>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    __body = params.body;
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/activeDirectory/principals`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AddedActiveDirectoryPrincipal>>;
      })
    );
  }
  /**
   * Add multiple groups or users on the Cohesity Cluster for the specified
   * Active Directory principals. In addition, assign Cohesity roles to the
   * users or groups to define their Cohesity privileges.
   *
   * After a group or user has been added to a Cohesity Cluster,
   * the referenced Active Directory principal can be used by the Cohesity Cluster.
   * In addition, this operation maps Cohesity roles with a group or user and
   * this mapping defines the privileges allowed on the Cohesity Cluster for the
   * group or user.
   * For example if an 'management' group is created on the Cohesity Cluster
   * for the Active Directory 'management' principal group and is
   * associated with the Cohesity 'View' role, all users in the
   * referenced Active Directory 'management' principal group can log in to the
   * Cohesity Dashboard but will only have view-only privileges.
   * These users cannot create new Protection Jobs, Policies, Views, etc.
   *
   * NOTE: Local Cohesity users and groups cannot be created by this operation.
   * Local Cohesity users or groups do not have an associated Active Directory
   * principals and are created directly in the default LOCAL domain.
   * @param params The `ActiveDirectoryService.AddActiveDirectoryPrincipalsParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: Request to add groups or users to the Cohesity Cluster.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  AddActiveDirectoryPrincipals(params: ActiveDirectoryService.AddActiveDirectoryPrincipalsParams): __Observable<Array<AddedActiveDirectoryPrincipal>> {
    return this.AddActiveDirectoryPrincipalsResponse(params).pipe(
      __map(_r => _r.body as Array<AddedActiveDirectoryPrincipal>)
    );
  }

  /**
   * Updates the states of trusted domains discovery.
   * @param params The `ActiveDirectoryService.EnableTrustedDomainDiscoveryParams` containing the following parameters:
   *
   * - `name`: Specifies the Active Directory Domain Name.
   *
   * - `body`: Request to update enable trusted domains state of an Active Directory.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  EnableTrustedDomainDiscoveryResponse(params: ActiveDirectoryService.EnableTrustedDomainDiscoveryParams): __Observable<__StrictHttpResponse<ActiveDirectoryEntry>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/activeDirectory/${encodeURIComponent(params.name)}/enableTrustedDomainState`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ActiveDirectoryEntry>;
      })
    );
  }
  /**
   * Updates the states of trusted domains discovery.
   * @param params The `ActiveDirectoryService.EnableTrustedDomainDiscoveryParams` containing the following parameters:
   *
   * - `name`: Specifies the Active Directory Domain Name.
   *
   * - `body`: Request to update enable trusted domains state of an Active Directory.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  EnableTrustedDomainDiscovery(params: ActiveDirectoryService.EnableTrustedDomainDiscoveryParams): __Observable<ActiveDirectoryEntry> {
    return this.EnableTrustedDomainDiscoveryResponse(params).pipe(
      __map(_r => _r.body as ActiveDirectoryEntry)
    );
  }

  /**
   * Updates the user id mapping info of an Active Directory.
   * @param params The `ActiveDirectoryService.UpdateActiveDirectoryIdMappingParams` containing the following parameters:
   *
   * - `name`: Specifies the Active Directory Domain Name.
   *
   * - `body`: Request to update user id mapping of an Active Directory.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateActiveDirectoryIdMappingResponse(params: ActiveDirectoryService.UpdateActiveDirectoryIdMappingParams): __Observable<__StrictHttpResponse<ActiveDirectoryEntry>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/public/activeDirectory/${encodeURIComponent(params.name)}/idMappingInfo`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ActiveDirectoryEntry>;
      })
    );
  }
  /**
   * Updates the user id mapping info of an Active Directory.
   * @param params The `ActiveDirectoryService.UpdateActiveDirectoryIdMappingParams` containing the following parameters:
   *
   * - `name`: Specifies the Active Directory Domain Name.
   *
   * - `body`: Request to update user id mapping of an Active Directory.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateActiveDirectoryIdMapping(params: ActiveDirectoryService.UpdateActiveDirectoryIdMappingParams): __Observable<ActiveDirectoryEntry> {
    return this.UpdateActiveDirectoryIdMappingResponse(params).pipe(
      __map(_r => _r.body as ActiveDirectoryEntry)
    );
  }

  /**
   * Updates the list of trusted domains to be ignored during trusted domain discovery of an Active Directory.
   * @param params The `ActiveDirectoryService.UpdateActiveDirectoryIgnoredTrustedDomainsParams` containing the following parameters:
   *
   * - `name`: Specifies the Active Directory Domain Name.
   *
   * - `body`: Request to update the list of ignored trusted domains of an AD.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateActiveDirectoryIgnoredTrustedDomainsResponse(params: ActiveDirectoryService.UpdateActiveDirectoryIgnoredTrustedDomainsParams): __Observable<__StrictHttpResponse<ActiveDirectoryEntry>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/public/activeDirectory/${encodeURIComponent(params.name)}/ignoredTrustedDomains`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ActiveDirectoryEntry>;
      })
    );
  }
  /**
   * Updates the list of trusted domains to be ignored during trusted domain discovery of an Active Directory.
   * @param params The `ActiveDirectoryService.UpdateActiveDirectoryIgnoredTrustedDomainsParams` containing the following parameters:
   *
   * - `name`: Specifies the Active Directory Domain Name.
   *
   * - `body`: Request to update the list of ignored trusted domains of an AD.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateActiveDirectoryIgnoredTrustedDomains(params: ActiveDirectoryService.UpdateActiveDirectoryIgnoredTrustedDomainsParams): __Observable<ActiveDirectoryEntry> {
    return this.UpdateActiveDirectoryIgnoredTrustedDomainsResponse(params).pipe(
      __map(_r => _r.body as ActiveDirectoryEntry)
    );
  }

  /**
   * Updates the LDAP provide Id for an Active Directory domain.
   * @param params The `ActiveDirectoryService.UpdateActiveDirectoryLdapProviderParams` containing the following parameters:
   *
   * - `name`: Specifies the Active Directory Domain Name.
   *
   * - `body`: Request to update the LDAP provider info.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateActiveDirectoryLdapProviderResponse(params: ActiveDirectoryService.UpdateActiveDirectoryLdapProviderParams): __Observable<__StrictHttpResponse<ActiveDirectoryEntry>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/public/activeDirectory/${encodeURIComponent(params.name)}/ldapProvider`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ActiveDirectoryEntry>;
      })
    );
  }
  /**
   * Updates the LDAP provide Id for an Active Directory domain.
   * @param params The `ActiveDirectoryService.UpdateActiveDirectoryLdapProviderParams` containing the following parameters:
   *
   * - `name`: Specifies the Active Directory Domain Name.
   *
   * - `body`: Request to update the LDAP provider info.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateActiveDirectoryLdapProvider(params: ActiveDirectoryService.UpdateActiveDirectoryLdapProviderParams): __Observable<ActiveDirectoryEntry> {
    return this.UpdateActiveDirectoryLdapProviderResponse(params).pipe(
      __map(_r => _r.body as ActiveDirectoryEntry)
    );
  }

  /**
   * Updates the machine accounts of an Active Directory.
   * @param params The `ActiveDirectoryService.UpdateActiveDirectoryMachineAccountsParams` containing the following parameters:
   *
   * - `name`: Specifies the Active Directory Domain Name.
   *
   * - `body`: Request to update machine accounts of an Active Directory.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateActiveDirectoryMachineAccountsResponse(params: ActiveDirectoryService.UpdateActiveDirectoryMachineAccountsParams): __Observable<__StrictHttpResponse<ActiveDirectoryEntry>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/activeDirectory/${encodeURIComponent(params.name)}/machineAccounts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ActiveDirectoryEntry>;
      })
    );
  }
  /**
   * Updates the machine accounts of an Active Directory.
   * @param params The `ActiveDirectoryService.UpdateActiveDirectoryMachineAccountsParams` containing the following parameters:
   *
   * - `name`: Specifies the Active Directory Domain Name.
   *
   * - `body`: Request to update machine accounts of an Active Directory.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateActiveDirectoryMachineAccounts(params: ActiveDirectoryService.UpdateActiveDirectoryMachineAccountsParams): __Observable<ActiveDirectoryEntry> {
    return this.UpdateActiveDirectoryMachineAccountsResponse(params).pipe(
      __map(_r => _r.body as ActiveDirectoryEntry)
    );
  }

  /**
   * Updates the preferred domain controllers of an Active Directory
   * @param params The `ActiveDirectoryService.UpdatePreferredDomainControllersParams` containing the following parameters:
   *
   * - `name`: Specifies the Active Directory Domain Name.
   *
   * - `body`: Request to update preferred domain controllers of an Active Directory.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdatePreferredDomainControllersResponse(params: ActiveDirectoryService.UpdatePreferredDomainControllersParams): __Observable<__StrictHttpResponse<ActiveDirectoryEntry>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/public/activeDirectory/${encodeURIComponent(params.name)}/preferredDomainControllers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ActiveDirectoryEntry>;
      })
    );
  }
  /**
   * Updates the preferred domain controllers of an Active Directory
   * @param params The `ActiveDirectoryService.UpdatePreferredDomainControllersParams` containing the following parameters:
   *
   * - `name`: Specifies the Active Directory Domain Name.
   *
   * - `body`: Request to update preferred domain controllers of an Active Directory.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdatePreferredDomainControllers(params: ActiveDirectoryService.UpdatePreferredDomainControllersParams): __Observable<ActiveDirectoryEntry> {
    return this.UpdatePreferredDomainControllersResponse(params).pipe(
      __map(_r => _r.body as ActiveDirectoryEntry)
    );
  }
}

module ActiveDirectoryService {

  /**
   * Parameters for GetActiveDirectoryEntry
   */
  export interface GetActiveDirectoryEntryParams {

    /**
     * TenantIds contains ids of the tenants for which objects are to be
     * returned.
     */
    tenantIds?: Array<string>;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Specifies the domains to fetch active directory entries.
     */
    domains?: Array<string>;

    /**
     * AllUnderHierarchy specifies if objects of all the tenants under the
     * hierarchy of the logged in user's organization should be returned.
     */
    allUnderHierarchy?: boolean;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreateActiveDirectoryEntry
   */
  export interface CreateActiveDirectoryEntryParams {

    /**
     * Request to join an Active Directory.
     */
    body: CreateActiveDirectoryEntryParams;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DeleteActiveDirectoryEntry
   */
  export interface DeleteActiveDirectoryEntryParams {

    /**
     * Request to delete a join with an Active Directory.
     */
    body: ActiveDirectoryEntry;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for ListCentrifyZones
   */
  export interface ListCentrifyZonesParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Specifies the fully qualified domain name (FQDN) of an Active Directory.
     */
    domainName?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetActiveDirectoryDomainControllers
   */
  export interface GetActiveDirectoryDomainControllersParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Specifies the domain name
     */
    domainName?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for SearchActiveDirectoryPrincipals
   */
  export interface SearchActiveDirectoryPrincipalsParams {

    /**
     * Optionally filter by a list of security identifiers (SIDs) found
     * in the specified domain. Only principals matching
     * the specified SIDs are returned.
     * If specified, a 'search' parameter should not be specified.
     */
    sids?: Array<string>;

    /**
     * Optionally filter by matching a substring. Only principals in
     * the with a name or sAMAccountName that matches
     * part or all of the specified substring are returned.
     * If specified, a 'sids' parameter should not be specified.
     */
    search?: string;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Optionally filter by a principal object class such as 'kGroup' or 'kUser'.
     * If 'kGroup' is specified, only group principals are returned.
     * If 'kUser' is specified, only user principals are returned.
     * If not specified, both group and user principals are returned.
     * 'kUser' specifies a user object class.
     * 'kGroup' specifies a group object class.
     * 'kComputer' specifies a computer object class.
     * 'kWellKnownPrincipal' specifies a well known principal.
     */
    objectClass?: 'kUser' | 'kGroup' | 'kComputer' | 'kWellKnownPrincipal';

    /**
     * Specifies if Computer/GMSA accounts need to be included in this search.
     */
    includeComputers?: boolean;

    /**
     * Specifies the domain name of the principals to search. If specified
     * the principals in that domain are searched. Domain could be an
     * Active Directory domain joined by the Cluster or any one of the
     * trusted domains of the Active Directory domain or the LOCAL domain.
     * If not specified, all the domains are searched.
     */
    domain?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for AddActiveDirectoryPrincipals
   */
  export interface AddActiveDirectoryPrincipalsParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Request to add groups or users to the Cohesity Cluster.
     */
    body?: Array<ActiveDirectoryPrincipalsAddParameters>;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for EnableTrustedDomainDiscovery
   */
  export interface EnableTrustedDomainDiscoveryParams {

    /**
     * Specifies the Active Directory Domain Name.
     */
    name: string;

    /**
     * Request to update enable trusted domains state of an Active Directory.
     */
    body: UpdateTrustedDomainEnableParams;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateActiveDirectoryIdMapping
   */
  export interface UpdateActiveDirectoryIdMappingParams {

    /**
     * Specifies the Active Directory Domain Name.
     */
    name: string;

    /**
     * Request to update user id mapping of an Active Directory.
     */
    body: IdMappingInfo;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateActiveDirectoryIgnoredTrustedDomains
   */
  export interface UpdateActiveDirectoryIgnoredTrustedDomainsParams {

    /**
     * Specifies the Active Directory Domain Name.
     */
    name: string;

    /**
     * Request to update the list of ignored trusted domains of an AD.
     */
    body: UpdateIgnoredTrustedDomainsParams;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateActiveDirectoryLdapProvider
   */
  export interface UpdateActiveDirectoryLdapProviderParams {

    /**
     * Specifies the Active Directory Domain Name.
     */
    name: string;

    /**
     * Request to update the LDAP provider info.
     */
    body: UpdateLdapProviderParams;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateActiveDirectoryMachineAccounts
   */
  export interface UpdateActiveDirectoryMachineAccountsParams {

    /**
     * Specifies the Active Directory Domain Name.
     */
    name: string;

    /**
     * Request to update machine accounts of an Active Directory.
     */
    body: UpdateMachineAccountsParams;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdatePreferredDomainControllers
   */
  export interface UpdatePreferredDomainControllersParams {

    /**
     * Specifies the Active Directory Domain Name.
     */
    name: string;

    /**
     * Request to update preferred domain controllers of an Active Directory.
     */
    body: Array<PreferredDomainController>;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { ActiveDirectoryService }
