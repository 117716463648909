/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityV2ApiConfiguration as __Configuration } from '../cohesity-v2api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { AlertList } from '../models/alert-list';
import { AlertsSummaryResponse } from '../models/alerts-summary-response';
import { AlertsList } from '../models/alerts-list';
import { AlertResolutionsList } from '../models/alert-resolutions-list';
import { AlertResolution } from '../models/alert-resolution';
import { CreateHeliosAlertResolutionParams } from '../models/create-helios-alert-resolution-params';
import { NotificationRules } from '../models/notification-rules';
import { NotificationRule } from '../models/notification-rule';
@Injectable({
  providedIn: 'root',
})
class AlertService extends __BaseService {
  static readonly GetAlertsPath = '/alerts';
  static readonly GetAlertSummaryPath = '/alerts-summary';
  static readonly GetHeliosAlertsPath = '/mcm/alerts';
  static readonly GetHeliosAlertResolutionPath = '/mcm/alerts/resolutions';
  static readonly CreateHeliosAlertResolutionPath = '/mcm/alerts/resolutions';
  static readonly GetHeliosAlertsSummaryPath = '/mcm/stats/alerts-summary';
  static readonly GetAlertNotificationRulesPath = '/alerts/config/notification-rules';
  static readonly CreateAlertNotificationRulePath = '/alerts/config/notification-rules';
  static readonly RemoveAlertNotificationRulePath = '/alerts/config/notification-rules/{id}';
  static readonly UpdateAlertNotificationRulePath = '/alerts/config/notification-rules/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get alerts.
   * @param params The `AlertService.GetAlertsParams` containing the following parameters:
   *
   * - `tenantIds`: Filter by tenant ids.
   *
   * - `startTimeUsecs`: Specifies start time Unix epoch time in microseconds to filter alerts by.
   *
   * - `resolutionIds`: Specifies alert resolution ids to filter alerts by.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `propertyValue`: Specifies value of the property to filter alerts by.
   *
   * - `propertyKey`: Specifies name of the property to filter alerts by.
   *
   * - `maxAlerts`: Specifies maximum number of alerts to return.The default value is 100 and maximum allowed value is 1000
   *
   * - `endTimeUsecs`: Specifies end time Unix epoch time in microseconds to filter alerts by.
   *
   * - `allUnderHierarchy`: Filter by objects of all the tenants under the hierarchy of the logged in user's organization.
   *
   * - `alertTypes`: Filter by list of alert types.
   *
   * - `alertTypeBuckets`: Filter by list of alert type buckets.
   *
   * - `alertStates`: Filter by list of alert states.
   *
   * - `alertSeverities`: Filter by list of alert severity types.
   *
   * - `alertName`: Specifies name of alert to filter alerts by.
   *
   * - `alertIds`: Filter by list of alert ids.
   *
   * - `alertCategories`: Filter by list of alert categories.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetAlertsResponse(params: AlertService.GetAlertsParams): __Observable<__StrictHttpResponse<AlertList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.tenantIds || []).forEach(val => {if (val != null) __params = __params.append('tenantIds', val.toString())});
    if (params.startTimeUsecs != null) __params = __params.set('startTimeUsecs', params.startTimeUsecs.toString());
    (params.resolutionIds || []).forEach(val => {if (val != null) __params = __params.append('resolutionIds', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.propertyValue != null) __params = __params.set('propertyValue', params.propertyValue.toString());
    if (params.propertyKey != null) __params = __params.set('propertyKey', params.propertyKey.toString());
    if (params.maxAlerts != null) __params = __params.set('maxAlerts', params.maxAlerts.toString());
    if (params.endTimeUsecs != null) __params = __params.set('endTimeUsecs', params.endTimeUsecs.toString());
    if (params.allUnderHierarchy != null) __params = __params.set('allUnderHierarchy', params.allUnderHierarchy.toString());
    (params.alertTypes || []).forEach(val => {if (val != null) __params = __params.append('alertTypes', val.toString())});
    (params.alertTypeBuckets || []).forEach(val => {if (val != null) __params = __params.append('alertTypeBuckets', val.toString())});
    (params.alertStates || []).forEach(val => {if (val != null) __params = __params.append('alertStates', val.toString())});
    (params.alertSeverities || []).forEach(val => {if (val != null) __params = __params.append('alertSeverities', val.toString())});
    if (params.alertName != null) __params = __params.set('alertName', params.alertName.toString());
    (params.alertIds || []).forEach(val => {if (val != null) __params = __params.append('alertIds', val.toString())});
    (params.alertCategories || []).forEach(val => {if (val != null) __params = __params.append('alertCategories', val.toString())});
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/alerts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AlertList>;
      })
    );
  }
  /**
   * Get alerts.
   * @param params The `AlertService.GetAlertsParams` containing the following parameters:
   *
   * - `tenantIds`: Filter by tenant ids.
   *
   * - `startTimeUsecs`: Specifies start time Unix epoch time in microseconds to filter alerts by.
   *
   * - `resolutionIds`: Specifies alert resolution ids to filter alerts by.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `propertyValue`: Specifies value of the property to filter alerts by.
   *
   * - `propertyKey`: Specifies name of the property to filter alerts by.
   *
   * - `maxAlerts`: Specifies maximum number of alerts to return.The default value is 100 and maximum allowed value is 1000
   *
   * - `endTimeUsecs`: Specifies end time Unix epoch time in microseconds to filter alerts by.
   *
   * - `allUnderHierarchy`: Filter by objects of all the tenants under the hierarchy of the logged in user's organization.
   *
   * - `alertTypes`: Filter by list of alert types.
   *
   * - `alertTypeBuckets`: Filter by list of alert type buckets.
   *
   * - `alertStates`: Filter by list of alert states.
   *
   * - `alertSeverities`: Filter by list of alert severity types.
   *
   * - `alertName`: Specifies name of alert to filter alerts by.
   *
   * - `alertIds`: Filter by list of alert ids.
   *
   * - `alertCategories`: Filter by list of alert categories.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetAlerts(params: AlertService.GetAlertsParams): __Observable<AlertList> {
    return this.GetAlertsResponse(params).pipe(
      __map(_r => _r.body as AlertList)
    );
  }

  /**
   * Get alerts summary.
   *
   * Get alerts summary grouped by category.
   * @param params The `AlertService.GetAlertSummaryParams` containing the following parameters:
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which alerts are to be
   *   used to compute summary.
   *
   * - `statesList`: Specifies list of alert states to filter alerts by. If not specified,
   *   only open alerts will be used to get summary.
   *
   * - `startTimeUsecs`: Filter by start time. Specify the start time as a Unix epoch Timestamp (in microseconds). By default it is current time minus a day.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `includeTenants`: IncludeTenants specifies if alerts of all the tenants under the
   *   hierarchy of the logged in user's organization should be used to
   *   compute summary.
   *
   * - `endTimeUsecs`: Filter by end time. Specify the end time as a Unix epoch Timestamp (in microseconds). By default it is current time.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetAlertSummaryResponse(params: AlertService.GetAlertSummaryParams): __Observable<__StrictHttpResponse<AlertsSummaryResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.tenantIds || []).forEach(val => {if (val != null) __params = __params.append('tenantIds', val.toString())});
    (params.statesList || []).forEach(val => {if (val != null) __params = __params.append('statesList', val.toString())});
    if (params.startTimeUsecs != null) __params = __params.set('startTimeUsecs', params.startTimeUsecs.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.includeTenants != null) __params = __params.set('includeTenants', params.includeTenants.toString());
    if (params.endTimeUsecs != null) __params = __params.set('endTimeUsecs', params.endTimeUsecs.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/alerts-summary`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AlertsSummaryResponse>;
      })
    );
  }
  /**
   * Get alerts summary.
   *
   * Get alerts summary grouped by category.
   * @param params The `AlertService.GetAlertSummaryParams` containing the following parameters:
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which alerts are to be
   *   used to compute summary.
   *
   * - `statesList`: Specifies list of alert states to filter alerts by. If not specified,
   *   only open alerts will be used to get summary.
   *
   * - `startTimeUsecs`: Filter by start time. Specify the start time as a Unix epoch Timestamp (in microseconds). By default it is current time minus a day.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `includeTenants`: IncludeTenants specifies if alerts of all the tenants under the
   *   hierarchy of the logged in user's organization should be used to
   *   compute summary.
   *
   * - `endTimeUsecs`: Filter by end time. Specify the end time as a Unix epoch Timestamp (in microseconds). By default it is current time.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetAlertSummary(params: AlertService.GetAlertSummaryParams): __Observable<AlertsSummaryResponse> {
    return this.GetAlertSummaryResponse(params).pipe(
      __map(_r => _r.body as AlertsSummaryResponse)
    );
  }

  /**
   * Get list of helios alerts.
   *
   * Get the list of helios alerts.
   * @param params The `AlertService.GetHeliosAlertsParams` containing the following parameters:
   *
   * - `startDateUsecs`: Specifies the start time of the alerts to be returned. All the alerts returned are raised after the specified start time. This value should be in Unix timestamp epoch in microseconds.
   *
   * - `regionIds`: Filter by list of region ids.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `maxAlerts`: Specifies maximum number of alerts to return
   *
   * - `endDateUsecs`: Specifies the end time of the alerts to be returned. All the alerts returned are raised before the specified end time. This value should be in Unix timestamp epoch in microseconds.
   *
   * - `clusterIdentifiers`: Filter by list of cluster ids.
   *
   * - `alertTypeList`: Filter by list of alert types.
   *
   * - `alertTypeBucketList`: Filter by list of alert type buckets.
   *
   * - `alertStateList`: Filter by list of alert states.
   *
   * - `alertSeverityList`: Filter by list of alert severity types.
   *
   * - `alertPropertyValueList`: Specifies list of the alert property value, multiple values for one key should be joined by '|'.
   *
   * - `alertPropertyKeyList`: Specifies list of the alert property keys to query.
   *
   * - `alertIdList`: Filter by list of alert ids.
   *
   * - `alertCategoryList`: Filter by list of alert categories.
   *
   * @return Success
   */
  GetHeliosAlertsResponse(params: AlertService.GetHeliosAlertsParams): __Observable<__StrictHttpResponse<AlertsList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDateUsecs != null) __params = __params.set('startDateUsecs', params.startDateUsecs.toString());
    (params.regionIds || []).forEach(val => {if (val != null) __params = __params.append('regionIds', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.maxAlerts != null) __params = __params.set('maxAlerts', params.maxAlerts.toString());
    if (params.endDateUsecs != null) __params = __params.set('endDateUsecs', params.endDateUsecs.toString());
    (params.clusterIdentifiers || []).forEach(val => {if (val != null) __params = __params.append('clusterIdentifiers', val.toString())});
    (params.alertTypeList || []).forEach(val => {if (val != null) __params = __params.append('alertTypeList', val.toString())});
    (params.alertTypeBucketList || []).forEach(val => {if (val != null) __params = __params.append('alertTypeBucketList', val.toString())});
    (params.alertStateList || []).forEach(val => {if (val != null) __params = __params.append('alertStateList', val.toString())});
    (params.alertSeverityList || []).forEach(val => {if (val != null) __params = __params.append('alertSeverityList', val.toString())});
    (params.alertPropertyValueList || []).forEach(val => {if (val != null) __params = __params.append('alertPropertyValueList', val.toString())});
    (params.alertPropertyKeyList || []).forEach(val => {if (val != null) __params = __params.append('alertPropertyKeyList', val.toString())});
    (params.alertIdList || []).forEach(val => {if (val != null) __params = __params.append('alertIdList', val.toString())});
    (params.alertCategoryList || []).forEach(val => {if (val != null) __params = __params.append('alertCategoryList', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/mcm/alerts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AlertsList>;
      })
    );
  }
  /**
   * Get list of helios alerts.
   *
   * Get the list of helios alerts.
   * @param params The `AlertService.GetHeliosAlertsParams` containing the following parameters:
   *
   * - `startDateUsecs`: Specifies the start time of the alerts to be returned. All the alerts returned are raised after the specified start time. This value should be in Unix timestamp epoch in microseconds.
   *
   * - `regionIds`: Filter by list of region ids.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `maxAlerts`: Specifies maximum number of alerts to return
   *
   * - `endDateUsecs`: Specifies the end time of the alerts to be returned. All the alerts returned are raised before the specified end time. This value should be in Unix timestamp epoch in microseconds.
   *
   * - `clusterIdentifiers`: Filter by list of cluster ids.
   *
   * - `alertTypeList`: Filter by list of alert types.
   *
   * - `alertTypeBucketList`: Filter by list of alert type buckets.
   *
   * - `alertStateList`: Filter by list of alert states.
   *
   * - `alertSeverityList`: Filter by list of alert severity types.
   *
   * - `alertPropertyValueList`: Specifies list of the alert property value, multiple values for one key should be joined by '|'.
   *
   * - `alertPropertyKeyList`: Specifies list of the alert property keys to query.
   *
   * - `alertIdList`: Filter by list of alert ids.
   *
   * - `alertCategoryList`: Filter by list of alert categories.
   *
   * @return Success
   */
  GetHeliosAlerts(params: AlertService.GetHeliosAlertsParams): __Observable<AlertsList> {
    return this.GetHeliosAlertsResponse(params).pipe(
      __map(_r => _r.body as AlertsList)
    );
  }

  /**
   * List the Alert Resolutions in Cohesity system.
   *
   * Returns the Helios Alert Resolution objects found in Cohesity system that
   * match the filter criteria from given parameters. If no filter
   * parameters are specified, MaxResolutions Alert Resolution objects
   * are returned. Each object provides details about the Alert Resolution
   * info and the resolved alert info.
   * @param params The `AlertService.GetHeliosAlertResolutionParams` containing the following parameters:
   *
   * - `maxResolutions`: Specifies the max number of Resolutions to be returned, from the
   *   latest created to the earliest created
   *
   * - `resolutionName`: Specifies Alert Resolution Name to query.
   *
   * - `resolutionId`: Specifies Alert Resolution id to query.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * @return Success
   */
  GetHeliosAlertResolutionResponse(params: AlertService.GetHeliosAlertResolutionParams): __Observable<__StrictHttpResponse<Array<AlertResolutionsList>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.maxResolutions != null) __params = __params.set('maxResolutions', params.maxResolutions.toString());
    if (params.resolutionName != null) __params = __params.set('resolutionName', params.resolutionName.toString());
    if (params.resolutionId != null) __params = __params.set('resolutionId', params.resolutionId.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/mcm/alerts/resolutions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AlertResolutionsList>>;
      })
    );
  }
  /**
   * List the Alert Resolutions in Cohesity system.
   *
   * Returns the Helios Alert Resolution objects found in Cohesity system that
   * match the filter criteria from given parameters. If no filter
   * parameters are specified, MaxResolutions Alert Resolution objects
   * are returned. Each object provides details about the Alert Resolution
   * info and the resolved alert info.
   * @param params The `AlertService.GetHeliosAlertResolutionParams` containing the following parameters:
   *
   * - `maxResolutions`: Specifies the max number of Resolutions to be returned, from the
   *   latest created to the earliest created
   *
   * - `resolutionName`: Specifies Alert Resolution Name to query.
   *
   * - `resolutionId`: Specifies Alert Resolution id to query.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * @return Success
   */
  GetHeliosAlertResolution(params: AlertService.GetHeliosAlertResolutionParams): __Observable<Array<AlertResolutionsList>> {
    return this.GetHeliosAlertResolutionResponse(params).pipe(
      __map(_r => _r.body as Array<AlertResolutionsList>)
    );
  }

  /**
   * Create an Helios Alert Resolution.
   *
   * Apply resolution to Helios alerts, create the resolution if it dose not exists
   * @param body Helios Alert resolution to be created, with alerts to resolve. Alerts.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  CreateHeliosAlertResolutionResponse(body: CreateHeliosAlertResolutionParams,
    regionId?: string): __Observable<__StrictHttpResponse<AlertResolution>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/mcm/alerts/resolutions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AlertResolution>;
      })
    );
  }
  /**
   * Create an Helios Alert Resolution.
   *
   * Apply resolution to Helios alerts, create the resolution if it dose not exists
   * @param body Helios Alert resolution to be created, with alerts to resolve. Alerts.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  CreateHeliosAlertResolution(body: CreateHeliosAlertResolutionParams,
    regionId?: string): __Observable<AlertResolution> {
    return this.CreateHeliosAlertResolutionResponse(body, regionId).pipe(
      __map(_r => _r.body as AlertResolution)
    );
  }

  /**
   * Get alerts summary on Helios.
   *
   * Get alerts summary grouped by category.
   * @param params The `AlertService.GetHeliosAlertsSummaryParams` containing the following parameters:
   *
   * - `statesList`: Specifies list of alert states to filter alerts by. If not specified, only open alerts will be used to get summary.
   *
   * - `startTimeUsecs`: Filter by start time. Specify the start time as a Unix epoch Timestamp (in microseconds). By default it is current time minus a day.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `endTimeUsecs`: Filter by end time. Specify the end time as a Unix epoch Timestamp (in microseconds). By default it is current time.
   *
   * - `clusterIdentifiers`: Specifies the list of cluster identifiers. Format is clusterId:clusterIncarnationId.
   *
   * @return Success
   */
  GetHeliosAlertsSummaryResponse(params: AlertService.GetHeliosAlertsSummaryParams): __Observable<__StrictHttpResponse<AlertsSummaryResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.statesList || []).forEach(val => {if (val != null) __params = __params.append('statesList', val.toString())});
    if (params.startTimeUsecs != null) __params = __params.set('startTimeUsecs', params.startTimeUsecs.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.endTimeUsecs != null) __params = __params.set('endTimeUsecs', params.endTimeUsecs.toString());
    (params.clusterIdentifiers || []).forEach(val => {if (val != null) __params = __params.append('clusterIdentifiers', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/mcm/stats/alerts-summary`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AlertsSummaryResponse>;
      })
    );
  }
  /**
   * Get alerts summary on Helios.
   *
   * Get alerts summary grouped by category.
   * @param params The `AlertService.GetHeliosAlertsSummaryParams` containing the following parameters:
   *
   * - `statesList`: Specifies list of alert states to filter alerts by. If not specified, only open alerts will be used to get summary.
   *
   * - `startTimeUsecs`: Filter by start time. Specify the start time as a Unix epoch Timestamp (in microseconds). By default it is current time minus a day.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `endTimeUsecs`: Filter by end time. Specify the end time as a Unix epoch Timestamp (in microseconds). By default it is current time.
   *
   * - `clusterIdentifiers`: Specifies the list of cluster identifiers. Format is clusterId:clusterIncarnationId.
   *
   * @return Success
   */
  GetHeliosAlertsSummary(params: AlertService.GetHeliosAlertsSummaryParams): __Observable<AlertsSummaryResponse> {
    return this.GetHeliosAlertsSummaryResponse(params).pipe(
      __map(_r => _r.body as AlertsSummaryResponse)
    );
  }

  /**
   * List all notification rules
   *
   * List all notification rules configured.
   * @param ids Specifies the ids of the notification rules. If this is not specified, all the notification rules will be returned.
   * @return Success
   */
  GetAlertNotificationRulesResponse(ids?: Array<string>): __Observable<__StrictHttpResponse<Array<NotificationRules>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/alerts/config/notification-rules`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<NotificationRules>>;
      })
    );
  }
  /**
   * List all notification rules
   *
   * List all notification rules configured.
   * @param ids Specifies the ids of the notification rules. If this is not specified, all the notification rules will be returned.
   * @return Success
   */
  GetAlertNotificationRules(ids?: Array<string>): __Observable<Array<NotificationRules>> {
    return this.GetAlertNotificationRulesResponse(ids).pipe(
      __map(_r => _r.body as Array<NotificationRules>)
    );
  }

  /**
   * Add a notification rule
   *
   * Create a new notification rule rules that send emails, SNMP, Syslog, and/or cURL HTTP POST requests to a webhook URL based on the alert categories, severities, and names.
   * @param body Specifies the alert notification rule config.
   * @return Success
   */
  CreateAlertNotificationRuleResponse(body: NotificationRule): __Observable<__StrictHttpResponse<NotificationRule>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/alerts/config/notification-rules`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<NotificationRule>;
      })
    );
  }
  /**
   * Add a notification rule
   *
   * Create a new notification rule rules that send emails, SNMP, Syslog, and/or cURL HTTP POST requests to a webhook URL based on the alert categories, severities, and names.
   * @param body Specifies the alert notification rule config.
   * @return Success
   */
  CreateAlertNotificationRule(body: NotificationRule): __Observable<NotificationRule> {
    return this.CreateAlertNotificationRuleResponse(body).pipe(
      __map(_r => _r.body as NotificationRule)
    );
  }

  /**
   * Remove a notification rule
   *
   * Remove an alert notification rule specified by id.
   * @param id Unique Id of the notification rule.
   */
  RemoveAlertNotificationRuleResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/alerts/config/notification-rules/${encodeURIComponent(id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Remove a notification rule
   *
   * Remove an alert notification rule specified by id.
   * @param id Unique Id of the notification rule.
   */
  RemoveAlertNotificationRule(id: number): __Observable<null> {
    return this.RemoveAlertNotificationRuleResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Update a notification rule
   *
   * Update Notification rule specified by id.
   * @param id Unique Id of the notification rule.
   * @param body Specifies the parameters to update notification rule.
   * @return Success
   */
  UpdateAlertNotificationRuleResponse(id: number,
    body: NotificationRule): __Observable<__StrictHttpResponse<NotificationRule>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/alerts/config/notification-rules/${encodeURIComponent(id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<NotificationRule>;
      })
    );
  }
  /**
   * Update a notification rule
   *
   * Update Notification rule specified by id.
   * @param id Unique Id of the notification rule.
   * @param body Specifies the parameters to update notification rule.
   * @return Success
   */
  UpdateAlertNotificationRule(id: number,
    body: NotificationRule): __Observable<NotificationRule> {
    return this.UpdateAlertNotificationRuleResponse(id, body).pipe(
      __map(_r => _r.body as NotificationRule)
    );
  }
}

module AlertService {

  /**
   * Parameters for GetAlerts
   */
  export interface GetAlertsParams {

    /**
     * Filter by tenant ids.
     */
    tenantIds?: Array<string>;

    /**
     * Specifies start time Unix epoch time in microseconds to filter alerts by.
     */
    startTimeUsecs?: null | number;

    /**
     * Specifies alert resolution ids to filter alerts by.
     */
    resolutionIds?: Array<number>;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies value of the property to filter alerts by.
     */
    propertyValue?: null | string;

    /**
     * Specifies name of the property to filter alerts by.
     */
    propertyKey?: null | string;

    /**
     * Specifies maximum number of alerts to return.The default value is 100 and maximum allowed value is 1000
     */
    maxAlerts?: null | number;

    /**
     * Specifies end time Unix epoch time in microseconds to filter alerts by.
     */
    endTimeUsecs?: null | number;

    /**
     * Filter by objects of all the tenants under the hierarchy of the logged in user's organization.
     */
    allUnderHierarchy?: null | boolean;

    /**
     * Filter by list of alert types.
     */
    alertTypes?: Array<number>;

    /**
     * Filter by list of alert type buckets.
     */
    alertTypeBuckets?: Array<'kHardware' | 'kSoftware' | 'kDataService' | 'kMaintenance'>;

    /**
     * Filter by list of alert states.
     */
    alertStates?: Array<'kResolved' | 'kOpen' | 'kNote' | 'kSuppressed'>;

    /**
     * Filter by list of alert severity types.
     */
    alertSeverities?: Array<'kCritical' | 'kWarning' | 'kInfo'>;

    /**
     * Specifies name of alert to filter alerts by.
     */
    alertName?: string;

    /**
     * Filter by list of alert ids.
     */
    alertIds?: Array<string>;

    /**
     * Filter by list of alert categories.
     */
    alertCategories?: Array<'kDisk' | 'kNode' | 'kCluster' | 'kChassis' | 'kPowerSupply' | 'kCPU' | 'kMemory' | 'kTemperature' | 'kFan' | 'kNIC' | 'kFirmware' | 'kNodeHealth' | 'kOperatingSystem' | 'kDataPath' | 'kMetadata' | 'kIndexing' | 'kHelios' | 'kAppMarketPlace' | 'kSystemService' | 'kLicense' | 'kSecurity' | 'kUpgrade' | 'kClusterManagement' | 'kAuditLog' | 'kNetworking' | 'kConfiguration' | 'kStorageUsage' | 'kFaultTolerance' | 'kBackupRestore' | 'kArchivalRestore' | 'kRemoteReplication' | 'kQuota' | 'kCDP' | 'kViewFailover' | 'kDisasterRecovery' | 'kStorageDevice' | 'kStoragePool' | 'kGeneralSoftwareFailure' | 'kAgent'>;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetAlertSummary
   */
  export interface GetAlertSummaryParams {

    /**
     * TenantIds contains ids of the tenants for which alerts are to be
     * used to compute summary.
     */
    tenantIds?: null | Array<string>;

    /**
     * Specifies list of alert states to filter alerts by. If not specified,
     * only open alerts will be used to get summary.
     */
    statesList?: null | Array<'kResolved' | 'kOpen' | 'kNote' | 'kSuppressed'>;

    /**
     * Filter by start time. Specify the start time as a Unix epoch Timestamp (in microseconds). By default it is current time minus a day.
     */
    startTimeUsecs?: number;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * IncludeTenants specifies if alerts of all the tenants under the
     * hierarchy of the logged in user's organization should be used to
     * compute summary.
     */
    includeTenants?: null | boolean;

    /**
     * Filter by end time. Specify the end time as a Unix epoch Timestamp (in microseconds). By default it is current time.
     */
    endTimeUsecs?: number;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetHeliosAlerts
   */
  export interface GetHeliosAlertsParams {

    /**
     * Specifies the start time of the alerts to be returned. All the alerts returned are raised after the specified start time. This value should be in Unix timestamp epoch in microseconds.
     */
    startDateUsecs?: number;

    /**
     * Filter by list of region ids.
     */
    regionIds?: Array<string>;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies maximum number of alerts to return
     */
    maxAlerts?: number;

    /**
     * Specifies the end time of the alerts to be returned. All the alerts returned are raised before the specified end time. This value should be in Unix timestamp epoch in microseconds.
     */
    endDateUsecs?: number;

    /**
     * Filter by list of cluster ids.
     */
    clusterIdentifiers?: Array<string>;

    /**
     * Filter by list of alert types.
     */
    alertTypeList?: Array<number>;

    /**
     * Filter by list of alert type buckets.
     */
    alertTypeBucketList?: Array<null | 'kHardware' | 'kSoftware' | 'kDataService' | 'kMaintenance'>;

    /**
     * Filter by list of alert states.
     */
    alertStateList?: Array<string>;

    /**
     * Filter by list of alert severity types.
     */
    alertSeverityList?: Array<'kCritical' | 'kWarning' | 'kInfo'>;

    /**
     * Specifies list of the alert property value, multiple values for one key should be joined by '|'.
     */
    alertPropertyValueList?: Array<null | string>;

    /**
     * Specifies list of the alert property keys to query.
     */
    alertPropertyKeyList?: Array<null | string>;

    /**
     * Filter by list of alert ids.
     */
    alertIdList?: Array<string>;

    /**
     * Filter by list of alert categories.
     */
    alertCategoryList?: Array<null | 'kDisk' | 'kNode' | 'kCluster' | 'kChassis' | 'kPowerSupply' | 'kCPU' | 'kMemory' | 'kTemperature' | 'kFan' | 'kNIC' | 'kFirmware' | 'kNodeHealth' | 'kOperatingSystem' | 'kDataPath' | 'kMetadata' | 'kIndexing' | 'kHelios' | 'kAppMarketPlace' | 'kSystemService' | 'kLicense' | 'kSecurity' | 'kUpgrade' | 'kClusterManagement' | 'kAuditLog' | 'kNetworking' | 'kConfiguration' | 'kStorageUsage' | 'kFaultTolerance' | 'kBackupRestore' | 'kArchivalRestore' | 'kRemoteReplication' | 'kQuota' | 'kCDP' | 'kViewFailover' | 'kDisasterRecovery' | 'kStorageDevice' | 'kStoragePool' | 'kGeneralSoftwareFailure' | 'kAgent' | 'kDataSourceConnector'>;
  }

  /**
   * Parameters for GetHeliosAlertResolution
   */
  export interface GetHeliosAlertResolutionParams {

    /**
     * Specifies the max number of Resolutions to be returned, from the
     * latest created to the earliest created
     */
    maxResolutions: number;

    /**
     * Specifies Alert Resolution Name to query.
     */
    resolutionName?: string;

    /**
     * Specifies Alert Resolution id to query.
     */
    resolutionId?: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;
  }

  /**
   * Parameters for GetHeliosAlertsSummary
   */
  export interface GetHeliosAlertsSummaryParams {

    /**
     * Specifies list of alert states to filter alerts by. If not specified, only open alerts will be used to get summary.
     */
    statesList?: null | Array<'kResolved' | 'kOpen' | 'kNote' | 'kSuppressed'>;

    /**
     * Filter by start time. Specify the start time as a Unix epoch Timestamp (in microseconds). By default it is current time minus a day.
     */
    startTimeUsecs?: number;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Filter by end time. Specify the end time as a Unix epoch Timestamp (in microseconds). By default it is current time.
     */
    endTimeUsecs?: number;

    /**
     * Specifies the list of cluster identifiers. Format is clusterId:clusterIncarnationId.
     */
    clusterIdentifiers?: null | Array<string>;
  }
}

export { AlertService }
