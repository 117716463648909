import { FileSize } from '@cohesity/helix';

export const maxFileSizeBytes = 200 * FileSize.megabyte;

export const excludeFileExtension = [
  // Unsupported media (images, audio, and video) file extensions. See:
  // https://en.wikipedia.org/wiki/Audio_file_format
  // https://en.wikipedia.org/wiki/Video_file_format
  // https://en.wikipedia.org/wiki/Image_file_formats
  '3g2', '3gp', '8svx', 'aa', 'aac', 'aax', 'act', 'aif', 'aiff', 'alac',
  'amr', 'amv', 'ape', 'asf', 'au', 'avci', 'avcs', 'avi', 'avif',
  'avifs', 'awb', 'bmp', 'bpg', 'cda', 'cgm', 'dib', 'drc', 'drw',
  'dss', 'dvf', 'ecw', 'f4a', 'f4b', 'f4p', 'f4v', 'fit', 'fits',
  'flac', 'flif', 'flv', 'fts', 'gif', 'gifv', 'gsm', 'hdp', 'heic',
  'heics', 'heif', 'heifs', 'icb', 'ico', 'iff', 'iklax', 'ivs', 'j2c',
  'j2k', 'jfi', 'jfif', 'jif', 'jp2', 'jpc', 'jpe', 'jpeg', 'jpf',
  'jpg', 'jpg2', 'jpm', 'jpx', 'jxl', 'jxr', 'lbm', 'liff', 'm2ts',
  'm4a', 'm4b', 'm4p', 'm4v', 'mid', 'mj2', 'mkv', 'mmf', 'mng', 'mogg',
  'mov', 'mp2', 'mp3', 'mp4', 'mpc', 'mpe', 'mpeg', 'mpg', 'mpv',
  'msv', 'mts', 'mxf', 'nmf', 'nrrd', 'nsv', 'oga', 'ogg', 'ogv',
  'opus', 'org', 'pam', 'pbm', 'pcx', 'pgf', 'pgm', 'png', 'pnm',
  'ppm', 'psd', 'qt', 'ra', 'ras', 'raw', 'rm', 'rmvb', 'roq',
  'rtf64', 'sgi', 'sid', 'sln', 'sun', 'svg', 'svi', 'tga',
  'tif', 'tiff', 'ts', 'tta', 'vda', 'viv', 'vob', 'voc', 'vox',
  'vst', 'wav', 'wdp', 'webm', 'webp', 'wma', 'wmv', 'wv',
  'xisf', 'yuv',

  // Other unsupported non-text file extensions.
  // Note: zip files are supported (not included here).
  'apk', 'bat', 'bin', 'bz2', 'ciso', 'cso', 'cty', 'dat', 'disk',
  'dll', 'dmg', 'dsk', 'etl', 'exe', 'hdi', 'img', 'ini', 'iso',
  'mdf', 'msi', 'ovf', 'rels', 'snap', 'swf', 'sys',
  'vdi', 'vfd', 'vhd', 'vmdk', 'vmsd', 'vmx', 'xdi', 'xz'
];

export const excludeFilePaths = [
  // Common linux system paths to exclude.
  // See https://en.wikipedia.org/wiki/Filesystem_Hierarchy_Standard
  '/bin', '/boot', '/dev', '/etc', '/lib', '/lib32', '/lib64', '/opt',
  '/proc', '/run', '/sbin', '/sys', '/usr', '/var/backups', '/var/lib/dpkg',

  // Common windows system paths to exclude.
  // See https://en.wikipedia.org/wiki/Directory_structure
  '/SysWOW64', '/System', '/System32', '/WinSxS', '/Windows', '/ProgramData',
  '/Program Files', '/Program Files (x86)', '/$Recycle.Bin', '/$RECYCLE.BIN',
  '/Recovery', '/Users/Administrator/AppData', '/Users/Default/AppData',
];
