<ng-template cohFormSectionEditMode>
  <cog-settings-list [title]="'dg.td.scan.advSettings' | translate" [settingsVisible]="true">
    <cog-settings-list-item [label]="'dg.td.scan.scanName' | translate" cogDataId="scan-name" [inheritDataId]="true">
      <cog-settings-list-item-value-display>
        {{ scanNameCtrl.value | naLabel }}
      </cog-settings-list-item-value-display>
      <cog-settings-list-item-value-editor class="flex-row">
        <mat-form-field class="flex-grow" cogDataId="field" [inheritDataId]="true">
          <mat-label>{{ 'name' | translate }}</mat-label>
          <input matInput [formControl]="scanNameCtrl" cogDataId="input" [inheritDataId]="true" type="text" />
          <mat-error class="mat-error" *ngIf="formControl.hasError('whitespace')">
            {{ 'errors.patterns.noWhiteSpace' | translate }}
          </mat-error>
          <mat-error class="mat-error" *ngIf="formControl.hasError('nameRequired')">
            {{ 'errors.required' | translate }}
          </mat-error>
        </mat-form-field>
      </cog-settings-list-item-value-editor>
    </cog-settings-list-item>

    <!-- option: Scan Location -->
    <cog-settings-list-item
      *ngIf="isReplicaScansEnabled"
      [label]="'dg.scanLocation' | translate"
      cogDataId="scan-location"
      [inheritDataId]="true">
      <cog-settings-list-item-value-display>
        <mat-progress-bar mode="indeterminate" *ngIf="isClusterPreferenceLoading" class="margin-bottom">
        </mat-progress-bar>
        {{ 'dg.scanLocation.' + scanPreference | translate }}
      </cog-settings-list-item-value-display>
      <cog-settings-list-item-value-editor>
        <mat-progress-bar mode="indeterminate" *ngIf="isClusterPreferenceLoading" class="margin-bottom">
        </mat-progress-bar>
        <mat-radio-group class="flex-column-sm" [value]="scanPreference" (change)="selectScanLocation($event.value)">
          <mat-radio-button [value]="scanPreferences.scanOnLocal" cogDataId="local" [inheritDataId]="true">
            {{ 'dg.scanLocation.scanOnLocal' | translate }}
          </mat-radio-button>
          <div class="flex-column-xs">
            <span class="flex-row baseline-align">
              <mat-radio-button
                class="margin-bottom-xs"
                [value]="scanPreferences.clusterScanOrder"
                [disabled]="disableScanOnReplica || isClusterPreferenceLoading"
                cogDataId="scan-order"
                [inheritDataId]="true">
                {{ 'dg.scanLocation.clusterScanOrder' | translate }}
              </mat-radio-button>
              <a
                class="argus-card-anchor margin-left"
                cogDataId="view-scan-order"
                [inheritDataId]="true"
                (click)="openViewScanOrderDialog()">
                {{ 'dg.scanLocation.clusterScanOrder.viewScanOrder' | translate }}
              </a>
            </span>
            <small class="margin-left-lg">
              {{ 'dg.scanLocation.clusterScanOrder.helpText' | translate }}
            </small>
            <cog-banner class="margin-left-lg" status="warn" *ngIf="isClusterScanOrderOptionSelected">
              {{ 'dg.scanLocation.clusterScanOrder.warningMessage' | translate }}
            </cog-banner>
          </div>
        </mat-radio-group>
      </cog-settings-list-item-value-editor>
    </cog-settings-list-item>

    <!-- removing end detection field till GA -->
    <!-- <cog-settings-list-item [label]="'dg.td.scan.endDetectionAfter' | translate"
      cogDataId="end-detection-after" [inheritDataId]="true">
      <cog-settings-list-item-value-display>
        {{
          'dg.td.scan.iocMatches' | translate : {
            minIocCount: endDetectionRunAfter
          }
        }}
      </cog-settings-list-item-value-display>
      <cog-settings-list-item-value-editor>
        <mat-form-field>
          <mat-label>{{ 'dg.td.scan.matches' | translate }}</mat-label>
          <input
            matInput
            type="number"
            [value]="endDetectionRunAfter"
            (change)="endDetectionAfterChanged($event.target.value)"
            cogDataId="input"
            [inheritDataId]="true"/>
        </mat-form-field>
      </cog-settings-list-item-value-editor>
    </cog-settings-list-item> -->
    <!-- Removing skip files greater than until supported -->
    <!-- <cog-settings-list-item [label]="'dg.td.scan.skipFilesGreaterThan' | translate" [isReadOnly]="true"
      cogDataId="skip-files-greater-than" [inheritDataId]="true">
      <cog-settings-list-item-value-display>
        {{
          'dg.td.megabytes.defaultFileSizeLimit' | translate : {
            megaBytes: skipFilesGreaterThan
          }
        }}
      </cog-settings-list-item-value-display>
      <cog-settings-list-item-value-editor>
        <mat-form-field>
          <mat-label>{{ 'filesizeAbbrevations.megabytes' | translate }}</mat-label>
          <input
            matInput
            type="number"
            [value]="skipFilesGreaterThan"
            (change)="skipFilesGreaterThanChanged($event.target.value)"
            cogDataId="input"
            [inheritDataId]="true"/>
        </mat-form-field>
      </cog-settings-list-item-value-editor>
    </cog-settings-list-item> -->
  </cog-settings-list>
</ng-template>
