import { ChangeDetectionStrategy, Component, forwardRef } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { DcScan, DlpApiService, Policy, PolicyId, PolicyList, ResourceType } from '@cohesity/api/argus';
import { HasCustomRBACPermissions } from '@cohesity/data-govern/shared';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { FormSectionComponent } from '@cohesity/shared-forms';
import { AjaxHandlerService } from '@cohesity/utils';
import { finalize, tap } from 'rxjs/operators';

import { PoliciesDialogService } from '../../policies';
import { DcScanFormModel, FormSectionName } from '../dc-scan.model';

const getDefaultPolicy = (policies: PolicyList) =>
  (policies || []).find(({ name, buildType }) => name === 'Base patterns' && buildType === ResourceType.builtIn);

@Component({
  selector: 'dg-dc-scan-select-policy',
  templateUrl: './select-policy.component.html',
  styleUrls: ['./select-policy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: FormSectionComponent,
      useExisting: forwardRef(() => SelectPolicyComponent),
    },
  ],
})
export class SelectPolicyComponent extends FormSectionComponent<PolicyId, DcScanFormModel, DcScan> {
  formSectionName = 'policy' as FormSectionName;

  isLoading = false;

  policies: PolicyList;

  /**
   * FormControl for managing the MatSelect.
   */
  policySelector = new FormControl<Policy>(null);

  /**
   * Allows create policy flow based on feature flag
   */
  get allowCreateNewPolicy(): boolean {
    return flagEnabled(this.irisCtx.irisContext, 'dataHawkDataClassificationCreatePoliciesEnabled');
  }

  /**
   * true, if user has policy manage permissions.
   */
  get hasPolicyManagePermission(): boolean {
    return HasCustomRBACPermissions([
      'DGAAS_MANAGE_DC_POLICY',
      // for backward compatibility
      'DGAAS_MODIFY',
    ], this.irisCtx.irisContext);
  }

  constructor(
    private ajaxHandler: AjaxHandlerService,
    private dlpApiService: DlpApiService,
    private irisCtx: IrisContextService,
    private policiesDialogService: PoliciesDialogService,
  ) {
    super();
  }

  initFormSection() {
    super.initFormSection();
    this.formControl.addValidators(Validators.required);
    this.formControl.updateValueAndValidity();

    this.getData()
      .pipe(
        this.untilDestroy(),
        tap(() => {
          const policyId = this.fromDataModel(this.builder.dataModel);

          if (policyId) {
            const selectedPolicy = this.policies.find(policy => policy.id === policyId) || undefined;

            if (selectedPolicy) {
              this.selectPolicy(selectedPolicy);
              this.updateValue();
            }
          }
        }),
      )
      .subscribe({
        error: this.ajaxHandler.handler,
      });
  }

  toDataModel(): Partial<DcScan> {
    return { policyId: this.builder.formGroup.value.policy };
  }

  fromDataModel(dataModel: DcScan): PolicyId {
    return dataModel?.policyId;
  }

  getData() {
    this.isLoading = true;
    // this.cdr.detectChanges();
    return this.dlpApiService.getPolicies().pipe(
      this.untilDestroy(),
      finalize(() => {
        this.isLoading = false;
        // this.cdr.detectChanges();
      }),
      tap(({ policies }) => {
        this.policies = policies;
        if (!this.allowCreateNewPolicy && this.policies.length && !this.policySelector.value) {
          const defaultPolicy = getDefaultPolicy(this.policies);

          if (defaultPolicy) {
            this.selectPolicy(defaultPolicy);
            this.updateValue();
          }
        }
      }),
    );
  }

  updateValue() {
    this.next(this.policySelector.value.id);
  }

  selectPolicy(newPolicy: Policy) {
    this.policySelector.setValue(newPolicy);
  }

  editPolicy() {
    this.formSectionViewCta.viewEditMode();
  }

  /**
   * callback for create new policy action of the policy selector. This opens up the policy dialog and selects
   * the newly created policy
   */
  createNewPolicy() {
    const dialogRef = this.policiesDialogService.launchPolicyModifyDialog();

    dialogRef.afterClosed().pipe(this.untilDestroy()).subscribe(result => {
      // If the dialog is cancelled then do nothing else update the source list.
      if (!result) {
        return;
      }

      // refresh the policy data
      this.getData().pipe(
        this.untilDestroy(),
      ).subscribe(() => {
        // select the newly created policy
        this.selectPolicy(result);
        this.updateValue();
      }, this.ajaxHandler.handler);
    });
  }
}
