import { AppType, PillarType } from '../app-pillars/app-pillars.model';
import { IrisContext } from '../iris-context';

/**
 * The app service context matcher function
 */
export type CanAccessApp = (irisContext: IrisContext) => boolean;

/**
 * App internal name.
 */
export type AppName =
  | 'applianceManager'
  | 'clusterManager'
  | 'dataProtect'
  | 'fortKnox'
  | 'gaia'
  | 'global'
  | 'platformInsights'
  | 'securityCenter'
  | 'siteContinuity'
  | 'siteContinuityV2'
  | 'sizer'
  | 'smartFiles'
  ;

/**
 * Scope name which includes cluster scope as well.
 */
export type ScopeName = AppName | 'singleCluster' | 'dataCloud';

/**
 * Plugin name.
 */
export type PluginName =
  | 'aiBotPluginConfig'
  | 'argusRecoveryPluginConfig'
  | 'turingPluginConfig'
  ;

/**
 * App UMD library exported global variable name.
 */
export type LibName =
  | '@cohesity/ai-bot-plugin'
  | '@cohesity/argus-recovery-plugin'
  | '@cohesity/argus'
  | '@cohesity/cluster-manager'
  | '@cohesity/data-protect'
  | '@cohesity/fort-knox'
  | '@cohesity/gaia'
  | '@cohesity/global'
  | '@cohesity/site-continuity-v2'
  | '@cohesity/site-continuity'
  | '@cohesity/sizer'
  | '@cohesity/smartfiles'
  | '@cohesity/appliance-manager'
  | '@cohesity/turing-plugin'
  ;

/**
 * These are the entitlement keys embedded in the
 * subscription information returned from the
 * crm service for each app
 */
export enum EntitlementKeys {
  Classification = 'classification',
  DataProtect = 'dataProtect',
  DataProtectAzure = 'dataProtectAzure',
  FortKnoxAzureCool = 'fortKnoxAzureCool',
  FortKnoxAzureHot = 'fortKnoxAzureHot',
  FortKnoxCold = 'fortKnoxCold',
  GaiaIndexStorages = 'gaiaIndexStorages',
  Llms = 'llms',
  Ransomware = 'ransomware',
  SiteContinuityInfo = 'siteContinuity',
  ThreatProtection = 'threatProtection',
}

export interface AppSwitcherConfig<T> {
  /**
   * The app name key.
   */
  name: T;

  /**
   * The url segment where the app UI is activated.
   */
  urlSegment?: string;

  /**
   * The map of url segments if the app has multiple entry pahts.
   */
  urlSegmentsMap?: { [key: string]: string };

  /**
   * The optional params passed when navigated to app.
   */
  params?: any;

  /**
   * Function responsible for transitioning the user to the app.
   */
  go?: () => void;

  /**
   * The app icon.
   */
  icon?: string;

  /**
   * Indicates if the service/app is currently active.
   */
  isActive?: boolean;

  /**
   * Which pillar this app belongs to. This will determine which pillar icon to use.
   */
  pillar?: PillarType;

  /**
   * Which app type this app belongs to.
   */
  app?: AppType;

  /**
   * Which entitlements grant access to this app
   */
  entitlements?: EntitlementKeys[];
}

export interface AppConfig extends AppSwitcherConfig<AppName | PluginName> {
  /**
   * App UMD library exported global variable name.
   */
  libName: LibName;

  /**
   * Path to App UMD modules main.js file.
   */
  publicPath?: string;

  /**
   * The app root container class name.
   */
  rootContainerClassName: string;
}
