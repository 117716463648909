<h3 class="popover-header-title" translate>objectDetails</h3>
<div class="popover-section-title" translate>generalInformation</div>
<ul class="c-ul clean mat-small popover-section-ul">
  <li *ngIf="!isDmsScope() && object?.protectionGroupName">
    {{ 'protectionGroup' | label:
      (object.protectionGroupName || ('naNotAvailable' | translate))
    }}
  </li>
  <li>
    {{ 'recoveredFrom' | label:
      ((targetType ? 'enum.snapshotTargetType.' + targetType : 'naNotAvailable') | translate)
    }}
  </li>
  <li *ngIf="object.archivalTargetInfo">
    {{ 'targetName' | label:
      object.archivalTargetInfo?.targetName || ('naNotAvailable' | translate)
    }}
  </li>
  <li *ngIf="object.sourceName">
    {{ 'parentSource' | label: object.sourceName }}
  </li>
  <li *ngIf="object.bytesRestored">
    {{ 'size' | label: (object.bytesRestored | byteSize) }}
  </li>
</ul>
