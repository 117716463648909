import { envGroups, Environment } from 'src/app/shared';

/**
 * Protection group router states constants.
 */
export namespace ProtectionStates {
  /**
   * Root/parent state for protection group pages.
   */
  export const groupRoot = 'protection-group';

  /**
   * Protection groups (list) page state.
   */
  export const groupList = `${groupRoot}.groups`;

  /**
   * Protection group parent page state.
   */
  export const groupParent = `${groupRoot}.group`;

  /**
   * Protection group details page state.
   */
  export const groupDetails = `${groupParent}.details`;

  /**
   * Protection group audit page state.
   */
  export const groupAudit = `${groupParent}.audit`;

  /**
   * Protection group settings page state.
   */
  export const groupSettings = `${groupParent}.settings`;

  /**
   * Protection group cdp page state.
   */
  export const groupCdp = `${groupParent}.cdp`;

  /**
   * Root/parent state for protection run page.
   */
  export const runParent = `${groupRoot}.run`;

  /**
   * Protection run backup page state.
   */
  export const runBackup = `${runParent}.backup`;

  /**
   * Protection run replication page state.
   */
  export const runReplication = `${runParent}.replication`;

  /**
   * Protection run replication page state.
   */
  export const runVaultCluster = `${runParent}.vaultCluster`;

  /**
   * Protection run cloud archive page state.
   */
  export const runArchive = `${runParent}.archive`;

  /**
   * Protection run cloud archive page state.
   */
  export const runCloudVault = `${runParent}.cloudvault`;

  /**
   * Protection run cloud archive page state.
   */
  export const runTape = `${runParent}.tape`;

  /**
   * Protection run cloud archive page state.
   */
  export const runIndexing = `${runParent}.indexing`;

  /**
   * Protection run CloudSpin page state.
   */
  export const runCloudspin = `${runParent}.cloudspin`;

  /**
   * Protection run CloudSpin page state.
   */
  export const runDirectArchive = `${runParent}.direct-archive`;
}

/**
 * Environment types that needs to be included here
 * if we want to check for settings environment as protection type
 */
export const environmentTypes: Environment[] = [
  Environment.kRDSSnapshotManager,
  Environment.kAuroraSnapshotManager,
];

/**
 * Settings state configs for protection groups that determine which environments have
 * ng settings groups available to them or not.
 */
export const protectionGroupSettingsStates = [
  {
    environments: envGroups.vms,
    featureFlag: 'ngProtectionGroupVmSettings',
    componentId: 'vm-protection-group-settings',
  },
  {
    environments: environmentTypes,
    featureFlag: 'ngProtectionGroupAwsRdsSettings',
    componentId: 'aws-rds-protection-group-settings',
  },
  {
    environments: envGroups.s3,
    featureFlag: 's3Protection',
    componentId: 'aws-s3-object-protection-settings',
  },
  {
    environments: envGroups.nas,
    featureFlag: 'ngProtectionGroupNasSettings',
    componentId: 'nas-protection-group-settings',
  },
  {
    environments: [
      Environment.kCassandra,
      Environment.kHBase,
      Environment.kHdfs,
      Environment.kHive,
      Environment.kMongoDB,
    ],
    featureFlag: 'ngProtectionGroupNosqlSettings',
    componentId: 'nosql-protection-group-settings',
  },
  {
    environments: [Environment.kSQL],
    featureFlag: 'ngProtectionGroupSqlSettings',
    componentId: 'sql-protection-group-settings',
  },
  {
    environments: [Environment.kAD],
    featureFlag: 'ngProtectionGroupAdSettings',
    componentId: 'ad-protection-group-settings',
  },
  {
    environments: [Environment.kExchange],
    featureFlag: 'ngProtectionGroupExchangeSettings',
    componentId: 'exchange-protection-group-settings',
  },
  {
    environments: [Environment.kPhysical, Environment.kPhysicalFiles],
    featureFlag: 'ngProtectionGroupPhysicalSettings',
    componentId: 'physical-protection-group-settings',
  },
  {
    environments: [Environment.kOracle],
    featureFlag: 'ngProtectionGroupOracleSettings',
    componentId: 'oracle-protection-group-settings',
  },
  {
    environments: [Environment.kO365],
    featureFlag: 'ngProtectionGroupOffice365Settings',
    componentId: 'office365-protection-group-settings',
  },
  {
    environments: [Environment.kKubernetes],
    featureFlag: 'ngProtectionGroupKubernetesSettings',
    componentId: 'kubernetes-protection-group-settings',
  },
  {
    environments: [Environment.kRemoteAdapter],
    featureFlag: 'ngProtectionGroupRemoteAdapterSettings',
    componentId: 'remote-adapter-protection-group-settings',
  },
  {
    environments: [Environment.kView],
    featureFlag: 'ngProtectionGroupViewSettings',
    componentId: 'view-protection-group-settings',
  },
  {
    environments: [Environment.kPure],
    featureFlag: 'ngProtectionGroupPureSettings',
    componentId: 'pure-protection-group-settings',
  },
  {
    environments: [Environment.kIbmFlashSystem],
    featureFlag: 'ibmFlashSystemEnabled',
    componentId: 'pure-protection-group-settings',
  },
  {
    environments: [Environment.kUDA],
    featureFlag: 'ngProtectionGroupUdaSettings',
    componentId: 'uda-protection-group-settings',
  },
  {
    environments: [Environment.kSAPHANA],
    featureFlag: 'sapHanaEnabledDms',
    componentId: 'saphana-protection-group-settings',
  },
  {
    environments: [Environment.kAzureSQL],
    featureFlag: 'ngProtectionGroupAzureSqlModify',
    componentId: 'azure-sql-protection-group-settings',
  },
  {
    environments: [Environment.kAwsDynamoDB],
    featureFlag: 'awsDynamoDbEnabled',
    componentId: 'aws-db-protection-group-settings',
  },
];
