import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Policy } from '@cohesity/api/argus';

import { PolicyApplyDialogComponent } from './policy-apply-dialog/policy-apply-dialog.component';
import { PolicyDeleteDialogComponent } from './policy-delete-dialog/policy-delete-dialog.component';
import { PolicyModifyDialogComponent } from './policy-modify-dialog/policy-modify-dialog.component';

/**
 * Policies dialog service.
 */
@Injectable({
  providedIn: 'root',
})
export class PoliciesDialogService {
  constructor(private dialog: MatDialog) {}

  /**
   * Launch the policy modify dialog.
   *
   * @param policy The policy to edit.
   */
  launchPolicyModifyDialog(policy?: Policy): MatDialogRef<PolicyModifyDialogComponent, Policy> {
    const config = { data: { policy } };

    return this.dialog.open(PolicyModifyDialogComponent, config);
  }

  /**
   * Launch the policy delete dialog.
   *
   * @param policy The policy to edit.
   * @returns policy details
   */
  launchPolicyDeleteDialog(policy?: Policy): MatDialogRef<PolicyDeleteDialogComponent> {
    const config = { data: { policy } };

    return this.dialog.open(PolicyDeleteDialogComponent, config);
  }

  /**
   * Launch the apply policy dialog.
   *
   * @param policy added as parameter for edit
   */
  launchPolicyApplyDialog(policy?: Policy): MatDialogRef<PolicyApplyDialogComponent> {
    const config = { data: { policy } };

    return this.dialog.open(PolicyApplyDialogComponent, config);
  }
}
