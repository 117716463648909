<mat-form-field
  [class.loading]="loading"
  [class.mat-form-field-invalid]="control.dirty && control.errors"
  [class.readonly]="readonly"
  [hideRequiredMarker]="hideRequiredMarker"
  [floatLabel]="floatLabel">
  <mat-label>{{label}}</mat-label>
  <cog-spinner *ngIf="loading"
    [inline]="true"
    matPrefix
    size="xs">
  </cog-spinner>
  <mat-select
    (openedChange)="openedChange($event)"
    [formControl]="control"
    [inheritDataId]="true"
    [multiple]="multiple"
    [required]="required"
    [tabIndex]="readonly ? -1 : 0"
    cogDataId="select"
    panelClass="cog-mat-select-panel-lg">
    <mat-select-trigger *ngIf="control.value"
      [inheritDataId]="true"
      cogDataId="value">
      <!-- If a custom trigger template is not provided, default to using
        to the option value to show selected value trigger label. -->
      <ng-container *ngIf="!multiple">
        <div
          [cogDataId]="getOptionString(control.value) || 'selected'"
          [inheritDataId]="true">
          <ng-container *ngTemplateOutlet="triggerTemplate || optionKeyTemplate; context: { $implicit: control.value }">
          </ng-container>
        </div>
      </ng-container>

      <ng-container *ngIf="multiple">
        <mat-chip-list [inheritDataId]="true" cogDataId="value-list">
          <mat-chip *ngFor="let item of control.value; index as index"
            (removed)="removeAt(index)"
            [cogDataId]="'selected-' + (getOptionString(item) || index)"
            [inheritDataId]="true"
            [removable]="true"
            [selectable]="true">
            <span class="chip-label">
              <ng-container *ngTemplateOutlet="triggerTemplate || optionKeyTemplate; context: { $implicit: item }">
              </ng-container>
            </span>

            <!-- Mat Icon aligns better inside of mat-chip then cog-icon does -->
            <mat-icon matChipRemove>{{ removeIcon }}</mat-icon>
          </mat-chip>
        </mat-chip-list>
      </ng-container>
    </mat-select-trigger>

    <!-- Show search filter unless disabled -->
    <mat-option *ngIf="searchEnabled"
      [inheritDataId]="true"
      cogDataId="option-search">
      <ngx-mat-select-search
        [formControl]="searchCtrl"
        [noEntriesFoundLabel]="loading ? '' : ('noMatchingItems' | translate)"
        [placeholderLabel]="searchPlaceholderLabel"
        [searching]="loading">
      </ngx-mat-select-search>
    </mat-option>

    <!-- If the field is not required, selecting this value will clear the
      select field. This is not needed for multi select as values in those can
      be unselected since it uses checkboxes. -->
    <mat-option *ngIf="!required && !multiple && showNoneOption" [inheritDataId]="true" cogDataId="option-none">
      {{'none' | translate}}
    </mat-option>

    <!-- Use virtual scroll to handle scale issues within source selector -->
    <cdk-virtual-scroll-viewport itemSize="50"
      class="virtual-scroll-container"
      minBufferPx="200"
      maxBufferPx="400">
      <mat-option #option
        *cdkVirtualFor="let value of values$ | async; index as index"
        [cogDataId]="'option-' + (getOptionString(value) || index)"
        [disabled]="optionDisabled(option)"
        [inheritDataId]="true"
        [value]="value"
        (onSelectionChange)="onSelectionChange($event)">
        <!-- Used passed template to render the option, otherwise the option
          key value -->
        <ng-container *ngTemplateOutlet="optionTemplate || optionKeyTemplate; context: { $implicit: value }">
        </ng-container>
      </mat-option>

      <!-- This is needed with virtual scrolling as the active rendered
        options may not include the currently selected option, so if a value
        is selected, add it again here, but keep it hidden. -->
      <mat-option #option
        *ngIf="control.value"
        [cogDataId]="'option-' + (getOptionString(control.value) || -1)"
        [inheritDataId]="true"
        [value]="control.value"
        class="hidden">
        <!-- Used passed template to render the option, otherwise the option
          key value -->
        <ng-container *ngTemplateOutlet="optionTemplate || optionKeyTemplate; context: { $implicit: control.value }">
        </ng-container>
      </mat-option>

      <mat-option *ngIf="(values$ | async)?.length && additionalInfoTemplate"
        [inheritDataId]="true"
        cogDataId="option-additional-info">
        <ng-container *ngTemplateOutlet="additionalInfoTemplate">
        </ng-container>
      </mat-option>

      <!-- This is needed to have an add option to the form field. This will be
        enabled if addNewEnable boolean is passed in as true by the parent
        component. It's general use case can be to emit an event to parent
        component on clicking of add button and opening up a dialog box to handle
        any further processing required-->
      <mat-option #add
        (click)="!add.disabled && addItem()"
        *ngIf="addNewEnable">
        <coh-add-item-option>
          {{addButtonLabel | translate}}
        </coh-add-item-option>
      </mat-option>
    </cdk-virtual-scroll-viewport>
  </mat-select>
  <cog-icon matSuffix *ngIf="suffixIcon"
    [shape]="suffixIcon">
  </cog-icon>
  <mat-error *ngIf="control.errors?.required">{{'errors.required' | translate}}</mat-error>
  <mat-error *ngIf="control.errors?.duplicate">{{'errors.duplicateEntry' | translate}}</mat-error>
  <mat-hint *ngIf="hint"> {{ hint }}</mat-hint>
</mat-form-field>

<ng-template #optionKeyTemplate let-value>
  {{getOptionString(value)}}
</ng-template>
