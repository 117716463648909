import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { ProtectionGroupAlertingPolicy } from '@cohesity/api/v2';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { LocaleService } from 'src/app/core/services';
import { Locale } from 'src/app/models';

import { AlertingPolicy } from '../../../models/protection-group-object.model';
import { BaseProtectionBuilderComponent } from '../../base-protection-builder/base-protection-builder.component';

/**
 * Default value for backup run status.
 */
export const DefaultBackupRunStatus = ['kFailure'];

/**
 * Component for defining the alerting policy for a protection group. This
 * component allows the user to select combinations of run failure, success,
 * and/or sla violation and magneto will throw an alert on the specified events.
 * Additional, email addresses can be added and emails will be sent on such
 * events.
 */
@Component({
  selector: 'coh-settings-list-alerts',
  templateUrl: './settings-list-alerts.component.html',
  styleUrls: ['./settings-list-alerts.component.scss'],
})
export class SettingsListAlertsComponent
  extends BaseProtectionBuilderComponent<ProtectionGroupAlertingPolicy, any>
  implements OnInit {

  /**
   * Check if localization is enabled or not.
   */
  get localizationEnabled() {
    return flagEnabled(this.irisCtx.irisContext, 'localizationEnabled');
  }

  /**
   * Internal FormGroup for managing time and timezone values.
   */
  private alertingPolicyFormGroup: UntypedFormGroup = new UntypedFormGroup({
    backupRunStatus: new UntypedFormControl(DefaultBackupRunStatus),
    alertTargets: new UntypedFormArray([]),
  });

  /**
   * Possible options for selection.
   */
  statusOptions: AlertingPolicy = ['kSlaViolation', 'kFailure', 'kSuccess'];

  // List of possible recipient types.
  recipientTypes = ['kTo', 'kCc'];

  /**
   * Variable to store the current user locale.
   */
  locale: Locale;

  constructor(private localeService: LocaleService,
    private irisCtx: IrisContextService) {
    super();
  }

  /**
   * Component Init.
   */
  ngOnInit() {
    this.localeService.getUserLocale()
      .pipe(this.untilDestroy())
      .subscribe(locale => {
        this.locale = locale;
      });

      if (flagEnabled(this.irisCtx.irisContext, 'enableAlertsForWarning')) {
        this.statusOptions.push('kWarning');
      }
  }

  /**
   * Override of addFormControl from BaseProtectionBuilderComponent so this component's FormControl isn't required.
   */
  addFormControl() {
    // If this component is used as a standalone component, then update the
    // default value from the formControl and replace that formControl.
    if (this.properties?.standalone) {
      this.setDefaultValues(this.formControl.value);
      this.formGroup.removeControl(this.name);
    }
    this.formGroup.addControl(this.name, this.alertingPolicyFormGroup);
  }

  /**
   * Function to add a new item to the email alerts list.
   *
   * @param   type   One of 'include' or 'exclude'.
   * @param   value   Default value for the path
   * @param   locale  The locale for the email recipient.
   */
  addAlertTarget(value = '', recipientType = 'kTo', locale = this.locale) {
    const target = new UntypedFormGroup({
      emailAddress: new UntypedFormControl(value, Validators.email),

      // Recipient type of email recipient.
      recipientType: new UntypedFormControl(recipientType, Validators.required),

      // Update the user locale as without it, the email will be saved with
      // backend default "en-us" locale.
      language: new UntypedFormControl(locale),
    });

    (this.alertingPolicyFormGroup.get('alertTargets') as UntypedFormArray).push(target);
  }

  /**
   * Returns FormControls[] from alertTargets FormArray.
   */
  get alertTargets(): AbstractControl[] {
    return (this.alertingPolicyFormGroup.get('alertTargets') as UntypedFormArray).controls;
  }

  /**
   * Function to remove a form control item from the alert targets list.
   *
   * @param   index   Index of the item to be removed.
   */
  removeAlertTarget(index) {
    (this.alertingPolicyFormGroup.get('alertTargets') as UntypedFormArray).removeAt(index);
  }

  /**
   * Form control init.
   */
  initFormControl() {
    if (this.protectionGroup && this.protectionGroup.id) {
      if (this.protectionGroup.alertingPolicy) {
        this.setDefaultValues(this.protectionGroup.alertingPolicy);
      } else {
        // The protection group has no alerting policy, and therefore no values
        // should be set in the form, but we still want to maintain an empty array.
        this.alertingPolicyFormGroup.controls.backupRunStatus.setValue([]);
      }
    }
  }

  /**
   * Set the default values to the form.
   *
   * @param param the Alerting policy
   */
  setDefaultValues({backupRunStatus, alertTargets = []}) {
    this.alertingPolicyFormGroup.controls.backupRunStatus.setValue(backupRunStatus);

    alertTargets.forEach(target => {
      this.addAlertTarget(target.emailAddress, target.recipientType, target.language);
    });
  }

  /**
   * Handles the selection of alerts for protection group.
   *
   * @param event  Mat Checkbox change event
   * @param option The selected option.
   */
  handleAlertSelection(event: MatCheckboxChange, option: string) {
    let alerts = this.formControl.value.backupRunStatus || [];
    if (event.checked) {
      alerts.push(option);
    } else  {
      alerts = alerts.filter(al => al !== option);
    }

    this.formControl.get('backupRunStatus').setValue(alerts);
    this.formControl.updateValueAndValidity();
  }
}
