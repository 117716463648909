import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { IrisContextService } from '@cohesity/iris-core';

import {
  BaseProtectionBuilderComponent,
} from '../../../../protection-builder/components/base-protection-builder/base-protection-builder.component';

interface SanTransport {
  sanTransportEnabled: boolean;
  allowNbdsslTransportFallback: boolean;
}

/**
 * Default value for storage snapshot.
 */
export const DefaultLeverageSanTransport: SanTransport = {
  sanTransportEnabled: false,
  allowNbdsslTransportFallback: true,
};

@Component({
  selector: 'coh-settings-list-leverage-san-transport',
  templateUrl: './settings-list-leverage-san-transport.component.html',
  styleUrls: ['./settings-list-leverage-san-transport.component.scss']
})
export class SettingsListLeverageSanTransportComponent extends
  BaseProtectionBuilderComponent<SanTransport, any> {
  /**
   * Default abort in blackouts value.
   */
  _value: SanTransport = DefaultLeverageSanTransport;
  /**
   * Flag indicating if san transport toggle is disabled
   */
  isDisabled = false;

  /**
   * Indicates if multitenancy is enabled.
   */
  multiTenancyEnabled = false;

  /**
   * Form group for storage snapshots settings.
   */
  sanTransportFormGroup = new UntypedFormGroup({
    sanTransportEnabled: new UntypedFormControl(this.value.sanTransportEnabled, Validators.required),
    allowNbdsslTransportFallback: new UntypedFormControl(this.value.allowNbdsslTransportFallback, Validators.required),
  });

  get showAllowNbdsslTransportFallback(): boolean {
    return this.sanTransportFormGroup.get('sanTransportEnabled').value;
  }

  constructor(private irisCtx: IrisContextService) {
    super();
  }

  /**
   * Add the leverage SAN transport setting form group as control.
   */
  addFormControl() {
    this.formGroup.addControl(this.name, this.sanTransportFormGroup);
  }

  /**
   * This returns the info msg in case the toggle is disabled as storage snapshot is enabled.
   */
  getDisabledInfoMsg(): string{
    return 'storageOrSanDisableInfoMsg';
  }

  /**
   * Form control init.
   */
  initFormControl() {
      // Check if multitenancy is enabled
      this.multiTenancyEnabled = this.irisCtx.irisContext.clusterInfo.multiTenancyEnabled;

      if (this.protectionGroup?.id) {
        this.value.sanTransportEnabled = Boolean(this.protectionGroup.leverageSanTransport);
        this.value.allowNbdsslTransportFallback = Boolean(this.protectionGroup.allowNbdsslTransportFallback);
      }
      this.formControl.setValue(this.value);

      this.sanTransportFormGroup.get('sanTransportEnabled').valueChanges.subscribe((value)=>{
        if(!value){
          this.sanTransportFormGroup.get('allowNbdsslTransportFallback').setValue(false);
        }
      });

      const leverageStorageSnapshotControl = this.formGroup.controls.leverageStorageSnapshots.get('enabled');

      // Check for default value
      if(leverageStorageSnapshotControl.value){
        this.sanTransportFormGroup.get('sanTransportEnabled').setValue(false);
        this.sanTransportFormGroup.get('allowNbdsslTransportFallback').setValue(false);
        this.sanTransportFormGroup.disable();
        this.isDisabled  = true;
      }
      leverageStorageSnapshotControl.valueChanges.subscribe((value)=>{
        if(value){
          this.sanTransportFormGroup.get('allowNbdsslTransportFallback').setValue(false);
          this.sanTransportFormGroup.disable();
          this.isDisabled  = true;
        } else{
          this.sanTransportFormGroup.enable();
          this.isDisabled  = false;
        }
      });
  }
}
