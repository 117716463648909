import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Api } from '@cohesity/api/private';
import { NodesServiceApi } from '@cohesity/api/v1';
import { ClusterIpmiLanInfo, ClusterIpmiUsers, Node, NodeIpmiInfoEntry, NodeIpmiUser, PlatformServiceApi } from '@cohesity/api/v2';
import { IrisContextService, flagEnabled } from '@cohesity/iris-core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AsyncBehaviorSubject, executeWithFallback, updateWithStatus } from 'src/app/util';

import { ClusterDataNode } from '../../shared/cluster-node-tree';
import { IpmiClusterNetworkingInfo, IpmiClusterNodesContext, IpmiNodeNetworkingInfo, IpmiUserClusterInfo, IpmiUserNodeInfo } from './models';

/**
 * Used to get and update ipmi settings at node level and cluster level.
 * It also includes fetchClusterTreeNodes() which fetches cluster nodes and transforms them
 * to ClusterDataNodes. This can be moved to appropriate service in future based on usage.
 */
@Injectable({
  providedIn: 'root'
})
export class IpmiService {
  /**
   * AsyncBehavior subject to hold transformed nodes.
   */
  transformedNodes$ = new AsyncBehaviorSubject<IpmiClusterNodesContext>();

  /**
   * Constructor.
   */
  constructor(
    private http: HttpClient,
    private irisContextService: IrisContextService,
    private nodesServiceApi: NodesServiceApi,
    private platformServiceApi: PlatformServiceApi,
  ) {}

  /**
   * Get cluster ipmi networking info.
   */
  getIpmiLanInfo(): Observable<ClusterIpmiLanInfo> {
    const shouldUseV2 = flagEnabled(this.irisContextService.irisContext, 'platformBucket2v2ApiMigration');
    const apiCallV1 = () => (this.http.get<any>(Api.private('nexus/ipmi/cluster_get_lan_info')) as Observable<IpmiClusterNetworkingInfo>).pipe(
      map((v1Response: IpmiClusterNetworkingInfo) => {
        // Convert v2 response to existing v1 model to avoid code refactoring
        const v2Response: ClusterIpmiLanInfo = {
          clusterIpmiGateway: v1Response.ipmiGateway,
          clusterIpmiSubnetMask: v1Response.ipmiSubnetMask,
          nodeIpmiEntries: (v1Response.nodesIpmiInfo || []).map((v1Val: IpmiNodeNetworkingInfo) => {
            const v2Val: NodeIpmiInfoEntry = {
              nodeIp: v1Val.nodeIp,
              nodeIpmiIp: v1Val.nodeIpmiIp,
              nodeIpmiSubnetMask: v1Val.nodeIpmiSubnetMask,
              nodeIpmiGateway: v1Val.nodeIpmiGateway
            };
            return v2Val;
          })
        };
        return v2Response;
      })
    );
    const apiCallV2 = () => this.platformServiceApi.GetClusterIpmiLanInfo();
    return shouldUseV2 ? executeWithFallback<ClusterIpmiLanInfo>(apiCallV2, apiCallV1) : apiCallV1();
  }

  /**
   * Get cluster ipmi users info.
   */
  getIpmiClusterUsers(): Observable<ClusterIpmiUsers> {
    const shouldUseV2 = flagEnabled(this.irisContextService.irisContext, 'platformBucket2v2ApiMigration');
    const apiCallV1 = () => (this.http.get<any>(Api.private('nexus/ipmi/cluster_list_users')) as Observable<IpmiUserClusterInfo>).pipe(
      map((v1Response: IpmiUserClusterInfo) => {
        // Convert v2 response to existing v1 model to avoid code refactoring
        const v2Response: ClusterIpmiUsers = {
          clusterIpmiUsername: v1Response.clusterIpmiUser,
          nodeIpmiUsers: (v1Response.nodeIpmiUsers || []).map((v1Val: IpmiUserNodeInfo) => {
            const v2Val: NodeIpmiUser = {
              ipmiUsername: v1Val.ipmiUser,
              nodeIp: v1Val.nodeIp,
            };
            return v2Val;
          })
        };
        return v2Response;
      })
    );
    const apiCallV2 = () => this.platformServiceApi.GetClusterIpmiUsers();
    return shouldUseV2 ? executeWithFallback<ClusterIpmiUsers>(apiCallV2, apiCallV1) : apiCallV1();
  }

  /**
   * Update ipmi users info.
   */
  updateIpmiUsersInfo(ipmiUserInfo: IpmiUserClusterInfo) {
    return this.http.put<any>(Api.private('nexus/ipmi/cluster_update_users'), ipmiUserInfo);
  }

  /**
   * Update ipmi networking info.
   */
  updateIpmiLanInfo(lanInfo: IpmiClusterNetworkingInfo) {
    return this.http.put<any>(Api.private('nexus/ipmi/cluster_update_lan_info'), lanInfo);
  }

  /**
   * Get cluster tree nodes and update transformedNodes$ subject.
   */
  fetchClusterTreeNodes() {
    const shouldUseV2 = flagEnabled(this.irisContextService.irisContext, 'platformBucket2v2ApiMigration');
    const fetchNodesV1 = () => this.nodesServiceApi.GetNodes({}) as unknown as Observable<Node[]>;
    const fetchNodesV2 = () => this.platformServiceApi.GetNodes({});
    const fetchNodes$ = shouldUseV2 ? executeWithFallback<Node[]>(fetchNodesV2, fetchNodesV1) : fetchNodesV1();
    fetchNodes$
      .pipe(map(this.sortNodesBySlotNumber), map(this.transformNodes), updateWithStatus(this.transformedNodes$))
      .subscribe();
  }

  /**
   * Transform nodes api response to cluster tree data nodes.
   *
   * @param    nodes List of nodes info fetched from api.
   * @returns  IpmiClusterNodesContext object which contains cluster tree nodes and nodes map.
   */
  private transformNodes(nodes: Node[]): IpmiClusterNodesContext {
    const nodesMap = new Map<string, ClusterDataNode>();
    const nodeIdToNodeIpMap = new Map<number, string>();
    for (const node of nodes) {
      nodeIdToNodeIpMap[node.id] = node.ip;
      const chassisInfo = node.chassisInfo;
      const chassisId = chassisInfo.chassisId.toString();
      if (!nodesMap.has(chassisId)) {
        // Parent node.
        const parentNode: ClusterDataNode = {
          id: chassisId,
          serialNumber: chassisId,
          data: {
            // Todo(Sudeep) Change this to product model once iris changes are done.
            chassisModel: chassisInfo.chassisName,
          },
          nodes: []
        };
        nodesMap.set(chassisId, parentNode);
      }

      // Leaf node.
      const leafNode: ClusterDataNode = {
        id: node.id.toString(),
        data:  {
          ip: node.ip,
          slotNumber: node.slotNumber,
        }
      };

      // Get from nodesMap and append node to child nodes.
      const chassisNode = nodesMap.get(chassisId);
      chassisNode.nodes.push(leafNode);
    }
    return {
      clusterDataNodes: Array.from(nodesMap.values()),
      nodeIdToNodeIpMap: nodeIdToNodeIpMap
    };
  }

  /**
   * Sort nodes in increasing order of slot number.
   *
   * @param    nodes nodes array to be sorted.
   * @returns  Node[] Sorted array of nodes.
   */
  private sortNodesBySlotNumber(nodes: Node[]): Node[] {
    nodes.sort((node1, node2) => (node1.slotNumber - node2.slotNumber));
    return nodes;
  }
}
