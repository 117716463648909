import { zipWith } from 'lodash-es';
import { each } from 'lodash-es';
import { merge } from 'lodash-es';
import { last } from 'lodash-es';
import { filter } from 'lodash-es';
import { find } from 'lodash-es';
import { cloneDeep } from 'lodash-es';
import { clone } from 'lodash-es';
import { isEmpty } from 'lodash-es';
import { get } from 'lodash-es';
import { assign } from 'lodash-es';
import { sanitizeParameters } from '@cohesity/utils';
import { isEntityOwner } from '@cohesity/iris-core';

// Service: VM Restore and Clone Service
;(function(angular, undefined) {
  'use strict';

  var moduleDependencies = ['C.restoreServiceFormatter', 'C.slideModal'];

  angular.module('C.restoreService', moduleDependencies)
    .service('RestoreService', RestoreServiceFn);

  function RestoreServiceFn(_, $rootScope, $http, $httpParamSerializer, $window,
    $q, evalAJAX, $interpolate, cModal, SourceService, DateTimeService,
    RestoreServiceFormatter, RemoteAccessClusterService, ENV_TYPE_CONVERSION,
    ENUM_RESTORE_FILE_STATUS, API, ENV_GROUPS, ENUM_ENV_TYPE, JobRunsService,
    ENUM_RESTORE_TYPE, SlideModalService, $state, FEATURE_FLAGS, JobService,
    cMessage, ENUM_ICON_TYPE_MAPPING, PubRestoreServiceFormatter,
    PubRestoreService, TenantService, NgFileDownloadService, UserService, ENTITY_KEYS,
    RESTORE_TASK_UI_STATUS, ENUM_RESTORE_TASK_STATUS, SNAPSHOT_TARGET_TYPE,
    $translate, NgTenantService, NgPassthroughOptionsService, NgIrisContextService) {

    /**
     * the Service object to be returned, public functions and properties to be
     * added to this object
     *
     * @type {Object}
     */
    var RestoreService = {
      canCancelTask: canCancelTask,
      cancelRestoreTask: cancelRestoreTask,
      cancelTaskModal: cancelTaskModal,
      canReconfigureRestoreTask: canReconfigureRestoreTask,
      canRetryRestoreTask: canRetryRestoreTask,
      canTearDownTask: canTearDownTask,
      canDownloadDebugLog: canDownloadDebugLog,
      clone: clone,
      cloneApplication: cloneApplication,
      cloneView: cloneView,
      cloudRestoreSettingsModal: cloudRestoreSettingsModal,
      createRemoteRestoreTask: createRemoteRestoreTask,
      createVulScanTask: createVulScanTask,
      decorateFiles: decorateFiles,
      decorateRestoreTaskObjects: decorateRestoreTaskObjects,
      deployToCloud: deployToCloud,
      destroyCloneTask: destroyCloneTask,
      downloadFile: downloadFile,
      downloadFileFromRecoveryTask: downloadFileFromRecoveryTask,
      downloadFileFromSnapshot: downloadFileFromSnapshot,
      downloadVulScanReport: downloadVulScanReport,
      fileVersionsArg: fileVersionsArg,
      filterDirectArchiveSnapshots: filterDirectArchiveSnapshots,
      finalizeApplicationTask: finalizeApplicationTask,
      isMultiStageRestore: isMultiStageRestore,
      isUnsupportedTarget: isUnsupportedTarget,
      generateRestoreTaskObjects: generateRestoreTaskObjects,
      getAllowedCloneTypes: getAllowedCloneTypes,
      getAppRestoreTasks: getAppRestoreTasks,
      getArchiveTask: getArchiveTask,
      getDBTimeRanges: getDBTimeRanges,
      getDefaultTaskName: getDefaultTaskName,
      getDestroyableTaskTypes: getDestroyableTaskTypes,
      getDirectoryInfo: getDirectoryInfo,
      getDownloadFileName: getDownloadFileName,
      getFileRecoveryDisabledState: getFileRecoveryDisabledState,
      getFileRestoreTaskStatus: getFileRestoreTaskStatus,
      getFileVersions: getFileVersions,
      getJobRunHistory: getJobRunHistory,
      getObjectSnapshots: getObjectSnapshots,
      getPfileMetadata: getPfileMetadata,
      getRemoteRestoreTasks: getRemoteRestoreTasks,
      getRestorableVersions: getRestorableVersions,
      getRestoreFileOrFolderNameAndPath: getRestoreFileOrFolderNameAndPath,
      getRestoreObjectProto: getRestoreObjectProto,
      getRestorePointsForTimeRange: getRestorePointsForTimeRange,
      getRestoreTaskDetailStateName: getRestoreTaskDetailStateName,
      getRestoreTasks: getRestoreTasks,
      getSnapshotByJobInstanceId: getSnapshotByJobInstanceId,
      getDataProtectSnapshotVersions: getDataProtectSnapshotVersions,
      getStatInfo: getStatInfo,
      getStatusCounts: getStatusCounts,
      getTargetDatastores: getTargetDatastores,
      getTask: getTask,
      getTasks: getTasksWrapper,
      getVirtualDiskInfo: getVirtualDiskInfo,
      getVlanParams: getVlanParams,
      getVolumeInfo: getVolumeInfo,
      getVulScanAppStatus: getVulScanAppStatus,
      getVulScanResult: getVulScanResult,
      isCloudFLRFeatureOff: isCloudFLRFeatureOff,
      isTaskDestroyed: isTaskDestroyed,
      isTaskDestroying: isTaskDestroying,
      isTaskRunning: isTaskRunning,
      isTaskScheduled: isTaskScheduled,
      recoverApplication: recoverApplication,
      restoreFiles: restoreFiles,
      restoreVM: restoreVM,
      retrieveFromArchive: retrieveFromArchive,
      retryRestoreTask: retryRestoreTask,
      setAutoSync: setAutoSync,
      showAagStepsModal: showAagStepsModal,
      syncApplicationTask: syncApplicationTask,
      teardownTaskModal: teardownTaskModal,
      downloadRestoreDebugLog: downloadRestoreDebugLog,
      validateDatabaseRestoreTime: validateDatabaseRestoreTime,

      // New Recover (public APIs)
      downloadFilesAndFolders: downloadFilesAndFolders,
      recover: recover,
      updateJobRunWithVms: updateJobRunWithVms,
      updateRecoverTask: updateRecoverTask,
    };

    /**
     * The list of task types that can be torn down.
     * @type   {Array}
     */
    var DESTROYABLE_TASK_TYPES = [
      // kCloneVMs
      2,

      // kRecoverApp can be sql or active directory.
      // active directory can be destroyed, sql can not.
      4,

      // kMountVolumes
      6,

      // kCloneApp
      7,

      // kConvertAndDeployVMs (Azure)
      9,

      // kSystem
      11,

      // kDeployVms (cloudSpin)
      13,

      // kCloneAppView (expose view)
      18,

      // kAd (Active Directory)
      29,
    ];

    /**
     * List of restoreTask type ints that are clone operations.
     * @type   {array}
     */
    var CLONE_TASK_TYPE_INTS = [
      // kCLoneVms
      2,

      // kCloneViews
      5,

      // kCloneApp
      7
    ];

    /**
     * Simple cache object for pointsForTimeRange requests.
     *
     * @type  {Object}
     */
    var PIT_CACHE = {};

    /**
     * Shows the AAG manual steps modal.
     *
     * @method   showAagStepsModal
     * @param    {array}     [cart=[]]            The Restore Task cart.
     * @param    {string}    [flowType=recover]   The flow type: 'recover' or
     *                                            'clone'.
     * @param    {boolean}   [accepted]           Modal already accepted.
     * @return   {object}    Promise to resolve or reject when the user closes
     *                       or cancels the modal.
     */
    function showAagStepsModal(cart, flowType, accepted) {
      var modalOpts = {
        actionBtn: 'continue',
        titleKey: 'restoreSqlAagChallengeModal.title',
      };
      var modalConfig = {
        controller: challengeModalController,
        templateUrl: 'app/protection/recovery/db/sql-aag-challenge-modal.html',
        resolve: {
          cart: function() { return cart || []; },
          challengeAccepted: function() { return accepted; },
          flowType: function() { return flowType || 'recover'; },
        },
      };

      return cModal.standardModal(modalConfig, modalOpts);
    }

    /**
     * Modal controller for AAG next steps challenge modal.
     *
     * @method   challengeModalController
     */
    /* @ngInject */
    function challengeModalController($filter, $uibModalInstance, flowType,
      cart, challengeAccepted) {
      var $ctrl = this;

      angular.extend($ctrl, {
        flowType: flowType,
        confirmStepsAreCompleted: !!challengeAccepted,

        // User can not click OK if they have not checked the box.
        okDisabled: !challengeAccepted,

        // If the acceptance has already been set explicitely `true`, disable
        // the checkbox.
        checkboxDisabled: challengeAccepted === true,
      });

      /**
       * Controller $onInit lifecycle hook.
       *
       * @method   $onInit
       */
      $ctrl.$onInit = function onInit() {
        // If this list is a Magneto list (not Yoda search), convert it to a
        // Yoda analog list.
        if (cart[0] && cart[0].appEntity) {
          cart = cart.map(function convertCartList(item) {
            return {
              _isAagMember: item.appEntity.sqlEntity.aagDbEntityId ||
                item.appEntity.sqlEntity.aagEntityId,
              vmDocument: { objectId: { entity: item.appEntity } },
            };
          });
        }

        $ctrl.aags = cart.reduce(function cartReducer(aags, item) {
          if (item._isAagMember) {
            aags.push($filter('aagName')(item.vmDocument.objectId.entity));
          }
          return aags;
        }, []);
      };

      /**
       * Modal's OK button click handler.
       *
       * @method   ok
       */
      $ctrl.ok = function ok() {
        if (!$ctrl.confirmStepsAreCompleted) {
          return $uibModalInstance.dismiss(false);
        }

        $uibModalInstance.close(true);
      };
    }

    /**
     * Gets the list of destroyable task types
     *
     * @method   getDestroyableTaskTypes
     * @return   {array}   The list of types
     */
    function getDestroyableTaskTypes() {
      return angular.copy(DESTROYABLE_TASK_TYPES);
    }

    /**
     * Determines ability to tear down a given restoreTask.
     *
     * @param      {object}    restoreTask   The restoreTask
     * @return     {boolean}   True if can be torn-down, false otherwise.
     */
    function canTearDownTask(restoreTask) {
      var taskBase = restoreTask.performRestoreTaskState.base;
      var restoreType = get(restoreTask, '_envTaskParams.restoreInfo.type');
      var destroyVec = restoreTask.destroyClonedTaskStateVec;
      var isTargetCloud = ENV_GROUPS.cloudSources.includes(
        get(restoreTask, '_envTaskParams.restoreParentSource.type'));
      var isCloneOrDeploy = [ENUM_RESTORE_TYPE.kDeployVMs,
        ENUM_RESTORE_TYPE.kCloneVMs, ENUM_RESTORE_TYPE.kConvertAndDeployVMs]
          .includes(taskBase.type);

      // Only users belonging to task owner organization can issue tear down
      // request.
      if (!restoreTask._isTaskOwner) {
        return false;
      }

      // For Cloud Targets, allow tear down even for failures because
      // some resources get created on cloud while task is running
      // which need to be removed.
      if (isTargetCloud && isCloneOrDeploy) {
        return _canTearDownCloudTask(taskBase.type, restoreTask._status);
      }

      // Exit early if can not tear down
      // If canTeardown is true, we still need to check the subsequent
      // conditions below.
      if (angular.isDefined(restoreTask.performRestoreTaskState.canTeardown) &&
        !restoreTask.performRestoreTaskState.canTeardown) {
        return false;
      }

      // Only proceed if the task is kFinished (3) or kCancelled (7).
      if (![3, 7].includes(taskBase.status)) {
        return false;
      }

      // Check privileges. Instant Mount needs RESTORE_MODIFY but others need
      // CLONE_MODIFY.
      if (taskBase.type === 6) {
        if (!$rootScope.user.privs.RESTORE_MODIFY) {
          return false;
        }
      } else {
        if (!$rootScope.user.privs.CLONE_MODIFY) {
          return false;
        }
      }

      // If the restore task doesn't have the parts or is still running, exit
      // early
      if (!hasThingsToTearDown(restoreTask) ||
        // The task isn't in the list of destroyable task types
        !DESTROYABLE_TASK_TYPES.includes(taskBase.type) ||

        // The task is kRecoverApp, but not Active Directory and Exchange
        (taskBase.type === 4 &&
          ![ENV_TYPE_CONVERSION.kAD,ENV_TYPE_CONVERSION.kExchange].includes(restoreType)) ||

        // The task  scheduled, nor running
        isTaskScheduled(restoreTask) || isTaskRunning(restoreTask) ||

        // The task is currently destroying or already successfully destroyed
        isTaskDestroying(restoreTask) || isTaskDestroyed(restoreTask)) {
          return false;
      }

      // There are destroy attempts and the most recent has errors. This means
      // we can continue attempting to tear down.
      if (Array.isArray(destroyVec) &&
          destroyVec.length &&
          destroyVec[destroyVec.length - 1].error) {
        return true;
      }

      // No teardown attempts have been made.
      return true;
    }

    /**
     * Returns true if debug log can be download.
     *
     * @param      {object}    restoreTask   The restoreTask
     * @return     {boolean}   True if can be download debug log, false otherwise.
     */
    function canDownloadDebugLog(restoreTask) {
      var restoreType = get(restoreTask, '_envTaskParams.restoreInfo.type');
      const isOracle = restoreType === ENV_TYPE_CONVERSION.kOracle;
      const isEnabled = FEATURE_FLAGS.downloadDebugLogsEnabled;
      const isRunning = isTaskRunning(restoreTask);
      return (isOracle && isEnabled && !isRunning);
    }

    /**
     * Determines ability to tear down a given restoreTask having a cloud
     * target.
     *
     * @param    {String}    type   The restoreTask type (clone/restore etc)
     * @param    {String}    state  The restoreTask state (failed/success etc)
     * @return   {boolean}   True if can be torn-down, false otherwise.
     */
    function _canTearDownCloudTask(type, status) {
      var canTearDown = [RESTORE_TASK_UI_STATUS.kSuccess,
        RESTORE_TASK_UI_STATUS.kCanceled,
        RESTORE_TASK_UI_STATUS.kError]
        .includes(status);

      // If it is cloudSpin, it should be feature Toggle checked
      return (((type === ENUM_RESTORE_TYPE.kDeployVMs &&
        FEATURE_FLAGS.canTearDownCloudSpin) ||
          type !== ENUM_RESTORE_TYPE.kDeployVMs) && canTearDown);
    }

    /**
     * indicates if the provided restoreTask created any objects which can be
     * torn down. (not taking into account if said objects have already been
     * torn down).
     *
     * @param      {object}   restoreTask  The restore task
     * @return     {boolean}  True if restoreTask has things to tear down, false
     *                        otherwise.
     */
    function hasThingsToTearDown(restoreTask) {

      var mvTaskState;
      var restoreInfoType;

      // exit early if any critical parts are missing
      if (!restoreTask || !restoreTask.performRestoreTaskState ||
        !restoreTask.performRestoreTaskState.base) {
        return false;
      }

      switch (restoreTask.performRestoreTaskState.base.type) {

        // kRecoverApp with kAD or kExchange restoreType can use the canTearDown
        // flag from the api - it is guaranteed to be set to true only
        // if the task can be torn down.
        case 4:
          // A db migration task may not have a restore info property on it
          // It might make sense to put this check above and return earlier,
          // but just in case there are scenarios where a volume mount doesn't
          // have the restoreInfo info, this check is being made more specific.
          restoreInfoType =
            (restoreTask.performRestoreTaskState.restoreInfo || {}).type;
          return ([ENV_TYPE_CONVERSION.kAD, ENV_TYPE_CONVERSION.kExchange]
            .includes(restoreInfoType)) &&
            restoreTask.performRestoreTaskState.canTeardown;

        // Volume Mount
        // did the restoreTask create any mount volumes?
        case 6:
          return restoreTask.performRestoreTaskState.canTeardown;

        // Do other restore tasks types need to check for successful object
        // creation?
        default:
          return true;
      }

    }

    /**
     * Determines if a given task is currently running.
     *
     * @method     isTaskRunning
     * @param      {object}    restoreTask   The restore task to query
     * @return     {boolean}   True if running, false otherwise
     */
    function isTaskRunning(restoreTask) {
      return [
        RESTORE_TASK_UI_STATUS.kRunning,
        RESTORE_TASK_UI_STATUS.kMigrating,
        RESTORE_TASK_UI_STATUS.kRefreshing,
      ].includes(getTaskStatus(restoreTask));
    }

    /**
     * Determines if a given task is currently scheduled.
     *
     * @method     isTaskScheduled
     * @param      {object}    restoreTask   The restore task to query
     * @return     {boolean}   True if scheduled, false otherwise
     */
    function isTaskScheduled(restoreTask) {
      return (getTaskStatus(restoreTask) === 0);
    }

    /**
     * Determines if the given task has been successfully torn down.
     *
     * @method     isTaskDestroyed
     * @param      {object}    restoreTask   The restoreTask to query
     * @return     {boolean}   True is destroyed successfully, false otherwise
     */
    function isTaskDestroyed(restoreTask) {
      var lastAttempt = getLastDestroyAttempt(restoreTask);

      return !!lastAttempt && lastAttempt.status === 2 && !lastAttempt.error;
    }

    /**
     * Determines if a given task may be cancelled.
     *
     * @method     canCancelTask
     * @param      {object}   task  The restore task to query
     * @return     {boolean}  True if cancellable, false otherwise
     */
    function canCancelTask(task) {
      var taskState = task.performRestoreTaskState;
      var restoreAppParams = taskState.restoreAppTaskState &&
        taskState.restoreAppTaskState.restoreAppParams;

      // Only users belonging to task owner organization can issue cancel
      // request.
      if (!task._isTaskOwner) {
        return false;
      }

      // May not cancel completed tasks. 3 = kFinished, 7 = kCancelled
      if ([3, 7].includes(taskState.base.status)) {
        return false;
      }

      // For SQL sources
      if (restoreAppParams && restoreAppParams.type === 3) {
        // Must be a SQL instance, not VM.
        return !restoreAppParams.ownerRestoreInfo.performRestore;
      }

      // Enable Cancel Restore for Download Files if the feature flag for
      // download files and folders is turned on and the restoreType is
      // 14: kDownloadFiles
      if (FEATURE_FLAGS.downloadFilesAndFoldersEnabled &&
        taskState.base.type === 14) {
        return true;
      }

      // Enable Cancel Restore for Efficient NAS Volume recovery
      // which is a fileBasedVolume Restore
      // Use the same feature flag used for existing NAS
      // i.e 'cancelNasRestore'
      if (taskState.base._isFileBasedVolumeRestore) {
        return FEATURE_FLAGS.cancelNasRestore;
      }

      switch (true) {
        // VMWare tasks
        case get(taskState, 'restoreParentSource.type') ===
          ENV_TYPE_CONVERSION.kVMware:
          return FEATURE_FLAGS.cancelVMWareRestore;

        // NAS
        case ENV_GROUPS.nas.includes(get(taskState, 'restoreInfo.type')):
          return FEATURE_FLAGS.cancelNasRestore;

        // Qstar
        case [1, 'kTape'].includes(get(taskState, 'retrieveArchiveTaskVec[0].archivalTarget.type')):
          return FEATURE_FLAGS.cancelQstarRestore;
      }

      // All other tasks may be cancelled. NOTE: For 4.2.1, 5.0.x, 6.0, this
      // flag is false. Eventually this line should be replaced with a simple
      // return true;
      return FEATURE_FLAGS.cancelRestoreEnabled;
    }

    /**
     * Build an array of allowed clone types for use with task filters.
     *
     * @method     getAllowedCloneTypes
     * @return     {Array}   Alowed Clone Types
     */
    function getAllowedCloneTypes() {
      return [
        'kCloneView',
        'kConvertAndDeployVMs',
        'kCloneApp',
        'kCloneVMs',
        'kDeployVMs',
      ];
    }

    /**
     * Determines if the given task is currently performing a teardown.
     *
     * @method     isTaskDestroying
     * @param      {object}    restoreTask   The restoreTask to query
     * @return     {boolean}   True is destroyed successfully, false otherwise
     */
    function isTaskDestroying(restoreTask) {
      var lastAttempt = getLastDestroyAttempt(restoreTask);
      return lastAttempt ? (1 === lastAttempt.status) : false;
    }

    /**
     * Gets the most recent destroy attempt from a given restoreTask.
     *
     * @method     getLastDestroyAttempt
     * @param      {object}   restoreTask   The restoreTask to query
     * @return     {object}   If found, the latest destroy attempt; undefined
     *                        otherwise.
     */
    function getLastDestroyAttempt(restoreTask) {
      var lastIndex;
      var lastAttempt;
      if (restoreTask &&
        restoreTask.destroyClonedTaskStateVec &&
        restoreTask.destroyClonedTaskStateVec.length) {
          lastIndex = restoreTask.destroyClonedTaskStateVec.length - 1;
          lastAttempt = restoreTask.destroyClonedTaskStateVec[lastIndex];
      }
      return lastAttempt;
    }

    /**
     * takes an optional search params object, runs an ajax call to get restore
     * tasks, and returns a promise that should result in the desired data
     *
     * @param      {Object}    [restoreJobParams]   optional restoreJobParams
     *                                              object, which will be
     *                                              converted to query string
     * @return     {object}   returns a promise
     */
    function getTasks(restoreJobParams) {
      return $http({
        method: 'get',
        url: API.private('restoretasks'),
        params: restoreJobParams
      });
    }

    /**
     * Decorate provided restore task info with its owner info used to prevent
     * unauthorized user from making cancel, tear down etc type of requests.
     *
     * @method     decorateTaskOwnerInfo
     * @param      {Object}   restoreTask   The restore task to decorate.
     * @return     {object}   Modified restore task with its owner info.
     */
    function decorateTaskOwnerInfo(restoreTask) {
      var pulseAttributeVec = get(
        restoreTask.performRestoreTaskState, 'base.userInfo.pulseAttributeVec');
      var tenantId = get(restoreTask.performRestoreTaskState,
        'base.userInfo.tenantIdVec[0]');

      // If tenantInfo already exists in the userInfo, overwrite the tenantId.
      if (tenantId) {
        restoreTask._tenantId = tenantId;
      } else if (pulseAttributeVec && pulseAttributeVec.length) {
        var tenantInfo = find(pulseAttributeVec, ['key', 'tenantId']);

        restoreTask._tenantId = undefined;
        if (tenantInfo) {
          restoreTask._tenantId = tenantInfo.value.data.stringValue;
        }
      }

      restoreTask._isTaskOwner =
        isEntityOwner(NgIrisContextService.irisContext, restoreTask._tenantId);

      return restoreTask;
    }

    /**
     * This function takes data returned from getTasks and transforms it by
     * adding additional information properties
     *
     * @param      {Array}   data    array of restore objects as returned from
     *                               API
     * @return     {Array}   processed and filtered array of objects
     */
    function processRestoreTasksData(data) {
      data = Array.isArray(data) ? data : [];

      // Process the provided tasks, adding additional information
      return data.map(function dataMapFn(task) {
        var restoreTask = task.restoreTask;
        var taskState = restoreTask.performRestoreTaskState;
        var taskType = taskState.base.type;
        var firstRestoreEntity;

        restoreTask = decorateTaskOwnerInfo(restoreTask);

        // If this task is either Clone or Recover VM, add some helper props.
        if ([ 1, 2 ].includes(taskType)) {
          firstRestoreEntity = taskState.objects[0].entity;
          restoreTask._isHyperV = !!firstRestoreEntity.hypervEntity;
          restoreTask._isAcropolis = !!firstRestoreEntity.acropolisEntity;
          restoreTask._isVMware = !!firstRestoreEntity.vmwareEntity;
        }

        // In case of nas volume recovery to original/alternate location
        // we use the same files_reovery_op with an extra flag to differentiate
        // b/n files/folder recovery and NAS volumes recovery.
        taskState.base._isFileBasedVolumeRestore = get(restoreTask, [
          'performRestoreTaskState', 'restoreFilesTaskState', 'restoreParams',
          'isFileVolumeRestore'
        ].join('.'));

        /**
         * Because VM restore tasks on environments other than VMware (ie.
         * HyperV) have most of their params moved within an environment
         * specific property, this acts as a generic shortcut to those params
         * which works for all environment types (except restoreApp [SQL]).
         */
        restoreTask._envTaskParams =
          taskState.restoreHypervVmParams ||
          taskState.restoreAcropolisVmParams ||
          taskState;

        restoreTask._startTimeUsecs = taskState.base.startTimeUsecs;
        restoreTask._endTimeUsecs = taskState.base.endTimeUsecs ||
          Date.clusterNow() * 1000;

        if (restoreTask._startTimeUsecs) {
          restoreTask._durationUsecs =
            restoreTask._endTimeUsecs - restoreTask._startTimeUsecs;
        }

        /**
         * For kCloneApp tasks, the objects to be copied are returned in the
         * ownerRestoreWrapperProto. Check if that is present and if it contains
         * objects. If so, copy it to restoreTask.performRestoreTaskState for
         * consistency.
         */
        if (restoreTask.ownerRestoreWrapperProto &&
          Array.isArray(restoreTask.ownerRestoreWrapperProto.performRestoreTaskState.objects)) {
          taskState.objects =
            restoreTask.ownerRestoreWrapperProto.performRestoreTaskState.objects.slice(0);
        }

        if (Array.isArray(taskState.objects)) {
          restoreTask._objectCount = taskState.objects.length;
        }
        restoreTask = processTaskDetail(restoreTask);

        // If this task is destroyable, process it for teardown info.
        switch (taskType) {
          // Clone VM
          case 2:
            restoreTask = processCloneDestroyInfo(restoreTask);
            break;

          // kMountVolumes
          case 6:
            restoreTask = processMountPointDestroyInfo(restoreTask);
            break;

          // Clone SQL
          case 7:
            restoreTask = processCloneDestroyInfo(restoreTask);
        }

        restoreTask._runTime = getRunTime(restoreTask);
        restoreTask._status = getTaskStatus(restoreTask);

        restoreTask._errorMessage = RestoreServiceFormatter
          .getTaskErrorMessage(restoreTask);

        restoreTask._warningMessage = RestoreServiceFormatter
          .getTaskWarningMessage(restoreTask);

        restoreTask._uiStatus = RestoreServiceFormatter
          .getTaskStatusString(restoreTask._status);

        restoreTask._uiStatusClass = RestoreServiceFormatter
          .getTaskStatusClass(restoreTask);

        restoreTask._progressMonitorURL = RestoreServiceFormatter
          .getProgressMonitorUrl(taskState.progressMonitorTaskPath);

        // Not clone App
        if (taskType !== 7) {
          taskState.objects =
            taskState.objects || [];
          // Add progress object to each object of this task
          taskState.objects.forEach(
            function addProgressProperty(obj, index) {
              obj._progress = {};
              obj._taskPath = taskState.objectsProgressMonitorTaskPaths ?
                taskState.objectsProgressMonitorTaskPaths[index] :
                taskState.progressMonitorTaskPath;
            }
          );

          /**
           * NOTE: this field (_computeDestination) is referenced in clone
           * details.html, but is only being populated for VM clone tasks and
           * app clone tasks (below). Need to evaluate its general usefulness.
           * I'm not sure the name of it even applies to non VM related tasks.
           */
          if (taskType === 2) {
            restoreTask._computeDestination = [
              SourceService.normalizeEntity(taskState.restoreParentSource).name,
              SourceService.normalizeEntity(taskState.resourcePoolEntity).name,
            ].join(':');
          }
        }

        // SQL Recovery & Clone
        if (taskState.restoreAppTaskState) {
          if (taskState.restoreAppTaskState.restoreAppParams &&
            Array.isArray(taskState.restoreAppTaskState.restoreAppParams.restoreAppObjectVec)) {
            restoreTask._hasAagMembers = taskState
              .restoreAppTaskState.restoreAppParams.restoreAppObjectVec
              .some(function findAagMembers(obj) {
                return obj.appEntity &&
                  obj.appEntity.sqlEntity &&
                  (obj.appEntity.sqlEntity.aggDbEntityId ||
                  obj.appEntity.sqlEntity.aagEntityId);
              });

            restoreTask._isMultiStageRestore = isMultiStageRestore(restoreTask);
          }
        }

        // Clone app (SQL, etc)
        if (restoreTask.ownerRestoreWrapperProto &&
          restoreTask.ownerRestoreWrapperProto.performRestoreTaskState &&
          Array.isArray(restoreTask.ownerRestoreWrapperProto.performRestoreTaskState.objects)) {
          taskState.objects.forEach(
            function addProgressProperty(obj, index) {
              // Add progress object to each object of this task
              obj._progress = {};
              obj._taskPath = taskState.progressMonitorTaskPath;
            }
          );

          // Detect specified new target and create _computeDestination, else
          // use the default.
          if (taskState.restoreAppTaskState &&
            taskState.restoreAppTaskState.restoreAppParams &&
            taskState.restoreAppTaskState.restoreAppParams.ownerRestoreInfo.ownerRestoreParams.restoreParentSource) {
            // User specified a new clone target. Get Source name from
            // restoreParentSource.
            restoreTask._computeDestination = [
              SourceService.normalizeEntity(taskState.restoreAppTaskState.restoreAppParams.ownerRestoreInfo.ownerRestoreParams.restoreParentSource).name,
              SourceService.normalizeEntity(taskState.restoreAppTaskState.restoreAppParams.ownerRestoreInfo.ownerRestoreParams.resourcePoolEntity).name
            ].join(':');
          } else if (taskState.objects[0].parentSource &&
            taskState.resourcePoolEntity) {
            // User did not specify a clone target. Get Source name from Objects
            // list.
            restoreTask._computeDestination = [
              SourceService.normalizeEntity(
                taskState.objects[0].parentSource).name,
              SourceService.normalizeEntity(
                taskState.resourcePoolEntity).name,
            ].join(':');
          }
        }

        // Add clone app info
        if (taskType === ENUM_RESTORE_TYPE.kCloneApp) {
          RestoreServiceFormatter.decorateCloneAppInfo(restoreTask);
        }

        if (taskType === ENUM_RESTORE_TYPE.kDownloadFiles) {
          restoreTask._disableDownloadFileReason = null;

          // case 1: you can't download files which is not owned by tenant of the logged in user.
          // case 2: SP can't download tenants files until you are having the required RESTORE_DOWNLOAD privilege.
          if (!restoreTask._isTaskOwner ||
            (!!NgTenantService.impersonatedTenant && !UserService.user.privs.RESTORE_DOWNLOAD)) {
            restoreTask._disableDownloadFileReason =
              $translate.instant('recovery.disableMessage.onlyTenantDownloadSupported');
          } else if (!UserService.user.privs.RESTORE_DOWNLOAD) {
            restoreTask._disableDownloadFileReason = $translate.instant('recovery.disableMessage.userPrivilegeError');
          }
        }

        return restoreTask;
      });
    }

    /**
     * Determines if the given task is a multi-stage recovery task. Initially
     * this means DB Migration/HotStandby.
     *
     * @method    isMultiStageRestore
     * @param     {Object}    restoreTask    The restore task to detect multi-
     *                                       stage on.
     * @return    {Boolean}   True if this task is multi-stage. False otherwise.
     */
    function isMultiStageRestore(restoreTask) {
      return !!get(
        restoreTask.performRestoreTaskState,
        'restoreAppTaskState.restoreAppParams.restoreAppObjectVec[0].'+
          'restoreParams.sqlRestoreParams.isMultiStageRestore'
      );
    }

    /**
     * This function takes data returned from getRetreivalTasks and transforms
     * it by adding additional information properties
     *
     * @param      {Array}   data    Array of retrieval tasks as returned from
     *                               API
     * @return     {Array}   List of processed and updated tasks
     */
    function processRetrievalTasksData(data) {
      var dateTimeFormat = DateTimeService.getPreferredFormat();

      if (!data || !data.length) {
        return [];
      }

      // process the provided tasks, adding additional information
      data = data.map(function mapData(task) {
        var retrieveArchiveTask = task.restoreTask;

        retrieveArchiveTask = decorateTaskOwnerInfo(retrieveArchiveTask);

        retrieveArchiveTask._status = getTaskStatus(task.restoreTask);
        retrieveArchiveTask._uiStatus = RestoreServiceFormatter
          .getTaskStatusString(retrieveArchiveTask._status);
        retrieveArchiveTask._uiStatusClass = RestoreServiceFormatter
          .getTaskStatusClass(retrieveArchiveTask._status);
        retrieveArchiveTask._duration =
          (retrieveArchiveTask.performRestoreTaskState.base.endTimeUsecs) ?
          (retrieveArchiveTask.performRestoreTaskState.base.endTimeUsecs -
            retrieveArchiveTask.performRestoreTaskState.base.startTimeUsecs) :
          undefined;
        retrieveArchiveTask._envTaskParams =
          retrieveArchiveTask.performRestoreTaskState;
        return retrieveArchiveTask;
      });

      return data;
    }

    /**
     * takes a restoreTask object and returns same object with added/computed
     * properties
     *
     * @param      {Object}   task    as returned from api
     * @return     {Object}   same object with computed properties added
     */
    function processTaskDetail(task) {
      var restoreTaskState = task.performRestoreTaskState;

      if (!restoreTaskState.objects) {
        return task;
      }

      restoreTaskState.objects.forEach(
        function eachCloneTaskObject(taskObj, index) {
          var restoredEntity;

          // Copy status/error from restoreEntityVec to objects.
          // restoreEntityVec has the status and error info.
          if (restoreTaskState.restoreInfo && restoreTaskState.restoreInfo.restoreEntityVec) {
            restoreTaskState.restoreInfo.restoreEntityVec.some(
              function copyStatusFromRestoreEntity(restoreEntity) {
                if (restoreEntity.entity &&
                  restoreEntity.entity.id === taskObj.entity.id) {
                  taskObj.status = restoreEntity.status;
                  taskObj.publicStatus = restoreEntity.publicStatus;
                  taskObj.error = restoreEntity.error;
                  return true;
                }
             }
            );
          }


          taskObj._normalizedEntity = SourceService.normalizeEntity(taskObj.entity);
          taskObj._snapshotClonedUsecs = taskObj.startTimeUsecs;

          switch (task.performRestoreTaskState.base.type) {
            case 7:
              if (task.ownerRestoreWrapperProto.performRestoreTaskState.restoreInfo &&
                task.ownerRestoreWrapperProto.performRestoreTaskState.restoreInfo.restoreEntityVec &&
                task.ownerRestoreWrapperProto.performRestoreTaskState.restoreInfo.restoreEntityVec[index]) {

                restoredEntity = task.ownerRestoreWrapperProto.performRestoreTaskState.restoreInfo.restoreEntityVec[index].restoredEntity ||
                  task.ownerRestoreWrapperProto.performRestoreTaskState.restoreInfo.restoreEntityVec[index].entity;
              }
              break;
            default:
              if (task.performRestoreTaskState.restoreInfo &&
                task.performRestoreTaskState.restoreInfo.restoreEntityVec &&
                task.performRestoreTaskState.restoreInfo.restoreEntityVec[index]) {
                  restoredEntity = task.performRestoreTaskState.restoreInfo.restoreEntityVec[index].restoredEntity ||
                    task.performRestoreTaskState.restoreInfo.restoreEntityVec[index].entity;
              }
          }

          if (restoredEntity) {
            taskObj._createdEntityUuid = restoredEntity[SourceService.getEntityKey(restoredEntity.type)].uuid;
          }
        }
      );

      return task;
    }

    /**
     * takes a restoreTask object (clone task, specically) and returns the task
     * with computed clone destroy based information added.
     *
     * @param      {Object}   task    restoreTask as returned from API
     * @return     {Object}   restoreTask with added/computed properties
     */
    function processCloneDestroyInfo(task) {
      var dateTimeFormat = DateTimeService.getPreferredFormat();
      var numObjects;
      var lastIndex;

      // No task? Go home!!
      if (!task) {
        return task;
      }

      // If there is an ownerRestoreWrapperProto present, check that proto for
      // relevant destroy info. If found, copy it to task.
      if (task.ownerRestoreWrapperProto &&
        task.ownerRestoreWrapperProto.destroyClonedTaskStateVec) {
        task.destroyClonedTaskStateVec =
          task.ownerRestoreWrapperProto.destroyClonedTaskStateVec.slice(0);
      }

      // if no destroy information, destroy hasn't been attemped yet, return
      // task object as-is
      if (!task.destroyClonedTaskStateVec) {
        return task;
      }

      numObjects = task.performRestoreTaskState.objects ?
        task.performRestoreTaskState.objects.length : 1;
      lastIndex = task.destroyClonedTaskStateVec.length - 1;

      task._destroyAttempted = true;
      task._destroyUser = task.destroyClonedTaskStateVec[lastIndex].user;

      // overwrite clone job start time, end time and duration with latest
      // destroy attempt
      task._startTimeUsecs =
        task.destroyClonedTaskStateVec[lastIndex].startTimeUsecs;

      task._endTimeUsecs =
        task.destroyClonedTaskStateVec[lastIndex].endTimeUsecs || null;

      if (task._startTimeUsecs && task._endTimeUsecs) {
        task._durationUsecs = task._endTimeUsecs - task._startTimeUsecs;
      } else {
        task._durationUsecs = null;
      }

      // only surface a destroy error message message if there is one on the
      // most recent destroy attempt
      if (task.destroyClonedTaskStateVec[lastIndex].error) {
        task._destroyErrorMsg =
          task.destroyClonedTaskStateVec[lastIndex].error.errorMsg;
      }

      // loop through destroy attempts and then entities contained within those
      // attempts, updating the clone task objects with additional information
      // related to destroy
      task.destroyClonedTaskStateVec.forEach(function eachTeardownAttempt(destroyAttempt) {

        var isDestroying;
        var isDestroyed;

        // sanity check to ensure the destroy attempt has
        // destroyClonedEntityInfoVec
        if (!destroyAttempt.destroyCloneVmTaskInfo ||
          !Array.isArray(destroyAttempt.destroyCloneVmTaskInfo.destroyClonedEntityInfoVec)) {
            return;
        }

        destroyAttempt.destroyCloneVmTaskInfo.destroyClonedEntityInfoVec.forEach(
          function eachClonedEntityInfoVec(destroyEntity) {

            // sanity check so we don't waste our time looping and to prevent js
            // errors
            if (!destroyEntity.clonedEntity ||
              !destroyEntity.clonedEntity.entity) {
                return;
            }

            destroyEntity._clonedEntityUuid = destroyEntity.clonedEntity.entity[
              SourceService.getEntityKey(destroyEntity.clonedEntity.entity.type)
            ].uuid;

            // search the clone task objects for this particular entity, and add
            // additional information
            for (var x = 0; x < numObjects; x++) {
              if (task.performRestoreTaskState.objects[x]._createdEntityUuid &&
                task.performRestoreTaskState.objects[x]._createdEntityUuid === destroyEntity._clonedEntityUuid) {

                // kFinished for destroyClonedEntityState is 1.
                isDestroying = (destroyEntity.destroyClonedEntityState < 1);
                isDestroyed = (destroyEntity.destroyClonedEntityState === 1);

                // Determine the destroyStatus of this task object
                switch (true) {
                  case (isDestroying):
                    // Running
                    angular.extend(task.performRestoreTaskState.objects[x], {
                      _destroyedStatus: 'destroyRunning',
                      _destroyed: false
                    });
                    break;
                  case (!!destroyEntity.error):
                    // Error
                    angular.extend(task.performRestoreTaskState.objects[x], {
                      _destroyedStatus: 'destroyError',
                      _destroyed: false,
                      _destroyError: destroyEntity.error.errorMsg
                    });
                    break;
                  case (isDestroyed):
                    // Done Successfully
                    angular.extend(task.performRestoreTaskState.objects[x], {
                      _destroyedStatus: 'destroySuccess',
                      _destroyed: true
                    });
                    break;
                  default:
                    // No info about status
                    angular.extend(task.performRestoreTaskState.objects[x], {
                      _destroyedStatus: undefined,
                      _destroyed: false
                    });
                }
                if (task.performRestoreTaskState.objects[x]._destroyAttempts) {
                  task.performRestoreTaskState.objects[x]._destroyAttempts++;
                } else {
                  task.performRestoreTaskState.objects[x]._destroyAttempts = 1;
                }
                // get out of the for loop since we found our match
                break;
              }
            }

          }
        );

      });

      return task;
    }

    /**
     * Takes a restoreTask object (mount volumes, specifically) and returns the
     * task with computed clone destroy based information added.
     *
     * @method     processMountPointDestroyInfo
     * @param      {Object}   task    restoreTask as returned from API
     * @return     {Object}   restoreTask with added/computed properties
     */
    function processMountPointDestroyInfo(task) {
      var dateTimeFormat = DateTimeService.getPreferredFormat();
      var object = task.performRestoreTaskState.objects[0];
      var lastIndex;

      task._destroyAttempted = !!task.destroyClonedTaskStateVec;

      // If no destroy information, destroy hasn't been attemped yet, return
      // task object as-is
      if (!task._destroyAttempted) {
        return task;
      }

      lastIndex = task.destroyClonedTaskStateVec.length - 1;

      task._destroyUser = task.destroyClonedTaskStateVec[lastIndex].user;

      // Single object recover, so all attempts are related to this object
      object._destroyAttempts = task.destroyClonedTaskStateVec.length;

      // Overwrite clone job start time, end time and duration with latest
      // destroy attempt
      task._startTimeUsecs =
        task.destroyClonedTaskStateVec[lastIndex].startTimeUsecs;

      task._endTimeUsecs =
        task.destroyClonedTaskStateVec[lastIndex].endTimeUsecs || null;

      if (task._startTimeUsecs && task._endTimeUsecs) {
        task._durationUsecs = task._endTimeUsecs - task._startTimeUsecs;
      }

      // Only surface a destroy error message if there is one on the most recent
      // destroy attempt
      if (task.destroyClonedTaskStateVec[lastIndex].error) {
        task._destroyErrorMsg =
          task.destroyClonedTaskStateVec[lastIndex].error.errorMsg;
      }

      // Loop through destroy attempts and then entities contained within those
      // attempts, updating the clone task objects with additional information
      // related to destroy
      task.destroyClonedTaskStateVec.forEach(function eachAttempt(destroyAttempt) {
        // Sanity check to ensure the destroy attempt has
        // destroyAttempt.destroyMountVolumesTaskInfo.targetEntity
        if (!destroyAttempt.destroyMountVolumesTaskInfo.targetEntity) {
          return task;
        }

        switch (true) {
          // There was an error tearing down
          case (destroyAttempt.error):
            angular.extend(object, {
              _destroyedStatus: 'destroyError',
              _destroyed: false,
              _destroyError: destroyAttempt.error.errorMsg
            });
            break;

          // Teardown is running
          case (!destroyAttempt.destroyMountVolumesTaskInfo.finished):
            angular.extend(object, {
              _destroyedStatus: 'destroyRunning',
              _destroyed: false
            });
            break;

          // Teardown finished successfully
          case (destroyAttempt.destroyMountVolumesTaskInfo.finished):
            angular.extend(object, {
              _destroyedStatus: 'destroySuccess',
              _destroyed: true
            });
            break;

          // No info about status
          default:
            angular.extend(object, {
              _destroyedStatus: undefined,
              _destroyed: false
            });
        }

      });

      return task;
    }

    /**
     * takes a restoreTask object and returns the run time for the restore task
     *
     * @param      {Object}    restoreTask   restoreTask, as returned by the
     *                                       getTasks function
     * @return     {Number}   number of minutes the job took to complete
     */
    function getRunTime(restoreTask) {
      if (restoreTask.performRestoreTaskState &&
        restoreTask.performRestoreTaskState.base) {

        var startTimeUsecs =
          restoreTask.performRestoreTaskState.base.startTimeUsecs;
        var endTimeUsecs =
          restoreTask.performRestoreTaskState.base.endTimeUsecs;
        var runTimeUsecs = endTimeUsecs - startTimeUsecs;
        return runTimeUsecs / 1000 / 1000 / 60;
      } else {
        return 0;
      }
    }

    /**
     * parses the provided status value and returns a UI friendly status (in
     * progress, success, errorText).
     *
     * @method     getTaskStatus
     * @param      {Object}    restoreTask   restore Object as returned from API
     *                                       call.
     * @return     {Number}   The calculated status.
     */
    function getTaskStatus(restoreTask) {
      var lastItem;
      var status;
      var taskStatus = restoreTask.performRestoreTaskState.base.status;

      /**
      ENUM Definitions for reference.

      ENUM_RESTORE_TASK_STATUS = {
        0: "kReadyToSchedule",
        1: "kAdmitted",
        2: "kInProgress",
        3: "kFinished",
        4: "kProgressMonitorCreated",
        5: "kFinishingProgressMonitor",
        6: "kRetrievedFromArchive",
        7: "kCancelled",
      });

      // Out put of this Fn
      CALCULATED_STATUS = {
        1: 'running',
        3: 'task error (??)',
        4: 'task error',
        5: 'Ready to schedule',
        6: 'admitted == running == in progress',
        7: 'sub-task error (can continue?)',
        8: 'sub-task error (can't continue?)',
        9: 'canceled',
      }
      */

      // The status calculation happens in this sequence:
      // Destroy, Refresh, Restore.
      if (!isEmpty(restoreTask.destroyClonedTaskStateVec)) {
        // Mapping: RestoreTaskStatus_Type_name > CALCULATED_STATUS
        // if a delete has been attempted, delete status types take priority
        lastItem = last(restoreTask.destroyClonedTaskStateVec);

        // and we are most interested in the latest destroy attempt
        switch (lastItem.status) {
          // ready to schedule
          case 0:
            return 5;

          // admitted = running = in progress
          case 1:
            return 6;

          // finished, with or without error
          case 2:
            return lastItem.error ?
              // Error : Success
              8 : 7;
        }
      } else if (!isEmpty(restoreTask.performRefreshTaskStateVec)) {
        // If there are any refresh attempts, then those take priority for
        // status.
        lastItem = last(restoreTask.performRefreshTaskStateVec);
        status = lastItem.base.status;

        if (status === ENUM_RESTORE_TASK_STATUS.kFinished) {
          // finished, with or without success.
          return lastItem.base.error ?
            RESTORE_TASK_UI_STATUS.kRefreshError :
            RESTORE_TASK_UI_STATUS.kSuccess;
        }

        // for anything else such as ready to schedule, admitted, in progress,
        // show "Refreshing".
        return RESTORE_TASK_UI_STATUS.kRefreshing;
      } else if (taskStatus === 3) {
        // Mapping: ENUM_RESTORE_TASK_STATUS > CALCULATED_STATUS
        // taskStatus 3 represents 'Finished' state. We need to determine if it
        // finished successfully or if there was an error
        return restoreTask.performRestoreTaskState.base.error ?
          // Error : Success
          4 : 3;
      } else if (taskStatus === 7) {
        // Cancelled
        return 9;
      } else if (taskStatus > 3) {
        // Mapping: ENUM_RESTORE_TASK_STATUS > CALCULATED_STATUS
        // Status greater than 3 is interpreted as 'running' in the UI
        return 1;
      }

      // Catch all response, just in case none of the above conditions are met
      return taskStatus;
    }

    /**
     * calls API for 'restore' tasks (which can include both restore and clone
     * tasks) and runs results through processing
     * NOTE: if you want clone tasks or restore tasks specifically use
     * RestoreService.getRestoreTasks() or RestoreService.getCloneTasks()
     *
     * @param      {Object}    restoreJobParams   API documented restore
     *                                            parameters
     * @return     {object}   promise to resolve request
     */
    function getTasksWrapper(restoreJobParams) {
      return getTasks(restoreJobParams).then(
        function getTasksSuccess(response) {
          var tasks = processRestoreTasksData(response.data);

          // If _includeTenantInfo is true, add the tenant info in the task.
          if(restoreJobParams._includeTenantInfo) {
            return TenantService.resolveTenantDetails(tasks, '_tenantId')
              .then(function updatedTaskSummary(updatedTasks) {
                return updatedTasks;
              });
          }
          return tasks;
        });
    }

    /**
     * calls API for 'restore' tasks and runs results through processing,
     * filtering for only restore tasks
     *
     * @param      {Object}    params   API documented restore parameters
     * @return     {object}    promise to resolve request
     */
    function getRestoreTasks(params) {
      var restoreTaskTypes = [
        'kMountFileVolume',
        'kMountVolumes',
        'kSystem',
        'kRecoverApp',
        'kRecoverSanVolume',
        'kRecoverVMs',
        'kRestoreFiles',
        'kRecoverVolumes',
        'kDownloadFiles',
        'kRecoverEmails',
        'kRecoverDisks',
        'kCloneAppView',
        'kRecoverNamespaces',
        'kRecoverO365Drive',
      ];

      params = params || {};

      if (!params.restoreTypes || !params.restoreTypes.length) {
        // Request only restore/recover type tasks.
        params.restoreTypes = restoreTaskTypes;
      }

      return getTasks(params).then(
        function httpSuccess(response) {
          var tasksList = processRestoreTasksData(response.data);
          if (params._includeTenantInfo) {
            return TenantService.resolveTenantDetails(tasksList, '_tenantId');
          }
          return tasksList;
        }
      );
    }

    /**
     * Gets list of restore tasks for all successful app clones and recoveries.
     * Performs client side filter for application environment and entity id of
     * the original id.
     *
     * @method   getRestoreTasks
     * @param    {string}   environment   Environment to filter by
     * @param    {number}   [entityId]    Filter by entity id of the original,
     *                                    cloned or recovered object.
     * @param    {options}  [options]     Additional request options for restore
     * @return   {array}    A list of recovery and clone tasks filtered for sql
     */
    function getAppRestoreTasks(environment, entityId, options) {
      options = merge(options || {}, {
        restoreTypes: ['kCloneApp', 'kRecoverApp']
      });
      return getRestoreTasks(options).then(function processTasks(tasks) {
        return tasks.filter(function findEnv(task) {
          var found = true;
          var restoreParams = get(
            task, '_envTaskParams.restoreAppTaskState.restoreAppParams');
          var appEntity =get(
            restoreParams, 'restoreAppObjectVec[0].appEntity', {});

          // Find AppEntity in a child task in case of MSSQL parent-child
          // recovery task model.
          if (isEmpty(appEntity)) {
            // Get all the child task app restore params
            var childTaskVec = get(task,
              '_envTaskParams.restoreAppTaskState.childRestoreAppParamsVec', []);

            // Find the corresponding child task restore params
            var childRestoreParams = find(childTaskVec,
              function findRestoreParams(childTask) {
                return get(childTask,
                  'restoreAppObjectVec[0].appEntity.id') === entityId;
            });

            appEntity = get(
              childRestoreParams, 'restoreAppObjectVec[0].appEntity', {});
          }

          if (environment && get(restoreParams, 'type') !==
            ENV_TYPE_CONVERSION[environment]) {
            found = false;
          }

          if (found && appEntity && entityId && appEntity.id !== entityId) {
            found = false;
          }

          // If found, decorate the task
          if (found) {
            assign(task, {
              _params: restoreParams,
              _appEntity: appEntity,
              _restoreType: ENUM_RESTORE_TYPE[task._envTaskParams.base.type],
              _user: task._envTaskParams.base.user,
            });
          }

          return found;
        });
      });
    }

    /**
     * calls API for 'clone' tasks and runs results through processing,
     * filtering for only clone tasks
     *
     * @param      {Object}   params   API documented restore parameters
     * @return     {object}   promise to resolve request
     */
    function getCloneTasks(params) {
      var cloneTaskTypes = ['kCloneVMs', 'kCloneViews'];

      if (!params.restoreTypes || !params.restoreTypes.length) {
        // Request only clone type tasks.
        params.restoreTypes = cloneTaskTypes;
      }

      return getTasks(params).then(
        function httpSuccess(response) {
          return processRestoreTasksData(response.data);
        }
      );
    }

    /**
     * takes a restore task id and return a promise to get the specific task and
     * returns a promise that should result in the desired data
     *
     * @param      {Number}   taskId   the id of the desired task
     * @param      {object}   params   restore task params
     * @return     {object}            returns a promise
     */
    function getTask(taskId, params={}) {
      let headers = {};
      if ( params?.regionId) {
        headers = { regionId: params.regionId };
      }

      return $http({
        method: 'get',
        url: API.private('restoretasks', taskId),
        headers: {
          ...NgPassthroughOptionsService.requestHeaders,
          headers
        }
      }).then(
        function getTaskRequestSuccess(response) {
          return processRestoreTasksData(response.data);
        }
      );
    }

    /**
     * Returns the required vlan params for given selected vlan
     *
     * @method     getVlanParams
     * @param      {object}   [selectedVlanTarget]   vlan target
     * @param      {boolean}  [publicApi=false]      If it is for public api
     * @return     {object}                          vlan params required
     */
    function getVlanParams(selectedVlanTarget, publicApi) {

      // Public and private apis got different keys
      var vlanKey = publicApi? 'vlan' : 'vlanId';

      var vlanParameters = {};

      // if there is an ID on the object add the vlanId param to the API object
      // else we need to check explicitly if the ID is null because its the ID
      // for DO NOT USE VLAN and a seperate property (disableVlan) must be
      // passed
      if (selectedVlanTarget && (selectedVlanTarget.id !== undefined)) {
        vlanParameters[vlanKey] = selectedVlanTarget.id;
        vlanParameters.interfaceName =
          selectedVlanTarget.ifaceGroupName.split('.')[0];
      } else if (selectedVlanTarget && selectedVlanTarget.id === null) {
        vlanParameters.disableVlan = true;
      } else {
        vlanParameters = undefined;
      }

      return vlanParameters;
    }

    /**
     * Takes an archive restore task id to get the specific task and returns a
     * promise that should result in the desired data
     *
     * @param      {Number}   archiveTaskId   The id of the desired task
     * @return     {object}    returns a promise
     */
    function getArchiveTask(archiveTaskId) {
      return $http({
        method: 'get',
        url: API.private('restoretasks', archiveTaskId),
      }).then(
        function getTaskRequestSuccess(response) {
          return processRetrievalTasksData(response.data)[0];
        }
      );
    }

    /**
     * Creates a retrieveFromArchive task
     *
     * @method     retrieveFromArchive
     * @param      {Object}   task    CreateRetrieveArchiveTaskArg
     * @return     {object}   $q promise
     */
    function retrieveFromArchive(task) {
      var httpOpts = {
        method: 'post',
        url: API.private('retrieveFromArchive'),
        data: task || {}
      };

      return $http(httpOpts);
    }

    /**
     * takes a restoreObject (as defined in API docs) and posts it to the
     * restore endpoint. returns a promise based on the post
     *
     * @param      {Object}   restoreObject   restoreObject as outlined in the
     *                                        API docs
     * @return     {object}   returns a promise to be handled by the calling
     *                        controller
     */
    function restoreVM(restoreObject) {
      return $http({
        method: 'post',
        url: API.private('restore'),
        data: restoreObject,
      }).then(function reduceResponse(resp) {
        return processRestoreTasksData([resp.data])[0];
      });
    }

    /**
     * takes a cloneArg object (as defined in API docs) and posts it to the
     * clone endpoint. returns a promise based on the post
     *
     * @param      {Object}   cloneArg   clone arguments as defined in the API
     *                                   docs
     * @return     {object}   returns a promise to be handled by the calling
     *                        controller
     */
    function clone(cloneArg) {
      return $http({
        method: 'post',
        url: API.private('clone'),
        data: cloneArg,
      }).then(function reduceResponse(resp) {
        if (resp.data?.quorumResponse?.id) {
          // Don't show a regular snackbar message if the recovery
          // task is a quorum request.
          return;
        }
        return processRestoreTasksData([resp.data])[0];
      });
    }

    /**
     * Takes a deployArg object and posts it to the deploy endpoint.
     * Returns a promise based on the post
     *
     * @param      {Object}   deployArg  deploy arguments
     * @return     {object}   returns a promise to be handled by the calling
     *                        controller
     */
    function deployToCloud(deployArg) {
      return $http({
        method: 'post',
        url: API.private('deploy'),
        data: deployArg,
      }).then(function reduceResponse(resp) {
        return processRestoreTasksData([resp.data])[0];
      });
    }

    /**
     * requests for destroy of an existing clone task
     *
     * @param      {Number}   id      of the clone task to be destroyed
     * @return     {object}    promise to resolve request
     */
    function destroyCloneTask(id) {
      return $http({
        method: 'post',
        url: API.private('destroyclone', id),
      }).then(
        function destroyRequestSuccess(response) {
          // fake getTasks API call return structure in order to use our process
          // function as-is
          var destroyArray = [{
            restoreTask: response.data.restoreTask
          }];
          return processRestoreTasksData(destroyArray)[0];
        }
      );
    }

    /**
     * Requests to cancel an existing restore task
     *
     * @method     cancelRestoreTask
     * @param      {Number}  id      of the restore task to be canceled
     * @return     {object}  promise to resolve request
     */
    function cancelRestoreTask(id) {
      return $http({
        method: 'put',
        url: API.public('restore/tasks/cancel', id),
      }).then(
        function cancelRequestSuccess(response) {
          return response.data || {};
        }
      );
    }

    /**
     * Request to return a VMs virtual disk info
     *
     * @method    getVirualDiskInfo
     * @param     {Object}    params    vm entity params
     * @return    {Object}    Promise to resolve request
     */
    function getVirtualDiskInfo(params) {
      return $http({
        method: 'get',
        url: API.public('restore/virtualDiskInformation'),
        params: params,
      }).then(function getVirtualDiskSuccess(resp) {
        return resp.data || [];
      });
    }

    /**
     * Gets the datastores list for the target VM entity.
     *
     * @method   getTargetDatastores
     * @param    {object}   params   The parameters
     * @return   {Object}   Promise to resolve request
     */
    function getTargetDatastores(params) {
      return $http({
        method: 'get',
        url: API.public('protectionSources/datastores'),
        params: params
      }).then(function getDatastoresSuccess(resp) {
        return resp.data || [];
      });
    }

    /**
     * Sets up a modal to teardown a task. Handles task types and appropriate
     * strings internally.
     *
     * @method     teardownTaskModal
     * @param      {object}   task    The full restoreTask to destroy.
     */
    function teardownTaskModal(task) {
      var text = $rootScope.text.servicesRestoreService;

      // @type {object}  - The deferred promise we're returning
      var deferred = $q.defer();

      // @type {Number}  - The task type
      var taskType = (task.performRestoreTaskState) ?
              task.performRestoreTaskState.base.type : 2;

      // @type {Number}  - The task's object type. Overwritten lower in this
      // Fn.
      var entityType = 1;

      // @type {object}  - A faux-$scope for use with $interpolate.
      var fauxScope = {
        flowType: (6 === taskType) ? 'recover' : 'clone',

        // The full task proto
        restoreTask: task,
      };

      // @type {object}  - Modal options (strings are $interpolated)
      var options;

      // @type {object}  - Type-specific strings for teardown modal
      var typeModalStrings;

      // @type {object}  - Placeholder for deeper task property (SQL specific).
      var restoreAppParams;

      switch (taskType) {
        // Recover App. This should only apply to active directory.
        // Other recover apps shouldn't get to this point unless they
        // can actually be torn down.
        case ENUM_RESTORE_TYPE.kRecoverApp:
          entityType = task.performRestoreTaskState.restoreInfo.type;
          break;

        // SQL & Oracle
        // Expose as View
        case ENUM_RESTORE_TYPE.kCloneApp:
        case ENUM_RESTORE_TYPE.kCloneAppView:
          restoreAppParams =
            task.performRestoreTaskState.restoreAppTaskState.restoreAppParams;

          entityType =
            Array.isArray(restoreAppParams.restoreAppObjectVec) ?

              // App entity type (SQL for now).
              // NOTE: the above Array.isArray() check may no longer be
              // relevant. Is it possible restoreAppParams.type can be assigned
              // directly to entityType?
              // https://cohesity-review.appspot.com/203827003
              restoreAppParams.type :

              // App owner type (server)
              task.performRestoreTaskState.restoreAppTaskState.ownerRestoreInfo.ownerObject.type;
          break;

        // convert and deploy
        case 9:

        // cloudSpin
        case 13:
          task._targetDisplayName = ENUM_ENV_TYPE
            [task.performRestoreTaskState.restoreParentSource.type];
          break;

        // Everything else
        default:
          // NOTE: This may break if/when we support mixed-entity task
          // tear-downs, ie. file+folder.
          entityType = (task.performRestoreTaskState) ?
            task.performRestoreTaskState.objects[0].entity.type : 1;
      }

      typeModalStrings = (text.destroyModal.types[taskType]) ?
        text.destroyModal.types[taskType][entityType] : {};

      options = {
        title: $interpolate(typeModalStrings.title)(fauxScope),
        content: $interpolate(typeModalStrings.content)(fauxScope),
        closeButtonText: $interpolate(text.destroyModal.cancel)(fauxScope),
        actionButtonText: $interpolate(text.destroyModal.destroy)(fauxScope),
      };

      // Show a confirmation modal before destroying.
      return cModal.showModal({}, options)
        .then(function cModalConfirmed() {
          RestoreService
            // Destroy the task
            .destroyCloneTask(task.performRestoreTaskState.base.taskId)

            // Handle the deferred promise accordingly
            .then(deferred.resolve, function destroyFailed(resp) {
              // Here we handle the server's response
              evalAJAX.errorMessage(resp);

              // This will let the calling function handle just this modal's
              // response.
              deferred.reject(resp);
            });
          return deferred;
        });
    }

    /**
     * Download Restore debug log
     *
     * @method     downloadRestoreDebugLog
     * @param      {string}   taskId    The taskId to download debug log.
     */
    function downloadRestoreDebugLog(taskId) {
      // object doesn't exist in performRestoreTaskState. Cannot find
      // clusterId and clusterIncarnationId. Use 0 instead as we don't
      // need them anyway.
      // Example: data-protect/recoveries/0:0:1000/debug-logs
      var recoveryId = ['0', '0', taskId].join(':');
      var url = API.publicV2('data-protect/recoveries/' + recoveryId + '/debug-logs');
      $window.open(url);
    }

    /**
     * Sets up a modal to cancel a task. Handles task types and appropriate
     * strings internally.
     *
     * @method     cancelTaskModal
     * @param      {object}  taskOptions  Object containing the restore/archive
     *                                    taskId to cancel, and the entityType
     * @param      {string}  type    The type of restore: clone or recovery
     * @return     {object}  promise to resolve the API request
     */
    function cancelTaskModal(taskOptions, type) {
      var modalConfig = {
        templateUrl: 'app/protection/recovery/common/cancel-modal.html',
        controller: function cancelTaskModalCtrl() {
          this.isSQL = ENV_TYPE_CONVERSION.kSQL === taskOptions.entityType;
        },
      };

      var options = {
        titleKey: type === 'clone' ?
          'clone.cancel.title' : 'restore.cancel.title',
        closeButtonKey: 'no',
        actionButtonKey: 'yes',
      };

      // Show a confirmation modal before canceling.
      return cModal.standardModal(modalConfig, options)
        .then(function cModalConfirmed() {
          return RestoreService.cancelRestoreTask(taskOptions.id)
            .then(
              function cancelSucceeded(resp) {
                cMessage.success({
                  textKey: 'restore.cancel.success',
                });
                return resp;
              },
              evalAJAX.errorMessage
            );
        });
    }

    /**
     * posts fileVerionsArg to the API endpoint to retrive a list of avaiable
     * versions for a particular file and returns a promise to resolve when the
     * call is completed
     *
     * @param      {Object}    fileVersionsArg        file version arguments
     * @return     {object}    promise to resolve once API call completes
     */
    function getFileVersions(fileVersionsArg) {
      var deferred = $q.defer();
      var directArchivePromise =
        filterDirectArchiveSnapshots(fileVersionsArg.jobDetailsId);

      $http({
        method: 'get',
        url: API.private('file/versions'),
        params: fileVersionsArg
      }).then(function gotFileVersions(resp) {
        directArchivePromise
          .then(function promiseResolved(promiseResolver) {
            resp.data.versions =
              promiseResolver().filterDirectArchive(resp.data.versions);
            deferred.resolve(resp);
          });
      });

      return deferred.promise;
    }

    /**
     * Direct Archive Jobs do not have any local snapshots. Filter those out.
     * This function acceps a jobId to check whether the job is directArchive
     * enabled or not. If yes, it provides a callback which accepts snapshot
     * versions and filters out the non directArchive Snapshots from them.
     * If the job is not directArchive enabled, it returns back the versions
     * passed to it.
     *
     * @method  filterDirectArchiveSnapshots
     * @param   {Object[]} jobId The jobId of job to be checked for
     *                           directArchive
     * @return  {Object}   Promise to be resolved with a callback for filtering
     *                     directArchiveSnapshots.
     */
    function filterDirectArchiveSnapshots(jobId) {
      var deferred = $q.defer();
      var retVersions = [];
      var jobPromise = JobService.getJob(jobId);

      jobPromise.then(function gotJob(job) {
        deferred.resolve(function promiseResolver() {
          return {
            filterDirectArchive: function filterVersions(versions) {
              if (job.isDirectArchiveEnabled) {
                each(versions, function eachVersion(version) {
                  version.replicaInfo.replicaVec =
                    filter(version.replicaInfo.replicaVec,
                      function filterReplica(replica) {
                        return replica.target.type ===
                          SNAPSHOT_TARGET_TYPE.kArchival;
                      });

                  if (version.replicaInfo.replicaVec.length) {
                    retVersions.push(version);
                  }
                });
              } else {
                retVersions = versions;
              }
              return retVersions;
            },
            isDirectArchiveEnabled: job.isDirectArchiveEnabled,
          };
        });
      });

      return deferred.promise;
    }

    /**
     * redirect the browser to the download api URL
     *
     * @param      {Object}   downloadFileArg   file argument as outlined in API
     *                                          docs
     */
    function downloadFile(downloadFileArg) {
      NgFileDownloadService.downloadFile(downloadFileArg);
    }

    /**
     * download file from provided recovery task snapshot
     *
     * @param   {Object}   taskState   The recovery task state object containing
     *                                 file document and snapshot details for
     *                                 downloading a file
     */
    function downloadFileFromRecoveryTask(taskState) {
      var taskObject = taskState.objects[0];
      var params = {
        jobId: taskObject.jobId,
        filepath:
          taskState.restoreFilesTaskState.restoreFilesInfo.downloadFilesPath,
        viewBoxId: taskState.viewBoxId,
        viewName: taskState.fullViewName,
        clusterId: get(taskObject.jobUid, 'clusterId'),
        clusterIncarnationId: get(taskObject.jobUid, 'clusterIncarnationId'),
      };

      downloadFile(params);
    }

    /**
     * download file from provided snapshot
     *
     * @param   {String}   filePath      The path of the file to download from
     * @param   {Object}   documentObj   The file document info
     * @param   {Object}   snapshot      The snapshot info from where to
     *                                   download the file
     */
    function downloadFileFromSnapshot(filePath, documentObj, snapshot) {
      var params = {
        jobId: get(documentObj.objectId.jobUid, 'objectId') ||
          documentObj.objectId.jobId,
        filepath: filePath,
        entityId: documentObj.objectId.entity.id,
        viewBoxId: documentObj.viewBoxId,
        clusterId: get(documentObj.objectId.jobUid, 'clusterId'),
        clusterIncarnationId:
          get(documentObj.objectId.jobUid, 'clusterIncarnationId'),

        // snapshot params
        jobInstanceId: snapshot.instanceId.jobInstanceId,
        jobStartTimeUsecs: snapshot.instanceId.jobStartTimeUsecs,
        attemptNum: snapshot.instanceId.attemptNum,
      };

      downloadFile(params);
    }

    /**
     * Restore files to Source
     *
     * @method     restoreFiles
     * @param      {object}   data    RestoreFileParams Proto
     * @return     {object}   Promise to resolve with restore task data, or raw
     *                        response if failure.
     */
    function downloadFilesAndFolders(data) {
      return $http({
        method: 'post',
        url: API.public('restore/downloadFilesAndFolders'),
        data: data
      }).then(function downloadTaskCreatedFn(resp) {
        return resp.data || {};
      });
    }

    /**
     * returns a promise which should contain VMDiskInfoResult_DiskInfo
     *
     * @return     {object}   returns a promise to be handled by the calling
     *                        controller
     */
    function getVolumeInfo(params) {
      return $http({
        method: 'get',

        // Use sanitizeParameters method instead of delegating url encoding to
        // angular by using 'params' key since angular doesn't encode characters
        // like semicolon implicitly which could be present in dirPath.
        url: API.private('vm/volumeInfo?' + sanitizeParameters(params)),
      }).then(
        function getVolumeSuccess(r) {
          r.data.volumeInfos = r.data.volumeInfos || [];
          return r.data;
        }
      );
    }

    /**
     * returns a promise which should contain getDirectoryInfo
     *
     * @method   getDirectoryInfo
     * @param    {@object}   params   query params for get Dir info request.
     * @return   {@object}            returns a promise to be handled by the
     *                                calling controller.
     */
    function getDirectoryInfo(params) {
      return $http({
        method: 'get',

        // Use sanitizeParameters method instead of delegating url encoding to
        // angular by using 'params' key since angular doesn't encode characters
        // like semicolon implicitly which could be present in dirPath.
        url: API.private('vm/directoryList?' + sanitizeParameters(params)),
      }).then(
        function getDirectoryInfoSuccess(r) {
          r.data.entries = r.data.entries || [];
          return r.data;
        }
      );
    }

    /**
     * fetch stat information for file or directory.
     *
     * @method   getStatInfo
     * @param    {@object}   params   query params for get stat info request.
     * @return   {@object}            a promise that will resolve to stat info
     *                                or rejection reason.
     */
    function getStatInfo(params) {
      return $http({
        method: 'get',

        // Use sanitizeParameters method instead of delegating url encoding to
        // angular by using 'params' key since angular doesn't encode characters
        // like semicolon implicitly which could be present in dirPath.
        url: API.private('file/stat?' + sanitizeParameters(params)),
      }).then(
        function getDirectoryInfoSuccess(r) {
          return r.data.fstatInfo || {};
        }
      );
    }

    /**
     * Generates a list of status counts based on provided array of retrieval
     * tasks
     *
     * @param      {Array}    tasks   array of retrievalTasks
     * @return     {Object}   object containing the counts of various status
     *                        types
     */
    function getRetrievalStatusCounts(tasks) {
      var counts = {
        total: 0,
        running: 0,
        success: 0,
        error: 0,
        canceled: 0,
      };

      if (tasks) {
        tasks.forEach(function countTask(task) {
          counts.total++;
          switch (task._status) {
            case 0:
              counts.running++;
              break;
            case 1:
              counts.running++;
              break;
            case 2:
              counts.error++;
              break;
            case 3:
              counts.success++;
              break;
            case 4:
              counts.error++;
              break;
            case 5:
              counts.running++;
              break;
            case 6:
              counts.running++;
              break;
            case 7:
              counts.running++;
              break;
            case 8:
              counts.running++;
          }
        });
      }

      return counts;
    }

    /**
     * generates a list of status counts based on provided array of tasks
     *
     * @param      {Array}    tasks   array of restoreTasks as returned from
     *                                getCloneTasks or getRestoreTasks
     * @return     {Object}   object containing the counts of various status
     *                        types
     */
    function getStatusCounts(tasks) {
      var counts = {
        total: 0,
        running: 0,
        scheduled: 0,
        success: 0,
        errors: 0,
        warnings: 0,
        destroys: 0,
        destroyErrors: 0,
        refreshing: 0,
        refreshErrors: 0,
      };
      if (tasks) {
        tasks.forEach(function(task) {
          counts.total++;
          switch (task._status) {
            case RESTORE_TASK_UI_STATUS.kScheduled:
              counts.scheduled++;
              break;
            case RESTORE_TASK_UI_STATUS.kRunning:
              counts.running++;
              break;
            case RESTORE_TASK_UI_STATUS.kMigrating:
              // migrating, considering part of run
              counts.running++;
              break;
            case RESTORE_TASK_UI_STATUS.kSuccess:
              counts.success++;
              break;
            case RESTORE_TASK_UI_STATUS.kError:
              counts.errors++;
              break;
            case RESTORE_TASK_UI_STATUS.kDestroyScheduled:
              // destroy scheduled
              counts.scheduled++;
              break;
            case RESTORE_TASK_UI_STATUS.kDestroying:
              // destroy running
              counts.running++;
              break;
            case RESTORE_TASK_UI_STATUS.kDestroyed:
              // destroy success
              counts.destroys++;
              break;
            case RESTORE_TASK_UI_STATUS.kDestroyError:
              // destroy error, also counts as a destroy
              counts.destroys++;
              counts.destroyErrors++;
              break;
            case RESTORE_TASK_UI_STATUS.kRefreshing:
              counts.refreshing++;
              counts.running++;
              break;
            case RESTORE_TASK_UI_STATUS.kRefreshError:
              counts.errors++;
              counts.refreshErrors++;
              break;
          }
        });
      }

      return counts;
    }

    /**
     * Computes status related UI data for fileRestoreTasks
     *
     * @param      {Object}   task    FileRestoreTask object
     * @return     {Object}   Status related object
     */
    function getFileRestoreTaskStatus(task) {

      var text = $rootScope.text.servicesRestoreService;

      var statusObject = {
        status: null,
        className: null,
        error: null
      };

      if (task.status !== 2) {
        statusObject.status = ENUM_RESTORE_FILE_STATUS[task.status];
      } else {
        if (task.error) {
          statusObject.status = text.error;
          statusObject.className = 'status-critical';
          statusObject.error = task.error;
        } else {
          statusObject.status = text.success;
          statusObject.className = 'status-ok';
        }
      }

      return statusObject;
    }

    /**
     * Parse an absolute path. The logic here is also copied to:
     * src/app/modules/restore/restore-shared/model/recovery-file-object.ts.
     *
     * @param      {String}   restoredFileInfo.absolutePath   File path
     * @return     {Object}   File object with isolated properties
     */
    function getRestoreFileOrFolderNameAndPath(path) {
      var array = path.split('/');
      var obj = {
        name: array.pop(),
        path: array.join('/') || '/'
      };
      return obj;
    }

    /**
     * Parses the download file path and returns the name of the file to be
     * downloaded
     *
     * @method   getDownloadFileName
     * @param    {Object}   restoreTask   The restore task
     * @return   {String}   The name of the file to be downloaded
     */
    function getDownloadFileName(restoreTask) {
      return get(restoreTask.performRestoreTaskState,
        'restoreFilesTaskState.restoreFilesInfo.downloadFilesPath', '')
        .split('/').pop();
    }


    /**
     * It decorates the task objects with useful properties like name, path,
     * whether the object is file or a directory.
     *
     * @method   decorateRestoreTaskObjects
     * @param    {Object}   restoreTask   The restore task
     * @return   {Object}   Decorated task object
     */
    function decorateRestoreTaskObjects(restoreTask) {
      return restoreTask.performRestoreTaskState
        .restoreFilesTaskState.restoreParams.restoredFileInfoVec.map(
          function eachRecoveredFile(file) {
            var fullPath = [file.volumePath, file.absolutePath].join('');
            var fileObj = getRestoreFileOrFolderNameAndPath(fullPath);
            return {
              _name: fileObj.name,
              path: fileObj.path,
              sourceName: restoreTask.performRestoreTaskState
                .objects[0].entity.displayName,
              isDirectory: file.isDirectory,
            };
          }
        );
    }

    /**
     * Restore files to Source
     *
     * @method     restoreFiles
     * @param      {object}   data    RestoreFileParams Proto
     * @return     {object}   Promise to resolve with restore task data, or raw
     *                        response if failure.
     */
    function restoreFiles(data) {
      return $http({
        method: 'post',
        url: API.private('restoreFiles'),
        data: data
      }).then(function restoreTaskCreatedFn(resp) {
        return resp.data.restoreTask || {};
      });
    }

    /**
     * Constructs the query arguments for getting a file's version data
     *
     * @method     fileVersionsArg
     * @param      {Object}   fileEntity   File Entity
     * @return     {Object}   Params for fetching a file's versions data
     */
    function fileVersionsArg(fileEntity) {
      var params;
      if (!fileEntity.fileDocument) {
        return {};
      }
      params = {
        jobId: fileEntity.fileDocument.objectId.jobId,
        entityId: fileEntity.fileDocument.objectId.entity.id,
        filename: fileEntity.fileDocument.filename,
        fromObjectSnapshotsOnly: !!fileEntity.fromObjectSnapshotsOnly,
      };
      if (fileEntity.fileDocument.objectId.jobUid) {
        angular.extend(params, {
          jobId: fileEntity.fileDocument.objectId.jobUid.objectId,
          clusterId: fileEntity.fileDocument.objectId.jobUid.clusterId,
          clusterIncarnationId:
            fileEntity.fileDocument.objectId.jobUid.clusterIncarnationId
        });
      }
      return params;
    }

    /**
     * Creates a RestoreObjectProto compatible object from the params
     *
     * @method     getRestoreObjectProto
     * @param      {Object}   entityDocument   Ex. vmDocument, fileDocument,
     *                                         sqlDocument, etc.
     * @param      {Object}   restorePoint     Snapshot Restore Point object
     *                                         (varies by entity)
     * @return     {Object}   RestoreObjectProto (see struct within)
     */
    function getRestoreObjectProto(entityDocument, restorePoint) {
      var restoreObject;

      // Sanity check
      if (!entityDocument) {
        return restoreObject;
      }

      // If no restorePoint is defined, fall back on the first version in the
      // entityDocument
      // @type  {object}
      restorePoint = restorePoint || entityDocument.versions[0];

      /*
      restoreObject = {
        jobUid: UniversalIdProto. v2.5+
        jobId: Integer,
        jobInstanceId: Integer,
        startTimeUsecs: Integer,
        entity: EntityProto
        parentSource: EntityProto - id is sufficient
        archivalTarget: ArchivalTarget
      };
      */
      restoreObject = {
        jobId: entityDocument.objectId.jobId,
        jobInstanceId: restorePoint.instanceId.jobInstanceId,
        startTimeUsecs: restorePoint.instanceId.jobStartTimeUsecs,
        // If this entity is a physical server
        parentSource: (6 === entityDocument.objectId.entity.type) ?
          // Don't set anything
          undefined :
          // Otherwise set the parent id if its available
          (entityDocument.objectId.entity.parentId ?
            { id: entityDocument.objectId.entity.parentId } :
            undefined),
        entity: entityDocument.objectId.entity,
      };

      // Pre v2.5 data won't have this, so we check for it
      if (entityDocument.objectId.jobUid) {
        restoreObject.jobUid = entityDocument.objectId.jobUid;
      }
      return restoreObject;
    }

    /**
     * Recover Application
     *
     * @method   recoverApplication
     * @param    {Object}   data   The RestoreAppArg
     * @return   {Object}   On success returns restoreTask, and on fail returns
     *                      server's response.
     */
    function recoverApplication(data) {
      var opts = {
        method: 'post',
        url: API.private('recoverApplication'),
        data: data,
      };

      return $http(opts)
        .then(function restoreTaskCreatedFn(resp) {
          return resp.data.restoreTask || {};
        });
    }

    /**
     * Clone Application
     *
     * @method     cloneApplication
     * @param      {object}   data    RestoreAppArg
     * @return     {object}   On success returns restoreTask, and on fail
     *                        returns server's response.
     */
    function cloneApplication(data) {
      var opts = {
        method: 'post',
        url: API.private('cloneApplication'),
        data: data
      };
      return $http(opts)
        .then(function restoreTaskCreatedFn(resp) {
          return resp.data.restoreTask || {};
        });
    }

    /**
     * Submits a clone view request to the API.
     *
     * @method     cloneView
     * @param      {object}   data    API required and optional params
     * @return     {object}   Promise to resolve with the restoreTask, raw
     *                        response if failure.
     */
    function cloneView(data) {
      /**
       * This request is a POST call, and not a GET call intentionally. Because
       * the request parm for one or more jobs is a jobUid object (which itself
       * has 3 properties), we can't guarantee that splitting those into
       * respective sub-property url param arrays will maintain their order and
       * be associated with the correct id+clusterId+clusterIncarnationId at the
       * receiving end. So we decided to make this a POST request so we can pass
       * a JSON payload.
       *
       * While native Ajax allows this, AngularJS's $http does not. It ignores
       * the data property in GET requests. :(
       *
       * Because of this choice, this "public" API is not published by iris
       * Backend and remains effectively private until we can find a better
       * solution.
       */
      var opts = {
        method: 'post',
        url: API.private('clone'),
        data: data
      };
      return $http(opts)
        .then(function cloneViewSuccess(response) {
          return response.data.restoreTask || {};
        });
    }

    /**
     * Checks validity of the provided database restore snapshot+custom time
     *
     * @method     validateDatabaseRestoreTime
     * @param      {options}   params   The CheckRestoreAppTaskArg
     * @return     {object}    Promise to resolve with the requested data; raw
     *                         response if failure.
     */
    function validateDatabaseRestoreTime(params) {
      var opts = {
        method: 'post',
        data: {
          restoreAppParams: params
        },
        url: API.private('restoreApp/validate'),
      };

      return $http(opts)
        .then(function validationReceived(resp) {
          return resp.data || {};
        });
    }

    /**
     * Get the valid Database restore time ranges based on a task config
     *
     * @method     getDBTimeRanges
     * @param      {object}   data    The GetRestoreAppTimeRangesArg
     * @return     {object}   Promise to resolve with the requested data; raw
     *                        response if failure.
     */
    function getDBTimeRanges(data) {
      var opts = {
        method: 'post',
        data: data,
        url: API.private('restoreApp/timeRanges')
      };

      return $http(opts)
        .then(function validationReceived(resp) {
          return resp.data || {};
        });
    }

    /**
     * Get the PIT ranges & info for an entity and date range.
     *
     * @method   getRestorePointsForTimeRange
     * @param    {object}   [requestArg]   Query params.
     * @return   {object}   Promise with the requested data.
     */
    function getRestorePointsForTimeRange(requestArg) {
      // Baking our own cache mechanism here because AJS doesn't cache http post
      // requests. Need this because this is an expensive call on clusters with
      // long runs histories. Only cache if a defined endTimeUsecs is less than
      // right now.
      var useCache =
        get(requestArg, 'endTimeUsecs') < Date.clusterNow() * 1000;

      // Using AJS's toJson here to ignore $ and $$ prefixed keys.
      var cacheKey = angular.toJson(requestArg);

      if (PIT_CACHE[cacheKey]) {
        // cloneDeep is necessary here and within the response handler because
        // the timeRanges array gets emptied out somehow (no RCA on that).
        return $q.resolve(cloneDeep(PIT_CACHE[cacheKey]));
      }

      return $http({
        method: 'post',
        url: API.public('restore/pointsForTimeRange'),
        data: requestArg,
        transformResponse:
          PubRestoreServiceFormatter.transformPointsForTimeRange,
      })
      .then(function handleSuccess(resp) {
        if (useCache) {
          PIT_CACHE[cacheKey] = cloneDeep(resp.data);
        }
        return isEmpty(resp.data) ? $q.reject(resp.data) : resp.data;
      });
    }

    /**
     * Generates a default Restore Task name
     *
     * @method     getDefaultTaskName
     * @param      {string}   flowType     The flow type name: [recover, clone,
     *                                     failover, cloneRefresh]
     * @param      {string}   adapterType  The adapter type name [sql, VM, File,
     *                                     etc]
     * @return     {string}   The default Restore Task name.
     */
    function getDefaultTaskName(flowType, adapterType, entity) {
      // TODO: Replace the entityType to adapterType where getDefaultTask is
      // referenced.

      var defaultTaskNameTemplate =
        '{{flowType | capitalize}}-{{entityName}}_{{date | msecsToDate}}';
      var entityName = [];
      var data;

      // If entity is supplied then based on type generate the name. Else
      // generate default entity name
      if (entity) {
        switch (true) {
          case /sql/i.test(adapterType) || /oracle/i.test(adapterType):
            // Host Name or IP
            entityName.push(entity.vmDocument.objectAliases[0]);

            // DB Name and Instance
            entityName.push(entity._name.replace(/\//g, '_'));
            break;

          case /vm/i.test(adapterType):
            // Add the VM Name or the VCenter name
            entityName.push(entity._name);
            break;
        }
      } else {
        entityName.push(adapterType || 'VM');
      }

      data = {
        flowType: flowType || 'Recover',
        entityName: entityName.join('_'),
        date: Date.clusterNow(),
      };

      return $interpolate(defaultTaskNameTemplate)(data)
        .replace(/,/g, '')
        .replace(/[\s]+/g, '_')
        .replace(/[^\w.]+/g, '-');
    }

    /**
     * Gets the restorable Entity versions/snapshots.
     *
     * Restorable versions are: !replica target (type: 2) AND has one of [local,
     * archive] targets
     *
     * @method     getRestorableVersions
     * @param      {array}   versions   Array of versions/snapshots
     * @return     {Array}   The subset of versions/snapshots that can be
     *                       restored from
     */
    function getRestorableVersions(versions) {
      var nowUsecs = Date.clusterNow() * 1000;
      if (!Array.isArray(versions)) {
        return [];
      }
      return versions.reduce(function reduceViewVersionsFn(_versions, version) {
        // if this version has the snapshot deleted then ignore as we cannot
        // recover a deleted snapshot.
        if (!version.snapshotsDeleted) {
          // here we're looking for replicaInfo. If it's not found, move on.
          if (version.replicaInfo &&
            Array.isArray(version.replicaInfo.replicaVec)) {
            version.replicaInfo.replicaVec = version.replicaInfo.replicaVec
              .reduce(function reduceTargetsFn(_targets, target) {
                // Not a replica target
                if (2 !== target.target.type &&

                  // and is not expired
                  (!target.expiryTimeUsecs ||
                  target.expiryTimeUsecs >= nowUsecs)) {
                    _targets.push(target);
                }
                return _targets;
              }, [])

              // Sort the available targets by typeInt (local(1) first).
              .sort(function sortTargetsFn(targetA, targetB) {
                return targetA.target.type - targetB.target.type;
              });
          }

          // Now, if replicaInfo has been found or we're explicitely told there's a
          // local replica, push this version to the return list.
          if (version.hasLocalReplica || (version.replicaInfo &&
            Array.isArray(version.replicaInfo.replicaVec) &&
            version.replicaInfo.replicaVec[0])) {
              _versions.push(version);
          }
        }
        return _versions;
      }, []);
    }

    /**
     * Given a list of versions/entity snapshots, and a jobInstanceId, this
     * finds the full version obeject.
     *
     * TODO(spencer): To be implemented in recover/clone VM, SQL, Pure, and
     * Instant Volume Mount
     *
     * @method     getSnapshotByJobInstanceId
     * @param      {array}     versions   The list of version/snapshot objects
     * @param      {Number}   id         The desired jobInstanceId
     * @return     {object}    The found version, or undefined
     */
    function getSnapshotByJobInstanceId(versions, id) {
      var version;
      if (!id || !versions || !versions.length) {
        return;
      }

      versions.some(function findVersion(_version) {
        if (+id === _version.instanceId.jobInstanceId) {
          version = _version;
          return true;
        }
      });

      return version;
    }

    /**
     * Generates a restore task detail state name (Clone or Recover).
     *
     * @method     getRestoreTaskDetailStateName
     * @param      {string}   [flowType]     One of 'recover' or 'clone'.
     *                                       Defaults: 'recover'.
     * @param      {string}   [targetType]   One of 'local', 'archive'.
     *                                       Defaults: 'local'.
     * @return     {string}   The restore task detail state name.
     */
    function getRestoreTaskDetailStateName(flowType, targetType) {
      var stateName;
      flowType = flowType || 'recover';
      targetType = !targetType ? 'local' : targetType;
      stateName = [flowType, 'detail'];

      if (targetType) {
        stateName.push(targetType);
      }
      // Outputs 'clone-detail', 'recover-detail-local', or
      // 'recover-detail-archive'
      return stateName.join('-');
    }

    /**
     * Generates restore task compatible objects array from the given list of
     * selected search result entities.
     *
     * @method     generateRestoreTaskObjects
     * @param      {object|array}   objects   Single or array of search result
     *                                        objects.
     * @return     {array}          The generated list of objects
     */
    function generateRestoreTaskObjects(objects) {

      // Sanity check
      if (!objects) { return []; }

      objects = [].concat(objects);
      return objects.map(function eachObject(obj) {
        var typedDoc = obj.vmDocument || obj.fileDocument;
        var out = angular.extend(
          {},
          typedDoc.objectId,
          obj._snapshot.instanceId
        );
        // In the RecoverArg, `jobStartTimeUsecs` becomes `startTimeUsecs`
        out.startTimeUsecs = out.jobStartTimeUsecs;
        delete out.jobStartTimeUsecs;

        // If there's a selected archivalTarget and it's type is 3
        if (obj._archiveTarget &&
          obj._archiveTarget.target &&
          obj._archiveTarget.target.type &&
          3 === obj._archiveTarget.target.type) {
            out.archivalTarget = obj._archiveTarget.target.archivalTarget;
        }

        return out;
      });

    }

    /**
     * Method called to get object based snapshot information.
     *
     * @method    getObjectSnapshots
     * @param     {Integer}   objectId   object id
     * @param     {Object}    params     Snapshot Params
     * @returns   {Object}               Promise with snapshots information.
     */
    function getObjectSnapshots(objectId, params) {
      return $http({
        method: 'get',
        url: API.publicV2('data-protect/objects', objectId, 'snapshots'),
        params: params
      }).then(function fetchSnapshotsRequestSuccess(response) {
        return response.data || {};
      });
    }

    /**
     * Method called to fetch pfile parmaters metadata.
     *
     * @method    getPfileMetadata
     * @param     {Object}   dbParams     Oracle db setup parameters
     * @param     {String}   snapshotId   Snapshot id
     * @returns   {object}                Promise with oracle pfile parameters.
     */
    function getPfileMetadata(dbParams, snapshotId) {
      return $http({
        method: 'post',
        url: API.publicV2('data-protect/snapshots', snapshotId, 'metaInfo'),
        data: dbParams
      }).then(function constructMetaInfoSuccess(response) {
        return response.data || {};
      });
    }

    /**
     * Opens a modal for configuring Cloud Restore task settings.
     *
     * @method     cloudRestoreSettingsModal
     * @param      {object}   task           The current state of the restore
     *                                       Task settings.
     *
     * @param      {object}   modifiedHash   Hash of jobs with modified settings
     *                                       (by job Id).
     * @return     {object}   Promise to resolve with the original input Task
     *                        and updated Task objects.
     */
    function cloudRestoreSettingsModal(task, modifiedHash) {
      var config = {
        controller: 'CloudSearchSettingsModalController as ctrl',
        size: 'xl',
        templateUrl:
          'app/protection/cloud-retrieval/cloud-search.retrieval-settings-modal.html',
        resolve: {
          modifiedHash: modifiedHash || {},
          task: task || {},
        },
      };

      return SlideModalService.newModal(config);
    }

    /**
     * Get Remote Restore Tasks.
     *
     * @method     getRemoteRestoreTasks
     * @return     {object}   Promise to resolve with the list of tasks, or full
     *                        server response if error.
     */
    function getRemoteRestoreTasks() {
      var opts = {
        method: 'get',
        url: API.public('remoteVaults/restoreTasks'),
      };

      return $http(opts).then(RestoreServiceFormatter.transformRemoteTasks);
    }

    /**
     * Creates a remote restore task.
     *
     * @method     createRemoteRestoreTask
     * @param      {object}   [params]   The parameters
     * @return     {object}   promise to resovle with the created Restore Task,
     *                        or raw server response if failed.
     */
    function createRemoteRestoreTask(params) {
      var opts = {
        method: 'post',
        url: API.public('remoteVaults/restoreTasks'),
        data: params,
      };

      return $http(opts).then(function restoreTaskAccepted(resp) {
        return RestoreServiceFormatter.transformRemoteTasks([resp.data]).pop();
      });
    }

    /**
     * Create a Recovery Task.
     *
     * @method    recover
     * @param     {Object}   task   The RestoreTaskArg.
     * @returns   {Object}   Promise resolving with the server's task response.
     */
    function recover(task) {
      return _publicRecover(task, 'post');
    }

    /**
     * Update a Recovery Task.
     *
     * @method    updateRecoverTask
     * @param     {Object}   task   The RestoreTaskArg.
     * @returns   {Object}   Promise resolving with the server's task response.
     */
    function updateRecoverTask(task) {
      return _publicRecover(task, 'put');
    }

    /**
     * General public restore API method.
     *
     * @method    _publicRecover
     * @param     {Object}   task              The RestoreTaskArg.
     * @param     {String}   [method='post']   HTTP method to use.
     * @returns   {Object}   Promise resolving with the server's task response.
     */
    function _publicRecover(task, method) {
      var useParams = false;
      method = method || 'post';
      useParams = !/get/i.test(method);

      return $http({
        method: method,
        url: API.public('restore/recover'),
        data: !useParams || task,
        params: useParams || task,
      }).then(function recoverSubmitSuccess(resp) {
        return resp.data || {};
      });
    }

    /**
     * Creates a UpdateRestoreTaskRequest proto for the given task ID.
     *
     * @method   _setDbMigrationAction
     * @param    {Number}   taskId            The RestoreAppArg to set the
     *                                        action on.
     * @param    {Object}   [snapshot]        The selected snapshot.
     * @param    {String}   [action='sync']   One of [sync, finalize]
     * @return   {Object}   Copy of the input task with the appropriate action
     *                      set.
     */
    function _setDbMigrationAction(taskId, snapshot, action) {
      // 3 = kFinalize, 2 = kUpdate
      action = /finalize|3/.test(action) ? 'kFinalize' : 'kUpdate';

      return {
        restoreTaskId: taskId,
        sqlOptions: action,

        // TODO (spencer; 6.3.1+): Currently unused. Will need Iris backend &
        // Magneto to add support.
        // snapshot: snapshot,
      };
    }

    /**
     * Sync the given task to the given snapshot.
     *
     * NOTE: At the time of implementing this, Magneto has not implemented the
     * RPC to handle arbitrary snapshots. It will use latest by default.
     *
     * @method   syncApplicationTask
     * @param    {Object}   task         The RestoreAppArg with the sync action
     *                                   specified.
     * @param    {Object}   [snapshot]   The optional snapshot to select.
     * @return   {Object}   Promise to resolve with the sync subtask?
     */
    function syncApplicationTask(taskId, snapshot) {
      // TODO (spencer): Correct this after snapshots are accepted (6.3.1+).
      snapshot = undefined;

      return updateRecoverTask(
        _setDbMigrationAction(taskId, snapshot, 'sync')
      );
    }

    /**
     * Finalize the given task to the given snapshot.
     *
     * NOTE: At the time of implementing this, Magneto has not implemented the
     * RPC to handle arbitrary snapshots. It will use latest by default.
     *
     * @method   finalizeApplicationTask
     * @param    {Object}   task         The RestoreAppArg with the sync action
     *                                   specified.
     * @param    {Object}   [snapshot]   The optional snapshot to select.
     * @return   {Object}   Promise to resolve with the sync subtask?
     */
    function finalizeApplicationTask(taskId, snapshot) {
      // TODO (spencer): Correct this after snapshots are accepted
      snapshot = undefined;

      return updateRecoverTask(
        _setDbMigrationAction(taskId, snapshot, 'finalize')
      );
    }

    /**
     * Sets the autosync setting for the given DB migration task.
     *
     * @param    {number}   taskId           The task ID.
     * @param    {boolean}  autoSyncEnabled  The auto-sync setting value.
     * @returns  {object}   The response promise.
     */
    function setAutoSync(taskId, autoSyncEnabled) {
      const params = Object.assign(_setDbMigrationAction(taskId, null, 'update'), {
        enableAutoSync: !!autoSyncEnabled,
      });
      return updateRecoverTask(params);
    }

    /**
     * Retries the given restore task. Internally determines which API to use.
     *
     * @method   retryRestoreTask
     * @param    {object}   taskData   The task to resubmit.
     * @return   {object}   Promise carrying the new restore task.
     */
    function retryRestoreTask(taskData) {
      var restoreType = ENUM_ICON_TYPE_MAPPING.recover[taskData.base.type];
      var restoreMethod = 'restoreVM';
      var restoreState = 'recover-vm.recover-options';
      var params;
      var taskConfigPropertyKey = 'restoreTaskState';
      var newTask = {
        name: 'Retry-' + taskData.base.name,
      };

      // RDS shows as a VM, but has a different set up params that need to be checked for.
      var isRds = !!get(taskData,
        'deployVmsToCloudTaskState.deployVmsToCloudParams.deployVmsToAwsParams.rdsParams') ||
        !!get(taskData,
          'deployVmsToCloudTaskState.deployVmsToCloudParams.deployVmsToAwsParams.auroraParams');

      // Whether it is an NG Storage Volume recovery resubmit.
      // 3 -> file recovery and NAS storage volume recovery
      // 8 -> SAN storage volume recovery
      // 10 -> File volume
      // 39 -> SAN PPG recovery
      var ngStorageVolumeResubmit =
        FEATURE_FLAGS.ngRecoverStorageVolume && [3, 8, 10, 39].includes(taskData.base.type);

      if (ngStorageVolumeResubmit) {
        // 3 base type is used for file recovery and nas storagevolume recovery.
        if (!taskData.resubmitRecoveryObject ||
          taskData.resubmitRecoveryObject.recoveryAction === 'RecoverFiles') {
          // resubmitRecoveryObject is only populated if resubmitting from NG
          // recovery list. If taskData.resubmitRecoveryObject was not
          // populated, the resubmit was triggered from classic UI files
          // recovery details page. Classic UI storage volume recovery doesn't
          // have a "Resubmit" button in the recovery details page, so this will
          // never be triggered without resubmitRecoveryObject populated for
          // storage volume resubmit.
          ngStorageVolumeResubmit = false;
        }
      }

      // redirect to public api for supported ones.
      if (restoreType === 'nas' && FEATURE_FLAGS.restoreStorageVolume
        && !ngStorageVolumeResubmit) {
        return PubRestoreService.getRestoreTaskById(taskData.base.taskId)
          .then(function onGetRestorePublicDetails(response) {
            PubRestoreService.retryRestoreTask(
              PubRestoreServiceFormatter.getResubmitRestoreTask(response)
            );
          }
        );
      }

      switch (true) {
        // VM Clone (kCloneVms)
        case taskData.base.type === 2:
          restoreMethod = 'cloneVM';
          break;

        // File Recover (kRestoreFiles)
        case taskData.base.type === 3 && !ngStorageVolumeResubmit:
          restoreState = 'recover-files.options';
          taskConfigPropertyKey = 'restoreFilesTaskState';
          params = {
            taskId: taskData.base.taskId,
            jobInstanceId: get(taskData, 'objects.0.jobInstanceId', 0),
            jobStartTimeUsecs: get(taskData, 'objects.0.startTimeUsecs', 0),
            resubmit: true,
            resubmitRecoveryObject: taskData.resubmitRecoveryObject,
          };
          break;

        // SQL/Oracle Recover (kRecoverApp)
        case taskData.base.type === 4:
          if (_.get(taskData, 'restoreAppTaskState.restoreAppParams.type') === 19 &&
            FEATURE_FLAGS.ngRecoverOracle) {
            restoreState = 'recover-oracle';
            params = {
              resubmitRecoveryObject: taskData.resubmitRecoveryObject,
            };
          } else if (FEATURE_FLAGS.ngRestoreMsSql) {
            restoreState = 'recover-ms-sql';
            params = {
              resubmitRecoveryObject: taskData.resubmitRecoveryObject,
            };
          } else {
            var restoreAppParams = taskData.restoreAppTaskState.restoreAppParams;
            var ownerObject = restoreAppParams.ownerRestoreInfo.ownerObject;
            restoreState = 'recover-db.options';
            params = {
              archiveId: { type: 'string' },
              entityId: restoreAppParams.restoreAppObjectVec[0].appEntity.id,
              failover: false,
              sourceId: ownerObject.parentSource.id,
              jobUid: ownerObject.jobUid,
              jobId: ownerObject.jobId,
              jobInstanceId: ownerObject.jobInstanceId,
              jobRunStartTime: ownerObject.startTimeUsecs,
              restoreParams: restoreAppParams,
              resubmit: true,
              resubmitRecoveryObject: taskData.resubmitRecoveryObject,
              dbType: 'sql',
            };
          }
          break;

        // Oracle Recover (kRecoverApp)
        case taskData.base.type === 17:
          restoreMethod = 'recoverApplication';
          taskConfigPropertyKey = 'restoreAppTaskState';

        case taskData.base.type === 19:
          restoreState = 'recover-kubernetes-ng';
          params = {
            jobId: taskData.objects[0].jobId,
            jobIds: taskData.objects.map(object => object.jobId),
            runInstanceId: taskData.objects[0].jobInstanceId,
            sourceEntity: taskData.restoreParentSource,
            taskId: taskData.base.taskId,
            jobRunStartTime: taskData.objects[0].startTimeUsecs,
            jobUid: taskData.objects[0].jobUid,
            entityIds: taskData.objects.map(object => object.entity.id),
            resubmitRecoveryObject: taskData.resubmitRecoveryObject,
          };
          break;

        // View Clone (kCloneViews)
        case taskData.base.type === 5:
          restoreMethod = 'cloneView';
          break;

        // IVM Recover
        case taskData.base.type === 6:
          restoreState = 'recover-mount-point.options';
          params = {
            entityId: taskData.objects[0].entity.id,
            jobId: taskData.objects[0].jobId,
            jobInstanceId: taskData.objects[0].jobInstanceId,
            jobRunStartTime: taskData.objects[0].startTimeUsecs,
            jobUid: taskData.objects[0].jobUid,
            mountTarget: taskData.mountVolumesTaskState.mountParams.targetEntity,
            volumeInfoVec: taskData.volumeInfoVec,
            resubmitRecoveryObject: taskData.resubmitRecoveryObject || taskData.mountVolumesTaskState.mountParams,
          };
          break;

        // SQL Clone (kCloneApp)
        case taskData.base.type === 7:
          restoreMethod = 'cloneApplication';
          taskConfigPropertyKey = 'restoreAppTaskState';
          newTask.action = 'kCloneApp';
          break;

        // Everything else
        default:
          if (ngStorageVolumeResubmit) {
            restoreState = 'recover-storage-volume-ng';
          } else {
            restoreState = 'recover-vm.recover-options';
          }

          params = {
            backupType: taskData.objects[0].backupType,
            jobId: taskData.objects[0].jobId,
            jobIds: taskData.objects.map(object => object.jobId),
            jobInstanceId: taskData.objects[0].jobInstanceId,
            sourceEntity: taskData.restoreParentSource,
            taskId: taskData.base.taskId,
            jobRunStartTime: taskData.objects[0].startTimeUsecs,
            jobUid: taskData.objects[0].jobUid,
            entityIds: taskData.objects.map(object => object.entity.id),
            resubmitRecoveryObject: taskData.resubmitRecoveryObject,
          };
      }

      if (isRds) {
        restoreState = 'recover-rds.options';
      }

      if(taskData.base.type === 37) {
        restoreState = 'recover-s3-ng';
      }

      /**
       *  VM(1), file and folder(3), SQL(4), IVM(6), Pure(8), NAS(10), Kubernetes(19), RDS(32), RDS Aurora(33), S3(37) and SAN PPG(39).
       *  @See ENUM_ENV_TYPE in env.constants.ts
       */
      if ([1, 3, 4, 6, 8, 10, 19, 32, 33, 37, 39].includes(taskData.base.type)) {
        // These recovery types immediately continue to edit page.

        if ((
            (FEATURE_FLAGS.ngRecoverVm && [1, 3].includes(taskData.base.type)) ||
            (FEATURE_FLAGS.ngRecoverInstantVolumeMount && taskData.base.type === 6) ||
            ngStorageVolumeResubmit || [19].includes(taskData.base.type) ||
            (FEATURE_FLAGS.ngRecoverRds && [32, 33].includes(taskData.base.type)) ||
            (FEATURE_FLAGS.ngRecoverS3 && taskData.base.type === 37)
          ) && params.hasOwnProperty('resubmitRecoveryObject') && !params.resubmitRecoveryObject) {
          // For NG Recovery, if the resubmitRecoveryObject is not populated,
          // populate it before going to recovery details resubmit.
          var jobUid = taskData.objects[0].jobUid;
          var recoveryId = [
            jobUid.clusterId,
            jobUid.clusterIncarnationId,
            taskData.base.taskId,
          ].join(':');

          return $http({
            method: 'GET',
            params: {includeTenants: true},
            url: API.publicV2('data-protect/recoveries/' + recoveryId),
            headers: NgPassthroughOptionsService.requestHeaders,
          }).then(function processResponse(response) {
            return $state.go(restoreState, Object.assign(
              params,
              NgPassthroughOptionsService.routerParams,
              {resubmitRecoveryObject: response.data}
            ));
          });
        }

        return $state.go(
          restoreState,
          Object.assign(params, NgPassthroughOptionsService.routerParams)
        );
      }

      // Else just sending out the API.
      assign(newTask, taskData[taskConfigPropertyKey]);

      return RestoreService[restoreMethod](newTask)
        .then(
          function taskCreated(createdTask) {
            var taskBase = createdTask.performRestoreTaskState.base;
            var cloneTaskDetailsStateName = 'clone-detail';
            var detailsStateName =
              createdTask.performRestoreTaskState.retrieveArchiveTaskUid ?
                'recover-detail-archive' : 'recover-detail-local';

            detailsStateName = CLONE_TASK_TYPE_INTS.includes(taskBase.type) ?
              cloneTaskDetailsStateName : detailsStateName;

            $state.go(detailsStateName, { id: taskBase.taskId });
          },
          evalAJAX.errorMessage
        );
    }

    /**
     * Determines if the restore task can be prepopulated within the recovery
     * options page and then user may choose to change configuration.
     * This is different from retry as the user doesn't get an option to
     * chnage the recovery configuration.
     *
     * @method    canReconfigureRestoreTask
     * @param     {object}    task   Specifies the restore task
     * @returns   {boolean}   True, if the current restore task be reconfigured
     */
    function canReconfigureRestoreTask(task) {
      var taskState = task.performRestoreTaskState;
      var isOracleTask = !!get(
        taskState.restoreAppTaskState,
        'restoreAppParams.restoreAppObjectVec[0].restoreParams.oracleRestoreParams'
      );

      // Only users belonging to task owner organization can issue reconfigure
      // request.
      if (!task._isTaskOwner) { return false; }

      return FEATURE_FLAGS.oracleRestoreReconfigure && isOracleTask;
    }

    /**
     * Determines if a given restoreTask can be resubmitted.
     *
     * @param    {object}    task   The restoreTask to check.
     * @return   {boolean}   True if can be resubmitted.
     */
    function canRetryRestoreTask(task) {
      var taskState = task.performRestoreTaskState;
      var isSqlTask = !!get(
        taskState.restoreAppTaskState,
        'restoreAppParams.restoreAppObjectVec[0].restoreParams.sqlRestoreParams'
      );

      // Only users belonging to task owner organization can issue resubmitted
      // request.
      if (!task._isTaskOwner) { return false; }

      // Except for the feature flag, Migration, and clone, SQL tasks can resubmit.
      if (FEATURE_FLAGS.sqlRestoreResubmit && isSqlTask) { return true; }

      return ((FEATURE_FLAGS.restoreVmResubmit && taskState.base.type === 1) ||

        (FEATURE_FLAGS.restoreFlrResubmit && taskState.base.type === 3 &&
          !get(taskState, 'restoreFilesTaskState.restoreParams.isFileVolumeRestore')) ||

        (FEATURE_FLAGS.restoreIVMResubmit && taskState.base.type === 6)) &&

        // disable re-submit clone job
        ![2, 5, 7].includes(taskState.base.type) &&

        // Ref: ENUM_RESTORE_TASK_STATUS: 'kFinished', 'kCancelled'
        [3, 7].includes(taskState.base.status);
    }

    /**
     * Determines if the 'Continue to Options' option should be disabled.
     * If the option is disabled, also update the scope with appropriate
     * tooltip message to be displayed.
     *
     * @method    getFileRecoveryDisabledState
     * @param     {Object}    cartEntity   The file object in Task cart
     * @params    {Object[]}  vaults       List of available vaults
     * @returns   {Object}    Object conataining disabled state and message
     */
    function getFileRecoveryDisabledState(cartEntity, vaults) {
      var jobType = get(cartEntity, '_jobType');

      switch (true) {
        // The cart should not be empty
        case !cartEntity:
          return {
            message: 'recovery.disableMessage.emptyCart',
            isDisabled: true,
          };

        // The snapshot should not be Glacier target
        case isUnsupportedTarget(get(
          cartEntity, '_archiveTarget.target.archivalTarget.vaultId'), vaults):

          return {
            message: 'recovery.disableMessage.cloudSnapshot',
            isDisabled: true,
          };

        case isSourceDownloadOnly(cartEntity):
          return {
            message: 'downloadOnly',
            isDisabled: true,
          };

        // The entity is backed up by a Cloud 'Native' job
        case ENV_GROUPS.nativeSnapshotTypes.includes(jobType):
          if (isCloudFLRFeatureOff(jobType)) {
            return {
              message:
                'recovery.disableMessage.unsupportedCloudNativeSnapshot',
              isDisabled: true,
            };
          }
      }

      return {
        message: '',
        isDisabled: false,
      };
    }

    /**
     * Is the selected target unsupported for recovery to server
     *
     * @param {number}    vaultId the target id
     * @param {object[]}  vaults  the list of vaults to check in
     */
    function isUnsupportedTarget (vaultId, vaults) {
      // var unsupportedTargetTypes = ['kAmazonGlacier'];
      var unsupportedTargetTypes = [];
      var vault;
      var isUnsupportedTarget;

      if (!vaultId) {
        return false;
      }

      vault = find(vaults || [], function findVault(vault) {
        return vault.id === vaultId;
      });

      isUnsupportedTarget =
        unsupportedTargetTypes.indexOf(get(vault, '_tierData.tier')) > -1;

      return isUnsupportedTarget;
    };

    /**
     * Check the feature flag protection for cloud FLR
     *
     * @method  isCloudFLRFeatureOff
     * @param   {Number}  jobType`jobType for which feature flag is to be
     *                    checked
     * @return  {Boolean} True if feature flag is off. False otherwise.
     */
    function isCloudFLRFeatureOff(jobType) {
      switch (true) {
        case ENV_TYPE_CONVERSION.kAWSNative === jobType
          && !FEATURE_FLAGS.awsFileLevelRecovery:
        case ENV_TYPE_CONVERSION.kAzureNative === jobType
          && !FEATURE_FLAGS.azureFileLevelRecovery:
        case ENV_TYPE_CONVERSION.kGCPNative === jobType
          && !FEATURE_FLAGS.gcpFileLevelRecoveryUi:

          return true;

        default:
          return false;
      }
    }


    /**
     * Determines if only file download is supported by source type.
     *
     * @method    isSourceDownloadOnly
     * @param     {Object}    cartEntity   The file object in Task cart
     * @return    {Boolean}   returns true if user may only download files (not
     *                        recover) from the source.
     */
    function isSourceDownloadOnly(cartEntity) {
      var entityKey = ENTITY_KEYS[cartEntity.fileDocument.objectId.entity.type];

      return (entityKey === ENTITY_KEYS[2] &&
        FEATURE_FLAGS.hypervIsDownloadOnly) ||

        // 12: Acropolis Entity
        // If the feature is enabled, Acropolis will be not be download only
        (entityKey === ENTITY_KEYS[12] &&
          !FEATURE_FLAGS.acropolisRecoveryEnabled) ||
        ENV_GROUPS.downloadOnly.includes(entityKey);
    }

    /**
     * Gets status of vulnerability scan app
     *
     * @method  getVulScanAppStatus
     * @return   {object}   promise to resolve
     */
    function getVulScanAppStatus() {
      var opts = {
        method: 'GET',
        url: API.private('vulscan/api/appstatus'),
      };

      return $http(opts);
    }

    /**
     * Gets scanner results for job / vm
     *
     * @method    getVulScanResults
     * @param   {object}    data    Object in recovery cart
     * @return   {object}   promise to resolve
     */
    function getVulScanResult(data) {
      var opts = {
        method: 'POST',
        data: data,
        url: API.private('vulscan/api/restoreinfo'),
      };

      return $http(opts)
        .then(function scanResultReceived(resp) {
          return resp.data || {};
        });
    }

    /**
     * Creates vulnerability scan task for provided entity
     *
     * @method    createVulScanTask
     * @param   {object}    data    job / vm details
     * @return   {object}   promise to resolve
     */
    function createVulScanTask(data) {
      var opts = {
        method: 'POST',
        data: data,
        url: API.private('vulscan/api/ondemandscan'),
      };

      return $http(opts)
        .then(function taskStatusReceived(resp) {
          return resp.data || {};
        });

    }

    /**
     * Gets tasks information of a selected job run and updates the run object
     * with the vm information.
     *
     * @method   updateJobRunWithVms
     * @param    {object}   entity                   The job entity
     * @param    {number}   snapshotStartTimeUsecs   Start time of the selected
     *                                               run.
     * @return   {object}   Updated entity object with the task information
     */
    function updateJobRunWithVms(entity, snapshotStartTimeUsecs) {
      var params = {
        id: entity._jobId,
        excludeNonRestoreableRuns: true,
        exactMatchStartTimeUsecs: snapshotStartTimeUsecs,
      };

      return JobRunsService.getJobRuns(params).then(
        function getJobRunForSelectedSnapshot(resp) {
          var run = resp[0].backupJobRuns.protectionRuns[0];
          var jobInfo = resp[0].backupJobRuns.jobDescription;

          entity._runHash[run.backupRun.base.jobInstanceId] = {
            isUsable: isRunUsable(run),
            instanceId: generateInstanceIdFromBackupRun(run.backupRun),
            jobName: jobInfo.name,
            jobUid: run.backupRun.base.jobUid,
            replicaInfo: {
              replicaVec: reduceReplicaVecs(run),
            },
            snapshotType: getJobSnapshotType(run.backupRun.latestFinishedTasks),
            vms: reduceVMs(run.backupRun.latestFinishedTasks),
          };

          entity._backupJobRuns = {
            jobId: entity._jobId,
            jobName: entity._name,
            runs: entity._runHash,
          };
          return entity;
        });
    }

    /**
     * Fetches the Job Run history for the given job entity. We get the first
     * run with the vms information and all other runs without the vms and then
     * merge them. First run needs vm information as we show the vms for the
     * first run by default.
     *
     * @method   getJobRunHistory
     * @param    {object}   entity   Job entity
     * @return   {object}   Promise carrying the updated Entity.
     */
    function getJobRunHistory(entity) {
      var firstRunParams;
      var allRunsParams;
      var promises;

      if (get(entity.vmDocument, 'versions[0].vms[0]')) {
        return entity;
      }
      // Params to get the first job run with vm information
      firstRunParams = {
        id: entity._jobId,
        excludeNonRestoreableRuns: true,
        numRuns: 1,
      };

      // Params to get all job runs (trimmed version) without the vm information
      allRunsParams = {
        id: entity._jobId,
        excludeNonRestoreableRuns: true,
        excludeTasks: true,
      };

      promises = {
        firstRun: JobRunsService.getJobRuns(firstRunParams),
        allRuns: JobRunsService.getJobRuns(allRunsParams),
      }

      return $q.all(promises).then(
        function runsHistoryReceivedFn(responses) {
          return decorateEntityWithSnapshots(entity, responses);
        });
    }

    /**
     * Decorated the job entity with the run information as .versions, and sets
     * the default _snapshotIndex to the latest run startTimeUsecs
     *
     * @method   decorateEntityWithSnapshots
     * @param    {object}   entity      The job entity
     * @param    {array}    responses   The API responses containing first run
     *                                  with vms and all runs
     * @return   {object}   Decorated job entity with run and vm information
     */
    function decorateEntityWithSnapshots(entity, responses) {
      var runs;

      // Reduce the response to just backupRuns
      runs = updateJobRuns(responses, entity._uniqueId);

      entity._runHash = runs;

      entity._backupJobRuns = {
        jobId: entity._jobId,
        jobName: entity._name,
        runs: runs,
      }

      // Set entity._snapshotIndex to the jobInstanceId passed on the state
      // params, or of the first run in the response
      entity._snapshotIndex = $state.params.jobInstanceId;

      // Attach it to entity.vmDocument.versions as an array
      entity.vmDocument.versions = Object.values(runs).sort(
        function sorter1(a, b) {
          return b.instanceId.jobStartTimeUsecs -
            a.instanceId.jobStartTimeUsecs;
        }
      );

      if (!(entity._snapshotIndex > 0) &&
        entity.vmDocument.versions &&
        entity.vmDocument.versions.length) {

        entity._snapshot = entity.vmDocument.versions[0];
        entity._snapshotIndex = entity._snapshot.instanceId.jobInstanceId;
      }

      return entity;
    }

    /**
     * Updates the backupJobRuns object with the reduced server response.
     *
     * @method   updateJobRuns
     * @param    {array}    responses   The API responses containing first run
     *                                  with vms and all runs
     * @return   {object}   A hash of jobRuns
     */
    function updateJobRuns(responses) {
      var runs = [];
      var runsHash = {};
      var jobInfo;
      var firstRunResponse = [].concat(responses.firstRun);
      var allRunsResponse = [].concat(responses.allRuns);

      // Replace the first protection run in allRunsResponse with a task loaded
      // protection run from firstRunResponse
      if (firstRunResponse[0] && allRunsResponse[0]) {
        allRunsResponse[0].backupJobRuns.protectionRuns.splice(1, 0,
          firstRunResponse[0].backupJobRuns.protectionRuns[0]);
        jobInfo = firstRunResponse[0].backupJobRuns.jobDescription;
        runs = allRunsResponse[0].backupJobRuns.protectionRuns;
        runsHash = reduceRuns(runs, jobInfo);

        return runsHash;

      // First run failed but there are other runs available
      } else if (!firstRunResponse.length && allRunsResponse.length) {
        return reduceRuns(allRunsResponse[0].backupJobRuns.protectionRuns,
          allRunsResponse[0].backupJobRuns.jobDescription);
      }

      return {};
    }

    /**
     * Downloads Vulnerability scan report
     *
     * @method    downloadVulScanReport
     * @param   {string}    path
     */
    function downloadVulScanReport(path) {
      // Need clusterId as query param, So download from helios works
      var url = [
        API.private('vulscan/api' + path),
        '?clusterId=', $rootScope.clusterInfo.id,
      ].join('');

      // Open link in new tab
      $window.open(url, '_blank');
    }

    /**
     * Transforms an Array of job runs into a hash of jobRuns by
     * jobInstanceId.
     *
     * @method   reduceRuns
     * @return   {object}   Hash of jobRuns by jobInstanceId
     */
    function reduceRuns(runs, jobInfo) {
      if (!runs) {
        return {};
      }

      return runs.reduce(function reducer1(_runs, run) {
        var vms;

        // We're only interested in finished runs
        if ((run.backupRun && 2 === run.backupRun.base.status) ||

          // No active backup nor copy tasks?
          !(!!run.backupRun.activeTasks &&
            run.backupRun.activeTasks.length &&
            !!run.copyRun.activeTasks &&
            run.copyRun.activeTasks.length)) {

          _runs[run.backupRun.base.jobInstanceId] = {
            isUsable: isRunUsable(run),
            instanceId: generateInstanceIdFromBackupRun(run.backupRun),
            jobName: jobInfo.name,
            jobUid: run.backupRun.base.jobUid,
            replicaInfo: {
              replicaVec: reduceReplicaVecs(run),
            },
          };

          // For the runs with vm tasks
          if (run.backupRun.latestFinishedTasks) {
            assign(_runs[run.backupRun.base.jobInstanceId], {
              snapshotType: getJobSnapshotType(run.backupRun.latestFinishedTasks),
              vms: reduceVMs(run.backupRun.latestFinishedTasks),
            });
          }
        }
        return _runs;
      }, {});
    }

    /**
     * Sets a snapshotType at a job level according to the vm snapshotType
     * values. Returns undefined if the vms have different snapshotTypes,
     * else returns the common snapshotType
     *
     * @method   getJobSnapshotType
     * @param   {array}   List of all finished tasks
     * @return  {number}   Snapshot type
     */
    function getJobSnapshotType(tasks) {
      var snapshotType = tasks[0].currentSnapshotInfo &&
        // Snapshot tasks don't always have currentSnapshotInfo.
        tasks[0].currentSnapshotInfo.snapshotType ?
          tasks[0].currentSnapshotInfo.snapshotType.type : undefined;

      if (!snapshotType) {
        return;
      }

      var mixed = tasks.find(function findMixedType(task) {
        return task.currentSnapshotInfo &&
          task.currentSnapshotInfo.snapshotType &&
          snapshotType !== task.currentSnapshotInfo.snapshotType.type;
      });

      return !mixed ? snapshotType : 'mixed';
    }

    /**
     * Transforms run.copyRun.finishedTasks snapshotTargets into usable
     * replicaVec targets.
     *
     * @method   reduceReplicaVecs
     * @param    {object}   run   backupRuns object
     * @return   {array}    Available replcaVec targets
     */
    function reduceReplicaVecs(run) {
      /**
       * Placeholder list of finishedTasks
       *
       * @type   {array}
       */
      var tasks = [];

      /**
       * Right NOW in microseconds
       *
       * @type   {integer}
       */
      var nowUsecs = Date.clusterNow() * 1000;

      // Sanity check
      if (!run.copyRun || !run.copyRun.finishedTasks ||
        !run.copyRun.finishedTasks.length) {
        return tasks;
      }

      tasks = run.copyRun.finishedTasks;

      return tasks.reduce(function reduceTasksFn(_targets, task) {
        // Only return successful completed local or archive (cloud/tape) copy
        // tasks. (not interested in replication)
        if (!task.error &&

          // This task has completed successfully
          3 === task.status &&

          // This task has a snapshotTarget
          task.snapshotTarget &&

          // This task expires after now, or has no expiry stamp
          (task.expiryTimeUsecs ? task.expiryTimeUsecs > nowUsecs : true) &&

          // If this isn't a deleted local snapshot
          !(1 === task.snapshotTarget.type && run.backupRun.snapshotsDeleted) &&

          // This snapshotTarget is not a replication target
          [1, 3].includes(task.snapshotTarget.type)) {
          _targets.push({
              expiryTimeUsecs: task.expiryTimeUsecs || 0,
              target: task.snapshotTarget,
            });

          // if dealing with archive, add the taskUid as archiveUid so we can
          // successfully show tapes on options page
          if (task.snapshotTarget.type) {
            _targets.slice(-1)[0].archiveUid = task.taskUid;
          }
        }

        return _targets;
      }, [])
      .sort(function targetSorter1(a, b) {
        return a.target.type - b.target.type;
      });
    }

    /**
     * Generate an instanceId object based on the run information
     *
     * @method     generateInstanceIdFromBackupRun
     * @param      {Object}  run     The run.typeRun
     * @return     {Object}  InstanceId
     */
    function generateInstanceIdFromBackupRun(run) {
      return angular.extend({
          jobStartTimeUsecs: run.base.startTimeUsecs,
          jobInstanceId: run.base.jobInstanceId,
        }, run.base);
    }

    /**
     * Determines if a given jobRun is usable for restore
     *
     * @method     isRunUsable
     * @param      {object}   run     A jobRun object
     * @return     {boolean}  True if the run is usable, False
     *                        otherwise.
     */
    function isRunUsable(run) {
      var nowUsecs = Date.clusterNow() * 1000;
      /**
       * True if all targets are unexpired and not replication targets
       *
       * @type       {boolean}
       */
      var hasUsableTargets = run.copyRun && run.copyRun.finishedTasks &&
        run.copyRun.finishedTasks.some(function eachTargetFn(target) {
          // Not a replication task AND...
          return (2 !== target.snapshotTarget.type) &&

            // ... has no expiration stamp, or expires later than now
            (!target.expiryTimeUsecs || target.expiryTimeUsecs > nowUsecs);
        });

      /**
       * False if no replication task, or if canceled or if failed
       *
       * @type       {boolean}
       */
      var isUnfinishedCopyRun = false;
      if (run._hasReplicationTask) {
        isUnfinishedCopyRun = run.copyRun && run.copyRun.finishedTasks &&
          run.copyRun.finishedTasks.some(function eachTaskFn(task) {
            return task.cancellationRequested ||
              task.copyPartiallySuccessfulRun ||
              task.copyFailedBackupTasks;
          });
      }

      // The run has usable targets, is a successful replication, or is
      // not a deleted local snapshot
      return hasUsableTargets || (run._hasReplicationTask && !isUnfinishedCopyRun) ||
        !run.backupRun.snapshotsDeleted;
    }

    /**
     * Reduces a list of latestFinishedTasks from a Job Run into a
     * UI-compatible array of VMs.
     *
     * @method   reduceVMs
     * @param    {array}   tasks   List of latestFinishedTasks for a Job Run
     * @return   {array}   UI-compatible list of VMs for a run
     */
    function reduceVMs(tasks) {
      tasks = tasks || [];
      return tasks.reduce(function reducer2(_vms, task) {
        var typedEntity;

        // If it's a VM, lets process it for listing, otherwise ignore it
        if (task.base.sources[0].source &&
          ENV_GROUPS.hypervisor.includes(task.base.sources[0].source.type)) {
          _vms.push(angular.extend({
            objectName: task.base.sources[0].source.displayName ||
              SourceService.getTypedEntity(task.base.sources[0].source).name,
            logicalSizeBytes: task.base.totalLogicalBackupSizeBytes,
            snapshotType: task.currentSnapshotInfo &&
              task.currentSnapshotInfo.snapshotType ?
                task.currentSnapshotInfo.snapshotType.type : undefined,
          }, task.base.sources[0].source));
        }

        if (task.base.sources[0].source &&
          task.base.sources[0].source.type === 34) {
            _vms.push(task.base.sources[0].source.displayName);
          }

        return _vms;
      }, []);
    }

    /**
     * Decorate the restore task
     *
     * @param     {object}  task    The restore task
     * @param     {object}  server  The target entity the user want to restore to
     * @return    {object}  A list of decorated files with several properties.
     */

    function decorateFiles(task, server) {
      var decoratedTaskObjects = decorateRestoreTaskObjects(task);
      var recoveredFiles = _formatFiles(task);

      // Decorate each file and add to cart.
      zipWith(recoveredFiles, decoratedTaskObjects,
        function zipTwoGroups(file, decorateRestoreTaskObj) {
          assign(file, decorateRestoreTaskObj, {
            _entityName: get(file, 'fileDocument.objectId.entity.displayName'),
            _path: decorateRestoreTaskObj.path,
            _type: decorateRestoreTaskObj.isFolder ? 'directory' : 'file',
            _snapshotIndex: 0,
            _snapshot: server.vmDocument.versions[0],
            _server: server,
            _archiveTarget: server._archiveTarget,
            _hostType: server._hostType,
            _jobType: server._jobType,
            _versions: server.vmDocument.versions,
            _serverType: file.fileDocument.objectId.entity.physicalEntity ?
              'physical' : 'vm',
          });
      });

      return recoveredFiles;
    }

    /**
     * Format restore task to apply on file recovery template. The template comes
     * from addToCartFromBrowser in _file.js.
     *
     * @param     {object}  task
     * @returns   {object}  A list of recovered files in a required template.
     */
    function _formatFiles(task) {
      var taskState = task.performRestoreTaskState;
      var resultVec =
        taskState.restoreFilesTaskState.restoreFilesInfo.restoreFilesResultVec;

      return resultVec.map(
        function eachObject(vec) {
          return {
            entityId: taskState.objects[0].entity.id,
            fileDocument: {
              filename: vec.restoredFileInfo.absolutePath,
              size: get(vec, 'copyStats.totalBytesToCopy'),
              objectId: {
                entity: taskState.objects[0].entity,
                jobId: taskState.objects[0].jobId,
                jobUid: taskState.objects[0].jobUid,
              },
            },
            fullPath: vec.restoredFileInfo.absolutePath,
          };
        }
      );
    }

    /**
     * Decorates the snapshot versions with source snapshot time information
     * which points to the snapshot time of already taken snapshot on source.
     *
     * @param     {array}  versions   List of Snapshot versions
     * @param     {number}  jobId      Job Identifier
     * @param     {number}  sourceId   Source Identifier
     * @returns   {object}  A list of decorated snapshot versions with
     *                      source snapshot time
     */
    function getDataProtectSnapshotVersions(versions, jobId, sourceId) {
      return JobRunsService.getPublicJobRuns({
        jobId: jobId,
        sourceId: sourceId,
        excludeNonRestoreableRuns: true
      }).then(function getJobSuccess(runs) {
        const runToSourceSnapshotTimeHash = runs.reduce(function getRunHash(acc, run) {
          const sourceStatus = run.backupRun.sourceBackupStatus.find((sourceStatus) =>
            (sourceStatus.source.id === sourceId)
          );
          acc[run.jobRunId] = sourceStatus.currentSnapshotInfo.sourceSnapshotCreateTimeUsecs;
          return acc;
        }, {});
        return versions.map((version) => {
          version._sourceSnapshotCreateTimeUsecs =
            runToSourceSnapshotTimeHash[version.instanceId.jobInstanceId];
          return version;
        });
      });
    }

    return RestoreService;
  }

})(angular);
