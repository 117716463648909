<div class="flex-row-md" [formGroup]="form.formGroup">

  <!-- Read/Write Protocol Selector -->
  <mat-form-field>
    <mat-label>{{ 'readWriteProtocol' | translate }}</mat-label>

    <!-- Single select protocol selector for some cases -->
    <mat-select *ngIf="isSingleSelectReadWriteProtocol"
      formControlName="readWriteProtocol"
      [compareWith]="compareProtocols"
      id="single-read-write-protocol-selector"
      cogDataId="read-write-protocol-selector">
      <mat-option *ngFor="let protocol of readWriteProtocolList"
        [value]="protocol"
        [inheritDataId]="true"
        [disabled]="isProtocolDisabled(protocol)"
        cogDataId="option-{{protocol.type}}">
        {{ 'views.protocols.' + protocol.type | translate }}
        <cog-icon *ngIf="isProtocolDisabled(protocol)"
          shape="info"
          class="margin-left-xs"
          size="sm"
          [matTooltip]="'protocolDisabled' | translate"
          matTooltipPosition="right">
        </cog-icon>
      </mat-option>
    </mat-select>

    <!-- Multi select protocol selector for other cases -->
    <mat-select *ngIf="!isSingleSelectReadWriteProtocol"
      [multiple]="true"
      formControlName="readWriteProtocol"
      [compareWith]="compareProtocols"
      id="multiple-read-write-protocol-selector"
      cogDataId="read-write-protocol-selector">
      <mat-option *ngFor="let protocol of readWriteProtocolList"
        [value]="protocol"
        [inheritDataId]="true"
        [disabled]="isProtocolDisabled(protocol)"
        cogDataId="option-{{protocol.type}}">
        {{ 'views.protocols.' + protocol.type | translate }}
        <cog-icon *ngIf="isProtocolDisabled(protocol)"
          shape="info"
          class="margin-left-xs"
          size="sm"
          [matTooltip]="'protocolDisabled' | translate"
          matTooltipPosition="right">
        </cog-icon>
      </mat-option>
    </mat-select>

    <mat-error *ngIf="form.formGroup.controls.readWriteProtocol.errors?.required">
      {{ 'views.errors.selectAtleastOneReadWriteProtocol' | translate }}
    </mat-error>
  </mat-form-field>

  <!-- Read only protocol selector -->
  <mat-form-field *ngIf="showReadOnlyProtocolSelect">
    <mat-label>{{ 'readOnlyProtocolOptional' | translate }}</mat-label>

    <mat-select [multiple]="true"
      formControlName="readOnlyProtocol"
      cogDataId="read-only-protocol-selector"
      [compareWith]="compareProtocols">
      <mat-option *ngFor="let protocol of readOnlyProtocolList"
        [value]="protocol"
        [inheritDataId]="true"
        cogDataId="option-{{protocol.type}}">
        {{ 'views.protocols.' + protocol.type | translate }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
