import { Injectable } from '@angular/core';
import { PassthroughOptions } from '@cohesity/api/private';
import {
  CreateDataAccessSessionRequestParams,
  DataAccessorServiceApi,
  ProtectedObject,
  Recovery,
  RecoveryServiceApi,
} from '@cohesity/api/v2';
import { MomentDatePipe, NavItem, SnackBarService } from '@cohesity/helix';
import { flagEnabled, IrisContextService, isDmsScope } from '@cohesity/iris-core';
import { SourceSelection } from '@cohesity/iris-source-tree';
import { TranslateService } from '@ngx-translate/core';
import { StateService, UIRouterGlobals } from '@uirouter/core';
import { takeUntilDestroyed } from 'ngx-sub-form';
import { Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { DialogService, PassthroughOptionsService } from 'src/app/core/services';
import { RestorePointSelection, SnapshotSelectorModalComponent } from 'src/app/modules/restore/restore-shared';
import {
  AjaxHandlerService,
  AzureBackupType,
  AzureObjectTypes,
  AzureSQLEntityTypes,
  AzureSupportedWorkloadType,
  cloudGroups,
  Environment,
  RecoveryAction,
  sanitizeFileName,
} from 'src/app/shared';
import { CloudLeafToJobType } from 'src/app/shared/constants';

import { ProtectionGroupService } from '../protection-group-shared';
import { ObjectActionCreator } from './object-action-creator';
import { SimpleObjectInfo } from './object-menu-provider';
import { ProtectedObjectsService } from './protected-objects.service';

/**
 * Helper class for creating object action items for Azure.
 */
@Injectable({
  providedIn: 'root',
})
export class AzureObjectActionCreator extends ObjectActionCreator {
  /**
   * Specifies whether the objects are in global search context.
   */
  get isGlobalSearchContext() {
    return this.uiRouterGlobals?.current?.name === 'ng-search';
  }

  constructor(
    private azureObjectsService: ProtectedObjectsService,
    private azureProtectionGroupService: ProtectionGroupService,
    private azureStateService: StateService,
    private momentDatePipe: MomentDatePipe,
    private dialogService: DialogService,
    private dataAccessorService: DataAccessorServiceApi,
    private irisContextService: IrisContextService,
    private uiRouterGlobals: UIRouterGlobals,
    private translate: TranslateService,
    private passthroughOptionsService: PassthroughOptionsService,
    readonly recoveryService: RecoveryServiceApi,
    readonly ajaxService: AjaxHandlerService,
    readonly snackBarService: SnackBarService,
  ) {
    super(azureObjectsService, azureProtectionGroupService, azureStateService);
  }

  /**
   * Creates a protect object nav item
   *
   * @param objects          The objects being protected
   * @param selection        Most of the time, we can easily determine the selection based on
   *                         list of objects. Using sourceSelection will allow for more complex
   *                         configurations for tags, auto protect, etc...
   * @returns                The NavItem for AWS
   */
  createProtectAction(objects: SimpleObjectInfo[], selection?: SourceSelection): NavItem {
    const navItem = super.createProtectAction(objects, selection);
    const workloadType = this.getAzureWorkloadType(objects[0]);
    navItem.stateParams.cloudJobType = this.getCloudStateParams(objects[0]);
    navItem.stateParams.workloadType = workloadType;

    if (AzureSQLEntityTypes.includes(workloadType)) {
      navItem.stateParams.environments = [Environment.kAzureSQL];
    } else if (workloadType === AzureSupportedWorkloadType.kAzureEntraID) {
      navItem.stateParams.environments = [Environment.kAzureEntraID];
    }
    return navItem;
  }

  /**
   * Returns workload type based on selected azure object type information.
   *
   * @param object The object currently selected.
   * @returns workloadType to be added to stateParams.
   */
  getAzureWorkloadType(object: SimpleObjectInfo): string {
    if (object?.workloadType) {
      return object.workloadType;
    }

    if (AzureObjectTypes.includes(object.objectType)) {
      return object.objectType;
    }

    // If there is no object type available during global search currently assigning entra id
    // as default workload on trying to protect azure tenant.
    if (!object?.objectType?.length &&
      flagEnabled(this.irisContextService.irisContext, 'dmsAzureEntraIdWorkload') &&
      isDmsScope(this.irisContextService.irisContext) && this.isGlobalSearchContext) {
      return this.uiRouterGlobals.params.workload ||
        AzureSupportedWorkloadType.kAzureEntraID;
    }
    return null;
  }

  /**
   * Adds cloudJobType to navItem.stateParams in order to navigate to
   * another page for cases like RDS instances in AWS.
   * Support for Aurora and other types can be added in future.
   *
   * @param object The object currently selected.
   * @returns cloudjobType to be added to stateParams.
   */
  getCloudStateParams(object: SimpleObjectInfo) {
    if (cloudGroups.cloud.includes(object.environment) &&
      AzureObjectTypes.includes(object.objectType as AzureBackupType)) {
      return CloudLeafToJobType[object.objectType];
    }
    if (object.workloadType && (object.workloadType in CloudLeafToJobType)) {
      return CloudLeafToJobType[object.workloadType];
    }
    return null;
  }

  /**
   * Creates a recover Azure SQL nav item
   *
   * @param objects The objects being recovered.
   * @param restorePointSelection The Restore point selection of the selected object.
   * @param objectOptions The object's passthrough options
   * @returns The NavItem
   */
  createRecoverAzureSqlAction(
    objects?: ProtectedObject[],
    restorePointSelection?: RestorePointSelection,
    objectOptions: PassthroughOptions = {},
  ): NavItem {
    const restorePoints = this.getRestorePointSelection(objects, restorePointSelection);

    if (!restorePoints) {
      return;
    }

    return {
      displayName: 'recover',
      icon: 'restore',
      state: 'recover-azure-sql-ng',
      stateParams: {
        restorePoints,
        cid: objectOptions.accessClusterId,
        regionId: objectOptions.regionId,
        workloadType: 'kSQLDatabase'
      },
    } as any;
  }

  /**
   * Creates a recover object nav item.
   *
   * @param type The type of the recovery.
   * @param simpleObject The simple object.
   * @param object The protected object.
   * @param restorePointSelection The Restore point selection of the selected object.
   * @param objectOptions The object's passthrough options.
   * @returns The recovery NavItem object.
   */
  createRecoverAction(
    type: RecoveryAction,
    simpleObject: SimpleObjectInfo,
    object?: ProtectedObject,
    restorePointSelection?: RestorePointSelection,
    objectOptions?: PassthroughOptions
  ): NavItem {
    const objects = object ? [object] : null;
    switch (type) {
      case RecoveryAction.RecoverVMs:
        return this.createRecoverVmAction(objects, null, objectOptions);
      case RecoveryAction.RecoverAzureSQL:
        return this.createRecoverAzureSqlAction(objects, null, objectOptions);
      case RecoveryAction.RecoverFiles:
        return super.createRecoverFilesAction(simpleObject, RecoveryAction.RecoverFiles,
          restorePointSelection, objectOptions);
      case RecoveryAction.DownloadFilesAndFolders:
        return super.createRecoverFilesAction(simpleObject, RecoveryAction.DownloadFilesAndFolders,
          restorePointSelection, objectOptions);
      case RecoveryAction.RecoverAzureEntraID:
        return this.createRecoverAzureEntraIdAction(objects, restorePointSelection, objectOptions);
    }
  }

  /**
   * Creates and returns a recovery action for the supplied protected object, based on the supplied recovery type.
   *
   * @param type The type of the recovery.
   * @param objects The array of protected objects.
   * @param objectOptions The object's passthrough options.
   * @returns The recovery NavItem object.
   */
  createBulkRecoverAction(
    type: RecoveryAction,
    objects?: ProtectedObject[],
    objectOptions?: PassthroughOptions
  ): NavItem {
    switch (type) {
      case RecoveryAction.RecoverVMs:
        return this.createRecoverVmAction(objects, null, objectOptions);
      case RecoveryAction.RecoverAzureSQL:
        return this.createRecoverAzureSqlAction(objects, null, objectOptions);
      case RecoveryAction.RecoverAzureEntraID:
        return this.createRecoverAzureEntraIdAction(objects, null, objectOptions);
    }
  }

  /**
   * Creates DB authorization action.
   *
   * @param action The action to perform on click of this button
   * @returns The Action nav item.
   */
  createDbAuthorizeAction(action: () => void): NavItem {
    return {
      displayName: 'databaseCredentials',
      icon: 'lock_open',
      action,
    };
  }

  /**
   * Creates a recover Azure AD action nav item
   *
   * @param objects The objects being recovered.
   * @param restorePointSelection The Restore point selection of the selected object.
   * @param objectOptions The object's passthrough options
   * @returns The NavItem
   */
  createRecoverAzureEntraIdAction(
    objects?: ProtectedObject[],
    restorePointSelection?: RestorePointSelection,
    objectOptions: PassthroughOptions = {},
  ): NavItem {
    const restorePoints = this.getRestorePointSelection(objects, restorePointSelection);
    if (!restorePoints?.length || !objects?.length) {
      return;
    }

    return {
      displayName: 'recover',
      icon: 'restore',
      action: () => this.dialogService.showDialog(SnapshotSelectorModalComponent, {
        object: {
          ...restorePoints[0]
        },
        recoveryAction: RecoveryAction.RecoverAzureEntraID,
        snapshotActions: [RecoveryAction.RecoverAzureEntraID],
        confirmButtonLabel: this.translate.instant('browse'),
        title: this.translate.instant('selectRecoveryPointFor', {name: restorePoints[0]?.objectInfo?.name}),
      }, { width: '55rem' }).pipe(
        takeUntilDestroyed(this),
        filter(snapshot => !!snapshot),
        switchMap((selection: RestorePointSelection) => this.createDataAccessBrowseSession(selection, objectOptions))
      ).subscribe(sessionId => {
        if (sessionId?.length) {
          const params = {
            objectId: objects[0].id,
            cid: objectOptions.accessClusterId,
            regionId: objectOptions.regionId,
            sessionId,
          };

          // Redirecting user to browse details page after successfull session creation
          this.azureStateService.go('object-browse.landing.summary', params);
        }
      })
    };
  }

  /**
   * Creates a browse session for specified object.
   *
   * @param restorePointSelection The Restore point selection of the selected object.
   * @param objectOptions The object's passthrough options
   * @returns Observable of browse session id
   */
  createDataAccessBrowseSession(restorePointSelection?: RestorePointSelection,
    objectOptions: PassthroughOptions = {},
  ): Observable<string> {
    const objectInfo = restorePointSelection?.objectInfo;
    const params: CreateDataAccessSessionRequestParams = {
      currentSnapshotInfo: {
        snapshotId: restorePointSelection?.restorePointId,
        environment: Environment.kAzureEntraID,
      },
      sourceId: Number(objectInfo?.id),
      sessionName: this.getDefaultTaskName('azure.browse.defaultTaskName')
    };

    return this.dataAccessorService.CreateDataAccessSession({
      body: params,
      regionId: objectOptions?.regionId
    }).pipe(
      filter(session => !!session),
      map(response => response.sessionId)
    );
  }

  /**
   * Gets a default task name for a browse task.
   *
   * @param   taskNameKey   The key to use for translating the task name
   * @returns A default task name.
   */
  getDefaultTaskName(taskNameKey: string) {
    const dateString = [
      this.momentDatePipe.transform(new Date(), 'mediumDate'),
      this.momentDatePipe.transform(new Date(), 'shortTime'),
    ].join(' ');
    return sanitizeFileName(this.translate.instant(taskNameKey, { dateString }));
  }

  /**
   * Created recovery task to download database backpac file.
   *
   * @param    snapshotId  Recovery Snapshot ID.
   * @returns  Observable for when file recovery task is created.
   */
  createDownloadDatabaseFilesRecoveryTask(snapshotId: string): Observable<Recovery> {
    if (!snapshotId) {
      return;
    }
    // Hotfix: `InternalApiCreateDownloadFilesAndFoldersRecovery` endpoint is deprecated.
    // Update DMaaS DP clusters to new endpoints then change it in UI.
    return this.recoveryService.InternalApiCreateDownloadFilesAndFoldersRecovery({
      body: {
        name: this.getDefaultTaskName('recovery.files.defaultTaskName'),
        object: {
          snapshotId,
        },
      },
      ...this.passthroughOptionsService.requestParams,
    }).pipe(
      map((recoverTask) => {
        if (recoverTask) {
          this.snackBarService
            .openWithAction(
              this.translate.instant('recoveryDownloadTaskSuccessfullyCreated'),
              this.translate.instant('viewProgress')
            )
            .subscribe(() =>
              this.azureStateService.go('recovery.detail', {
                id: recoverTask.id,

                // The finalize in createFilesAndFoldersDownloadTask would have cleared
                // the passthrough options values, so fallback to passed values.
                regionId: this.passthroughOptionsService.regionId,
                cid: this.passthroughOptionsService.accessClusterId,
              })
            );
        }
        return recoverTask;
      }),
      this.ajaxService.catchAndHandleError()
    );
  }
}
