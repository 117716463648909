/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityApiConfiguration as __Configuration } from '../cohesity-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ProtectionJob } from '../models/protection-job';
import { UpdateProtectionObjectParameters } from '../models/update-protection-object-parameters';
import { ProtectedObject } from '../models/protected-object';
import { ProtectObjectParameters } from '../models/protect-object-parameters';
import { UnprotectObjectParams } from '../models/unprotect-object-params';
import { ProtectionObjectSummary } from '../models/protection-object-summary';
@Injectable({
  providedIn: 'root',
})
class ProtectionObjectsService extends __BaseService {
  static readonly UpdateProtectionObjectPath = '/public/protectionObjects';
  static readonly ProtectObjectPath = '/public/protectionObjects';
  static readonly UnprotectObjectPath = '/public/protectionObjects';
  static readonly GetProtectionObjectSummaryPath = '/public/protectionObjects/summary';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Update a Protected Object.
   *
   * Returns the Updated Protected Object.
   * @param params The `ProtectionObjectsService.UpdateProtectionObjectParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`:
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return UpdateProtectionObjectRequest is the response of updating a Protection
   * Object.
   * Success
   */
  UpdateProtectionObjectResponse(params: ProtectionObjectsService.UpdateProtectionObjectParams): __Observable<__StrictHttpResponse<ProtectionJob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    __body = params.body;
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/public/protectionObjects`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ProtectionJob>;
      })
    );
  }
  /**
   * Update a Protected Object.
   *
   * Returns the Updated Protected Object.
   * @param params The `ProtectionObjectsService.UpdateProtectionObjectParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`:
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return UpdateProtectionObjectRequest is the response of updating a Protection
   * Object.
   * Success
   */
  UpdateProtectionObject(params: ProtectionObjectsService.UpdateProtectionObjectParams): __Observable<ProtectionJob> {
    return this.UpdateProtectionObjectResponse(params).pipe(
      __map(_r => _r.body as ProtectionJob)
    );
  }

  /**
   * Protect an Object.
   *
   * Returns the Protected Object and its corresponding Protection Job information.
   * @param params The `ProtectionObjectsService.ProtectObjectParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`:
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  ProtectObjectResponse(params: ProtectionObjectsService.ProtectObjectParams): __Observable<__StrictHttpResponse<Array<ProtectedObject>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    __body = params.body;
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/protectionObjects`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ProtectedObject>>;
      })
    );
  }
  /**
   * Protect an Object.
   *
   * Returns the Protected Object and its corresponding Protection Job information.
   * @param params The `ProtectionObjectsService.ProtectObjectParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`:
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  ProtectObject(params: ProtectionObjectsService.ProtectObjectParams): __Observable<Array<ProtectedObject>> {
    return this.ProtectObjectResponse(params).pipe(
      __map(_r => _r.body as Array<ProtectedObject>)
    );
  }

  /**
   * Unprotect a Protected Object.
   * @param params The `ProtectionObjectsService.UnprotectObjectParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`:
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  UnprotectObjectResponse(params: ProtectionObjectsService.UnprotectObjectParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    __body = params.body;
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/public/protectionObjects`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Unprotect a Protected Object.
   * @param params The `ProtectionObjectsService.UnprotectObjectParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`:
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  UnprotectObject(params: ProtectionObjectsService.UnprotectObjectParams): __Observable<null> {
    return this.UnprotectObjectResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Protect an Object.
   *
   * Returns the Protected Object and its corresponding Protection Job information.
   * @param params The `ProtectionObjectsService.GetProtectionObjectSummaryParams` containing the following parameters:
   *
   * - `protectionSourceId`: Specifies the id of the Protection Source.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetProtectionObjectSummaryResponse(params: ProtectionObjectsService.GetProtectionObjectSummaryParams): __Observable<__StrictHttpResponse<ProtectionObjectSummary>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.protectionSourceId != null) __params = __params.set('protectionSourceId', params.protectionSourceId.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/protectionObjects/summary`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ProtectionObjectSummary>;
      })
    );
  }
  /**
   * Protect an Object.
   *
   * Returns the Protected Object and its corresponding Protection Job information.
   * @param params The `ProtectionObjectsService.GetProtectionObjectSummaryParams` containing the following parameters:
   *
   * - `protectionSourceId`: Specifies the id of the Protection Source.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetProtectionObjectSummary(params: ProtectionObjectsService.GetProtectionObjectSummaryParams): __Observable<ProtectionObjectSummary> {
    return this.GetProtectionObjectSummaryResponse(params).pipe(
      __map(_r => _r.body as ProtectionObjectSummary)
    );
  }
}

module ProtectionObjectsService {

  /**
   * Parameters for UpdateProtectionObject
   */
  export interface UpdateProtectionObjectParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;
    body?: UpdateProtectionObjectParameters;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for ProtectObject
   */
  export interface ProtectObjectParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;
    body?: ProtectObjectParameters;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UnprotectObject
   */
  export interface UnprotectObjectParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;
    body?: UnprotectObjectParams;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetProtectionObjectSummary
   */
  export interface GetProtectionObjectSummaryParams {

    /**
     * Specifies the id of the Protection Source.
     */
    protectionSourceId: number;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { ProtectionObjectsService }
