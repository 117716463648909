/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityApiConfiguration as __Configuration } from '../cohesity-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { BasicClusterInfo } from '../models/basic-cluster-info';
import { Cluster } from '../models/cluster';
import { UpdateClusterParams } from '../models/update-cluster-params';
import { AppsConfig } from '../models/apps-config';
import { ClusterStatusResult } from '../models/cluster-status-result';
@Injectable({
  providedIn: 'root',
})
class ClusterService extends __BaseService {
  static readonly GetBasicClusterInfoPath = '/public/basicClusterInfo';
  static readonly GetClusterPath = '/public/cluster';
  static readonly UpdateClusterPath = '/public/cluster';
  static readonly GetAppSettingsPath = '/public/cluster/appSettings';
  static readonly UpdateAppSettingsPath = '/public/cluster/appSettings';
  static readonly GetClusterStatusPath = '/public/cluster/status';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * List details about the Cohesity Cluster such as the name, type, version,
   * language, locale and domains.
   * This operation does not require authentication.
   *
   * All Active Directory domains that are currently joined to the Cohesity
   * Cluster are returned. In addition, the default LOCAL domain on the
   * Cohesity Cluster is returned as the first element of the domains array in
   * the response.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetBasicClusterInfoResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<BasicClusterInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/basicClusterInfo`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BasicClusterInfo>;
      })
    );
  }
  /**
   * List details about the Cohesity Cluster such as the name, type, version,
   * language, locale and domains.
   * This operation does not require authentication.
   *
   * All Active Directory domains that are currently joined to the Cohesity
   * Cluster are returned. In addition, the default LOCAL domain on the
   * Cohesity Cluster is returned as the first element of the domains array in
   * the response.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetBasicClusterInfo(regionId?: string,
    accessClusterId?: number): __Observable<BasicClusterInfo> {
    return this.GetBasicClusterInfoResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as BasicClusterInfo)
    );
  }

  /**
   * List details about this Cohesity Cluster.
   *
   * Returns information about this Cohesity Cluster.
   * @param params The `ClusterService.GetClusterParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `fetchTimeSeriesSchema`: Specifies whether to get time series schema info of the cluster.
   *
   * - `fetchStats`: If 'true', also get statistics about the Cohesity Cluster.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Successful Response
   */
  GetClusterResponse(params: ClusterService.GetClusterParams): __Observable<__StrictHttpResponse<Cluster>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.fetchTimeSeriesSchema != null) __params = __params.set('fetchTimeSeriesSchema', params.fetchTimeSeriesSchema.toString());
    if (params.fetchStats != null) __params = __params.set('fetchStats', params.fetchStats.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/cluster`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Cluster>;
      })
    );
  }
  /**
   * List details about this Cohesity Cluster.
   *
   * Returns information about this Cohesity Cluster.
   * @param params The `ClusterService.GetClusterParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `fetchTimeSeriesSchema`: Specifies whether to get time series schema info of the cluster.
   *
   * - `fetchStats`: If 'true', also get statistics about the Cohesity Cluster.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Successful Response
   */
  GetCluster(params: ClusterService.GetClusterParams): __Observable<Cluster> {
    return this.GetClusterResponse(params).pipe(
      __map(_r => _r.body as Cluster)
    );
  }

  /**
   * Update the configuration of this Cohesity Cluster.
   *
   * Returns the updated Cluster configuration.
   * @param params The `ClusterService.UpdateClusterParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * - `Body`: Update Cluster Parameter.
   *
   * @return Success
   */
  UpdateClusterResponse(params: ClusterService.UpdateClusterParams): __Observable<__StrictHttpResponse<Cluster>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    __body = params.Body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/public/cluster`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Cluster>;
      })
    );
  }
  /**
   * Update the configuration of this Cohesity Cluster.
   *
   * Returns the updated Cluster configuration.
   * @param params The `ClusterService.UpdateClusterParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * - `Body`: Update Cluster Parameter.
   *
   * @return Success
   */
  UpdateCluster(params: ClusterService.UpdateClusterParams): __Observable<Cluster> {
    return this.UpdateClusterResponse(params).pipe(
      __map(_r => _r.body as Cluster)
    );
  }

  /**
   * Gets the app settings for the cluster.
   *
   * Returns the app settings for the cluster.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetAppSettingsResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<AppsConfig>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/cluster/appSettings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AppsConfig>;
      })
    );
  }
  /**
   * Gets the app settings for the cluster.
   *
   * Returns the app settings for the cluster.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetAppSettings(regionId?: string,
    accessClusterId?: number): __Observable<AppsConfig> {
    return this.GetAppSettingsResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as AppsConfig)
    );
  }

  /**
   * Update the app settings of the cluster.
   *
   * Returns the updated app settings.
   * @param params The `ClusterService.UpdateAppSettingsParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * - `Body`: Update App Settings Parameter.
   *
   * @return Success
   */
  UpdateAppSettingsResponse(params: ClusterService.UpdateAppSettingsParams): __Observable<__StrictHttpResponse<AppsConfig>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    __body = params.Body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/public/cluster/appSettings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AppsConfig>;
      })
    );
  }
  /**
   * Update the app settings of the cluster.
   *
   * Returns the updated app settings.
   * @param params The `ClusterService.UpdateAppSettingsParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * - `Body`: Update App Settings Parameter.
   *
   * @return Success
   */
  UpdateAppSettings(params: ClusterService.UpdateAppSettingsParams): __Observable<AppsConfig> {
    return this.UpdateAppSettingsResponse(params).pipe(
      __map(_r => _r.body as AppsConfig)
    );
  }

  /**
   * Get the status of a Cohesity Cluster.
   *
   * Sends a request to get the status of every node that is part of the current
   * Cluster.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetClusterStatusResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<ClusterStatusResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/cluster/status`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ClusterStatusResult>;
      })
    );
  }
  /**
   * Get the status of a Cohesity Cluster.
   *
   * Sends a request to get the status of every node that is part of the current
   * Cluster.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetClusterStatus(regionId?: string,
    accessClusterId?: number): __Observable<ClusterStatusResult> {
    return this.GetClusterStatusResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as ClusterStatusResult)
    );
  }
}

module ClusterService {

  /**
   * Parameters for GetCluster
   */
  export interface GetClusterParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Specifies whether to get time series schema info of the cluster.
     */
    fetchTimeSeriesSchema?: boolean;

    /**
     * If 'true', also get statistics about the Cohesity Cluster.
     */
    fetchStats?: boolean;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateCluster
   */
  export interface UpdateClusterParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;

    /**
     * Update Cluster Parameter.
     */
    Body?: UpdateClusterParams;
  }

  /**
   * Parameters for UpdateAppSettings
   */
  export interface UpdateAppSettingsParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;

    /**
     * Update App Settings Parameter.
     */
    Body?: AppsConfig;
  }
}

export { ClusterService }
