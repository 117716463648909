import { Component, Inject, Optional } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Api } from '@cohesity/api/private';
import { AgentServiceApi } from '@cohesity/api/v2';
import { IrisContextService, flagEnabled, getConfigByKey, isDmsScope } from '@cohesity/iris-core';
import { AjaxHandlerService, AutoDestroyable } from '@cohesity/utils';
import { UIRouterGlobals } from '@uirouter/core';
import { AgentPackage, AgentsService } from 'src/app/services/agents.service';

import { HostType } from '@cohesity/dataprotect/shared';

/**
 * @description
 * A Dialog Component which shows the list of agents to be downloaded.
 * @example
 *   Simple Usage by MatDialog (inject MatDialog as this.dialog):
 *     this.dialog.open(DownloadAgentsComponent);
 */
@Component({
  selector: 'coh-download-agents',
  templateUrl: './download-agents.component.html',
  styleUrls: ['./download-agents.component.scss']
})
export class DownloadAgentsComponent extends AutoDestroyable {

  /**
   * Optional region id to get sources from. This is set from the
   * region id state param if present.
   */
  get regionId(): string {
    return this.uiRouterGlobals?.params?.regionId;
  }

  /**
   * List of agents available for download.
   */
  agents: AgentPackage[] = [];

  /**
   * The form control to select the version to linux installer to be downloaded.
   */
  package: UntypedFormControl = new UntypedFormControl('kRPM', Validators.required);

  /**
   * The form control to select the solaris installer package to be downloaded.
   */
  solarisPackage: UntypedFormControl = new UntypedFormControl('kSolaris10x86', Validators.required);

  /**
   * The form control to select the Esxi host version to be downloaded.
   */
  cdpVMWareEsxiVersion: UntypedFormControl = new UntypedFormControl('6.7', Validators.required);

  /**
   * This determines which description we'll be using for the modal.
   */
  descriptionMessage = 'downloadAgentsModal.description';

  /**
   * Helper function for feature flag checks that is easier to expose to the html templae
   *
   * @param flag The flag name
   * @returns true if the flag is enabled
   */
  flagEnabled(flag: string): boolean {
    return flagEnabled(this.irisCtx.irisContext, flag);
  }

  /**
   * Constructor
   */
  constructor(
    private ajaxHandlerService: AjaxHandlerService,
    private irisCtx: IrisContextService,
    private uiRouterGlobals: UIRouterGlobals,
    private agentService: AgentsService,
    private agentServiceApi: AgentServiceApi,
    @Optional() @Inject(MAT_DIALOG_DATA) public dialogData) {
    super();
    this.agents = this.agentService.agents;
    const hiddenAgents = this.dialogData?.hiddenAgents || getConfigByKey(this.irisCtx.irisContext, 'agents.hiddenAgents', []);

    // Ensure `hostEnv` is a string value. Default is '' which will allow all files
    // to display (unfiltered). It is the developer's responsibility to ensure
    // they are using a valid Hostenv. These are currently "Windows",
    // "Linux", "AIX" and "Solaris".
    let hostType = typeof(dialogData) === 'string' ? dialogData : '';

    if (typeof(dialogData) === 'object' && Object.prototype.hasOwnProperty.call(dialogData, hostType)) {
      hostType = dialogData.hostType;
    }

    if (typeof(dialogData) === 'object' && Object.prototype.hasOwnProperty.call(dialogData, this.descriptionMessage)) {
      this.descriptionMessage = dialogData.descriptionMessage;
    }

    if (!isDmsScope(this.irisCtx.irisContext)) {
      // limit to agents matching hostType
      this.agents = this.agents.filter((agent) => agent.hostType.includes(hostType));

      // hide agents according to config
      this.agents = this.agents.filter((agent) => !hiddenAgents.includes(agent.hostType));

      this.agents.forEach(agent => this.transformHostAgent(agent));
    }
  }

  /**
   * Transforms the agent and adds the appropriate URL to download.
   *
   * @param  hostAgent  The agent which needs to be downloaded.
   */
  transformHostAgent(hostAgent: AgentPackage) {
    // Populate download url based on platform package type info.
    if (isDmsScope(this.irisCtx.irisContext)) {
      hostAgent.url = this.agentService.fetchAgentPkgTypeDownloadUrl(hostAgent);
      return;
    }
    // Populate download url for all platforms except kCdpVMwareIOFilter.
    if (hostAgent.hostType !== HostType.kCdpVMwareIOFilter) {
      hostAgent.url = this.getDownloadUrl(hostAgent.hostType, hostAgent.hostOSType,
        hostAgent.pkgType, hostAgent.agentType, hostAgent.solarisPkgType);
    }
  }

  /**
   * Returns agent download url for given host type
   *
   * @param    hostType        Type of the Host
   * @param    [pkgType]       Type of package installer
   * @param    [agentType]     Type of agent i.e. kGo, kJava
   * @return   Download url
   */
  private getDownloadUrl(hostType: string, hostOSType: string, pkgType: string,
    agentType: string, solarisPkgType: string): string {
    const downloadParams = [
      `hostType=${hostType}`,
      hostOSType ? `hostOSType=${hostOSType}` : undefined,
      pkgType ? `pkgType=${pkgType}` : undefined,
      agentType ? `agentType=${agentType}` : undefined,
      solarisPkgType ? `solarisPkgType=${solarisPkgType}` : undefined,
    ].filter(Boolean).join('&');
    const path = `physicalAgents/download?${downloadParams}`;
    return Api.public(path);
  }

  /**
   * Function to download CDP Vmware IO filter using v2 api.
   *
   * @param hostAgent The agent which needs to be downloaded.
   */
  downloadCdpIOFilter(hostAgent: AgentPackage) {
    // V2 DownloadAgentParams
    const params: AgentServiceApi.DownloadAgentParams = {
      body: {
        platform: 'kVMWareCDPFilter',
        vmwareCDPFilterParams: {
          esxiVersion: hostAgent.esxiVersion,
        }
      }
    };
    // V2 api called to download CDP IO filter agent.
    this.agentServiceApi.DownloadAgent(params)
      .pipe(this.untilDestroy())
      .subscribe(result => {
        const blob = new Blob([result], { type: result.type });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'cohesity_cdp_agent.zip';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      },
      error => this.ajaxHandlerService.errorMessage(error)
    );
  }
}
