import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { IoCsScansApiService, Scan, ScanId, ScanParams } from '@cohesity/api/argus';
import { ClusterInfoService } from '@cohesity/data-govern/cluster-replication';
import { getDefaultTaskName } from '@cohesity/data-govern/scans';
import { MomentDatePipe, SnackBarService } from '@cohesity/helix';
import { BuilderConfig, ControlFlow, FormBuilderComponent } from '@cohesity/shared-forms';
import { AjaxHandlerService, AutoDestroyable, Memoize } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import { isEmpty } from 'lodash-es';
import { combineLatest, of } from 'rxjs';
import { finalize, take, tap } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';

import { DetectionTypeComponent } from './detection-type';
import { ObjectSearchComponent } from './object-search';
import { ScanFormModel } from './scan.model';
import { SettingsComponent } from './settings';
import { SnapshotSelectionComponent } from './snapshot-selection';

/**
 * Returns a hash function for memoizing `getScanParams` based on the `scanParams` object.
 */
const getScanParamsHashFn = (scanParams: ScanParams) => JSON.stringify(scanParams || {});

@Component({
  selector: 'dg-td-scan',
  templateUrl: './scan.component.html',
  styleUrls: ['./scan.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScanComponent extends AutoDestroyable implements OnInit, AfterViewInit {

  /**
   * Scan id to fetch scan
   */
  @Input() scanId: ScanId;

  /**
   * Scan to use in the form
   */
  @Input() scan?: Partial<Scan>;

  /**
   * Event indicating scan creation cancelled
   */
  @Output() goBack = new EventEmitter<null>();

  /**
   * FormBuilder component
   */
  @ViewChild(FormBuilderComponent, { static: true }) scanBuilder: FormBuilderComponent<ScanFormModel, ScanParams>;

  /**
   * Scan form config for formBuilder
   */
  builderConfig: BuilderConfig = {
    sections: [
      ObjectSearchComponent,
      DetectionTypeComponent,
      SnapshotSelectionComponent,
      SettingsComponent
    ],
    saveForm: this.saveForm.bind(this),
    goBack: () => this.goBack.next(),
    canShowSaveForm: this.canShowSaveForm.bind(this),
  };

  constructor(
    private ajaxService: AjaxHandlerService,
    private cdr: ChangeDetectorRef,
    private clusterInfoService: ClusterInfoService,
    private momentDatePipe: MomentDatePipe,
    private scansApiService: IoCsScansApiService,
    private snackBarService: SnackBarService,
    private translateService: TranslateService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.getScanDetails();
  }

  ngAfterViewInit(): void {
    const settingsControlFlow = new ControlFlow(
      this.scanBuilder.formSectionRefByName.settings,
      () => isEmpty(this.scanBuilder?.formGroup?.value?.settings),
    );
    const snapshotSelectionControlFlow = new ControlFlow(
      this.scanBuilder.formSectionRefByName.snapshotSelection,
      () => isEmpty(this.scanBuilder?.formGroup?.value?.snapshotSelection),
    );
    const detectionTypeControlFlow = new ControlFlow(
      this.scanBuilder.formSectionRefByName.detectionType,
      () => Boolean(this.scanBuilder?.formGroup?.value?.detectionType),
      [settingsControlFlow, snapshotSelectionControlFlow],
    );
    const objectsControlFlow = new ControlFlow(
      this.scanBuilder.formSectionRefByName.objects,
      () => Boolean(this.scanBuilder?.formGroup?.value?.objects?.length),
      [detectionTypeControlFlow],
    );

    this.scanBuilder.initControlFlow(objectsControlFlow).pipe(this.untilDestroy()).subscribe();
  }

  @Memoize(getScanParamsHashFn)
  getScanRequestKey(_scanParams: ScanParams): string {
    return uuid();
  }

  /**
   * Get scan details for current scan id
   */
  getScanDetails() {
    this.scanBuilder.isLoading = true;
    this.cdr.detectChanges();
    combineLatest([(this.scanId ?
      this.scansApiService.getScanById({ id: this.scanId }) :
      of({
        ...this.scan,
        name: getDefaultTaskName(this.translateService, this.momentDatePipe, 'dg.td.scan.defaultTaskName'),
      }).pipe(take(1))
    ), this.clusterInfoService.loadData()]).pipe(
      this.untilDestroy(),
      finalize(() => {
        this.scanBuilder.isLoading = false;
        this.cdr.detectChanges();
        this.scanBuilder.initFormSection();
      }),
      tap(([scan]) => this.scan = scan),
    ).subscribe({ error: this.ajaxService.handler });
  }

  /**
   * Create or updates the scan
   */
  saveForm() {
    const body = Object.values(this.scanBuilder.formSectionRefByName).reduce((out, { componentRef }) => {
      out = { ...out, ...(componentRef.instance.toDataModel() || {}) };
      return out;
    }, {} as ScanParams);

    if (this.scanId) {
      delete body.scanRequestKey;
    } else {
      body.scanRequestKey = this.getScanRequestKey(body);
    }

    this.scanBuilder.isSubmitting = true;
    this.cdr.detectChanges();
    this.scanBuilder.cdr.detectChanges();
    (this.scanId ? this.scansApiService.updateScan : this.scansApiService.createScan).call(
      this.scansApiService,
      { id: this.scanId || undefined, body }
    ).pipe(
      this.untilDestroy(),
      finalize(() => {
        this.scanBuilder.isSubmitting = false;
        this.cdr.detectChanges();
        this.scanBuilder.cdr.detectChanges();
      }),
      tap(scan => {
        this.scan = scan;
        this.builderConfig.goBack();
      }),
    ).subscribe({
      next: () => this.snackBarService.open(this.translateService.instant('dg.td.scan.created', { scanName: this.scan.name })),
      error: this.ajaxService.handler
    });
  }

  /**
   * Decides weather to show save button or not
   *
   * @returns boolean
   */
  canShowSaveForm() {
    return Object.values(this.scanBuilder.formGroup.controls).every(({ disabled }) => disabled === false);
  }
}
