import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { DlpApiService, Policy } from '@cohesity/api/argus';
import { SnackBarService } from '@cohesity/helix';
import { AjaxHandlerService, AutoDestroyable } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';

/**
 * Argus policy Delete dialog components data inputs.
 */
export interface DeletePolicyInput {
  policy: Policy;
}

/**
 * Policy Delete Dialog
 *
 * @example
 * <dg-dc-policy-delete-dialog></dg-dc-policy-delete-dialog>
 */
@Component({
  selector: 'dg-dc-policy-delete-dialog',
  templateUrl: './policy-delete-dialog.component.html',
  styleUrls: ['./policy-delete-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PolicyDeleteDialogComponent extends AutoDestroyable {
  /**
   * Indicates whether we are submitting the data or not.
   */
  isSubmitting = false;

  /**
   * The provided policy.
   */
  get policy(): Policy {
    return this.data.policy;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: DeletePolicyInput,
    private ajaxService: AjaxHandlerService,
    private cdr: ChangeDetectorRef,
    private dialogRef: MatDialogRef<PolicyDeleteDialogComponent>,
    private dlpApiService: DlpApiService,
    private snackBarService: SnackBarService,
    private translate: TranslateService,
  ) {
    super();
  }

  /**
   * Delete Policy
   */
  deletePolicy() {
    // showing the loader.
    this.isSubmitting = true;
    this.cdr.detectChanges();

    this.dlpApiService.deletePolicy({ id: this.policy.id })
      .pipe(
        this.untilDestroy(),
        finalize(() => {
          // resetting the loader.
          this.isSubmitting = false;
          this.cdr.detectChanges();
        }),
      )
      .subscribe(() => {
        this.snackBarService.open(this.translate.instant('successfullyDeleted'), 'success');
        this.dialogRef.close(this.policy);
      },
      this.ajaxService.handler,
      );
  }
}
