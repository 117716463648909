import { NgFor, NgIf } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { Subscription } from 'rxjs';
import { IconComponent } from '../icon/icon.component';

/**
 * This component provides the stepper header which can be placed in toolbar
 *
 * @example
 *
 * <coh-stepper-header *ngIf="stepper" [matStepperRef]="stepper"></coh-stepper-header>
 */
@Component({
  selector: 'cog-stepper-header',
  templateUrl: './stepper-header.component.html',
  styleUrls: ['./stepper-header.component.scss'],
  standalone: true,
  imports: [NgFor, NgIf, IconComponent],
})
export class StepperHeaderComponent implements OnInit, OnDestroy {
  /**
   * The currently selected index of the stepper.
   */
  selectedIndex: number;

  /**
   * Reference to matstepper to control the state of this component
   */
  @Input() matStepperRef: MatStepper;

  /**
   * Flag to indicate the background color to change active stepper color
   */
  @Input() isDarkBackground = false;

  /**
   * The subscription for the "selectionChange" event on the stepper.
   */
  private selectionChangeSub: Subscription;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    if (this.matStepperRef) {
      this.selectedIndex = this.matStepperRef.selectedIndex;

      this.selectionChangeSub = this.matStepperRef.selectionChange.subscribe(change => {
        this.selectedIndex = change.selectedIndex;
        this.cdr.detectChanges();
      });
    }
  }

  ngOnDestroy(): void {
    if (!this.selectionChangeSub?.closed) {
      this.selectionChangeSub.unsubscribe();
    }
  }
}
