<cog-settings-list-item
  cogFormGroup
  *ngIf="recoverExcludedDisksFlag"
  [label]="'excludeDiskHandling' | translate"
  [isReadOnly]="isNotApplicable">

  <cog-settings-list-item-value-display *ngIf="!readOnly && isNotApplicable">
    <p>{{ 'Not Applicable' | translate }}</p>
  </cog-settings-list-item-value-display>

  <!-- Display the appropriate message based on the toggle states -->
  <cog-settings-list-item-value-display *ngIf="!readOnly && !isNotApplicable">
    <p>
      <ng-container *ngIf="recoverExcludedDisks.value && !recoverIndependentDisks.value">
        {{ 'recoverExcludedDiskAsBlankDisk' | translate }}
      </ng-container>
      <ng-container *ngIf="!recoverExcludedDisks.value && recoverIndependentDisks.value">
        {{ 'recoverIndependentDiskAsBlankDisk' | translate }}
      </ng-container>
      <ng-container *ngIf="recoverExcludedDisks.value && recoverIndependentDisks.value">
        {{ 'recoverExcludedIndependentDiskAsBlankDisk' | translate }}
      </ng-container>
      <ng-container *ngIf="!recoverExcludedDisks.value && !recoverIndependentDisks.value">
        {{ 'None' | translate }}
      </ng-container>
    </p>
  </cog-settings-list-item-value-display>

  <!-- Editable form for recoverExcludedDisks with mat-slide-toggle (only when not readonly) -->

  <cog-settings-list-item-value-editor *ngIf="!readOnly &&  !isNotApplicable">
    <mat-slide-toggle
      [formControl]="recoverExcludedDisks"
      [inheritDataId]="true"
      cogDataId="recover-excluded-disks">
      {{ 'recoverExcludedDiskAsBlankDisk' | translate }}
    </mat-slide-toggle>
  </cog-settings-list-item-value-editor>

  <!-- Editable form for recoverIndependentDisks with mat-slide-toggle (only when not readonly) -->
  <cog-settings-list-item-value-editor *ngIf="!readOnly &&  !isNotApplicable">
    <mat-slide-toggle
      [formControl]="recoverIndependentDisks"
      [inheritDataId]="true"
      cogDataId="recover-independent-disks">
      {{ 'recoverIndependentDiskAsBlankDisk' | translate }}
    </mat-slide-toggle>
  </cog-settings-list-item-value-editor>

  <!-- Display the same message when readOnly is true -->
  <cog-settings-list-item-value-display *ngIf="readOnly">
    <p>
      <ng-container *ngIf="recoverExcludedDisks.value && !recoverIndependentDisks.value">
        {{ 'recoverExcludedDiskAsBlankDisk' | translate }}
      </ng-container>
      <ng-container *ngIf="!recoverExcludedDisks.value && recoverIndependentDisks.value">
        {{ 'recoverIndependentDiskAsBlankDisk' | translate }}
      </ng-container>
      <ng-container *ngIf="recoverExcludedDisks.value && recoverIndependentDisks.value">
        {{ 'recoverExcludedIndependentDiskAsBlankDisk' | translate }}
      </ng-container>
      <ng-container *ngIf="!recoverExcludedDisks.value && !recoverIndependentDisks.value">
        {{ 'None' | translate }}
      </ng-container>
    </p>
  </cog-settings-list-item-value-display>
</cog-settings-list-item>
