import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NavItem } from '@cohesity/helix';

/**
 * The Argus stepper component.
 *
 * @example
 * <dg-stepper
 *  [steps]="steps"
 *  [selectedStep]="selectedStep">
 * </dg-stepper>
 */
@Component({
  selector: 'dg-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepperComponent {
  /**
   * The steps to display.
   */
  @Input() steps: NavItem[] = [];

  /**
   * The selected step to display.
   */
  @Input() selectedStep?: NavItem;

  /**
   * Indicates whether to use invert the used color.
   */
  @Input() colorInversion = false;
}
