/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityApiConfiguration as __Configuration } from '../cohesity-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Vault } from '../models/vault';
import { TapeMediaInformation } from '../models/tape-media-information';
import { VaultBandwidthLimits } from '../models/vault-bandwidth-limits';
import { VaultEncryptionKey } from '../models/vault-encryption-key';
@Injectable({
  providedIn: 'root',
})
class VaultsService extends __BaseService {
  static readonly GetVaultsPath = '/public/vaults';
  static readonly CreateVaultPath = '/public/vaults';
  static readonly GetArchiveMediaInfoPath = '/public/vaults/archiveMediaInfo';
  static readonly GetBandwidthSettingsPath = '/public/vaults/bandwidthSettings';
  static readonly UpdateBandwidthSettingsPath = '/public/vaults/bandwidthSettings';
  static readonly GetVaultEncryptionKeyPath = '/public/vaults/encryptionKey/{id}';
  static readonly GetVaultByIdPath = '/public/vaults/{id}';
  static readonly UpdateVaultPath = '/public/vaults/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * List the Vaults (External Targets) registered on the Cohesity Cluster
   * filtered by the specified parameters.
   *
   * If no parameters are specified, all Vaults (External Targets) currently
   * registered on the Cohesity Cluster are returned.
   * Specifying parameters filters the results that are returned.
   * A Vault is equivalent to an External Target in the Cohesity Dashboard.
   * @param params The `VaultsService.GetVaultsParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `name`: Specifies the name of the Vault to return. If empty, all Vaults are
   *   returned.
   *
   * - `includeMarkedForRemoval`: Specifies if Vaults that are marked for removal should be returned.
   *
   * - `id`: Specifies the id of Vault to return. If empty, all Vaults are returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetVaultsResponse(params: VaultsService.GetVaultsParams): __Observable<__StrictHttpResponse<Array<Vault>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.name != null) __params = __params.set('name', params.name.toString());
    if (params.includeMarkedForRemoval != null) __params = __params.set('includeMarkedForRemoval', params.includeMarkedForRemoval.toString());
    if (params.id != null) __params = __params.set('id', params.id.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/vaults`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Vault>>;
      })
    );
  }
  /**
   * List the Vaults (External Targets) registered on the Cohesity Cluster
   * filtered by the specified parameters.
   *
   * If no parameters are specified, all Vaults (External Targets) currently
   * registered on the Cohesity Cluster are returned.
   * Specifying parameters filters the results that are returned.
   * A Vault is equivalent to an External Target in the Cohesity Dashboard.
   * @param params The `VaultsService.GetVaultsParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `name`: Specifies the name of the Vault to return. If empty, all Vaults are
   *   returned.
   *
   * - `includeMarkedForRemoval`: Specifies if Vaults that are marked for removal should be returned.
   *
   * - `id`: Specifies the id of Vault to return. If empty, all Vaults are returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetVaults(params: VaultsService.GetVaultsParams): __Observable<Array<Vault>> {
    return this.GetVaultsResponse(params).pipe(
      __map(_r => _r.body as Array<Vault>)
    );
  }

  /**
   * Create a new Vault (External Target).
   *
   * Returns the created Vault.
   * A Vault is equivalent to an External Target in the Cohesity Dashboard.
   * @param params The `VaultsService.CreateVaultParams` containing the following parameters:
   *
   * - `body`: Request to create a new Vault.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateVaultResponse(params: VaultsService.CreateVaultParams): __Observable<__StrictHttpResponse<Vault>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/vaults`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Vault>;
      })
    );
  }
  /**
   * Create a new Vault (External Target).
   *
   * Returns the created Vault.
   * A Vault is equivalent to an External Target in the Cohesity Dashboard.
   * @param params The `VaultsService.CreateVaultParams` containing the following parameters:
   *
   * - `body`: Request to create a new Vault.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateVault(params: VaultsService.CreateVaultParams): __Observable<Vault> {
    return this.CreateVaultResponse(params).pipe(
      __map(_r => _r.body as Vault)
    );
  }

  /**
   * List the media information for the specified archive service.
   *
   * Returns the media information about the specified archive service uid
   * (such as a QStar tape archive service).
   *
   * An archive service uid is uniquely identified using a combination of the
   * following fields: clusterIncarnationId, entityIds and clusterId.
   * These are all required fields.
   * @param params The `VaultsService.GetArchiveMediaInfoParams` containing the following parameters:
   *
   * - `qstarArchiveJobId`: Specifies the id of the Job that archived to a QStar media Vault.
   *
   * - `clusterIncarnationId`: Specifies the incarnation id of the Cohesity Cluster that archived
   *   to a QStar media Vault.
   *
   * - `clusterId`: Specifies the id of the Cohesity Cluster that archived to a
   *   QStar media Vault.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `qstarRestoreTaskId`: Specifies the id of the restore task to optionally filter by.
   *   The restore task that is restoring data from the specified media Vault.
   *
   * - `entityIds`: Specifies an array of entityIds to optionally filter by.
   *   An entityId is a unique id for a VM assigned by the Cohesity Cluster.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetArchiveMediaInfoResponse(params: VaultsService.GetArchiveMediaInfoParams): __Observable<__StrictHttpResponse<Array<TapeMediaInformation>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.qstarArchiveJobId != null) __params = __params.set('qstarArchiveJobId', params.qstarArchiveJobId.toString());
    if (params.clusterIncarnationId != null) __params = __params.set('clusterIncarnationId', params.clusterIncarnationId.toString());
    if (params.clusterId != null) __params = __params.set('clusterId', params.clusterId.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.qstarRestoreTaskId != null) __params = __params.set('qstarRestoreTaskId', params.qstarRestoreTaskId.toString());
    (params.entityIds || []).forEach(val => {if (val != null) __params = __params.append('entityIds', val.toString())});
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/vaults/archiveMediaInfo`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TapeMediaInformation>>;
      })
    );
  }
  /**
   * List the media information for the specified archive service.
   *
   * Returns the media information about the specified archive service uid
   * (such as a QStar tape archive service).
   *
   * An archive service uid is uniquely identified using a combination of the
   * following fields: clusterIncarnationId, entityIds and clusterId.
   * These are all required fields.
   * @param params The `VaultsService.GetArchiveMediaInfoParams` containing the following parameters:
   *
   * - `qstarArchiveJobId`: Specifies the id of the Job that archived to a QStar media Vault.
   *
   * - `clusterIncarnationId`: Specifies the incarnation id of the Cohesity Cluster that archived
   *   to a QStar media Vault.
   *
   * - `clusterId`: Specifies the id of the Cohesity Cluster that archived to a
   *   QStar media Vault.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `qstarRestoreTaskId`: Specifies the id of the restore task to optionally filter by.
   *   The restore task that is restoring data from the specified media Vault.
   *
   * - `entityIds`: Specifies an array of entityIds to optionally filter by.
   *   An entityId is a unique id for a VM assigned by the Cohesity Cluster.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetArchiveMediaInfo(params: VaultsService.GetArchiveMediaInfoParams): __Observable<Array<TapeMediaInformation>> {
    return this.GetArchiveMediaInfoResponse(params).pipe(
      __map(_r => _r.body as Array<TapeMediaInformation>)
    );
  }

  /**
   * List the upload and download bandwidth limit and bandwidth overrides
   * settings.
   *
   * Returns the upload and download bandwidth limits.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetBandwidthSettingsResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<VaultBandwidthLimits>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/vaults/bandwidthSettings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<VaultBandwidthLimits>;
      })
    );
  }
  /**
   * List the upload and download bandwidth limit and bandwidth overrides
   * settings.
   *
   * Returns the upload and download bandwidth limits.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetBandwidthSettings(regionId?: string,
    accessClusterId?: number): __Observable<VaultBandwidthLimits> {
    return this.GetBandwidthSettingsResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as VaultBandwidthLimits)
    );
  }

  /**
   * Updates bandwidth limits.
   *
   * Returns the updated bandwidth limits.
   * @param params The `VaultsService.UpdateBandwidthSettingsParams` containing the following parameters:
   *
   * - `body`: Request to update global bandwidth limits settings.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateBandwidthSettingsResponse(params: VaultsService.UpdateBandwidthSettingsParams): __Observable<__StrictHttpResponse<VaultBandwidthLimits>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/public/vaults/bandwidthSettings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<VaultBandwidthLimits>;
      })
    );
  }
  /**
   * Updates bandwidth limits.
   *
   * Returns the updated bandwidth limits.
   * @param params The `VaultsService.UpdateBandwidthSettingsParams` containing the following parameters:
   *
   * - `body`: Request to update global bandwidth limits settings.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateBandwidthSettings(params: VaultsService.UpdateBandwidthSettingsParams): __Observable<VaultBandwidthLimits> {
    return this.UpdateBandwidthSettingsResponse(params).pipe(
      __map(_r => _r.body as VaultBandwidthLimits)
    );
  }

  /**
   * Get encryption information for a Vault (External Target).
   * A Vault is equivalent to an External Target in the Cohesity Dashboard.
   *
   * Get encryption information (such as the encryption key)
   * for the specified Vault (External Target).
   * To restore data to a remote Cluster (for example to support a disaster
   * recovery scenario), you must get the encryption key of the Vault
   * and store it outside the local source Cluster, before disaster strikes.
   * If you have the encryption key and the local source Cluster goes down,
   * you can restore the data to a remote Cluster from the Vault.
   * The local source Cluster is the Cluster that archived the data on the Vault.
   * @param params The `VaultsService.GetVaultEncryptionKeyParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Vault.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetVaultEncryptionKeyResponse(params: VaultsService.GetVaultEncryptionKeyParams): __Observable<__StrictHttpResponse<VaultEncryptionKey>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/vaults/encryptionKey/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<VaultEncryptionKey>;
      })
    );
  }
  /**
   * Get encryption information for a Vault (External Target).
   * A Vault is equivalent to an External Target in the Cohesity Dashboard.
   *
   * Get encryption information (such as the encryption key)
   * for the specified Vault (External Target).
   * To restore data to a remote Cluster (for example to support a disaster
   * recovery scenario), you must get the encryption key of the Vault
   * and store it outside the local source Cluster, before disaster strikes.
   * If you have the encryption key and the local source Cluster goes down,
   * you can restore the data to a remote Cluster from the Vault.
   * The local source Cluster is the Cluster that archived the data on the Vault.
   * @param params The `VaultsService.GetVaultEncryptionKeyParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Vault.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetVaultEncryptionKey(params: VaultsService.GetVaultEncryptionKeyParams): __Observable<VaultEncryptionKey> {
    return this.GetVaultEncryptionKeyResponse(params).pipe(
      __map(_r => _r.body as VaultEncryptionKey)
    );
  }

  /**
   * List details about a single Vault (External Target).
   *
   * Returns the Vault corresponding to the specified Vault Id.
   * A Vault is equivalent to an External Target in the Cohesity Dashboard.
   * @param params The `VaultsService.GetVaultByIdParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Vault.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetVaultByIdResponse(params: VaultsService.GetVaultByIdParams): __Observable<__StrictHttpResponse<Vault>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/vaults/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Vault>;
      })
    );
  }
  /**
   * List details about a single Vault (External Target).
   *
   * Returns the Vault corresponding to the specified Vault Id.
   * A Vault is equivalent to an External Target in the Cohesity Dashboard.
   * @param params The `VaultsService.GetVaultByIdParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Vault.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetVaultById(params: VaultsService.GetVaultByIdParams): __Observable<Vault> {
    return this.GetVaultByIdResponse(params).pipe(
      __map(_r => _r.body as Vault)
    );
  }

  /**
   * Update a Vault (External Target).
   *
   * Update the settings of a Vault.
   * A Vault is equivalent to an External Target in the Cohesity Dashboard.
   * Returns the updated Vault.
   * @param params The `VaultsService.UpdateVaultParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Vault.
   *
   * - `body`: Request to update a Vault's settings.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateVaultResponse(params: VaultsService.UpdateVaultParams): __Observable<__StrictHttpResponse<Vault>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/public/vaults/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Vault>;
      })
    );
  }
  /**
   * Update a Vault (External Target).
   *
   * Update the settings of a Vault.
   * A Vault is equivalent to an External Target in the Cohesity Dashboard.
   * Returns the updated Vault.
   * @param params The `VaultsService.UpdateVaultParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Vault.
   *
   * - `body`: Request to update a Vault's settings.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateVault(params: VaultsService.UpdateVaultParams): __Observable<Vault> {
    return this.UpdateVaultResponse(params).pipe(
      __map(_r => _r.body as Vault)
    );
  }
}

module VaultsService {

  /**
   * Parameters for GetVaults
   */
  export interface GetVaultsParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Specifies the name of the Vault to return. If empty, all Vaults are
     * returned.
     */
    name?: string;

    /**
     * Specifies if Vaults that are marked for removal should be returned.
     */
    includeMarkedForRemoval?: boolean;

    /**
     * Specifies the id of Vault to return. If empty, all Vaults are returned.
     */
    id?: number;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreateVault
   */
  export interface CreateVaultParams {

    /**
     * Request to create a new Vault.
     */
    body: Vault;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetArchiveMediaInfo
   */
  export interface GetArchiveMediaInfoParams {

    /**
     * Specifies the id of the Job that archived to a QStar media Vault.
     */
    qstarArchiveJobId: number;

    /**
     * Specifies the incarnation id of the Cohesity Cluster that archived
     * to a QStar media Vault.
     */
    clusterIncarnationId: number;

    /**
     * Specifies the id of the Cohesity Cluster that archived to a
     * QStar media Vault.
     */
    clusterId: number;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Specifies the id of the restore task to optionally filter by.
     * The restore task that is restoring data from the specified media Vault.
     */
    qstarRestoreTaskId?: number;

    /**
     * Specifies an array of entityIds to optionally filter by.
     * An entityId is a unique id for a VM assigned by the Cohesity Cluster.
     */
    entityIds?: Array<number>;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateBandwidthSettings
   */
  export interface UpdateBandwidthSettingsParams {

    /**
     * Request to update global bandwidth limits settings.
     */
    body: VaultBandwidthLimits;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetVaultEncryptionKey
   */
  export interface GetVaultEncryptionKeyParams {

    /**
     * Specifies a unique id of the Vault.
     */
    id: number;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetVaultById
   */
  export interface GetVaultByIdParams {

    /**
     * Specifies a unique id of the Vault.
     */
    id: number;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateVault
   */
  export interface UpdateVaultParams {

    /**
     * Specifies a unique id of the Vault.
     */
    id: number;

    /**
     * Request to update a Vault's settings.
     */
    body: Vault;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { VaultsService }
