import { Pipe, PipeTransform } from '@angular/core';
import { McmConfig } from '@cohesity/api/private';

/**
 * Connectivity to helios status types.
 */
type connectionStatus = 'disconnected' | 'connected' | 'connectingToHelios' | 'unknown';

/**
 * Properties to be displayed on helios token claim panel.
 */
export interface DisplayProperties {
  /**
   * the icon based on the status. Displayed on the token claim panel.
   */
  connectionStatusIcon: string;

  /**
   * Helios button status icon
   */
  heliosStatusIcon: string;

  /**
   * Connectivity status.
   */
  status: string;
}

/**
 * A connectivity details map by current status.
 */
export const ConnectivityDetailsByStatusMap: Record<connectionStatus, DisplayProperties> = {
  disconnected: {
    connectionStatusIcon: 'helix:status-error!critical',
    heliosStatusIcon: 'helios-error',
    status: 'disconnected',
  },
  connected: {
    connectionStatusIcon: 'helix:status-success!success',
    heliosStatusIcon: 'helios-online',
    status: 'connected',
  },
  connectingToHelios: {
    connectionStatusIcon: 'helix:status-in-progress',
    status: 'connectingToHelios',
    heliosStatusIcon: '',
  },
  unknown: {
    connectionStatusIcon: '',
    status: '',
    heliosStatusIcon: '',
  },
};

/**
 * Get current helios connectivity status
 *
 * @return Current connectivity status
 */
const getStatus = (config: McmConfig): connectionStatus => {
  if (
    config?.enableMcm &&
    config?.registrationStatus === 'kRegistrationCompleted' &&
    config?.connectionStatus?.connectedToMcm
  ) {
    return 'connected';
  }

  if (config?.registrationStatus === 'kRegistrationInProgress') {
    return 'connectingToHelios';
  }

  if (!config?.connectionStatus?.connectedToMcm || !config?.enableMcm) {
    return 'disconnected';
  }

  return 'unknown';
};

/**
 * @ngdoc Pipe
 * @description
 *    Transforms MCM config status into different strings depending on the asset type requested.
 *
 * @example
 *    {{ config | heliosConnectivity: 'connectionStatusIcon' }} => 'helix:status-error!critical'
 *    {{ config | heliosConnectivity: 'status' }} => 'disconnected'
 */
@Pipe({
  name: 'heliosConnectivity',
  standalone: true,
})
export class HeliosConnectivityPipe implements PipeTransform {
  /**
   * Transforms MCM config and returns relevant properties to be displayed to the user.
   *
   * @param config MCM config
   * @param assetType Property type to be displayed to the user
   * @returns property value to be displayed
   */
  transform<T extends keyof DisplayProperties>(config: McmConfig, assetType: T): DisplayProperties[T] {
    return ConnectivityDetailsByStatusMap[getStatus(config)][assetType];
  }
}
