/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityApiConfiguration as __Configuration } from '../cohesity-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ClusterAuditLogsSearchResult } from '../models/cluster-audit-logs-search-result';
@Injectable({
  providedIn: 'root',
})
class AuditService extends __BaseService {
  static readonly GetAuditLogsActionsPath = '/public/auditLogs/actions';
  static readonly GetAuditLogsCategoriesPath = '/public/auditLogs/categories';
  static readonly SearchClusterAuditLogsPath = '/public/auditLogs/cluster';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Return list of audit log actions.
   *
   * A string array of all the actions used to filter audit logs.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetAuditLogsActionsResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<Array<string>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/auditLogs/actions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<string>>;
      })
    );
  }
  /**
   * Return list of audit log actions.
   *
   * A string array of all the actions used to filter audit logs.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetAuditLogsActions(regionId?: string,
    accessClusterId?: number): __Observable<Array<string>> {
    return this.GetAuditLogsActionsResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as Array<string>)
    );
  }

  /**
   * Return list of audit log categories.
   *
   * A string array of all the categories used to filter audit logs.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetAuditLogsCategoriesResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<Array<string>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/auditLogs/categories`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<string>>;
      })
    );
  }
  /**
   * Return list of audit log categories.
   *
   * A string array of all the categories used to filter audit logs.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetAuditLogsCategories(regionId?: string,
    accessClusterId?: number): __Observable<Array<string>> {
    return this.GetAuditLogsCategoriesResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as Array<string>)
    );
  }

  /**
   * Lists the cluster audit logs on the Cohesity Cluster that match the filter
   * criteria specified using parameters.
   *
   * When actions (such as a login or a Job being paused) occur on the
   * Cohesity Cluster, the Cluster generates Audit Logs.
   * If no parameters are specified, all logs currently on the Cohesity Cluster
   * are returned. Specifying the parameters filters the results that are returned.
   * @param params The `AuditService.SearchClusterAuditLogsParams` containing the following parameters:
   *
   * - `userNames`: Filter by user names who cause the actions that generate Cluster Audit
   *   Logs.
   *
   * - `tenantId`: TenantId specifies the tenant whose action resulted in the audit log.
   *
   * - `startTimeUsecs`: Filter by a start time. Only Cluster audit logs that were generated after
   *   the specified time are returned.
   *   Specify the start time as a Unix epoch Timestamp (in microseconds).
   *
   * - `startIndex`: Specifies an index number that can be used to return subsets of items
   *   in multiple requests.
   *   Break up the items to return into multiple requests by setting pageCount
   *   and startIndex to return a subsets of items in the search result.
   *   For example, set startIndex to 0 to get the first set of pageCount items
   *   for the first request. Increment startIndex by pageCount
   *   to get the next set of pageCount items for a next request.
   *   Continue until all items are returned and therefore the total number of
   *   returned items is equal to totalCount. Default value is 0.
   *
   * - `search`: Filter by matching a substring in entity name or details of the
   *   Cluster audit log.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `pageCount`: Limit the number of items to return in the response for pagination
   *   purposes. Default value is 1000.
   *
   * - `outputFormat`: Specifies the format of the output such as csv and json.
   *   If not specified, the json format is returned. If csv is specified,
   *   a comma-separated list with a heading row is returned.
   *
   * - `entityTypes`: Filter by entity types involved in the actions that generate the
   *   Cluster audit logs, such as User, Protection Job, View, etc.
   *   For a complete list, see the Category drop-down in the Admin > Audit Logs
   *   page of the Cohesity Dashboard.
   *
   * - `endTimeUsecs`: Filter by a end time specified as a Unix epoch Timestamp
   *   (in microseconds). Only Cluster audit logs that were generated before the
   *   specified end time are returned.
   *
   * - `domains`: Filter by domains of users who cause the actions that trigger
   *   Cluster audit logs.
   *
   * - `allUnderHierarchy`: AllUnderHierarchy specifies if logs of all the tenants under the hierarchy
   *   of tenant with id TenantId should be returned.
   *
   * - `actions`: Filter by the actions that generate Cluster audit logs such as
   *   Activate, Cancel, Clone, Create, etc. For a complete list, see the
   *   Actions drop-down in the Admin > Audit Logs page of the Cohesity
   *   Dashboard.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  SearchClusterAuditLogsResponse(params: AuditService.SearchClusterAuditLogsParams): __Observable<__StrictHttpResponse<ClusterAuditLogsSearchResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.userNames || []).forEach(val => {if (val != null) __params = __params.append('userNames', val.toString())});
    if (params.tenantId != null) __params = __params.set('tenantId', params.tenantId.toString());
    if (params.startTimeUsecs != null) __params = __params.set('startTimeUsecs', params.startTimeUsecs.toString());
    if (params.startIndex != null) __params = __params.set('startIndex', params.startIndex.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.pageCount != null) __params = __params.set('pageCount', params.pageCount.toString());
    if (params.outputFormat != null) __params = __params.set('outputFormat', params.outputFormat.toString());
    (params.entityTypes || []).forEach(val => {if (val != null) __params = __params.append('entityTypes', val.toString())});
    if (params.endTimeUsecs != null) __params = __params.set('endTimeUsecs', params.endTimeUsecs.toString());
    (params.domains || []).forEach(val => {if (val != null) __params = __params.append('domains', val.toString())});
    if (params.allUnderHierarchy != null) __params = __params.set('allUnderHierarchy', params.allUnderHierarchy.toString());
    (params.actions || []).forEach(val => {if (val != null) __params = __params.append('actions', val.toString())});
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/auditLogs/cluster`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ClusterAuditLogsSearchResult>;
      })
    );
  }
  /**
   * Lists the cluster audit logs on the Cohesity Cluster that match the filter
   * criteria specified using parameters.
   *
   * When actions (such as a login or a Job being paused) occur on the
   * Cohesity Cluster, the Cluster generates Audit Logs.
   * If no parameters are specified, all logs currently on the Cohesity Cluster
   * are returned. Specifying the parameters filters the results that are returned.
   * @param params The `AuditService.SearchClusterAuditLogsParams` containing the following parameters:
   *
   * - `userNames`: Filter by user names who cause the actions that generate Cluster Audit
   *   Logs.
   *
   * - `tenantId`: TenantId specifies the tenant whose action resulted in the audit log.
   *
   * - `startTimeUsecs`: Filter by a start time. Only Cluster audit logs that were generated after
   *   the specified time are returned.
   *   Specify the start time as a Unix epoch Timestamp (in microseconds).
   *
   * - `startIndex`: Specifies an index number that can be used to return subsets of items
   *   in multiple requests.
   *   Break up the items to return into multiple requests by setting pageCount
   *   and startIndex to return a subsets of items in the search result.
   *   For example, set startIndex to 0 to get the first set of pageCount items
   *   for the first request. Increment startIndex by pageCount
   *   to get the next set of pageCount items for a next request.
   *   Continue until all items are returned and therefore the total number of
   *   returned items is equal to totalCount. Default value is 0.
   *
   * - `search`: Filter by matching a substring in entity name or details of the
   *   Cluster audit log.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `pageCount`: Limit the number of items to return in the response for pagination
   *   purposes. Default value is 1000.
   *
   * - `outputFormat`: Specifies the format of the output such as csv and json.
   *   If not specified, the json format is returned. If csv is specified,
   *   a comma-separated list with a heading row is returned.
   *
   * - `entityTypes`: Filter by entity types involved in the actions that generate the
   *   Cluster audit logs, such as User, Protection Job, View, etc.
   *   For a complete list, see the Category drop-down in the Admin > Audit Logs
   *   page of the Cohesity Dashboard.
   *
   * - `endTimeUsecs`: Filter by a end time specified as a Unix epoch Timestamp
   *   (in microseconds). Only Cluster audit logs that were generated before the
   *   specified end time are returned.
   *
   * - `domains`: Filter by domains of users who cause the actions that trigger
   *   Cluster audit logs.
   *
   * - `allUnderHierarchy`: AllUnderHierarchy specifies if logs of all the tenants under the hierarchy
   *   of tenant with id TenantId should be returned.
   *
   * - `actions`: Filter by the actions that generate Cluster audit logs such as
   *   Activate, Cancel, Clone, Create, etc. For a complete list, see the
   *   Actions drop-down in the Admin > Audit Logs page of the Cohesity
   *   Dashboard.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  SearchClusterAuditLogs(params: AuditService.SearchClusterAuditLogsParams): __Observable<ClusterAuditLogsSearchResult> {
    return this.SearchClusterAuditLogsResponse(params).pipe(
      __map(_r => _r.body as ClusterAuditLogsSearchResult)
    );
  }
}

module AuditService {

  /**
   * Parameters for SearchClusterAuditLogs
   */
  export interface SearchClusterAuditLogsParams {

    /**
     * Filter by user names who cause the actions that generate Cluster Audit
     * Logs.
     */
    userNames?: Array<string>;

    /**
     * TenantId specifies the tenant whose action resulted in the audit log.
     */
    tenantId?: string;

    /**
     * Filter by a start time. Only Cluster audit logs that were generated after
     * the specified time are returned.
     * Specify the start time as a Unix epoch Timestamp (in microseconds).
     */
    startTimeUsecs?: number;

    /**
     * Specifies an index number that can be used to return subsets of items
     * in multiple requests.
     * Break up the items to return into multiple requests by setting pageCount
     * and startIndex to return a subsets of items in the search result.
     * For example, set startIndex to 0 to get the first set of pageCount items
     * for the first request. Increment startIndex by pageCount
     * to get the next set of pageCount items for a next request.
     * Continue until all items are returned and therefore the total number of
     * returned items is equal to totalCount. Default value is 0.
     */
    startIndex?: number;

    /**
     * Filter by matching a substring in entity name or details of the
     * Cluster audit log.
     */
    search?: string;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Limit the number of items to return in the response for pagination
     * purposes. Default value is 1000.
     */
    pageCount?: number;

    /**
     * Specifies the format of the output such as csv and json.
     * If not specified, the json format is returned. If csv is specified,
     * a comma-separated list with a heading row is returned.
     */
    outputFormat?: string;

    /**
     * Filter by entity types involved in the actions that generate the
     * Cluster audit logs, such as User, Protection Job, View, etc.
     * For a complete list, see the Category drop-down in the Admin > Audit Logs
     * page of the Cohesity Dashboard.
     */
    entityTypes?: Array<string>;

    /**
     * Filter by a end time specified as a Unix epoch Timestamp
     * (in microseconds). Only Cluster audit logs that were generated before the
     * specified end time are returned.
     */
    endTimeUsecs?: number;

    /**
     * Filter by domains of users who cause the actions that trigger
     * Cluster audit logs.
     */
    domains?: Array<string>;

    /**
     * AllUnderHierarchy specifies if logs of all the tenants under the hierarchy
     * of tenant with id TenantId should be returned.
     */
    allUnderHierarchy?: boolean;

    /**
     * Filter by the actions that generate Cluster audit logs such as
     * Activate, Cancel, Clone, Create, etc. For a complete list, see the
     * Actions drop-down in the Admin > Audit Logs page of the Cohesity
     * Dashboard.
     */
    actions?: Array<string>;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { AuditService }
