import { ProtectionSource, ProtectionSourceNode } from '@cohesity/api/v1';
import {
  AcropolisDiskInfo,
  AwsProtectionGroupParams,
  AzureSqlPackageOptions,
  AzureSqlSkuOptions,
  CassandraProtectionGroupParams,
  CloudBackupScriptParams,
  ExternallyTriggeredJobParams,
  FileLevelDataLockConfig,
  Filter,
  HyperVDiskInfo,
  KubernetesFilterParams,
  KubernetesLabel,
  NasProtocol,
  ProtectionGroupAlertingPolicy,
  ProtectionGroupRun,
  RemoteAdapterHost,
  SapHanaProtectionGroupParams,
  SfdcProtectionGroupParams,
  TimeOfDay,
  UdaProtectionGroupParams,
  View,
  VMFilter,
  VmwareProtectionGroupStandbyResourceParams,
} from '@cohesity/api/v2';
import { SourceSelection } from '@cohesity/iris-source-tree';
import { AzureAdInclusionOptions, CloudJobType, EnvironmentType, MongoDBPreferredNodeType } from 'src/app/shared';
import { HdfsPathType } from 'src/app/shared/constants';
import { JobSourceTreeComponent } from 'src/app/shared/source-tree';

import { IncludeExcludePaths } from '../components';

export type AlertingPolicy = ('kSuccess' | 'kFailure' | 'kSlaViolation' | 'kWarning')[];
export type HyperVProtectionType = 'kAuto' | 'kVSS' | 'kRCT';
export type Priority = 'kLow' | 'kMedium' | 'kHigh';
export type QosPolicy = 'kBackupHDD' | 'kBackupSSD' | 'kTestAndDevHigh' | 'kBackupAll';
export type IgnorableErrorTypes = ('kEOF' | 'kNonExistent')[];
export type DeltaType = 'incremental' | 'differential';
/**
 * Enum for last / all snapshots
 */
export enum PureRetentionOptions {
  last,
  all,
}

/**
 * Pure retention form group interface
 */
export interface PureRetention {
  maxSnapshotsOnPrimary?: number;
  snapshotRetention?: PureRetentionOptions;
}

export interface CrashConsistentSettings {
  /**
   * Whether or not to take crash consistent backups
   */
  isCrashConsistentBackup: boolean;

  /**
   * Whether on not to continue on crash consistent backup failure
   */
  continueOnFailure?: boolean;

  /**
   * Whether or not excluding writers is enabled or not
   */
  enabled?: boolean;

  /**
   * Include and Exclude VSS writers.
   */
  includeExcludeWriters?: IncludeExcludePaths;
}

/**
 * Interface for VssWriters in CrashConsistentSettings.
 */
export interface VssWriters {
  /**
   * name for VssWriter.
   */
  writerName: string;

  /**
   * Flag to check if writer is excluded or included.
   */
  isWriterExcluded: boolean;
}

/**
 * Interface for indexing params in protection group
 */
export interface Indexing {
  /**
   * Whether or not indexing is enabled or not
   */
  enabled: boolean;

  /**
   * Include and Exclude paths to be indexed
   */
  paths?: IncludeExcludePaths;
}

export interface PathInclusionExclusion {
  /**
   * All path or specific path
   */
  pathType: HdfsPathType;

  /**
   * Include and Exclude paths to be indexed
   */
  paths?: IncludeExcludePaths;
}

/**
 * interface for kubernetes include exclude labels at group level
 */
export interface KubernetesLabelsIncludeExclude {
  /**
   * Whether or not indexing is enabled or not
   */
   enableLabelFilter: boolean;

   /**
    * Include and Exclude paths to be indexed
    */
   logicalType?: 'OR' | 'AND';

   /**
    * include or exclude
    */
   filterType?: 'include' | 'exclude';

   /**
    * array of key-value pairs for include/exclude
    */
   labels?: KubernetesLabel[];
}

/**
 * Interface for global excludes params in physical protection group
 */
export interface GlobalExclusions {
  paths?: string[];
}

/**
 * Interface for global exclude fsTypes params in physical protection group
 */
export interface GlobalFsExclusions {
  /**
   * List of File-system types, to be excluded globally for all the sources in a protection group.
   */
  fsTypes?: string[];
}

/**
 * Interface for NAS Exclusions params in protection group
 */
export interface Exclusions {
  /**
   * Whether or not indexing is enabled or not
   */
  enabled: boolean;

  /**
   * Include and Exclude paths to be indexed
   */
  paths?: IncludeExcludePaths;
}

/**
 * Interface for NAS Continuous snapshots setting in protection group
 */
export interface ContinuousSnapshots {
  /**
   * Specifies if the continuous snapshots setting is enabled or not.
   */
  enabled: boolean;

  /**
   * Specifies the maximum continuous snapshots allowed if there is already a
   * pending run.
   */
  maxAllowedSnapshots?: number;
}

/**
 * Specifies filtered IP addresses for NAS protection groups.
 */
export interface FilterIps {
  /**
   * Specifies if the filter Ips setting is enabled or not.
   */
  enabled: boolean;

  /**
   * Specifies the Filter config for IP addresses.
   */
  filterIpConfig?: FilterIpConfig;
}

/**
 * Specifies Baseline Incremental frequency for S3.
 */
export interface S3BaselineFrequency {
  /**
   * Specifies if the filter Ips setting is enabled or not.
   */
  enabled: boolean;

  /**
   * Specifies the Filter config for IP addresses.
   */
  baselineFrequency?: string;
}

/**
 * Specifies the Inventory bucket Location for S3.
 */
export interface InventoryBucketLocation {
  /**
   * ARN of the inventory report destination bucket for S3 backups.
   */
  arn: string;

  /**
   * The prefix in the S3 destination bucket where inventory reports will be stored.
   */
  prefix: string;

  /**
   * The S3 bucket required for inventory location.
   */
  bucket: ProtectionSource;
}

/**
 * Specifies the Filter config for IP addresses.
 */
export interface FilterIpConfig {
  /**
   * Specifies the type of filter applied like 'allow' or 'deny'.
   */
  filterType: 'allow' | 'deny';

  /**
   * Specifies list of exclusively allowed or denied IP addresses.
   */
  ipList: string[];
}

/**
 * Interface for Host based pre/post scripts.
 */
export interface RemotePrePostScripts extends PrePostScripts {
  // TODO(maulik): Remote hosts should not have toggle.
  // They are mandatory parameters.
  /**
   * Whether or not Remote host details are toggled on
   */
  remoteScriptsToggle?: boolean;

  /**
   * Remote host ddetails
   */
  remoteHost?: RemoteHost;
}

/**
 * Interface for pre/post script params in protection group
 */
export interface PrePostScripts {
  /**
   * Whether or not pre script is toggled on
   */
  preScriptToggle?: boolean;

  /**
   * Whether or not post script is toggled on
   */
  postScriptToggle?: boolean;

  /**
   * Whether or not post script is toggled on
   */
  postSnapshotScriptToggle?: boolean;

  /**
   * Whether or not post script is toggled on
   */
  postBackupScriptToggle?: boolean;

  /**
   * Pre Script params
   */
  preScript?: CommonPreOrPostScript;

  /**
   * Post Script Params
   */
  postScript?: CommonPreOrPostScript;

  /**
   * Post Script Params
   */
  postBackupScript?: CommonPreOrPostScript;

  /**
   * Post Script Params
   */
  postSnapshotScript?: CommonPreOrPostScript;
}

/**
 * Interface for remote host details in pre/post scripts
 */
export interface RemoteHost {
  /**
   * Specifies the Hostname or IP address of the host where the pre and post script will be run.
   */
  address: string;

  /**
   * Specifies the username for the host.
   */
  username: string;

  /**
   * Specifies the host type of the remote host
   */
  hostType?: 'kLinux' | 'kWindows' | 'kOther' | 'kAix' | 'kSolaris' | 'kSapHana';
}

/**
 * Common interface for pre or post script
 */
export interface CommonPreOrPostScript {
  /**
   * Pre or post Script name
   */
  scriptName: string;

  /**
   * Pre or post script parameters
   */
  scriptParams: string;

  /**
   * Timeout value for the script
   */
  timeout: number;

  /**
   * Whether or not script should continue on errors
   */
  continueEnabled?: boolean;
}

/**
 * Type for all protection types.
 */
export type ProtectionType = CloudJobType | HyperVProtectionType;

/**
 * Interface for source side deduplication.
 */
export interface SourceSideDeduplication {
  enabled: boolean;
  exclusions: number[];
}

/**
 * Snapshot Label for Netapp Data-Protect Volume Job.
 */
export interface SnapshotLabelModel {
  /**
   * Enable Snapshot label setting
   */
  enableSnapshotLabel: boolean;

  /**
   * Full Snapshot label value
   */
  fullSnapshotLabel?: string;

  /**
   * Incremental snapshot label value
   */
  incrementalSnapshotLabel?: string;
}

/**
 * Interface for Remote View params for all selected views.
 */
export interface RemoteViews {
  remoteViewParams: RemoteView[];
}

/**
 * Interface for Remote view params for a particular view.
 */
export interface RemoteView {
  /**
   * Specifies the identifier of protected view.
   */
  sourceViewId: number;

  /**
   * Specifies the name of the view being protected.
   */
  sourceViewName: string;

  /**
   * Specifies the name of the view on the replication side.
   */
  viewName: string;
}

/**
 * Source throttling config for source and protection groups
 */
export interface ThrottlingSettings {
  /**
   * Specifies the percentage value of maximum concurrent metadata to be fetched
   * during full backup of the source.
   */
  maxMetadataFetchFullPercentage: number;

  /**
   * Specifies the percentage value of maximum concurrent metadata to be fetched
   * during incremental backup of the source.
   */
  maxMetadataFetchIncrementalPercentage: number;

  /**
   * Specifies the percentage value of maximum concurrent read/write during full
   * backup of the source.
   */
  maxReadWriteFullPercentage: number;

  /**
   * Specifies the percentage value of maximum concurrent read/write during
   * incremental backup of the source.
   */
  maxReadWriteIncrementalPercentage: number;
}

/**
 * Specifies the information of the missing/invalid source entity.
 * Unwanted IDs include entity IDs that are either missing from the entity
 * hierarchy OR are present in the Entity Hierarchy but are no more
 * compatible for the current backup type.
 *
 * Missing Entity Example:
 * t0 -> PG1 has VMware entities with ID [1-10].
 * t1 -> Entity ID 10 goes away from the EH due to removal of VM within
 *       vCenter after EH refresh. Entity 10 qualifies to be missing entity.
 *       EH contains [1-9].
 *       PG1 still contains [1-10].
 *
 * Invalid Entity Example:
 * t0 -> PG1 has User(OneDrive) entities with ID [1-10].
 * t2 -> Entity ID 10 has its OneDrive license revoked at the M365 source.
 *       The entity continues to stay within EH as a valid user with valid
 *       mailbox but now qualifies as an invalid entity for a OneDrive job.
 *       EH contains [1-10].
 *       PG1 still contains [1-10].
 */
export interface UnwantedObjectParams {
  /**
   * Specifies the id of the object.
   */
  id: number;

  /**
   * Specifies the name of the object.
   */
  name: string;

  /**
   * Specifies the selection of the object.
   */
  remove: boolean;

  /**
   * Specifies the id of the parent source object.
   */
  parentSourceId?: number;

  /**
   * Specifies the name of the parent source object.
   */
  parentSourceName?: string;

  /**
   * Missing application entity params.
   */
  applicationEntities?: Partial<UnwantedObjectParams[]>;
}

/**
 * Interface for Netapp Snapshot Backup Preferences.
 */
export interface SnapshotBackupPreferences {
  /**
   * Specifies the backup preference for Netapp backup.
   */
  backupPreference: string;

  /**
   * Specifies the snapshot prefix interface for Netapp backup.
   */
  snapshotPrefix?: SnapshotPrefix;
}

export interface SnapMirrorBackupPreferences {

  /**
   * specify whether we want snapMirrorBackup enabled
   */
  isSnapMirrorBackup: boolean;
  /**
   * Specifies the snapMirror config interface for Netapp backup.
   */
  snapMirrorConfig?: SnapMirrorConfig;
}

/**
 * Specifies the snapMirror config interface for Netapp backup.
 */
export interface SnapMirrorConfig {
  /**
   * Specifies the S3 View to be used in SnapMirror Netapp backup.
   */
  view: View;

  /**
   * Specifies the incremental prefix for SnapMirror Netapp backup.
   */
  incrementalPrefix?: string;
}

/**
 * Specifies the snapshot prefix interface for Netapp backup.
 */
export interface SnapshotPrefix {
  /**
   * Specifies the incremental snapshot prefix for Netapp backup.
   */
  incrementalPrefix: string;

  /**
   * Specifies the full snapshot prefix for Netapp backup.
   */
  fullPrefix: string;
}

/**
 * Specifies the config for action taken during quiet times.
 */
export interface QuietTimesConfig {
  /**
   * Determines if there is action to be taken during quiet times.
   */
  enabled: boolean;

  /**
   * Specifies the action taken during quiet times.
   */
  action?: 'pause' | 'abort';
}

/**
 * Specifies the config for creating a private endpoint.
 */
export interface PrivateNetworkInfo {
  /**
   * Specifies the region.
   */
  location: string;

  /**
   * Specifies the id of the virtual network.
   */
  vpn: any;

  /**
   * Specifies the id of the subnet.
   */
  subnet: any;
}

/**
 * Specifies the config for backup using private endpoints.
 */
export interface DataTransferInfo {
  /**
   * Specifies if we are using public or private endpoints.
   */
  isPrivateNetwork: boolean;

  /**
   * Specifies the list of configurations for private endpoints.
   */
  privateNetworkInfoList: PrivateNetworkInfo[];
}

/**
 * This interface defines the properties currently used within the protection builder.
 * An implementation for this can be based on either a v1 or v2 api call, or even a
 * simple object that initializes several form controls.
 */
export interface ProtectionGroupObject {
  /**
   * Abort in blackouts value.
   */
  readonly abortInBlackouts?: boolean;

  /**
   * Specifies the config for action taken during quiet times.
   */
  readonly quietTimes?: QuietTimesConfig;

  /**
   * Indicates the run status for which alerts should be thrown.
   */
  readonly alertingPolicy?: ProtectionGroupAlertingPolicy;

  /**
   * Whether to enable cloud migration.
   */
  readonly cloudMigration?: boolean;

  /**
   * The protection group description.
   */
  readonly description?: string;

  /**
   * Whether to enable sync replication.
   */
  readonly enableCdpSyncReplication?: boolean;

  /**
   * The protection group environment.
   */
  readonly environment?: EnvironmentType;

  /**
   * Whether to fallback to crash consistent snapshot.
   */
  readonly crashConsistentBackup?: boolean;

  /**
   * This is set when editing an object's protection rather than a specific protection goup.
   */
  readonly editingProtectedObject?: boolean;

  /**
   * Exclude VSS writers to sent in API.
   */
  readonly excludedVssWriters?: string[];

  /**
   * The id of the group object. If this is present, then the form should be in edit mode.
   */
  readonly id?: number | string;

  /**
   * Whether to leverage Hyperflex snapshots.
   */
  readonly leverageHyperflexSnapshots?: boolean;

  /**
   * Whether to leverage Nutanix snapshots.
   */
  readonly leverageNutanixSnapshots?: boolean;

  /**
   * Whether to leverage storage snapshots.
   */
  readonly leverageStorageSnapshots?: boolean;

  /**
   * Whether to leverage SAN transport.
   */
  readonly leverageSanTransport?: boolean;

  /**
   * Whether allow Nbdssl Transport Fallback when leverageSanTransport is on.
   */
  readonly allowNbdsslTransportFallback?: boolean;

  /**
   * Whether to allow parallel runs and defer incomplete objects in concurrent runs.
   */
  readonly allowParallelRuns?: boolean;

  /**
   * The migration target name.
   */
  readonly migrationTargetName?: string;

  /**
   * The group name
   */
  readonly name?: string;

  /**
   * The nas protocol
   */
  readonly nasProtocol?: NasProtocol;

  /**
   * NFS preference for multi NFS protocol volumes.
   */
  readonly nfsPreference?: boolean;

  /**
   * The policy ide
   */
  readonly policyId?: string;

  /**
   * The policy ide
   */
  readonly csmPolicyId?: string;

  /**
   * The policy ide
   */
  readonly ingestPolicyId?: string;

  /**
   * The priority.
   */
  readonly priority?: Priority;

  /**
   * This is applicable only for sap hana dms.
   */
  readonly delta?: DeltaType;

  /**
   * Pure env params
   */
  readonly pureParams?: PureRetention;

  /**
   * The QOS Policy
   */
  readonly qosPolicy?: QosPolicy;

  /**
   * Whether app consistent is enabled.
   */
  readonly appConsistentBackups?: boolean;
  /**
   * The parent source being protected.
   */
  readonly selectedSourceId?: number;

  readonly selectedSourceNode?: ProtectionSourceNode;

  /**
   * The selected protection source.
   */
  readonly protectionSource?: ProtectionSource;
  /**
   * The selection to pass to the source tree, consisting of selected, excluded, and tagged ids.
   */
  readonly selection?: SourceSelection;

  /**
   * The source tree passed in the protection group (used in quick protect to
   * full protect workflow, without this, the full form doesn't appear).
   */
  readonly sourceTree?: JobSourceTreeComponent<any, any>;

  /**
   * SLA Config
   */
  readonly sla?: any;

  /**
   * The storage domain id.
   */
  readonly storageDomainId?: number;

  /**
   * The start time
   */
  readonly time?: TimeOfDay;

  /**
   * The time zone the group is configured for.
   */
  readonly timeZone?: string;

  /**
   * Array of excluded disks.
   */
  readonly excludeDisks?: any[];

  /**
   * Exclude Physical RDM Volumes
   */
  readonly excludePhysicalRdm?: boolean;

  /**
   * Selected View ID
   */
  readonly viewId?: number;

  /**
   * Selected View IDs
   */
  readonly viewIds?: number[];

  /**
   * Remote View
   */
  readonly remoteView?: any;

  /**
   * Remote View list
   */
  readonly remoteViews?: RemoteView[];

  /**
   * Use Isilon Change List
   */
  readonly useChangelist?: boolean;

  /**
   * MS SQL Params
   */
  readonly mssqlParams?: any;

  /**
   * Oracle Params
   */
  readonly oracleParams?: any;

  /**
   * VMware Params
   */
  readonly vmwareParams?: any;

  /**
   * Office365 Params
   */
  readonly office365Params?: any;

  /**
   * Salesforce Params
   */
  readonly sfdcParams?: Partial<SfdcProtectionGroupParams>;

  /**
   * Physical Params
   */
  readonly physicalParams?: any;

  /**
   * Env params for Universal Data Adapter.
   */
  readonly udaParams?: Partial<UdaProtectionGroupParams>;

  /**
   * Env params for Sap Hana Adapter.
   */
  readonly sapHanaParams?: Partial<SapHanaProtectionGroupParams>;

  /**
   * Aws Params
   */
  readonly awsParams?: Partial<AwsProtectionGroupParams>;

  /**
   * Incremental After Restart
   */
  readonly incrementalBackupAfterRestart?: boolean;

  /**
   * Skip File on Errors
   */
  readonly continueOnError?: boolean;

  /**
   * Crash consistency settings for physical file protection group
   */
  readonly crashConsistentSettings?: CrashConsistentSettings;

  /**
   * Indexing Policy
   */
  readonly indexing?: Indexing;

  /**
   * Externally triggered job parameters.
   */
  readonly externallyTriggeredJobParams?: ExternallyTriggeredJobParams;

  /**
   * Pre/Post Scripts
   */
  readonly prePostScripts?: RemotePrePostScripts;

  /**
   * End time
   */
  readonly endTime?: Date;

  /**
   * Last run of the protection group
   */
  readonly lastRun?: ProtectionGroupRun;

  /**
   * Whether the protection group is active
   */
  readonly isActive?: boolean;

  /**
   * Whether the protection is deleted
   */
  readonly isDeleted?: boolean;

  /**
   * Whether the protection is paused
   */
  readonly isPaused?: boolean;

  /**
   * Whether the protection is safe guarded copy snapshot
   */
  readonly isSafeGuardedCopySnapshot?: boolean;

  /**
   * Whether Cache Optimization is enabled.
   */
  readonly cacheOptimization?: boolean;

  /**
   * Whether the protection is for log backup.
   */
  readonly isLogBackupJob?: boolean;

  /**
   * Whether the protection is for system keyspaces.
   */
  readonly isSystemKeyspaceBackup?: boolean;

  /**
   * NAS Exclusions
   */
  readonly exclusions?: Exclusions;

  /**
   * the jobType of a cloud or hyperv job
   */
  readonly protectionType?: ProtectionType;

  /**
   * whether to create an AMI on aws or not
   */
  readonly createAmi?: boolean;

  /**
   * create AMI after every nth run
   */
  readonly amiCreationFrequency?: number;

  /**
   * Source Side Deduplication
   */
  readonly sourceSideDeduplication?: SourceSideDeduplication;

  /**
   * Direct Cloud Archive
   */
  readonly directCloudArchive?: boolean;

  /**
   * Native format for Direct Cloud Archive
   */
  readonly nativeFormat?: boolean;

  /**
   * Whether exchange log truncation is enabled.
   * Only applicable for Exchange Protection Group.
   */
  readonly truncateExchangeLog?: boolean;

  /**
   * Global excludes for adaptors supporting exclusions within backup.
   */
  readonly globalExcludePaths?: string[];

  /**
   * Global excludes fs types for adaptors supporting fs_type exclusions within backup.
   */
  readonly globalExcludeFS?: string[];

  /**
   * Specifies whether Preservation Hold Library is being backed up.
   * This is used by M365 OneDrive & SharePoint backup.
   */
  readonly enablePhlBackup?: boolean;

  /**
   * Global includes for adaptors supporting inclusions within backup.
   */
  readonly globalIncludePaths?: string[];

  /**
   * Remote Adapter Host info.
   */
  readonly hosts?: RemoteAdapterHost[];

  /**
   * Concurrency per stream for NoSql-Hadoop adapters.
   */
  readonly concurrency?: number;

  /**
   * Bandwidth throttling per stream for NoSql-Hadoop adapters.
   */
  readonly bandwidthThrottling?: number;

  /**
   * Specifies whether the mount points have to be persisted for Oracle backups.
   */
  readonly persistMountpoints?: boolean;

  /**
   * Snapshot Label for Read-Only Netapp Volumes
   */
  readonly snapshotLabel?: SnapshotLabelModel;

  /**
   * Paths to include for protection.
   * Only applicable for HDFS Protection Group.
   */
  readonly includePaths?: string[];

  /**
   * Paths to exclude for protection.
   * Only applicable for HDFS Protection Group.
   */
  readonly excludePaths?: string[];

  /**
   * NFS Encryption
   * Only applicable for NAS Protection Group.
   */
  readonly encryptionEnabled?: boolean;

  /**
   * Vlan target.
   * Only applicable for Oracle Protection Group.
   * TODO (Tung): specify type when yaml model is available.
   */
  readonly vlan?: any;

  /**
   * Continuous Snapshots for NAS
   * Applicable only in case of Netapp and Isilon
   */
  readonly continuousSnapshots?: ContinuousSnapshots;

  /**
   * NAS File Data Lock config
   */
  readonly fileLockConfig?: FileLevelDataLockConfig;

  /**
   * Netapp SnapMirror Backup config.
   */
  readonly snapMirrorBackup?: boolean;

  /**
   * Specifies filtered IP addresses for NAS protection groups.
   */
  readonly filterIps?: FilterIps;

  /**
   * Specifies the source throttling configuration for NAS protection groups.
   */
  readonly throttling?: ThrottlingSettings;

  /**
   * Specifies the Snapshot Backup Preferences for Netapp backup.
   */
  readonly snapshotBackupPreferences?: SnapshotBackupPreferences;

  /**
   * Specifies the Snapshot Backup Preferences for Netapp backup.
   */
  readonly snapMirrorConfig?: SnapMirrorBackupPreferences;

  /**
   * Specifies the Information about the missing source entities.
   */
  readonly missingObjectsParams?: UnwantedObjectParams[];

  /**
   * Specifies the Information about the invalid source entities.
   * An entity will be considered invalid if it is part of an active
   * protection group but has lost compatibility for the given backup type.
   */
  readonly invalidObjectsParams?: UnwantedObjectParams[];

  /**
   * Specifies the list of exclusion filters applied during the group creation or edit.
   */
  readonly excludeFilters?: Filter[] | VMFilter[];

  /**
   * Vss backup type options.
   * Applicable only for hyper-V environment
   */
  readonly vssBackupType?: boolean;

  /**
   * Specifies the standby resource objects for backup.
   */
  readonly standbyObjects?: VmwareProtectionGroupStandbyResourceParams[];

  /**
   * Whether to leverage CSI snapshots. Applicable only for kubernetes
   * environment.
   */
  readonly leverageCsiSnapshot?: boolean;

  /**
   * Whether to apply volume backup failure feature. Applicable only for kubernetes
   * environment.
   */
  readonly volumeBackupFailure?: boolean;

  /**
   * Whether to use non snapshot Backup. Applicable only for kubernetes
   * environment.
   */
  readonly nonSnapshotBackup?: boolean;

  /**
   * Specifies labels for kubernetes that are included.
   */
  readonly kubernetesIncludeParams?: KubernetesFilterParams;

  /**
   * Specifies labels for kubernetes that are excluded.
   */
  readonly kubernetesExcludeParams?: KubernetesFilterParams;

  /**
   * Environment level EBS Volume Exclusion parameters for AWS EC2 Volumes.
   */
  readonly globalEbsVolumeExclusions?: any;

  /**
   * Environment level GCP Volume Exclusion parameters for GCP disk volumes.
   */
  readonly gcpDiskExclusions?: any;

  /**
   * Environment level Azure Disk Exclusion parameters for Azure Disks.
   */
  readonly azureDiskExclusions?: any;

  /**
   * Specifies the Errors to be ignored in error db
   */
  readonly ignorableErrors?: string[];

  /**
   * Environment parameters for Cassandra environment.
   */
  readonly cassandraParams?: Partial<CassandraProtectionGroupParams>;

  /**
   * Specifies the modify source permission setting, applicable only to Netapp and Isilon.
   */
  readonly modifySourcePermissions?: boolean;

  /**
   * Specifies the Acropolis global exclude disks.
   */
  readonly acropolisGlobalExcludeDisks?: AcropolisDiskInfo[];

  /**
   * Specifies the Acropolis global include disks.
   */
  readonly acropolisGlobalIncludeDisks?: AcropolisDiskInfo[];

  /**
   * Specifies the HyperV global exclude disks.
   */
  readonly hypervGlobalExcludeDisks?: HyperVDiskInfo[];

  /**
   * Specifies the HyperV global include disks.
   */
  readonly hypervGlobalIncludeDisks?: HyperVDiskInfo[];

  /**
   * Specifies whether or not to perform brick based deduplication on this Protection Group.
   */
  performBrickBasedDeduplication?: boolean;

  /**
   * DataTransferInfo for private endpoint.
   */
  dataTransferInfo?: DataTransferInfo;

  /**
   * Storage Classes for S3 protection.
   */
  storageClasses?: string[];

  /**
   * Enable object level acl backups.
   */
  enableAclBackups?: boolean;

  /**
   * Inventory bucket location info.
   */
  inventoryBucketLocation?: InventoryBucketLocation;

  /**
   * Inventory bucket for Quick protect flow.
   */
  inventoryBucket?: ProtectionSourceNode;

  /**
   * Inventory Report frequency.
   */
  inventoryReportFrequency?: string;

  /**
   * Baseline incremental frequency.
   */
  baselineFrequency?: S3BaselineFrequency;

  /**
   * Dynamically set Concurrency per stream.
   */
  autoScaleConcurrency?: boolean;

  /**
   * Specifies the pre script and post script to run cloud backup.
   */
  cloudPrePostScript?: CloudBackupScriptParams;

  /**
   * Specifies to enable/disable creation of copy database during Azure SQL Backup process.
   */
  copyDatabase?: boolean;

  /**
   * Specifies the SQL package parameters which are specific to Azure related Object Protection.
   */
  sqlPackageOptions?: AzureSqlPackageOptions;

  /**
   * Specifies the copy database SKU config for Azure SQL object protection.
   */
  copyDatabaseSku?: AzureSqlSkuOptions;

  /**
   * Specifies the temporary database disk size attached in GB.
   */
  tempDiskSizeGb?: number;

  /**
   * Specifies Azure AD inclusion options
   */
  azureAdInclusionOptions?: AzureAdInclusionOptions;

  /**
   * Excluded object types during Azure AD object protection.
   */
  excludedObjectTypes?: string[];

  /**
   * Paused Note
   */
  pausedNote?: string;

  /**
   * Whether to convert incremental backup to full backup on node failure.
   */
  convertToFullOnFailure?: boolean;

  /**
   * Specifies the preferred node for backup.
   */
  preferredNode?: MongoDBPreferredNodeType;
}
