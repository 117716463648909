import { assign } from 'lodash-es';
// Module: Alerts Detail

(function(angular, undefined) {
  'use strict';

  angular.module('C.alerts').controller('alertsDetailCtrl', AlertsDetailCtrlFn);

  function AlertsDetailCtrlFn($scope, $transition$, evalAJAX, AlertService,
    ENUM_ALERTS_SEVERITY,ENUM_ALERTS_STATUS, StateManagementService) {
    var $ctrl = this;

    // This alert code is for anomaly alerts
    // The purpose is to redirect user to security dashboard to view details.
    const ANOMALY_ALERT_CODE = 'CE01516011';

    /**
     * Navigates to previous state.
     */
    $scope.goBack = function goBack() {
      StateManagementService.goToPreviousState();
    };

    assign($ctrl, {
      // variables
      dataReady: false,
      isAnomalyAlert: false,
      theAlert: {},

      // constants
      ENUM_ALERTS_SEVERITY: ENUM_ALERTS_SEVERITY,
      ENUM_ALERTS_STATUS: ENUM_ALERTS_STATUS,

      // functions
      $onInit: $onInit,
    });

    /**
     * Controller $onInit lifecycle hook.
     *
     * @method    $onInit
     */
    function $onInit() {
      // hit the API for the details
      AlertService.getAlert($transition$.params().id).then(
        function getAlertSuccess(result) {
          // V2 Api returns the array inside '.alert', map that correctly to the UI.
          if (result.data && result.data.alert) {
            $ctrl.theAlert = result.data.alert;
          } else {
            $ctrl.theAlert = result.data;
          }

          // Check if its a anomaly alert
          $ctrl.isAnomalyAlert = ANOMALY_ALERT_CODE === $ctrl.theAlert.alertCode;
          $ctrl.dataReady = true;
        },
        evalAJAX.errorMessage,
      );
    }
  }
})(angular);
