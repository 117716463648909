<div class="flex-row vertical-align margin-top margin-bottom">
  <!-- commenting filter since we only have one base policy for now -->
  <cog-filters class="hidden">
    <ng-container *ngIf="viewType === 'table'">
      <cog-value-property-filter
        *cogFilterDef="let params = params;
          let filter;
          quickFilter true;
          key: 'resourceType';
          label: 'type' | translate;
          filterType: 'valueProperty'"
        [filterDefParams]="params"
        [filter]="filter"
        [allowMultiple]="true"
        [filterValues]="resourceFilters || []">
      </cog-value-property-filter>
    </ng-container>

    <ng-container *ngIf="viewType !== 'table'">
      <cog-value-property-filter
        *cogFilterDef="let params = params;
          let filter;
          quickFilter true;
          key: 'statusType';
          label: 'status' | translate;
          filterType: 'valueProperty'"
        [filterDefParams]="params"
        [filter]="filter"
        [allowMultiple]="true"
        [filterValues]="statusFilters || []">
      </cog-value-property-filter>
    </ng-container>
    <cog-search-property-filter
      cogDataId="argus-dlp-table-filter-by-policy-name"
      *cogFilterDef="let filter;
        key: 'name';
        label: 'name' | translate;
        quickFilter: true;
        filterType: 'searchProperty'"
      [filter]="filter"
      [placeholder]="'name' | translate">
    </cog-search-property-filter>
  </cog-filters>

  <div class="flex-pull-right" *ngIf="filteredPolicyList.length && !hideActions">
    <ng-container *ngTemplateOutlet="createNewBtn"></ng-container>
  </div>
</div>

<ng-container *ngIf="filteredPolicyList.length; else noDataFound">
  <ng-container *ngTemplateOutlet="viewType === 'table' ? tableView : cardView"></ng-container>
</ng-container>

<ng-template #createNewBtn>
  <button mat-flat-button
    [dgHasPermissions]="['DGAAS_MANAGE_DC_POLICY', 'DGAAS_MODIFY']"
    *ngIf="viewType === 'table' && allowCreateNewPolicy"
    cogDataId="add-new-policy-btn"
    color="primary"
    cogDataId="add-new-policy"
    (click)="modifyPolicy.emit()">
    {{ 'createNewPolicy' | translate }}
  </button>

  <!-- download icon should not appear when policy list is empty -->
  <div class="flex-row-sm vertical-align" *ngIf="viewType === 'card'">
    <ng-container *ngIf="filteredPolicyList.length">
      <ng-container *ngTemplateOutlet="downloadOption"></ng-container>
    </ng-container>
    <button mat-flat-button
      [dgHasPermissions]="['DGAAS_MANAGE_DC_SCAN', 'DGAAS_MODIFY']"
      cogDataId="apply-policy-btn"
      color="primary"
      cogDataId="apply-policy"
      (click)="applyPolicy.emit()">
      {{ 'applyPolicy' | translate }}
    </button>
  </div>
</ng-template>

<ng-template #tableView>
  <cog-table name="policy-table" [source]="filteredPolicyList" [persistSelection]="true" [selection]="selection">
    <table mat-table matSort matSortActive="name">
      <!-- The table definition having info like what all columns to show in the header & in the rows -->
      <tr mat-header-row *matHeaderRowDef="colDefinition"></tr>
      <tr mat-row *matRowDef="let row; columns: colDefinition;"></tr>

      <!-- Definition for policy name column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>
          {{ 'name' | translate }}
        </th>
        <td mat-cell *matCellDef="let policy;">
          {{ policy.name }}
        </td>
      </ng-container>

      <!-- Definition for the type of the policy column -->
      <ng-container matColumnDef="buildType">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>
          {{ 'type' | translate }}
        </th>
        <td mat-cell *matCellDef="let policy;">
          <span class="flex-row-xs vertical-align">
            <cog-icon *ngIf="policy.buildType" [shape]="resourceTypeIcon[policy.buildType]"></cog-icon>
            <span>{{ policy.buildType | resourceType | naLabel }}</span>
          </span>
        </td>
      </ng-container>

      <!-- Definition for number of patterns associated with the policy column -->
      <ng-container matColumnDef="patterns">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>
          {{ 'patterns' | translate }}
        </th>
        <td mat-cell *matCellDef="let policy;">
          {{ (policy.patternIds?.length || 0) | number }}
        </td>
      </ng-container>

      <!-- Definition for actions column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let policy;" class="show-on-hover-cell">
          <button mat-icon-button
            [dgHasPermissions]="['DGAAS_MANAGE_DC_POLICY', 'DGAAS_MODIFY']"
            [matMenuTriggerFor]="menu"
            cogDataId="policy-{{ policy.name }}-actions"
            [inheritDataId]="true">
            <cog-icon shape="more_vert"></cog-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item
              *ngIf="allowCreateNewPolicy"
              cogDataId="policy-{{ policy.name }}-copy-option"
              [inheritDataId]="true"
              (click)="copyPolicy.emit(policy)">
              {{ 'copy' | translate }}
            </button>
            <button mat-menu-item
              cogDataId="policy-{{ policy.name }}-edit-option"
              [inheritDataId]="true"
              (click)="modifyPolicy.emit(policy)">
              {{ 'edit' | translate }}
            </button>
            <span [matTooltip]="'argus.policy.deleteTooltip' | translate"
              [matTooltipDisabled]="policy.buildType !== resourceType.builtIn">
              <button mat-menu-item
                cogDataId="policy-{{ policy.name }}-delete-option"
                [inheritDataId]="true"
                [disabled]="policy.buildType === resourceType.builtIn"
                (click)="deletePolicy.emit(policy)">
                {{ 'delete' | translate }}
              </button>
            </span>
          </mat-menu>
        </td>
      </ng-container>
    </table>
    <!-- <mat-paginator [pageSize]="5"></mat-paginator> -->
  </cog-table>
</ng-template>

<ng-template #cardView>
  <a class="argus-card-anchor" *ngFor="let policy of filteredPolicyList" (click)="viewRunDetails(policy)">
    <cog-expansion-card
      class="margin-bottom"
      [showContent]="hasError(policy)">
      <cog-expansion-card-icon class="policy-icon {{ getPolicyStatusClass(policy) }}">
        <cog-icon size="lg" shape="argus:dlp-running-policy"></cog-icon>
      </cog-expansion-card-icon>
      <cog-expansion-card-header>
        <div class="flex-row-md vertical-align flex-grow">
          <div class="flex-column policy-name">
            <span>{{ policy.name }}</span>
            <small class="margin-top-sm">
              {{ getRunDetailsWithDelimiter(policy) }}
            </small>
          </div>

          <cog-statlist class="policy-metadata">
            <cog-statlist-item [label]="'dataPools' | translate">
              <span class="flex-row vertical-align">
                {{ policy.runDetails?.dataPoolsCount | number | naLabel }}
                <small class="margin-left-sm">{{ 'scanned' | translate }}</small>
              </span>
            </cog-statlist-item>
            <cog-statlist-item [label]="'patterns' | translate">
              <span class="flex-row vertical-align">
                {{ policy.runDetails?.patternsCount | number | naLabel }}
                <small class="margin-left-sm">{{ 'matched' | translate }}</small>
              </span>
            </cog-statlist-item>
            <cog-statlist-item [label]="'files' | translate">
              <span class="flex-row vertical-align">
                {{ policy.runDetails?.filesCount | number | naLabel }}
                <small class="margin-left-sm">{{ 'matched' | translate }}</small>
              </span>
            </cog-statlist-item>
          </cog-statlist>

          <button mat-icon-button
            cogDataId="edit-policy"
            cdkFocusInitial
            (click)="$event.stopPropagation(); editPolicy.emit(policy);"
            class="margin-right">
            <cog-icon shape="helix:edit-outline"></cog-icon>
          </button>
        </div>
      </cog-expansion-card-header>

      <cog-expansion-card-content *ngIf="hasError(policy)">
        <cog-banner status="critical" *ngIf="policy.runDetails?.message">
          {{ policy.runDetails?.message }}
        </cog-banner>
      </cog-expansion-card-content>
    </cog-expansion-card>
  </a>
</ng-template>

<ng-template #noDataFound>
  <cog-blank-card message="{{ 'noPoliciesFound' | translate }}">
    <ng-container *ngIf="!hideActions">
      <ng-container *ngTemplateOutlet="createNewBtn"></ng-container>
    </ng-container>
  </cog-blank-card>
</ng-template>

<ng-template #downloadOption>
  <button mat-icon-button
    [matMenuTriggerFor]="downloadMenu"
    [matTooltip]="'argus.policy.downloadTooltip' | translate">
    <cog-icon shape="helix:download"></cog-icon>
  </button>
  <mat-menu #downloadMenu xPosition="before">
    <button mat-menu-item
      *ngFor="let item of downloadOptions"
      (click)="downloadReports(item.value)">
      {{ item.text | translate }}
    </button>
  </mat-menu>
</ng-template>
