import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { DlpApiService, Policy } from '@cohesity/api/argus';
import { SnackBarService } from '@cohesity/helix';
import { AjaxHandlerService, AutoDestroyable } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import { finalize, tap } from 'rxjs/operators';

/**
 * Argus policy modify dialog components data inputs.
 */
export interface PolicyModifyInput {
  policy: Policy;
}

/**
 * Data classification create/edit policy resource page component.
 */
@Component({
  selector: 'dg-dc-policy-modify-dialog',
  templateUrl: './policy-modify-dialog.component.html',
  styleUrls: ['./policy-modify-dialog.component.scss'],
})
export class PolicyModifyDialogComponent extends AutoDestroyable {
  /**
   * Indicates whether we are loading data.
   */
  isLoading = false;

  /**
   * Indicates whether we are submitting the data or not.
   */
  isSubmitting = false;

  /**
   * To validate controls when form is invalid
   */
  isTouched = false;

  /**
   * The provided policy.
   */
  get policy(): Policy {
    return this.data.policy;
  }

  /**
   * The policy form control.
   */
  policyCtrl = new UntypedFormControl();

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: PolicyModifyInput,
    private ajaxHandler: AjaxHandlerService,
    private cdr: ChangeDetectorRef,
    private dialogRef: MatDialogRef<PolicyModifyDialogComponent, Policy>,
    private dlpApiService: DlpApiService,
    private snackBarService: SnackBarService,
    private translate: TranslateService,
  ) {
    super();
    this.policyCtrl.setValue(this.policy);
  }

  /**
   * Create/Modify Policy
   */
  modifyPolicy() {
    if (this.policyCtrl.invalid) {
      this.isTouched = true;
      return;
    }

    const policy = this.policyCtrl.value;
    const methodName = policy.id ? 'updatePolicy' : 'createPolicy';
    const param = {
      id: policy.id,
      body: policy
    };
    this.isSubmitting = true;
    this.cdr.detectChanges();

    let newPolicy: Policy;

    this.dlpApiService[methodName](param)
      .pipe(
        finalize(() => {
          this.isSubmitting = false;
          this.cdr.detectChanges();
        }),
        tap(value => newPolicy = value),
      )
      .subscribe({
        complete: () => {
          this.snackBarService.open(
            this.translate.instant(param.id ? 'policyUpdatedSuccessfully' : 'policyCreatedSuccessfully'),
            'success');
          this.goBack(newPolicy);
        },
        error: this.ajaxHandler.handler
      });
  }

  /**
   * Closes the dialog with newly created/updated policy as a result.
   *
   * @param policy newly created/updated policy
   */
  goBack = (policy: Policy) => {
    this.dialogRef.close(policy);
  };
}
