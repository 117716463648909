import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Api } from '@cohesity/api/private';
import { ClustersServiceApi, InterfaceServiceApi } from '@cohesity/api/v1';
import {
  ChassisList,
  ClusterInterfaces,
  ClusterOperationStatus,
  NetworkInterface,
  PlatformServiceApi,
  Racks,
  RegisteredRemoteStorageList,
  RemoteStorageServiceApi
} from '@cohesity/api/v2';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { executeWithFallback } from '@cohesity/utils';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { DiskV1 } from 'src/app/models';
import { HardwareNode } from 'src/app/modules/cluster/hardware/models/hardware-nodes.model';

/**
 * Parameters for GetChassis
 */
export interface GetChassisParams {
  /**
   * Filters chassis that have no rack assigned.
   */
  noRackAssigned?: boolean;
}

/**
 * Interface for grouping disks by a key
 */
interface DisksGroupedBy {
  [key: string]: DiskIdsGrouped;
}

/**
 * Grouped disks in lists depending on their status
 */
interface DiskIdsGrouped {
  /**
   * List of disk ids that were identified for removal
   */
  autoRemoved?: number[];

  /**
   * List of disk ids that are online
   */
  online?: number[];

  /**
   * List of disk ids that are marked for removal
   */
  removing?: number[];
}

/**
 * List of generic product used to display or not node data
 */
export const GENERIC_MODELS = [
  'Compute Node',
  'General Storage Node',
  'Dense Storage Node',
  'All Flash Node',
  'ROBO Node'
];

/**
 * This service provides functions to call get/update api's related to cluster platform.
 */
@Injectable({
  providedIn: 'root',
})
export class PlatformService {
  constructor(
    private clustersServiceApi: ClustersServiceApi,
    private http: HttpClient,
    private interfaceService: InterfaceServiceApi,
    private irisContext: IrisContextService,
    private platformServiceApi: PlatformServiceApi,
    private remoteStorageServiceApi: RemoteStorageServiceApi,
  ) {}

  /**
   * Local cache of list of registered remote storages.
   */
  private registeredRemoteStorages$ = new BehaviorSubject<RegisteredRemoteStorageList>(undefined);

  /**
   * Cache of all the ids of the disks marked for removal and identified for auto removal grouped by nodeId
   */
  disksGroupedByNodeId: DisksGroupedBy;

  /**
   * Get list of chassis in cluster.
   *
   * @param GetChassisParams Get chassis params.
   * @returns    ChassisList observable.
   */
  getChassis(params: GetChassisParams): Observable<ChassisList> {
    return this.platformServiceApi.GetChassis(params);
  }

  /**
   * Get list of racks in cluster.
   *
   * @returns    Observable of list of racks.
   */
  getRacks(): Observable<Racks> {
    return this.platformServiceApi.GetRacks();
  }

  /**
   * Get list of nodes in cluster.
   *
   * @returns    Observable of list of nodes.
   */
  getNodes(includeMarkedForRemoval: boolean, ids?: number[]): Observable<HardwareNode[]> {
    return this.platformServiceApi.GetNodes({
      includeMarkedForRemoval: includeMarkedForRemoval,
      ids: ids,
    }) as Observable<HardwareNode[]>;
  }

  /**
   * Call api to remove node node from cluster and return empty response on success.
   *
   * @param nodeId Id of the node to be removed.
   * @returns    Empty observable.
   */
  removeNode(nodeId: number): Observable<null> {
    return this.clustersServiceApi.RemoveNode({ id: nodeId });
  }

  /**
   * Get list of registered remote storages.
   *
   * @param     forceQuery If true, makes http request to get remote storages else, returns local value.
   * @returns   Observable of RegisteredRemoteStorageList.
   */
  getRegisteredRemoteStorages(forceQuery: boolean = false): Observable<RegisteredRemoteStorageList> {
    if (forceQuery || !this.registeredRemoteStorages$.value) {
      return this.remoteStorageServiceApi
        .GetRegisteredRemoteStorageList()
        .pipe(tap(remoteStorages => this.registeredRemoteStorages$.next(remoteStorages)));
    } else {
      return of(this.registeredRemoteStorages$.value);
    }
  }

  /**
   * Get list of all cluster disks
   *
   * @returns   Observable of DiskV1List.
   */
  getAllClusterDisks(): Observable<DiskV1[]> {
    const params = { includeMarkedForRemoval: true };
    this.disksGroupedByNodeId = {};
    return this.http.get<DiskV1[]>(Api.private('disks'), { params }).pipe(
      tap(disks => {
        disks.forEach((disk: DiskV1) => {
          switch (disk.removalState) {
            case 'kDontRemove':
              this.addDiskId(disk.currentNodeId, disk.id, 'online');
              break;
            case 'kMarkedForRemoval':
              this.addDiskId(disk.currentNodeId, disk.id, 'removing');
              break;
            case 'kOkToRemove':
              this.addDiskId(disk.currentNodeId, disk.id, 'autoRemoved');
              break;
            default:
              break;
          }
        });
      })
    );
  }

  /**
   * Check if node is a generic product type
   *
   * @param  node is type of HardwareNode which contains productModel variable used to validate.
   * @returns   boolean checking if productModel is in GENERIC_MODELS.
   */
  isGenericProductNode(node: HardwareNode): boolean {
    return GENERIC_MODELS.includes(node?.productModel);
  }

  /**
   * Add disk id in the appropriate array for a node id
   *
   * @param  nodeId the id of the node.
   * @param  diskId the id of the disk.
   * @param  field the name of the array the disk needs to be added to.
   */
  private addDiskId(nodeId: string, diskId: number, field: string) {
    if (!this.disksGroupedByNodeId[nodeId]) {
      this.disksGroupedByNodeId[nodeId] = {};
    }
    if (!this.disksGroupedByNodeId[nodeId][field]) {
      this.disksGroupedByNodeId[nodeId][field] = [];
    }
    this.disksGroupedByNodeId[nodeId][field].push(diskId);
  }

  /**
   * Fetches the status of node addition operation from the platform service API.
   *
   * @returns An observable that emits the cluster operation status for node addition.
   */
  fetchNodeAdditionStatus(): Observable<ClusterOperationStatus> {
    return this.platformServiceApi
      .GetClusterOperationStatusList({
        operationTypes: ['NodeAddition'],
      })
      .pipe(
        map(list =>
          list.find(op => op.operationType === 'NodeAddition' && (op.status === 'InProgress' || op.status === 'Failed'))
        )
      );
  }

  /**
   * Get list of interfaces of a node.
   */
  getInterfaces(
    params?: Parameters<PlatformServiceApi['GetNetworkInterfaces']>[number]
  ): Observable<ClusterInterfaces> {
    const shouldUseV2 = flagEnabled(this.irisContext.irisContext, 'platformBucket1v2ApiMigration');

    // Check if the newly added ip field exists on the all nodes in the response.
    const evaluateFn = (response: ClusterInterfaces) => !response.nodes?.every(node => node.ip);

    // Helper function to fetch interfaces using V1 API.
    const fetchInterfaceV1 = (): Observable<ClusterInterfaces> =>
      this.interfaceService.ListInterface(params).pipe(
        map(result => {
          // Adding transformer logic here for ease of cleanup during V1 API deprecation.
          // This approach allows us to remove the entire function easily when no longer needed.
          const bondingModeMap: Record<number, NetworkInterface['bondingMode']> = {
            1: 'ActiveBackup',
            4: '802_3ad',
            6: 'BalanceAlb',
            10: 'Invalid',
          };

          const interfaceTypeMap: Record<number, NetworkInterface['type']> = {
            1: 'Physical',
            2: 'Bond',
            3: 'Bridge',
            4: 'Group',
            5: 'Vlan',
            6: 'VlanPhysical',
            7: 'VlanBond',
            8: 'VlanGroup',
            9: 'VlanBridge',
            10: 'Invalid',
          };

          return {
            nodes: result.map(node => ({
              id: node.nodeId,
              ip: node.nodeIp,
              chassisSerial: node.chassisSerial,
              slotNumber: node.slot,
              interfaces: node?.interfaces.map(iface => ({
                name: iface.name,
                type: interfaceTypeMap[iface.type],
                staticIP: iface.staticIp,
                virtualIP: null,
                gateway: iface.gateway,
                mtu: iface.mtu,
                subnet: iface.subnet,
                isUp: iface.isUp,
                services: iface.services,
                group: iface?.group,
                role: iface.role === 'primary' ? 'Primary' : 'Secondary',
                defaultRoute: iface.isDefaultRoute,
                bondSlaveNames: iface.bondSlaves,
                bondSlaveSlots: [],
                bondSlavesDetails: null,
                bondingMode: bondingModeMap[iface.bondingMode],
                macAddress: iface.macAddress,
                isConnected: iface.isConnected,
                speed: iface.speed as NetworkInterface['speed'],
                activeBondSlave: null,
              })) ?? [],
            })),
          };
        })
      );

    // Helper function to fetch interfaces using V2 API.
    const fetchInterfaceV2 = (): Observable<ClusterInterfaces> =>
      this.platformServiceApi.GetNetworkInterfaces(params);

    // Determine which fetch method to use with fallback handling
    return shouldUseV2
      ? executeWithFallback<ClusterInterfaces>(fetchInterfaceV2, fetchInterfaceV1, evaluateFn)
      : fetchInterfaceV1();
  }
}
