/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityV2ApiConfiguration as __Configuration } from '../cohesity-v2api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { RelaunchSystemAppRequest } from '../models/relaunch-system-app-request';
import { RelaunchAppInstanceRequest } from '../models/relaunch-app-instance-request';
import { PerformServiceActionParameters } from '../models/perform-service-action-parameters';
import { ImagesStatus } from '../models/images-status';
@Injectable({
  providedIn: 'root',
})
class AthenaAppOpsService extends __BaseService {
  static readonly RelaunchSystemAppPath = '/apps/{id}/relaunch';
  static readonly RelaunchAppInstancePath = '/apps/instances/{id}/relaunch';
  static readonly PerformServiceActionPath = '/apps/{appUid}/images/action';
  static readonly GetImagesStatusPath = '/apps/{appUid}/images/status';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Relaunch System App
   *
   * Relaunch (Pause & Resume) a system app
   * @param id UID of the system app to be relaunched
   * @param body Specifies various conditions to check
   */
  RelaunchSystemAppResponse(id: number,
    body: RelaunchSystemAppRequest): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/apps/${encodeURIComponent(id)}/relaunch`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Relaunch System App
   *
   * Relaunch (Pause & Resume) a system app
   * @param id UID of the system app to be relaunched
   * @param body Specifies various conditions to check
   */
  RelaunchSystemApp(id: number,
    body: RelaunchSystemAppRequest): __Observable<null> {
    return this.RelaunchSystemAppResponse(id, body).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Relaunch app instance
   *
   * Relaunch (Pause & Resume) an app instance
   * @param id ID of the app instance to be relaunched
   * @param body Specifies various conditions to check
   */
  RelaunchAppInstanceResponse(id: number,
    body: RelaunchAppInstanceRequest): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/apps/instances/${encodeURIComponent(id)}/relaunch`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Relaunch app instance
   *
   * Relaunch (Pause & Resume) an app instance
   * @param id ID of the app instance to be relaunched
   * @param body Specifies various conditions to check
   */
  RelaunchAppInstance(id: number,
    body: RelaunchAppInstanceRequest): __Observable<null> {
    return this.RelaunchAppInstanceResponse(id, body).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Enables or disables a service.
   * @param params The `AthenaAppOpsService.PerformServiceActionParams` containing the following parameters:
   *
   * - `body`: Request to update app instance settings.
   *
   * - `appUid`: Specifies the app Id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  PerformServiceActionResponse(params: AthenaAppOpsService.PerformServiceActionParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;

    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/apps/${encodeURIComponent(params.appUid)}/images/action`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Enables or disables a service.
   * @param params The `AthenaAppOpsService.PerformServiceActionParams` containing the following parameters:
   *
   * - `body`: Request to update app instance settings.
   *
   * - `appUid`: Specifies the app Id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  PerformServiceAction(params: AthenaAppOpsService.PerformServiceActionParams): __Observable<null> {
    return this.PerformServiceActionResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Api to get images information.
   * @param appUid Specifies the app Id.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return GetImagesStatusResponse specifies response for getting image status
   */
  GetImagesStatusResponse(appUid: number,
    accessClusterId?: number): __Observable<__StrictHttpResponse<ImagesStatus>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apps/${encodeURIComponent(appUid)}/images/status`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ImagesStatus>;
      })
    );
  }
  /**
   * Api to get images information.
   * @param appUid Specifies the app Id.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return GetImagesStatusResponse specifies response for getting image status
   */
  GetImagesStatus(appUid: number,
    accessClusterId?: number): __Observable<ImagesStatus> {
    return this.GetImagesStatusResponse(appUid, accessClusterId).pipe(
      __map(_r => _r.body as ImagesStatus)
    );
  }
}

module AthenaAppOpsService {

  /**
   * Parameters for PerformServiceAction
   */
  export interface PerformServiceActionParams {

    /**
     * Request to update app instance settings.
     */
    body: PerformServiceActionParameters;

    /**
     * Specifies the app Id.
     */
    appUid: number;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { AthenaAppOpsService }
