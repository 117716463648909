<coh-source-special-parameters [form]="form" [nodeContext]="nodeContext"
  (dialogOpened)="updateForm()"
  (saved)="onSaved()">
  <div class="margin-bottom" *ngIf="missingVolumeGuids.length">
    <cog-status-indicator type="warning" [label]="'warning' | translate" size="sm"></cog-status-indicator>
      <p class="margin-left-lg">{{'serverOptions.missingVolumes.text' | translate}}</p>
      <ul class="c-ul margin-top-sm margin-left-lg">
        <li *ngFor="let volumneGuid of missingVolumeGuids">{{ volumneGuid }}</li>
      </ul>
  </div>
   <ng-container *ngIf="form" [formGroup]="form">
    <mat-slide-toggle formControlName="enableSystemBackup"
     *ngIf="!isWindowsFileServer"
      cogDataId="bmr-enable-toggle">
      {{'takeBMRBackup' | translate}}
    </mat-slide-toggle>
    <div class="margin-top">
      <mat-slide-toggle formControlName="protectAllVolumes"
        cogDataId="protect-all-volume-toggle">
        {{'serverOptions.automaticallyProtect' | translate}}
      </mat-slide-toggle>
    </div>
    <div *ngIf="!form.value.protectAllVolumes" formArrayName="volumes" cogDataId="volume-options"
      class="margin-bottom">
      <div *ngFor="let volume of form.get('volumes').controls; let i = index "
          formGroupName="{{i}}" class="margin-top">
          <mat-checkbox
            class="margin-left" formControlName="selected" [cogDataId]="'volume-' + i" [inheritDataId]="true"
            [matTooltip]="!!volume.controls.guid.value ? '' : ('serverOptions.nonLvmVolumesWarning' | translate)">
            <div>
                <span *ngIf="volume.controls.label !== undefined">{{volume.controls.label.value}}</span>
                <span *ngIf="volume.controls.label === undefined">{{'volume' | translate}}</span>
              <span>
                  (
                    {{volume.controls.mountPoints &&
                      volume.controls.mountPoints.value &&
                        volume.controls.mountPoints.value[0] ||
                      ('serverOptions.noMountPoint' | translate)}}
                  )
              </span>
              <span class="volume-metadata">
                {{volume.controls.logicalSizeBytes.value | byteSize}}
              </span>
            </div>
          </mat-checkbox>
      </div>
    </div>
    <div *ngIf="isExcludeVSSWritersSupported" class="margin-top">
      <div class="flex-row">
        <mat-slide-toggle formControlName="enabledExcludeVSSWriters" cogDataId="exclude-writers-toggle" >
          {{'excludeVolumeShadowCopyServiceWriters' | translate}}
        </mat-slide-toggle>
        <cog-icon shape="info!outline" class="margin-left-xs"
          [matTooltip]="'vssWritersHostLevelTooltip' | translate" >
        </cog-icon>
      </div>
      <div *ngIf="form.value.enabledExcludeVSSWriters" class="row margin-top-sm">
        <mat-form-field class="col-xs-12">
          <mat-label>{{'exclude' | translate}}</mat-label>
          <mat-chip-list #chipList>
            <mat-chip *ngFor="let vssWriter of form.value.excludedVssWriters; let index = index;" [cogDataId]="vssWriter"
              [inheritDataId]="true" (removed)="removeVssWriter(index)">
              {{vssWriter}}
              <cog-icon shape="cancel" matChipRemove></cog-icon>
            </mat-chip>

            <input #vssWriterInput [placeholder]="'addNew' | translate" [matChipInputFor]="chipList"
              [matChipInputAddOnBlur]="true" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              cogDataId="vss-writer-exclude-input" [matAutocomplete]="auto"
              (matChipInputTokenEnd)="vssWriterInputChange($event)">

            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectExcludeVssWriter($event)">
              <mat-option *ngFor="let vssWriter of getSupportedVSSWriters()" [cogDataId]="'option-' + vssWriter"
                [inheritDataId]="true" [value]="vssWriter">
                {{vssWriter}}
              </mat-option>
            </mat-autocomplete>
          </mat-chip-list>
        </mat-form-field>
      </div>
    </div>
  </ng-container>
</coh-source-special-parameters>
