/**
 * Enum for recover type options for recovery details.
 */
export enum RecoveryTypeOptions {
  /**
   * This option will let selected VM(s) be usable instantly in the target environment and will be moved to target
   * storage later.
   */
  InstantRecovery,

  /**
   * This option will let selected VM(s) be usable instantly in the target environment only after all the data has been
   * copied over from Cohesity to the target storage.
   */
  CopyRecovery,
}

/**
 * Enum for recover type options for recovery details.
 */
export enum RecoveryProcessTypeOptions {
  /**
   * This option will let selected VM(s) be usable instantly in the target environment and will be moved to target
   * storage later.
   */
  instantRecovery = 'InstantRecovery',

  /**
   * This option will let selected VM(s) be usable instantly in the target environment only after all the data has been
   * copied over from Cohesity to the target storage.
   */
  copyRecovery = 'CopyRecovery'
}
