
<h2 class="auto-truncate" mat-dialog-title>
  {{ title }}
</h2>
<form [formGroup]="dateTimeForm" (ngSubmit)="onSubmit()" class="border-top">
  <cog-banner status="info">
    {{ infoMessage }}
  </cog-banner>

  <mat-dialog-content>
    <div class="row margin-top">
      <div class="col-xs-5">
        <mat-form-field appearance="fill">
          <mat-label>Date</mat-label>
          <input matInput [matDatepicker]="picker"
            [readonly]="(loading$ | async) || isSelectSnapshotMode"
            formControlName="date" />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col-xs-4">
        <mat-form-field appearance="fill">
          <mat-label>End Time</mat-label>
          <input matInput type="time"
            formControlName="time"
            [readonly]="(loading$ | async) || isSelectSnapshotMode"/>
        </mat-form-field>
      </div>
      <div *ngIf="isSelectSnapshotMode"
        class="col-xs-3 margin-top">
        <button type="button"
          mat-icon-button aria-label="Edit"
          (click)="switchToEditMode()">
          <mat-icon class="material-icons-outlined">edit</mat-icon>
        </button>
      </div>
    </div>

    <div class="row" [@openClose]="isSelectSnapshotMode ? 'open' : 'close'">
      <div class="col-xs-12">
        <h3>{{ 'snapshotTime' | translate }}</h3>
        <mat-radio-group
          class="margin-bottom-lg"
          formControlName="selectedSnapshot">
          <mat-radio-button *ngFor="let snapshot of ((snapshots$ | async) || []); first as isFirst"
            cogDataId="item-type-{{snapshot.snapshotTimestampUsecs}}"
            [inheritDataId]="true"
            class="row col-xs-12"
            [value]="snapshot">
            <span class="flex-row">
              {{ snapshot.snapshotTimestampUsecs | cohDate }}
              <mat-chip *ngIf="isFirst" class="margin-left-xs">{{ 'latest' | translate }}</mat-chip>
            </span>
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end" class="border-top">
    <button mat-button cogDataId="cancel-snapshot-selection" mat-dialog-close>
      {{ 'cancel' | translate }}
    </button>

    <button type="submit"
      [disabled]="dateTimeForm.invalid"
      mat-raised-button color="primary"
      cogSubmitButton
      cogDataId="submit-snapshot-selection"
      [disabled]="(loading$ | async)"
      [loading]="loading$ | async">
      {{ submitBtnText | translate }}
    </button>
  </mat-dialog-actions>
</form>