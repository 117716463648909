import { Injectable } from '@angular/core';
import { PaginationParameters, ProtectionSourceNode, ProtectionSourcesServiceApi } from '@cohesity/api/v1';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { Environment } from '@cohesity/iris-shared-constants';
import { SourceTreeDataProvider } from '@cohesity/iris-source-tree';
import { BehaviorSubject, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { PassthroughOptionsService } from 'src/app/core/services';
import { AzureBackupType } from 'src/app/shared';

@Injectable()
export class AzureSourceDataProvider implements SourceTreeDataProvider<ProtectionSourceNode, AzureBackupType> {

  /**
   * Indicates whether the lazy loading feature flag is enabled.
   */
  private lazyLoadEnabled = flagEnabled(this.irisContext.irisContext, 'dmsAzureSourceLazyLoadingEnabled');

  /**
   * Page size to use for loading the source tree. We are still eagerly loading
   * the entire tree, so the page size should be as high as we can get away
   * with in order to avoid too many api calls.
   * This value may be larger than the max size allowed by iris_exec API,
   * FLAGS_MaxEntityHierarchyPageSize, if so, the iris_exec API flag will be
   * used. Pagination will still work as expected if there is a mismatch.
   *
   * NOTE: There is a size limit of 4MB for passthrough calls from ControlPlane
   * to DataPlane. In such cases, the EH call for 50k entities may fail to
   * load. For such scenarios FLAGS_MaxEntityHierarchyPageSize within iris_exec
   * should be tuned.
   */
  private pageSize = 50000;

  /**
   * For lazy loading, this will return each page as we receive it so the UI can display.
   */
  private sourceTree$: BehaviorSubject<ProtectionSourceNode[]>;

  /**
   * Behavior Subject to store the boolean status whether children entities
   * are being fetched.
   */
  private loadingChildrenSubject = new BehaviorSubject(false);

  /**
   * Get value of loadingChildrenSubject
   */
  isLoadingChildren(): boolean {
    return this.loadingChildrenSubject?.getValue();
  }

  constructor(
    readonly sourceService: ProtectionSourcesServiceApi,
    readonly irisContext: IrisContextService,
    readonly passthroughOptionsService: PassthroughOptionsService
  ) {}

  fetchTree(
    sourceId: number, environment: Environment,
    workloadType?: AzureBackupType, allUnderHierarchy?: boolean): Observable<ProtectionSourceNode[]> {
    const excludeTypes: any = ['kResourcePool'];
    const excludeAzureTypes = [];

    if (flagEnabled(this.irisContext.irisContext, 'dmsAzureEntraIdWorkload') &&
      workloadType !== 'kAzureEntraID') {
      excludeAzureTypes.push('kEntraID');
    }

    // Determine node type to be excluded based on current workload context
    switch (workloadType) {
      case 'kSQLDatabase':
        excludeAzureTypes.push('kVirtualMachine');
        break;
      case 'kVirtualMachine':
        excludeAzureTypes.push(...['kSQLDatabase', 'kSQLServer', 'kSQLManagedInstance']);
        break;
      case 'kAzureEntraID':
        excludeAzureTypes.push(...['kSQLDatabase', 'kSQLServer', 'kSQLManagedInstance', 'kVirtualMachine']);
        break;
    }

    const params: ProtectionSourcesServiceApi.ListProtectionSourcesParams = {
      id: sourceId,
      allUnderHierarchy: allUnderHierarchy || false,
      environment,
      excludeTypes,
      excludeAzureTypes,
      includeEntityPermissionInfo: true,
      includeVMFolders: true,
      includeSystemVApps: true,
      ...this.passthroughOptionsService.requestParams,
    };

    if (this.lazyLoadEnabled) {
      return this.lazyLoadTree(params);
    }

    return this.sourceService.ListProtectionSources(params);
  }

  /**
   * Function to fetch the source tree lazily. Fetches the first level of the tree and then
   * fetches the rest of the tree on demand.
   *
   * @param params The params to be passed to the API.
   * @returns An observable with the entire tree data, lazily loaded.
   */
  lazyLoadTree(params: ProtectionSourcesServiceApi.ListProtectionSourcesParams): Observable<ProtectionSourceNode[]> {
    // Reset the sourceTree$ as it gets closed after loading the full tree and
    // this file loads only once. So to Lazy Load the tree again, we need a new
    // instance.
    this.sourceTree$ = new BehaviorSubject<ProtectionSourceNode[]>([]);

    // Adds `numLevels` to the params to fetch only the first level of the tree.
    return this.sourceService.ListProtectionSources({ ...params, numLevels: 1 })
      .pipe(
        switchMap(([rootNode]) => {
          // This would fetch all the children and internally update the source tree subject.
          this.loadAllChildren(rootNode).subscribe();
          return this.sourceTree$.asObservable();
        }),
      );
  }

  /**
   * Recursively loads all of a node's children in chunks until there are none left.
   * Updates the source tree subject with the new tree.
   *
   * @param rootNode            The container node to load children for
   * @param allUnderHierarchy   Whether to include all objects all nodes a user has access to
   * @param pageInfo            Pagination info from the previus load
   * @param loadedChildren      Already loaded children
   * @returns All of a node's direct children, using paginated apis
   */
  loadAllChildren(
    rootNode: ProtectionSourceNode,
    allUnderHierarchy = true,
    pageInfo: PaginationParameters = null,
    loadedChildren: ProtectionSourceNode[] = []
  ): Observable<ProtectionSourceNode[]> {
    return this.sourceService
      .ListProtectionSources({
        id: rootNode.protectionSource.id,
        nodeId: rootNode.protectionSource.id,
        allUnderHierarchy: allUnderHierarchy,
        pageSize: this.pageSize,
        afterCursorEntityId: pageInfo?.beforeCursorEntityId,
        pruneNonCriticalInfo: flagEnabled(this.irisContext.irisContext, 'pruneNonCriticalInfo'),
        ...this.passthroughOptionsService.requestParams,
      })
      .pipe(
        switchMap(([rootNodeWithNewChildren]) => {
          const { nodes: newChidren, entityPaginationParameters } = rootNodeWithNewChildren;
          const childNodes = loadedChildren.concat(newChidren ?? []);

          let loadMore = !!entityPaginationParameters?.beforeCursorEntityId && !!newChidren;

          // Due to ENG-347413, magneto returns beforeCursorEntityId even if
          // the last page is being returned. An extra API call is triggerred
          // since client doesn't know if it has to stop fetching more. The
          // extra API call returns only the last entity and has same after &
          // before cursor.
          if (entityPaginationParameters?.beforeCursorEntityId === entityPaginationParameters?.afterCursorEntityId) {
            loadMore = false;
          }

          // Due to ENG-347413, remove the last entity from the children array
          // since magneto will return the same in the next API call as the
          // first entity.
          if (loadMore) {
            childNodes.pop();
          }

          // Update the loader.
          this.loadingChildrenSubject.next(loadMore);

          // Update the source tree with latest tree.
          this.sourceTree$.next([{
            ...rootNode,
            nodes: childNodes,
          }]);

          // If we need to load more children, recursively load them. Else
          // update the container node with the children.
          if (loadMore) {
            return this.loadAllChildren(
              rootNode,
              allUnderHierarchy,
              entityPaginationParameters,
              childNodes,
            );
          }

          // Emit the sourceTree$ completion event to close the stream after
          // loading all the children.
          this.sourceTree$.complete();

          return this.sourceTree$;
        }),
      );
  }
}
