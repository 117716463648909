import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Api, McmConfig } from '@cohesity/api/private';
import { IrisContextService, isTenantUser } from '@cohesity/iris-core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';

/**
 * Model for cluster registration API request payload
 */
export interface ClusterRegistrationDeletionPayload {
  clusterIdentifier: number;
  clusterIncarnation: number;
}

/**
 * This service provides information related to cluster connectivity Helios
 * Note: Following methods have been ported from AJS HeliosService
 * getHeliosConfiguration
 * updateHeliosConfiguration
 * unregisterClusterFromHelios
 */
@Injectable({
  providedIn: 'root',
})
export class HeliosConnectivityService {
  /**
   * Observable of config object.
   */
  private _config$ = new BehaviorSubject<McmConfig>(undefined);

  /**
   * Expose _config$ as an observable.
   */
  readonly config$ = this._config$.asObservable();

  constructor(
    private irisContextService: IrisContextService,
    private http: HttpClient,
    private translate: TranslateService
  ) {}

  /**
   * Retrieves the current Helios Configuration
   *
   * @return   Returns the observable of helios config
   */
  getHeliosConfiguration(): Observable<McmConfig> {
    if (isTenantUser(this.irisContextService.irisContext)) {
      return throwError(this.translate.instant('configFetchError'));
    }

    // Note: Passing the clusterID helps unblock the loading the of smart license page in helios.
    // clusterId = 1 belongs to Helios. (backend sends dummy cluster information for Helios)
    const clusterId = this.irisContextService.irisContext?.selectedScope?.clusterId;
    const request =
      this.irisContextService.irisContext?.basicClusterInfo?.mcmMode && clusterId !== 1
        ? this.http.get(Api.private('mcm/config'), {
          headers: new HttpHeaders({
            clusterId: clusterId.toString(),
          }),
        })
        : this.http.get(Api.private('mcm/config'));

    return request.pipe(tap((config: McmConfig) => this._config$.next(config)));
  }

  /**
   * Updates the current Helios Configuration
   *
   * @param    config   Helios Configuration
   * @return   Returns the observable of helios config
   */
  updateHeliosConfiguration(config: Partial<McmConfig>): Observable<McmConfig> {
    return this.http
      .put(Api.private('mcm/config'), config)
      .pipe(tap((newConfig: McmConfig) => this._config$.next(newConfig)));
  }

  /**
   * Unregister cluster from Helios.
   *
   * @param    payload   The cluster params to be deleted.
   * @return   observable with successful unregistration or error.
   */
  unregisterClusterFromHelios(payload: ClusterRegistrationDeletionPayload): Observable<any> {
    return this.http.delete(Api.private('mcm/cluster/registration/helios'), { body: payload });
  }
}
