import { Injectable } from '@angular/core';
import {
  ActiveDirectoryServiceApi as ActiveDirectoryServiceApiV1,
} from '@cohesity/api/v1';
import {
  ActiveDirectories,
  ActiveDirectory,
  ActiveDirectoryServiceApi as ActiveDirectoryServiceApiV2,
  CentrifyZones,
  DomainController,
  FallbackUserIdMappingParams,
  IdMappingParams,
  Ldap,
  LDAPServiceApi,
  TrustedDomainParams
} from '@cohesity/api/v2';
import { IrisContextService, flagEnabled, isEntityOwner } from '@cohesity/iris-core';
import { pruneObject } from '@cohesity/utils';
import { uniq } from 'lodash-es';
import { Observable } from 'rxjs';
import { DialogService, UserService } from 'src/app/core/services';
import { AvailableDomainControllerStatus, DomainControllerStatusIconMap } from 'src/app/shared/constants';
import { DomainControllersDialogParams, MappingTypeParamNames, MappingTypesType } from './active-directory-models';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ActiveDirectoryService {
  constructor(
    private activeDirectoryServiceV1: ActiveDirectoryServiceApiV1,
    private activeDirectoryServiceV2: ActiveDirectoryServiceApiV2,
    private dialogService: DialogService,
    private irisCtx: IrisContextService,
    private ldapProviderService: LDAPServiceApi,
    private userService: UserService) {}

  /**
   * Update Active Dirctory
   *
   * @param   ad   The ad to be updated,
   * @param   updateMachineAccounts   Whether to update machine account or not.
   * @param   overwriteMachineAccounts   Whether to overwrite machine accounts or not.
   * @param   username   Username of current ad. If updateMachineAccounts is true, this must be provided.
   * @param   password   Password  of current ad. If updateMachineAccounts is true, this must be provided.
   * @return  Observable of updated active directory,
   */
  updateActiveDirectory (
    ad: ActiveDirectory,
    updateMachineAccounts?: boolean,
    overwriteMachineAccounts?: boolean,
    username?: string,
    password?: string): Observable<ActiveDirectory> {
    const param: ActiveDirectoryServiceApiV2.UpdateActiveDirectoryParams = {
      id: ad.id,
      body: {
        activeDirectoryAdminParams: updateMachineAccounts ? {
          username: username,
          password: password,
        } : null,
        overwriteMachineAccounts: overwriteMachineAccounts,
        idMappingParams: ad.idMappingParams as IdMappingParams,
        machineAccounts: ad.machineAccounts,
        preferredDomainControllers: ad.preferredDomainControllers,
        organizationalUnitName: ad.organizationalUnitName,
        workGroupName: ad.workGroupName,
        ldapProviderId: ad.ldapProviderId,
        nisProviderDomainName: ad.nisProviderDomainName,
        trustedDomainParams: ad.trustedDomainParams,
        domainControllersDenyList: ad.domainControllersDenyList,
      },
    };
    return this.activeDirectoryServiceV2.UpdateActiveDirectory(param);
  }

  /**
   * Returns a params object for the provided mapping type.
   *
   * @param   mappingType   A string mapping type.
   * @param   fallbackOption  A params object for the fallback option.
   * @param   userIdMappingFormValue  The form control value object.
   *
   * @return  params object for the provided mapping type.
   */
  getMappingTypeParams(
    mappingType: MappingTypesType,
    fallbackOption: FallbackUserIdMappingParams,
    userIdMappingFormValue: any) {

    switch (mappingType) {
      case MappingTypesType.Fixed:
        return {
          uid: userIdMappingFormValue.fixedUid,
          gid: userIdMappingFormValue.fixedGid,
        };

      case MappingTypesType.Centrify:
        return {
          description: userIdMappingFormValue.centrifyZone.description,
          distinguishedName: userIdMappingFormValue.centrifyZone.distinguishedName,
          schema: userIdMappingFormValue.centrifyZone.schema,
          fallbackOption: fallbackOption,
        };

      case MappingTypesType.CustomAttributes:
        return {
          gidAttrName: userIdMappingFormValue.customGid,
          uidAttrName: userIdMappingFormValue.customUid,
          fallbackOption: fallbackOption,
        };

      case MappingTypesType.Rfc2307:
        return {
          fallbackOption: fallbackOption,
        };

      case MappingTypesType.Sfu30:
        return {
          fallbackOption: fallbackOption,
        };

      case MappingTypesType.LdapProvider:
        return {
          fallbackOption: fallbackOption,
        };

      case MappingTypesType.NisProvider:
        return {
          fallbackOption: fallbackOption,
        };

      default:
        // Most common case is Rid. No additional params needed.
        return;
    }
  }

  /**
   * Service function to update user id mapping for active directory
   *
   * @param   domainName   The active directory name.
   * @param   userIdMapping  User ID Mapping config
   *
   * @return  Observable of updated active directory.
   */
  updateUserIdMapping(ad: ActiveDirectory, userIdMappingFormValue: any):
  Observable<ActiveDirectory> {
    const tempAd = {...ad, idMappingParams: null};

    if (userIdMappingFormValue) {
      const {
        fallbackMappingType,
        fallbackFixedGid,
        fallbackFixedUid,
        mappingType,
        unixRootSid,
      } = userIdMappingFormValue;

      const fallbackOption = {
        fixedTypeParams: {
          uid: fallbackFixedUid,
          gid: fallbackFixedGid,
        },
        type: fallbackMappingType,
      };

      const mappingTypeParamName = MappingTypeParamNames[mappingType];

      tempAd.idMappingParams = {
        sidMappedToUnixRootUser: unixRootSid,
        userIdMappingParams: {
          type: mappingType,
        },
      };
      tempAd.idMappingParams.userIdMappingParams[mappingTypeParamName] =
        this.getMappingTypeParams(mappingType, fallbackOption, userIdMappingFormValue);

      pruneObject(tempAd.idMappingParams);
    }

    return this.updateActiveDirectory(tempAd);
  }
  /**
   * Gets a list of LDAP Providers and populates a hash for lookup.
   *
   * @return  Observable of known LDAP providers.
   */
  getLdapProviderHash(): Observable<Ldap[]> {
    return this.ldapProviderService.GetLdaps({}).pipe(
      map((resp) => resp?.ldaps || [])
      );
  }
  /**
   * Gets a list of Centrify Zones for the provided domain.
   *
   * @return  List of Centrify Zones.
   * @deprecated
   */
  getCentrifyZones(domainName: string) {
    const params: ActiveDirectoryServiceApiV1.ListCentrifyZonesParams = {
      domainName,
    };
    return this.activeDirectoryServiceV1.ListCentrifyZones(params);
  }

  /**
   * Gets a list of Centrify Zones for the provided domain using V2 service.
   *
   * @param  domainName Specified domain.
   * @return            Observable of Centrify Zones.
   */
  getCentrifyZonesV2(domainName: string): Observable<CentrifyZones>{
    const params: ActiveDirectoryServiceApiV2.GetCentrifyZonesParams = {
      domainName,
    };
    return this.activeDirectoryServiceV2.GetCentrifyZones(params);
  }

  /**
   * Helper funciton to get matched ldap provider name.
   *
   * @param   ldapProviders   Ldap Provider list.
   * @param   ldapId   Selected ldap id.
   *
   * @return   Matched ldap provoder name.
   */
  getMatchedLdapProviderName(ldapProviders: Ldap[], ldapId: number): string {
    const matchedLdapProvider = (ldapProviders || []).find((ldap) => ldap.id === ldapId);
    return (matchedLdapProvider || { name: '' }).name;
  }
  /**
   * private functions to find matched domain controllers for current domain.
   *
   * @param   ad   The current AD object.
   * @param   name   The domain name of which domain's controller we are looking for.
   * @return  DomainController[]   Matched domain controllers.
   */
  getMatchedDomainControllers(ad: ActiveDirectory, name: string): DomainController[] {
    if (!ad.domainControllers) {
      return [];
    } else {
      const index = ad.domainControllers.findIndex(element => element.domainName === name);
      if (index > -1) {
        return ad.domainControllers[index].controllers;
      } else {
        return [];
      }
    }
  }

  /**
   * Get all Active Directories.
   * Note: This method was created for downgraded v1 access.
   *
   * @returns   Observable of all Active Directories.
   */
  getActiveDirectories(): Observable<ActiveDirectories> {
    return this.activeDirectoryServiceV2.GetActiveDirectory({});
  }

  /**
   * Returns whether an Active Directory can be modified.
   *
   * @param     ad   The Active Directory object.
   * @returns   `true` if the AD can be modified, `false` otherwise.
   */
  canModifyAd(ad: ActiveDirectory): boolean {
    // User can modify an AD if they have privilege AND
    return this.userService.privs.AD_LDAP_MODIFY &&

    // Either 1) The AD does not belong to an organization OR
    ((!ad.permissions || !ad.permissions[0]) ||

    // 2) The AD belongs to the logged-in user's organization.
    isEntityOwner(this.irisCtx.irisContext, ad.permissions[0].id));
  }

  /**
   * Opens the domain controller dialog
   *
   * @param dialogParams The dialog params
   * @returns Observable resolving after opening the dialog.
   */
  openDomainControllerDialog(dialogParams: DomainControllersDialogParams) {
    const component = flagEnabled(this.irisCtx.irisContext, 'domainControllerEnhancements') ?
      'manage-domain-controllers-dialog' : 'domain-controllers-dialog';

    return this.dialogService.showDialog(component as any, dialogParams);
  }


  /**
   * Fetch domain controllers for specified domain.
   *
   * @param   domainName   Specified domain.
   * @requires             Observable of domain controllers.
   */
  getDomainControllers(domainName: string) {
    const param: ActiveDirectoryServiceApiV2.GetDomainControllersParams = {
      domainNames: [domainName],
    };
    return this.activeDirectoryServiceV2.GetDomainControllers(param);
  }

  /**
   * Gets the domain controllers status & icon with the help of the status map.
   *
   * Defaults to 'Unreachable'
   *
   * @param domainController The domain controller.
   * @param statusMap The DC name -> Status map
   * @returns The status & icon of the DC.
   */
  getDomainControllerStatus(domainController: DomainController, statusMap: Map<string, DomainController['status']>):
    { icon: string; status: AvailableDomainControllerStatus } {
    const status = domainController.status || statusMap.get(domainController.name) || 'Unreachable';

    return {
      icon: DomainControllerStatusIconMap[status],
      status,
    };
  }

  /**
   * Compiles a string list of Active Directory domain names and their trusted
   * domains, given a list of Active Directory objects. If an Active Directory
   * name is provided to constrain the list, then only return that AD and its
   * trusted domains.
   *
   * @param domainList Source list to concat.
   * @param adList List of Active Directories.
   * @param constrainedAd Optional AD Domain to constrain results.
   * @returns String list of Trusted Domain names.
   */
  getPrimaryAndTrustedDomains(
    domainList: string[] = [],
    adList: ActiveDirectory[] = [],
    constrainedAd?: string
  ): string[] {
    const adMap = new Map<ActiveDirectory['domainName'], ActiveDirectory>();
    adList.forEach(ad => adMap.set(ad.domainName, ad));
    const activeDirectories = [...(constrainedAd ? [adMap.get(constrainedAd)] : adMap.values())];
    activeDirectories.forEach(activeDirectory => {
      domainList.push(activeDirectory.domainName);
      const trustedDomainParams = activeDirectory.trustedDomainParams as TrustedDomainParams;
      if (trustedDomainParams?.trustedDomains) {
        domainList.push(...trustedDomainParams.trustedDomains.map(td => td.domainName));
      }
    });
    return uniq(['LOCAL', ...domainList.filter(Boolean)]);
  }
}
