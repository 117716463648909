<div class="user-card"
  [ngClass]="{'ng-user': ngUserProfileEnabled}">
  <div class="user-details-container">
    <div class="user-avatar-container">
      <cog-icon shape="helix:user-alt"></cog-icon>
    </div>
    <div class="user-info-container">
      <div class="user-heading">
        {{user?.username}}
        <span *ngIf="showTenantId">{{user?.tenantId | tenantId: true | orgIdSplit}}</span>
      </div>
      <h5 class="user-subheading"
        *ngIf="user?.emailAddress && !ngUserProfileEnabled">
        {{user?.emailAddress}}
      </h5>
      <div class="user-meta" *ngIf="isHelios && roles?.length && user?.roles?.length">
        {{ 'roles' | label: rolesString(user.roles) }}
      </div>
      <div class="user-info-section"
        *ngIf="ngUserProfileEnabled">
          <div class="user-info">
            {{ 'lastLoggedInAt' | translate: { time: loginTime } }}
          </div>
          <div class="user-actions">
            <a *ngIf="!isHelios" (click)="openUserSettings()">
              {{'manageProfile' | translate}}
            </a>
          </div>
      </div>
      <div class="user-info-section"
        *ngIf="ngUserProfileEnabled">
        <div class="user-info">
          {{ 'loggedInAs' | translate: { name: tenant.name } }}
        </div>
        <div class="user-actions">
          <a (click)="openSwitchAccountModal()"
            *ngIf="!impersonationMode || privs.ORGANIZATION_IMPERSONATE || isImpersonated">
            {{(impersonationMode ? 'impersonate' : 'switchOrg') | translate}}
          </a>
          <a (click)="quitImpersonation()"
            *ngIf="impersonationMode && isImpersonated">
            {{'switchBack' | translate}}
          </a>
        </div>
      </div>
      <div class="user-info-section" *ngIf="salesforceAccount">
        <div class="user-info" *ngIf="salesforceAccount.caseId">
          {{ 'supportCaseId' | label: salesforceAccount.caseId }}
        </div>
        <div class="user-info">
          {{ 'impersonating' | translate: { name: salesforceAccount.name } }}
        </div>
      </div>
    </div>
  </div>
  <div class="user-card-footer"
    *ngIf="ngUserProfileEnabled">
    <a class="pull-right" (click)="logout()">
      {{'logout' | translate}}
    </a>
  </div>
  <mat-action-list
    *ngIf="!ngUserProfileEnabled">
    <button id="user-settings-button"
      mat-list-item
      *ngIf="!isHelios && !isImpersonated && isUserSettingsEnabled"
      (click)="openUserSettings()">
      {{'userSettings' | translate}}
    </button>
    <button cogDataId="user-preferences-button"
      mat-list-item
      *ngIf="uiCustomizationEnabled"
      uiSref="customization.list">
      {{'preferences' | translate}}
    </button>
    <ng-container *ngIf="!this.isHelios">
      <button id="impersonate-button"
        mat-list-item
        *ngIf="!impersonationMode || privs.ORGANIZATION_IMPERSONATE || isImpersonated"
        (click)="openSwitchAccountModal()">
        {{(impersonationMode ? 'impersonate' : 'switch') | translate}}
      </button>
      <button id="quit-impersonation-anchor"
        mat-list-item
        *ngIf="isImpersonated"
        (click)="quitImpersonation()">
        {{'switchBack' | translate}}
      </button>
    </ng-container>
    <button id="user-panel-switch-account-button"
      mat-list-item
      *ngIf="isSalesforceUser && canSwitchCompanyAccount"
      (click)="openSwitchSalesforceAccountModal()">
      {{'switchAccount' | translate}}
    </button>
    <button id="logout-user-button"
      mat-list-item
      (click)="logout()">
      {{'logout' | translate}}
    </button>
    <button *ngIf="showSelfServicePortalGoto"
      cogDataId="goto-self-service-portal"
      mat-list-item
      (click)="gotoSelfServicePortal()">
      {{'goToSelfServicePortal' | translate}}
    </button>
  </mat-action-list>
</div>
