/**
 * Enum for differential recovery.
 */
export enum HandlingExistingVmOptions {
  /**
   *  Do nothing to existing VM.
   */
  None,

  /**
   * Overwrite the Existing VM.
   */
  OverwriteExistingVm,

  /**
   * Keep the existing VM. Magneto will deal with the duplicated VM.
   */
  KeepExistingVm,
}
