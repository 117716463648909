import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import {
  IocIntegrationId,
  IocIntegrationStat,
  IocIntegrationSyncStatus,
  IoCsIntegrationsApiService,
} from '@cohesity/api/argus';
import { Integration } from '@cohesity/api/v2';
import { IntegrationName, TiVendor } from '@cohesity/shared/integrations';
import { AjaxHandlerService, AutoDestroyable } from '@cohesity/utils';
import { isEqual } from 'lodash-es';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'dg-td-select-ioc-integrations',
  templateUrl: './select-ioc-integrations.component.html',
  styleUrls: ['./select-ioc-integrations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectIocIntegrationsComponent extends AutoDestroyable implements OnChanges, OnInit {
  /**
   * Specifies the list of Ioc Integrations to be selected.
   */
  @Input() value: IocIntegrationId[] = [];

  /**
   * Emits changes to selected Integrations
   */
  @Output() selectedIntegrations = new EventEmitter<IocIntegrationId[]>();

  /**
   * List of available integrations
   */
  @Input() integrations: Integration[] = [];

  /**
   * Boolean flag to track api loading
   */
  @Input() disabled = false;

  /**
   * Boolean flag to track if the data is loading
   */
  loading = false;

  /**
   * Integration stats
   */
  integrationStats: IocIntegrationStat[] = [];

  /**
   * Form control to track integrations selection
   */
  readonly integrationsControl = new FormControl<IocIntegrationId[]>({ value: this.value, disabled: this.disabled });

  /**
   * Ioc integration sync status
   */
  readonly syncStatus = IocIntegrationSyncStatus;

  constructor(
    private ajaxHandler: AjaxHandlerService,
    private cdr: ChangeDetectorRef,
    private integrationStatsService: IoCsIntegrationsApiService,
    private router: Router
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value) {
      this.integrationsControl.patchValue(changes.value.currentValue);
    }

    if (changes.disabled && !isEqual(changes.disabled.currentValue, changes.disabled.previousValue)) {
      this.disabled ? this.integrationsControl.disable() : this.integrationsControl.enable();
    }
  }

  ngOnInit() {
    this.integrationsControl.valueChanges
      .pipe(this.untilDestroy())
      .subscribe(value => this.selectedIntegrations.emit(value));
    this.getIntegrationStats();
  }

  /**
   * removes an ioc integration from the selected list
   *
   * @param id ioc integration id
   */
  removeIntegration(id: IocIntegrationId) {
    const filteredInts = this.integrationsControl.value.filter(value => value !== id);
    this.integrationsControl.setValue(filteredInts);
  }

  /**
   * Returns the name of the Ioc integration with the given ID, or the ID if no matching integration is found.
   *
   * @param id - the ID of the Ioc integration
   * @returns the name of the Ioc integration, or the ID if no matching integration is found
   */
  getIntegrationName(id: IocIntegrationId): string {
    return this.getIntegration(id)?.integrationInfo?.tiInfo?.vendorName === TiVendor.CrowdStrike
      ? IntegrationName.CrowdStrikeFalconIntelligence
      : id;
  }

  canSelectIntegration(id: IocIntegrationId): boolean {
    if (this.loading) {
      return false;
    }
    const stat = this.getIntegrationStat(id);
    return stat?.syncStatus === IocIntegrationSyncStatus.Success;
  }

  /**
   * configure a new integration
   */
  configureIntegration() {
    this.router.navigate(['security/integrations/browse']);
  }

  getIntegrationStat(id: IocIntegrationId): IocIntegrationStat | undefined {
    const vendor = this.getIntegration(id)?.integrationInfo?.tiInfo?.vendorName;
    if (this.loading || !vendor) {
      return undefined;
    }
    return this.integrationStats.find(stat => stat.vendorName === vendor);
  }

  private getIntegration(id: IocIntegrationId): Integration | undefined {
    return this.integrations.find(int => int.integrationId === id);
  }

  private getIntegrationStats() {
    this.loading = true;
    this.cdr.detectChanges();

    this.integrationStatsService
      .getIocIntegrationsStats()
      .pipe(
        this.untilDestroy(),
        finalize(() => this.cdr.detectChanges())
      )
      .subscribe(stats => {
        this.loading = false;
        this.integrationStats = stats;
      }, this.ajaxHandler.handler);
  }
}
