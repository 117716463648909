<h2 mat-dialog-title class="flex-row vertical-align margin-bottom-lg">
  <span>{{ (policy ? 'editPolicy' : 'applyPolicy') | translate }}</span>
  <h4 *ngIf="!policy" class="flex-pull-right no-margin-top no-margin-bottom">
    <dg-stepper [selectedStep]="selectedStep" [steps]="steps"></dg-stepper>
  </h4>
</h2>

<mat-dialog-content>
  <ng-container *ngIf="!isLoading; else spinner" [ngSwitch]="selectedStep.displayName">
    <dg-dc-policy-list
      *ngSwitchCase="'policies'"
      [hideActions]="true"
      [policyList]="policyList"
      [selection]="selectedPolicies">
    </dg-dc-policy-list>

    <dg-dc-data-pool-list
      *ngSwitchCase="'dataPools'"
      [showDataSources]="true"
      [selection]="selectedDataPools"
      [canSelectDataPool]="canSelectDataPool"
      (initSelection)="initDataPoolSelection($event)">
    </dg-dc-data-pool-list>
  </ng-container>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <ng-container *ngIf="!policy; else editActions">
    <dg-stepper-actions
      [selectedStepIndex]="selectedStepIndex"
      [totalSteps]="steps.length"
      [isSubmitting]="isSubmitting"
      [isDisabled]="isDisabled"
      [saveLabel]="'applyPolicy' | translate"
      (cancel)="closeDialog()"
      (goBack)="navigate(-1)"
      (goNext)="navigate(+1)"
      (save)="applyPolicy()">
    </dg-stepper-actions>
  </ng-container>
</mat-dialog-actions>

<ng-template #spinner>
  <cog-spinner></cog-spinner>
</ng-template>

<ng-template #editActions>
  <button mat-button
    [disabled]="isSubmitting"
    (click)="closeDialog()">
    {{ 'cancel' | translate }}
  </button>

  <button mat-flat-button
    cdkFocusInitial
    cogSubmitButton
    cogDataId="save-btn"
    [disabled]="isSubmitting || isDisabled"
    [loading]="isSubmitting"
    (click)="applyPolicy()">
    {{ 'save' | translate }}
  </button>
</ng-template>
