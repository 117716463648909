import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ThreatId } from '@cohesity/api/argus';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { SelectUserDefinedThreatsDialogComponent } from './select-user-defined-threats-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class SelectUserDefinedThreatsDialogService {
  constructor(private dialog: MatDialog) { }

  /**
   * Launches Custom Threats Dialog to select rules
   *
   * @returns dialog observable
   */
  launch(threatIds: ThreatId[]): Observable<any> {
    const dialogRef = this.dialog.open(SelectUserDefinedThreatsDialogComponent, {
      data: threatIds,
      maxHeight: '90vh',
      minWidth: '90%'
    });
    return dialogRef.afterClosed().pipe(filter(Boolean));
  }
}
