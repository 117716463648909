<h2 mat-dialog-title>
  <ul class="flex-row c-ul-inline c-ul-dot-separator no-margin">
    <li class="no-padding-right">
      <span>
        {{ 'dg.td.scan.selectThreats' | translate }}
      </span>
    </li>
    <li>
      <span>
        {{ selectedThreatIds.length }}
        {{ 'dg.td.scan.selected' | translate }}
      </span>
    </li>
  </ul>
</h2>
<div class="rules-container">
  <dg-td-threat-list
    [enableSelection]="true"
    [select]="selectThreatIds"
    (selected)="selectionChanged($event)">
  </dg-td-threat-list>
</div>

<div mat-dialog-actions class="flex-row vertical-align">
  <div>
    <button
      *ngIf="hasModifyAccess"
      mat-stroked-button
      cogDataId="add-yara-rules"
      color="primary"
      (click)="openAddYaraRuleDialog()">
      {{ 'dg.td.addYaraRule' | translate }}
    </button>
  </div>
  <div class="flex-pull-right">
    <button mat-button cogCancelButton cogDataId="cancel-yara-rule-selection" (click)="cancel()">
      {{ 'cancel' | translate }}
    </button>
    <button mat-button cogSubmitButton cogDataId="submit-yara-rule-selection" (click)="save()">
      {{ 'save' | translate }}
    </button>
  </div>
</div>
