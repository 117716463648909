import { CdkVirtualScrollViewport, ScrollDispatcher } from '@angular/cdk/scrolling';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef, ViewChild, ViewEncapsulation, QueryList, ChangeDetectorRef } from '@angular/core';
import { ComparatorFn } from '../searchable-select/searchable-select.model';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyOption as MatOption } from '@angular/material/legacy-core';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { ClearSubscriptions } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import { get} from 'lodash-es';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';

/**
 * Component to show a select dropdown with passed values, attach the
 * passed form control and select an object.
 */
@Component({
  selector: 'coh-form-field-object-selector[control][label][values]',
  templateUrl: './form-field-object-selector.component.html',
  styleUrls: ['./form-field-object-selector.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FormFieldObjectSelectorComponent
  extends ClearSubscriptions implements OnInit, OnChanges {
  /**
   * MatSelect child component, available so implementations can auto-open as needed.
   */
  @ViewChild(MatSelect, { static: true }) matSelect: MatSelect;

  /**
   * List of all MatOption components in the view, used to manage and sync selection states.
   */
  @ViewChild(MatOption) options: QueryList<MatOption> = new QueryList<MatOption>();

  /**
   * Whether add new source button should be enabled.
   */
  @Input() addNewEnable = false;

  /**
   * Whether add new source button should be enabled.
   */
  @Input() addButtonLabel: string;

  /**
   * Event emitted to register the click of add button.
   */
  @Output() registerAddButtonClick = new EventEmitter<void>();

  /**
   * Form control for the generic selector.
   */
  @Input() control: UntypedFormControl;

  /**
   * Label to display for <mat-label>.
   */
  @Input() label: string;

  /**
   * Array of values for the dropdown.
   */
  @Input() values = [];

  /**
   * Key to determine the string value from an option.
   *
   * Either this or optionalTemplate and triggerTemplate are required. When
   * optionTemplate and triggerTemplate are used, optionKey should still be
   * specified for cogDataId value of <mat-option>. If no filteredResultsFn is
   * specified, optionKey is also used for searching.
   */
  @Input() optionKey: string;

  /**
   * Template to render the option in the select dropdown.
   *
   * This is used over optionKey when specified. When this is used, optionKey
   * should still be specified for cogDataId value of <mat-option> and
   * searching (if no filteredResultsFn).
   */
  @Input() optionTemplate: TemplateRef<any>;

  /**
   * Template to render additional info option in the select dropdown.
   *
   * This can be used to display additional information in various scenarios
   * Eg. Display a message indicating that results are limited in case of
   * elastic search API
   */
  @Input() additionalInfoTemplate: TemplateRef<any>;

  /**
   * Optional. Whether to allow searching.
   *
   * Only works with either optionKey or filteredResultsFn.
   */
  @Input() allowSearch = true;

  /**
   * Allow async search if true.
   */
  @Input() asyncSearch = false;

  /**
   * Event emitted to when user type something in search string.
   */
  @Output() search = new EventEmitter<string>();

  /**
   * Optional. Whether this component is in loading state.
   */
  @Input() loading = false;

  /**
   * Optional. Whether this component has a hint text (help text for the form field).
   */
  @Input() hint: string;

  /**
   * Optional. Number of max selected items allowed if multi select.
   */
  @Input() maxSelectedItems: number;

  /**
   * Optional. "multiple" for mat-select.
   */
  @Input() multiple = false;

  /**
   * Optional. Whether this component has a suffix icon.
   */
  @Input() suffixIcon: string;

  /**
   * Optional. Template to render the select trigger label, if not provided,
   * the optionalTemplate is used to render trigger label.
   */
  @Input() triggerTemplate: TemplateRef<any>;

  /**
   * Optional. Whether the field is required.
   */
  @Input() required = false;

  /**
   * Flag to hide required asterisk mark on input fields.
   */
  @Input() hideRequiredMarker = false;

  /**
   * Optional. Whether the field is readonly.
   */
  @Input() readonly = false;

  /**
   * Float label passed on to determine mat-form-field behavior.
   */
  @Input() floatLabel = 'auto';

  /**
   * Optional. If specified, this function will be used to return the array of
   * filtered results.
   */
  @Input() filteredResultsFn: (values: any[], searchString: string) => any[];

  /**
   * Specified if none option has to be shown in the  mat-select dropdown list.
   */
  @Input() showNoneOption = true;

  /**
   * The remove icon to use.
   */
  @Input() removeIcon: 'close' | 'cancel' = 'cancel';

  /**
   * Placeholder text for search input.
   */
  @Input() searchPlaceholderLabel = this.translate.instant('search');

  /**
   * Sets a custom comparator function and enables selection tracking if provided.
   */
  @Input()
  set compareWith(value: ComparatorFn<any>) {
    this._compareWith = value;
    this.formFieldSelectionTracking = !!value;
  }

  /**
   * Gets the current comparator function.
   */
  get compareWith(): ComparatorFn<any> | null {
    return this._compareWith;
  }

  /**
   * Comparator function for comparing items.
   */
  private _compareWith: ComparatorFn<any>;

  /**
   * Indicates whether formFieldSelectionTracking is enabled
   */
  formFieldSelectionTracking = false;

  /**
   * Reference to the virtual scroll viewport.
   */
  @ViewChild(CdkVirtualScrollViewport) cdkVirtualScrollViewPort: CdkVirtualScrollViewport;

  /**
   * Form Control for searching the values.
   */
  searchCtrl = new UntypedFormControl();

  /**
   * Observable to contain the list of values.
   */
  values$ = new BehaviorSubject([]);

  /**
   * Function to return whether <mat-option> searching should be enabled in
   * this component.
   */
  get searchEnabled(): boolean {
    // Search is allowed only if allow search flag is true and and either
    //  an optionKey is provided, or filteredResultsFn is present.
    return this.allowSearch && Boolean(this.optionKey || this.filteredResultsFn);
  }

  constructor(
    private translate: TranslateService,
    readonly sd: ScrollDispatcher,
    private cd: ChangeDetectorRef,
  ) {
    super();
  }

  /**
   * Component init.
   */
  ngOnInit() {
    this.values$.next(this.values);
    this.setupSearch();
    this.initializeSelectionHandling();
  }

  /**
   * Initializes selection handling for the component.
   * This includes setting up the selected array and listening for value changes.
   */
  private initializeSelectionHandling(): void {
    if (!this.formFieldSelectionTracking) {
      return;
    }

    const valueChangeSubscription = this.control.valueChanges.subscribe((value: any[]) => {
      if (value) {
        this.updateOptionSelectionStates();
      }
    });

    this.subscriptions.push(valueChangeSubscription);
  }

  /**
   * Component AfterViewInit.
   */
  ngAfterViewInit(): void {
    if(this.formFieldSelectionTracking) {
      const scrollSubscription = this.sd
        .scrolled()
        .pipe(
          filter(scrollable => this.cdkVirtualScrollViewPort === scrollable),
          debounceTime(200)
        )
        .subscribe(() => {
          this.updateOptionSelectionStates();
        });
      this.subscriptions.push(scrollSubscription);
    }
  }

  /**
   * Called whenever a selection change event occurs.
   */
  onSelectionChange(change): void {
    if (!change.isUserInput || !this.formFieldSelectionTracking) {
      return;
    }

    const currentValue = this.control.value || [];
    const value = change.source.value;

    let updatedValue;

    // Check if the value already exists in the current selection
    const selectedItem = currentValue.find(item => this.compareWith(item, value));
    const isAlreadySelected = !!selectedItem;

    if (isAlreadySelected) {
      updatedValue = currentValue.filter(item => !this.compareWith(item, value));
    } else if (this.maxSelectedItems && currentValue.length >= this.maxSelectedItems) {
      // Keep the current value unchanged if the maxSelectedItems limit is reached
      updatedValue = currentValue;
    } else {
      // Add the value if not already selected
      updatedValue = [...currentValue, value];
    }

    this.control.setValue(updatedValue, { emitEvent: false });
    this.updateOptionSelectionStates();
  }

  /**
   * Updates the selection state of all options in the dropdown.
   * This ensures that selected options remain selected and other options
   * adhere to the max selection limit.
   */
  updateOptionSelectionStates(): void {
    if (!this.options?.length) {
      return;
    }

    const currentValue = this.control.value || [];
    this.options.forEach(option => {
      const isSelected = currentValue.find(item => this.compareWith(item, option.value));
      if (isSelected && !option.selected) {
        option.select();
      } else if (!isSelected && option.selected) {
        option.deselect();
      }
    });

    this.cd.detectChanges();
  }

  /**
   * Component on change.
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.values) {
      if (!this.asyncSearch) {
        this.searchCtrl.setValue('');
      }
      this.values$.next(this.values);
    }

    if (changes.filteredResultsFn) {
      this.clearSubscriptions();
      this.setupSearch();
    }

    if (!this.optionKey && (!this.optionTemplate || !this.triggerTemplate)) {
      throw new Error(
        'Both optionTemplate and triggerTemplate ' +
        'are required if no optionKey is provided.'
      );
    }

    if (this.allowSearch && !this.optionKey && !this.filteredResultsFn) {
      throw new Error(
        'Either optionKey or filteredResultsFn ' +
        'needs to be specified with allowSearch.'
      );
    }
  }

  /**
   * Function to be called when mat select is opened.
   *
   * @param event Whether the select was opened.
   */
  openedChange(event: boolean) {
    if (!event) {
      return;
    }

    // Without this, when the select is opened, the virtual scroll list appears
    // blank after scrolling all the way down.
    this.cdkVirtualScrollViewPort.scrollToIndex(0);
    this.cdkVirtualScrollViewPort.checkViewportSize();
  }

  /**
   * Function to setup search filters for the selector.
   */
  setupSearch() {
    if (!this.searchEnabled) {
      return;
    }

    this.subscriptions.push(
      this.searchCtrl.valueChanges.pipe(
        debounceTime(500),
        distinctUntilChanged(),
      ).subscribe(searchString => {
        if (this.asyncSearch) {
          this.search.emit(searchString);
        } else {
          let filteredValues;

          if (this.filteredResultsFn) {
            filteredValues = this.filteredResultsFn(this.values, searchString);
          } else {
            const regularExpression = new RegExp(searchString, 'i');

            filteredValues = this.values?.filter(value =>
              this.getOptionString(value).search(regularExpression) > -1
            );
          }

          this.values$.next(filteredValues);
        }
      })
    );
  }

  /**
   * Returns whether the option is disabled. This is used when multiple
   * selection is enabled with an additional maxSelectedItems option.
   *
   * @param option The mat-option.
   * @return Whether the option should be disabled.
   */
  optionDisabled(option: MatOption) {
    if (!this.multiple || !Object.prototype.hasOwnProperty.call(this, 'maxSelectedItems')) {
      return false;
    }

    const value = this.control.value || [];
    return value.length >= this.maxSelectedItems && !option.selected;
  }

  /**
   * Removes a value from the selection.
   *
   * @param index The index of the item to remove.
   */
  removeAt(index: number) {
    this.control.setValue(this.control.value.filter((_, idx) => idx !== index));
  }

  /**
   * Function to return string value of an option.
   *
   * @param value the option.
   * @return the string of the option.
   */
  getOptionString(value: any): string {
    return get(value, this.optionKey);
  }

  /**
   * Function to return string value of an option.
   */
  addItem() {
    this.registerAddButtonClick.emit();
    return;
  }
}
