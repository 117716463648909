<div class="user-card">
  <div class="user-details-container">
    <div class="user-avatar-container">
      <cog-icon shape="helix:user-alt"></cog-icon>
    </div>
    <div class="user-info-container">
      <div class="user-heading">{{ user?.username }}</div>
      <h5 class="user-subheading" *ngIf="user?.emailAddress">{{ user?.emailAddress }}</h5>
    </div>
  </div>
  <mat-action-list>
    <button id="logout-user-button" cogDataId="logout-user-button" mat-list-item (click)="logout.emit()">
      {{ 'logout' | translate }}
    </button>
  </mat-action-list>
</div>
