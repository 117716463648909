<div class="flex-row flex-justify-space-between">
  <div>
    <button
      mat-stroked-button
      *ngIf="selectedStepIndex"
      class="flex-pull-left"
      id="back-btn"
      cogDataId="back-btn"
      [disabled]="isSubmitting"
      (click)="goBack.emit()">
      {{ 'back' | translate }}
    </button>
  </div>

  <div>
    <button
      mat-button
      class="mat-primary"
      id="cancel-btn"
      cogDataId="cancel-btn"
      [disabled]="isSubmitting"
      (click)="cancel.emit()">
      {{ 'cancel' | translate }}
    </button>
    <button
      mat-flat-button
      *ngIf="selectedStepIndex < totalSteps - 1"
      id="continue-btn"
      cogDataId="continue-btn"
      cdkFocusInitial
      [disabled]="isSubmitting || isDisabled"
      color="primary"
      (click)="goNext.emit()">
      {{ 'continue' | translate }}
    </button>
    <button
      mat-flat-button
      *ngIf="selectedStepIndex === totalSteps - 1"
      id="save-btn"
      cogDataId="save-btn"
      cdkFocusInitial
      cogSubmitButton
      [disabled]="isSubmitting || isDisabled"
      [loading]="isSubmitting"
      (click)="save.emit()">
      {{ saveLabel || ('save' | translate) }}
    </button>
  </div>
</div>
