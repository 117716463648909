/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityApiConfiguration as __Configuration } from '../cohesity-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { UserPreferencesResult } from '../models/user-preferences-result';
@Injectable({
  providedIn: 'root',
})
class PreferencesService extends __BaseService {
  static readonly GetUserPreferencesPath = '/public/sessionUser/preferences';
  static readonly UpdateUserPreferencesPath = '/public/sessionUser/preferences';
  static readonly PatchUserPreferencesPath = '/public/sessionUser/preferences';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * List the preferences of the session user.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetUserPreferencesResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<UserPreferencesResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/sessionUser/preferences`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserPreferencesResult>;
      })
    );
  }
  /**
   * List the preferences of the session user.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetUserPreferences(regionId?: string,
    accessClusterId?: number): __Observable<UserPreferencesResult> {
    return this.GetUserPreferencesResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as UserPreferencesResult)
    );
  }

  /**
   * Update the preferences of the session user
   *
   * Returns the updated user preferences.
   * @param params The `PreferencesService.UpdateUserPreferencesParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `preferences`: Request to create or update User Preferences.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateUserPreferencesResponse(params: PreferencesService.UpdateUserPreferencesParams): __Observable<__StrictHttpResponse<UserPreferencesResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    __body = params.preferences;
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/public/sessionUser/preferences`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserPreferencesResult>;
      })
    );
  }
  /**
   * Update the preferences of the session user
   *
   * Returns the updated user preferences.
   * @param params The `PreferencesService.UpdateUserPreferencesParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `preferences`: Request to create or update User Preferences.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateUserPreferences(params: PreferencesService.UpdateUserPreferencesParams): __Observable<UserPreferencesResult> {
    return this.UpdateUserPreferencesResponse(params).pipe(
      __map(_r => _r.body as UserPreferencesResult)
    );
  }

  /**
   * Update specific preferences of the session user
   *
   * Returns the updated user preferences.
   * @param params The `PreferencesService.PatchUserPreferencesParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `preferences`: Request to create or update User Preferences.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  PatchUserPreferencesResponse(params: PreferencesService.PatchUserPreferencesParams): __Observable<__StrictHttpResponse<UserPreferencesResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    __body = params.preferences;
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/public/sessionUser/preferences`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserPreferencesResult>;
      })
    );
  }
  /**
   * Update specific preferences of the session user
   *
   * Returns the updated user preferences.
   * @param params The `PreferencesService.PatchUserPreferencesParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `preferences`: Request to create or update User Preferences.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  PatchUserPreferences(params: PreferencesService.PatchUserPreferencesParams): __Observable<UserPreferencesResult> {
    return this.PatchUserPreferencesResponse(params).pipe(
      __map(_r => _r.body as UserPreferencesResult)
    );
  }
}

module PreferencesService {

  /**
   * Parameters for UpdateUserPreferences
   */
  export interface UpdateUserPreferencesParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Request to create or update User Preferences.
     */
    preferences?: {[key: string]: string};

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for PatchUserPreferences
   */
  export interface PatchUserPreferencesParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Request to create or update User Preferences.
     */
    preferences?: {[key: string]: string};

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { PreferencesService }
