
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA
} from '@angular/material/legacy-dialog';
import { ObjectServiceApi, ObjectSnapshot } from '@cohesity/api/v2';
import { OPEN_CLOSE_ANIMATION } from '@cohesity/helix';
import moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { PassthroughOptionsService } from 'src/app/core/services';
import { Environment, RecoveryAction } from 'src/app/shared';
import { RestorePointSelection } from '../..';

/**
 * Specifies the interface for data used by TimeBasedSnapshotSelectorComponent
 * as a Modal.
 */
interface DialogData {
  /**
   * Specifies the title for the Dialog.
   */
  title: string;

  /**
   * Specifies the message which will be shown within the Info banner.
   */
  infoMessage?: string;

  /**
   * Specifies the backup type also referred as object action key. This enum
   * is inline with the proto message Environment.Type defined in
   * main/magneto/base/enums.proto.
   */
  objectActionKey?: Environment;

  /**
   * Specifies the object selected for recovery.
   */
  object: RestorePointSelection;

  /**
   * Specifies the recovery type.
   */
  recoveryAction: RecoveryAction;
}

/**
 * Enumerates the types of modes TimeBasedSnapshotSelectorComponent modal can
 * be in.
 */
export enum DialogMode {
  /**
   * Specifies the mode in which user is allowed to edit the current datetime
   * and fetch snapshots upto that datetime.
   */
  FETCH_SNAPSHOT = 'FETCH_SNAPSHOT',

  /**
   * Specifies the mode in which user can view the list of available snapshots
   * and can select a snapshto to restore from.
   */
  SELECT_SNAPSHOT = 'SELECT_SNAPSHOT'
}

/**
 * Specifies the component which is capable of listing snapshot based on the
 * time-range provided by the user by utilizing the time range parameters
 * within the /snapshots API unlike the traditional SnapshotSelectorComponent
 * which fetched all available snapshots and only filters based on date range
 * within UI which is not scalable.
 *
 * This is currently used by M365 CSM based backups only which the include the
 * below types:
 * - Office365BackupType.kOneDriveCSM
 * - Office365BackupType.kMailboxCSM
 * - Office365BackupType.kSharePointCSM
 */
@Component({
  selector: 'coh-time-based-snapshot-selector',
  templateUrl: './time-based-snapshot-selector.component.html',
  styleUrls: ['./time-based-snapshot-selector.component.scss'],
  animations: [OPEN_CLOSE_ANIMATION],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeBasedSnapshotSelectorComponent implements OnInit {

  /**
   * Specifies the message which will be shown within the Info banner.
   */
  infoMessage: string;

  /**
   * Specifies the backup type also referred as object action key. This enum
   * is inline with the proto message Environment.Type defined in
   * main/magneto/base/enums.proto.
   */
  objectActionKey: Environment;

  /**
   * Specifies the object selected for restore whose snapshot is to be
   * modified.
   */
  object: RestorePointSelection;

  /**
   * Specifies the recovery type.
   */
  recoveryAction: RecoveryAction;

  /**
   * Specifies the title for the Dialog.
   */
  title: string;

  /**
   * Specifies the formgroup to hold the datepicker, timepicker & selected
   * snapshot.
   */
  dateTimeForm: FormGroup;

  /**
   * Specifies the mode in which the current Dialog is being used.
   */
  mode: DialogMode;

  /**
   * Whether a search is in progress or not.
   */
  loading$ = new BehaviorSubject<boolean>(false);

  /**
   * Specifies the BehaviorSubject to store the list of snapshots.
   */
  snapshots$ = new BehaviorSubject<ObjectSnapshot[]>([]);

  /**
   * Detrmines the text to be shown within the Submit button. This depends on
   * the dialog mode.
   */
  get submitBtnText(): string {
    switch (this.mode) {
      case DialogMode.SELECT_SNAPSHOT:
        return 'update';
      case DialogMode.FETCH_SNAPSHOT:
        return 'fetchSnapshotOptions';
    }
  }

  /**
   * Determines whether the dialog mode is currently listing all snapshot and
   * the versions can be selected.
   */
  get isSelectSnapshotMode(): boolean {
    return this.mode === DialogMode.SELECT_SNAPSHOT;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) readonly data: DialogData,
    private fb: FormBuilder,
    private objectsService: ObjectServiceApi,
    private passthroughOptionsService: PassthroughOptionsService,
    public dialogRef: MatDialogRef<TimeBasedSnapshotSelectorComponent>,
  ) {
    /**
     * Init the class variables which will not change.
     */
    this.infoMessage = this.data.infoMessage || '';
    this.objectActionKey = this.data.objectActionKey;
    this.object = this.data.object;
    this.recoveryAction = this.data.recoveryAction;
    this.title = this.data.title;

    /**
     * Init the form.
     */
    this.dateTimeForm = this.fb.group({
      date: [null, Validators.required],
      time: [null, Validators.required],
      selectedSnapshot: [null, Validators.required],
    });
  }

  ngOnInit() {
    // Init the Mode to pre-select and list snapshots.
    this.mode = DialogMode.SELECT_SNAPSHOT;

    // Init the date & time to Now().
    const now = moment();
    const currentTime = this.formatTime(moment().toDate()); // Format the time as hh:mm

    this.dateTimeForm.setValue({
      date: now,
      time: currentTime,
      selectedSnapshot: null,
    });

    // Initialize the snapshots list with the latest snapshots on first load.
    this.fetchAndPopulateSnapshots();
  }

  /**
   * Changes the dialog mode to edit and enables the date time selectors for
   * user to edit.
   */
  switchToEditMode() {
    this.dateTimeForm.enable();
    this.mode = DialogMode.FETCH_SNAPSHOT;
  }

  /**
   * Handles the clicking of the submit button. This function can do 2 things:
   * 1. Set the selected snapshot as the snapshot to recover from.
   * 2. Fetch the snapshots and show the same within the Dialog.
   */
  onSubmit() {
    const selectedSnapshot = this.dateTimeForm.value.selectedSnapshot;
    let restorePointSelection: RestorePointSelection;
    switch (this.mode) {
      case DialogMode.SELECT_SNAPSHOT:
        restorePointSelection = {
          ...this.object,
          snapshot: selectedSnapshot,
          restorePointId: selectedSnapshot.id,
          timestampUsecs: selectedSnapshot.snapshotTimestampUsecs,
          isPointInTime: false,
          snapshotRunType: selectedSnapshot.runType,
          indexedObjectSourceUuid: (selectedSnapshot as any).indexedObjectSourceUuid,
          archiveTargetInfo: {...selectedSnapshot.externalTargetInfo},
        };
        this.dialogRef.close(restorePointSelection);
        break;
      case DialogMode.FETCH_SNAPSHOT:
        this.fetchAndPopulateSnapshots();
        break;
    }
  }

  /**
   * Method to handle fetch and update of snapshots within the Dialog.
   */
  private fetchAndPopulateSnapshots() {
    this.loading$.next(true);

    // Fetch the snapshots upto Now().
    this.invokeSnapshotApi().pipe(
      finalize(() => {
        this.loading$.next(false);
        this.mode = DialogMode.SELECT_SNAPSHOT;
        this.dateTimeForm.controls['date'].disable();
        this.dateTimeForm.controls['time'].disable();
    })).subscribe((snapshots) => {
      // Ensure the list is in reverse chronological order.
      this.snapshots$.next(snapshots.reverse());
    });
  }

  /**
   * Invokes the snapshots' API to fetch the available versions.
   *
   * @returns Observable of array of the snapshots.
   */
  private invokeSnapshotApi(): Observable<ObjectSnapshot[]> {
    const formData = this.dateTimeForm.value;
    let selectedDateMoment: moment.Moment = formData.date;
    // Init the date to now() if not initialized.
    if (!selectedDateMoment) {
      selectedDateMoment = moment();
    }

    const selectedDateObject: Date = selectedDateMoment.toDate();

    // Update the Date Object with the time selected by the user.
    if (formData.time) {
      const splittedTimeParts = formData.time.split(':');
      if (splittedTimeParts.length === 2) {
        selectedDateObject.setHours(splittedTimeParts[0]);
        selectedDateObject.setMinutes(splittedTimeParts[1]);
      }
    }

    return this.objectsService
      .GetObjectSnapshots({
        id: Number(this.data.object.objectInfo.id),

        // Convert to epoch for the API to consume.
        toTimeUsecs: selectedDateObject.getTime() * 1000,
        fromTimeUsecs: null,
        snapshotActions: ([this.recoveryAction]) as ObjectServiceApi.
          GetObjectSnapshotsParams['snapshotActions'],
        objectActionKeys: [this.objectActionKey] as ObjectServiceApi.
          GetObjectSnapshotsParams['objectActionKeys'],
        ...this.passthroughOptionsService.requestParams,
      })
      .pipe(
        map(result => result.snapshots),
      );
  }

  /**
   * Formats the JS Date object into human readable string.
   *
   * @param date Specifies the JS date object.
   * @returns human readable string in hh:mm format.
   */
  private formatTime(date: Date): string {
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  }
}
