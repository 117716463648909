/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityApiConfiguration as __Configuration } from '../cohesity-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { NodeNetworkInterfaces } from '../models/node-network-interfaces';
import { Interface } from '../models/interface';
@Injectable({
  providedIn: 'root',
})
class InterfaceService extends __BaseService {
  static readonly ListInterfacePath = '/public/interface';
  static readonly UpdateInterfacePath = '/public/interface';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Show network interfaces.
   * @param params The `InterfaceService.ListInterfaceParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `nodeId`: Specifies the id of the ndde.
   *
   * - `includeUplinkSwitchInfo`: Specifies if include uplink switch info.
   *
   * - `includeBondSlaveDetails`: Specifies if include bond slave detailed info.
   *
   * - `ifaceGroupAssignedOnly`: Specifies if only show interface group assigned interface info.
   *
   * - `cache`: Specifies if interface is cached info.
   *
   * - `bondInterfaceOnly`: Specifies if only show bond interface info.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  ListInterfaceResponse(params: InterfaceService.ListInterfaceParams): __Observable<__StrictHttpResponse<Array<NodeNetworkInterfaces>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.nodeId != null) __params = __params.set('nodeId', params.nodeId.toString());
    if (params.includeUplinkSwitchInfo != null) __params = __params.set('includeUplinkSwitchInfo', params.includeUplinkSwitchInfo.toString());
    if (params.includeBondSlaveDetails != null) __params = __params.set('includeBondSlaveDetails', params.includeBondSlaveDetails.toString());
    if (params.ifaceGroupAssignedOnly != null) __params = __params.set('ifaceGroupAssignedOnly', params.ifaceGroupAssignedOnly.toString());
    if (params.cache != null) __params = __params.set('cache', params.cache.toString());
    if (params.bondInterfaceOnly != null) __params = __params.set('bondInterfaceOnly', params.bondInterfaceOnly.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/interface`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<NodeNetworkInterfaces>>;
      })
    );
  }
  /**
   * Show network interfaces.
   * @param params The `InterfaceService.ListInterfaceParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `nodeId`: Specifies the id of the ndde.
   *
   * - `includeUplinkSwitchInfo`: Specifies if include uplink switch info.
   *
   * - `includeBondSlaveDetails`: Specifies if include bond slave detailed info.
   *
   * - `ifaceGroupAssignedOnly`: Specifies if only show interface group assigned interface info.
   *
   * - `cache`: Specifies if interface is cached info.
   *
   * - `bondInterfaceOnly`: Specifies if only show bond interface info.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  ListInterface(params: InterfaceService.ListInterfaceParams): __Observable<Array<NodeNetworkInterfaces>> {
    return this.ListInterfaceResponse(params).pipe(
      __map(_r => _r.body as Array<NodeNetworkInterfaces>)
    );
  }

  /**
   * Update an interface.
   *
   * Returns the update status upon completion.
   * @param params The `InterfaceService.UpdateInterfaceParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`:
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateInterfaceResponse(params: InterfaceService.UpdateInterfaceParams): __Observable<__StrictHttpResponse<Interface>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    __body = params.body;
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/public/interface`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Interface>;
      })
    );
  }
  /**
   * Update an interface.
   *
   * Returns the update status upon completion.
   * @param params The `InterfaceService.UpdateInterfaceParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`:
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateInterface(params: InterfaceService.UpdateInterfaceParams): __Observable<Interface> {
    return this.UpdateInterfaceResponse(params).pipe(
      __map(_r => _r.body as Interface)
    );
  }
}

module InterfaceService {

  /**
   * Parameters for ListInterface
   */
  export interface ListInterfaceParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Specifies the id of the ndde.
     */
    nodeId?: number;

    /**
     * Specifies if include uplink switch info.
     */
    includeUplinkSwitchInfo?: boolean;

    /**
     * Specifies if include bond slave detailed info.
     */
    includeBondSlaveDetails?: boolean;

    /**
     * Specifies if only show interface group assigned interface info.
     */
    ifaceGroupAssignedOnly?: boolean;

    /**
     * Specifies if interface is cached info.
     */
    cache?: boolean;

    /**
     * Specifies if only show bond interface info.
     */
    bondInterfaceOnly?: boolean;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateInterface
   */
  export interface UpdateInterfaceParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;
    body?: Interface;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { InterfaceService }
