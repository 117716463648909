import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { ArgusAppService, ClusterInfo, notStartedStatus } from '@cohesity/data-govern/shared';
import { CanSelectRowFn, IsAllSelectedFn, KeyedSelectionModel, ToggleSelectAllFn } from '@cohesity/helix';
import { AdaptorAccessService } from '@cohesity/iris-core';
import { Environment } from '@cohesity/iris-shared-constants';
import { AutoDestroyable, ClusterIdentifierId, getClusterIdentifier } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';

import { SearchObjectPerSource, SupportedOsTypes } from '../../scan.models';

@Component({
  selector: 'dg-scan-object-search-result',
  templateUrl: './object-search-result.component.html',
  styleUrls: ['./object-search-result.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ObjectSearchResultComponent extends AutoDestroyable implements OnInit {
  /**
   * The search results to display.
   */
  @Input() searchResults: SearchObjectPerSource[];

  /**
   * The selection model for the search results. This could be single or multi-select.
   * This uses a keyed selection model and persists the selection between table reloads,
   * so that the selection can be maintained as data is swapped out with each search.
   * This can be null to remove any checkboxes or radio buttons from the form.
   */
  @Input() selection: KeyedSelectionModel<SearchObjectPerSource>;

  /**
   * Flag that when a search returned no results.
   */
  @Input() noData = false;

  /**
   * Supported Environments
   */
  @Input() adapterAccessService: AdaptorAccessService;

  /**
   * Supported OS types
   */
  @Input() supportedOsTypes: SupportedOsTypes[];

  /**
   * Flag to restrict non datahawk app installed objects selection or not
   */
  @Input() restrictNonDatahawkAppInstalledObjects = false;


  /**
   * Flag indicating that max number of objects are selected already
   */
  @Input() maxSelected = false;

  /**
   * Max number of objects allowed for selection.
   */
  @Input() maxObjectsAllowedForSelection;


  /** The cached cluster by ClusterIdentifierId map */
  private clusterByClusterIdentifierId: Map<ClusterIdentifierId, ClusterInfo>;

  constructor(
    private argusAppService: ArgusAppService,
    private cdr: ChangeDetectorRef,
    private translateService: TranslateService
  ) {
    super();
  }

  ngOnInit() {
    if (this.selection) {
      this.selection.changed.pipe(this.untilDestroy()).subscribe(() => this.cdr.detectChanges());

      this.argusAppService.getAppInstallationStatus().pipe(
        this.untilDestroy(),
        map(({ clusterByClusterIdentifierId }) => this.clusterByClusterIdentifierId = clusterByClusterIdentifierId),
      ).subscribe();
    }
  }

  /**
   * Toggles the selection of an item.
   *
   * @param   object   The object to toggle.
   */
  toggleObjectSelection(object: SearchObjectPerSource) {
    if (this.selection && this.canSelectRow(object)) {
      this.selection.toggle(object);
    }
  }

  /** Callback Fn to cog-table to disable selection of object when argus app is not installed there */
  canSelectRow: CanSelectRowFn<SearchObjectPerSource> = object =>
    this.selection.isSelected(object) ||
      (this.maxObjectsAllowedForSelection ? !this.maxSelected : true) &&
    this.hasSupportedAdapter(object) &&
    this.hasSupportedOsType(object) &&
    (!this.restrictNonDatahawkAppInstalledObjects ||
      notStartedStatus.has(
        this.clusterByClusterIdentifierId?.get(getClusterIdentifier(object.objectProtectionInfo))?.status
      ));

  /**
   * Callback Fn to cog-table to show tooltip for object with when argus app is not installed there
   */
  selectRowTooltip = (object: SearchObjectPerSource) => {
    if (this.canSelectRow(object)) {
      return null;
    }

    switch (true) {
      case this.maxSelected:
        return this.translateService.instant('dg.tooltip.scan.maxObjectSelectionLimitReached');
      case this.restrictNonDatahawkAppInstalledObjects &&
        !notStartedStatus.has(
          this.clusterByClusterIdentifierId?.get(getClusterIdentifier(object.objectProtectionInfo))?.status
        ):
        return this.translateService.instant('dg.tooltip.scan.dhEngineNotInstalled');
      case !this.hasSupportedAdapter(object):
        return this.translateService.instant('dg.tooltip.scan.objectTypeNotSupported');
      case !this.hasSupportedOsType(object):
        return this.translateService.instant('dg.tooltip.scan.osTypeNotSupported');
      default:
        return null;
    }
  };

  /**
   * Intimate cog-table with limited object selection capability
   */
  isAllSelectedFn: IsAllSelectedFn = () =>
    this.maxSelected || this.selection.selected.length >= this.searchResults?.length;

  /**
   * Limiting object selection when select all clicked
   */
  toggleSelectAllFn: ToggleSelectAllFn = (event: MatCheckboxChange) => {
    if (event.checked && !this.maxSelected) {
      this.selection.clear();
      if (this.maxObjectsAllowedForSelection) {
        this.selection.select(...this.searchResults.slice(0, this.maxObjectsAllowedForSelection));
      } else {
        this.selection.select(...this.searchResults);
      }
    }
  };

  /**
   * Find whether given environment is supported or not
   */
  hasSupportedAdapter = (object: SearchObjectPerSource) =>
    this.adapterAccessService.getSupportedEnvironments().includes(object.object.environment as Environment);

  /**
   * Find whether given OS type is supported or not
   */
  hasSupportedOsType = (object: SearchObjectPerSource) =>
    !object.object.osType || this.supportedOsTypes.includes(object.object.osType as SupportedOsTypes);
}
