<coh-source-special-parameters [form]="form" [nodeContext]="nodeContext"
  [label]="(excludePvcs?.length || includePvcs?.length || quiesceRules?.length) ? ('cSourceTreeNode.customized' | translate) : ''"
  (dialogOpened)="updateForm()"
  (saved)="onSaved()">
  <div *ngIf="form" [formGroup]="form">
    <mat-card class="include-exclude-pvcs" *ngIf="pvcs?.length">
      <mat-slide-toggle class="margin-top-sm" formControlName="enablePvcFilter"
        cogDataId="enable-pvc-inclusion-exclusion"
        [inheritDataId]="true"
        (change)="togglePvcs()">
        {{'enablePvcInclusionExclusion' | translate}}
      </mat-slide-toggle>

      <cog-banner class="margin-top">
        <p>{{'job.settings.exclusionInclusion.kubernetes.selectPvcs' | translate}}</p>
      </cog-banner>

      <ng-container *ngIf="form.value.enablePvcFilter">
        <mat-radio-group class="margin-top" formControlName="pvcInclusionType" (change)="togglePvcInclusionType()">
          <div class="select-dropdown">
            <mat-radio-button cogDataId="include-pvcs-radio-button" [inheritDataId]="true" value="include">
              {{'include' | translate}}
            </mat-radio-button>
            <mat-radio-button cogDataId="exclude-pvcs-radio-button" [inheritDataId]="true" value="exclude">
              {{'exclude' | translate}}
            </mat-radio-button>
          </div>
          <div class="margin-tl">
            <mat-hint *ngIf="form.value.pvcInclusionType === 'include'">{{'job.settings.exclusionInclusion.kubernetes.includeHint' |
              translate}}</mat-hint>
            <mat-hint *ngIf="form.value.pvcInclusionType === 'exclude'">{{'job.settings.exclusionInclusion.kubernetes.excludeHint' |
              translate}}</mat-hint>
          </div>
          <div class="select-pvcs-container">
              <ng-container *ngTemplateOutlet="selectPvcs; context: {type: form.value.pvcInclusionType}"></ng-container>
          </div>
          <mat-checkbox class="margin-top" formControlName="backupOnlyPvc" *ngIf="isK8sResourceInclusionExclusionEnabled"
            (change)="backupOnlyPvcOnChange()">
            {{'backupOnlyPvcInfoMsg' | translate }}</mat-checkbox>
          </mat-radio-group>

        <ng-template #selectPvcs let-type="type">
          <mat-form-field class="margin-top">
            <mat-select cogDataId="include-exclude-pvcs-selector" class="pvc-chip-list"
              placeholder="{{(type === 'include' ? 'includePvcs' : 'excludePvcs') | translate}}"
              formControlName="pvcs" multiple>
              <mat-select-trigger *ngIf="form.value.pvcs?.length">
                <mat-chip-list>
                  <mat-chip class="chip"
                    *ngFor="let pvc of form.value.pvcs; index as i"
                    [removable]="true"
                    (removed)="removePvc(pvc)">
                    {{ pvc.name }}
                    <cog-icon cogDataId="remove-pvc-{{i}}" matChipRemove shape="close" size="sm"></cog-icon>
                  </mat-chip>
                </mat-chip-list>
              </mat-select-trigger>
              <mat-option *ngFor="let pvc of pvcs" [value]="pvc" cogDataId="pvc-{{pvc.id}}">
                <div>
                  {{ pvc.name }}
                </div>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </ng-template>
      </ng-container>
    </mat-card>

    <!-- Include exclude Resources -->
    <mat-card class="include-exclude-pvcs" *ngIf="isK8sResourceInclusionExclusionEnabled">
      <mat-slide-toggle class="margin-top-sm" formControlName="enableResourceFilter"
        cogDataId="enable-resource-inclusion-exclusion"
        [inheritDataId]="true"
        (change)="toggleResourceInclusion()">
        {{'enableResourceInclusionExclusion' | translate}}
      </mat-slide-toggle>

      <ng-container *ngIf="form.value.enableResourceFilter">
        <mat-radio-group class="margin-top" formControlName="resourceInclusionType" (change)="toggleResourceInclusion()">
          <div class="select-dropdown">
          <mat-radio-button cogDataId="include-resources-radio-button" [inheritDataId]="true" value="include">
            {{'include' | translate}}
          </mat-radio-button>
          <mat-radio-button cogDataId="exclude-resources-radio-button" [inheritDataId]="true" value="exclude">
            {{'exclude' | translate}}
          </mat-radio-button>
        </div>

          <div class="select-pvcs-container">
              <ng-container *ngTemplateOutlet="selectResources; context: {type: form.value.resourceInclusionType}"></ng-container>
          </div>
        </mat-radio-group>

        <ng-template #selectResources let-type="type">
          <mat-form-field class="margin-top">
            <mat-select cogDataId="include-exclude-resources-selector" class="pvc-chip-list"
              placeholder="{{(type === 'include' ? 'includeResources' : 'excludeResources') | translate}}"
              formControlName="resources" multiple>
              <mat-select-trigger *ngIf="form.value.resources?.length">
                <mat-chip-list>
                  <mat-chip class="chip"
                    *ngFor="let resource of form.value.resources; index as i"
                    [removable]="true"
                    (removed)="removeAddedResources(resource)">
                    {{ resource.name }}
                    <cog-icon cogDataId="remove-resource-{{i}}" matChipRemove shape="close" size="sm"></cog-icon>
                  </mat-chip>
                </mat-chip-list>
              </mat-select-trigger>
              <mat-option *ngFor="let resource of resourceList" [value]="resource"
               cogDataId="{{form.value.resourceInclusionType}}-resource-{{resource.id}}">
                <div>
                  {{ resource.name }}
                </div>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </ng-template>

        <div class="margin-tl">
          <div *ngFor="let customResource of customResourceControl.controls; index as i;" [formGroup]="customResource">
            <mat-form-field *ngIf="customResource">
              <input [formControl]="customResource"
                [placeholder]="(form.value.resourceInclusionType === 'include' ? 'includeCustomResources' : 'excludeCustomResources') | translate"
                matInput required>
              </mat-form-field>
            <button (click)="removeCustomResourceFormControl(i)" cogDataId="kubernetes-remove-custom-resource-button"
              mat-icon-button type="button">
              <cog-icon shape="clear"></cog-icon>
            </button>
          </div>
          <button (click)="addCustomResourceFormControl()" [inheritDataId]="true" cogDataId="kubernetes-add--custom-resource-button"
            class="margin-bottom-sm margin-top-sm" color="primary" mat-stroked-button type="button">
            <cog-icon shape="add"></cog-icon>
            {{'addCustomResources' | translate}}
          </button>
        </div>
      </ng-container>
    </mat-card>

    <mat-card class="quiesce-rules" *ngIf="isKubernetesQuiesceRulesEnabled">
      <hr>
      <h2>{{'job.settings.kubernetes.quiesceRules' | translate}} </h2>
      <mat-form-field class="quiesce-mode">
        <mat-label translate>quiesceMode</mat-label>
        <mat-select formControlName="quiesceMode"
          [inheritDataId]="true"
          cogDataId="quiesce-mode-select">
          <mat-option [inheritDataId]="true"
            cogDataId="option-together"
            [value]="kubernetesQuiesceMode.together">
            {{'enum.protectionGroup.kubernetes.quiesceMode.together' | translate}}
          </mat-option>
          <mat-option [inheritDataId]="true"
            cogDataId="option-independently"
            [value]="kubernetesQuiesceMode.independently">
            {{'enum.protectionGroup.kubernetes.quiesceMode.independently' | translate}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div *ngFor="let quiesceRuleControl of quiesceRulesControl.controls; index as i;"
        [formGroup]="quiesceRuleControl" class="rule padding-bottom-sm">
        <div class="script">
          <h3 class="margin-left-sm margin-top-sm">{{'job.settings.kubernetes.rule' | translate: {index: i+1} }}</h3>
          <div class="close-button">
            <button
              type="button"
              mat-icon-button
              (click)="toggleRuleExpand(i)"
              cogDataId="toggle-rule-expand"
              [inheritDataId]="true">
              <cog-icon
                class="mat-icon-rtl-mirror"
                shape="{{ ruleExpand[i] ? 'expand_more' : 'expand_less' }}">
              </cog-icon>
            </button>

            <button (click)="removeQuiesceRuleControl(i)"
              mat-icon-button
              [cogDataId]="'remove-rule-' + i"
              type="button">
              <cog-icon shape="clear"></cog-icon>
            </button>
          </div>
        </div>
        <div *ngIf="ruleExpand[i]">
          <div *ngFor="let type of [typeSectionRule.labels, typeSectionRule.preScript, typeSectionRule.postScript]" [ngClass]="type !== 'postScript' ? 'type-section': 'none'">
            <h4 class="margin-left-sm" *ngIf="type === typeSectionRule.labels">{{'job.settings.kubernetes.podSelectorLabels' | translate}}</h4>
            <h4 class="margin-left-sm margin-top-sm" *ngIf="type === typeSectionRule.preScript">{{'job.settings.kubernetes.preSnapshotScript' | translate}}</h4>
            <h4 class="margin-left-sm margin-top-sm" *ngIf="type === typeSectionRule.postScript">{{'job.settings.kubernetes.postSnapshotScript' | translate}}</h4>

            <!-- Pod selector labels  -->
            <div *ngIf="type === typeSectionRule.labels; else prePostScript">
              <div *ngFor="let labelFormGroup of typeControl(i, type)?.controls; index as j;"
                [formGroup]="labelFormGroup">
                <div class="label-container">
                  <mat-form-field>
                    <input [cogDataId]="'rule-'+ type + '-' + i + '-key'"
                    [formControl]="labelFormGroup.get('key')" placeholder="key" matInput required>
                    <mat-error>{{'errors.required' | translate}}</mat-error>
                  </mat-form-field>
                  <mat-form-field class="margin-left-sm">
                    <input [cogDataId]="'rule-'+ type + '-' + i + '-' + 'value'"
                    [formControl]="labelFormGroup.get('value')" placeholder="value" matInput required>
                    <mat-error>{{'errors.required' | translate}}</mat-error>
                  </mat-form-field>
                  <button (click)="removeTypeFormControl(i, j, type)"
                    mat-icon-button
                    [cogDataId]="'remove-'+ type + '-' + i"
                    type="button">
                    <cog-icon shape="clear"></cog-icon>
                  </button>
                </div>
              </div>
            </div>

            <!-- Loop through all pre and post script form controls -->
            <ng-template #prePostScript>
              <div *ngFor="let scriptFormGroup of typeControl(i, type)?.controls; index as k;"
                [formGroup]="scriptFormGroup">
                <div class="script margin-left-sm">
                  <h5 class="margin-top-sm">{{'job.settings.kubernetes.script' | translate: {index: k+1} }}</h5>
                  <div class="close-button">
                    <button [cogDataId]="'remove-' + type + '-rule-button-' + index"
                      (click)="removeTypeFormControl(i, k, type)"
                      mat-icon-button
                      type="button">
                      <cog-icon shape="clear" size="sm"></cog-icon>
                    </button>
                  </div>
                </div>

                <mat-form-field class="margin-left-sm">
                  <input [cogDataId]="'rule-'+ type + '-' + i + '-container'"
                  [formControl]="scriptFormGroup.get('container')" placeholder="container" matInput>
                </mat-form-field>

                <mat-form-field class="margin-left-sm">
                  <input [cogDataId]="'rule-'+ type + '-' + i + '-command'"
                  [formControl]="scriptFormGroup.get('command')" placeholder="command" matInput>
                  <mat-error>{{'errors.required' | translate}}</mat-error>
                </mat-form-field>

                <div class="script margin-left-sm">
                  <mat-form-field class="sub-section">
                    <mat-label>{{ 'timeoutSecs' | translate }}</mat-label>
                    <input
                      type="number"
                      matInput
                      [cogDataId]="'rule-'+ type + '-' + i + '-timeout'"
                      [inheritDataId]="true"
                      [formControl]="scriptFormGroup.get('timeout')"
                      [min]="timeoutLimits.min"
                      [max]="timeoutLimits.max">
                    <mat-error>{{ 'errors.invalid' | translate }}</mat-error>
                  </mat-form-field>

                  <mat-slide-toggle class="margin-top-sm margin-left-lg" [formControl]="scriptFormGroup.get('failOnError')"
                    [cogDataId]="'rule-'+ type + '-' + i + '-failonerror'"
                    [inheritDataId]="true">
                    {{'job.settings.kubernetes.failOnError' | translate }}
                  </mat-slide-toggle>
                </div>
              </div>
            </ng-template>

            <div class="add-button">
              <button [cogDataId]="'add-' + type + '-rule-button'"
                class="margin-top-sm"
                (click)="addTypeFormControl(i, type)"
                color="primary"
                mat-stroked-button
                type="button">
                <cog-icon shape="add"></cog-icon>
                {{(type === typeSectionRule.labels ? 'job.settings.kubernetes.addLabel':
                'job.settings.kubernetes.addScript') | translate}}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="add-button">
        <button (click)="addQuiesceRule()"
          [inheritDataId]="true"
          cogDataId="add-button"
          class="margin-bottom-sm margin-top-lg"
          color="primary"
          mat-stroked-button
          type="button">
          <cog-icon shape="add"></cog-icon>
          {{'job.settings.kubernetes.addRule' | translate}}
        </button>
      </div>
    </mat-card>
  </div>
</coh-source-special-parameters>
