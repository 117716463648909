import { Pipe, PipeTransform } from '@angular/core';
import { ResourceType } from '@cohesity/api/argus';
import { TranslateService } from '@ngx-translate/core';


@Pipe({ name: 'resourceType' })
export class ResourceTypePipe implements PipeTransform {
  constructor(private translateService: TranslateService) { }

  transform(type: ResourceType | string): string {
    return type ? this.translateService.instant(`argus.enum.resourceType.${type}`) : null;
  }
}
