import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Controls, NgxSubFormComponent, subformComponentProviders, takeUntilDestroyed, TypedFormGroup } from 'ngx-sub-form';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { ProtectionGroupServiceApi } from '@cohesity/api/v2';
import { Environment } from 'src/app/shared/constants';
import { RecoveryFormContextService } from '../../model/recovery-form-context';
import { InstantVolumeMountCommonOptionsForm } from '../../model/instant-volume-mount-common-options-form';

/**
 * This component provides common options for file recoveries that are used across all
 * adapters. The UI for the target section of the form should be provided via content-projection
 *
 * @example
 * <coh-instant-volume-mount-common-options-form [isReadOnly]="isReadOnly"
 *   [formControl]="formControl">
 *   <ng-container [formGroup]="optionsForm">
 *     <coh-instant-volume-mount-vm-target-options [availableSources]="vmFileService.vmTargetsData$ | async"
 *       [environment]="environment"
 *       [isReadOnly]="isReadOnly"
 *       [formControl]="targetControl">
 *     </coh-instant-volume-mount-vm-target-options>
 *   </ng-container>
 * </coh-instant-volume-mount-common-options-form>
 */
@Component({
  selector: 'coh-instant-volume-mount-common-options-form',
  templateUrl: './instant-volume-mount-common-options-form.component.html',
  providers: [...subformComponentProviders(InstantVolumeMountCommonOptionsFormComponent)],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InstantVolumeMountCommonOptionsFormComponent
  extends NgxSubFormComponent<InstantVolumeMountCommonOptionsForm> implements OnInit, OnChanges {

  /**
   * If the component should be displayed as read-only.
   */
  @Input() readOnly: boolean;

  /**
   * The parent form group
   */
  @Input() parent: TypedFormGroup<InstantVolumeMountCommonOptionsForm>;

  /**
   * Environment
   */
  @Input() environment: Environment;

  /**
   * Type of OS
   */
  @Input() osType = null;

  /**
   * Protection Group Id of object
   */
  @Input() protectionGroupId = null;

  /**
   * True if component is in resubmit flow.
   * Being used in html template.
   */
  isResubmit = false;

  /**
   * True if recovery is for windows physical block based volume
   */
  isPhysicalWindowsBlockVolumeRecovery = null;

  constructor(
    private cdr: ChangeDetectorRef,
    private irisCtx: IrisContextService,
    private recoveryContext: RecoveryFormContextService,
    private protectionGroupServiceApi: ProtectionGroupServiceApi
  ) {
    super();
  }

  ngOnInit() {
    this.isResubmit = Boolean(this.recoveryContext.initialRecoveryConfig);

    // To set 'isPhysicalWindowsBlockVolumeRecovery' for recovery summary page
    if (this.recoveryContext.isReadOnly) {
      const recoveryObject = this.recoveryContext.initialRecoveryConfig?.physicalParams?.objects?.[0];
      if (recoveryObject) {
        this.protectionGroupServiceApi.GetProtectionGroupById({ id: recoveryObject.protectionGroupId }).pipe(
          takeUntilDestroyed(this)
        ).subscribe(value => {
          this.isPhysicalWindowsBlockVolumeRecovery = this.osType === 'kWindows'
            && value?.physicalParams?.protectionType === 'kVolume';
          this.cdr.detectChanges();
        });
      }
    }
  }

  /**
   * Handling to update isPhysicalWindowsBlockVolumeRecovery
   * when recovery object selection is changed
   *
   * @param changes - updated input changes
   */
  ngOnChanges(changes: SimpleChanges) {
    if (this.isPhysicalSMBCredentialsForRecoveryEnabled && changes?.protectionGroupId?.currentValue) {
      this.protectionGroupServiceApi.GetProtectionGroupById({ id: changes.protectionGroupId.currentValue }).pipe(
        takeUntilDestroyed(this)
      ).subscribe(value => {
        this.isPhysicalWindowsBlockVolumeRecovery = this.osType === 'kWindows'
          && value?.physicalParams?.protectionType === 'kVolume';
        this.cdr.detectChanges();
      });
    }
  }

  /**
   * To check if `physicalSMBCredentialsForRecoveryEnabled` is enabled
   *
   * @returns true if the physicalSMBCredentialsForRecoveryEnabled flag is enabled
   */
  get isPhysicalSMBCredentialsForRecoveryEnabled(): boolean {
    return flagEnabled(this.irisCtx.irisContext, 'physicalSMBCredentialsForRecoveryEnabled');
  }

  /**
   * Show readOnlyMount control or not
   */
  get showReadOnlyMount(): boolean {
    return this.environment === Environment.kPhysical;
  }

  /**
   * Gets the form controls for configuring IVM.
   *
   * @returns  The common options controls for IVM.
   */
  protected getFormControls(): Controls<InstantVolumeMountCommonOptionsForm> {
    return {
      readOnlyMount: new UntypedFormControl(false, null),
      clusterInterface: new UntypedFormControl(null, Validators.required),
      authorisedUser: new UntypedFormControl(null),
      taskName: new UntypedFormControl(null, Validators.required),
    };
  }
}
