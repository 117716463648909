<ng-container [formGroup]="formGroup">
  <mat-card cogFormGroup>
    <h2 *ngIf="!isReadOnly">{{'recoverTo' | translate}}</h2>
    <!-- Env-specific target options get displayed here. -->
    <ng-content></ng-content>
  </mat-card>

  <mat-card cogFormGroup>
    <cog-settings-list [cogDataId]="dataId"
      [title]="isReadOnly ? undefined : ('recoveryOptions' | translate)"
      [alwaysOpen]="true"
      [readOnly]="isReadOnly"
      [invalid]="formGroup.invalid">
      <ng-container *ngIf="!onlyClusterInterface">
        <coh-settings-list-toggle
          cohIrisHide="isDmsScope"
          *ngIf="physicalRecovery && showAclOnlyRestoreSetting"
          [label]="'aclOnlyRestore' | translate"
          [toggleLabel]="'enableAclOnlyRestore' | translate"
          trueLabel="yes"
          falseLabel="no"
          [control]="formGroupControls.aclOnlyRestore">
        </coh-settings-list-toggle>

        <coh-settings-list-overwrite-existing-file
          [lockControl]="formGroupControls.aclOnlyRestore.value"
          [control]="formGroupControls.overwriteOriginals">
        </coh-settings-list-overwrite-existing-file>

        <coh-settings-list-preserve-custom-attributes *ngIf="!nasRecovery" [label]="'preserveAttributes' | translate"
          [control]="formGroupControls.preserveAttributes"
          [isDisabled]="disablePreserveFileAttributesSetting">
        </coh-settings-list-preserve-custom-attributes>

        <coh-settings-list-continue-on-error [label]="'recover.continueOnObjectsError' | translate"
          [control]="formGroupControls.continueOnError">
        </coh-settings-list-continue-on-error>

        <coh-settings-list-save-success-files
          *ngIf="showSaveSuccessFileSetting"
          [label]="'saveSuccessFiles' | translate"
          [control]="formGroupControls.saveSuccessFiles">
        </coh-settings-list-save-success-files>

        <coh-settings-list-toggle
          cohIrisHide="isDmsScope"
          *ngIf="nasRecovery && showEncryptionControl"
          [label]="'encryption' | translate"
          [toggleLabel]="'enableEncryption' | translate"
          trueLabel="yes"
          falseLabel="no"
          [control]="formGroupControls.encryptionEnabled">
        </coh-settings-list-toggle>

        <coh-settings-list-ip-filters
          *ngIf="showFilterIpsSetting"
          [formControlName]="formControlNames.filterIps">
        </coh-settings-list-ip-filters>
      </ng-container>

      <coh-settings-list-authorised-user
        *ngIf="isPhysicalSMBCredentialsForRecoveryEnabled && isPhysicalWindowsBlockFileRecovery"
        [formControlName]="formControlNames.authorisedUser"
        [isResubmit]="isResubmit">
      </coh-settings-list-authorised-user>

      <coh-settings-list-cluster-interface
        *ngIf="!isSnapMirrorBackup && shouldDisplay('clusterInterface')"
        [formControlName]="formControlNames.clusterInterface"
        cohIrisHide="isDmsScope">
      </coh-settings-list-cluster-interface>

      <coh-settings-list-task-name
        *ngIf="!isReadOnly"
        [control]="formGroupControls.taskName"
        [taskNameKey]="getTaskNameKey()">
      </coh-settings-list-task-name>

    </cog-settings-list>
  </mat-card>
</ng-container>
