import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { CohesityHelixModule } from '@cohesity/helix';
import { TranslateModule } from '@ngx-translate/core';
import { UIRouterModule } from '@uirouter/angular';

import {
  AppBreadcrumbsComponent,
  AppClusterMessageComponent,
  AppEntitlementBannerComponent,
  AppPaywallMessageComponent,
  AppPillarLandingComponent,
  AppPillarsComponent,
  AppPillarsSwitcherComponent
} from './app-pillars';

import { IrisContextDisplayComponent } from './iris-context-display/iris-context-display.component';

const components = [
  AppBreadcrumbsComponent,
  AppClusterMessageComponent,
  AppEntitlementBannerComponent,
  AppPaywallMessageComponent,
  AppPillarLandingComponent,
  AppPillarsComponent,
  AppPillarsSwitcherComponent,
  IrisContextDisplayComponent,
];

/**
 * 5 Pillars pages.
 */
@NgModule({
  declarations: components,
  imports: [
    CohesityHelixModule,
    CommonModule,
    TranslateModule,
    UIRouterModule,
  ],
  exports: components,
})
export class IrisCoreModule {}
