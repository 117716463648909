<ng-template cohFormSectionEditMode>

  <!-- TODO(banner-exceptions): Below banner should be removed/updated based on s3/swift supportability -->
  <cog-banner *ngIf="showBanner" class="margin-bottom">
    {{ 'dg.viewAdaptor.protocol.bannerInfo' | translate }}
  </cog-banner>

  <cog-banner *ngIf="ifMaxObjectsSelected" cogDataId="max-objects-selected-banner" status="info" class="margin-bottom">
    {{ 'dg.td.scan.maxObjectSelectionLimitReached' | translate: { count: maxObjectsAllowed } }}
  </cog-banner>

  <div class="flex-row-md" *ngIf="!isLoadingPrerequisite; else spinner">
    <div class="flex-column flex-grow" *ngIf="!isLoadingPrerequisite">
      <dg-scan-object-search-field
        (queryChanged)="getObjects($event)"
        [searchPending]="searchPending"
        cogDataId="field">
      </dg-scan-object-search-field>

      <cog-filters class="margin-bottom" [showFilterLabel]="true" (valueChange)="applyFilters($event)">
        <cog-value-property-filter
          *cogFilterDef="
            let params = params;
            let filter;
            quickFilter: true;
            key: 'osTypes';
            label: 'osType' | translate;
            filterType: 'valueProperty'
          "
          [filterDefParams]="params"
          [filter]="filter"
          [allowMultiple]="true"
          [filterValues]="osTypes">
        </cog-value-property-filter>
        <cog-environment-type-filter
          [adapterAccessService]="thAdapterAccessService"
          [selectAllByDefault]="true">
        </cog-environment-type-filter>
        <cog-value-property-filter
          *cogFilterDef="
            let params = params;
            let filter;
            quickFilter: true;
            key: 'system';
            label: 'system' | translate;
            filterType: 'valueProperty'
          "
          [filterDefParams]="params"
          [filter]="filter"
          [alphaSort]="false"
          [allowMultiple]="true"
          [canSelect]="canSelectSystemFilter"
          [filterValues]="systemFilterValues">
        </cog-value-property-filter>
        <cog-value-property-filter
          *cogFilterDef="
            let params = params;
            let filter;
            quickFilter: true;
            key: 'source';
            label: 'source' | translate;
            filterType: 'valueProperty'
          "
          [filterDefParams]="params"
          [filter]="filter"
          [allowMultiple]="true"
          [filterValues]="sourceFilterValues">
        </cog-value-property-filter>
      </cog-filters>

      <dg-scan-object-search-result
        [adapterAccessService]="thAdapterAccessService"
        [supportedOsTypes]="supportedOsTypes"
        [searchResults]="searchObjects"
        [selection]="selectionModel"
        [maxSelected]="cart.length >= maxObjectsAllowed"
        [maxObjectsAllowedForSelection]="maxObjectsAllowed"
        [noData]="query?.length && !searchPending && !searchObjects?.length"
        cogDataId="results">
      </dg-scan-object-search-result>
    </div>

    <div *ngIf="cart?.length">
      <dg-scan-object-selection-cart
        [selectedObjects]="cart"
        cogDataId="selection-cart"
        [inheritDataId]="true"
        (removeFromCart)="removeFromCart($event)">
      </dg-scan-object-selection-cart>
    </div>
  </div>
</ng-template>

<ng-template cohFormSectionViewMode>
  <dg-scan-selection-summary
    [selectedObjects]="cart"
    cogDataId="selection-summary"
    [inheritDataId]="true">
  </dg-scan-selection-summary>
</ng-template>

<ng-template cohFormSectionViewCta>
  <button
    mat-icon-button
    class="edit-form-section"
    type="button"
    cogDataId="object-edit-form-section"
    [inheritDataId]="true"
    (click)="editObjects()">
    <cog-icon shape="edit!outline"></cog-icon>
  </button>
</ng-template>

<ng-template cohFormSectionNavigator>
  <button
    mat-flat-button
    [disabled]="!cart?.length || cart.length > maxObjectsAllowed"
    class="save-form-section"
    type="button"
    color="primary"
    cogDataId="save-form-section"
    (click)="updateValue()">
    {{ 'dg.dc.scan.nextSettings' | translate }}
  </button>
</ng-template>

<ng-template #spinner>
  <cog-spinner></cog-spinner>
</ng-template>
