import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { McmClusterServiceApi } from '@cohesity/api/private';
import { SecurityAdvisorServiceApi, SecurityCriteriaResult } from '@cohesity/api/secops';
import { FiltersComponent } from '@cohesity/helix';
import { AutoDestroyable, FilterConfig, FilterValue, FilterValuesList } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import { UIRouterGlobals } from '@uirouter/core';
import { groupBy, isEqual, map, pick, size, uniq, uniqBy } from 'lodash-es';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { debounceTime, map as rxjsMap } from 'rxjs/operators';

import { IssueModel, ListIssuesDialogParams } from '../../models';
import { PostureAdvisorService } from '../../services';

/**
 * Parameters to help creating the data structure for cog-value-property-filter
 */
const filterSettings: FilterConfig[] = [
  {
    name: 'cluster',
    prefix: '',
    values: [],
  },
];

@Component({
  selector: 'dg-pa-issues',
  templateUrl: './issues.component.html',
  styleUrls: ['./issues.component.scss'],
})
export class IssuesComponent extends AutoDestroyable implements AfterViewInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  /**
   * Filters data for cog-value-property-filter [filterValues]
   */
  filterValuesList: FilterValuesList = {};

  /**
   * True if screen has to be in loading state.
   */
  loading = true;

  /**
   * Created to be used as pipe and subscribe FilterComponent events
   */
  @ViewChild(FiltersComponent) private filtersComponent: FiltersComponent;

  /**
   * Model used to show issues in the cards
   */
  filteredIssues: IssueModel[] = [];

  /**
   * Stores the issue object that is expanded.
   */
  expandedIssue: IssueModel;

  /**
   * Holds issue column names for standard presentation.
   */
  columns = ['securityAdvisor.allIssues.issueName', 'securityAdvisor.allIssues.occursOn'];

  /**
   * Cluster region name selected.
   */
  @Input() clusterRegionName: string;

  /**
   * ClusterId row data selected
   */
  @Input() clusterId: number;

  /**
   * Cluster name used only inside dialog component
   */
  clusterName: string;

  /**
   * Query Param which can be provided by another screen
   */
  issueName: string;

  /**
   * Name of issue to be expanded.
   */
  expandIssueName: string;

  /**
   * Control if the columns will be displayed
   */
  showColumns = false;

  /**
   * Control if the occurs on data will be displayed
   */
  showOccursOnInfo = false;

  /**
   * Total clusters present in the issues
   */
  totalClusters = 0;

  /**
   * Page size to be used on Mat-Paginator
   */
  pageSize = 5;

  /**
   * Used to control page index when customer change page after insert some search
   */
  lastSearch = '';

  /**
   * Used to control page index when customer change page after insert some search
   */
  lastClusters: string[];

  /**
   * Subject to update pageIndex value
   */
  private pageIndex = new BehaviorSubject<number>(0);

  /**
   * Observable of page index change.
   */
  private pageIndex$ = this.pageIndex.asObservable();

  private globalRouterParams: {[key: string]: string};

  /**
   * Constructor
   */
  constructor(
    private elementRef: ElementRef,
    private translate: TranslateService,
    private securityAdvisorServiceApi: SecurityAdvisorServiceApi,
    private clusterService: McmClusterServiceApi,
    readonly postureAdvisorService: PostureAdvisorService,
    private uiRouterGlobals: UIRouterGlobals,
  ) {
    super();
    this.filterValuesList = new FilterValuesList(filterSettings, this.translate);
    this.expandIssueName = this.uiRouterGlobals.params?.expandIssueName;
  }

  /**
   * AfterViewInit lifecycle hook.
   */
  ngAfterViewInit() {
    this.globalRouterParams = this.postureAdvisorService.getRouterGlobalParams();

    combineLatest(
      [this.loadData(), this.clusterService.getUpgradeInfo(), this.filtersComponent.filterValues$, this.pageIndex$]
    ).pipe(
      debounceTime(500),
      rxjsMap(([securityScoreResult, {upgradeInfo}, filters]) => {
        // The property "this.filterValuesList" belongs to the component "cog-value-property-filter".
        // Whenever the value is updated, the "ngOnChanges" method of "cog-value-property-filter" is invoked.
        // Based on the label, it selects the corresponding values. However, if there are multiple labels with the same
        // name and a single checkbox is selected, it selects all the labels with the same name,
        // which is not the expected behavior.
        if (!this.filterValuesList.cluster) {
          this.filterValuesList = {
            cluster: uniqBy(
              securityScoreResult?.map(score => ({
                label: score.clusterName,
                value: score.clusterId?.toString(),
              })),
              'value'
            ),
          };
        }

        // Apply filtering
        const getFilter = (key: string) => filters?.find(value => value.key === key)?.value;
        const issueNameFilter = getFilter('issueName');
        const clusterFilter: FilterValue[] = getFilter('clusterName');

        if (clusterFilter && clusterFilter.length > 0) {
          const clusterNames: string[] = clusterFilter?.map(f => f.value);
          securityScoreResult = securityScoreResult.filter(score => clusterNames.includes(score.clusterId?.toString()));

          if (!isEqual(clusterNames, this.lastClusters)) {
            this.lastClusters = clusterNames;
            this.paginator.firstPage();
          }
        }

        if (issueNameFilter) {
          securityScoreResult = securityScoreResult.filter(score =>
            // We use description field's value for name, so compare with it
            score.description.toLocaleLowerCase().includes((issueNameFilter as string).toLocaleLowerCase())
          );

          if (!isEqual(this.lastSearch, issueNameFilter)) {
            this.lastSearch = issueNameFilter;
            this.paginator.firstPage();
          }
        }

        // Convert scores array to issues array
        this.totalClusters = clusterFilter?.length > 0 ?
          uniq(map(securityScoreResult, 'clusterId')).length :
          (upgradeInfo?.length || 0);

        const groupedScores = groupBy(securityScoreResult, 'name');
        const issues = map(groupedScores, (value, _) => {
          // Consider only failed results for cluster with issues
          const failedScans = value.filter(r => r.scanResult === 'Failed');

          // When multiple clusters are listed for an issue in the "All Issues" tab,
          // the "Learn more" link should use the help link from the latest cluster among them
          // instead of the first document URL link.
          // For example, If we sort the below URLs in descending order then 7.0 documentUrl will be on top.
          // https://docs.cohesity.com/6_3/Web/UserGuide/Content/CLI/AddLogServerCLI.htm
          // https://docs.cohesity.com/7.0/Web/UserGuide/Content/CLI/AddLogServerCLI.htm

          const sortedValues = value.slice().sort((a, b) => b.documentUrl.localeCompare(a.documentUrl));

          const clusterWithIssues = uniqBy(
            failedScans.map(i => pick(i, ['clusterName', 'clusterId'])),
            'clusterId'
          );
          const issue: IssueModel = {
            name: sortedValues[0].name,
            // Use description for issueName
            // Cannot be used for matching as description can be different for the same name.
            issueName: sortedValues[0].description,
            // Replace fixed cohesity docs website URL with dynamic value from Routes
            documentUrl: sortedValues[0].documentUrl?.replace(
              this.postureAdvisorService.cohesityDocsUrl, this.postureAdvisorService.docsUrl),
            suggestion: sortedValues[0].suggestion,
            occursOn: size(clusterWithIssues),
            clusterList: clusterWithIssues as IssueModel['clusterList'],
            totalClusters: this.totalClusters,
          };
          return issue;
        })?.filter(s => s.occursOn > 0).sort((a, b) => b.occursOn - a.occursOn);

        this.paginator.length = size(issues);

        this.expandedIssue = issues.find(issue => issue.name === this.expandIssueName);
        if (!this.clusterRegionName && !this.clusterId) {
          this.filteredIssues = [...issues];
        } else {
          this.filteredIssues = [...issues].slice(
            this.paginator.pageIndex * this.pageSize,
            this.paginator.pageIndex * this.paginator.pageSize + this.paginator.pageSize
          );
        }

        if (this.clusterId && size(this.filteredIssues) > 0) {
          this.clusterName = this.filteredIssues[0].clusterList[0].clusterName;
        }

        if (
          (!this.clusterId && this.clusterRegionName && this.totalClusters > 1) ||
          (!this.clusterId && !this.clusterRegionName)
        ) {
          this.showColumns = true;
          this.showOccursOnInfo = true;
        }

        this.loading = false;
      }),
      this.untilDestroy(),
    ).subscribe(() => {
      if (this.expandIssueName) {
        // Need to scroll in the next event loop after the issues are rendered.
        setTimeout(() => {
          this.elementRef.nativeElement
            .querySelector('.issue-row-expanded')?.scrollIntoView({ behavior: 'smooth', block: 'start' });
          this.expandIssueName = null;
        }, 0);
      }
    });
  }

  /**
   * Load issues data.
   */
  loadData(): Observable<SecurityCriteriaResult[]> {
    this.loading = true;

    this.issueName = this.globalRouterParams.issueName;

    const { city, state, country } = this.globalRouterParams;
    const params: ListIssuesDialogParams = {country, state};

    if (this.clusterId) {
      params.clusterId = this.clusterId;
    } else if (!country && this.clusterRegionName) {
      params.country = this.clusterRegionName;
    } else if (!state && !params.country && this.clusterRegionName) {
      params.state = this.clusterRegionName;
    } else if (!city && !params.state && !params.country && this.clusterRegionName) {
      params.city = this.clusterRegionName;
    }

    return this.securityAdvisorServiceApi
      .GetScanIssuesSummary(params)
      .pipe(
        this.untilDestroy(),
        rxjsMap(r => r.securityCriteriaResultList),
      );
  }

  /**
   * Update page number.
   *
   * @param   page   Page Event
   */
  onPageChange(page: PageEvent): void {
    this.pageIndex.next(page.pageIndex);
  }

  /**
   * Toggles the expand of a issue.
   *
   * @param issue object.
   */
  toggleExpand(issue: IssueModel) {
    if (this.expandedIssue === issue) {
      this.expandedIssue = undefined;
    } else {
      this.expandedIssue = issue;
    }
  }
}
