import { SelectionModel } from '@angular/cdk/collections';
import { CdkColumnDef, CdkHeaderRowDef, CdkRowDef } from '@angular/cdk/table';
import {
  AfterContentInit,
  Component,
  ContentChild,
  ContentChildren,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatLegacyCheckbox as MatCheckbox, MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatLegacyRadioButton as MatRadioButton } from '@angular/material/legacy-radio';
import { MatSort, SortDirection } from '@angular/material/sort';
import { MatLegacyTable as MatTable } from '@angular/material/legacy-table';
import { get } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { HelixIntlService } from '../../helix-intl.service';
import { DataFilterValue } from '../data-filters';
import { TableDataSource } from './table-data-source';

/**
 * Default table page size
 */
const defaultPageSize = 10;

/**
 * Default table page size options
 */
export const defaultPageSizeOptions = [5, 10, 25, 50, 100];

/**
 * Default cache keys
 */
export enum TableCacheKey {
  PageSize = 'PageSize',
  SortColumn = 'SortColumn',
  // eslint-disable-next-line @typescript-eslint/no-shadow
  SortDirection = 'SortDirection',
}

/**
 * Type definition for CanSelectAnyRowsFn callback
 */
export type CanSelectAnyRowsFn = () => boolean;

/**
 * Type definition for CanSelectRowFn callback
 */
export type CanSelectRowFn<T> = (row: T) => boolean;

/**
 * Type definition for isAllSelectedFn callback
 */
export type IsAllSelectedFn = () => boolean;

/**
 * Type definition for ToggleSelectAllFn callback
 */
export type ToggleSelectAllFn = (event?: MatCheckboxChange) => void;

/**
 * This component assists with configuring and using an angular material data table.
 * It provides utilities and helper directiveas to automatically configure the a
 * TableDataSource object which supports client-side, filtering, paging, and sorting.
 *
 * @example
 *   Simple Usage:
 *   <cog-table name="exampleTable" [source]="data" [selection]="tableSelection">
 *     <table mat-table>
 *        ...table contents
 *     </table>
 *   </cog-table>
 */
@Component({
  selector: 'cog-table',
  templateUrl: './table.component.html',
})
export class TableComponent<T> implements AfterContentInit, OnChanges, OnDestroy {
  /**
   * Indicates whether the table implement is using the flex classes or the standard html
   * table elements.
   */
  @HostBinding('class.flex-table') get isFlex(): boolean {
    // _isNativeHtmlTable is a private property of MatTable, but it's the
    // cleanest way to determine if this is a flex table or a native html table.
    return this.table && !(this.table as any)._isNativeHtmlTable;
  }

  /**
   * Configures the table to top align row content. This is useful for tables that
   * may need to show multiple lines of text in rows. This will not top align the
   * header rows.
   */
  @HostBinding('class.top-align') @Input() topAlign = false;
  /**
   * Adds horizontal cell padding to the table cells. Usually this can be off, but
   * it is especially useful for tables that need to add additional styling such as
   * backgrounds or borders to individual columns.
   */
  @HostBinding('class.cell-padding') @Input() useCellPadding = false;

  /**
   * Optional user-provided function to determine whether the select all button
   * should be enabled or not. This can be used to add custom selection logic to
   * a table. The function should return true if the select all button should be
   * enabled, false if not.
   */
  @Input() canSelectAnyRowsFn: CanSelectAnyRowsFn;

  /**
   * Optional user-provided function to determine whether a row can be selected
   * or not. This can be used to add custom selection logic to a table.
   * The function accepts a row parameter and return true if it should be enabled
   * false to disable it.
   */
  @Input() canSelectRowFn: CanSelectRowFn<T>;

  /**
   * The data source for the table. Eventually, this will be able to be swapped
   * out for a version that support server side filtering.
   */
  @Input() dataSource: TableDataSource<T> = new TableDataSource([]);

  /**
   * A list of filters currently applied to the table.
   */
  @Input() filters: DataFilterValue<any, T>[] = [];

  /**
   * Optional user-provided function for determining if all items on the page
   * are selected. This can be used to add custom selection logic to a table.
   * The function should return true if all items are selected, false otherwise.
   */
  @Input() isAllSelectedFn: IsAllSelectedFn;

  /**
   * Sets a name for the table. This name will be used to persist the pagination
   * preferences in local storage. And will also be used to generate ids for
   * pagination buttons
   */
  @Input() name: string;

  /**
   * If this is set to true, the tree should maintain the selection whenever data is changed
   * or reloaded.
   */
  @Input() persistSelection = false;

  /**
   * A selection model for the table based on the @angular/cdk selectionModel
   * This supports single or multiple selection.
   */
  @Input() selection: SelectionModel<T>;

  /**
   * If this is set to true, the selection coloum will be marked as sticky
   */
  @Input() stickySelection = false;

  /**
   * An array of data to use as a source for the table. The table is currently
   * configured for a client side filter. In the future, a complete data source
   * could be taken as an input which would support server side filtering, etc...
   */
  @Input() source: T[] = [];

  /**
   * Optional user-provided function to toggle the select all button.
   * This can be used to add custom selection logic to a table.
   */
  @Input() toggleSelectAllFn: ToggleSelectAllFn;

  /**
   * Function to return tooltip for select all checkbox.
   */
  @Input() selectAllRowsTooltipFn: () => string;

  /**
   * Function to return tooltip for a row checkbox.
   */
  @Input() selectRowTooltipFn: (row: T) => string;

  /**
   * Disables table sort.
   */
  @Input() disableSort = false;

  /**
   * Used for updating table whenever the value changes (the value is a timestamp)
   */
  @Input() updateTable: number;

  /**
   * The viewport size do we want to add horizontal scrolling to the component
   */
  @Input() reflowScrollSize: 'xs' | 'sm' | 'disabled' | '' = '';

  /**
   * Conditionally add horizontal scrolling at extra small viewport
   */
  @HostBinding('class.reflow-table-x-scrollable@xs')
  get reflowScrollAtXs() {
    return this.reflowScrollSize === 'xs' || this.reflowScrollSize === '' ;
  }

  /**
   * Conditionally add horizontal scrolling at small viewport
   */
  @HostBinding('class.reflow-table-x-scrollable@sm')
  get reflowScrollAtSm() {
    return this.reflowScrollSize === 'sm';
  }

  /**
   * Conditionally disable the reflow horizontal scrolling
   */
  @HostBinding('class.reflow-table-x-scrollable-disabled')
  get reflowScrollDisabled() {
    return this.reflowScrollSize === 'disabled';
  }

  /**
   * When data rendering is complete, emit an event.
   * This can be used for pre-selecting values in table.
   * Capture this event in host component and add selection values in SelectionModel.
   */
  @Output() renderedDataChange = new EventEmitter<T[]>();

  /**
   * An array of the data currently rendered on the page, after filtering and
   * pagination are applied.
   */
  renderedData: T[] = [];

  /**
   * This flag is set to true after ngAfterContenxt has been run. It prevents
   * the data source from being initialized before everything is ready.
   */
  private contentInitialized = false;

  /**
   * Used for subscription clean up for data source and paginator.
   */
  private destroy = new Subject<void>();

  /**
   * All header row definitions applied to the table. If the table has a selection model,
   * a select column will be added to the first header row def.
   */
  @ContentChildren(CdkHeaderRowDef, { descendants: true }) private headerRowDefs: QueryList<CdkHeaderRowDef>;

  /**
   * Optional. If present, it will automatically configure the paginator
   * to work with the data table
   *
   * @example
   * <coh-table>
   *   <table mat-table>...</table>
   *   <mat-paginator></mat-paginator>
   * </coh-table>
   */
  @ContentChild(MatPaginator, { static: false }) private paginator: MatPaginator;

  /**
   * All row definitions applied to the table. If the table has a selection model,
   * a select column will be added to the first row def.
   */
  @ContentChildren(CdkRowDef, { descendants: true }) private rowDefs: QueryList<CdkRowDef<any>>;

  /**
   * Column def for a select column that can be dynamically added to any table with
   * a selection model set.
   */
  @ViewChild('selectColumn', { read: CdkColumnDef, static: true }) private selectColumn: CdkColumnDef;

  /**
   * Optional. When the mat-sort directive is added to the table, this table
   * will automatically configure it to work with the data source. To enable
   * sorting on a column, add the mat-sort-header directive to the header.
   *
   * @example
   * <coh-table>
   *   <table mat-table matSort>
   *     ...
   *     <ng-container matColumnDef="name">
   *       <th mat-header-cell *matHeaderCellDef mat-sort-header>name</th>
   *       <td mat-cell *matCellDef="let row">{{row.name}}</td>
   *     </ng-container>
   *     ...
   *   </table>
   * </coh-table>
   */
  @ContentChild(MatSort, { static: false }) private sort: MatSort;

  /**
   * A reference to the material data table.
   */
  @ContentChild(MatTable, { static: false }) private table: MatTable<T>;

  /**
   * Indicates if table has paginator component and page sizes are defined by the user.
   */
  private hasPageSizeOptions = false;

  /**
   * Indicates if max page size option is selected. This flag will be used to persist max page size
   * selection when max page size number changes.
   */
  private maxPageSizeOptionSelected = false;

  /**
   * Track the last index that the user has selected so that it can be uesd with
   * shift-click to select multiple rows at once.
   */
  private lastSelectedIndex = -1;

  /**
   * Track whether the shift key is currently pressed. Use this during the mat checkbox event to
   * determine whether to select multiple options.
   */
  shiftKeyDown = false;

  constructor( public intl: HelixIntlService) {}

  /**
   * Generates localStorage key.
   *
   * @param   key   Key of item.
   * @return  Full string key.
   */
  genKey(key: TableCacheKey): string {
    return `cog.table.${this.name}.${key}`;
  }

  /**
   * Get item in localStorage by key.
   *
   * @param   key   Key of item.
   * @return  Saved item in localStorage.
   */
  getCachedItem<S extends string>(key: TableCacheKey): S {
    if (this.name) {
      return localStorage.getItem(this.genKey(key)) as S;
    }
  }

  /**
   * Set item in localStorage by key.
   *
   * @param   key   Key of item.
   * @param   value Value of item to be saved in localStorage.
   */
  setCachedItem(key: TableCacheKey, value: string) {
    if (this.name) {
      const fullKey = this.genKey(key);

      if (value) {
        localStorage.setItem(fullKey, value);
      } else {
        localStorage.removeItem(fullKey);
      }
    }
  }

  /**
   * Determine if the select all button should be disabled or not.
   *
   * @return  True if select all should be enabled false if not.
   */
  canSelectAnyRows(): boolean {
    if (this.canSelectAnyRowsFn) {
      return this.canSelectAnyRowsFn();
    }
    return true;
  }

  /**
   * Determine if row selection should be enabled or not.
   *
   * @param   row   The current row.
   * @return  True if the row can be selected, false if not.
   */
  canSelectRow(row: T): boolean {
    if (this.canSelectRowFn) {
      return this.canSelectRowFn(row);
    }
    return true;
  }

  /**
   * Triggers destroy to clean up subscriptions.
   */
  cleanUpSubscriptions() {
    this.destroy.next();
  }

  /**
   * Check if all items are selected
   *
   * @return  true if the number of selected items matches the number of items
   *          current rendered.
   */
  isAllSelected(): boolean {
    if (this.isAllSelectedFn) {
      return this.isAllSelectedFn();
    }
    const numSelected = this.selection.selected.length;
    const numRows = this.renderedData.length;
    return numSelected === numRows;
  }

  /**
   * Initialize the Content Children to configure the table and data source
   */
  ngAfterContentInit() {
    if (!this.table) {
      throw new Error('No Material Table Found');
    }

    if (this.paginator) {
      // Sets default pageSizeOptions if user does not define it, pageSize
      // will be set to 50 already so cannot be used for checking
      if (!this.paginator.pageSizeOptions || !this.paginator.pageSizeOptions.length) {
        this.setDefaultPageOptions();
      } else {
        this.hasPageSizeOptions = true;
      }

      this.displayCachedPage();
    }

    this.contentInitialized = true;
    this.updateColumnDefs();
    this.initializeDataSource();
  }

  /**
   * Displays previously selected cached page from local storage if available.
   */
  private displayCachedPage() {
    if (this.paginator) {
      const { pageSizeOptions, pageSize } = this.paginator;
      const cachedPageSize = +this.getCachedItem(TableCacheKey.PageSize);
      const hasCachedPage = pageSizeOptions.includes(cachedPageSize);

      let selectedPageSize = pageSize || defaultPageSize;
      if (cachedPageSize && hasCachedPage) {
        selectedPageSize = cachedPageSize;
      } else if (this.maxPageSizeOptionSelected) {
        selectedPageSize = pageSizeOptions[pageSizeOptions.length - 1];
      }

      this.paginator.pageSize = selectedPageSize;
    }
  }

  /**
   * Sets default page options when paginator component exists and no page options are provided.
   */
  private setDefaultPageOptions() {
    if (this.paginator && !this.hasPageSizeOptions) {
      const dataLength = this.paginator.length;
      const hasMaxLengthOption = defaultPageSizeOptions.every(size => size < dataLength);
      this.paginator.pageSizeOptions = hasMaxLengthOption
        ? [...defaultPageSizeOptions, dataLength]
        : defaultPageSizeOptions;
    }
  }

  /**
   * Listen for changes to the component inputs and make updates as needed
   *
   * @param   changes   summary of input changes
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.dataSource) {
      if (changes.dataSource.previousValue) {
        // Clear the old data source and subscriptions
        changes.dataSource.previousValue.disconnect();
        this.cleanUpSubscriptions();
      }
      this.initializeDataSource();
    }

    if (changes.selection) {
      this.updateColumnDefs();
    }

    // If the source has changed, just update the data source
    if (changes.source || changes.updateTable) {
      this.dataSource.data = this.source;
    }

    // If the filters have changed, we need to clean up the old ones and then
    // configure a new listener.
    if (changes.filters) {
      this.dataSource.dataFilters = this.filters;
    }
  }

  /**
   * Disconnect from the data source.
   */
  ngOnDestroy() {
    // This avoids selection column not defined error
    // It removes selection column that was added as part of ngAfterContentInit
    if (this.selection) {
      this.removeSelectColumn();
    }
    this.cleanUpSubscriptions();
    this.dataSource.disconnect();
  }

  /**
   * Toggles the select all checkbox
   *
   * @param   event   Optional mat checkbox event. If passed, its used to determine
   *                  if checkbox is checked or not.
   */
  toggleSelectAll(event?: MatCheckboxChange) {
    if (this.toggleSelectAllFn) {
      this.toggleSelectAllFn(event);
    } else {
      const clearSelection = event ? !event.checked : this.isAllSelected();

      if (clearSelection) {
        this.selection.clear();
      } else {
        const newSelection = this.renderedData.filter(row => this.canSelectRow(row));

        this.selection.select(...newSelection);
      }
    }
  }

  /**
   * Toggles a row, applying either single select or shift select as needed
   *
   * @param row The row to select
   * @param index The row's index
   * @param shiftSelect Whether to apply shift select, taken from the last previously selected index.
   */
  toggleRow(row: T, index: number, shiftSelect: boolean = false ) {
    if (this.lastSelectedIndex === -1 || !shiftSelect || this.lastSelectedIndex === index) {
      this.selection.toggle(row);
    } else {
      const select = !this.selection.isSelected(row);
      const start = Math.min(this.lastSelectedIndex, index);
      const end = Math.max(this.lastSelectedIndex, index);
      const rows = this.renderedData.slice(start, end + 1).filter(r => this.canSelectRow(r));
      if (select) {
        this.selection.select(...rows);
      } else {
        this.selection.deselect(...rows);
      }
    }
    this.lastSelectedIndex = index;
  }

  /**
   * Stops event propagation to avoid triggering row click if table supports clickable row.
   *
   * @param  event   Mouse click event.
   */
  stopPropagation(event: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }
  }

  /**
   * Configure the datasource for the table.
   */
  private initializeDataSource() {
    // Don't do anything if this gets called before the component has finished
    // initializing.
    if (!this.table || !this.contentInitialized || !this.dataSource) {
      return;
    }

    // Connects to the data source and configures a subscription to keep track of
    // the currently rendered data in the table.
    this.dataSource
      .connect()
      .pipe(takeUntil(this.destroy))
      .subscribe(data => {
        // Clear the selection when the visible data changes
        if (this.selection && !this.persistSelection) {
          this.selection.clear();
        }
        this.renderedData = data;

        this.setDefaultPageOptions();
        this.displayCachedPage();

        // Emit event after data rendering is complete
        this.renderedDataChange.emit(this.renderedData);
        this.lastSelectedIndex = -1;
      });

    // Update column definitions when columns change
    this.headerRowDefs.changes.pipe(
      takeUntil(this.destroy)
    ).subscribe(() => this.updateColumnDefs());

    // If the paginator is present, it needs to be configured. Use the
    if (this.paginator) {
      // Only set the paginator on the datasource if it's enabled.
      this.dataSource.paginator = this.paginator.disabled ? undefined : this.paginator;
      this.paginator.page.pipe(
        takeUntil(this.destroy)
      ).subscribe((page: PageEvent) => {
        const { pageSize } = page;

        this.maxPageSizeOptionSelected = !this.hasPageSizeOptions && !defaultPageSizeOptions.includes(pageSize);

        this.setCachedItem(TableCacheKey.PageSize, pageSize.toString());
      });
    }

    if (!this.disableSort && this.sort) {
      const storedSortColumn = this.getCachedItem(TableCacheKey.SortColumn);
      const storedSortOrder: SortDirection = this.getCachedItem(TableCacheKey.SortDirection);

      if (storedSortColumn && storedSortOrder) {
        this.sort.active = storedSortColumn;
        this.sort.direction = storedSortOrder;
      }

      this.sort.sortChange
        .pipe(
          takeUntil(this.destroy)
        ).subscribe(({ active, direction }) => {
          this.setCachedItem(TableCacheKey.SortColumn, active);
          this.setCachedItem(TableCacheKey.SortDirection, direction);
        });

      this.dataSource.sort = this.sort;
    }

    this.table.dataSource = this.dataSource;

    // Certain cases cause the table to not render it's data correctly after it has been initialized. Forcing a call to
    // ngAfterContentChecked after we set the dataSource forces the rendering code to re-run and make everything work.
    setTimeout(() => this.table.ngAfterContentChecked());
  }

  /**
   * Add or remove the select column definition to the table object. If selection is enabled,
   * this will add the column def and update the row definitions to include the column.
   * If selection is not enabled, it will ensure that they are not include in the table.
   */
  private updateColumnDefs() {
    if (!(this.selectColumn instanceof CdkColumnDef) || !this.table) {
      return;
    }

    this.selection ? this.addSelectColumn() : this.removeSelectColumn();
  }

  /**
   * Gets current row and header column defs
   *
   * @returns Array containing row and header columns
   */
  private getColumnDef() {
    const rowColumns = get(this.rowDefs, 'first.columns', []) as string[];
    const headerRowColumns = get(this.headerRowDefs, 'first.columns', []) as string[];
    return [rowColumns, headerRowColumns];
  }

  /**
   * Programmatically adds a custom column definition to the table.
   *
   * @param def The custom column definition.
   */
  addColumnDef(def: CdkColumnDef): void {
    this.table.addColumnDef(def);
  }

  /**
   * Adds Select column to row and header when Selection is enabled
   */
  private addSelectColumn() {
    const [rowColumns, headerRowColumns] = this.getColumnDef();
    this.table.addColumnDef(this.selectColumn);

    if (!rowColumns.includes('select')) {
      rowColumns.unshift('select');
    }

    if (!headerRowColumns.includes('select')) {
      headerRowColumns.unshift('select');
    }
  }

  /**
   * Removes Select column to row and header while taking table off the DOM
   */
  private removeSelectColumn() {
    const [rowColumns, headerRowColumns] = this.getColumnDef();
    this.table.removeColumnDef(this.selectColumn);

    if (rowColumns.includes('select')) {
      rowColumns.splice(0, 1);
    }

    if (headerRowColumns.includes('select')) {
      headerRowColumns.splice(0, 1);
    }
  }

  /**
   * Get the aria-label for the native checkbox or radio input for the table select.
   *
   * This function is a hack fix to temporarily give all checkbox or radio inputs
   * the correct aria-label by assuming the next cell is a cell with the name
   * of the row.
   *
   * @param selectComponent The Angular select component.
   * @return The string aria-label.
   */
  getSelectAriaLabel(selectComponent: MatCheckbox | MatRadioButton) {
    // Get the underlying native checkbox or radio
    const selectElement = document.getElementById(selectComponent.inputId);

    // Get the next sibling table cell
    const node = selectElement?.closest('td')?.nextElementSibling as HTMLElement;

    // Get the first line of inner text for that row.
    const ariaLabelName = node?.innerText?.split('\n')[0];

    // Return the aria label
    return `${this.intl.selectRow}${ariaLabelName ? ` - ${ariaLabelName}` : ''}`;
  }
}
