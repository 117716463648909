/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityApiConfiguration as __Configuration } from '../cohesity-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { UpdateAppInstanceSettingsParameters } from '../models/update-app-instance-settings-parameters';
import { AppInstance } from '../models/app-instance';
import { AppInstanceIdParameter } from '../models/app-instance-id-parameter';
import { LaunchAppInstance } from '../models/launch-app-instance';
import { UpdateAppInstanceStateParameters } from '../models/update-app-instance-state-parameters';
@Injectable({
  providedIn: 'root',
})
class AppInstanceService extends __BaseService {
  static readonly UpdateAppInstanceSettingsPath = '/public/appInstanceSettings/{appInstanceId}';
  static readonly GetAppInstancesPath = '/public/appInstances';
  static readonly LaunchAppInstancePath = '/public/appInstances';
  static readonly UpdateAppInstanceStatePath = '/public/appInstances/{appInstanceId}/states';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Updates app instance settings.
   *
   * Changes the settings of the app instance.
   * @param params The `AppInstanceService.UpdateAppInstanceSettingsParams` containing the following parameters:
   *
   * - `body`: Request to update app instance settings.
   *
   * - `appInstanceId`: Specifies the app instance Id.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  UpdateAppInstanceSettingsResponse(params: AppInstanceService.UpdateAppInstanceSettingsParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/public/appInstanceSettings/${encodeURIComponent(params.appInstanceId)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Updates app instance settings.
   *
   * Changes the settings of the app instance.
   * @param params The `AppInstanceService.UpdateAppInstanceSettingsParams` containing the following parameters:
   *
   * - `body`: Request to update app instance settings.
   *
   * - `appInstanceId`: Specifies the app instance Id.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  UpdateAppInstanceSettings(params: AppInstanceService.UpdateAppInstanceSettingsParams): __Observable<null> {
    return this.UpdateAppInstanceSettingsResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Lists the app instances.
   *
   * Api provides the list of the app instances. Instances can be in different
   * states including stopped.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return GetAppInstancesResponse specifies response for getting apps
   */
  GetAppInstancesResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<Array<AppInstance>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/appInstances`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AppInstance>>;
      })
    );
  }
  /**
   * Lists the app instances.
   *
   * Api provides the list of the app instances. Instances can be in different
   * states including stopped.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return GetAppInstancesResponse specifies response for getting apps
   */
  GetAppInstances(regionId?: string,
    accessClusterId?: number): __Observable<Array<AppInstance>> {
    return this.GetAppInstancesResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as Array<AppInstance>)
    );
  }

  /**
   * Starts the application instance launch on the cluster.
   *
   * Only installed apps can be launched.
   * @param params The `AppInstanceService.LaunchAppInstanceParams` containing the following parameters:
   *
   * - `body`: Request to launch app.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  LaunchAppInstanceResponse(params: AppInstanceService.LaunchAppInstanceParams): __Observable<__StrictHttpResponse<AppInstanceIdParameter>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/appInstances`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AppInstanceIdParameter>;
      })
    );
  }
  /**
   * Starts the application instance launch on the cluster.
   *
   * Only installed apps can be launched.
   * @param params The `AppInstanceService.LaunchAppInstanceParams` containing the following parameters:
   *
   * - `body`: Request to launch app.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  LaunchAppInstance(params: AppInstanceService.LaunchAppInstanceParams): __Observable<AppInstanceIdParameter> {
    return this.LaunchAppInstanceResponse(params).pipe(
      __map(_r => _r.body as AppInstanceIdParameter)
    );
  }

  /**
   * Updates app instance state.
   *
   * Changes the state of the app instances.
   * @param params The `AppInstanceService.UpdateAppInstanceStateParams` containing the following parameters:
   *
   * - `body`: Request to update app instance state.
   *
   * - `appInstanceId`: Specifies the app instance Id.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  UpdateAppInstanceStateResponse(params: AppInstanceService.UpdateAppInstanceStateParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/public/appInstances/${encodeURIComponent(params.appInstanceId)}/states`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Updates app instance state.
   *
   * Changes the state of the app instances.
   * @param params The `AppInstanceService.UpdateAppInstanceStateParams` containing the following parameters:
   *
   * - `body`: Request to update app instance state.
   *
   * - `appInstanceId`: Specifies the app instance Id.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  UpdateAppInstanceState(params: AppInstanceService.UpdateAppInstanceStateParams): __Observable<null> {
    return this.UpdateAppInstanceStateResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module AppInstanceService {

  /**
   * Parameters for UpdateAppInstanceSettings
   */
  export interface UpdateAppInstanceSettingsParams {

    /**
     * Request to update app instance settings.
     */
    body: UpdateAppInstanceSettingsParameters;

    /**
     * Specifies the app instance Id.
     */
    appInstanceId: number;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for LaunchAppInstance
   */
  export interface LaunchAppInstanceParams {

    /**
     * Request to launch app.
     */
    body: LaunchAppInstance;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateAppInstanceState
   */
  export interface UpdateAppInstanceStateParams {

    /**
     * Request to update app instance state.
     */
    body: UpdateAppInstanceStateParameters;

    /**
     * Specifies the app instance Id.
     */
    appInstanceId: number;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { AppInstanceService }
