<div class="flex-row flex-row-sm">
  <div>
    <h2>MCM Flags</h2>
    <pre>isMcm: {{ isMcm }}
isMcmSaaS: {{ isMcmSaaS }}
isMcmOnPrem: {{ isMcmOnPrem }}
isMcmGovCloud: {{ isMcmGovCloud }}</pre>
  </div>


  <div>
    <h2>Helios Scope</h2>
    <pre>isGlobalScope: {{ isGlobalScope }}
isDmsScope: {{ isDmsScope }}
isCohesityServiceScope: {{ isCohesityServiceScope }}
isRpaasScope: {{ isRpaasScope }}
isDraasScope: {{ isDraasScope }}
isSecurityCenterScope: {{ isSecurityCenterScope }}
isSmartFilesScope: {{ isSmartFilesScope }}
isClusterScope: {{ isClusterScope }}
isAllClustersScope: {{ isAllClustersScope }}</pre>
  </div>


  <div>
    <h2>User Helpers</h2>
    <pre>isOrganizationEnabled: {{ isOrganizationEnabled }}
hasCompanyContext: {{ hasCompanyContext }}
getSalesforceCompany: {{ getSalesforceCompany }}
getUserTenantId: {{ getUserTenantId }}
getUserAnyTenantId: {{ getUserAnyTenantId }}
getStandaloneTenantId: {{ getStandaloneTenantId }}
getUserTenantName: {{ getUserTenantName }}
getMcmAccountId: {{ getMcmAccountId }}</pre>
  </div>

  <div>
    <h2>Platform</h2>
    <pre>isIbmBaaSEnabled: {{ isIbmBaaSEnabled }}
isOneHeliosAppliance: {{ isOneHeliosAppliance }}
IS_IBM_AQUA_ENV: {{ IS_IBM_AQUA_ENV }}</pre>
  </div>

</div>