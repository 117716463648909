<h4>{{ 'argus.policy.selectPattern' | translate }}</h4>

<div class="margin-bottom" *ngIf="!isLoading; else spinner">
  <div class="flex-column-sm" *ngIf="patterns.length; else noDataFound">

    <div class="flex-row-xs">
      <span>
        {{ 'argus.pattern.nPatternCount' | translate: {
          type: (resourceType.builtIn | resourceType),
          n: (builtInPatterns.length)
        } }}
      </span>
      <span>&#8226;</span>
      <span cogDataId="built-in-pattern-count">
        {{ 'nSelectedNg' | translate: { n: builtInPatternIds.size } }}
      </span>
    </div>

    <cog-banner status="info" *ngIf="maxBuildInPatterns">
      {{ 'argus.pattern.error.maxPatternSelectionCount' | translate: { maxCount: maxBuildInPatterns } }}
    </cog-banner>

    <mat-chip-list multiple cogDataId="built-in-pattern-chips">
      <mat-chip *ngFor="let pattern of builtInPatterns"
        [selected]="patternIds.includes(pattern.id)"
        (click)="toggleSelection(pattern)">
        {{ pattern.name }}
      </mat-chip>
    </mat-chip-list>

    <div class="flex-row-xs margin-top">
      <span>
        {{ 'argus.pattern.nPatternCount' | translate: {
          type: (resourceType.custom | resourceType),
          n: (customPatterns.length)
        } }}
      </span>
      <span>&#8226;</span>
      <span cogDataId="custom-pattern-count">{{ 'nSelectedNg' | translate: { n: customPatternIds.size } }}</span>
    </div>

    <cog-banner status="info" *ngIf="maxCustomPatterns">
      {{ 'argus.pattern.error.maxPatternSelectionCount' | translate: { maxCount: maxCustomPatterns } }}
    </cog-banner>

    <mat-chip-list multiple cogDataId="custom-pattern-chips">
      <mat-chip *ngFor="let pattern of customPatterns"
        [selected]="patternIds.includes(pattern.id)"
        (click)="toggleSelection(pattern)">
        {{ pattern.name }}
      </mat-chip>
    </mat-chip-list>
  </div>
</div>

<ng-template #noDataFound>
  <cog-blank-card message="{{ 'noPatternsFound' | translate }}"></cog-blank-card>
</ng-template>

<ng-template #spinner>
  <cog-spinner></cog-spinner>
</ng-template>
