import { Injectable } from '@angular/core';
import { ClusterMetadataDocsPurpose, ClusterMetadataRequest, PlatformServiceApi } from '@cohesity/api/v2';
import { getConfigByKey, IrisContext, IrisContextService, isMcm } from '@cohesity/iris-core';
import { AjaxHandlerService } from '@cohesity/utils';
import { isEmpty } from 'lodash-es';
import { combineLatest, Observable } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';

/**
 * SLA model
 */
export interface Sla {
  full: number;
  incremental: number;
  log?: number;
}

@Injectable({
  providedIn: 'root',
})
export class ClusterMetadataService {
  /**
   * Default SLA value for a cluster
   */
  defaultSla: Sla = { full: 120, incremental: 60, log: 60 };

  /**
   * Record of Docs URL by purpose
   * Note: Defaults need to be blank. They will be populated from the configuration file or metadata API
   */
  urlByPurpose: Partial<Record<ClusterMetadataDocsPurpose['purpose'], string>> = {
    APIDocs: '',
    BackupServiceConnectionDocs: '',
    HelpDocs: '',
    SupportDocs: '',
    LicenseDocs: '',
    DownloadCLIDocs: '',
  };

  constructor(
    private ajaxService: AjaxHandlerService,
    private platformServiceApi: PlatformServiceApi,
    private irisContextService: IrisContextService
  ) {}

  /**
   * Initialize defaults from metadata API
   */
  initializeMetadata() {
    combineLatest([this.irisContextService.irisContext$, this.fetchMetadata()]).subscribe(
      ([context, metadata]) => {
        this.populateDefaultsFromConfig(context);
        metadata?.docs?.filter(doc => doc.url).forEach(doc => (this.urlByPurpose[doc.purpose] = doc.url));

        if (metadata?.sla?.defaultSLA) {
          this.defaultSla = { full: metadata.sla.defaultSLA, incremental: metadata.sla.defaultSLA, log: 60 };
        }
      },
      error => this.ajaxService.handler(error)
    );
  }

  /**
   * Fetch cluster metadata
   *
   * @returns observable of cluster metadata
   */
  fetchMetadata(): Observable<ClusterMetadataRequest> {
    return this.irisContextService.irisContext$.pipe(
      filter(context => context.basicClusterInfo && !isEmpty(context.basicClusterInfo) && !isMcm(context)),
      take(1),
      switchMap(() => this.platformServiceApi.GetClusterMetadata())
    );
  }

  /**
   * Populate defaults based on the config file
   *
   * @param context Iris context
   */
  populateDefaultsFromConfig(context: IrisContext): void {
    this.urlByPurpose = getConfigByKey(context, 'metadata.docUrlsByPurpose', this.urlByPurpose);
    this.defaultSla = getConfigByKey(this.irisContextService.irisContext, 'metadata.defaultSla', {
      full: 120,
      incremental: 60,
      log: 60,
    });
  }

  /**
   * Get documentation URL based on usage purpose
   *
   * @param purpose purpose of the docs URL
   * @returns URL of the requested documentation
   */
  getDocUrl(purpose: ClusterMetadataDocsPurpose['purpose'] = 'HelpDocs'): string {
    return this.urlByPurpose[purpose];
  }

  /**
   * Default SLA values for a protection job
   *
   * @returns default SLA value
   */
  getDefaultSla(): Sla {
    return this.defaultSla;
  }
}
