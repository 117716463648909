import { NgModule } from '@angular/core';

import { ObjectTypeIconPipe } from './object-type-icon.pipe';

@NgModule({
  providers: [ObjectTypeIconPipe],
  exports: [ObjectTypeIconPipe],
  declarations: [ObjectTypeIconPipe],
})
export class ObjectTypeIconPipeModule {}
