<div class="icon-wrapper" *ngIf="objectIcon">
  <cog-icon
    [shape]="objectIcon"
    [matTooltip]="getIconTooltip(objectIcon) | translate"
    matTooltipPosition="above"></cog-icon>
</div>
<div class="detail">
  <span class="object-name user-data-xl"
    cogDataId="object-detail-{{object.name}}"
    [inheritDataId]="true">
    <span class="item-label" *ngIf="isEmailOrMailboxFolder">
      {{'label' | translate: {label: getLabelText() | translate} }}
    </span>
    {{object.name}}
    <span *ngIf="storageDomain || objectSize"
      class="icn-view"
      [cogPopover]="popoverTemplate"
      (click)="$event.stopPropagation()">
    </span>
  </span>
  <ng-container *ngIf="isEmailOrMailboxFolder">
    <ng-container *ngTemplateOutlet="emailAndFolderObjectDetailTemplate;"></ng-container>
  </ng-container>
  <ng-container *ngIf="!isEmailOrMailboxFolder">
    <ng-content select="coh-object-search-item-detail-line"></ng-content>
    <ul class="c-ul-inline no-margin mat-caption">
      <!-- Outlet to include additional info for the item. -->
      <ng-container *ngIf="!metadata">
        <ng-content></ng-content>
        <li *ngIf="source && sourceType">
          <span class="item-label">{{'label' | translate: {label: sourceType} }}</span>
          <span class="user-data-sm auto-truncate"
            [matTooltip]="source"
            matTooltipPosition="below">
            {{source}}
          </span>
        </li>
        <li *ngIf="channelName">
          <span class="item-label">{{'label' | translate: {label: 'Channel' | translate} }}</span>
          <span class="user-data-lg auto-truncate">{{channelName}}</span>
        </li>
        <li *ngIf="protectionGroupName">
          <span class="item-label">{{'label' | translate: {label: 'protectionGroup' | translate} }}</span>
          <span class="user-data-lg auto-truncate"
            [matTooltip]="protectionGroupName"
            matTooltipPosition="below">
            {{protectionGroupName}}
          </span>
        </li>
        <li *ngIf="object?.containerDbName && isOracleSource">
          <span class="item-label">{{'cdbNameColon' | translate }}</span>
          <span class="user-data-sm auto-truncate"
            [matTooltip]="object?.containerDbName"
            matTooltipPosition="below">
            {{object?.containerDbName}}
          </span>
        </li>
        <li *ngIf="lastBackupUsecs && !isContinuousProtected">
          <span>{{'label' | translate: {label: 'lastBackup' | translate} }}</span>
          <span>{{lastBackupUsecs | humanizeFromUsecs}}</span>
        </li>
        <li *ngIf="isContinuousProtected">
          <span>{{'continuouslyProtected' | translate}}</span>
        </li>
        <li *ngIf="objectSize">
          <span class="item-label">{{'label' | translate: {label: 'size' | translate} }}</span>
          <span class="user-data-lg auto-truncate">{{objectSize | byteSize}}</span>
        </li>
        <li *ngIf="ownerName">
          <span class="item-label">{{'label' | translate: {label: 'ownerName' | translate} }}</span>
          <span class="user-data-lg auto-truncate">{{ownerName}}</span>
        </li>
      </ng-container>
      <ng-container *ngIf="metadata">
        <li *ngFor="let item of metadata">
          <span class="item-label">{{'label' | translate: {label: item.label | translate} }}</span>
          <span *ngIf="item.type !== 'date'" class="user-data-md auto-truncate"
            [matTooltip]="item.value"
            matTooltipPosition="below">
            {{item.value}}
          </span>
          <span *ngIf="item.type === 'date'">{{item.value | humanizeFromUsecs}}</span>
        </li>
      </ng-container>
    </ul>
  </ng-container>
</div>

<coh-nav-item-menu *ngIf="actions"
  [ariaLabel]="'objectActionsMenu' | translate"
  cogDataId="menu-{{object.name}}"
  [inheritDataId]="true"
  [navItems]="actions"
  [menuId]="object.name"
  (click)="$event.stopPropagation()">
</coh-nav-item-menu>

<ng-template #popoverTemplate>
  <dl class="meta-popover c-dl c-dl-clean no-margin mat-caption">
    <ng-container *ngIf="storageDomain">
      <dt>{{'label' | translate: {label: 'storageDomain' | translate} }}</dt>
      <dd class="user-data-lg auto-truncate">{{storageDomain}}</dd>
    </ng-container>
    <ng-container *ngIf="objectSize">
      <dt>{{'label' | translate: {label: 'size' | translate} }}</dt>
      <dd>{{objectSize | byteSize}}</dd>
    </ng-container>
  </dl>
</ng-template>

<ng-template #emailAndFolderObjectDetailTemplate>
  <ul class="c-ul-inline no-margin mat-caption">
    <ng-container *ngIf="!isPresentInDmsScope">
      <li>
        <span class="item-label">{{'label' | translate: {label: 'mailbox' | translate} }}</span>
        <span class="user-data-lg auto-truncate"
          [matTooltip]="office365IndexedItem.parentSourceName"
          matTooltipPosition="below">
          {{office365IndexedItem.parentSourceName}}</span>
      </li>
    </ng-container>
    <li *ngIf="office365IndexedItem.folderRootType as folderRootType">
      <span class="item-label">{{'label' | translate: {label: 'type' | translate} }}</span>
      <span class="user-data-lg auto-truncate"
        [matTooltip]="folderRootType"
        matTooltipPosition="below">
        {{folderRootType}}</span>
    </li>
    <li *ngIf="office365IndexedItem.createdTimeSecs as createdTimeSecs">
      <span class="item-label">{{'label' | translate: {label: 'createdOn' | translate} }}</span>
      <span class="user-data-lg auto-truncate"
        [matTooltip]="createdTimeSecs"
        matTooltipPosition="below">
        {{createdTimeSecs | date: 'medium'}}</span>
    </li>
    <li *ngIf="office365IndexedItem.mailboxItemSenderAddress as senderAddress">
      <span class="item-label">{{'label' | translate: {label: 'from' | translate} }}</span>
      <span class="user-data-lg"
        [ngClass]="{'auto-truncate': office365IndexedItem.createdTimeSecs || office365IndexedItem.folderRootType}"
        [matTooltip]="senderAddress"
        matTooltipPosition="below">
        {{senderAddress}}
      </span>
    </li>
  </ul>
  <ng-container *ngIf="!isPresentInDmsScope">
    <ul class="c-ul clean no-margin mat-caption" *ngIf="office365IndexedItem.protectionGroupName as protectionGroupName">
      <li>
        <span class="item-label">{{'label' | translate: {label: 'protectionGroup' | translate} }}</span>
        <span class="user-data-lg auto-truncate"
          [matTooltip]="protectionGroupName"
          matTooltipPosition="below">
          {{protectionGroupName}}
        </span>
      </li>
    </ul>
  </ng-container>
  <ul class="c-ul clean no-margin mat-caption" *ngIf="office365IndexedItem.sanitizedPath as sanitizedPath">
    <li>
      <span class="item-label">{{'label' | translate: {label: 'path' | translate} }}</span>
      <span class="user-data-lg auto-truncate"
        [matTooltip]="sanitizedPath"
        matTooltipPosition="below">
        {{sanitizedPath}}
      </span>
    </li>
  </ul>
</ng-template>
