<ng-template cohFormSectionEditMode>
  <h2 class="settings-list-header" cogDataId="snapshot-title" [inheritDataId]="true">{{ 'settings' | translate }}</h2>

  <!-- scheduled scan options -->
  <div cogDataId="schedule-option" [inheritDataId]="true" *ngIf="isScheduleEnabled">
    <h5 cogDataId="title" [inheritDataId]="true">{{ 'dg.td.scanSchedule' | translate }}</h5>
    <mat-radio-group class="flex-column-sm" [value]="scheduleType" (change)="selectScheduleType($event.value)">
      <mat-radio-button *ngFor="let type of scheduleTypeEnum" [value]="type" [cogDataId]="type" [inheritDataId]="true">
        {{ type | scheduleScanType }}
      </mat-radio-button>
    </mat-radio-group>
  </div>

  <div class="margin-top" cogDataId="snapshot-selection" [inheritDataId]="true">
    <h5 cogDataId="title" [inheritDataId]="true">{{ 'dg.td.scan.snapshotWindow' | translate }}</h5>
    <mat-radio-group
      [disabled]="snapshotsLoading"
      class="flex-column"
      [value]="selectionType"
      (change)="selectScanType($event.value)">
      <mat-radio-button value="most-recent" cogDataId="most-recent" [inheritDataId]="true">
        {{ 'dg.td.scan.mostRecent' | translate }}
      </mat-radio-button>
      <!-- Hiding max count util it is supported -->
      <!-- <mat-radio-button value="snapshot-count">
        {{ 'dg.td.scan.runUntilCleanSnapshot' | translate }}
      </mat-radio-button>
      <div class="flex-row vertical-align margin-left-lg">
        <span matLine class="margin-right">{{ 'dg.td.scan.scanMaxOf' | translate }}</span>
        <mat-form-field class="no-margin-bottom">
          <input
            matInput
            type="number"
            [value]="maxSnapshotCount"
            (change)="snapshotCountChanged($event.value)"
            [disabled]="disableSnapshotCount"/>
          <span matSuffix>{{ 'snapshots' | translate }}</span>
        </mat-form-field>
      </div> -->
      <div class="flex-row baseline-align" cogDataId="date-range" [inheritDataId]="true">
        <mat-radio-button
          value="date-range"
          cogDataId="button"
          [inheritDataId]="true"
          [disabled]="disableDateRangeOption">
          <span class="margin-right">
            {{ 'dg.td.scan.dataRange' | translate }}
          </span>
        </mat-radio-button>
        <cog-date-range-input
          [value]="dateRange"
          (valueChange)="dateRangeChanged($event)"
          cogDataId="picker"
          [inheritDataId]="true"
          [useTimeframe]="true"
          [enableGuessTimeframe]="true"
          [enablePreciseDateRangeSelection]="true"
          [disabled]="disableDateRange || snapshotsLoading">
        </cog-date-range-input>
        <cog-spinner *ngIf="snapshotsLoading" size="sm"></cog-spinner>
      </div>
    </mat-radio-group>
    <cog-banner status="critical" *ngIf="formControl.hasError('maxSnapshotLimitReached')">
      <div class="margin-top-sm margin-bottom-sm">
        <div class="margin-bottom-sm">
          {{
            'dg.td.scan.maxSnapshotSelectionLimitReached'
              | translate: { selectedSnapshotCount: snapshotData?.length, snapshotLimit: maxAllowedSnapshot }
          }}
        </div>
        <button mat-stroked-button (click)="openSnapshotSummaryDialog()">
          {{ 'viewDetails' | translate }}
        </button>
      </div>
    </cog-banner>
  </div>
</ng-template>
