/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityV2ApiConfiguration as __Configuration } from '../cohesity-v2api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { BackupConfig } from '../models/backup-config';
import { BackupLog } from '../models/backup-log';
import { BackupStatus } from '../models/backup-status';
import { BackupRunStatus } from '../models/backup-run-status';
import { Pagination } from '../models/pagination';
import { RunResponse } from '../models/run-response';
@Injectable({
  providedIn: 'root',
})
class BackupService extends __BaseService {
  static readonly GetBackupConfigPath = '/mcm/backup-mgmt/backups/config';
  static readonly UpdateBackupConfigPath = '/mcm/backup-mgmt/backups/config';
  static readonly GetBackupLogsPath = '/mcm/backup-mgmt/backup/{runId}/logs';
  static readonly GetBackupStatusesPath = '/mcm/backup-mgmt/backups';
  static readonly TriggerBackupRunPath = '/mcm/backup-mgmt/backups';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get backup configuration.
   *
   * Retrieve the backup configuration details.
   * @return Success
   */
  GetBackupConfigResponse(): __Observable<__StrictHttpResponse<BackupConfig>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/mcm/backup-mgmt/backups/config`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BackupConfig>;
      })
    );
  }
  /**
   * Get backup configuration.
   *
   * Retrieve the backup configuration details.
   * @return Success
   */
  GetBackupConfig(): __Observable<BackupConfig> {
    return this.GetBackupConfigResponse().pipe(
      __map(_r => _r.body as BackupConfig)
    );
  }

  /**
   * Update backup configuration.
   *
   * Update the backup configuration details.
   * @param body Backup configuration details to be updated.
   * @return Success
   */
  UpdateBackupConfigResponse(body: BackupConfig): __Observable<__StrictHttpResponse<BackupConfig>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/mcm/backup-mgmt/backups/config`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BackupConfig>;
      })
    );
  }
  /**
   * Update backup configuration.
   *
   * Update the backup configuration details.
   * @param body Backup configuration details to be updated.
   * @return Success
   */
  UpdateBackupConfig(body: BackupConfig): __Observable<BackupConfig> {
    return this.UpdateBackupConfigResponse(body).pipe(
      __map(_r => _r.body as BackupConfig)
    );
  }

  /**
   * Retrieve backup logs for a specific run.
   *
   * Fetches the logs for a particular backup run, identified by `runId`.
   * @param runId The unique identifier for the backup run to retrieve logs for.
   * @return List of logs for the backup run.
   */
  GetBackupLogsResponse(runId: string): __Observable<__StrictHttpResponse<{backupLogs?: Array<BackupLog>, status?: BackupStatus}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/mcm/backup-mgmt/backup/${encodeURIComponent(runId)}/logs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{backupLogs?: Array<BackupLog>, status?: BackupStatus}>;
      })
    );
  }
  /**
   * Retrieve backup logs for a specific run.
   *
   * Fetches the logs for a particular backup run, identified by `runId`.
   * @param runId The unique identifier for the backup run to retrieve logs for.
   * @return List of logs for the backup run.
   */
  GetBackupLogs(runId: string): __Observable<{backupLogs?: Array<BackupLog>, status?: BackupStatus}> {
    return this.GetBackupLogsResponse(runId).pipe(
      __map(_r => _r.body as {backupLogs?: Array<BackupLog>, status?: BackupStatus})
    );
  }

  /**
   * Retrieve backup statuses.
   *
   * Fetches a paginated list of backup statuses, including details like start and end times, data size, and status.
   * @param pageSize Number of entries per page.
   * @param page Page number for pagination (starts from 1).
   * @return A list of backup statuses.
   */
  GetBackupStatusesResponse(pageSize?: number,
    page?: number): __Observable<__StrictHttpResponse<{backupStatuses?: Array<BackupRunStatus>, pagination?: Pagination}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (pageSize != null) __params = __params.set('pageSize', pageSize.toString());
    if (page != null) __params = __params.set('page', page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/mcm/backup-mgmt/backups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{backupStatuses?: Array<BackupRunStatus>, pagination?: Pagination}>;
      })
    );
  }
  /**
   * Retrieve backup statuses.
   *
   * Fetches a paginated list of backup statuses, including details like start and end times, data size, and status.
   * @param pageSize Number of entries per page.
   * @param page Page number for pagination (starts from 1).
   * @return A list of backup statuses.
   */
  GetBackupStatuses(pageSize?: number,
    page?: number): __Observable<{backupStatuses?: Array<BackupRunStatus>, pagination?: Pagination}> {
    return this.GetBackupStatusesResponse(pageSize, page).pipe(
      __map(_r => _r.body as {backupStatuses?: Array<BackupRunStatus>, pagination?: Pagination})
    );
  }

  /**
   * Trigger a backup run.
   *
   * Triggers a backup run for the specified data stores and returns a unique identifier for the run.
   * @return Response containing the backup run identifier.
   */
  TriggerBackupRunResponse(): __Observable<__StrictHttpResponse<RunResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/mcm/backup-mgmt/backups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RunResponse>;
      })
    );
  }
  /**
   * Trigger a backup run.
   *
   * Triggers a backup run for the specified data stores and returns a unique identifier for the run.
   * @return Response containing the backup run identifier.
   */
  TriggerBackupRun(): __Observable<RunResponse> {
    return this.TriggerBackupRunResponse().pipe(
      __map(_r => _r.body as RunResponse)
    );
  }
}

module BackupService {
}

export { BackupService }
