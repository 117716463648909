import { RecoverCassandraFormOptions } from 'src/app/modules/restore/recover-cassandra/model';
import { CreateRecoveryForm } from 'src/app/modules/restore/restore-shared';

/**
 * Cassandra authentication types.
 */
export enum CassandraAuthType {

  /**
   * Kerberso authentication
   */
  kKerberos = 'KERBEROS',

  /**
   * Password based authentication.
   */
  kPassword = 'PASSWORD',

  /**
   * LDAP based authentication.
   */
  kLdap = 'LDAP'
}

/**
 * Distribution of Cassandra installed on the Cassandra host.
 */
export enum CassandraDistribution {

  /**
   * DSE Cassandra.
   */
  kDseCassandra = 'kDseCassandra',

  /**
   * Apache Cassandra.
   */
  kApacheCassandra = 'kApacheCassandra',
}

/**
 * Replication strategy set for the Cassandra Keyspace
 *
 * TODO(karan.sood): Change the number values to string once
 * this change is made within Iris.
 */
export enum CassandraKeyspaceReplicationStrategy {

  /**
   * Simple replication strategy. This is agnostic of Data Centres.
   */
  simple = 0,

  /**
   * Network replication strategy for multi Data Cassandra centre setup.
   */
  network = 1,

  /**
   * Unsuported replication strategy. (Anything other than simple or network)
   */
  unsupported = 2
}

/*
 * Recovery related constants
 ******************************************************************************/
export type CassandraRecoveryForm = CreateRecoveryForm<RecoverCassandraFormOptions>;

/**
 * Min Cassandra release version which is compatible with Staging Directories.
 */
export enum StagingDirectorySupportedMinCassandraVersion {

    /**
     * for DSE Cassandra Distribution.
     */
    dse = 4,

    /**
     * for Apache Cassandra Distribution.
     */
    apache = 5
}
