import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { IoCsThreatsApiService, Threat, ThreatId } from '@cohesity/api/argus';
import { DataFilterValue, KeyedSelectionModel } from '@cohesity/helix';
import { IrisContextService } from '@cohesity/iris-core';
import { AjaxHandlerService, AutoDestroyable } from '@cohesity/utils';
import { finalize } from 'rxjs/operators';

import { ThreatDeleteDialogService } from './threat-delete-dialog/threat-delete-dialog.service';
import { ThreatListFilters } from './threat-list.model';

@Component({
  selector: 'dg-td-threat-list',
  templateUrl: './threat-list.component.html',
  styleUrls: ['./threat-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThreatListComponent extends AutoDestroyable implements OnInit, OnChanges {

  /**
   * flag to enable/disable selection. this is disabled by default
   */
  @Input() enableSelection = false;

  /**
   * Selects given list of threats
   */
  @Input() select: ThreatId[] = [];

  /**
   * flag to display threats corresponding to threat ids passed
   */
  @Input() showRequestedThreatsOnly = false;

  /**
   * Displays threat with the given ids if showRequested flag is passed in as true
   */
  @Input() threatIds: ThreatId[] = [];

  /**
   * Hides actions button column
   */
  @Input() disableActions = false;

  /**
   * Columns to be displayed
   */
  displayColumns = this.getColDef();

  /**
   * emits selected threats whenever selection changes
   */
  @Output() selected = new EventEmitter<ThreatId[]>();

  /**
   * Selection model to keep track of selected threats
   */
  threatSection: KeyedSelectionModel<Threat> = new KeyedSelectionModel<Threat>(threat => threat.id, true);

  /**
   * Flag to indicate if threats are loading
   */
  isLoading = false;

  /**
   * All the threats associated with the account
   */
  threats: Threat[] = [];

  /**
   * filter for threats
   */
  threatFilters: ThreatListFilters = {};

  constructor(
    private ajaxService: AjaxHandlerService,
    private cdr: ChangeDetectorRef,
    private deleteThreatDialogService: ThreatDeleteDialogService,
    private irisCtx: IrisContextService,
    private threatService: IoCsThreatsApiService,
  ) {
    super();
  }

  ngOnInit() {
    this.emitSelectionChangesInIt();
  }

  /**
   * A callback called when input binding changes.
   *
   * @param changes Input binding changes
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.disableActions) {
      this.displayColumns = this.getColDef();
    }

    if (changes.select) {
      if (this.threats.length) {
        this.threatSection.select(...this.threats.filter(threat => this.select.includes(threat.id)));
        this.select = [];
      }

    }
  }

  /**
   * Return the list of columns to show.
   *
   * @returns The column to show.
   */
  getColDef(): string[] {
    return [
      'name',
      'createdAt',
      !this.disableActions ? 'actions' : null,
    ].filter(Boolean);
  }

  /**
   * Initializes listener on selection changes and emit the selected threats if selection is enabled
   */
  emitSelectionChangesInIt() {
    this.threatSection.changed.pipe(
      this.untilDestroy()
    ).subscribe(() => {
      if (this.enableSelection) {
        this.selected.emit(this.threatSection.selected.map(threat => threat.id));
      }
    });
  }

  /**
   * Gets all the threats associated with the account
   */
  getThreats() {
    this.isLoading = true;
    this.cdr.detectChanges();
    if (this.showRequestedThreatsOnly) {
      this.threatFilters = {
        ...this.threatFilters,
        includeDeleted: true,
      };
    }
    this.threatService.getThreats(this.threatFilters).pipe(
      this.untilDestroy(),
      finalize(() => {
        this.isLoading = false;
        this.cdr.detectChanges();
      })
    ).subscribe({
      next: threatsRes => {
        this.threats = threatsRes.threats;
        // Selecting threats if parent sends input
        if (this.select.length) {
          this.threatSection.select(...this.threats.filter(threat => this.select.includes(threat.id)));
          // clearing input so that we don't select in next getThreats response
          this.select = [];
        }
        // display threat corresponding given threat id when givenThreatsOnly is true
        this.filterRequestedThreats();
      },
      error: this.ajaxService.handler
    });
  }

  /**
   * Displays only given threats
   */
  private filterRequestedThreats() {
    if (!this.showRequestedThreatsOnly) {
      return;
    }
    this.threats = this.threats.filter(threat => this.threatIds.some(id => id === threat.id));
  }

  /**
   * Deletes a threat by given id
   *
   * @param id thread id
   */
  deleteThreat(threat: Threat) {
    this.deleteThreatDialogService.launch(threat)
      .pipe(this.untilDestroy())
      .subscribe(data => {
        if (!data) {
          return;
        }
        this.getThreats();
      });
  }

  /**
   * Applies filter and update the table
   *
   * @param filters selected filters
   */
  applyFilters(filters: DataFilterValue<any, any>[]) {
    const filterParams: ThreatListFilters = {};
    filters.forEach(filter => {
      switch (filter.key) {
        case 'name':
          filterParams.name = filter.value;
          break;
      }
    });

    this.threatFilters = filterParams;
    this.getThreats();
  }
}
