import { SelectionModel } from '@angular/cdk/collections';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { DlpApiService, Policy, PolicyList, PolicyRunStatus, ResourceType } from '@cohesity/api/argus';
import { Api } from '@cohesity/api/private';
import {
  DataFilterValue,
  FiltersComponent,
  MomentDatePipe,
  Percentage,
  ValueFilterSelection,
  WindowRef,
} from '@cohesity/helix';
import { IrisContextService, flagEnabled } from '@cohesity/iris-core';
import { AutoDestroyable } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';

import { HasCustomRBACPermissions } from '@cohesity/data-govern/shared';
import { ResourceTypeIcon, ResourceTypePipe, getResourceTypeFilterValues } from '../../resource-type-pipe';
import { PolicyRunStatusPipe } from '../policy-run-status-pipe';

/**
 * The policy-list component
 */
@Component({
  selector: 'dg-dc-policy-list',
  templateUrl: './policy-list.component.html',
  styleUrls: ['./policy-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PolicyListComponent extends AutoDestroyable implements OnChanges, AfterViewInit {
  /**
   * Policy List to Show
   */
  @Input() policyList: PolicyList;

  /**
   * The selected policy list set.
   */
  @Input() selection?: SelectionModel<PolicyList>;

  /**
   * Indicates whether to hide the actions column or not.
   */
  @Input() hideActions? = false;

  /**
   * View type to open policy list in the form of card or table
   */
  @Input() viewType? = 'table';

  /**
   * Event triggered on click of edit button
   */
  @Output() modifyPolicy? = new EventEmitter<Policy>();

  /**
   * Event triggered on click of delete button
   */
  @Output() deletePolicy? = new EventEmitter<Policy>();

  /**
   * Event triggered on click of copy button
   */
  @Output() copyPolicy? = new EventEmitter<Policy>();

  /**
   * Event triggered on click of apply policy button
   */
  @Output() applyPolicy? = new EventEmitter<void>();

  /**
   * Event triggered on click of edit active policy button
   */
  @Output() editPolicy? = new EventEmitter<Policy>();

  /**
   * The resourceType to compare the type of policy
   */
  readonly resourceType = ResourceType;

  /**
   * The map of resource type to its icon.
   */
  readonly resourceTypeIcon = ResourceTypeIcon;

  /**
   * Download Options to download policy reports
   */
  downloadOptions: {
    text: string;
    value: boolean;
  }[] = [
    {
      text: this.translateService.instant('argus.policy.downloadAggregated'),
      value: false,
    },
    {
      text: this.translateService.instant('argus.policy.downloadRaw'),
      value: true,
    },
  ];

  /**
   * Indicates whether the user is having modify privilege.
   */
  get hasModifyAccess(): boolean {
    return HasCustomRBACPermissions(['DGAAS_MANAGE_DC_POLICY', 'DGAAS_MODIFY'], this.irisCtx.irisContext);
  }

  /**
   * Allows create policy flow based on feature flag
   */
  get allowCreateNewPolicy(): boolean {
    return flagEnabled(this.irisCtx.irisContext, 'dataHawkDataClassificationCreatePoliciesEnabled');
  }

  /**
   * Filtered policy list after applying policy list.
   */
  filteredPolicyList: PolicyList = [];

  /**
   * Template for table filters.
   */
  @ViewChild(FiltersComponent) private filtersComponent: FiltersComponent;

  /**
   * Resource type filter observable
   */
  resourceFilters = getResourceTypeFilterValues(this.resourceTypePipe);

  /**
   * Status filter observable
   */
  statusFilters: ValueFilterSelection[] = [
    PolicyRunStatus.completed,
    PolicyRunStatus.error,
    PolicyRunStatus.notStarted,
    PolicyRunStatus.scanning,
  ].map(status => ({ label: this.translateService.instant(`argus.enum.status.${status}`), value: status }));

  /**
   * Column def for the policy table.
   */
  colDefinition = this.getColDef();

  constructor(
    private cdr: ChangeDetectorRef,
    private irisCtx: IrisContextService,
    private momentDatePipe: MomentDatePipe,
    private policyRunStatusPipe: PolicyRunStatusPipe,
    private resourceTypePipe: ResourceTypePipe,
    private router: Router,
    private translateService: TranslateService,
    private windowRef: WindowRef
  ) {
    super();
  }

  /**
   * Initialize the filters after component is rendered.
   */
  ngAfterViewInit() {
    // apply the filter on filter value changes.
    this.filtersComponent.filterValues$.pipe(this.untilDestroy()).subscribe(filters => {
      this.applyFilters(filters);
    });
  }

  /**
   * A callback called when input binding changes.
   *
   * @param changes Input binding changes
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.policyList || changes.hideActions) {
      this.colDefinition = this.getColDef();
    }
  }

  /**
   * Apply the selected filter.
   *
   * @param filters The applied filter value .
   */
  private applyFilters(filters: DataFilterValue<any, any>[]) {
    // creating the shallow copy of the filtered policy list to keep policyList intact
    this.filteredPolicyList = [...this.policyList];

    // applying the selected filter.
    filters.forEach(({ key, value, predicate }) => {
      // Set for Run status / Resource type filters
      let selectedValues: Set<ResourceType | PolicyRunStatus>;

      // skip null or empty value filters.
      if (!value || !value.length) {
        return;
      }

      // Update Set values so it can be used in switch case
      if (Array.isArray(value)) {
        selectedValues = new Set(value.map(val => val.value));
      }

      switch (key) {
        case 'name':
          this.filteredPolicyList = this.filteredPolicyList.filter(policy => predicate(policy, value, 'name'));
          break;

        case 'resourceType':
          this.filteredPolicyList = this.filteredPolicyList.filter(policy => selectedValues.has(policy.buildType));
          break;

        case 'statusType':
          this.filteredPolicyList = this.filteredPolicyList.filter(policy =>
            selectedValues.has(policy.runDetails?.runStatus)
          );
          break;

        default:
          break;
      }
    });

    this.cdr.detectChanges();
  }

  /**
   * Return the policy's sensitivity coverage percentage.
   *
   * @param shield The Shield.
   * @returns The sensitivity coverage percentage.
   */
  getSensitivityPct(): Percentage {
    const value = 100;

    return { value, status: value >= 50 && value <= 100 ? 'success' : 'critical' };
  }

  /**
   * Return the policy status class name.
   *
   * @param policy The policy.
   * @returns The status class name.
   */
  getPolicyStatusClass(policy: Policy): string {
    return this.hasError(policy) ? 'status-critical' : '';
  }

  /**
   * Return the list of columns to show.
   *
   * @returns The column to show. ['name', 'type', 'patterns', 'actions'];
   */
  getColDef(): string[] {
    return ['name', 'buildType', 'patterns', this.hideActions ? null : 'actions'].filter(Boolean);
  }

  /**
   * Download CSV Reports for policies
   *
   * @param isRawData whether to download raw report or aggregated
   */
  downloadReports(isRawData: boolean) {
    const path = `${Api.argusRoot}${DlpApiService.DownloadReportsPath}?rawData=${isRawData}`;

    this.windowRef.openExternalLink(path);
  }

  /**
   * A helper function to check whether whether the current policy has run into error or not
   *
   * @method hasError
   * @param currPolicy the current policy
   * @returns true if policy status is error else false
   */
  hasError(currPolicy: Policy): boolean {
    return currPolicy?.runDetails?.runStatus === PolicyRunStatus.error;
  }

  /**
   * Returns a delimited string with run status and last run timestamp
   *
   * @param policy policy
   * @returns run status and timestamp with delimiter
   */
  getRunDetailsWithDelimiter(policy: Policy): string {
    const runStatus = this.policyRunStatusPipe.transform(policy.runDetails?.runStatus);
    const lastRunTimestampMsecs = this.momentDatePipe.transform(policy.runDetails?.lastRunTimestampMsecs);

    return [runStatus, lastRunTimestampMsecs].filter(Boolean).join(', ');
  }

  /**
   * Navigate to policy scan run details page.
   *
   * @param policy the policy object
   */
  viewRunDetails(policy: Policy) {
    this.router.navigate([`data-classification/policies/${policy.id}/results`]);
  }
}
