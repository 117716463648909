import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef } from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';
import { ObjectIdList, Scan, ScheduleType } from '@cohesity/api/argus';
import { CopyStats, CopyStatsServiceApi, GetCopyStatParams, GetCopyStatResponse } from '@cohesity/api/v2';
import { extractClusterUuid, extractObjectId, SnapshotSelectionType } from '@cohesity/data-govern/scans';
import { DateRange, getDateRange, Timeframe } from '@cohesity/helix';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { FormSectionComponent } from '@cohesity/shared-forms';
import { AjaxHandlerService } from '@cohesity/utils';
import moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { catchError, finalize, switchMap } from 'rxjs/operators';

import { FormSectionName, ScanFormModel } from '../scan.model';
import { SnapshotDetailsDialogService } from './snapshot-details-dialog';
import { SnapshotSelectionFormValue, SnapshotSelectionModel } from './snapshot-selection.models';


export const MAX_SNAPSHOTS_ALLOWED = 250;
/**
 * A component to display and manage snapshot selection settings.
 */
@Component({
  selector: 'dg-td-snapshot-selection',
  templateUrl: './snapshot-selection.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: FormSectionComponent,
      useExisting: forwardRef(() => SnapshotSelectionComponent),
    }
  ],
})
export class SnapshotSelectionComponent
  extends FormSectionComponent<SnapshotSelectionFormValue, ScanFormModel, Scan> {

  /**
   * Section name for form builder
   */
  formSectionName = 'snapshotSelection' as FormSectionName;

  /**
   * Snapshot selection type
   */
  selectionType: SnapshotSelectionType;

  /**
   * Schedule Types
   */
  scheduleTypeEnum = Object.keys(ScheduleType);

  /**
   * scan schedule type
   */
  scheduleType = ScheduleType.scanOnce;

  /**
   * max snapshots to scan
   */
  maxSnapshotCount;

  /**
   * disable max snapshot count input field
   */
  disableSnapshotCount = true;

  /**
   * Date range for snapshots to scan
   */
  dateRange: DateRange<Date>;

  /**
   * disable date range radio field
   */
  disableDateRangeOption = false;

  /**
   * disable date range field
   */
  disableDateRange = true;

  /**
   * Loader flag used to check if snapshots data is loading
   */
  snapshotsLoading = false;

  /**
   * Maximum allowed snapshots
   */
  readonly maxAllowedSnapshot = MAX_SNAPSHOTS_ALLOWED;

  /**
   * Snapshots details for the selected objects and selected date range
   */
  snapshotData: CopyStats[] = [];

  /**
   * Subject to trigger copystat api call to get snapshots.
   */
  private triggerSnapshotFetch = new Subject<any>();

  /**
   * Indicates if the schedule scan feature flag is enabled or not
   */
  get isScheduleEnabled(): boolean {
    return flagEnabled(this.irisCtx.irisContext, 'dataHawkThreatDetectionScheduledScan');
  }

  constructor(
    private ajaxHandler: AjaxHandlerService,
    private cd: ChangeDetectorRef,
    private irisCtx: IrisContextService,
    private snapshotsApi: CopyStatsServiceApi,
    private snapshotDetailsDialogService: SnapshotDetailsDialogService,
  ) {
    super();
  }

  /**
   * Used to initialize the section. This function will be called by FormBuilderComponent.
   */
  initFormSection() {
    super.initFormSection();
    this.formControl.addValidators([
      Validators.required,
      this.snapshotsLoadingValidator
    ]);
    this.formControl.updateValueAndValidity();

    // initialize form value from fromDataModel
    const { scheduleType, selectionType, maxSnapshotCount, dateRange } = this.fromDataModel(this.builder.dataModel);
    this.scheduleType = scheduleType ?? ScheduleType.scanOnce;
    this.selectionType = selectionType;
    this.maxSnapshotCount = maxSnapshotCount;
    this.dateRange = dateRange;
    this.updateValue();

    this.initiateSnapshotFetch();
  }

  /**
   * update snapshot selection type
   *
   * @param val snapshot selection type
   */
  selectScanType(val: SnapshotSelectionType) {
    this.selectionType = val;
    if (this.selectionType === SnapshotSelectionType.dateRange) {
      this.formControl.addValidators(this.maxSnapshotsValidator);
      this.triggerSnapshotFetch.next();
    } else {
      this.formControl.removeValidators(this.maxSnapshotsValidator);
    }

    this.formControl.patchValue({
      ...this.formControl.value,
      selectionType: val
    });

    this.onSnapShotSelectionChange();
  }

  /**
   * updates snapshot selection depends on schedule type
   *
   * @param val scan schedule type
   */
  selectScheduleType(val: ScheduleType) {
    this.scheduleType = val;
    this.formControl.patchValue({
      ...this.formControl.value,
      scheduleType: val,
    });

    if (val === ScheduleType.default) {
      this.disableDateRangeOption = true;
      this.disableDateRange = true;
    } else {
      this.disableDateRangeOption = false;
    }
    this.selectionType = SnapshotSelectionType.mostRecent;
    this.updateValue();
    this.cd.detectChanges();
  }

  /**
   * update snapshot count to scan
   *
   * @param count max snapshots count
   */
  snapshotCountChanged(count: number) {
    this.maxSnapshotCount = count;
    this.formControl.patchValue({
      ...this.formControl.value,
      maxSnapshotCount: count
    });
  }

  /**
   * update date range of snapshots to scan
   *
   * @param dateRange date range
   */
  dateRangeChanged(dateRange: DateRange<Date>) {
    this.dateRange = dateRange;
    this.triggerSnapshotFetch.next();
    this.formControl.patchValue({ ...this.formControl.value, dateRange });
  }

  /**
   * Update current section value to parent Form value
   */
  updateValue() {
    this.next({
      scheduleType: this.scheduleType,
      selectionType: this.selectScanType,
      maxSnapshotCount: this.maxSnapshotCount,
      dateRange: this.dateRange
    });
  }

  /**
   * Converts current form value to scan objects model
   *
   * @returns Scan objects value for the scan
   */
  toDataModel(): Partial<Scan> {
    const snapshotSelection: SnapshotSelectionModel = {
      snapshotSelection: undefined,
    };

    if (this.scheduleType === ScheduleType.default) {
      snapshotSelection.schedule = {
        type: ScheduleType.default
      };
    }

    switch (this.selectionType) {
      case SnapshotSelectionType.mostRecent:
        snapshotSelection.snapshotSelection = {
          mostRecent: true,
        };
        break;
      case SnapshotSelectionType.snapshotCount:
        snapshotSelection.snapshotSelection = {
          maxSnapshotCount: this.maxSnapshotCount,
        };
        break;
      case SnapshotSelectionType.dateRange:
        snapshotSelection.snapshotSelection = {
          startTimeUsecs: this.dateRange.start.getTime() * 1000,
          endTimeUsecs: this.dateRange.end.getTime() * 1000,
        };
    }
    return snapshotSelection;
  }

  /**
   * Creates section value form the given scan
   *
   * @param dataModel Scan
   * @returns Section Value
   */
  fromDataModel(dataModel: Scan): SnapshotSelectionFormValue {
    const formValue: SnapshotSelectionFormValue = {
      scheduleType: ScheduleType.scanOnce,
      selectionType: SnapshotSelectionType.mostRecent,
      maxSnapshotCount: 10,
      dateRange: getDateRange(Timeframe.Past7Days)
    };
    if (dataModel.schedule?.type) {
      formValue.scheduleType = ScheduleType.default;
    }
    if (dataModel.snapshotSelection?.maxSnapshotCount) {
      formValue.selectionType = SnapshotSelectionType.snapshotCount;
      formValue.maxSnapshotCount = dataModel.snapshotSelection.maxSnapshotCount;
    }
    if (dataModel.snapshotSelection?.startTimeUsecs && dataModel.snapshotSelection?.endTimeUsecs) {
      formValue.selectionType = SnapshotSelectionType.dateRange;
      const startDate = moment(dataModel.snapshotSelection.startTimeUsecs / 1000);
      const endDate = moment(dataModel.snapshotSelection.endTimeUsecs / 1000);
      const dateRange = getDateRange(Timeframe.Custom);
      dateRange.start = startDate.toDate();
      dateRange.end = endDate.toDate();
      formValue.dateRange = dateRange;
    }
    return formValue;
  }

  /**
   * enable relevant fields and disable other fields base on snapshot selection type
   */
  private onSnapShotSelectionChange() {
    switch (this.selectionType) {
      case SnapshotSelectionType.mostRecent:
        this.disableSnapshotCount = true;
        this.disableDateRange = true;
        break;
      case SnapshotSelectionType.snapshotCount:
        this.disableSnapshotCount = false;
        this.disableDateRange = true;
        break;
      case SnapshotSelectionType.dateRange:
        this.disableSnapshotCount = true;
        this.disableDateRange = false;
        break;
    }
    this.cd.detectChanges();
  }

  private maxSnapshotsValidator: ValidatorFn = () => {
    if (this.snapshotData?.length > this.maxAllowedSnapshot) {
      return { maxSnapshotLimitReached: true };
    }
    return null;
  };

  private snapshotsLoadingValidator: ValidatorFn = () => {
    if (!this.snapshotsLoading) {
      return null;
    }
    return {
      snapshotsLoading: true
    };
  };

  private initiateSnapshotFetch() {
    this.builder.formGroup.controls.objects.valueChanges
      .pipe(this.untilDestroy()).subscribe(() => {
        if (this.selectionType === SnapshotSelectionType.dateRange) {
          this.triggerSnapshotFetch.next();
        }
      });
    this.triggerSnapshotFetch
      .pipe(
        switchMap(() => this.getValidSnapshot$(
          this.builder.formGroup.controls?.objects?.value?.map(object => object.id),
          this.dateRange,
        ))
      ).subscribe(copyStatData => this.snapshotData = copyStatData);
  }


  private getValidSnapshot$(
    objectIds: ObjectIdList,
    dateRange?: DateRange<Date>,
    pageSize?: number): Observable<GetCopyStatResponse> {
    const filters: GetCopyStatParams = {
      clusterIdentifiers: [],
      objectIds: [],
      filterByStatus: ['success', 'warning'],
    };

    objectIds
      .map(id => ({
        clusterIdentifier: extractClusterUuid(id),
        objectId: extractObjectId(id)
      }))
      .reduce((accFilters, object) => {
        accFilters.clusterIdentifiers.push(object.clusterIdentifier);
        accFilters.objectIds.push(object.objectId);
        return accFilters;
      }, filters);

    if (dateRange) {
      filters.fromRunStartTimeUsecs = dateRange.start.getTime() * 1000;
      filters.toRunStartTimeUsecs = dateRange.end.getTime() * 1000;
    }

    if (pageSize) {
      filters.pageSize = pageSize;
    }

    this.snapshotsLoading = true;
    this.cd.detectChanges();

    return this.snapshotsApi
      .GetCopyStats(filters)
      .pipe(
        this.untilDestroy(),
        finalize(() => {
          this.snapshotsLoading = false;
          this.updateValue();
          this.cd.detectChanges();
        }),
        catchError((err) => {
          this.ajaxHandler.handler(err);
          return ([]);
        })
      );
  }

  /**
   * Opens snapshot summary dialog
   */
  openSnapshotSummaryDialog() {
    this.snapshotDetailsDialogService
      .launch(this.snapshotData, this.builder.formGroup.controls?.objects?.value)
      .subscribe();
  }
}
