/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityApiConfiguration as __Configuration } from '../cohesity-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { PostgresNodeInfo } from '../models/postgres-node-info';
@Injectable({
  providedIn: 'root',
})
class CustomReportingService extends __BaseService {
  static readonly GetPostgresPath = '/public/postgres';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * List the postgres database running nodes on the cohesity cluster.
   *
   * Returns the list of node information running postgres database.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetPostgresResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<Array<PostgresNodeInfo>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/postgres`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PostgresNodeInfo>>;
      })
    );
  }
  /**
   * List the postgres database running nodes on the cohesity cluster.
   *
   * Returns the list of node information running postgres database.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetPostgres(regionId?: string,
    accessClusterId?: number): __Observable<Array<PostgresNodeInfo>> {
    return this.GetPostgresResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as Array<PostgresNodeInfo>)
    );
  }
}

module CustomReportingService {
}

export { CustomReportingService }
