<h2 mat-dialog-title class="margin-bottom-lg">
  {{ (policy?.id ? 'edit' : 'new') | translate }} {{ 'policy' | translate }}
</h2>

<mat-dialog-content>
  <dg-dc-policy-modify [formControl]="policyCtrl" [isTouched]="isTouched"></dg-dc-policy-modify>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button
    cogDataId="cancel-btn"
    cogCancelButton
    mat-dialog-close
    [disabled]="isSubmitting">
    {{'cancel' | translate}}
  </button>
  <button mat-flat-button
    cogDataId="continue-btn"
    cdkFocusInitial
    cogSubmitButton
    [disabled]="isSubmitting"
    [loading]="isSubmitting"
    (click)="modifyPolicy()">
    {{ (policy?.id ? 'save' : 'create') | translate }}
  </button>
</mat-dialog-actions>
