import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { IrisContextService, flagEnabled } from '@cohesity/iris-core';
import { RecoveryTypeOptions } from 'src/app/modules/restore/restore-shared/model/recovery-type-options';
import { HandlingExistingVmOptions } from '../../../../restore-shared/model/handling-existing-vm-options';

@Component({
  selector: 'coh-excluded-disk-handling',
  templateUrl: './settings-list-excluded-disk-handling.component.html',
  styleUrls: ['./settings-list-excluded-disk-handling.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsExcludedDiskHandlingComponent {
  /**
   * Form control for Recover Excluded Disks toggle.
   * Controls whether the option to recover excluded disks is enabled.
   */
  @Input() recoverExcludedDisks: UntypedFormControl;

  /**
   * Form control for Recover Independent Disks toggle.
   * Controls whether the option to recover independent disks is enabled.
   */
  @Input() recoverIndependentDisks: UntypedFormControl;

  /**
   * Determines if the component should be in read-only mode.
   */
  @Input() readOnly: boolean;

  /**
   * Specifies the type of recovery operation being performed.
   * Example values: InstantRecovery, CopyRecovery.
   */
  @Input() recoveryType: RecoveryTypeOptions;

  /**
   * Specifies how existing VMs should be handled during recovery.
   * Example values: OverwriteExistingVm, KeepExistingVm.
   */
  @Input() existingVmHandlingOption: HandlingExistingVmOptions;

  /**
   * To determine whether the "Not Applicable" message should be displayed.
   * This is true when Copy Recovery and Overwrite Existing VM options are selected.
   */
  isNotApplicable: boolean;

  /**
   * Feature flag to check if the 'Recover Excluded Disks' option is enabled.
   */
  recoverExcludedDisksFlag: boolean;

  constructor(public irisContext: IrisContextService) {
    this.recoverExcludedDisksFlag = flagEnabled(this.irisContext.irisContext, 'vmwareRecoverExcludedDisksEnabled');
  }

  ngOnChanges(): void {
    this.manageDiskRecoveryToggles();
  }

  /**
   * Updates the 'isNotApplicable' based on recovery type and VM handling option.
   * If the recovery type is Copy Recovery and the existing VM handling option is Overwrite Existing VM,
   * 'isNotApplicable' is set to true. Otherwise, it is set to false.
   *
   */
  private manageDiskRecoveryToggles(): void {
    if (this.recoveryType === RecoveryTypeOptions.CopyRecovery &&
        this.existingVmHandlingOption === HandlingExistingVmOptions.OverwriteExistingVm) {
      this.isNotApplicable = true;
    } else {
      this.isNotApplicable = false;
    }
  }
}
