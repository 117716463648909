import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { RegistrationServiceApi } from '@cohesity/api/v2';
import { HeliosConnectivityService } from '@cohesity/dataprotect/shared';
import { SnackBarService } from '@cohesity/helix';
import { ClusterService, IrisContextService } from '@cohesity/iris-core';
import { DeleteConfirmationDialogComponent } from '@cohesity/shared-dialogs';
import { AjaxHandlerService, AutoDestroyable } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, interval } from 'rxjs';
import { debounceTime, filter, finalize, map, repeatWhen, switchMap, take, takeWhile, tap } from 'rxjs/operators';
import { DialogService, UserService } from 'src/app/core/services';
import { AjsClusterService } from 'src/app/shared';

/**
 * @description
 *
 * Helios token claim component opened by app panel service
 */
@Component({
  selector: 'coh-helios-claim',
  templateUrl: './helios-claim.component.html',
  styleUrls: ['./helios-claim.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class HeliosClaimComponent extends AutoDestroyable implements OnDestroy, AfterViewInit {
  /**
   * Helios token claim main form group
   */
  formGroup = new FormGroup({
    tokenInput: new FormControl<string>(''),
    heliosPermission: new FormControl<boolean>(false),
    clusterPermission: new FormControl<boolean>(false),
  });

  /**
   * Max mcm/config calls to server to fetch registered status.
   */
  private readonly MAX_TRIES = 100;

  /**
   * Polling time for mcm/config calls
   */
  private readonly CONFIG_CALL_POLL_INTERVAL = 3_000;

  /**
   * Debounce time for saving settings.
   */
  private readonly SETTINGS_DEBOUNCE_TIME = 1_000;

  /**
   * Indicates form submission in progress
   */
  readonly submitting$ = new BehaviorSubject<boolean>(false);

  constructor(
    private ajaxHandler: AjaxHandlerService,
    private ajsClusterService: AjsClusterService,
    private clusterService: ClusterService,
    public heliosConnectivityService: HeliosConnectivityService,
    private logger: NGXLogger,
    private registrationService: RegistrationServiceApi,
    private snackBarService: SnackBarService,
    private translateService: TranslateService,
    private userService: UserService,
    private irisCtx: IrisContextService,
    private dialogService: DialogService,
  ) {
    super();
  }

  ngAfterViewInit(): void {
    this.heliosConnectivityService.getHeliosConfiguration().pipe(
      tap((status) => {
        const { clusterPermission, heliosPermission } = this.formGroup.controls;
        clusterPermission.setValue((status?.licenseOnly as boolean) ?? false);
        heliosPermission.setValue(!status?.mcmReadOnly ?? false);

        if (!this.userService.privs.MCM_MODIFY) {
          this.formGroup.disable();
        }
      }),
      filter(() => this.userService.privs.MCM_MODIFY),
      switchMap(() => this.formGroup.valueChanges.pipe(
        debounceTime(this.SETTINGS_DEBOUNCE_TIME),
        map(formValue => ([formValue.heliosPermission, formValue.clusterPermission]))
      )),
      this.untilDestroy(),
      switchMap(([mcmPermission, readOnlyCluster]) =>
          this.heliosConnectivityService.updateHeliosConfiguration({
            mcmReadOnly: !mcmPermission,
            licenseOnly: readOnlyCluster,
          })
        )
    ).subscribe(() => this.snackBarService.open(this.translateService.instant('heliosClaim.saveSettings')));
  }

  /**
   * Handles form submission
   */
  onSubmit() {
    this.submitting$.next(true);
    this.registrationService
      .HeliosClaim({
        body: {
          registrationToken: this.formGroup.controls.tokenInput.value,
        },
      })
      .pipe(
        switchMap(() => this.clusterService.updateLicenseState('kClaimed')),
        this.untilDestroy(),
        finalize(() => this.submitting$.next(false))
      )
      .subscribe(clusterInfo => {
        // Note: At many places in the code, clusterInfo from AJS cluster service is being referred.
        // Updating the AJS cluster info to keep it in sync with Angular clusterInfo
        // This update can be removed once AngularJS code has been removed
        this.ajsClusterService.updateClusterInfoInAjsService(clusterInfo);
        this.pollConfigOnRegistration();

      }, this.ajaxHandler.handler);
  }

  /**
   * Poll for cluster config on successful registration.
   *
   * Config needs to be polled because connectedToMcm does not immediately reflect true
   * on successful registration.
   */
  pollConfigOnRegistration() {
    const triggers$ = interval(this.CONFIG_CALL_POLL_INTERVAL).pipe(this.untilDestroy(), take(this.MAX_TRIES));

    this.heliosConnectivityService
      .getHeliosConfiguration()
      .pipe(
        this.untilDestroy(),
        repeatWhen(() => triggers$),
        takeWhile(config => config?.connectionStatus?.connectedToMcm === false, true)
      )
      .subscribe(
        () => {},
        err => {
          this.logger.error(err);
        }
      );
  }

  /**
   * @param    heliosEndpoint   The Endpoint of the Helios instance to which this cluster is connected.
   * @returns Domain name part of url without the protocol and port.
   */
  getHeliosDomainName(endpoint: string) {
    try {
      const url = new URL(endpoint);

      // Done to remove port (if any) from url host.
      const domainName = url?.host?.split(':')[0];

      return domainName ? `( ${domainName} )` : '';
    } catch (error) {
      return ''; // If an error is thrown, it's not a valid URL
    }
  }

  /**
   * Open dialog To Confirm Unregisteration of cluster from Helios App.
   */
  openDisconnectClusterConfirmationDialog() {
    this.dialogService
      .showDialog(DeleteConfirmationDialogComponent, {
        title: 'heliosClaim.dialog.disconnectClusterTitle',
        message: `<div>${this.translateService.instant('heliosClaim.dialog.disconnectClusterConfirmation')}</div>
          </br><div class='margin-bottom-xs'>${this.translateService.instant('heliosClaim.dialog.disconnectClusterRepercussion')}</div>`,
        confirmLabel: 'disconnect',
        cancelLabel: 'cancel',
        confirmButtonType: 'warn',
        isHtml: true
      })
      .pipe(
        this.untilDestroy(),
      )
      .subscribe(res => {
        if (res) {
          this.disconnectCluster();
        }
      });
  }

  /**
   * Disconnect cluster from Helios App.
   */
  disconnectCluster(): void {
    this.submitting$.next(true);
    const clusterInfo = this.irisCtx.irisContext.clusterInfo || {};
    const data = {
      clusterIdentifier: clusterInfo.id,
      clusterIncarnation: clusterInfo.incarnationId,
    };
    this.heliosConnectivityService.unregisterClusterFromHelios(data)
      .pipe(
        switchMap(()=> this.heliosConnectivityService.getHeliosConfiguration()),
        this.untilDestroy(),
        finalize(() => this.submitting$.next(false))
      )
      .subscribe(
        () => {
          this.snackBarService.open(this.translateService.instant('heliosClaim.clusterDisconnectSuccess'));
        },
        error => this.ajaxHandler.errorMessage(error)
      );
  }
}
