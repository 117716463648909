/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityApiConfiguration as __Configuration } from '../cohesity-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Route } from '../models/route';
import { DeleteRouteParam } from '../models/delete-route-param';
@Injectable({
  providedIn: 'root',
})
class RoutesService extends __BaseService {
  static readonly GetRoutesPath = '/public/routes';
  static readonly AddRoutePath = '/public/routes';
  static readonly DeleteRoutePath = '/public/routes';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * List the Static Routes for the Cohesity Cluster.
   *
   * Returns the Static Routes for the Cohesity Cluster.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetRoutesResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<Array<Route>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/routes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Route>>;
      })
    );
  }
  /**
   * List the Static Routes for the Cohesity Cluster.
   *
   * Returns the Static Routes for the Cohesity Cluster.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetRoutes(regionId?: string,
    accessClusterId?: number): __Observable<Array<Route>> {
    return this.GetRoutesResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as Array<Route>)
    );
  }

  /**
   * Create a Static Route on the Cohesity Cluster.
   *
   * Returns the create status upon completion.
   * @param params The `RoutesService.AddRouteParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`:
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  AddRouteResponse(params: RoutesService.AddRouteParams): __Observable<__StrictHttpResponse<Route>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    __body = params.body;
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/routes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Route>;
      })
    );
  }
  /**
   * Create a Static Route on the Cohesity Cluster.
   *
   * Returns the create status upon completion.
   * @param params The `RoutesService.AddRouteParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`:
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  AddRoute(params: RoutesService.AddRouteParams): __Observable<Route> {
    return this.AddRouteResponse(params).pipe(
      __map(_r => _r.body as Route)
    );
  }

  /**
   * Delete the specified Static Route from the Cohesity Cluster.
   *
   * Returns the delete status upon completion.
   * @param params The `RoutesService.DeleteRouteParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`:
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteRouteResponse(params: RoutesService.DeleteRouteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    __body = params.body;
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/public/routes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete the specified Static Route from the Cohesity Cluster.
   *
   * Returns the delete status upon completion.
   * @param params The `RoutesService.DeleteRouteParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`:
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteRoute(params: RoutesService.DeleteRouteParams): __Observable<null> {
    return this.DeleteRouteResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module RoutesService {

  /**
   * Parameters for AddRoute
   */
  export interface AddRouteParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;
    body?: Route;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DeleteRoute
   */
  export interface DeleteRouteParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;
    body?: DeleteRouteParam;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { RoutesService }
