import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  ObjectSearchFieldModule,
  ObjectSearchResultModule,
  ObjectSelectionCartModule,
  SelectionSummaryModule,
} from '@cohesity/data-govern/scans';
import { EnvironmentTypeFilterModule } from '@cohesity/shared/common';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { CohesityHelixModule, DataFiltersModule } from '@cohesity/helix';
import { CohesitySharedFormsModule, FormBuilderModule } from '@cohesity/shared-forms';
import { TranslateModule } from '@ngx-translate/core';

import { ObjectSearchComponent } from './object-search.component';

const COMPONENTS = [
  ObjectSearchComponent,
];

@NgModule({
  declarations: [...COMPONENTS],
  exports: [ObjectSearchComponent],
  imports: [
    CohesityHelixModule,
    CohesitySharedFormsModule,
    CommonModule,
    EnvironmentTypeFilterModule,
    DataFiltersModule,
    ObjectSearchFieldModule,
    ObjectSearchResultModule,
    ObjectSelectionCartModule,
    FormBuilderModule,
    SelectionSummaryModule,
    TranslateModule,
  ],
})
export class ObjectSearchModule { }
