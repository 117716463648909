import { MongoDBPhysicalProtectionSource, ProtectionSourceNode } from '@cohesity/api/v1';
import { Environment } from 'src/app/shared/constants';

import { ProtectionSourceDataNode } from '../shared/protection-source-data-node';
import { HasMetadata, SourceNodeMetadata } from '../shared/protection-source-metadata/source-node-metadata';

/**
 * Represents an active MOngoDB Physical source node and job tree selection behavior.
 */
export class MongoDBPhysicalSourceDataNode extends ProtectionSourceDataNode implements HasMetadata {

  /**
   * Stores the metadata for the source node.
   */
  readonly metadata: SourceNodeMetadata;

  /**
   * Specifies whether the node is part of the group currently being edited or viewed.
   */
  inCurrentGroup = false;

  /**
   * Stores the MongoDB Physical objectInfo.
   */
  readonly objectInfo: MongoDBPhysicalProtectionSource;

  constructor(data: ProtectionSourceNode, readonly level: number) {
    super(Environment.kMongoDBPhysical, data, level, []);
    this.objectInfo = data.protectionSource?.mongoDBPhysicalProtectionSource || null;

    this.metadata = {
      logicalSize: this.logicalSize,
      leafCount: this.expandable ? this.children && this.children?.length || 0 : undefined,
      nodeIdentifierKey: Environment.kMongoDBPhysical + '.' + this.envSource.type,
    };
  }

  /**
   * Used as a callback function to determine if a node can be selected.
   *
   * @return  True if the node can be selected.
   */
  canSelect(): boolean {
    return this.inCurrentGroup || !this.protected;
  }

  /**
   * Used as a callback function to determine if a node can be auto protected.
   *
   * @return  True if the ndoe can be auto protected.
   */
  canAutoSelect(): boolean {
    return this.inCurrentGroup ? !this.isLeaf : (!this.isLeaf && !this.protected);
  }

  /**
   * Whether the node is a leaf which can be directly selected or not.
   */
  get isLeaf() {
    return this.objectInfo?.type === 'kCluster';
  }
}
