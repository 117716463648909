import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Sort } from '@angular/material/sort';
import {
  Ability,
  DataSource,
  DataSourcesApiService,
  GetScansResponse,
  IoCsScansApiService,
  Scan,
  ScanHealthStatus,
  ScanId,
  ScanTrigger,
} from '@cohesity/api/argus';
import { clusterIdentifierIdToDataSourceMap } from '@cohesity/data-govern/data-classification';
import { getScanStatsCountMap, getSortedAppStatus } from '@cohesity/data-govern/scans';
import {
  AppStatus,
  getEnvironmentFilterValues,
  getObjectFilterValues,
  OriginState,
  ScanTriggerTypePipe,
} from '@cohesity/data-govern/shared';
import { DataFilterValue, defaultTimeframeOptions, ValueFilterSelection } from '@cohesity/helix';
import { flagEnabled, IrisContextService, SkuType } from '@cohesity/iris-core';
import { AjaxHandlerService, AutoDestroyable, ClusterIdentifierId } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import { isArray, isEqual, isNil, omit } from 'lodash-es';
import moment from 'moment';
import { combineLatest, interval, Observable, of, Subject } from 'rxjs';
import { filter, finalize, map, switchMap, takeWhile, tap } from 'rxjs/operators';

import { ThreatFeedType } from '../../filters';
import { ThAdapterAccessService } from '../../th-adapter-access';
import { IocScansParams } from './scan-list.model';

/**
 * Progressing scan status set
 */
const progressingScanStatusSet = new Set([
  ScanHealthStatus.Queued,
  ScanHealthStatus.Accepted,
  ScanHealthStatus.Running,
  ScanHealthStatus.Canceling,
]);

export const excludedStatuses = new Set([ScanHealthStatus.Accepted]);

export const getDcScanHealthStatus = () => getSortedAppStatus().filter(status => !excludedStatuses.has(status));

@Component({
  selector: 'dg-td-scan-list',
  templateUrl: './scan-list.component.html',
  styleUrls: ['./scan-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScanListComponent extends AutoDestroyable implements OnInit, OnChanges {
  /**
   * Origin state to handle back button
   */
  @Input() originState: OriginState;

  /**
   * predefined IOC scan params
   */
  @Input() filters: IocScansParams;

  /**
   * Anomalous snapshot Usecs
   */
  @Input() anomalousSnapshotUsecs;

  /**
   * Clean snapshot Usecs
   */
  @Input() cleanSnapshotUsecs;

  /**
   * flag indicating that scan-list component is being used in the context of an object
   */
  @Input() objectContext = false;

  /**
   * datahawk engines app status of the object
   */
  @Input() appStatus: AppStatus;

  readonly thSku = SkuType.threatProtect;

  readonly scanHealthFilterValues = getDcScanHealthStatus().map(type => ({
    label: this.translate.instant(`argus.enum.healthStatusType.${type}`),
    value: type,
  }));

  /**
   * Columns to be displayed
   */
  displayColumns = this.getDisplayColumns();

  /**
   * TH scan health stats map
   */
  healthStatsCountMap = new Map<ScanHealthStatus, number>();

  /**
   * object filter values
   */
  objectFilterValues: ValueFilterSelection[];

  /**
   * Scan Trigger Type filter value
   */
  filterValues: ValueFilterSelection[] = [
    ScanTrigger.anomaly,
    ScanTrigger.manually,
    this.isScheduledScansEnabled ? ScanTrigger.schedule : null,
  ]
    .filter(Boolean)
    .map(type => ({
      label: this.scanTriggerTypePipe.transform(type),
      value: type,
    }));

  /**
   * Default time frame options for the time range filter
   */
  readonly defaultTimeframeOptions = defaultTimeframeOptions;

  /**
   * An observable with detection scans.
   */
  scans: Scan[] = [];

  /**
   * Scans to be displayed.
   */
  filteredScans: Scan[] = [];

  /**
   * Indicate when to start for polling for running scans.
   */
  canInitiateNextPolling$ = new Subject<boolean>();

  /**
   * Hold data fetch status
   */
  isLoading = false;

  /**
   * IOC scan params
   */
  filterParams: IocScansParams;

  /**
   * flag to pause polling
   */
  pausePolling = true;

  /**
   * Contains cluster details providing cluster identifier Id
   */
  clusterIdentifierIdToDataSource: Map<ClusterIdentifierId, DataSource>;

  /**
   * Indicates if the custom threats feature flag is enabled or not
   */
  get isCustomThreatsEnabled(): boolean {
    return flagEnabled(this.irisCtx.irisContext, 'dataHawkThreatDetectionCustomThreatsEnabled');
  }

  /**
   * Indicates whether scheduled scans enabled or not
   */
  get isScheduledScansEnabled(): boolean {
    return flagEnabled(this.irisCtx.irisContext, 'dataHawkThreatDetectionScheduledScan');
  }

  /**
   * Indicates if the ioc/a integration feature flag is enabled or not
   */
  get isIntegrationsEnabled(): boolean {
    return flagEnabled(this.irisCtx.irisContext, 'dataHawkThreatDetectionIntegrationsEnabled');
  }

  constructor(
    private ajaxService: AjaxHandlerService,
    private cdr: ChangeDetectorRef,
    private iocScansApiService: IoCsScansApiService,
    private irisCtx: IrisContextService,
    private scanTriggerTypePipe: ScanTriggerTypePipe,
    private sourcesService: DataSourcesApiService,
    private translate: TranslateService,
    public thAdapterAccessService: ThAdapterAccessService
  ) {
    super();
  }

  ngOnInit(): void {
    this.runningScansPollInit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.includeAnomalyTriggered) {
      this.displayColumns = this.getDisplayColumns();
    }
  }

  /**
   * conditionally adds scan trigger type column
   *
   * @returns columns to display
   */
  private getDisplayColumns(): string[] {
    return ['name', 'healthStatus', 'scanTrigger', 'affectedAssets', 'completedOn'].filter(Boolean);
  }

  /**
   * Populates threat detection scans data
   */
  getScans(filterParams: IocScansParams) {
    const applyServerSideFiltering =
      isNil(this.filterParams) ||
      !isEqual(
        omit(this.filterParams, ['_name', '_completedOn', '_threatFeedTypes']),
        omit(filterParams, ['_name', '_completedOn', '_threatFeedTypes'])
      );

    if (applyServerSideFiltering) {
      this.isLoading = true;
    }
    const scan$: Observable<GetScansResponse> = applyServerSideFiltering
      ? this.getScans$(filterParams)
      : of({ scans: this.scans });

    combineLatest([
      scan$,
      this.iocScansApiService.getScannedObjects(),
      this.sourcesService.getRegisteredDataSources({
        abilities: [Ability.enabled, Ability.disabled],
      }),
    ])
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.cdr.detectChanges();
        }),
        map(([{ scans }, { objects }, { sources }]) => {
          const sortedScans =
            scans?.sort((a, b) =>
              this.compare(
                a.lastRun?.endTimeUsecs ?? Number.MAX_VALUE,
                b.lastRun?.endTimeUsecs ?? Number.MAX_VALUE,
                false
              )
            ) ?? [];
          this.clusterIdentifierIdToDataSource = clusterIdentifierIdToDataSourceMap(sources);
          this.objectFilterValues = getObjectFilterValues(this.clusterIdentifierIdToDataSource, objects);
          this.filterParams = filterParams;
          return sortedScans;
        }),
        tap(scans => (this.healthStatsCountMap = getScanStatsCountMap(scans)))
      )
      .subscribe(scans => {
        this.scans = scans;
        this.setScansToDisplay();
        this.canInitiateNextPolling$.next(true);
      }, this.ajaxService.handler);
  }

  /**
   * Creates link to scan details based on scan id
   *
   * @param id scan id
   * @returns scan details path
   */
  scanDetailLink(id: ScanId): string {
    return `/threat-detection/scans/${id}`;
  }

  private setScansToDisplay() {
    this.filteredScans = this.scans;
    if (this.filterParams?._name) {
      this.filteredScans = this.filteredScans.filter(scan =>
        scan.name.toLowerCase().includes(this.filterParams._name.toLowerCase())
      );
    }

    // filtering Scans by threat feed type
    if (this.filterParams?._threatFeedTypes?.length) {
      const filterVal = this.filterParams._threatFeedTypes;
      this.filteredScans = this.filteredScans.filter(
        scan =>
          (filterVal.includes(ThreatFeedType.builtIn) && scan.detectionType.builtInThreats) ||
          (filterVal.includes(ThreatFeedType.custom) && scan.detectionType.customThreatIds?.length) ||
          (filterVal.includes(ThreatFeedType.integration) && scan.detectionType.integrationTypes?.length)
      );
    }

    // filtering scans by completed on date
    if (this.filterParams._completedOn) {
      const filterVal = this.filterParams._completedOn;

      this.filteredScans = this.filteredScans.filter(
        scan =>
          scan.lastRun?.endTimeUsecs &&
          moment(scan.lastRun?.endTimeUsecs / 1000).isBetween(filterVal.start, filterVal.end, null, '[]')
      );
    }
  }

  // initialize polling to get status of running scans
  runningScansPollInit() {
    this.canInitiateNextPolling$
      .pipe(
        switchMap(() => interval(1000 * 5)),
        this.untilDestroy(),
        filter(() => !this.pausePolling),
        takeWhile(() => this.scans.filter(scan => progressingScanStatusSet.has(scan.lastRun.health.status)).length > 0),
        switchMap(() =>
          this.getScans$({
            ...this.filterParams,
            statuses: [...progressingScanStatusSet],
          })
        )
      )
      .subscribe(({ scans }) => {
        const progressScansMap = {};
        scans?.forEach(scan => (progressScansMap[scan.id] = scan));
        this.scans = this.scans.map(scan => progressScansMap[scan.id] ?? scan);
        this.setScansToDisplay();
      }, this.ajaxService.handler);
  }

  /**
   * Custom sorting for scan list
   *
   * @param state current sort state of the table.
   */
  sortScans(state: Sort) {
    if (!state.active && !state.direction.length) {
      return;
    }

    this.scans.sort((a, b) => {
      const isAsc = state.direction === 'asc';
      switch (state.active) {
        case 'name':
          return this.compare(a.name, b.name, isAsc);
        case 'totalObjectCount':
          return this.compare(a.lastRun?.stats?.totalObjectCount ?? 0, b.lastRun?.stats?.totalObjectCount ?? 0, isAsc);
        case 'healthStatus':
          return this.compare(a.lastRun?.health?.status, b.lastRun?.health?.status, isAsc);
        case 'totalIocCount':
          return this.compare(a.lastRun?.stats?.totalIocCount ?? 0, b.lastRun?.stats?.totalIocCount ?? 0, isAsc);
        case 'affectedAssets':
          return this.compare(
            a.lastRun?.stats?.affectedObjectCount ?? 0,
            b.lastRun?.stats?.affectedObjectCount ?? 0,
            isAsc
          );
        case 'completedOn':
          return this.compare(a.lastRun?.endTimeUsecs ?? 0, b.lastRun?.endTimeUsecs ?? 0, isAsc);
        default:
          return 0;
      }
    });
  }

  /**
   * comparison method for each scan.
   *
   * @param a number or string to compare.
   * @param b number or string to compare.
   * @param isAsc whether sorting direction is ascending.
   */
  private compare(a: number | string, b: number | string, isAsc: boolean = true): number {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  /**
   * Applies filter and update the table
   *
   * @param filters selected filters
   */
  applyFilters(filters: DataFilterValue<any, any>[]) {
    const filterParams: IocScansParams = { ...this.filters };

    filters.forEach(scanFilter => {
      const filterValues = isArray(scanFilter.value) ? scanFilter.value.map(item => item.value) : [scanFilter.value];
      switch (scanFilter.key) {
        case 'objectType':
          filterParams.environments = getEnvironmentFilterValues(scanFilter);
          break;
        case 'object':
          filterParams.objectIds = filterValues;
          break;
        case 'scanHealthStatus':
          filterParams.statuses = scanFilter.value.map(item => item.value);
          break;
        case 'scanTriggerType':
          filterParams.scanTriggers = scanFilter.value.map(item => item.value);
          break;
        case 'name':
          filterParams._name = filterValues[0];
          break;
        case 'threatFeedType':
          filterParams._threatFeedTypes = scanFilter.value.map(item => item.value);
          break;
        case 'completedOn':
          filterParams._completedOn = filterValues[0];
          break;
      }
    });
    this.getScans(filterParams);
  }

  private getScans$(filterParams: IocScansParams = {}): Observable<GetScansResponse> {
    this.pausePolling = true;
    return this.iocScansApiService.getScans(filterParams).pipe(
      finalize(() => {
        this.pausePolling = false;
        this.cdr.detectChanges();
      })
    );
  }
}
