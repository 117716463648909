/**
 * Schedule type of a protection run
 */
export enum ScheduleType {
  // TODO(maulik): Figure out what is the best way to declare constants and what
  // is the ideal data type for it
  kRegular = 'enum.jobRunType.kRegular',
  kFull = 'enum.jobRunType.kFull',
  kLog = 'enum.jobRunType.kLog',
}
