import { Component, inject, OnInit } from '@angular/core';
import {
  IrisContextService,
  isMcm,
  isDmsScope,
  isGlobalScope,
  isClusterScope,
  isCohesityServiceScope,
  isAllClustersScope,
  isDraasScope,
  isRpaasScope,
  isSecurityCenterScope,
  isMcmSaaS,
  isMcmOnPrem,
  isMcmGovCloud,
  getMcmAccountId,
  getSalesforceCompany,
  getStandaloneTenantId,
  getUserAnyTenantId,
  getUserTenantId,
  getUserTenantName,
  hasCompanyContext,
  isIbmBaaSEnabled,
  isOneHeliosAppliance,
  isOrganizationEnabled,
  isSmartFilesScope,
} from '../iris-context';
import { IS_IBM_AQUA_ENV } from '@cohesity/shared/core';

/**
 * This class is a developer utility to help identify various
 * flags, scopes, and context enabled through Iris Context
 */
@Component({
  selector: 'coh-iris-context-display',
  templateUrl: './iris-context-display.component.html',
  styleUrls: ['./iris-context-display.component.scss'],
})
export class IrisContextDisplayComponent implements OnInit {
  // Mcm flags
  isMcm;
  isMcmSaaS;
  isMcmOnPrem;
  isMcmGovCloud;

  // Scopes
  isCohesityServiceScope;
  isGlobalScope;
  isDmsScope;
  isRpaasScope;
  isDraasScope;
  isSecurityCenterScope;
  isSmartFilesScope;
  isClusterScope;
  isAllClustersScope;

  // platform specific identifiers
  isOneHeliosAppliance;
  isIbmBaaSEnabled;
  IS_IBM_AQUA_ENV = inject(IS_IBM_AQUA_ENV);

  // Org, Tenant, User Helpers
  isOrganizationEnabled;
  hasCompanyContext;
  getSalesforceCompany;
  getUserTenantId;
  getUserAnyTenantId;
  getStandaloneTenantId;
  getUserTenantName;
  getMcmAccountId;

  constructor(private irisCtx: IrisContextService) { }

  ngOnInit(): void {
    // Mcm flags
    this.isMcm = isMcm(this.irisCtx.irisContext);
    this.isMcmSaaS = isMcmSaaS(this.irisCtx.irisContext);
    this.isMcmOnPrem = isMcmOnPrem(this.irisCtx.irisContext);
    this.isMcmGovCloud = isMcmGovCloud(this.irisCtx.irisContext);

    // Scopes
    this.isCohesityServiceScope = isCohesityServiceScope(this.irisCtx.irisContext);
    this.isGlobalScope = isGlobalScope(this.irisCtx.irisContext);
    this.isDmsScope = isDmsScope(this.irisCtx.irisContext);
    this.isRpaasScope = isRpaasScope(this.irisCtx.irisContext);
    this.isDraasScope = isDraasScope(this.irisCtx.irisContext);
    this.isSecurityCenterScope = isSecurityCenterScope(this.irisCtx.irisContext);
    this.isSmartFilesScope = isSmartFilesScope(this.irisCtx.irisContext);
    this.isClusterScope = isClusterScope(this.irisCtx.irisContext);
    this.isAllClustersScope = isAllClustersScope(this.irisCtx.irisContext);


    // platform specific identifiers
    this.isOneHeliosAppliance = isOneHeliosAppliance(this.irisCtx.irisContext);
    this.isIbmBaaSEnabled = isIbmBaaSEnabled(this.irisCtx.irisContext);

    // Org, Tenant, User Helpers
    this.isOrganizationEnabled = isOrganizationEnabled(this.irisCtx.irisContext);
    this.hasCompanyContext = hasCompanyContext(this.irisCtx.irisContext);
    this.getSalesforceCompany = getSalesforceCompany(this.irisCtx.irisContext);
    this.getUserTenantId = getUserTenantId(this.irisCtx.irisContext);
    this.getUserAnyTenantId = getUserAnyTenantId(this.irisCtx.irisContext);
    this.getStandaloneTenantId = getStandaloneTenantId(this.irisCtx.irisContext);
    this.getUserTenantName = getUserTenantName(this.irisCtx.irisContext);
    this.getMcmAccountId = getMcmAccountId(this.irisCtx.irisContext);
  }
}
