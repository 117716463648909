/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityApiConfiguration as __Configuration } from '../cohesity-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { AntivirusServiceGroup } from '../models/antivirus-service-group';
import { UpdateAntivirusServiceGroupParameters } from '../models/update-antivirus-service-group-parameters';
import { AntivirusServiceGroupParams } from '../models/antivirus-service-group-params';
import { AntivirusServiceGroupStateParams } from '../models/antivirus-service-group-state-params';
import { IcapConnectionStatusResponse } from '../models/icap-connection-status-response';
import { InfectedFiles } from '../models/infected-files';
import { UpdateInfectedFileResponse } from '../models/update-infected-file-response';
import { UpdateInfectedFileParams } from '../models/update-infected-file-params';
import { DeleteInfectedFileResponse } from '../models/delete-infected-file-response';
import { DeleteInfectedFileParams } from '../models/delete-infected-file-params';
@Injectable({
  providedIn: 'root',
})
class AntivirusServiceGroupService extends __BaseService {
  static readonly GetAntivirusServiceGroupPath = '/public/antivirusGroups';
  static readonly UpdateAntivirusServiceGroupPath = '/public/antivirusGroups';
  static readonly CreateAntivirusServiceGroupPath = '/public/antivirusGroups';
  static readonly UpdateAntivirusServiceGroupStatePath = '/public/antivirusGroups/states';
  static readonly DeleteAntivirusServiceGroupPath = '/public/antivirusGroups/{id}';
  static readonly GetIcapConnectionStatusPath = '/public/icapConnectionStatus';
  static readonly GetInfectedFilesPath = '/public/infectedFiles';
  static readonly UpdateInfectedFilesPath = '/public/infectedFiles';
  static readonly DeleteInfectedFilesPath = '/public/infectedFiles';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Lists the antivirus service groups.
   *
   * Returns all the antivirus service group.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetAntivirusServiceGroupResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<Array<AntivirusServiceGroup>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/antivirusGroups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AntivirusServiceGroup>>;
      })
    );
  }
  /**
   * Lists the antivirus service groups.
   *
   * Returns all the antivirus service group.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetAntivirusServiceGroup(regionId?: string,
    accessClusterId?: number): __Observable<Array<AntivirusServiceGroup>> {
    return this.GetAntivirusServiceGroupResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as Array<AntivirusServiceGroup>)
    );
  }

  /**
   * Update an antivirus service group.
   *
   * Returns the updated antivirus service group.
   * @param params The `AntivirusServiceGroupService.UpdateAntivirusServiceGroupParams` containing the following parameters:
   *
   * - `body`: Request to update an Antivirus Service Group.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateAntivirusServiceGroupResponse(params: AntivirusServiceGroupService.UpdateAntivirusServiceGroupParams): __Observable<__StrictHttpResponse<AntivirusServiceGroup>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/public/antivirusGroups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AntivirusServiceGroup>;
      })
    );
  }
  /**
   * Update an antivirus service group.
   *
   * Returns the updated antivirus service group.
   * @param params The `AntivirusServiceGroupService.UpdateAntivirusServiceGroupParams` containing the following parameters:
   *
   * - `body`: Request to update an Antivirus Service Group.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateAntivirusServiceGroup(params: AntivirusServiceGroupService.UpdateAntivirusServiceGroupParams): __Observable<AntivirusServiceGroup> {
    return this.UpdateAntivirusServiceGroupResponse(params).pipe(
      __map(_r => _r.body as AntivirusServiceGroup)
    );
  }

  /**
   * Create an Antivirus service group.
   *
   * Returns the created Antivirus service group.
   * @param params The `AntivirusServiceGroupService.CreateAntivirusServiceGroupParams` containing the following parameters:
   *
   * - `body`: Request to create an Antivirus Service Group.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateAntivirusServiceGroupResponse(params: AntivirusServiceGroupService.CreateAntivirusServiceGroupParams): __Observable<__StrictHttpResponse<AntivirusServiceGroup>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/antivirusGroups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AntivirusServiceGroup>;
      })
    );
  }
  /**
   * Create an Antivirus service group.
   *
   * Returns the created Antivirus service group.
   * @param params The `AntivirusServiceGroupService.CreateAntivirusServiceGroupParams` containing the following parameters:
   *
   * - `body`: Request to create an Antivirus Service Group.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateAntivirusServiceGroup(params: AntivirusServiceGroupService.CreateAntivirusServiceGroupParams): __Observable<AntivirusServiceGroup> {
    return this.CreateAntivirusServiceGroupResponse(params).pipe(
      __map(_r => _r.body as AntivirusServiceGroup)
    );
  }

  /**
   * Change the state of an antivirus service group.
   *
   * Returns the state of an antivirus service group upon completion.
   * @param params The `AntivirusServiceGroupService.UpdateAntivirusServiceGroupStateParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`:
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateAntivirusServiceGroupStateResponse(params: AntivirusServiceGroupService.UpdateAntivirusServiceGroupStateParams): __Observable<__StrictHttpResponse<AntivirusServiceGroupStateParams>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    __body = params.body;
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/public/antivirusGroups/states`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AntivirusServiceGroupStateParams>;
      })
    );
  }
  /**
   * Change the state of an antivirus service group.
   *
   * Returns the state of an antivirus service group upon completion.
   * @param params The `AntivirusServiceGroupService.UpdateAntivirusServiceGroupStateParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`:
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateAntivirusServiceGroupState(params: AntivirusServiceGroupService.UpdateAntivirusServiceGroupStateParams): __Observable<AntivirusServiceGroupStateParams> {
    return this.UpdateAntivirusServiceGroupStateResponse(params).pipe(
      __map(_r => _r.body as AntivirusServiceGroupStateParams)
    );
  }

  /**
   * Delete an antivirus service group corresponding to the specified antivirus
   * service group Id.
   *
   * Returns delete status upon completion.
   * @param params The `AntivirusServiceGroupService.DeleteAntivirusServiceGroupParams` containing the following parameters:
   *
   * - `id`: Specifies the AntivirusServiceGroup Id.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteAntivirusServiceGroupResponse(params: AntivirusServiceGroupService.DeleteAntivirusServiceGroupParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/public/antivirusGroups/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete an antivirus service group corresponding to the specified antivirus
   * service group Id.
   *
   * Returns delete status upon completion.
   * @param params The `AntivirusServiceGroupService.DeleteAntivirusServiceGroupParams` containing the following parameters:
   *
   * - `id`: Specifies the AntivirusServiceGroup Id.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteAntivirusServiceGroup(params: AntivirusServiceGroupService.DeleteAntivirusServiceGroupParams): __Observable<null> {
    return this.DeleteAntivirusServiceGroupResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Check the Icap server connection status.
   *
   * Returns the list of succeeded and failed connection statuses of Icap servers.
   * @param params The `AntivirusServiceGroupService.GetIcapConnectionStatusParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `icapUris`: Specifies the list of icap uri.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetIcapConnectionStatusResponse(params: AntivirusServiceGroupService.GetIcapConnectionStatusParams): __Observable<__StrictHttpResponse<IcapConnectionStatusResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.icapUris || []).forEach(val => {if (val != null) __params = __params.append('icapUris', val.toString())});
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/icapConnectionStatus`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<IcapConnectionStatusResponse>;
      })
    );
  }
  /**
   * Check the Icap server connection status.
   *
   * Returns the list of succeeded and failed connection statuses of Icap servers.
   * @param params The `AntivirusServiceGroupService.GetIcapConnectionStatusParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `icapUris`: Specifies the list of icap uri.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetIcapConnectionStatus(params: AntivirusServiceGroupService.GetIcapConnectionStatusParams): __Observable<IcapConnectionStatusResponse> {
    return this.GetIcapConnectionStatusResponse(params).pipe(
      __map(_r => _r.body as IcapConnectionStatusResponse)
    );
  }

  /**
   * Lists the infected files.
   *
   * Returns all the infected files matching with query parameters.
   * @param params The `AntivirusServiceGroupService.GetInfectedFilesParams` containing the following parameters:
   *
   * - `viewNames`: Filter by a list of View names.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `paginationCookie`: Pagination cookie should be used from previous call to list infected
   *   files. It resumes (or gives the next set of values) from the result of the
   *   previous call.
   *
   * - `pageCount`: Specifies the number of items to return in the response for pagination
   *   purposes. Default value is 1000.
   *
   * - `includeUnquarantinedFiles`: Specifies whether to include unquarantined files in the result.
   *
   * - `includeQuarantinedFiles`: Specifies whether to include quarantined files in the result.
   *
   * - `filePath`: Specifies the path of a file. If this is provided, infected file list
   *   would contain the scan and infection state of the file and pagination
   *   cookie will be ignored.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetInfectedFilesResponse(params: AntivirusServiceGroupService.GetInfectedFilesParams): __Observable<__StrictHttpResponse<InfectedFiles>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.viewNames || []).forEach(val => {if (val != null) __params = __params.append('viewNames', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.paginationCookie != null) __params = __params.set('paginationCookie', params.paginationCookie.toString());
    if (params.pageCount != null) __params = __params.set('pageCount', params.pageCount.toString());
    if (params.includeUnquarantinedFiles != null) __params = __params.set('includeUnquarantinedFiles', params.includeUnquarantinedFiles.toString());
    if (params.includeQuarantinedFiles != null) __params = __params.set('includeQuarantinedFiles', params.includeQuarantinedFiles.toString());
    if (params.filePath != null) __params = __params.set('filePath', params.filePath.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/infectedFiles`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<InfectedFiles>;
      })
    );
  }
  /**
   * Lists the infected files.
   *
   * Returns all the infected files matching with query parameters.
   * @param params The `AntivirusServiceGroupService.GetInfectedFilesParams` containing the following parameters:
   *
   * - `viewNames`: Filter by a list of View names.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `paginationCookie`: Pagination cookie should be used from previous call to list infected
   *   files. It resumes (or gives the next set of values) from the result of the
   *   previous call.
   *
   * - `pageCount`: Specifies the number of items to return in the response for pagination
   *   purposes. Default value is 1000.
   *
   * - `includeUnquarantinedFiles`: Specifies whether to include unquarantined files in the result.
   *
   * - `includeQuarantinedFiles`: Specifies whether to include quarantined files in the result.
   *
   * - `filePath`: Specifies the path of a file. If this is provided, infected file list
   *   would contain the scan and infection state of the file and pagination
   *   cookie will be ignored.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetInfectedFiles(params: AntivirusServiceGroupService.GetInfectedFilesParams): __Observable<InfectedFiles> {
    return this.GetInfectedFilesResponse(params).pipe(
      __map(_r => _r.body as InfectedFiles)
    );
  }

  /**
   * Update the list of infected files.
   *
   * Returns the list of update succeeded and update failed infected files.
   * @param params The `AntivirusServiceGroupService.UpdateInfectedFilesParams` containing the following parameters:
   *
   * - `body`: Request to update the list of infected files.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateInfectedFilesResponse(params: AntivirusServiceGroupService.UpdateInfectedFilesParams): __Observable<__StrictHttpResponse<UpdateInfectedFileResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/public/infectedFiles`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UpdateInfectedFileResponse>;
      })
    );
  }
  /**
   * Update the list of infected files.
   *
   * Returns the list of update succeeded and update failed infected files.
   * @param params The `AntivirusServiceGroupService.UpdateInfectedFilesParams` containing the following parameters:
   *
   * - `body`: Request to update the list of infected files.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateInfectedFiles(params: AntivirusServiceGroupService.UpdateInfectedFilesParams): __Observable<UpdateInfectedFileResponse> {
    return this.UpdateInfectedFilesResponse(params).pipe(
      __map(_r => _r.body as UpdateInfectedFileResponse)
    );
  }

  /**
   * Delete the list of infected files.
   *
   * Returns the list of delete succeeded and delete failed infected files.
   * @param params The `AntivirusServiceGroupService.DeleteInfectedFilesParams` containing the following parameters:
   *
   * - `body`: Request to delete the list of infected files.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  DeleteInfectedFilesResponse(params: AntivirusServiceGroupService.DeleteInfectedFilesParams): __Observable<__StrictHttpResponse<DeleteInfectedFileResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/public/infectedFiles`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DeleteInfectedFileResponse>;
      })
    );
  }
  /**
   * Delete the list of infected files.
   *
   * Returns the list of delete succeeded and delete failed infected files.
   * @param params The `AntivirusServiceGroupService.DeleteInfectedFilesParams` containing the following parameters:
   *
   * - `body`: Request to delete the list of infected files.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  DeleteInfectedFiles(params: AntivirusServiceGroupService.DeleteInfectedFilesParams): __Observable<DeleteInfectedFileResponse> {
    return this.DeleteInfectedFilesResponse(params).pipe(
      __map(_r => _r.body as DeleteInfectedFileResponse)
    );
  }
}

module AntivirusServiceGroupService {

  /**
   * Parameters for UpdateAntivirusServiceGroup
   */
  export interface UpdateAntivirusServiceGroupParams {

    /**
     * Request to update an Antivirus Service Group.
     */
    body: UpdateAntivirusServiceGroupParameters;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreateAntivirusServiceGroup
   */
  export interface CreateAntivirusServiceGroupParams {

    /**
     * Request to create an Antivirus Service Group.
     */
    body: AntivirusServiceGroupParams;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateAntivirusServiceGroupState
   */
  export interface UpdateAntivirusServiceGroupStateParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;
    body?: AntivirusServiceGroupStateParams;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DeleteAntivirusServiceGroup
   */
  export interface DeleteAntivirusServiceGroupParams {

    /**
     * Specifies the AntivirusServiceGroup Id.
     */
    id: number;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetIcapConnectionStatus
   */
  export interface GetIcapConnectionStatusParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Specifies the list of icap uri.
     */
    icapUris?: Array<string>;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetInfectedFiles
   */
  export interface GetInfectedFilesParams {

    /**
     * Filter by a list of View names.
     */
    viewNames?: Array<string>;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Pagination cookie should be used from previous call to list infected
     * files. It resumes (or gives the next set of values) from the result of the
     * previous call.
     */
    paginationCookie?: string;

    /**
     * Specifies the number of items to return in the response for pagination
     * purposes. Default value is 1000.
     */
    pageCount?: number;

    /**
     * Specifies whether to include unquarantined files in the result.
     */
    includeUnquarantinedFiles?: boolean;

    /**
     * Specifies whether to include quarantined files in the result.
     */
    includeQuarantinedFiles?: boolean;

    /**
     * Specifies the path of a file. If this is provided, infected file list
     * would contain the scan and infection state of the file and pagination
     * cookie will be ignored.
     */
    filePath?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateInfectedFiles
   */
  export interface UpdateInfectedFilesParams {

    /**
     * Request to update the list of infected files.
     */
    body: UpdateInfectedFileParams;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DeleteInfectedFiles
   */
  export interface DeleteInfectedFilesParams {

    /**
     * Request to delete the list of infected files.
     */
    body: DeleteInfectedFileParams;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { AntivirusServiceGroupService }
