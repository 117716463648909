import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { CohesityHelixModule } from '@cohesity/helix';
import { ColoredTagModule } from '@cohesity/shared/tags';
import { TranslateModule } from '@ngx-translate/core';
import { HighchartsChartModule } from 'highcharts-angular';
import { PluginSharedModule } from 'src/app/plugin-shared';
import {
  FormsModule,
  SettingsListItemsModule,
  SharedModule,
  SourceTreeModule,
} from 'src/app/shared';
import { PipesModule } from 'src/app/shared/pipes';

import { ProtectionSharedModule } from '../../protection/protection-shared/protection-shared.module';
import { DataCentersSelectionComponent } from './components/data-centers-selection/data-centers-selection.component';
import { DynamicFormPortalComponent } from './components/dynamic-form-portal/dynamic-form-portal.component';
import { EntitiesOfTypeSelectorComponent } from './components/entities-of-type-selector/entities-of-type-selector.component';
import { ObjectTypeSearchFilterComponent } from './components/filters/object-type-search-filter/object-type-search-filter.component';
import { InstantVolumeMountCommonOptionsFormComponent } from './components/instant-volume-mount-common-options-form/instant-volume-mount-common-options-form.component';
import { MailboxAdvancedSearchDialogComponent } from './components/mailbox-advanced-search-dialog/mailbox-advanced-search-dialog.component';
import { NosqlMigrationObjectTableComponent } from './components/nosql-migration-object-table/nosql-migration-object-table.component';
import { NoSQLRecoveryObjectTableComponent } from './components/nosql-recovery-object-table/nosql-recovery-object-table.component';
import { ObjectSearchFieldComponent } from './components/object-search-field/object-search-field.component';
import { ObjectSearchFormComponent } from './components/object-search-form/object-search-form.component';
import {
  ObjectSearchItemDetailComponent,
  ObjectSearchItemDetailLineComponent,
} from './components/object-search-item-detail/object-search-item-detail.component';
import { ObjectSearchResultsComponent } from './components/object-search-results/object-search-results.component';
import { ObjectSelectionSummaryComponent } from './components/object-selection-summary/object-selection-summary.component';
import { OverwriteOptionComponent } from './components/overwrite-option/overwrite-option.component';
import { RecoverCommonOptionsFormComponent } from './components/recover-common-options-form/recover-common-options-form.component';
import { RecoverToCredentialsComponent } from './components/recover-to-credentials/recover-to-credentials.component';
import { RecoverToRadioGroupComponent } from './components/recover-to-radio-group/recover-to-radio-group.component';
import { RecoverToSourceSelectorComponent } from './components/recover-to-source-selector/recover-to-source-selector.component';
import { RecoverVmAwsTagsFormComponent } from './components/recover-vm-aws-tags-form/recover-vm-aws-tags-form.component';
import { RecoveryFileTableComponent } from './components/recovery-file-table/recovery-file-table.component';
import { RecoveryObjectPopoverComponent } from './components/recovery-object-popover/recovery-object-popover.component';
import { RecoveryObjectTableComponent } from './components/recovery-object-table/recovery-object-table.component';
import { RecoveryOptionsDetailsComponent } from './components/recovery-options-details/recovery-options-details.componnent';
import { RecoveryOptionsComponent } from './components/recovery-options/recovery-options.component';
import { RecoveryVolumeMappingTableComponent } from './components/recovery-volume-mapping-table/recovery-volume-mapping-table.component';
import { RestorePageComponent } from './components/restore-page/restore-page.component';
import { RestorePointGroupedSelectionDetailComponent } from './components/restore-point-grouped-selection-detail/restore-point-grouped-selection-detail.component';
import { RestorePointSelectionDetailComponent } from './components/restore-point-selection-detail/restore-point-selection-detail.component';
import { RpaasValidationBannerComponent } from './components/rpaas-validation-banner/rpaas-validation-banner.component';
import { SearchObjectIconModule } from './components/search-object-icon/search-object-icon.module';
import { SearchServersComponent } from './components/search-servers/search-servers.component';
import { SettingsListContinueOnErrorComponent } from './components/settings-list-items/settings-list-continue-on-error/settings-list-continue-on-error.component';
import {SettingsExcludedDiskHandlingComponent} from './components/settings-list-items/settings-list-excluded-disk-handling/settings-list-excluded-disk-handling.component';
import { SettingsListIpFiltersComponent } from './components/settings-list-items/settings-list-ip-filters/settings-list-ip-filters.component';
import { SettingsListLeverageSanTransportForRecoveryComponent } from './components/settings-list-items/settings-list-leverage-san-transport-for-recovery/settings-list-leverage-san-transport-for-recovery.component';
import { SettingsListObjectPropertyDetailsComponent } from './components/settings-list-items/settings-list-object-property-details/settings-list-object-property-details.component';
import { SettingsListObjectPropertyItemComponent } from './components/settings-list-items/settings-list-object-property-item/settings-list-object-property-item.component';
import { SettingsListObjectPropertyComponent } from './components/settings-list-items/settings-list-object-property/settings-list-object-property.component';
import { SettingsListObjectRenameItemComponent } from './components/settings-list-items/settings-list-object-rename-item/settings-list-object-rename-item.component';
import { SettingsListObjectRenameComponent } from './components/settings-list-items/settings-list-object-rename/settings-list-object-rename.component';
import { SettingsListPowerOnVmsComponent } from './components/settings-list-items/settings-list-power-on-vms/settings-list-power-on-vms.component';
import { SettingsListPreserveCustomAttributesComponent } from './components/settings-list-items/settings-list-preserve-custom-attributes/settings-list-preserve-custom-attributes.component';
import { SettingsListPreserveTagsComponent } from './components/settings-list-items/settings-list-preserve-tags/settings-list-preserve-tags.component';
import { SettingsListReadOnlyMountComponent } from './components/settings-list-items/settings-list-read-only-mount/settings-list-read-only-mount.component';
import { SettingsListRecoveryOptionsRenameComponent } from './components/settings-list-items/settings-list-recovery-options-rename/settings-list-recovery-options-rename.component';
import { PrivateEndpointsRowRecoverComponent } from './components/settings-list-items/settings-list-sas-url/private-endpoints-row-recover/private-endpoints-row-recover.component';
import { SettingsListSasUrlRecoverComponent } from './components/settings-list-items/settings-list-sas-url/settings-list-sas-url-recover.component';
import { SettingsListSaveSuccessFilesComponent } from './components/settings-list-items/settings-list-save-success-files/settings-list-save-success-files.component';
import { SettingsListTaskNameComponent } from './components/settings-list-items/settings-list-task-name/settings-list-task-name.component';
import { SettingsListThinCloneDetailsComponent } from './components/settings-list-items/settings-list-thin-clone-details/settings-list-thin-clone-details.component';
import { SettingsListOverwriteExistingFileComponent } from './components/settings-list-overwrite-existing-file/settings-list-overwrite-existing-file.component';
import { TapeMediaInfoComponent } from './components/tape-media-info/tape-media-info.component';
import { UdaRecoverToComponent } from './components/uda/recover-uda-recover-to/recover-uda-recover-to.component';
import { SettingsListRenameObjectComponent } from './components/uda/settings-list-rename-object/settings-list-rename-object.component';
import { SettingsListRestoreScriptArgumentsComponent } from './components/uda/settings-list-restore-script-arguments/settings-list-restore-script-arguments.component';
import { ViewSelectedObjectsDialogComponent } from './components/view-selected-objects-dialog/view-selected-objects-dialog.component';
import { VmCompleteMigrationDialogComponent } from './components/vm-complete-migration-dialog/vm-complete-migration-dialog.component';
import { VmMigrationObjectTableComponent } from './components/vm-migration-object-table/vm-migration-object-table.component';
import { VolumeBrowserModule } from './components/volume-browser';
import { VolumeSelectionFormComponent } from './components/volume-selection-form/volume-selection-form.component';
import { ProtectionGroupRunSelectorModalComponent } from './containers/protection-group-run-selector-modal/protection-group-run-selector-modal.component';
import { SnapshotSelectorModalComponent } from './containers/snapshot-selector-modal/snapshot-selector-modal.component';
import { SnapshotSelectorPluginModule } from './containers/snapshot-selector-plugin';
import { TargetSelectorFormComponent } from './containers/target-selector-form/target-selector-form.component';
import { NoSqlSnapshotSelectorComponent } from './nosql-snapshot-selector/nosql-snapshot-selector.component';
import { SnapshotSelectorModule } from './snapshot-selector';
import { TimeBasedSnapshotSelectorComponent } from './containers/time-based-snapshot-selector/time-based-snapshot-selector.component';

const settingsListComponents = [
  PrivateEndpointsRowRecoverComponent,
  SettingsListContinueOnErrorComponent,
  SettingsExcludedDiskHandlingComponent,
  SettingsListIpFiltersComponent,
  SettingsListLeverageSanTransportForRecoveryComponent,
  SettingsListObjectPropertyComponent,
  SettingsListObjectPropertyDetailsComponent,
  SettingsListObjectPropertyItemComponent,
  SettingsListObjectRenameComponent,
  SettingsListObjectRenameItemComponent,
  SettingsListOverwriteExistingFileComponent,
  SettingsListPowerOnVmsComponent,
  SettingsListPreserveCustomAttributesComponent,
  SettingsListPreserveTagsComponent,
  SettingsListReadOnlyMountComponent,
  SettingsListRecoveryOptionsRenameComponent,
  SettingsListRenameObjectComponent,
  SettingsListRestoreScriptArgumentsComponent,
  SettingsListSasUrlRecoverComponent,
  SettingsListSaveSuccessFilesComponent,
  SettingsListTaskNameComponent,
  SettingsListThinCloneDetailsComponent,
];

const exportedComponents = [
  ...settingsListComponents,
  DataCentersSelectionComponent,
  DynamicFormPortalComponent,
  EntitiesOfTypeSelectorComponent,
  InstantVolumeMountCommonOptionsFormComponent,
  MailboxAdvancedSearchDialogComponent,
  NosqlMigrationObjectTableComponent,
  NoSQLRecoveryObjectTableComponent,
  ObjectSearchFieldComponent,
  ObjectSearchFormComponent,
  ObjectSearchItemDetailComponent,
  ObjectSearchItemDetailLineComponent,
  ObjectSearchResultsComponent,
  ObjectSelectionSummaryComponent,
  ObjectTypeSearchFilterComponent,
  OverwriteOptionComponent,
  RecoverCommonOptionsFormComponent,
  RecoverToCredentialsComponent,
  RecoverToRadioGroupComponent,
  RecoverToSourceSelectorComponent,
  RecoverVmAwsTagsFormComponent,
  RecoveryFileTableComponent,
  RecoveryObjectPopoverComponent,
  RecoveryObjectTableComponent,
  RecoveryOptionsComponent,
  RecoveryOptionsDetailsComponent,
  RecoveryVolumeMappingTableComponent,
  RestorePageComponent,
  RestorePointGroupedSelectionDetailComponent,
  RestorePointSelectionDetailComponent,
  RpaasValidationBannerComponent,
  SearchServersComponent,
  TapeMediaInfoComponent,
  TargetSelectorFormComponent,
  TimeBasedSnapshotSelectorComponent,
  UdaRecoverToComponent,
  ViewSelectedObjectsDialogComponent,
  VmCompleteMigrationDialogComponent,
  VmMigrationObjectTableComponent,
  VolumeSelectionFormComponent,
];

const components = [
  ...exportedComponents,
  NoSqlSnapshotSelectorComponent,
  ProtectionGroupRunSelectorModalComponent,
  SnapshotSelectorModalComponent,
];
@NgModule({
  declarations: [...components],
  imports: [
    CohesityHelixModule,
    ColoredTagModule,
    CommonModule,
    FormsModule,
    HighchartsChartModule,
    MatDatepickerModule,
    PipesModule,
    PluginSharedModule,
    ProtectionSharedModule,
    ReactiveFormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
    ScrollingModule,
    SearchObjectIconModule,
    SettingsListItemsModule,
    SharedModule,
    SnapshotSelectorModule,
    SnapshotSelectorPluginModule,
    SourceTreeModule,
    TranslateModule.forChild(),
  ],
  exports: [
    ...exportedComponents,
    SearchObjectIconModule,
    SnapshotSelectorModule,
    VolumeBrowserModule,
  ],
})
export class RestoreSharedModule {}
