import { MongodbPhysicalFormOptions } from 'src/app/modules/restore/recover-mongodb-physical/model';
import { RecoverMongoDBFormOptions } from 'src/app/modules/restore/recover-mongodb/model';
import { CreateRecoveryForm } from 'src/app/modules/restore/restore-shared';

/**
 * MongoDB authentication types.
 */
export enum MongodbAuthTypes {

  /**
   * None
   */
  kNone = 'NONE',

  /**
   * SCRAM based authentication.
   */
  kScram = 'SCRAM',

  /**
   * LDAP based authentication.
   */
  kLdap = 'LDAP',
  /**
   * KERBEROS based authentication.
   */
  kKerberos = 'KERBEROS'
}

/*
 * Recovery related constants
 ******************************************************************************/

/**
 * Shorthand for the CreateRecoveryForm typed for MongoDB.
 */
export type MongoDBRecoveryForm = CreateRecoveryForm<RecoverMongoDBFormOptions>;

/**
 * Shorthand for the CreateRecoveryForm typed for MongoDB.
 */
export type MongoDBPhysicalRecoveryForm = CreateRecoveryForm<MongodbPhysicalFormOptions>;
