import { Inject, Injectable } from '@angular/core';
import { ClusterUiConfig, PlatformServiceApi, UpdateUserPreferences, UserServiceApi } from '@cohesity/api/v2';
import {
  ByteScale,
  ByteSizeService,
  DATE_PIPE_OPTIONS,
  DatePipeOptions,
  HourFormat,
  SnackBarService,
  ThemeService,
  TimeFormat,
  WindowRef,
  localStorageThemeModeKey,
} from '@cohesity/helix';
import {
  AppPillarsService,
  flagEnabled,
  getConfigByKey,
  HeliosLandingPageApp,
  IrisContextService,
  isLoggedIn,
  isMcm,
  UserPreferenceService,
} from '@cohesity/iris-core';
import { adaptersMap, Environment } from '@cohesity/iris-shared-constants';
import { CardConfig, UserDefinedDashboard } from '@cohesity/iris/feat-custom-dashboard';
import moment from 'moment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';
import { Locale } from 'src/app/models';
import {
  CommonRecoverToOptions,
  RecoverToOptions,
} from 'src/app/modules/restore/restore-shared/model/recover-to-options';
import { DefaultLocale } from 'src/app/shared/constants';
import { LocaleService } from './locale.service';
import { environment as appEnvironment } from 'src/environments/environment';
import { AjaxHandlerService } from '@cohesity/utils';

/**
 * Configuration of landing page (Cohesity Data Cloud).
 */
export interface LandingPageConfig {
  /** List of reports in Reports card. */
  reports: string[];
}

/**
 * Common user preference.
 */
export interface CommonUserPreferences {
  /**
   * Customized time format.
   */
  timeFormat: TimeFormat;

  /**
   * Preferred unit of byte scaling.
   */
  byteScaling: ByteScale;

  /**
   * if it keeps snackbar displayed permanent
   */
  snackBarPersist: boolean;

  /**
   * Custom dashboard data.
   */
  customDashboard?: Record<string, CardConfig<any>[]>;

  /**
   * User defined dashboards.
   */
  userDashboards?: UserDefinedDashboard<any>[];

  /**
   * Landing page configuration.
   */
  landingPageConfig?: LandingPageConfig;
}

/**
 * Interface for user customizations.
 */
export interface UserPreferences extends CommonUserPreferences {
  /**
   * Users preferred dashboard (based on state name).
   */
  preferredDashboard: string;

  /**
   * Array of workflows which are disabled.
   */
  disabledWorkflows: string[];

  /**
   * Preferred recovery location. This only supports selection New or Original.
   */
  recoveryLocation: CommonRecoverToOptions;
}

/**
 * Interface for Helios user customizations.
 */
export interface HeliosUserPreferences extends CommonUserPreferences {
  /**
   * Dark theme if true.
   */
  darkTheme: boolean;

  /**
   * Hide unsubscribed services if true.
   */
  hideUnsubscribedServices: boolean;

  /**
   * Default landing page for Helios
   */
  heliosDefaultLandingApp: HeliosLandingPageApp;
}

/**
 * A list of user preferences.
 */
export interface UserCustomizations {
  /**
   * The selected locale of the user.
   */
  locale: Locale;

  /**
   * The selected customizations of the user.
   * This is converted to a stringified json when saving to user preferences
   * API as that API only supports strings as values.
   */
  preferences?: UserPreferences;

  /**
   * User preferences for Helios.
   */
  heliosPreferences?: HeliosUserPreferences;
}

/**
 * Represents configuration for cluster customizations.
 */
export interface ClusterCustomizations {
  /**
   * Dashboards which should be hidden (not displayed) based on state name
   * property/value.
   */
  hiddenDashboardItems: string[];

  /**
   * Primary navigation items which should be hidden (not displayed) based
   * on displayName property/value.
   */
  hiddenNavItems?: string[];

  /**
   * Adapters/environments that should be hidden in UI.
   */
  hiddenAdapters?: (string | Environment)[];
}


/**
 * A default, empty customization object for use when no customizations exist.
 */
const EmptyClusterCustomizations: ClusterCustomizations = {
  /**
   * Dashboards which should be hidden by default.
   */
  hiddenDashboardItems: [],

  /**
   * Nav items which should be hidden by default.
   */
  hiddenNavItems: [],

  /**
   * List of adapters to be hidden throughout the UI.
   */
  hiddenAdapters: [],
};

/**
 * A default, empty customization object for use when no user customizations exist.
 */
const CommonEmptyUserPreferences: CommonUserPreferences = {
  /**
   * Default time format settings.
   */
  timeFormat: {
    hourFormat: HourFormat.Hour12,
    timeZone: moment.tz.guess(),
  },

  /**
   * Default byte scaling.
   */
  byteScaling: 'base2',

  /**
   * Default snackbar to not persist
   */
  snackBarPersist: false,
};

/**
 * A default, empty customization object for use when no user customizations exist.
 */
const EmptyUserPreferences: UserPreferences = {
  ...CommonEmptyUserPreferences,

  /**
   * Users preferred dashboard. Default to the summary dashboard.
   */
  preferredDashboard: 'dashboards.summary',

  /**
   * Workflows which should be enabled by default.
   */
  disabledWorkflows: [],

  /**
   * Default recovery location preference.
   */
  recoveryLocation: RecoverToOptions.originalLocation,
};

/**
 * A default, empty customization object for use when no helios user customizations exist.
 */
export const EmptyHeliosUserPreferences: HeliosUserPreferences = {
  ...CommonEmptyUserPreferences,

  /** default dark theme. */
  darkTheme: localStorage.getItem(localStorageThemeModeKey) === 'light' ? false : true,

  /** default show unsubscribed services. */
  hideUnsubscribedServices: false,

  /**
   * Default landing page for helios.
   */
  heliosDefaultLandingApp: 'cohesityDataCloud',
};

/**
 * A default, empty user preferences object for use when no user preferences exist.
 */
const EmptyUserCustomizations = {
  /**
   * The default locale.
   */
  locale: DefaultLocale,

  /**
   * The default customizations.
   */
  preferences: EmptyUserPreferences,

  /**
   * The default customizations.
   */
  heliosPreferences: EmptyHeliosUserPreferences,
};

@Injectable({
  providedIn: 'root',
})
export class CustomizationService {
  /**
   * Customizations behavior subject for tracking the current configuration.
   */
  private clusterCustomizationsSubject = new BehaviorSubject<ClusterCustomizations>(null);

  /**
   * User preferences behavior subject for tracking the current prefs.
   */
  public userCustomizationsSubject = new BehaviorSubject<UserCustomizations>(null);

  /**
   * Whether the UI Customization is enabled. It is only enabled when
   * uiCustomization flag is enabled and UI is not in MCM mode.
   */
  get uiCustomizationEnabled(): boolean {
    const irisContext = this.irisContextService.irisContext;
    if (!isMcm(irisContext) || appEnvironment.heliosInFrame) {
      return flagEnabled(irisContext, 'uiCustomization');
    }

    return flagEnabled(irisContext, 'mcmUserPreferences');
  }

  /**
   * Gets the current cluster context. It's generally safe for shorter-lived
   * components to access this property directly. Longer-lived components (app frame, etc...)
   * or components that need to respond to changes in the cluster context should subscribe
   * to the observable.
   */
  get userCustomizations(): UserCustomizations {
    return this.userCustomizationsSubject.value;
  }

  /**
   * A shortcut to access preferences based on userCustomizations.
   */
  get preferences(): UserPreferences | HeliosUserPreferences {
    return isMcm(this.irisContextService.irisContext) ?
      this.userCustomizations.heliosPreferences :
      this.userCustomizations.preferences;
  }

  /**
   * Default recovery location preference set by user.
   * This cannot be returned as observable since some component methods
   * need this value to instantiate form.
   */
  get recoveryLocation(): CommonRecoverToOptions {
    return this.userCustomizations?.preferences?.recoveryLocation || RecoverToOptions.originalLocation;
  }

  /**
   * Observable for the specific hiddenNavItems property of the cluster configuration.
   */
  readonly hiddenNavItems$ = this.clusterCustomizationsSubject.pipe(
    map(clusterCustomizations => clusterCustomizations?.hiddenNavItems || [])
  );

  /**
   * List of adapters hidden by user customization.
   */
  readonly hiddenAdapters$ = this.clusterCustomizationsSubject.pipe(
    map((clusterCustomizations: ClusterCustomizations) => getConfigByKey(this.irisContextService.irisContext, 'customizations.hiddenAdapters', clusterCustomizations?.hiddenAdapters || []))
  );

  /**
   * The list of hidden adapters from the observable so that the
   * view can call isHiddenAdapter without a subscription.
   */
  hiddenAdapters = [];

  /**
   * Observable for the specific hiddenNavItems property of the cluster configuration.
   */
  readonly hiddenDashboards$ = this.clusterCustomizationsSubject.pipe(
    map(clusterCustomizations => clusterCustomizations?.hiddenDashboardItems || [])
  );

  /**
   * Observable for the cluster customizations configuration.
   */
  readonly clusterCustomizations$ = this.clusterCustomizationsSubject.asObservable();

  /**
   * Observable for User Preferences.
   */
  readonly userCustomizations$ = this.userCustomizationsSubject.asObservable();

  /**
   * Observable for flows for which simple create workflows should be disabled.
   */
  readonly disabledWorkflows$ = this.userCustomizations$.pipe(
    map(value => value?.preferences?.disabledWorkflows || []),
  );

  constructor(
    private appPillarsService: AppPillarsService,
    private irisContextService: IrisContextService,
    private localeService: LocaleService,
    private platformService: PlatformServiceApi,
    private usersServiceApi: UserServiceApi,
    private byteSizeService: ByteSizeService,
    private snackBarService: SnackBarService,
    private themeService: ThemeService,
    private userPreferenceService: UserPreferenceService,
    private ajaxService: AjaxHandlerService,
    private window: WindowRef,
    @Inject(DATE_PIPE_OPTIONS) private globalDateOptions: DatePipeOptions,
  ) {
    this.irisContextService.irisContext$.pipe(
      // If MFA is enabled, a temporary user access is generated with restricted
      // privileges.
      filter(ctx => isLoggedIn(ctx)),
      distinctUntilChanged((oldCtx, newCtx) =>
        flagEnabled(oldCtx, 'uiCustomization') === flagEnabled(newCtx, 'uiCustomization')
        && flagEnabled(oldCtx, 'mcmUserPreferences') === flagEnabled(newCtx, 'mcmUserPreferences')),
    ).subscribe(() => {
      // Whenever there is a new user object, initialize the customization
      // service.
      this.init();
    });

    this.userCustomizations$.pipe(
      filter(customizations => !!customizations)
    ).subscribe((customizations: UserCustomizations) => {
      const isCluster = !isMcm(this.irisContextService.irisContext);
      const mcmPrefEnabled = flagEnabled(this.irisContextService.irisContext, 'appPillarsPreferencesEnabled');

      // Section for preference applicable for MCM as well as cluster UI
      if (isCluster || mcmPrefEnabled) {
        this.globalDateOptions.locale = customizations.locale;

        const preferences = isCluster ?
          { ...EmptyUserPreferences, ...customizations.preferences } :
          { ...EmptyHeliosUserPreferences, ...customizations.heliosPreferences };

        this.byteSizeService.byteScale = preferences.byteScaling;

        // Update date formatting preferences. These are updated by object reference so
        // the individual properties need to be overwritten.
        this.globalDateOptions.hourFormat = preferences.timeFormat.hourFormat;
        this.globalDateOptions.timezone = preferences.timeFormat.timeZone;

        this.snackBarService.persistSnackbar = preferences.snackBarPersist;

        if (!isCluster) {
          // theme toggle
          if (Object.prototype.hasOwnProperty.call(preferences, 'darkTheme')) {
            // UserPreferenceService is subscribed to theme changes from masthead icon
            // and this prevents theme preference from being saved twice.
            this.userPreferenceService.savingPreferences = true;
            this.themeService.setMode(
              (preferences as HeliosUserPreferences).darkTheme ? 'dark' : 'light'
            );
            this.userPreferenceService.savingPreferences = false;
          }

          this.appPillarsService.hideUnsubscribedServices =
            (preferences as HeliosUserPreferences).hideUnsubscribedServices;
        }
      }
    });
  }

  /**
   * Initialize the customization service.
   */
  init() {
    this.loadUserPreferences();
    this.loadClusterCustomizations();
  }

  /**
   * Loads user customizations and updates the behavior subject.
   */
  private loadUserPreferences() {
    if (!this.uiCustomizationEnabled) {
      // If customizations feature is not enabled, default user preferences should be provided.
      this.userCustomizationsSubject.next(EmptyUserCustomizations);
      return;
    }

    let userPreferences = of({ locale: DefaultLocale } as UserCustomizations);

    if (isMcm(this.irisContextService.irisContext)) {
      if (flagEnabled(this.irisContextService.irisContext, 'mcmUserPreferences')) {
        userPreferences = this.getHeliosUserCustomizations();
      }
    } else {
      userPreferences = this.getClusterUserCustomizations();
    }

    userPreferences.subscribe(userCustomizations => this.userCustomizationsSubject.next(userCustomizations));
  }

  /**
   * Gets user customizations for current MCM user.
   *
   * @returns User Customizations.
   */
  private getHeliosUserCustomizations(): Observable<UserCustomizations> {
    this.userPreferenceService.load();
    return this.userPreferenceService.userPreferences$.pipe(
      catchError(() => of({ locale: DefaultLocale })),
      map(response => {
        const userCustomizations = {} as UserCustomizations;

        if (response?.locale) {
          userCustomizations.locale = response.locale as Locale;
        } else {
          userCustomizations.locale = DefaultLocale;
        }

        // TODO: The response here should have a preferences key, but it is
        // not implemented yet. Fallback to localStorage for helios
        // user preferences.
        const tempPreferences = ((response as any)?.preferences) || localStorage.getItem('heliosUserPreferences');

        if (tempPreferences?.length) {
          // Merge in the empty customizations to ensure all things are represented.
          userCustomizations.heliosPreferences = {
            ...EmptyHeliosUserPreferences,
            ...JSON.parse(tempPreferences) as HeliosUserPreferences,
          };
        } else {
          userCustomizations.heliosPreferences = EmptyHeliosUserPreferences;
        }

        return userCustomizations;
      })
    );
  }

  /**
   * Gets user customizations for current cluster UI user.
   *
   * @returns User Customizations.
   */
  private getClusterUserCustomizations(): Observable<UserCustomizations> {
    return this.usersServiceApi.GetUserUiConfig().pipe(
      catchError(() => of({ locale: DefaultLocale, preferences: '' })),
      map(response => {
        const userCustomizations = {} as UserCustomizations;

        if (response?.locale) {
          userCustomizations.locale = response.locale as Locale;
        } else {
          userCustomizations.locale = DefaultLocale;
        }

        if (response?.preferences?.length) {
          // Merge in the empty customizations to ensure all things are represented.
          userCustomizations.preferences = {
            ...EmptyUserPreferences,
            ...JSON.parse(response.preferences) as UserPreferences,
          };
        } else {
          userCustomizations.preferences = EmptyUserPreferences;
        }

        return userCustomizations;
      }),
    );
  }

  /**
   * Gets the cluster customizations and updates the BehaviorSubject.
   */
  private loadClusterCustomizations() {
    if (isMcm(this.irisContextService.irisContext) || !this.uiCustomizationEnabled) {
      // If customizations feature is not enabled, no customizations should be provided.
      this.clusterCustomizationsSubject.next({...EmptyClusterCustomizations});
      return;
    }

    this.hiddenAdapters$.subscribe(hiddenAdapters => this.hiddenAdapters = hiddenAdapters);

    this.platformService.GetClusterUiConfig().pipe(
      catchError(e => {
        this.ajaxService.handler(e);
        return of({uiConfig: ''});
      }),
    ).subscribe((response: ClusterUiConfig)=> {
      let clusterCustomizations: ClusterCustomizations;

      if (response?.uiConfig?.length) {
        // Merge in the empty customizations to ensure all things are represented.
        clusterCustomizations = { ...EmptyClusterCustomizations, ...JSON.parse(response.uiConfig) };
      } else {
        clusterCustomizations = EmptyClusterCustomizations;
      }

      this.clusterCustomizationsSubject.next(clusterCustomizations);
    });
  }

  /**
   * Checks if specified environment is hidden by the user.
   *
   * @param environment Environment or entity to check if it's hidden.
   * @returns True is environment is hidden by user.
   */
  isHiddenEnvironment(environment): boolean {

    if (this.hiddenAdapters.includes(environment)) {
      return true;
    }

    for (const adapter of this.hiddenAdapters) {
      if (adaptersMap[adapter]) {
        const { environments, hiddenEnvironments } = adaptersMap[adapter];
        const checkEnvironments = environments?.length > 0 && environments.includes(environment);
        const checkHiddenEnvironments = hiddenEnvironments?.length > 0 && hiddenEnvironments.includes(environment);
        if (checkEnvironments || checkHiddenEnvironments) {
          return true;
        }
      }
    }

    return false;
  }

  /**
   * Handles saving a ClusterCustomization object.
   *
   * @param config   The cluster customization configuration to save.
   * @returns  Observable providing the saved configuration.
   */
  saveClusterCustomizations(config: ClusterCustomizations): Observable<ClusterCustomizations> {
    if (!config) {
      throw new Error('Must provide a customization object to save.');
    }

    if (!this.uiCustomizationEnabled) {
      throw new Error('Feature flag must be enabled to save customizations.');
    }

    this.clusterCustomizationsSubject.next({ ...config });

    return this.platformService.UpdateClusterUiConfig({
      body: {uiConfig: JSON.stringify(config)}
    }).pipe(
      map(response => {
        const clusterCustomizations: ClusterCustomizations =
          JSON.parse(response.uiConfig) || EmptyClusterCustomizations;

        return clusterCustomizations;
      }),
      tap(customizations => this.clusterCustomizationsSubject.next(customizations)),
    );
  }

  /**
   * Gets user preferences from customizations.
   *
   * @returns User preferences.
   */
  getPreferences(): HeliosUserPreferences | UserPreferences {
    const customizations: UserCustomizations = this.userCustomizations || {} as UserCustomizations;

    if (isMcm(this.irisContextService.irisContext)) {
      return customizations.heliosPreferences ?? {} as HeliosUserPreferences;
    }
    return customizations.preferences ?? {} as UserPreferences;
  }

  /**
   * Save user preferences.
   *
   * @param preferences  User preferences
   * @returns API result.
   */
  savePreferences(
    preferences: HeliosUserPreferences | UserPreferences
  ): Observable<HeliosUserPreferences | UserPreferences> {
    const customizations: UserCustomizations = this.userCustomizations || {} as UserCustomizations;

    if (isMcm(this.irisContextService.irisContext)) {
      customizations.heliosPreferences = preferences as HeliosUserPreferences;
    } else {
      customizations.preferences = preferences as UserPreferences;
    }
    return this.saveUserCustomizations(customizations).pipe(
      map(result => isMcm(this.irisContextService.irisContext) ? result.heliosPreferences : result.preferences),
    );
  }

  /**
   * Handles saving user customizations.
   *
   * @param prefs   Updated user preferences to be saved.
   * @returns   An observable resolving with the updated user prefs.
   */
  saveUserCustomizations(prefs: UserCustomizations): Observable<UserCustomizations> {
    const previousLocale = this.userCustomizationsSubject.value.locale;

    return (isMcm(this.irisContextService.irisContext)
    ? this.saveHeliosUserCustomizations(prefs)
    : this.saveClusterUserCustomizations(prefs)).pipe(
      switchMap(userCustomizations =>
        // If the locale was changed, load the new locale.
        userCustomizations.locale === previousLocale
          ? of(userCustomizations)
          : this.localeService.loadLocale(userCustomizations.locale).pipe(
              map(() => userCustomizations),
            )
      ),
      tap(customizations => {
        this.userCustomizationsSubject.next(customizations);
        if (customizations.locale !== previousLocale) {
          this.window.nativeWindow.location.reload();
        }
      }),
    );
  }

  /**
   * Saves user preferences for MCM user.
   *
   * @param prefs Preferences to be saved.
   * @returns Saved preferences.
   */
  private saveHeliosUserCustomizations(prefs: UserCustomizations): Observable<UserCustomizations> {
    const stringifiedPreferences = JSON.stringify(prefs?.heliosPreferences || {});

    // This API currently doesn't support a "preferences" key.
    // Temporarily store the preferences value in local storage.
    const body: UpdateUserPreferences & {preferences: string} = {
      locale: prefs?.locale,
      preferences: stringifiedPreferences,
    };

    return this.userPreferenceService.save(body).pipe(
      map(response => ({
        locale: response?.locale || DefaultLocale,
        heliosPreferences:
          ((response as any)?.preferences
            ? JSON.parse((response as any)?.preferences)
            : prefs?.heliosPreferences) ||
          EmptyHeliosUserPreferences,
      } as UserCustomizations))
    );
  }

  /**
   * Saves user preferences for cluster user.
   *
   * @param prefs Preferences to be saved.
   * @returns Saved preferences.
   */
  private saveClusterUserCustomizations(prefs: UserCustomizations): Observable<UserCustomizations> {
    return this.usersServiceApi.UpdateUserUiConfig({
      body: {
        locale: prefs.locale as string,

        // Customizations object can have arrays, etc., in it, so store is as
        // stringified json.
        preferences: JSON.stringify(prefs.preferences),
      }
    }).pipe(
      map(response => {
        const userCustomizations: UserCustomizations = {
          locale: response.locale as Locale,
          preferences: JSON.parse(response.preferences) || EmptyUserPreferences,
        };

        return userCustomizations;
      }),
    );
  }
}
