import { ComponentType } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Incidence } from '@cohesity/api/argus';
import { WebComponent, WebComponentModule } from '@cohesity/helix';
import { TranslateModule } from '@ngx-translate/core';

import { AnomalyAlert } from '../../../security-shared.models';

@Component({
  standalone: true,
  selector: 'dg-ar-chat-prompt-toggle',
  templateUrl: './chat-prompt-toggle.component.html',
  styleUrls: ['./chat-prompt-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    TranslateModule,
    WebComponentModule,
  ],
})
export class ChatPromptToggleComponent implements OnChanges, AfterViewInit {
  @Input() anomalyAlert?: AnomalyAlert;

  /**
   * The timeframe for which the alert is being fetched.
   * eg: Past30Days
   */
  @Input() timeframe?: string;

  /** The custom element component instaince */
  @ViewChild(WebComponent) webComponent: WebComponent<ComponentType<{ incidence: Incidence }>>;

  ngAfterViewInit() {
    this.updateInput();
  }

  ngOnChanges(_changes: SimpleChanges) {
    this.updateInput();
  }

  /**
   * Update the rendered web component inputs.
   */
  updateInput() {
    this.webComponent?.updateInput({
      incidence: this.anomalyAlert?.incidence || null,
      timeframe: this.timeframe,
    });
  }
}
