<cog-filters #filters (valueChange)="table.dataSource.filter = $event[0]?.value || ''">
  <ng-container *ngIf="showFilter && columns.length">
    <cog-search-property-filter
      *cogFilterDef="let filter; key: columns[0].key; label: ''; quickFilter: true; filterType: 'searchProperty'"
      [filter]="filter"
      [alwaysExpand]="true"
      [placeholder]="translate('placeholderSearch')">
    </cog-search-property-filter>
  </ng-container>
</cog-filters>
<cog-table #table [dataSource]="dataSource" [selection]="selection" [name]="name" class="margin-top-sm">
  <ng-content select="cog-table-multi-action"></ng-content>
  <table mat-table matSort>
    <ng-container *ngFor="let config of columns; let i = index">
      <ng-container [matColumnDef]="config.key">
        <ng-container *ngIf="!config.actionsColumn">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            [start]="config.sortStart || 'asc'"
            [ngClass]="{'header-text-align': alignHeader}">
            <span class="header-text"
              [matTooltip]="getTooltip(config.tooltip)"
              matTooltipPosition="above"
              [innerHTML]="(config.translate ? translate(config.title) : config.title) +
                ((config.showTitleDate && columnDate && dateFormat) ? (' (' + (columnDate | cogDate: dateFormat) + ')') : '')"
            ></span>
          </th>
        </ng-container>

        <ng-container *ngIf="config.actionsColumn">
          <th mat-header-cell *matHeaderCellDef class="actions-column">
            <span class="header-text" [innerHTML]="(config.translate ? translate(config.title) : config.title)">
            </span>
          </th>
        </ng-container>

        <td mat-cell *matCellDef="let row"
          [class.actions-column]="config.actionsColumn"
          [class.cog-table-object-name]="i === 0">
          <cog-auto-table-cell [config]="config" [row]="row" [translate]="translate"></cog-auto-table-cell>
        </td>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="!noHeader">
      <tr mat-header-row *matHeaderRowDef="columnIds"></tr>
    </ng-container>

    <tr mat-row *matRowDef="let row; columns: columnIds"></tr>

    <!-- NoData Footer -->
    <ng-container matColumnDef="noData">
      <td [attr.colspan]="columns.length + (selection ? 1 : 0)" mat-footer-cell *matFooterCellDef>
        <ng-container *ngIf="(loading$ | async) === false; else loading">
          {{ intl.noResults }}
        </ng-container>
      </td>
    </ng-container>

    <tr class="nodata-row" mat-footer-row
      [class.hidden]="table.renderedData?.length"
      *matFooterRowDef="['noData']">
    </tr>
  </table>
  <mat-paginator *ngIf="paginate" [pageSize]="pageSize" [pageSizeOptions]="defaultPageSizeOptions"></mat-paginator>
</cog-table>

<ng-template #loading>
  <cog-spinner class="margin-top-lg margin-bottom-lg"></cog-spinner>
</ng-template>
