import { ChangeDetectionStrategy, Component, forwardRef } from '@angular/core';
import { AbstractControl, FormControl, ValidatorFn, Validators } from '@angular/forms';
import { Scan, ScanPreference } from '@cohesity/api/argus';
import { ClusterInfoService, ViewClusterPreferenceDialogComponent } from '@cohesity/data-govern/cluster-replication';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { FormSectionComponent, NoWhitespaceValidator } from '@cohesity/shared-forms';
import { AjaxHandlerService, DialogService } from '@cohesity/utils';
import { finalize, take, tap } from 'rxjs/operators';

import { FormSectionName, ScanFormModel } from '../scan.model';
import { SettingsFormValue } from './settings.models';

/**
 * A function to validate whether the setting form control value is valid or not.
 *
 * @param control The form control.
 * @return null if the control is valid, error object otherwise.
 */
export const settingsFormModelValidator: ValidatorFn =
  (control: FormControl<Pick<Scan, 'name'>>) => control?.value?.name ? null : { nameRequired: true };

/**
 * custom validator to invalidate the detection type form if non of the detection types are selected
 *
 * @returns custom validator that can be added to a form group
 */
export const noWhitespaceValidator: ValidatorFn = (control: AbstractControl) => control?.value?.name?.indexOf(' ') >= 0 ? { whitespace: true } : null;

@Component({
  selector: 'dg-td-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: FormSectionComponent,
      useExisting: forwardRef(() => SettingsComponent),
    },
  ],
})
export class SettingsComponent
  extends FormSectionComponent<SettingsFormValue, ScanFormModel, Scan> {

  /**
   * Section name for form builder
   */
  formSectionName = 'settings' as FormSectionName;

  /**
   * form control for scan name
   */
  scanNameCtrl = new FormControl<string | null>(null, [Validators.required, NoWhitespaceValidator]);

  /**
   * Current value of minIocCount need to match to consider as a match
   */
  endDetectionRunAfter: number;

  /**
   * Current value of a file size in Gbs to skip scanning
   */
  skipFilesGreaterThan: number;

  /**
   * Current value of scan location
   */
  scanPreference = ScanPreference.scanOnLocal;

  /** Enum values for scan location options */
  readonly scanPreferences = ScanPreference;

  /**
   * Whether replica scans are enabled
   */
  readonly isReplicaScansEnabled = flagEnabled(this.irisContextService.irisContext, 'dataHawkThClusterReplication');

  /**
   * flag to track if the scan on replica option is enabled or disabled
   */
  disableScanOnReplica = false;

  /**
   * flag to track cluster preference loading
   */
  isClusterPreferenceLoading = false;

  /**
   * Indicates whether scan order preference option is selected or not
   */
  get isClusterScanOrderOptionSelected(): boolean {
    return this.scanPreference === ScanPreference.clusterScanOrder;
  }

  constructor(
    private ajaxHandler: AjaxHandlerService,
    private clusterInfoService: ClusterInfoService,
    private dialog: DialogService,
    private irisContextService: IrisContextService,
  ) {
    super();
  }

  /**
   * Used to initialize the section. This function will be called by FormBuilderComponent.
   */
  initFormSection() {
    super.initFormSection();
    this.formControl.addValidators([
      Validators.required,
      settingsFormModelValidator,
      noWhitespaceValidator,
      this.scanPreferenceValidator,
    ]);
    this.formControl.updateValueAndValidity();

    // initialize form value from fromDataModel
    const {
      name,
      endDetectionRunAfter,
      skipFilesGreaterThan,
      scanPreference
    } = this.fromDataModel(this.builder.dataModel);
    this.scanNameCtrl.setValue(name);
    this.scanNameCtrl.valueChanges.pipe(
      this.untilDestroy(),
      tap(() => this.updateValue()),
    ).subscribe();
    this.endDetectionRunAfter = endDetectionRunAfter;
    this.skipFilesGreaterThan = skipFilesGreaterThan;
    this.scanPreference = scanPreference;
    if (this.isReplicaScansEnabled) {
      this.checkClusterConfigStatus();
    }
    this.updateValue();
  }

  /**
   * Update current section value to parent Form value
   */
  updateValue() {
    this.next({
      name: this.scanNameCtrl.value,
      scanPreference: this.scanPreference,
      endDetectionRunAfter: this.endDetectionRunAfter,
      skipFilesGreaterThan: this.skipFilesGreaterThan
    });
  }


  selectScanLocation(scanLocation: ScanPreference) {
    this.scanPreference = scanLocation;
    this.updateValue();
  }

  /**
   * updates form value when end detection after changes
   *
   * @param val current value of end detection after
   */
  endDetectionAfterChanged(val: number) {
    this.endDetectionRunAfter = val;
    this.updateValue();
  }

  /**
   * updates form value when skip files greater than after changes
   *
   * @param val current value of end detection after
   */
  skipFilesGreaterThanChanged(val: number) {
    this.skipFilesGreaterThan = val;
    this.updateValue();
  }

  /**
   * Converts current form value to scan objects model
   *
   * @returns Scan objects value for the scan
   */
  toDataModel(): Partial<Scan> {
    return {
      name: this.scanNameCtrl.value,
      ...(this.isReplicaScansEnabled && {
        scanPreference: this.scanPreference
      }),
      endCondition: {
        minIocCount: this.endDetectionRunAfter
      }
    };
  }

  /**
   * Creates section value form the given scan
   *
   * @param dataModel Scan
   * @returns Section Value
   */
  fromDataModel(dataModel: Scan): SettingsFormValue {
    return {
      name: dataModel.name,
      scanPreference: dataModel?.scanPreference || ScanPreference.scanOnLocal,
      endDetectionRunAfter: dataModel.endCondition?.minIocCount || 10,
      skipFilesGreaterThan: 200
    };
  }

  /**
   * Opens Cluster scanning preference dialog
   */
  openViewScanOrderDialog() {
    ViewClusterPreferenceDialogComponent.launchDialog(this.dialog).pipe(this.untilDestroy()).subscribe();
  }

  /**
   * Validator function for scan preference option
   *
   * @returns invalid scan preference error if scan preference is loading
   */
  private scanPreferenceValidator = () => this.isClusterPreferenceLoading ? { invalidScanPreference: true } : null;

  /**
   * Checks cluster config detail and sets scan preference option accordingly
   */
  private checkClusterConfigStatus() {
    this.isClusterPreferenceLoading = true;
    this.clusterInfoService.clusterConfig$.pipe(
      take(1),
      this.untilDestroy(),
      finalize(() => {
        this.isClusterPreferenceLoading = false;
        this.updateValue();
      }),
    ).subscribe((config) => {
      if (!config?.orderedClusters?.length) {
        this.disableScanOnReplica = true;
      }
    }, (err) => {
      this.disableScanOnReplica = true;
      this.ajaxHandler.handler(err);
    });
  }
}
