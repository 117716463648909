import { ArchivalExternalTarget } from '@cohesity/api/v1';
import { AWSTargetConfig, RemoteTargetConfig } from '@cohesity/api/v2';
import { TimePeriodValue } from 'src/app/shared';

import { ArchivalTargetType, RunType } from './common.models';
import { DataLockConfigValue } from '../../policy-shared/form-group-components/data-lock-config/data-lock-config-form';

/**
 * Values when selecting which objects should be backed up.
 */
export enum BackupScope {
  All = 'all',
  Failed = 'failed',
  Selected = 'selected',
  Succeeded = 'succeed',
}

/**
 * Base form model for target forms.
 */
export interface BaseTargetFormModel {
  enabled: boolean;
  onlySuccessful: boolean;
  retention: TimePeriodValue;
  dataLockConfig?: DataLockConfigValue;
}

/**
 * Base group action target form interface.
 */
export interface BaseTargetForm {
  /**
   * Target name.
   */
  targetName: string;

  /**
   * Retention units like 'Week', 'Day', 'Month'.
   */
  retentionUnit: string;

  /**
   * Retention duration number.
   */
  retentionDuration: number;

  /**
   * True if this target form is enabled and its values should be submitted.
   */
  enabled: boolean;
}

/**
 * Archive form model.
 */
export interface GroupActionArchiveFormModel extends BaseTargetFormModel {
  target: ArchivalExternalTarget & { targetType?: ArchivalTargetType };
}

/**
 * CloudVault form model.
 */
export interface GroupActionCloudVaultFormModel extends BaseTargetFormModel {
  target: ArchivalExternalTarget & { targetType?: ArchivalTargetType };
}

/**
 * Replication target type.
 */
export type ReplicationType = 'RemoteCluster' | 'AWS' | 'Azure';

/**
 * Replication form model.
 */
export interface GroupActionReplicationFormModel extends BaseTargetFormModel {
  target?: RemoteTargetConfig;
  type?: ReplicationType;
  awsTargetConfig?: AWSTargetConfig;
}

/**
 * Group Action Form definition.
 */
export interface GroupActionFromModel {
  backupForm?: {
    backupObjectsScope: BackupScope;
    backupType: RunType;
    objects: number[];
  };
  archiveFormArray?: GroupActionArchiveFormModel[];
  replicationFormArray?: GroupActionReplicationFormModel[];
}

/**
 * Group Action Form default values.
 */
export const defaultGroupActionFormValues: GroupActionFromModel = {
  backupForm: {
    backupObjectsScope: BackupScope.All,
    backupType: RunType.Regular,
    objects: [],
  },
  archiveFormArray: [{
    enabled: false,
    onlySuccessful: true,
    retention: {
      value: 3,
      type: 'Months',
    },
    dataLockConfig: {
      dataLockConfigEnabled: false,
      dataLockConfigDuration: undefined
    },
    target: {},
  }],
  replicationFormArray: [{
    enabled: true,
    onlySuccessful: true,
    retention: {
      value: 3,
      type: 'Months',
    },
    dataLockConfig: {
      dataLockConfigEnabled: false,
      dataLockConfigDuration: undefined
    },
  }]
};

/**
 * Target type will be used to display label and icon.
 */
export type TargetType = 'backup' | 'archive' | 'replication' | 'cloudVault';

/**
 * Target status used to display icon.
 */
export type TargetStatus = 'scheduled' | 'cancel';

/**
 * Maps target type to target type icon name in helix icons library.
 */
export const targetIcon: { [key in TargetType]: string } = {
  archive: 'cloud',
  backup: 'cluster',
  cloudVault: 'vault',
  replication: 'replication',
};
