<!-- Show app classified notice in the header -->
<coh-app-layout-notice *ngIf="classifiedDataMessage"
  [message]="classifiedDataMessage">
</coh-app-layout-notice>

<cog-app-frame
  (closeButtonClick)="handleCloseButtonClick()"
  (navToggled)="appLayoutService.setNavDisplayed($event)"
  [class.app-frame-hidden]="hideAppFrame"
  [class.has-intercom]="(heliosAnalyticsService.intercomEnabled$ | async) && isDmsScope"
  [class.global-search-active]="globalSearchActive"
  [class.login-with-marketing]="loginWithMarketing"
  [keepNavDrawerOpen]="keepNavDrawerOpen"
  [collapseMenu]="collapseMenu"
  [hideControls]="!isLoggedIn || !isLicenseActivated"
  [hideNav]="hideNav"
  [showClose]="showClose">
  <!-- Brand Info -->
  <cog-app-branding class="branding">
    <div class="logo-wrapper" [ngClass]="{'custom-logo': !!customLogoUrl}">
      <ng-container *ngIf="!!customLogoUrl; else cohesityLogo">
        <a id="cohesity-logo"
          class="logo custom-logo"
          [style.background-image]="customLogoUrl"
          [uiSref]="homeService.name"
          (click)="(userService.user && isLicenseActivated) || $event.preventDefault()">
          Cohesity
        </a>

        <div class="cohesity-branding">
          {{"poweredByCohesity" | translate}}
        </div>
      </ng-container>

      <ng-template #cohesityLogo>
        <a id="cohesity-logo"
          [ngClass]="logoClasses"
          [uiSref]="homeService.name"
          (click)="(userService.user && isLicenseActivated) || $event.preventDefault()">
          Cohesity
        </a>
      </ng-template>
    </div>
  </cog-app-branding>

  <!-- Left Content, just to the right of the brand -->
  <cog-app-bar-left *ngIf="areControlsEnabled && globalSearchEnabled">
    <div *ngIf="showGlobalSearchInput && hasCompanyContext" class="nav-global-search-input">
      <coh-global-search-input></coh-global-search-input>
    </div>
  </cog-app-bar-left>

  <!-- When on global search page, don't show these controls since the search
  input is always expanded. On other pages, don't hide the controls to keep
  them accessible by keyboard -->
  <cog-app-bar-right *ngIf="uiRouterGlobals?.current?.name !== 'ng-search'"
    [class.visuallyhidden]="!areControlsEnabled || globalSearchActive">

    <!-- Tenant Switch Section -->
    <coh-tenant-switch *ngIf="showOrganizationSwitch" class="hide-block@xs"></coh-tenant-switch>

    <coh-selected-scope #selectedScope
      *ngIf="showSelectedClusterScope"
      (click)="!selectedScope.needMoreClusters && !flagEnabled('spogDeprecated') && openClusterSelect()"
      class="hide-block@xs">
    </coh-selected-scope>

    <!-- Go to Helios button - visible only in OneHelios appliances -->
    <button *ngIf="isOneHeliosAppliance"
      class="margin-right-sm"
      [cogDataId]="'go-to-helios'" mat-stroked-button mat-button type="button"
      (click)="goToOneHelios()">
      <span> {{ 'goToHelios' | translate }}  </span>
    </button>

    <cog-dark-mode-toggle *ngIf="showDarkModeToggle"
      cogDataId="theme-toggle"
      class="hide-block@xs"
      [trackingEvent]="{key: themeService.isDarkModeEnabled ? 'dark_mode_enabled' : 'light_mode_enabled'}">
    </cog-dark-mode-toggle>

    <cog-web-component [name]="'web-turing-chat-prompt-toggle'"></cog-web-component>

    <cog-theme-switcher
      *ngIf="!env.production || flagEnabled('themeSwitcherEnabled')"
      [themes]="themes"></cog-theme-switcher>

    <ng-container *ngIf="showAppSwitcher">
      <cog-app-switcher
        *ngIf="!appPillarsEnabled"
        [reload]="!flagEnabled('microFrontendEnabled')"
        [appConfigs]="scopeSelectorService.availableAppConfigs$ | async"
        [getAppDisplayName]="getAppDisplayName"
        label="{{ 'switchApp' | translate }}"
        class="hide-block@xs">
      </cog-app-switcher>

      <coh-app-pillars-switcher
        *ngIf="appPillarsEnabled"
        [appConfigs]="scopeSelectorService.availableAppConfigs$ | async">
      </coh-app-pillars-switcher>
    </ng-container>

    <!-- When intercom sdk is loaded, the help icon in the header is a toggle
      for intercom messenger. -->
    <ng-container *ngIf="(heliosAnalyticsService.intercomEnabled$ | async) && isDmsScope">
      <coh-toggle-messenger class="hide-block@xs">
      </coh-toggle-messenger>
    </ng-container>

    <!-- Have the standard help icon behavior if intercom is not loaded -->
    <ng-container *ngIf="(heliosAnalyticsService.intercomEnabled$ | async) === false || !isDmsScope">
      <!-- Check if helpList has visible items or page/generic help should be shown -->
      <ng-container *ngIf="(helpList$ | async | filterBy: { hidden: true } : 'reject') as filteredHelpList">
          <coh-nav-item-menu menuId="top-nav-help"
            [title]="'support' | translate"
            [tooltip]="'support' | translate"
            [navItems]="filteredHelpList"
            icon="helix:help"
            class="hide-block@xs"
            *ngIf="shouldShowHelpIcon(filteredHelpList)">
            <a target="_blank"
              *ngIf="showPageHelp"
              cohHelpLink
              mat-menu-item>
              {{'pageHelp' | translate}}
            </a>
          <a target="_blank"
              *ngIf="showGenericHelp"
              cohHelpLink
              mat-menu-item>
              {{'help' | translate}}
            </a>
          </coh-nav-item-menu>

          <button [attr.aria-label]="'pageHelp' | translate"
            *ngIf="showPageHelp"
            type="button"
            class="nav-item-menu-button show-block@xs"
            [title]="'pageHelp' | translate"
            [matTooltip]="'pageHelp' | translate"
            cohHelpLink
            mat-icon-button>
            <cog-icon shape="helix:help"></cog-icon>
          </button>


          <button [attr.aria-label]="'help' | translate"
            *ngIf="showGenericHelp"
            type="button"
            class="nav-item-menu-button show-block@xs"
            [title]="'help' | translate"
            [matTooltip]="'help' | translate"
            cohHelpLink
            mat-icon-button>
            <cog-icon shape="helix:help"></cog-icon>
          </button>

      </ng-container>
    </ng-container>

    <!-- Helios Connection Status, Show this option only for on-prem systems -->
    <ng-container *ngIf="heliosStatus$ | async as heliosStatus">
      <button *ngIf="!heliosTokenClaimEnabled"
        #heliosConnect
        [attr.aria-label]="'heliosConnect' | translate"
        id="top-nav-helios-status-button"
        class="top-nav-helios ng-nav-helios"
        mat-icon-button
        [matTooltip]="'helios' | translate"
        [cogPopover]="appHeliosStatusComponent"
        [popoverData]="{
          isRegistered: heliosStatus._isRegistered,
          isConnected: heliosStatus._isConnected,
          button: heliosConnect}">
        <cog-icon [class]="'helios-status ' + heliosStatus._icon" shape="helix:helios">
        </cog-icon>
      </button>
      <button *ngIf="heliosTokenClaimEnabled"
        [attr.aria-label]="'heliosConnect' | translate"
        id="top-nav-helios-status-button"
        class="top-nav-helios ng-nav-helios"
        mat-icon-button
        [matTooltip]="'helios' | translate"
        (click)="openHeliosTokenClaim()">
        <cog-icon [class]="'helios-status ' + heliosStatus._icon" shape="helix:helios">
        </cog-icon>
      </button>
    </ng-container>

    <div *ngIf="isImpersonated && !isHelios">
      <coh-impersonation-indicator class="hide-block@xs"></coh-impersonation-indicator>
    </div>

    <div *ngIf="showNotifications"
      (click)="openNotifications()">
      <coh-notifications-indicator></coh-notifications-indicator>
    </div>

    <button [attr.aria-label]="'userMenu' | translate"
      id="top-nav-user"
      mat-icon-button
      [matTooltip]="'userAccount' | translate"
      (click)="openUserPanel()"
      class="hide-block@xs"
      [class.with-org-name]="isAccountSwitchable">
      <span *ngIf="isAccountSwitchable" class="org-name auto-truncate user-data-sm">
        {{ userService.user._tenant.name }}
      </span>
      <cog-icon shape="helix:user-alt"></cog-icon>
    </button>

    <coh-nav-item-menu menuId="responsive-top-nav-menu"
      [title]="'navigationMenu' | translate"
      [tooltip]="'navigationMenu' | translate"
      [navItems]="responsiveTopNavItems$ | async"
      icon="more_vert"
      class="show-block@xs">
    </coh-nav-item-menu>

  </cog-app-bar-right>

  <cog-app-nav *ngIf="areControlsEnabled"
    [itemTemplate]="itemTemplate"
    [bannerTemplate]="serviceBanner"
    [navList]="navList">
  </cog-app-nav>

  <cog-app-content *ngIf="!hideAppFrame">
    <ng-container *ngTemplateOutlet="appContent"></ng-container>
  </cog-app-content>

  <cog-app-side-nav-footer *ngIf="displayLastLoginInfo && (lastLoginTime$ | async) as lastLoginTime">
    <div *ngIf="displayLastLoginInfo && lastLoginTime" class="last-login-info">
      {{ 'userLastLoginTime' | translate: { time: lastLoginTime$ | async | cohDate } }}
    </div>
  </cog-app-side-nav-footer>
</cog-app-frame>

<div *ngIf="hideAppFrame">
  <ng-container *ngTemplateOutlet="appContent"></ng-container>
</div>

<mf-app-outlet *ngIf="flagEnabled('microFrontendEnabled')" [appConfigs]="appConfigs"></mf-app-outlet>
<mf-plugin-outlet *ngIf="flagEnabled('microFrontendEnabled')"></mf-plugin-outlet>

<!-- Show app classified notice in the footer -->
<coh-app-layout-notice *ngIf="classifiedDataMessage"
  [message]="classifiedDataMessage">
</coh-app-layout-notice>

<!-- TODO(Sam): Check whether we need a separate template for Helios and Cluster. -->
<ng-template #appContent>
  <ng-content *ngIf="(mcmService.showFrame$ | async) === false"></ng-content>
  <coh-mcm-view *ngIf="mcmService.showFrame$ | async"></coh-mcm-view>
</ng-template>

<!-- Menu Item template for the drawer. Configures cog-nav-item with the
appropriate state decorators -->
<ng-template #itemTemplate let-ctx>
  <!--
    Skipping this in the tab index as the mat-list-item implementation in cogNavItem
    will take the focus and propagate the click to this element if appropriate.
  -->
  <a id="{{ctx.item.displayName}}-nav-anchor"
    *ngIf="!ctx.item.href"
    uiSref="{{!ctx.item.subNavList?.length && !ctx.item.disabled ? ctx.item.state : ''}}"
    [uiOptions]="ctx.item.stateOpts"
    [uiParams]="ctx.item.stateParams"
    tabindex="-1">
    <cog-nav-item [isSubItem]="ctx.subItem" [item]="ctx.item">
      <span [innerHTML]="ctx.item.displayName | translate"></span>
    </cog-nav-item>
  </a>
  <a *ngIf="ctx.item.href"
    href="{{ctx.item.href}}"
    target="{{ ctx.item?.options?.hrefTarget === '_self' ? '' : ctx.item?.options?.hrefTarget ?? '_blank' }}"
    id="{{ctx.item.displayName}}-nav-anchor">
    <cog-nav-item [isSubItem]="ctx.subItem" [item]="ctx.item">
      <span [innerHTML]="ctx.item.displayName | translate"></span>
    </cog-nav-item>
  </a>
</ng-template>

<ng-template #serviceBanner>
  <ng-container *ngIf="(isHelios || isOneHeliosAppliance) && serviceNavProvider$ | async as navProvider">
    <cog-nav-banner *ngIf="getNavBanner(navProvider) as navBanner"
      [title]="navBanner.title"
      [logo]="navBanner.logo"
      [size]="flagEnabled('appPillarsEnabled') ? 'md' : 'xl'"
      [showBranding]="navBanner.showBranding"
      [modifierClass]="navBanner.modifierClass">
      <small subtitle class="sub-text" *ngIf="navBanner.subtitle">
        <small [innerHTML]="navBanner.subtitle"></small>
      </small>

      <coh-cluster-switcher
        [class.cluster-switcher-no-branding]="!navBanner.showBranding"
        [classicDropdown]="navProvider.useClassicClusterDropdown"
        *ngIf="navProvider.serviceNavTitleClass === 'cluster-manager' && showNavBannerClusterScope">
      </coh-cluster-switcher>
    </cog-nav-banner>

    <a
      *ngIf="shouldShowScVersionSwitcher$ | async"
      class="sc-version-switcher"
      [href]="appConfigMap.siteContinuityV2.urlSegment">
      <mat-slide-toggle
        cogDataId="sitecontinuity-version-switcher"
        labelPosition="before"
        [checked]="false">
        {{ 'disasterRecovery.versionSwitcher.label' | translate }}
      </mat-slide-toggle>
    </a>
  </ng-container>
</ng-template>
