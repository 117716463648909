<!-- Template: Whitelist Table Component -->
<ng-container *ngIf="(whitelists || []).length > 0">
  <cog-filters #filters>
    <coh-tenant-filter property="tenantId" *ngIf="showOrgs"></coh-tenant-filter>

    <cog-search-property-filter
      cogDataId="whitelist-table-filter-search"
      *cogFilterDef="
        let filter;
        key: '_ip';
        label: 'search' | translate;
        quickFilter: true;
        filterType: 'searchProperty'"
      [filter]="filter"
      [placeholder]="'subnet' | translate">
    </cog-search-property-filter>
  </cog-filters>

  <cog-table name="views-whitelists-table"
    [source]="whitelists"
    class="whitelist-table"
    [filters]="filters.filterValues$ | async">
    <table mat-table matSort>
      <ng-container matColumnDef="_ip">
        <th mat-header-cell *matHeaderCellDef mat-sort-header cogDataId="subnet-header">
          {{'subnet' | translate}}
        </th>
        <td mat-cell *matCellDef="let subnet">
          {{subnet?._ip}}
        </td>
      </ng-container>

      <coh-tenant-column [internalResolve]="true" *ngIf="showOrgs"></coh-tenant-column>

      <ng-container matColumnDef="nfsAccess">
        <th mat-header-cell *matHeaderCellDef mat-sort-header cogDataId="nfs-header">
          {{'nfsPermissions' | translate}}
        </th>
        <td mat-cell *matCellDef="let subnet">
          {{subnet?.nfsAccess | translate}}
        </td>
      </ng-container>

      <ng-container matColumnDef="smbAccess">
        <th mat-header-cell *matHeaderCellDef mat-sort-header cogDataId="smb-header">
          {{'smbPermissions' | translate}}
        </th>
        <td mat-cell *matCellDef="let subnet">
          {{subnet?.smbAccess | translate}}
        </td>
      </ng-container>

      <ng-container matColumnDef="s3Access">
        <th mat-header-cell *matHeaderCellDef mat-sort-header cogDataId="s3-header">
          {{'s3Permissions' | translate}}
        </th>
        <td mat-cell *matCellDef="let subnet">
          {{subnet?.s3Access | translate}}
        </td>
      </ng-container>

      <ng-container matColumnDef="nfsSquash">
        <th mat-header-cell *matHeaderCellDef mat-sort-header cogDataId="nfs-squash-header">
          {{'nfsSquash' | translate}}
        </th>
        <td mat-cell *matCellDef="let subnet">
          {{subnet.nfsSquash || getNfsSquashValue(subnet) | translate}}
        </td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header cogDataId="description-header">
          {{'description' | translate}}
        </th>
        <td mat-cell *matCellDef="let subnet">
          {{subnet?.description || '-'}}
        </td>
      </ng-container>

      <!-- Actions Menu -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell id="whitelist-actions-col" *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let whitelist; index as i">
          <div class="flex-row actions-menu-container">
            <button mat-icon-button
              type="button"
              cogDataId="edit-whitelist-{{whitelist.ip}}"
              (click)="editWhitelist.emit(whitelist)"
              [disabled]="whitelist._modifyDisabled"
              [matTooltip]="'edit' | translate">
              <cog-icon shape="helix:edit-outline"></cog-icon>
            </button>

            <button mat-icon-button
              type="button"
              cogDataId="delete-whitelist-{{whitelist.ip}}"
              (click)="deleteWhitelist.emit(whitelist)"
              [disabled]="whitelist._modifyDisabled"
              [matTooltip]="'delete' | translate">
              <cog-icon shape="delete_outline"></cog-icon>
            </button>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columns"></tr>
      <tr mat-row *matRowDef="let row; columns: columns"></tr>
    </table>
    <mat-paginator [pageSize]="[5, 10, 50]" *ngIf="whitelists?.length > 5" class="whitelist-paginator"></mat-paginator>
  </cog-table>
</ng-container>

<p *ngIf="(whitelists || []).length === 0 && !enableAction" translate>none</p>
