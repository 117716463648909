import { ViewProtocol } from '@cohesity/api/v2';
import { ViewInfo } from '../models/views.model';

/**
 * Check if the given protocol is selected from the given list of protocols.
 *
 * @param protocolsList The protocol list
 * @param protocol The protocol to check
 * @returns True, if protocol is selected.
 */
export const isProtocolSelected = (protocolsList: ViewProtocol[], protocol: ViewProtocol['type']) =>
  protocolsList?.some(p => p.type === protocol);

/**
 * Check if the given protocol is selected from the given list of Read/Write protocols.
 *
 * @param protocolsList The protocol list
 * @param protocol The protocol to check
 * @returns True if give protocol is selected as Read/Write.
 */
export const isReadWriteProtocolSelected = (protocolsList: ViewProtocol[], protocol: ViewProtocol['type']) =>
  protocolsList?.some(p => p.type === protocol && p.mode === 'ReadWrite');

/**
 * Check if the Swift protocol is selected from the given list of protocols.
 *
 * @param protocolsList The protocol list
 * @returns True, if Swift protocol is selected.
 */
export const isSwiftProtocolSelected = (protocolsList: ViewProtocol[]) => isProtocolSelected(protocolsList, 'Swift');

/**
 * Check if the S3 protocol is selected from the given list of protocols.
 *
 * @param protocolsList The protocol list
 * @returns True, if S3 protocol is selected.
 */
export const isS3ProtocolSelected = (protocolsList: ViewProtocol[]) => isProtocolSelected(protocolsList, 'S3');

/**
 * Check if the S3 Read/Write protocol is selected from the given list of protocols.
 *
 * @param protocolsList The protocol list.
 * @returns True if S3 Read/Write protocol is selected.
 */
export const isS3ReadWriteProtocolSelected = (protocolsList: ViewProtocol[]) =>
  isReadWriteProtocolSelected(protocolsList, 'S3');

/**
 * Check if only S3 protocol is selected.
 *
 * @param protocolsList The protocol list
 * @returns  True, if only S3 protocol is selected.
 */
export const isS3ProtocolSelectedOnly = (protocolsList: ViewProtocol[]) => protocolsList.length === 1 &&
  isS3ProtocolSelected(protocolsList);

/**
 * Check if the SMB protocol is selected from the given list of protocols.
 *
 * @param protocolsList The protocol list
 * @returns True, if SMB protocol is selected.
 */
export const isSMBProtocolSelected = (protocolsList: ViewProtocol[]) => isProtocolSelected(protocolsList, 'SMB');

/**
 * Check if the NFS protocol is selected from the given list of protocols.
 *
 * @param protocolsList The protocol list
 * @returns True, if NFS protocol is selected.
 */
export const isNFSProtocolSelected = (protocolsList: ViewProtocol[]) =>
  isProtocolSelected(protocolsList, 'NFS') || isProtocolSelected(protocolsList, 'NFS4');

/**
 * Determines whether the logged-in user is allowed to rename a View.
 *
 * @param view View object
 * @returns True, if logged in user is able to rename the View.
 */
export const userCanRenameView = (view: ViewInfo) => {
  // If no DataLock then User can rename View.
  if (!view._hasViewDataLock && !view._hasFileDataLock) {
    return true;
  }

  // User can rename if it's File DataLock View AND
  return view._hasFileDataLock &&

    // it is Enterprise mode.
    view.fileLockConfig?.mode === 'Enterprise';
};
