<form class="flex-column" [formGroup]="formGroup">
  <mat-form-field>
    <input
      matInput
      cogDataId="pattern-name-input"
      formControlName="name"
      placeholder="{{ 'policyName' | translate }}" />
    <mat-error id="policy-name-required" *ngIf="formGroupErrors?.name?.required">
      {{ 'errors.required' | translate }}
    </mat-error>
  </mat-form-field>

  <dg-dc-patterns-selector formControlName="patternIds"></dg-dc-patterns-selector>
  <mat-error
    *ngIf="
      formGroup.get('patternIds').touched &&
      formGroup.get('patternIds').dirty &&
      formGroup.get('patternIds').hasError('required')
    ">
    {{ 'errors.required' | translate }}
  </mat-error>
</form>
