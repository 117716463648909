import { ChangeDetectionStrategy, Component, Inject, ViewChild } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ThreatId } from '@cohesity/api/argus';
import { IrisContextService } from '@cohesity/iris-core';
import { AjaxHandlerService, AutoDestroyable } from '@cohesity/utils';

import { HasCustomRBACPermissions } from '@cohesity/data-govern/shared';
import { ThreatListComponent } from '../../../threat-list';
import { YaraRuleUploadDialogService } from '../../../yara-rule-upload-dialog';

@Component({
  selector: 'dg-td-select-user-defined-threats-dialog',
  templateUrl: './select-user-defined-threats-dialog.component.html',
  styleUrls: ['./select-user-defined-threats-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class SelectUserDefinedThreatsDialogComponent extends AutoDestroyable {
  /**
   *Threat List component view child
   */
  @ViewChild(ThreatListComponent) private threatListComponent: ThreatListComponent;

  /**
   * Selected Threat Ids
   */
  selectedThreatIds: ThreatId[] = [];

  /**
   * Threat ids to select initially
   */
  selectThreatIds: ThreatId[] = [];

  /**
   * Indicates whether the user is having modify privilege.
   */
  get hasModifyAccess(): boolean {
    return HasCustomRBACPermissions(['DGAAS_MANAGE_THREAT_SCAN', 'DGAAS_MODIFY'], this.irisCtx.irisContext);
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: ThreatId[],
    private ajaxService: AjaxHandlerService,
    private dialogRef: MatDialogRef<SelectUserDefinedThreatsDialogComponent>,
    private irisCtx: IrisContextService,
    private yaraRuleUploadDialogService: YaraRuleUploadDialogService,
  ) {
    super();
    this.selectThreatIds = data ?? [];
  }

  /**
   * Sets selected threats
   *
   * @param threats selected threats
   */
  selectionChanged(threatIds: ThreatId[]) {
    this.selectedThreatIds = threatIds;
  }

  /**
   * Closes select threats dialog without updating selected threats
   */
  cancel() {
    this.dialogRef.close();
  }

  /**
   * Closes select threats dialog and updates selected threats
   */
  save() {
    this.dialogRef.close(this.selectedThreatIds);
  }

  /**
   * Opens YARA rule upload dialog
   */
  openAddYaraRuleDialog() {
    this.yaraRuleUploadDialogService
      .launch()
      .pipe(this.untilDestroy())
      .subscribe(() => {
        this.threatListComponent.getThreats();
      }, this.ajaxService.handler);
  }
}
