import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogModule as MatDialogModule,
} from '@angular/material/legacy-dialog';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { CopyStats } from '@cohesity/api/v2';
import { extractObjectId, SearchObjectPerSource } from '@cohesity/data-govern/scans';
import { DataIdModule, IconModule, TableModule } from '@cohesity/helix';
import { EnvironmentTypeModule, NaLabelModule, ObjectTypeIconPipeModule } from '@cohesity/utils';
import { TranslateModule } from '@ngx-translate/core';

export interface SnapshotDetailDialogInput {
  copyStats: CopyStats[];
  objects: SearchObjectPerSource[];
}

@Component({
  selector: 'dg-td-snapshot-details-dialog',
  standalone: true,
  imports: [
    CommonModule,
    DataIdModule,
    EnvironmentTypeModule,
    IconModule,
    MatButtonModule,
    MatDialogModule,
    MatTooltipModule,
    NaLabelModule,
    ObjectTypeIconPipeModule,
    TableModule,
    TranslateModule,
  ],
  templateUrl: './snapshot-details-dialog.component.html',
  styleUrls: ['./snapshot-details-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SnapshotDetailsDialogComponent {
  /**
   * TH scan objects
   */
  objects: SearchObjectPerSource[] = [];

  // Object snapshot count map
  objectSnapshotCountMap = new Map<number, number>();

  // column names for objects table
  columnNames = ['object', 'system', 'snapshotCount'];

  // util method
  extractObjectId = extractObjectId;

  constructor(@Inject(MAT_DIALOG_DATA) private data: SnapshotDetailDialogInput) {
    // Extracting snapshot count mapping towards objectId
    data.copyStats.forEach(copyStat => {
      const count = this.objectSnapshotCountMap.get(copyStat.local?.objectId) ?? 0;
      this.objectSnapshotCountMap.set(copyStat.local?.objectId, count + 1);
    });

    this.objects = data.objects;
  }
}
