<ng-template cohFormSectionEditMode>
  <h2>{{ 'dg.dc.scanPolicy' | translate }}</h2>

  <coh-form-field-object-selector
    class="policy-selector"
    cogDataId="policy"
    [control]="policySelector"
    [label]="'policy' | translate"
    [loading]="isLoading"
    [values]="policies"
    optionKey="name"
    [required]="true"
    [hideRequiredMarker]="true"
    [addNewEnable]="allowCreateNewPolicy && hasPolicyManagePermission"
    [addButtonLabel]="'createPolicy' | translate"
    (registerAddButtonClick)="createNewPolicy()">
  </coh-form-field-object-selector>
</ng-template>

<ng-template cohFormSectionViewMode>
  <h2 cogDataId="policy-name" [inheritDataId]="true">{{policySelector.value.name}}</h2>
  {{ 'policy' | translate }}
</ng-template>

<ng-template cohFormSectionViewCta *ngIf="allowCreateNewPolicy">
  <button
    mat-icon-button
    class="edit-form-section"
    type="button"
    cogDataId="edit-form-section"
    [inheritDataId]="true"
    (click)="editPolicy()">
    <cog-icon shape="edit!outline"></cog-icon>
  </button>
</ng-template>

<ng-template cohFormSectionNavigator *ngIf="allowCreateNewPolicy">
  <button
    mat-flat-button
    [disabled]="!policySelector.value"
    class="save-form-section"
    type="button"
    color="primary"
    cogDataId="save-form-section"
    (click)="updateValue()">
    {{ 'dg.dc.scan.nextSettings' | translate }}
  </button>
</ng-template>
