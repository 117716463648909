import { NavItem } from '@cohesity/helix';
import { flagEnabled, getUserPrivilegeCheck, IrisContext } from '@cohesity/iris-core';
import { TranslateService } from '@ngx-translate/core';

/**
 * Generates the navigation list for datahawk/security center based on the feature enablement.
 *
 * @param translate translation service
 * @param irisCtx iris context
 * @returns navigation list for the datahawk/security center
 */
export const getNavList = (translate: TranslateService, irisCtx: IrisContext): NavItem[] => [
  {
    displayName: translate.instant('chat'),
    icon: 'gaia:chat',
    href: '/conversation',
  },
  flagEnabled(irisCtx, 'gaiaDataDiscoverySupportEnabled') ? {
    displayName: translate.instant('di.dataset.topicExplorer'),
    icon: 'gaia:discover',
    href: '/topic-explorer',
  } : null,
  {
    displayName: translate.instant('di.datasets'),
    icon: 'gaia:datasets',
    href: '/datasets',
  },
  flagEnabled(irisCtx, 'gaiaPermissionsManagementSupportEnabled') ? {
    displayName: translate.instant('di.dataset.permissionsManagement.inventory'),
    icon: 'gaia:discover',
    href: '/permissions-mgmt-inventory',
  } : null,
  getUserPrivilegeCheck('GAIA_MODIFY')(irisCtx) ? {
    displayName: translate.instant('settings'),
    icon: 'settings!outline',
    href: '/settings/s',
  } : null,
].filter(Boolean) as NavItem[];
