<h3 mat-dialog-title class="margin-bottom-lg">
  {{ 'delete' | translate }} {{ 'dg.td.yaraRule' | translate }}
</h3>

<mat-dialog-content>
  <ng-container *ngIf="!isSubmitting; else spinner;">
    <p>
      {{"dg.td.deleteThreatWarning" | translate}}
    </p>
    <p *ngIf="!isSubmitting; else spinner;">
      {{threat.name}}
    </p>
    <mat-form-field class="margin-top">
      <input
        cdkFocusInitial
        required
        matInput
        placeholder="{{'typeYesToConfirm' | translate}}"
        [formControl]="confirmControl">
        <mat-error  *ngIf="confirmControl.errors?.required">
          {{'errors.required' | translate}}
        </mat-error>
        <mat-error  *ngIf="confirmControl.errors?.pattern">
          {{'mustInputYes' | translate}}
        </mat-error>
    </mat-form-field>
  </ng-container>
</mat-dialog-content>

<ng-template #spinner>
  <cog-spinner></cog-spinner>
</ng-template>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>
    {{'cancel' | translate}}
  </button>
  <button mat-flat-button color="warn"
    [disabled]="isSubmitting || confirmControl.invalid"
    (click)="deleteThreat()">
    {{'delete' | translate}}
  </button>
</mat-dialog-actions>
