/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityApiConfiguration as __Configuration } from '../cohesity-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { LdapProviderResponse } from '../models/ldap-provider-response';
import { UpdateLdapProviderParam } from '../models/update-ldap-provider-param';
import { LdapProvider } from '../models/ldap-provider';
@Injectable({
  providedIn: 'root',
})
class LdapProviderService extends __BaseService {
  static readonly GetLdapProviderPath = '/public/ldapProvider';
  static readonly UpdateLdapProviderPath = '/public/ldapProvider';
  static readonly CreateLdapProviderPath = '/public/ldapProvider';
  static readonly DeleteLdapProviderPath = '/public/ldapProvider/{id}';
  static readonly GetLdapProviderStatusPath = '/public/ldapProvider/{id}/status';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Lists the LDAP providers.
   * @param params The `LdapProviderService.GetLdapProviderParams` containing the following parameters:
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which objects are to be
   *   returned.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `ids`: Specifies the ids of the LDAP providers to fetch.
   *
   * - `allUnderHierarchy`: AllUnderHierarchy specifies if objects of all the tenants under the
   *   hierarchy of the logged in user's organization should be returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetLdapProviderResponse(params: LdapProviderService.GetLdapProviderParams): __Observable<__StrictHttpResponse<Array<LdapProviderResponse>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.tenantIds || []).forEach(val => {if (val != null) __params = __params.append('tenantIds', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    if (params.allUnderHierarchy != null) __params = __params.set('allUnderHierarchy', params.allUnderHierarchy.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/ldapProvider`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<LdapProviderResponse>>;
      })
    );
  }
  /**
   * Lists the LDAP providers.
   * @param params The `LdapProviderService.GetLdapProviderParams` containing the following parameters:
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which objects are to be
   *   returned.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `ids`: Specifies the ids of the LDAP providers to fetch.
   *
   * - `allUnderHierarchy`: AllUnderHierarchy specifies if objects of all the tenants under the
   *   hierarchy of the logged in user's organization should be returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetLdapProvider(params: LdapProviderService.GetLdapProviderParams): __Observable<Array<LdapProviderResponse>> {
    return this.GetLdapProviderResponse(params).pipe(
      __map(_r => _r.body as Array<LdapProviderResponse>)
    );
  }

  /**
   * Update an LDAP provider.
   *
   * Returns the updated LDAP provider.
   * @param params The `LdapProviderService.UpdateLdapProviderParams` containing the following parameters:
   *
   * - `body`: Request to update a LDAP provider.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateLdapProviderResponse(params: LdapProviderService.UpdateLdapProviderParams): __Observable<__StrictHttpResponse<LdapProviderResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/public/ldapProvider`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LdapProviderResponse>;
      })
    );
  }
  /**
   * Update an LDAP provider.
   *
   * Returns the updated LDAP provider.
   * @param params The `LdapProviderService.UpdateLdapProviderParams` containing the following parameters:
   *
   * - `body`: Request to update a LDAP provider.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateLdapProvider(params: LdapProviderService.UpdateLdapProviderParams): __Observable<LdapProviderResponse> {
    return this.UpdateLdapProviderResponse(params).pipe(
      __map(_r => _r.body as LdapProviderResponse)
    );
  }

  /**
   * Create a LDAP provider.
   *
   * Returns the created LDAP provider.
   * @param params The `LdapProviderService.CreateLdapProviderParams` containing the following parameters:
   *
   * - `body`: Request to configure a LDAP provider.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateLdapProviderResponse(params: LdapProviderService.CreateLdapProviderParams): __Observable<__StrictHttpResponse<LdapProviderResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/ldapProvider`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LdapProviderResponse>;
      })
    );
  }
  /**
   * Create a LDAP provider.
   *
   * Returns the created LDAP provider.
   * @param params The `LdapProviderService.CreateLdapProviderParams` containing the following parameters:
   *
   * - `body`: Request to configure a LDAP provider.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateLdapProvider(params: LdapProviderService.CreateLdapProviderParams): __Observable<LdapProviderResponse> {
    return this.CreateLdapProviderResponse(params).pipe(
      __map(_r => _r.body as LdapProviderResponse)
    );
  }

  /**
   * Delete an LDAP provider.
   * @param params The `LdapProviderService.DeleteLdapProviderParams` containing the following parameters:
   *
   * - `id`: Specifies the LDAP Id.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteLdapProviderResponse(params: LdapProviderService.DeleteLdapProviderParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/public/ldapProvider/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete an LDAP provider.
   * @param params The `LdapProviderService.DeleteLdapProviderParams` containing the following parameters:
   *
   * - `id`: Specifies the LDAP Id.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteLdapProvider(params: LdapProviderService.DeleteLdapProviderParams): __Observable<null> {
    return this.DeleteLdapProviderResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get the connection status of an LDAP provider.
   * @param params The `LdapProviderService.GetLdapProviderStatusParams` containing the following parameters:
   *
   * - `id`: Specifies the LDAP Id.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  GetLdapProviderStatusResponse(params: LdapProviderService.GetLdapProviderStatusParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/ldapProvider/${encodeURIComponent(params.id)}/status`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Get the connection status of an LDAP provider.
   * @param params The `LdapProviderService.GetLdapProviderStatusParams` containing the following parameters:
   *
   * - `id`: Specifies the LDAP Id.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  GetLdapProviderStatus(params: LdapProviderService.GetLdapProviderStatusParams): __Observable<null> {
    return this.GetLdapProviderStatusResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module LdapProviderService {

  /**
   * Parameters for GetLdapProvider
   */
  export interface GetLdapProviderParams {

    /**
     * TenantIds contains ids of the tenants for which objects are to be
     * returned.
     */
    tenantIds?: Array<string>;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Specifies the ids of the LDAP providers to fetch.
     */
    ids?: Array<number>;

    /**
     * AllUnderHierarchy specifies if objects of all the tenants under the
     * hierarchy of the logged in user's organization should be returned.
     */
    allUnderHierarchy?: boolean;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateLdapProvider
   */
  export interface UpdateLdapProviderParams {

    /**
     * Request to update a LDAP provider.
     */
    body: UpdateLdapProviderParam;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreateLdapProvider
   */
  export interface CreateLdapProviderParams {

    /**
     * Request to configure a LDAP provider.
     */
    body: LdapProvider;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DeleteLdapProvider
   */
  export interface DeleteLdapProviderParams {

    /**
     * Specifies the LDAP Id.
     */
    id: number;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetLdapProviderStatus
   */
  export interface GetLdapProviderStatusParams {

    /**
     * Specifies the LDAP Id.
     */
    id: number;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { LdapProviderService }
