/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityApiConfiguration as __Configuration } from '../cohesity-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { FreeNodeInformation } from '../models/free-node-information';
import { NodeStatusResult } from '../models/node-status-result';
import { Node } from '../models/node';
import { UpgradeNodeResult } from '../models/upgrade-node-result';
import { UpgradeNodeParameters } from '../models/upgrade-node-parameters';
@Injectable({
  providedIn: 'root',
})
class NodesService extends __BaseService {
  static readonly ListFreeNodesPath = '/public/freeNodes';
  static readonly GetNodeStatusPath = '/public/node/status';
  static readonly GetNodesPath = '/public/nodes';
  static readonly UpgradeNodePath = '/public/nodes/software';
  static readonly GetNodeByIdPath = '/public/nodes/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * List the free Nodes present on a network.
   *
   * Sends a request to any Node to list all of the free Nodes that are present on
   * the network.
   * @param params The `NodesService.ListFreeNodesParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `ips`: List of IP addresses to use for discovery of free nodes.
   *   If this parameter is not specified, then, nodes will be discovered
   *   using multicast.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  ListFreeNodesResponse(params: NodesService.ListFreeNodesParams): __Observable<__StrictHttpResponse<Array<FreeNodeInformation>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.ips || []).forEach(val => {if (val != null) __params = __params.append('ips', val.toString())});
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/freeNodes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<FreeNodeInformation>>;
      })
    );
  }
  /**
   * List the free Nodes present on a network.
   *
   * Sends a request to any Node to list all of the free Nodes that are present on
   * the network.
   * @param params The `NodesService.ListFreeNodesParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `ips`: List of IP addresses to use for discovery of free nodes.
   *   If this parameter is not specified, then, nodes will be discovered
   *   using multicast.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  ListFreeNodes(params: NodesService.ListFreeNodesParams): __Observable<Array<FreeNodeInformation>> {
    return this.ListFreeNodesResponse(params).pipe(
      __map(_r => _r.body as Array<FreeNodeInformation>)
    );
  }

  /**
   * Sends a request to a Node to get the status of that node.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetNodeStatusResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<NodeStatusResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/node/status`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<NodeStatusResult>;
      })
    );
  }
  /**
   * Sends a request to a Node to get the status of that node.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetNodeStatus(regionId?: string,
    accessClusterId?: number): __Observable<NodeStatusResult> {
    return this.GetNodeStatusResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as NodeStatusResult)
    );
  }

  /**
   * List Nodes of the cluster.
   *
   * If no parameters are specified, all Nodes currently on the Cohesity Cluster are
   * returned.
   * Specifying parameters filters the results that are returned.
   * @param params The `NodesService.GetNodesParams` containing the following parameters:
   *
   * - `showSystemDisks`: ShowSystemdisks is used to specify whether to display system disks for
   *   the nodes.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `includeOnlyUnassignedNodes`: IncludeOnlyUnassignedNodes will return nodes that are not yet assigned to
   *   any cluster partition. If this parameter is specified as true and
   *   ClusterPartitionIdList is also non-empty, then no nodes will be returned.
   *
   * - `includeMarkedForRemoval`: IncludeMarkedForRemoval is used to specify whether to include nodes
   *   marked for removal.
   *
   * - `ids`: IdList is the ids of nodes to be returned. If empty, all
   *   nodes are returned.
   *
   * - `fetchStats`: FetchStats is used to specify whether to call Stats service to fetch the
   *   stats for the nodes.
   *
   * - `clusterPartitionIds`: ClusterPartitionIdList specifies the list of Ids used to filter the
   *   nodes by specified cluster partition.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetNodesResponse(params: NodesService.GetNodesParams): __Observable<__StrictHttpResponse<Array<Node>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.showSystemDisks != null) __params = __params.set('showSystemDisks', params.showSystemDisks.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.includeOnlyUnassignedNodes != null) __params = __params.set('includeOnlyUnassignedNodes', params.includeOnlyUnassignedNodes.toString());
    if (params.includeMarkedForRemoval != null) __params = __params.set('includeMarkedForRemoval', params.includeMarkedForRemoval.toString());
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    if (params.fetchStats != null) __params = __params.set('fetchStats', params.fetchStats.toString());
    (params.clusterPartitionIds || []).forEach(val => {if (val != null) __params = __params.append('clusterPartitionIds', val.toString())});
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/nodes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Node>>;
      })
    );
  }
  /**
   * List Nodes of the cluster.
   *
   * If no parameters are specified, all Nodes currently on the Cohesity Cluster are
   * returned.
   * Specifying parameters filters the results that are returned.
   * @param params The `NodesService.GetNodesParams` containing the following parameters:
   *
   * - `showSystemDisks`: ShowSystemdisks is used to specify whether to display system disks for
   *   the nodes.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `includeOnlyUnassignedNodes`: IncludeOnlyUnassignedNodes will return nodes that are not yet assigned to
   *   any cluster partition. If this parameter is specified as true and
   *   ClusterPartitionIdList is also non-empty, then no nodes will be returned.
   *
   * - `includeMarkedForRemoval`: IncludeMarkedForRemoval is used to specify whether to include nodes
   *   marked for removal.
   *
   * - `ids`: IdList is the ids of nodes to be returned. If empty, all
   *   nodes are returned.
   *
   * - `fetchStats`: FetchStats is used to specify whether to call Stats service to fetch the
   *   stats for the nodes.
   *
   * - `clusterPartitionIds`: ClusterPartitionIdList specifies the list of Ids used to filter the
   *   nodes by specified cluster partition.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetNodes(params: NodesService.GetNodesParams): __Observable<Array<Node>> {
    return this.GetNodesResponse(params).pipe(
      __map(_r => _r.body as Array<Node>)
    );
  }

  /**
   * Upgrade the software on a Node.
   *
   * Sends a request to upgrade the software version of a Node. By default, the
   * Node that the request is sent to is the only one upgraded, but the user can
   * specify if they want to attempt to upgrade all free nodes on the network.
   * Before using this, you need to upload a new package to the Node you want to
   * upgrade by using the /public/packages endpoint.
   * @param params The `NodesService.UpgradeNodeParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpgradeNodeResponse(params: NodesService.UpgradeNodeParams): __Observable<__StrictHttpResponse<UpgradeNodeResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/public/nodes/software`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UpgradeNodeResult>;
      })
    );
  }
  /**
   * Upgrade the software on a Node.
   *
   * Sends a request to upgrade the software version of a Node. By default, the
   * Node that the request is sent to is the only one upgraded, but the user can
   * specify if they want to attempt to upgrade all free nodes on the network.
   * Before using this, you need to upload a new package to the Node you want to
   * upgrade by using the /public/packages endpoint.
   * @param params The `NodesService.UpgradeNodeParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpgradeNode(params: NodesService.UpgradeNodeParams): __Observable<UpgradeNodeResult> {
    return this.UpgradeNodeResponse(params).pipe(
      __map(_r => _r.body as UpgradeNodeResult)
    );
  }

  /**
   * List details about a single node.
   *
   * Returns the Node corresponding to the specified Node Id.
   * @param params The `NodesService.GetNodeByIdParams` containing the following parameters:
   *
   * - `id`: Id of the Node
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetNodeByIdResponse(params: NodesService.GetNodeByIdParams): __Observable<__StrictHttpResponse<Array<Node>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/nodes/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Node>>;
      })
    );
  }
  /**
   * List details about a single node.
   *
   * Returns the Node corresponding to the specified Node Id.
   * @param params The `NodesService.GetNodeByIdParams` containing the following parameters:
   *
   * - `id`: Id of the Node
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetNodeById(params: NodesService.GetNodeByIdParams): __Observable<Array<Node>> {
    return this.GetNodeByIdResponse(params).pipe(
      __map(_r => _r.body as Array<Node>)
    );
  }
}

module NodesService {

  /**
   * Parameters for ListFreeNodes
   */
  export interface ListFreeNodesParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * List of IP addresses to use for discovery of free nodes.
     * If this parameter is not specified, then, nodes will be discovered
     * using multicast.
     */
    ips?: Array<string>;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetNodes
   */
  export interface GetNodesParams {

    /**
     * ShowSystemdisks is used to specify whether to display system disks for
     * the nodes.
     */
    showSystemDisks?: boolean;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * IncludeOnlyUnassignedNodes will return nodes that are not yet assigned to
     * any cluster partition. If this parameter is specified as true and
     * ClusterPartitionIdList is also non-empty, then no nodes will be returned.
     */
    includeOnlyUnassignedNodes?: boolean;

    /**
     * IncludeMarkedForRemoval is used to specify whether to include nodes
     * marked for removal.
     */
    includeMarkedForRemoval?: boolean;

    /**
     * IdList is the ids of nodes to be returned. If empty, all
     * nodes are returned.
     */
    ids?: Array<number>;

    /**
     * FetchStats is used to specify whether to call Stats service to fetch the
     * stats for the nodes.
     */
    fetchStats?: boolean;

    /**
     * ClusterPartitionIdList specifies the list of Ids used to filter the
     * nodes by specified cluster partition.
     */
    clusterPartitionIds?: Array<number>;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpgradeNode
   */
  export interface UpgradeNodeParams {
    body: UpgradeNodeParameters;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetNodeById
   */
  export interface GetNodeByIdParams {

    /**
     * Id of the Node
     */
    id: number;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { NodesService }
