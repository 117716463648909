import { AppModuleConfig, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';

const heliosBackupAccessFn = (ctx: StateAccessContext): boolean => {
  const isFeatureFlagEnabled = ctx.FEATURE_FLAGS.globalHeliosBackupEnabled;
  const heliosBackupViewAccess = ctx.MCM_BACKUP_CONFIG_VIEW;

  return isFeatureFlagEnabled && heliosBackupViewAccess;
};

export const HeliosBackupConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'helios-backup.**',
      url: '/helios-backup',
      loadChildren: () => import('./helios-backup.module').then(m => m.HeliosBackupModule),
    },
  ],
  allClusterMap: {
    'helios-backup': {
      globalContext: true,
      heliosOnly: true,
    },
    'helios-backup.runs': {
      globalContext: true,
      heliosOnly: true,
    },
    'helios-backup.target-schedule': {
      globalContext: true,
      heliosOnly: true,
    },
  },
  getStateAccessMap(): StateAccessMap {
    return {
      'helios-backup': heliosBackupAccessFn,
      'helios-backup.runs': heliosBackupAccessFn,
      'helios-backup.target-schedule': heliosBackupAccessFn,
    };
  },
};
