import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { YaraRuleUploadDialogComponent } from './yara-rule-upload-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class YaraRuleUploadDialogService {
  constructor(private dialog: MatDialog) {}

  /**
   * Launches YARA rule upload dialog
   *
   * @returns dialog observable
   */
  launch(): Observable<any> {
    const dialogRef = this.dialog.open(YaraRuleUploadDialogComponent, {
      maxWidth: 'unset',
      width: '50rem',
    });
    return dialogRef.afterClosed().pipe(filter(Boolean));
  }
}
