import { Api } from '@cohesity/api/private';
import {
  hadoopEnvironments,
  noSqlEnvironments,
  SourceKeys,
  sourceTypesDisplayNames,
} from '@cohesity/iris-shared-constants';

import {
  AGENT_MIN_VERSION_PIT_RESTORE,
  alertsDateRangeList,
  AlertsSeverity,
  alertsSeverityList,
  AlertsStatus,
  alertsStatusList,
  alertsTypeBucketList,
  ArchivalTarget,
  HIGHCHART_SERIES_COLOR,
  CloudTarget,
  ENVIRONMENTS_WITH_HEALTH_CHECKS,
  ENUM_ENV_TYPE,
  ENUM_FETCH_TYPE,
  ENUM_TASK_TYPE,
  ENUM_TIMESPAN_TYPE,
  envTypes,
  FETCH_TYPE,
  FlrRestoreMethod,
  HypervisorEnvGroupNames,
  NasSourceNames,
  SchedulingPolicyPeriodicity,
  taskTypes,
  timeSpanType,
  ExchangeGroups,
  HELP_MAP,
  HOST_TYPE_GROUPS,
  IndexedDocumentType,
  IndexingStatusType,
  JOB_GROUPS,
  JobEnvParamsV1,
  SERVICE_FEATURES,
  NonBifrsostAwareNasEnvs,
  AllLocalDrives,
  RestoreFileEntityStatus,
  RestoreType,
  SnapshotTargetType,
  globalStateParams,
  RestoreTaskUiStatus,
  OFFICE365_GROUPS,
  Office365ContainerNodeType,
  Office365SearchType,
} from '@cohesity/dataprotect/shared';
import { REGEX_FORMATS } from './formats.constants';
import { AwsAuthType, CloudSubscription, CloudSubscriptionType } from './cloud.constants';
import { LangFile, LocaleFile } from './locale.constants';
import { OracleBackupType, OracleDataGuardDBRole } from './oracle.constants';
import { Routes } from './routes.constants';
import { HadoopSourceEditMode } from './nosql-hadoop.constants';

export { Api } from '@cohesity/api/private';
export * from '@cohesity/iris-shared-constants';
export * from '@cohesity/utils';

// Note: @cohesity/dataprotect/shared/constants path has been added to
// facilitate bulk migration of constants to dataprotect-shared library.
// Avoid using this path for imports in other files.
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
export * from '@cohesity/dataprotect/shared/constants';
export * from './cassandra.constants';
export * from './cloud.constants';
export * from './hbase.constants';
export * from './hdfs.constants';
export * from './hive.constants';
export * from './locale.constants';
export * from './mongodb.constants';
export * from './ms-sql.constants';
export * from './nosql-hadoop.constants';
export * from './oracle.constants';
export * from './organization.constants';
export * from './platform.constants';
export * from './recovery-os-type.constants';
export * from './routes.constants';
export * from './uda.constants';

// For backwards compatibility with Angular JS, add constants modules
declare const angular: any;
const constModule = angular.module('C.constants');

constModule
  .constant('AGENT_MIN_VERSION_PIT_RESTORE', AGENT_MIN_VERSION_PIT_RESTORE)
  .constant('ALERTS_DATE_RANGE_LIST', alertsDateRangeList)
  .constant('ALERTS_SEVERITY_LIST', alertsSeverityList)
  .constant('ALERTS_STATUS_LIST', alertsStatusList)
  .constant('ALERTS_TYPE_BUCKET_LIST', alertsTypeBucketList)
  .constant('ALL_LOCAL_DRIVES', AllLocalDrives)
  .constant('API', Api)
  .constant('AWS_AUTH_TYPE', AwsAuthType)
  .constant('ENUM_ALERTS_SEVERITY', AlertsSeverity)
  .constant('ENUM_ALERTS_STATUS', AlertsStatus)
  .constant('ENUM_ARCHIVAL_TARGET', ArchivalTarget)
  .constant('ENUM_CLOUD_TARGET', CloudTarget)
  .constant('ENVIRONMENTS_WITH_HEALTH_CHECKS', ENVIRONMENTS_WITH_HEALTH_CHECKS)
  .constant('EXCHANGE_GROUPS', ExchangeGroups)
  .constant('CLOUD_SUBSCRIPTION_TYPE', CloudSubscriptionType)
  .constant('CLOUD_SUBSCRIPTION', CloudSubscription)
  .constant('HADOOP_ENVIRONMENTS', hadoopEnvironments)
  .constant('HADOOP_SOURCE_EDIT_MODE', HadoopSourceEditMode)
  .constant('HELP_MAP', HELP_MAP)
  .constant('HOST_TYPE_GROUPS', HOST_TYPE_GROUPS)
  .constant('INDEXED_DOCUMENT_TYPE', IndexedDocumentType)
  .constant('INDEXING_STATUS_TYPE', IndexingStatusType)
  .constant('ENV_PARAMS_ENUM', JobEnvParamsV1)
  .constant('JOB_GROUPS', JOB_GROUPS)
  .constant('LANG_FILE', LangFile)
  .constant('LOCALE_FILE', LocaleFile)
  .constant('NOSQL_ENVIRONMENTS', noSqlEnvironments)
  .constant('OFFICE365_GROUPS', OFFICE365_GROUPS)
  .constant('OFFICE365_CONTAINER_NODE_TYPE', Office365ContainerNodeType)
  .constant('OFFICE365_SEARCH_TYPE', Office365SearchType)
  .constant('ORACLE_BACKUP_TYPE', OracleBackupType)
  .constant('ORACLE_DG_DB_ROLE_TYPE', OracleDataGuardDBRole)
  .constant('RESTORE_FILE_ENTITY_STATUS', RestoreFileEntityStatus)
  .constant('RESTORE_TASK_UI_STATUS', RestoreTaskUiStatus)
  .constant('RESTORE_TYPE', RestoreType)
  .constant('Routes', Routes)
  .constant('SNAPSHOT_TARGET_TYPE', SnapshotTargetType)
  .constant('SOURCE_KEYS', SourceKeys)
  .constant('SOURCE_TYPE_DISPLAY_NAME', sourceTypesDisplayNames)
  .constant('ENUM_ENV_TYPE', ENUM_ENV_TYPE)
  .constant('ENUM_TASK_TYPE', ENUM_TASK_TYPE)
  .constant('ENUM_FETCH_TYPE', ENUM_FETCH_TYPE)
  .constant('ENUM_TIMESPAN_TYPE', ENUM_TIMESPAN_TYPE)
  .constant('ENV_TYPE_CONVERSION', envTypes)
  .constant('TASK_TYPE_CONVERSION', taskTypes)
  .constant('FETCH_TYPE_CONVERSION', FETCH_TYPE)
  .constant('TIMESPAN_TYPE_CONVERSION', timeSpanType)
  .constant('FORMATS', REGEX_FORMATS)
  .constant('NAS_SOURCE_NAMES', NasSourceNames)
  .constant('HV_ENV_GROUP_NAMES', HypervisorEnvGroupNames)
  .constant('SCHEDULING_POLICY_PERIODICITY', SchedulingPolicyPeriodicity)
  .constant('FLR_RESTORE_METHOD', FlrRestoreMethod)
  .constant('HIGHCHART_SERIES_COLOR', HIGHCHART_SERIES_COLOR)
  .constant('GLOBAL_STATE_PARAMS', globalStateParams)
  .constant('NON_BIFROST_AWARE_NAS_ENVS', NonBifrsostAwareNasEnvs)
  .constant('SERVICE_FEATURES', SERVICE_FEATURES);
