import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Threat } from '@cohesity/api/argus';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { ThreatDeleteDialogComponent } from './threat-delete-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class ThreatDeleteDialogService {
  constructor(private dialog: MatDialog) { }

  /**
   * Launches Delete Threat Dialog
   *
   * @returns dialog observable
   */
  launch(threat: Threat): Observable<any> {
    const dialogRef = this.dialog.open(ThreatDeleteDialogComponent, {
      data: threat
    });
    return dialogRef.afterClosed().pipe(filter(Boolean));
  }
}
