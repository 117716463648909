<h3 mat-dialog-title class="margin-bottom-lg">
  {{ 'dg.td.snapshotDetails' | translate }}
</h3>
<mat-dialog-content>
  <cog-table [source]="objects" name="dg.th.scan.snapshotSummaryPage" class="margin-left margin-right">
    <table mat-table>
      <ng-container matColumnDef="object">
        <th mat-header-cell *matHeaderCellDef>{{ 'object' | translate }}</th>
        <td mat-cell class="cog-table-object-name" *matCellDef="let row; let index = index">
          <div class="flex-row-xs vertical-align">
            <cog-icon
              [size]="'md'"
              [shape]="row.object.environment | objectTypeIcon: row.object.objectType"
              [matTooltip]="row.object.environment | environmentType">
            </cog-icon>
            <div class="flex-column">
              <div
                class="user-data-xxl auto-truncate"
                [matTooltip]="row.object.name | naLabel"
                cogDataId="object-name-{{ index }}">
                {{ row.object.name }}
              </div>
              <small
                class="user-data-lg auto-truncate"
                [matTooltip]="row.sourceInfo.name | naLabel"
                cogDataId="object-source-{{ index }}">
                {{ 'dg.dc.sourceColon' | translate: { source: (row.sourceInfo.name | naLabel) } }}
              </small>
            </div>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="system">
        <th mat-header-cell *matHeaderCellDef>{{ 'system' | translate }}</th>
        <td mat-cell *matCellDef="let row; let index = index" cogDataId="system-name-{{ index }}">
          <span class="user-data-xl auto-truncate" [matTooltip]="row.objectProtectionInfo.name | naLabel">
            {{ row.objectProtectionInfo.name }}
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="snapshotCount">
        <th mat-header-cell *matHeaderCellDef>{{ 'snapshotCount' | translate }}</th>
        <td mat-cell *matCellDef="let row; let index = index" cogDataId="snapshot-count-{{ index }}">
          {{ objectSnapshotCountMap.get(extractObjectId(row.id)) || 0 }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnNames"></tr>
      <tr mat-row *matRowDef="let row; columns: columnNames"></tr>
    </table>
  </cog-table>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button color="primary" mat-dialog-close>
    {{ 'cancel' | translate }}
  </button>
</mat-dialog-actions>
