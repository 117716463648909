import { Injectable } from '@angular/core';
import {
  KmsConfigurationResponse,
  KmsConfigurationResponseServiceApi,
} from '@cohesity/api/v1';
import {
  ActiveDirectories,
  ActiveDirectoryServiceApi,
  CreateStorageDomainParam,
  LDAPServiceApi,
  Ldap,
  Ldaps,
  ProtectionGroupServiceApi,
  StorageDomain,
  StorageDomainServiceApi,
  StorageDomains,
  UpdateStorageDomainParam,
  ViewServiceApi,
} from '@cohesity/api/v2';
import { SnackBarService } from '@cohesity/helix';
import { IrisContextService, flagEnabled } from '@cohesity/iris-core';
import { AjaxHandlerService, AutoDestroyable } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import { get, isEmpty, isNil, isObject, omitBy } from 'lodash-es';
import { Observable, forkJoin, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ClusterService, DialogService } from 'src/app/core/services';

import {
  DeleteStorageDomainDialogComponent,
  DeleteStorageDomainDialogParams,
} from './delete-storage-domain-dialog/delete-storage-domain-dialog.component';
import {
  KmsServiceTypes,
  StorageDomainFormModel,
  StorageDomainInfo,
} from './modify-storage-domain/storage-domain-form.model';

@Injectable({
  providedIn: 'root'
})
export class StorageDomainsService extends AutoDestroyable {

  /**
   * KMS server type map.
   */
  KmsServiceTypes = KmsServiceTypes;

  constructor(
    public activeDirectoryService: ActiveDirectoryServiceApi,
    private ajaxHandlerService: AjaxHandlerService,
    public clusterService: ClusterService,
    private dialogService: DialogService,
    private irisContextService: IrisContextService,
    public ldapProviderService: LDAPServiceApi,
    public kmsService: KmsConfigurationResponseServiceApi,
    private snackbar: SnackBarService,
    public storageDomainsServiceApi: StorageDomainServiceApi,
    private protectionGroupsService: ProtectionGroupServiceApi,
    public translate: TranslateService,
    private viewsServiceApi: ViewServiceApi,) {
    super();
  }

  /**
   * Get list of storage domains.
   *
   * @returns   Observable of list of storage domains.
   */
  getStorageDomainList(params: StorageDomainServiceApi.GetStorageDomainsParams): Observable<StorageDomains> {
    return this.storageDomainsServiceApi.GetStorageDomains({
      includeTenants: true,
      ...(params || {}),
    });
  }

  /**
   * Get storage domain by ID.
   *
   * @param   id   Storage domain id.
   * @returns   Observable of storage domain.
   */
  getStorageDomainById(id: number): Observable<StorageDomain> {
    return this.storageDomainsServiceApi.GetStorageDomainById({id: id, includeTenants: true});
  }

  /**
   * Fetch all active directories.
   *
   * @returns   Observable of active directories.
   */
  fetchActiveDirectories(tenantIds?: string[]): Observable<ActiveDirectories> {
    return this.activeDirectoryService.GetActiveDirectory({ includeTenants: true, tenantIds: tenantIds });
  }

  /**
   * Fetch all ldaps.
   *
   * @returns   Observable of ldaps.
   */
  fetchLdaps(tenantIds?: string[]): Observable<Ldap[]> {
    return this.ldapProviderService.GetLdaps({ includeTenants: true, tenantIds: tenantIds }).pipe(
      map((resp: Ldaps) => resp.ldaps || [])
    );
  }

  /**
   * Fetch all kms services.
   *
   * @returns   Observable of kms services.
   */
  fetchKms(params: KmsConfigurationResponseServiceApi.GetKmsConfigParams): Observable<KmsConfigurationResponse[]> {
    return this.kmsService.GetKmsConfig(params);
  }

  /**
   * Get deduplication policy.
   *
   * @param   storageDomain   Storage Domain.
   * @returns                 Deduplication policy string.
   */
  getDedupPolicy(storageDomain: StorageDomain): string {
    const { deduplicationParams = {} } = storageDomain?.storagePolicy || {};

    if (deduplicationParams.enabled && deduplicationParams.inlineEnabled) {
      return this.translate.instant('inline');
    } else if (deduplicationParams.enabled && !deduplicationParams.inlineEnabled) {
      return this.translate.instant('postProcess');
    } else {
      return this.translate.instant('none');
    }
  }

  /**
   * Get compression policy.
   *
   * @param   storageDomain   Storage Domain.
   * @returns                 Compression policy string.
   */
  getCompressionPolicy(storageDomain: StorageDomain): string {
    const { compressionParams = {} } = storageDomain?.storagePolicy || {};

    if (compressionParams.inlineEnabled) {
      return this.translate.instant('inline');
    } else if (!compressionParams?.inlineEnabled) {
      return this.translate.instant('postProcess');
    } else {
      return this.translate.instant('none');
    }
  }

  /**
   * Get storage domain's ldap provider name.
   *
   * @param   ldapId   Id of ldap provider.
   * @returns          Observable of ldap provider name.
   */
  getStorageDomainLdapProvider(ldapId: number): Observable<string> {
    return this.fetchLdaps()
      .pipe(
        this.untilDestroy(),
        map(ldaps => {
          const matchedLdap = ldaps.filter(ldap => ldap.id === ldapId)[0];
          return matchedLdap?.name || '';
        } ));
  }

  /**
   * Helper function to get Kms service's server type.
   *
   * @param   storageDomain   Storage Domain.
   * @returns                 Observable of Kms service type.
   */
  getKmsServerType(storageDomain: StorageDomain): Observable<null | KmsServiceTypes> {
    if (storageDomain.kmsServerId === 0) {
      return of(this.KmsServiceTypes.kInternalKMS);
    } else if (storageDomain.kmsServerId && storageDomain.kmsServerId !== 0) {
      return this.fetchKms({id: storageDomain.kmsServerId})
        .pipe(
          this.untilDestroy(),
          catchError(() => of(null)),
          map(kms => this.KmsServiceTypes[kms[0].serverType])
        );
    } else {
      return of(null);
    }
  }

  /**
   * Transform storage domain with customized data.
   *
   * @param   sd   Storage domain information.
   * @returns      Transformed storage domain.
   */
  transformSD(sd: StorageDomainInfo):  StorageDomainInfo {
    sd._physicalQuota = get(sd, 'physicalQuota.hardLimitBytes', 0);
    sd._logicalManaged = get(sd, 'stats.dataInBytes', 0);
    sd._physicalUsed =  get(sd, 'stats.dataWrittenBytes', 0);
    sd._deduplication = this.getStorageDomainDeduplication(sd);
    sd._compression = this.getStorageDomainCompression(sd);
    sd._encryption = this.getStorageDomainEncryption(sd);
    sd._cloudTier = get(sd, 'storagePolicy.cloudSpillVaultId') ? 'yes' : 'no';

    return sd;
  }

  /**
   * Transform form value to formatted api parameter.
   *
   * @param   formValue   Form values.
   * @param   storageDomain   The storage domain for editing.
   * @returns    UpdateStorageDomainParam or CreateStorageDomainParam.
   */
  transformFromFormGroup(
    formValue: StorageDomainFormModel,
    storageDomain?: StorageDomain,
    isDisaggregatedStorage = false): UpdateStorageDomainParam | CreateStorageDomainParam {

    const {
      authenticationProvider,
      compressionEnabled,
      compressionInlineEnabled,
      cloudTierSettings,
      clusterPartitionId,
      clusterPartitionName,
      deduplicationEnabled,
      deduplicationInlineEnabled,
      encryption,
      faultToleranceSettings,
      name,
      id,
      storageDomainQuotaSettings,
      viewQuotaSettings,
    } = formValue;

    const params: UpdateStorageDomainParam | CreateStorageDomainParam = {
      adDomainName: authenticationProvider.adDomainName,
      cloudDownWaterFallParams: cloudTierSettings.cloudTierEnabled ? {
        thresholdPercentage: cloudTierSettings.spaceUtilizationThresholdEnabled ?
          cloudTierSettings.spaceUtilizationThresholdPercentage : null,
        thresholdSecs: cloudTierSettings.thresholdTime,
      } : null,
      clusterPartitionId: clusterPartitionId,
      clusterPartitionName: clusterPartitionName,
      defaultViewQuota: viewQuotaSettings.quotaEnabled ? {
        alertLimitBytes: Math.round(viewQuotaSettings.alertPercentage * viewQuotaSettings.quotaLimit / 100),
        alertThresholdPercentage: viewQuotaSettings.alertPercentage,
        hardLimitBytes: Math.round(viewQuotaSettings.quotaLimit),
      } : null,
      id: id,
      kmsServerId: encryption.encryptionEnabled ? encryption.kmsServiceId : null,
      ldapProviderId: authenticationProvider.ldapProviderId,
      name: name,
      physicalQuota: storageDomainQuotaSettings.quotaEnabled ? {
        alertLimitBytes:
          Math.round(storageDomainQuotaSettings.alertPercentage * storageDomainQuotaSettings.quotaLimit / 100),
        alertThresholdPercentage: storageDomainQuotaSettings.alertPercentage,
        hardLimitBytes: Math.round(storageDomainQuotaSettings.quotaLimit),
      } : null,
      stats: {...storageDomain?.stats},
      storagePolicy: {
        cloudSpillVaultId: cloudTierSettings.cloudTierEnabled ? cloudTierSettings.externalTargetId : null,
        compressionParams: {
          inlineEnabled: !!compressionInlineEnabled,

          // Hard coded in UI.
          type: compressionEnabled ? 'Low' : 'None',
        },
        deduplicationParams: {
          enabled: !!deduplicationEnabled,
          inlineEnabled: !!deduplicationInlineEnabled,
        },

        // Hard coded in UI.
        encryptionType: encryption.encryptionEnabled ? 'Strong' : storageDomain?.storagePolicy?.encryptionType,
        erasureCodingParams: {
          enabled: !!faultToleranceSettings?.erasureCodingEnabled,
          inlineEnabled: !!faultToleranceSettings?.erasureCodingInlineEnabled,
          numCodedStripes: faultToleranceSettings?.numCodedStripes || 0,
          numDataStripes: faultToleranceSettings?.numDataStripes || 0,
        },
        numDiskFailuresTolerated: faultToleranceSettings?.numDiskFailuresTolerated,
        numNodeFailuresTolerated: faultToleranceSettings?.numDomainFailuresTolerated,
      },
      tenantIds: storageDomain?.tenantIds,
    };

    // If Disaggregated Storage, then force RF1 when creating Storage Domain.
    if (isDisaggregatedStorage) {
      params.storagePolicy.erasureCodingParams = undefined;
      params.storagePolicy.numDiskFailuresTolerated =
      params.storagePolicy.numNodeFailuresTolerated = 0;
    }

    // remove empty properties.
    omitBy(params, val => isNil(val) || (isObject(val) && isEmpty(val)));
    return params;
  }

  /**
   * Get storage domain deduplication string.
   *
   * @param   storageDomain   The storage domain object.
   * @return  Storage domain deduplication status.
   */
  getStorageDomainDeduplication (storageDomain: StorageDomainInfo): string {
    if (!storageDomain || !storageDomain.storagePolicy) {
      return this.translate.instant('naNotAvailableLetters');
    }
    const {deduplicationParams} = storageDomain.storagePolicy;

    switch (true) {
      case !deduplicationParams || !deduplicationParams.enabled:
        return this.translate.instant('no');

      // inline
      case (deduplicationParams.enabled && deduplicationParams.inlineEnabled):
        return this.translate.instant('inline');

      // post dedupe
      case (deduplicationParams.enabled && !deduplicationParams.inlineEnabled):
        return this.translate.instant('post');

      // some other case, fallback for uncertainty
      default:
        return this.translate.instant('naNotAvailableLetters');
    }
  }

  /**
   * Get storage domain compression string.
   *
   * @param   storageDomain   The storage domain object.
   * @return  Storage domain compression status.
   */
  getStorageDomainCompression (storageDomain: StorageDomainInfo): string {
    if (!storageDomain ||
      !storageDomain.storagePolicy) {
      return this.translate.instant('naNotAvailableLetters');
    }

    const {compressionParams} = storageDomain.storagePolicy;

    switch (true) {
      case !compressionParams || compressionParams.type === 'None':
        return this.translate.instant('no');

      case compressionParams && compressionParams.inlineEnabled:
        return this.translate.instant('inline');

      case compressionParams && !compressionParams.inlineEnabled && compressionParams.type !== 'None':
        return this.translate.instant('post');

      // some other case, fallback for uncertainty
      default:
        return this.translate.instant('naNotAvailableLetters');
    }
  }

  /**
   * Get storage domain encryption string.
   *
   * @param   storageDomain   The storage domain object.
   * @return  Storage domain encryption status.
   */
  getStorageDomainEncryption (storageDomain: StorageDomain): string {
    if (!storageDomain ||
      !storageDomain.storagePolicy) {
      return this.translate.instant('naNotAvailableLetters');
    }
    const { encryptionType, aesEncryptionMode } = storageDomain.storagePolicy;
    switch (encryptionType) {
      case undefined:
      case 'None':
        return this.translate.instant('disabled');
      case 'Strong':
        if (flagEnabled(this.irisContextService.irisContext, 'encryptionKeySizeDisplay') ||
          flagEnabled(this.irisContextService.irisContext, 'encryptionAESGCM')) {
          switch (aesEncryptionMode) {
            case 'GCM':
              return this.translate.instant('aesGCMEncryption');
            case 'CBC':
              return this.translate.instant('aesCBCEncryption');
          }
        } else {
          return this.translate.instant('enabled');
        }
        break;
      case 'Weak':
        return this.translate.instant('enabled');
      default:
        // some other case, fallback for uncertainty
        return this.translate.instant('naNotAvailableLetters');
    }
  }

  /**
   * Determines if the cluster has remote disk storage.
   *
   * @returns True if the cluster is a disaggregated storage solution.
   */
   isDisaggregatedStorage() {
    return flagEnabled(this.irisContextService.irisContext, 'remoteDisksModule') ||
      this.clusterService.isDisaggregatedStorage;
  }

  /**
   * Delete selected storage domain.
   * Case 1: if the current sd is linked to a tenant, users can't delete the sd directly.
   * Case 2: if the current sd has at least one data-lock view or data-lock protection jobs
   *         users can't delete the sd directly.
   * Case 3: none of case 1 or case 2 are satisfied, then users can directly delete the sd.
   *
   * @param   storageDomain   The selected sd to be deleted.
   */
  deleteSD(storageDomain: StorageDomain) {
    // If the storage domain is linked to a tenant, then reject immediately.
    if (storageDomain.tenantIds) {
      this.snackbar.open(this.translate.instant('storageDomainLinkedToTenant'), 'error');
      return;
    }

    // Request params to get all DataLock Views in selected Storage Domain.
    const getViewsParams: ViewServiceApi.GetViewsParams = {
      storageDomainIds: [storageDomain.id],
      includeStats: false,
      includeViewsWithDataLockEnabledOnly: true,
      useCachedData: flagEnabled(this.irisContextService.irisContext, 'useMagnetoCachedData'),
    };

    // Request params to get all DataLock Protection Groups in selected Storage Domain.
    const getProtectionJobParam: ProtectionGroupServiceApi.GetProtectionGroupsParams = {
      storageDomainId: storageDomain.id,
      includeLastRunInfo: false,
      includeGroupsWithDatalockOnly: true,
    };

    forkJoin([
      this.viewsServiceApi.GetViews(getViewsParams),
      this.protectionGroupsService.GetProtectionGroups(getProtectionJobParam),
    ])
      .pipe(this.untilDestroy())
      .subscribe(
        ([viewResp, jobResp]) => {
          if (viewResp.views?.length || jobResp.protectionGroups?.length) {
            // If any Views or Protection Groups have DataLock, then deny action.
            this.snackbar.open(this.translate.instant('storageDomainHasDataLock'), 'error');
            return;
          } else {
            // Open Delete Storage Domain dialog.
            const deleteSDParam: DeleteStorageDomainDialogParams = {
              storageDomain: storageDomain,
            };
            this.dialogService.showDialog(DeleteStorageDomainDialogComponent, deleteSDParam);
          }
        },
        e => this.ajaxHandlerService.handler(e)
      );
  }
}
