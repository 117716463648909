<span [ngClass]="{ 'policies-badge margin-right-sm': !!policies }">
  {{ (policies && policies[0]?.name || null) | naLabel }}
</span>
<span
  *ngIf="policies?.length > 1"
  class="policies-badge"
  matTooltip="{{ tooltipMsg }}"
  matTooltipPosition="right">
  +{{ policies.length - 1 }}
</span>
