import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { VirtualDiskInfo } from '@cohesity/api/v1';
import { DataTreeNodeContext, DataTreeNodeDetail } from '@cohesity/helix';
import { Environment } from 'src/app/shared/constants';

import { VmSourceDataNode } from '../vm-source-data-node';

/**
 * Map of v1 API Controller Types to their v2 API equivalent.
 */
const controllerTypeMap = {
  IDE: 'kIde',
  SATA: 'kSata',
  SCSI: 'kScsi',
};

/**
 * This component is used to set and update source special params for virtual machine nodes.
 * It is not created directly, but returned from a call to getSpecialParametersComponent
 * in VmSourceTreeService.
 */
@Component({
  selector: 'coh-vm-host-options',
  templateUrl: './vm-host-options.component.html',
  styleUrls: ['./vm-host-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VmHostOptionsComponent implements DataTreeNodeDetail<VmSourceDataNode>, OnInit {
  /**
   * The node context, including info about the node and it's selection status.
   */
  @Input() nodeContext: DataTreeNodeContext<VmSourceDataNode>;

  /**
   * Gets the node from the nodeContext.
   */
  get node(): VmSourceDataNode {
    return this.nodeContext.node;
  }

  /**
   * Gets the current options for the node. This either gets them from the selection options, or
   * the default params for the node.
   */
  get currentOptions() {
    return this.nodeContext.selection.getOptionsForNode(this.node.id) || {};
  }

  /**
   * Gets the list of excluded disks, if any, from the special parameters.
   */
  get excludeDisks() {
    return (this.currentOptions.vmSpecialParameters || {}).excludeDisks;
  }

  /**
   * Gets the truncate exchange log value, if any, from the special parameters.
   */
  get truncateExchangeLogs() {
    return (this.currentOptions.vmSpecialParameters || {}).truncateExchangeLogs;
  }

  /**
   * Virtual disks that can be excluded.
   */
  virtualDisks: VirtualDiskInfo[];

  /**
   * The form to contain the options.
   */
  form: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder, private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    // Holds the available virtual disks that can be excluded.
    // Defined on init so that it is instantiated only once.
    this.virtualDisks = (this.node.virtualDisks as VirtualDiskInfo[] || []).map(disk => ({
      ...disk,
      isDisabled: disk.diskMode === 'kIndependentNonPersistent' || disk.diskMode === 'kIndependentPersistent',
    }));
  }

  /**
   * Checks to see whether the node is registered as exchange application.
   *
   * @return   True if exchange registered, False otherwise.
   */
  get isExchangeRegistered(): boolean {
    const registrationInfo = this.node.data.registrationInfo || {};

    const exchangeAppsInfo = (registrationInfo.registeredAppsInfo || [])
      .find(appInfo => appInfo.environment === Environment.kExchange);

    return registrationInfo.environments?.includes(Environment.kExchange) &&
      exchangeAppsInfo?.authenticationStatus === 'kFinished' &&
      !exchangeAppsInfo?.authenticationErrorMessage;
  }

  /**
   * Updates the form based on the current options setting in preparation for displaying the form
   * dialog.
   */
  updateForm() {
    // Map the excluded disk list from the job selection to the actual disks options
    // that are available for the host.
    const diskOptions = (this.excludeDisks || []).map(excluded =>
      this.virtualDisks.find(disk => disk.busNumber === excluded.busNumber && disk.unitNumber === excluded.unitNumber)
    ).filter(Boolean);

    this.form = this.fb.group({
      showDisks: [!!diskOptions.length, Validators.required],
      excludeDisks: [diskOptions],
      exchangeLogTruncationEnabled:
        [Boolean(this.truncateExchangeLogs), Validators.required],
    });
  }

  /**
   * Updates the selection options after the form has been saved and the dialog has been closed.
   */
  onSaved() {
    const options = {
      sourceId: this.node.id,
      vmSpecialParameters: {
        excludeDisks: (this.form.value.excludeDisks || []).map(disk => ({
          controllerType: controllerTypeMap[disk.controllerType],
          busNumber: disk.busNumber,
          unitNumber: disk.unitNumber,
        })),
        truncateExchangeLogs: this.form.value.exchangeLogTruncationEnabled,
      },
    };
    this.nodeContext.selection.setOptionsForNode(this.node.id, options);
    this.cdr.detectChanges();
  }

  /**
   * Triggers on toggling showDisks.
   * If the toggle is set to off, reset excludeDisks.
   */
  toggleDisks() {
    if (!this.form.value.showDisks) {
      this.form.get('excludeDisks').setValue([]);
    }
  }
}
