import { Injectable } from '@angular/core';
import { ProtectdObjectsActionRequest, ProtectedObject, ProtectedObjectInfo } from '@cohesity/api/v2';
import { NavItem } from '@cohesity/helix';
import { SourceSelection } from '@cohesity/iris-source-tree';
import { StateService } from '@uirouter/core';
import { ObjectSearchResult, RestorePointSelection } from 'src/app/modules/restore/restore-shared';
import { cohesityGroups, ENV_GROUPS, Environment, PassthroughOptions, RecoveryAction } from 'src/app/shared';

import { ModalAction, ProtectionGroupService } from '../protection-group-shared';
import { SimpleObjectInfo } from './object-menu-provider';
import { ObjectProtectAction } from './object-protect-action.constants';
import { ObjectProtectionGroupAction } from './object-protection-group-action.constants';
import { ProtectedObjectsService } from './protected-objects.service';

/**
 * Helper class for creating object action items.
 */
@Injectable({
  providedIn: 'root',
})
export class ObjectActionCreator {
  constructor(
    private objectsService: ProtectedObjectsService,
    private protectionGroupService: ProtectionGroupService,
    private stateService: StateService,
  ) {}
  /**
   * Creates a protect object nav item
   *
   * @param objects          The objects being protected
   * @param selection  Most of the time, we can easily determine the selection based on
   *                         list of objects. Using sourceSelection will allow for more complex
   *                         configurations for tags, auto protect, etc...
   * @returns The NavItem
   */
  createProtectAction(objects: SimpleObjectInfo[], selection?: SourceSelection): NavItem {
    if (!objects || !objects.length) {
      return null;
    }

    const { environment } = objects[0];
    let { sourceId: selectedSourceId } = objects[0];

    // in case of root there is no parent node, so set to 1
    if (!selectedSourceId) {
      selectedSourceId = 1;
    }
    if (!selection) {
      // Simple selection logic. We usually only need more if we're working directly
      // from the source tree.
      selection = {
        sourceIds: objects.filter(object => object.objectType !== 'kTag').map(object => object.id),

        // Tag node ids are always stored in the tree as strings. It needs to be saved to the selection as an
        // array over numbers though.
        vmTagIds: objects.filter(object => object.objectType === 'kTag').map(object => [Number(object.id)]),
        excludeSourceIds: objects.reduce((exclusions, object) => {
          exclusions.push(...(object.excludedIds || []));
          return exclusions;
        }, []),
        sourceSpecialParameters: this.objectsService.getSourceSpecialParameters(objects),
      };
    }

    const navItem: NavItem = {
      displayName: 'protect',
      icon: 'helix:protect',
      state: 'protection-builder',
    };

    if (cohesityGroups.includes(environment) && environment !== Environment.kOracle) {
      navItem.stateParams = {
        environments: [environment],
        parentSourceEnvironment: environment,
        protectSources: objects.map(object => ({id: object.id})),
        excludeFilters: selection.excludeFilters,
        cid: objects[0].accessClusterId,
        regionId: objects[0].regionId,
      };
    } else {
      navItem.stateParams = {
        environments: [environment],
        protectionGroup: {
          selectedSourceId,
          selection,
          environment,

          // Set the view ids for view protection.
          viewIds: objects.map(object => object.id),
        },
        cid: objects[0].accessClusterId,
        regionId: objects[0].regionId,
      };
    }

    return navItem;
  }

  /**
   * Creates an action to edit a protected object
   *
   * @param   environment      The object's environment
   * @param   id               The object's id
   * @param   objectOptions    The object's passthrough options
   * @param   isAutoProtection True if this is for a parent's auto protection
   * @returns The edit protected object action
   */
  createEditObjectProtectionAction(
    environment: Environment,
    id: number,
    objectOptions: PassthroughOptions = {},
    isAutoProtection: boolean = false,
    forceEditOnChild: boolean = false,
    workloadType?: string
  ): NavItem {
    return {
      displayName: isAutoProtection && !forceEditOnChild ? 'editAutoProtection' : 'editProtection',
      icon: 'edit!outline',
      state: 'protection-builder',
      stateParams: {
        environments: [environment],
        objectId: id,
        cid: objectOptions.accessClusterId,
        regionId: objectOptions.regionId,
        workloadType,
      },
    } as any;
  }

  /**
   * Creates a protected object action for one or more protected objects.
   *
   * @param   objects         The objects to perform the action on.
   * @param   action          The action to perform (ProtectNow, Pause, Resume, Unprotect).
   * @param   callback        A callback to run after the action completes. This
   *                          can be used to refresh any cached data for the objects.
   * @param   objectOptions   The object's passthrough options
   * @param   objectActionKey The object action key.
   * @returns A nav item object.
   */
  createProtectedObjectAction(
    objects: ProtectedObjectInfo[],
    action: ObjectProtectAction,
    callback: () => void,
    objectOptions?: PassthroughOptions,
    objectActionKey?: ProtectdObjectsActionRequest['objectActionKey'],
  ): NavItem {
    if (!objects?.length) {
      return null;
    }
    switch (true) {
      case action === ObjectProtectAction.Pause
        && objects.some(object => object.objectBackupConfiguration.isPaused):
        // Pause is only valid if all selected objects are not paused.
        return null;
      case action === ObjectProtectAction.Resume
        && objects.some(object => !object.objectBackupConfiguration.isPaused):
        // Resume is only valid if all selected objects are paused.
        return null;
      case action === ObjectProtectAction.ProtectNow
        && objects.some(object => this.objectsService.isObjectRunning(object)):
        // Don't allow protect now if any of the objects are currently running.
        return null;
      case action === ObjectProtectAction.CancelRun
        && objects.some(object => !this.objectsService.isObjectRunning(object)):
        // Don't allow cancel now if any of the objects are currently running.
        return null;
    }

    if (!callback) {
      callback = () => undefined;
    }
    switch (action) {
      case ObjectProtectAction.Pause:
        return {
          displayName: 'pauseFutureRuns',
          icon: 'pause',
          action: () => {
            this.objectsService.pauseProtection({
              protectedObjects: objects,
            }, objectOptions, objectActionKey).subscribe(() => callback());
          }
        };
      case ObjectProtectAction.Resume:
        return {
          displayName: 'resumeFutureRuns',
          icon: 'play_arrow!outline',
          action: () => {
            this.objectsService.resumeProtection(objects, objectOptions, objectActionKey).subscribe(() => callback());
          }
        };
      case ObjectProtectAction.UnProtect:
        return {
          displayName: 'unprotect',
          icon: 'remove_circle!outline',
          action: () => {
            this.objectsService.unprotectObjects({
              protectedObjects: objects,
            }, objectOptions, objectActionKey).subscribe(data => {
              if (data) {
                // For unprotect, we should reload the whole state, since the
                // protection stats for a source will have changed.
                this.stateService.reload();

                callback();
              }
            });
          }
        };
      case ObjectProtectAction.ProtectNow:
        return {
          displayName: 'runNow',
          icon: 'queue!outline',
          action: () => this.objectsService.runNow(
            objects, objectOptions, objectActionKey).subscribe(data => {
              if (data) {
                // For ProtectNow, we should reload the whole state, since the
                // protection stats for a source will have changed.
                this.stateService.reload();

                callback();
              }
            })
        };
      case ObjectProtectAction.CancelRun:
        return {
          displayName: 'cancelRun',
          icon: 'cancel!outline',
          action: () => {
            this.objectsService.cancelRun(objects, objectOptions, objectActionKey).subscribe(() => callback());
          }
        };
    }
  }

  /**
   * Creates a protection group object action for a protected object.
   *
   * @param   object       The object to perform the action on.
   * @param   simpleObject The simple object being recovered.
   * @param   action       The action to perform (Run, Edit).
   * @returns A nav item object.
   */
  createProtectionGroupObjectAction(
    object: ProtectedObjectInfo,
    simpleObject: SimpleObjectInfo,
    action: ObjectProtectionGroupAction,
  ): NavItem {
    const protectionGroup = simpleObject?.protectionGroups?.[0];

    if (!object || !protectionGroup) {
      return null;
    }

    // TODO: This is currently only wired up for dms regions.
    // Support multiple protection groups and protection groups from
    // disconnected clusters.
    if (!simpleObject.regionId) {
      return null;
    }

    // TODO: Add protection group pause, resume here.
    if (action === ObjectProtectionGroupAction.Run && this.objectsService.isObjectRunning(object)) {
      return null;
    }

    switch (action) {
      case ObjectProtectionGroupAction.Run:
        return {
          displayName: 'runNow',
          icon: 'queue!outline',
          action: () => {
            this.protectionGroupService.runJobAction(
              ModalAction.Start,
              {
                jobDescription: {
                  jobUid: {
                    clusterId: protectionGroup.clusterId,
                    regionId: protectionGroup.regionId,
                  },
                  name: protectionGroup.name,
                  jobId: protectionGroup.id.split(':').pop(),
                  policyId: protectionGroup.policyId,
                }
              }
            );
          },
          options: {
            type: 'protectionGroupAction',
          },
        };
      case ObjectProtectionGroupAction.Edit:
        return {
          displayName: 'editProtection',
          icon: 'edit!outline',
          state: 'protection-builder',
          stateParams: {
            id: protectionGroup.id.split(':').pop(),
            uid: protectionGroup.id,
            cid: protectionGroup.clusterId,
            regionId: protectionGroup.regionId,
            environments: [simpleObject.environment],
            // TODO(pg): Add cloudJobType when protectionGroup protectionType
            // is available.
            // cloudJobType: protectionGroup.protectionType === Environment.kRDSSnapshotManager ?
            //   Environment.kRDSSnapshotManager : undefined,
          },
          options: {
            type: 'protectionGroupAction',
          },
        };
    }
  }

  /**
   * Creates a protected object action for one or more protected objects which are deleted.
   *
   * @param   simpleObjects   The objects to perform the action on.
   * @param   action          The action to perform (Pause, Unprotect).
   * @param   objectOptions   The object's passthrough options
   * @param   objectActionKey The object action key.
   * @returns A nav item object.
   */
  createDeletedProtectedObjectAction(
    simpleObjects: SimpleObjectInfo[],
    action: ObjectProtectAction | ObjectProtectionGroupAction,
    objectOptions?: PassthroughOptions,
    objectActionKey?: ProtectdObjectsActionRequest['objectActionKey'],
  ): NavItem {
    if (!simpleObjects?.length) {
      return null;
    }

    switch (action) {
      case ObjectProtectAction.Pause:
        return {
          displayName: 'pauseFutureRuns',
          icon: 'pause',
          action: () => {
            this.objectsService.pauseProtection({simpleObjects}, objectOptions, objectActionKey).subscribe();
          }
        };
      case ObjectProtectAction.UnProtect:
        return {
          displayName: 'unprotect',
          icon: 'remove_circle!outline',
          action: () => {
            this.objectsService.unprotectObjects({simpleObjects}, objectOptions, objectActionKey).subscribe(data => {
              if (data) {
                // For unprotect, we should reload the whole state, since the
                // protection stats for a source will have changed.
                this.stateService.reload();
              }
            });
          }
        };
    }
  }

  /**
   * Creates and returns a recovery action for the supplied protected object, based on the supplied recovery type.
   *
   * @param type The type of the recovery.
   * @param simpleObject The simple object.
   * @param object The protected object.
   * @param restorePointSelection The Restore point selection of the selected object.
   * @param objectOptions The object's passthrough options.
   * @returns The recovery NavItem object.
   */
  createRecoverAction(
    type: RecoveryAction,
    simpleObject: SimpleObjectInfo,
    object?: ProtectedObject,
    restorePointSelection?: RestorePointSelection,
    objectOptions?: PassthroughOptions
  ): NavItem {
    if (simpleObject.useRestorePointSelection && !restorePointSelection) {
      // If simple object only wants restore point selection and no restore
      // point selection is specified.
      return;
    }

    const objectsArr = object ? [object] : null;

    switch (type) {
      case RecoveryAction.RecoverVMs:
        return this.createRecoverVmAction(objectsArr, restorePointSelection, objectOptions);
      case RecoveryAction.RecoverAzureSQL:
        return this.createRecoverAzureSqlAction(objectsArr, restorePointSelection, objectOptions);
      case RecoveryAction.RecoverFiles:
      case RecoveryAction.DownloadFilesAndFolders:
        // If a restorePointSelection is provided, do not return recover files
        // action as that is not supported with a specific restore point.
        return this.createRecoverFilesAction(simpleObject, type, restorePointSelection, objectOptions);
      case RecoveryAction.RecoverNasVolume:
      case RecoveryAction.RecoverSanGroup:
      case RecoveryAction.RecoverSanVolumes:
        return this.createRecoverStorageVolumeAction([object], restorePointSelection, objectOptions);
      case RecoveryAction.RecoverApps:
        return this.createRecoverAppsAction(simpleObject, objectsArr, restorePointSelection, objectOptions);
      case RecoveryAction.InstantVolumeMount:
        return this.createInstantVolumeMountAction(object, restorePointSelection, objectOptions);
      case RecoveryAction.RecoverObjects:
        return this.createRecoverObjectsAction([object], restorePointSelection, objectOptions);
        case RecoveryAction.RecoverMongodbClusters:
          return this.createMongodbPhysicalRecoverAction([object], null, objectOptions);
    }
  }

  /**
   * Creates and returns a recovery action for the supplied protected object, based on the supplied recovery type.
   *
   * @param type The type of the recovery.
   * @param objects The array of protected objects.
   * @param objectOptions The object's passthrough options.
   * @returns The recovery NavItem object.
   */
  createBulkRecoverAction(
    type: RecoveryAction,
    objects?: ProtectedObject[],
    objectOptions?: PassthroughOptions
  ): NavItem {
    switch (type) {
      case RecoveryAction.RecoverVMs:
        return this.createRecoverVmAction(objects, null, objectOptions);
      case RecoveryAction.RecoverAzureSQL:
        return this.createRecoverAzureSqlAction(objects, null, objectOptions);
      case RecoveryAction.RecoverApps:
        return this.createRecoverAppsAction(null, objects, null, objectOptions);
      case RecoveryAction.RecoverNasVolume:
        return this.createRecoverStorageVolumeAction(objects, null, objectOptions);
      case RecoveryAction.RecoverObjects:
        return this.createRecoverObjectsAction(objects, null, objectOptions);
    }
  }

  /**
   * Creates a recover objects nav item.
   *
   * @param   objects   The objects being recovered.
   * @param   restorePointSelection   The Restore point selection of the selected object.
   * @param   objectOptions   The object's passthrough options.
   * @returns The NavItem.
   */
  createRecoverObjectsAction(
    objects?: ProtectedObject[],
    restorePointSelection?: RestorePointSelection,
    objectOptions: PassthroughOptions = {},
  ): NavItem {

    const environment = objects?.[0]?.environment || restorePointSelection?.objectInfo?.environment;
    let state;

    switch (environment as Environment) {
      case Environment.kUDA:
        state = 'recover-uda-ng';
        break;
      case Environment.kSAPHANA:
        state = 'recover-saphana';
        break;
    }

    if (!state) {
      return;
    }

    const restorePoints = this.getRestorePointSelection(objects, restorePointSelection);

    if (!restorePoints) {
      return;
    }

    return {
      displayName: 'recover',
      icon: 'restore',
      state: state,
      stateParams: {
        restorePoints,
        cid: objectOptions.accessClusterId,
        regionId: objectOptions.regionId,
      },
    } as any;
  }

  /**
   * Creates a recover vm nav item
   *
   * @param objects The objects being recovered.
   * @param restorePointSelection The Restore point selection of the selected object.
   * @param objectOptions The object's passthrough options
   * @returns The NavItem
   */
  createRecoverVmAction(
    objects?: ProtectedObject[],
    restorePointSelection?: RestorePointSelection,
    objectOptions: PassthroughOptions = {}
  ): NavItem {
    const restorePoints = this.getRestorePointSelection(objects, restorePointSelection);

    if (!restorePoints) {
      return;
    }

    return {
      displayName: 'recover',
      icon: 'restore',
      state: 'recover-vm-ng',
      stateParams: {
        restorePoints,
        cid: objectOptions.accessClusterId,
        regionId: objectOptions.regionId,
      },
    } as any;
  }

  /**
   * Creates a recover Azure SQL nav item
   *
   * @param objects The objects being recovered.
   * @param restorePointSelection The Restore point selection of the selected object.
   * @param objectOptions The object's passthrough options
   * @returns The NavItem
   */
  createRecoverAzureSqlAction(
    objects?: ProtectedObject[],
    restorePointSelection?: RestorePointSelection,
    objectOptions: PassthroughOptions = {},
  ): NavItem {
    const restorePoints = this.getRestorePointSelection(objects, restorePointSelection);

    if (!restorePoints) {
      return;
    }

    return {
      displayName: 'recover',
      icon: 'restore',
      state: 'recover-azure-sql-ng',
      stateParams: {
        restorePoints,
        cid: objectOptions.accessClusterId,
        regionId: objectOptions.regionId,
        workloadType: 'kSQLDatabase'
      },
    } as any;
  }

  /**
   * Creates and returns a MOngoDB action for the supplied protected object.
   *
   * @param object The protected object.
   * @returns The recovery NavItem object.
   */
  createMongodbPhysicalRecoverAction( objects?: ProtectedObject[],
    restorePointSelection?: RestorePointSelection,
    objectOptions: PassthroughOptions = {},
  ): NavItem {
    const restorePoints = this.getRestorePointSelection(objects, restorePointSelection);

    if (!restorePoints) {
      return;
    }

    return {
      displayName: 'recover',
      icon: 'restore',
      state: 'recover-mongodb-physical-ng',
      stateParams: {
        restorePoints,
        cid: objectOptions.accessClusterId,
        regionId: objectOptions.regionId,
        recoveryAction: RecoveryAction.RecoverMongodbClusters
      }
    };
  }

  /**
   * Creates a recover file nav item that will open the source for browsing.
   *
   * @param simpleObject The simple object being recovered.
   * @param restorePointSelection The Restore point selection of the selected object.
   * @param type The type of the recovery.
   * @param objectOptions The object's passthrough options
   * @returns The NavItem
   */
  createRecoverFilesAction(
    simpleObject: SimpleObjectInfo,
    type: RecoveryAction.RecoverFiles | RecoveryAction.DownloadFilesAndFolders,
    restorePointSelection?: RestorePointSelection,
    objectOptions: PassthroughOptions = {},
  ): NavItem {
    let displayName;
    let icon;
    let environments: Environment[];

    // In case of Physical Source both the environments needs to be passed here
    // otherwise v2 protected objects doesnt return the file based objects.
    if (simpleObject?.environment === Environment.kPhysical) {
      environments = [Environment.kPhysical, Environment.kPhysicalFiles];
    }

    if (type === RecoveryAction.RecoverFiles) {
      displayName = 'recoverFiles';
      icon = 'note!left';
    } else {
      displayName = 'downloadFiles';
      icon = 'helix:download';
    }

    return {
      displayName,
      icon,
      state: 'recover-files-ng',
      stateParams: {
        browseSourceId: simpleObject.id,
        restorePoints: [simpleObject.restorePointSelection],
        cid: objectOptions.accessClusterId,
        environments,
        regionId: objectOptions.regionId,
      },
    } as any;
  }

  /**
   * Creates a clone nav item that will open the flow to clone an item.
   *
   * @param simpleObject The simple object being recovered.
   * @param objectOptions The object's passthrough options
   * @returns The NavItem
   */
  createCloneAction(simpleObject: SimpleObjectInfo, objectOptions?: PassthroughOptions,
    objects?: ProtectedObject[]): NavItem {
    const snapshot = simpleObject?.restorePointSelection?.snapshot;
    const latestsnapshot = objects[0]?.latestSnapshotsInfo;

    const cloneNavItem = {
      icon: 'helix:clone',
      displayName: 'clone',
      state: 'clone-vms.clone-options',
      stateParams: {}
    };

    if (!snapshot && simpleObject.environment === Environment.kVMware && latestsnapshot) {
      cloneNavItem.stateParams = {
        entityId: simpleObject.id,
        jobId: simpleObject.protectionGroups[0].id.split(':').pop(),
        jobInstanceId: objects[0]?.latestSnapshotsInfo?.[0]?.runInstanceId,
        cid: objectOptions.accessClusterId,
      };
     return cloneNavItem;
    }

    if (objectOptions.regionId || !snapshot) {
      // Clone is not available for dmaas
      return;
    }

    if (ENV_GROUPS.cloudSources.includes(simpleObject.environment) ||
      !ENV_GROUPS.hypervisor.includes(simpleObject.environment) ||
      simpleObject.environment === Environment.kAcropolis) {
      // Clone is only available for onprem non acropolis hypervisors
      return;
    }

    const { protectionGroupId, runInstanceId, externalTargetInfo } = snapshot;
    cloneNavItem.stateParams = {
      entityId: simpleObject.id,
      jobId: protectionGroupId?.split(':').pop(),
      jobInstanceId: runInstanceId,
      vaultId: externalTargetInfo?.targetId,
      vaultName: externalTargetInfo?.targetName,
      vaultType: externalTargetInfo?.targetType,
      cid: objectOptions.accessClusterId,
    };

    return cloneNavItem;
  }

  /**
   * Creates a instant volume mount action nav item
   *
   * @param object The object being recovered.
   * @param restorePointSelection The Restore point selection of the selected object.
   * @param objectOptions The object's passthrough options
   * @returns The NavItem
   */
  createInstantVolumeMountAction(
    object?: ProtectedObject,
    restorePointSelection?: RestorePointSelection,
    objectOptions: PassthroughOptions = {}
  ): NavItem {
    if (objectOptions.regionId) {
      // Instant volume mount is not available for regions (dms) right now
      return;
    }

    const restorePoints = this.getRestorePointSelection([object], restorePointSelection);

    if (!restorePoints) {
      return;
    }

    return {
      displayName: 'instantVolumeMount',
      icon: 'helix:volume',
      state: 'instant-volume-mount-ng',
      stateParams: {
        restorePoint: restorePoints[0],
        cid: objectOptions.accessClusterId,
      },
    };
  }

  /**
   * Creates a recover storage volume nav item
   *
   * @param object The object being recovered.
   * @param restorePointSelection The Restore point selection of the selected object.
   * @param objectOptions The object's passthrough options
   * @returns The NavItem
   */
  createRecoverStorageVolumeAction(
    object?: ProtectedObject[],
    restorePointSelection?: RestorePointSelection,
    objectOptions: PassthroughOptions = {}
  ): NavItem {
    const restorePoints = this.getRestorePointSelection(object, restorePointSelection);

    if (!restorePoints) {
      return;
    }

    return {
      displayName: 'recover',
      icon: 'restore',
      state: 'recover-storage-volume-ng',
      stateParams: {
        restorePoint: restorePoints[0],
        cid: objectOptions.accessClusterId,
        regionId: objectOptions.regionId,
      }
    } as any;
  }

  /**
   * Creates a recover apps nav item
   *
   * @param   simpleObject           The simple object.
   * @param   objects                The objects being recovered.
   * @param   restorePointSelection  The Restore point selection of the selected object.
   * @param   objectOptions          The object's passthrough options
   * @returns The NavItem
   */
  createRecoverAppsAction(
    simpleObject?: SimpleObjectInfo,
    objects?: ProtectedObject[],
    restorePointSelection?: RestorePointSelection,
    objectOptions: PassthroughOptions = {}
  ): NavItem {
    const environment = simpleObject?.environment || objects?.[0].environment;
    let state;

    switch (environment as Environment) {
      case Environment.kSQL:
        state = 'recover-ms-sql';
        break;
      case Environment.kOracle:
        state = 'recover-oracle';
        break;
      case Environment.kExchange:
        state = 'recover-exchange-ng';
    }

    if (!state) {
      return;
    }

    const restorePoints = this.getRestorePointSelection(objects, restorePointSelection);

    if (!restorePoints) {
      return;
    }

    return {
      displayName: 'recover',
      icon: 'restore',
      state,
      stateParams: {
        restorePoints,
        cid: objectOptions.accessClusterId,
        regionId: objectOptions.regionId,
        recoveryObjects: objects,
      },
    };
  }

  /**
   * Function to return an array of restore point selection for provided
   * protected objects and restore point selection.
   *
   * @param objects The objects being recovered.
   * @param restorePointSelection The Restore point selection of the selected object.
   * @returns The array of restore point selection.
   */
  getRestorePointSelection(
    objects?: ProtectedObject[],
    restorePointSelection?: RestorePointSelection,
  ): RestorePointSelection[] {
    if (objects?.length > 1 && restorePointSelection) {
      // Only one object is allowed if a restore point selection parameter is
      // provided.
      throw new Error('Only one object can be provided with a restore point selection.');
    }

    if (!restorePointSelection && !objects) {
      // If neither restorePointSelection or objects array is present,
      // return empty. This action will get filtered out later.
      return;
    }

    if (restorePointSelection) {
      // Use the provided restore point selection. This is typically used to
      // recover from a specific snapshot and not the latest snapshot.
      return [restorePointSelection];
    }

    // Protected objects is an optional argument and is only present for
    // clusters which support v2 in mcm mode. Select the default(latest)
    // restore point selection from the object.
    return objects.filter(object => Boolean(object)).map((object: Required<ProtectedObject>) =>
      new ObjectSearchResult(object).defaultRestorePointSelection
    );
  }
}
