import { sourceGroupIconTransform } from '@cohesity/dataprotect/shared';
import { RestoreSearchResult } from './restore-search-result';
import { ObjectSearchResult } from './object-search-result';

/**
 * Get VM Icon for recover/clone VMs.
 *
 * @param object  Restore object.
 * @param isAzureSqlWorkload  True if it is Azure SQL.
 * @returns Icon string.
 */
export function getVmIcon(object: RestoreSearchResult, isAzureSqlWorkload = false): string {
  if (object.resultType === 'protectionGroup') {
    return sourceGroupIconTransform(object.sourceEnvironment || object.environment);
  }
  if (object.objectType) {
    switch (object.objectType) {
      case 'kVirtualApp':
        return 'helix:object-vcloud-vapp';
      case 'kVirtualAppTemplate':
        return 'helix:object-vapp-template';
    }
  }
  if (object instanceof ObjectSearchResult && object.osType) {
    // Casting this to any, because the api types do not appear to match what
    // is actually being returned.
    switch (object.osType as any) {
      case 'Linux':
        return 'helix:object-vm-linux';
      case 'Windows':
        return 'helix:object-vm-windows';
      case 'Aix':
        return 'helix:object-vm-aix';
      case 'Solaris':
        return 'helix:object-vm-solaris';
    }
  }
  return isAzureSqlWorkload ? 'helix:object-db' : 'helix:object-vm';
}
