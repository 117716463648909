import { FilterConfig } from '@cohesity/utils';

export const HardwareFilterSettings: FilterConfig[] = [
  {
    name: 'diskType',
    prefix: 'enum.performanceTier.',
    values: ['SATA-SSD', 'SATA-HDD', 'PCIeSSD'],
  },
  {
    name: 'nodeStatus',
    prefix: '',
    values: ['active', 'inactive', 'removing', 'readyForPhysicalRemoval'],
  },
];
