<ng-template cohFormSectionEditMode>
  <h3 class="cog-modal-title">{{ 'dg.td.scan.detectionType' | translate }}</h3>
  <div>
    <mat-checkbox
      [checked]="enableBuiltInThreats"
      (change)="toggleBuiltInThreats($event.checked)"
      cogDataId="builtin-threats-checkbox">
      {{ 'dg.td.scan.checkBuiltInThreats' | translate }}
    </mat-checkbox>
    <div class="mat-caption margin-left-lg margin-top-sm">
      {{ 'dg.td.scan.builtIocScanCount' | translate: { count: '110k+' } }}
    </div>
    <cog-banner status="info" class="margin-top-sm margin-left-lg" *ngIf="showBuiltInScanLimitations">
      {{ 'dg.td.scan.supportWindowsOnly' | translate }}
      <a class="padding-left" (click)="openHelpLink()">
        {{ 'dg.td.scan.viewDocumentation' | translate }}
      </a>
    </cog-banner>
  </div>
  <div class="margin-top" *ngIf="isCustomThreatsEnabled">
    <mat-checkbox
      [checked]="enableCustomThreats"
      cogDataId="custom-threats-checkbox"
      (change)="toggleCustomThreats($event.checked)">
      {{ 'dg.td.scan.checkCustomThreats' | translate }}
    </mat-checkbox>
    <div class="margin-left-lg margin-top-sm" *ngIf="enableCustomThreats">
      <span
        class="item-label mat-caption margin-right"
        *ngIf="customThreatIds?.length"
        cogDataId="selected-threats"
        [inheritDataId]="true">
        {{ 'dg.td.scan.selectedCustomThreatCount' | translate: { count: customThreatIds?.length } }}
      </span>
      <a class="argus-card-anchor" (click)="selectCustomThreats()" cogDataId="select-threats" [inheritDataId]="true">
        {{ 'dg.td.scan.selectThreats' | translate }}
      </a>
    </div>
  </div>
  <div
    class="margin-top"
    [dgHasPermissions]="['DGAAS_INTEGRATE_THIRD_PARTY_THREAT_INTELLIGENCE_FEED', 'DGAAS_MODIFY']"
    *ngIf="isIntegrationsEnabled">
    <mat-checkbox
      [checked]="enableIntegrations"
      cogDataId="integrations-checkbox"
      (change)="toggleIntegrations($event.checked)">
      {{ 'dg.td.scan.selectIntegrations' | translate }}
    </mat-checkbox>
    <div class="mat-caption margin-left-lg margin-top-sm">
      {{ 'dg.td.scan.integrationHelpText' | translate }}
    </div>
    <div class="margin-left-lg margin-top flex-row-xs flex-equal-division vertical-align" *ngIf="enableIntegrations">
      <dg-td-select-ioc-integrations
        [value]="selectedIntegrationIds"
        (selectedIntegrations)="selectIntegrations($event)"
        [integrations]="integrations"
        [disabled]="iocIntegrationsLoading">
      </dg-td-select-ioc-integrations>
      <div>
        <mat-spinner *ngIf="iocIntegrationsLoading" mode="indeterminate" [diameter]="25"> </mat-spinner>
      </div>
    </div>
  </div>
</ng-template>

<ng-template cohFormSectionViewMode>
  <div cogDataId="selection-summary">
    <h3 class="cog-modal-title">{{ 'dg.td.scan.detectionType' | translate }}</h3>
    <ul class="flex-row c-ul-inline c-ul-dot-separator no-margin">
      <li class="no-padding-right" *ngIf="enableBuiltInThreats">
        <span class="item-label user-data-md auto-truncate">
          {{ 'dg.td.scan.checkBuiltInThreats' | translate }}
        </span>
      </li>
      <li class="no-padding-right" *ngIf="enableCustomThreats">
        <span class="item-label">
          {{ 'dg.td.scan.totalCustomThreatSelected' | translate: { count: customThreatIds?.length } }}
        </span>
      </li>
      <li *ngIf="enableIntegrations">
        <span class="item-label">
          {{ 'dg.td.scan.integrations.selectedSummary' | translate: { list: selectedIntegrationsNames } }}
        </span>
      </li>
    </ul>
  </div>
</ng-template>

<ng-template cohFormSectionViewCta>
  <button
    mat-icon-button
    class="edit-form-section"
    type="button"
    cogDataId="detection-type-edit-form-section"
    [inheritDataId]="true"
    (click)="editDetectionType()">
    <cog-icon shape="edit!outline"></cog-icon>
  </button>
</ng-template>

<ng-template cohFormSectionNavigator>
  <button
    mat-flat-button
    [disabled]="formControl.invalid"
    class="save-form-section"
    type="button"
    color="primary"
    cogDataId="save-form-section"
    (click)="updateValue()">
    <span matTooltipPosition="below" [matTooltip]="errorToolTip | translate" [matTooltipDisabled]="!errorToolTip">
      {{ 'dg.td.scan.nextSetting' | translate }}
    </span>
  </button>
</ng-template>
