import { combineLatest, Observable, of } from 'rxjs';
import { filter, map, startWith, switchMap, take } from 'rxjs/operators';
import { FlagData } from 'src/app/models';

import { Injectable } from '@angular/core';
import { BasicClusterInfo, ClusterInfo, Tenant, User } from '@cohesity/api/v1';
import { HeliosMfa, IsVaultClusterParams } from '@cohesity/api/v2';
import { ClusterConfig, IrisContext, IrisContextService } from '@cohesity/iris-core';
import { AppConfiguration, ConfigurationService } from '@cohesity/shared/core';

import { AppStateService } from './app-state.service';
import { ClusterGflagService } from './cluster-gflag.service';
import { ClusterService } from './cluster.service';
import { FeatureFlags, FeatureFlagsService } from './feature-flags.service';
import { HeliosMfaService } from './helios-mfa.service';
import { OrganizationsService } from './organizations.service';
import { TenantService } from './tenant.service';
import { UserService } from './user.service';


/**
 * This service implements IrisContext Service for iris based on existing services.
 */
@Injectable()
export class AppIrisContextService extends IrisContextService {
  constructor(
    private userService: UserService,
    private clusterService: ClusterService,
    private featureFlags: FeatureFlagsService,
    private gflagService: ClusterGflagService,
    private tenantService: TenantService,
    private appStateService: AppStateService,
    private organizationsService: OrganizationsService,
    private heliosMfaService: HeliosMfaService,
    private configurationService: ConfigurationService,
  ) {
    super();
    organizationsService.init(this);
  }

  /**
   * Creates the iris context observable
   *
   * @returns An observable of the iris context.
   */
  getIrisContext(): Observable<IrisContext> {
    return combineLatest([
      this.clusterService.clusterInfo$.pipe(
        filter(info => !!info?.id),
        take(1),
        map(info => info.id),
        startWith(undefined as number)
      ),
      this.featureFlags.featureFlags$,
      this.gflagService.gFlagData$,
      this.userService.user$,
      this.clusterService.basicClusterInfo$,
      this.clusterService.clusterInfo$,
      this.tenantService.impersonatedTenant$,
      this.appStateService.selectedScope$,
      this.organizationsService.isOrganizationEnabled$,
      this.heliosMfaService.mfaPreferences$,
      this.configurationService.config$,
      combineLatest([this.featureFlags.featureFlags$, this.userService.user$]).pipe(
        switchMap(([flags, user]) => flags?.onPremVaultClusters && user ?
            this.clusterService.vaultClusterInfo$ : of({
            isVaultCluster: false,
          })),
      )
    ]).pipe(
      map(
        ([
          accessClusterId,
          flags,
          gflags,
          user,
          basicClusterInfo,
          clusterInfo,
          impersonsatedTenant,
          scope,
          isOrganizationEnabled,
          heliosMfaPreferences,
          appConfiguration,
          vaultClusterInfo
        ]) =>
          this.mapIrisContext(
            accessClusterId as number,
            flags as FeatureFlags,
            gflags as FlagData,
            user as User,
            basicClusterInfo as BasicClusterInfo,
            clusterInfo as ClusterInfo,
            impersonsatedTenant as Tenant,
            scope as ClusterConfig,
            isOrganizationEnabled as boolean,
            heliosMfaPreferences as HeliosMfa,
            appConfiguration as AppConfiguration,
            vaultClusterInfo as IsVaultClusterParams
          )
      )
    );
  }

  /**
   * Creates the iris context based on all of its dependencies.
   * This will be fired any time one of its inputs changes.
   * It also creates copies of data models so that changes can never
   * affect the original data model.
   *
   * @param accessClusterId The access cluster id.
   * @param featureFlags Feature flag value.
   * @param gflags gflag value.
   * @param user The current user.
   * @param basicClusterInfo basic cluster info value.
   * @param clusterInfo current cluster info.
   * @param impersonatedTenant impersonated tenant info.
   * @param scope The selected cluster scope.
   * @param isOrganizationEnabled Whether organizations are enabled for the user.
   * @param heliosMfaPreferences The helios MFA preferences.
   * @param vaultClusterInfo Vault cluster information.
   * @returns The iris context.
   */
  private mapIrisContext(
    accessClusterId: number,
    featureFlags: FeatureFlags,
    gflags: FlagData,
    user: User,
    basicClusterInfo: BasicClusterInfo,
    clusterInfo: ClusterInfo,
    impersonatedTenant: Tenant,
    scope: ClusterConfig,
    isOrganizationEnabled: boolean,
    heliosMfaPreferences: HeliosMfa,
    appConfiguration: AppConfiguration = {},
    vaultClusterInfo: IsVaultClusterParams
  ): IrisContext {
    return {
      accessClusterId,
      featureFlags: { ...featureFlags },
      gflags: {...gflags},
      privs: { ...this.userService.privs },
      basicClusterInfo: { ...basicClusterInfo },
      clusterInfo: { ...clusterInfo },
      impersonatedTenant: { ...impersonatedTenant },
      user: { ...user },
      selectedScope: { ...scope },
      isOrganizationEnabled,
      mfaPreferences: {
        helios: heliosMfaPreferences,
      },
      appConfiguration: { ...appConfiguration },
      vaultClusterInfo: { ...vaultClusterInfo },
    };
  }
}
