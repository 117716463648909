import { NgModule } from '@angular/core';
import {
  BytesFractionPipe,
  CdpIoFilterIconPipe,
  DatePipeWrapper,
  DayCountPipe,
  DurationPipe,
  FileIconPipe,
  FilterByPipe,
  HighlightTextPipe,
  StatusIconMapPipe,
  StatusIconReducePipe,
  IsJobRunFinishedPipe,
  LabelPipe,
  ObjectIconPipe,
  OrgIdDistilledPipe,
  OrgIdSplitPipe,
  PrincipalIconPipe,
  QosPolicyPipe,
  S3KeyMappingPipe,
  SimpleListPipe,
  SourceGroupIconPipe,
  TenantIdFormatterPipe,
  ValueChangeTrendPipe,
  NgViewBoxDedupeStatusPipe,
} from '@cohesity/dataprotect/shared';
import { UtilPipesModule } from '@cohesity/utils';
import { TranslateModule } from '@ngx-translate/core';

import { BackupTargetIconPipe } from './backup-target-icon.pipe';
import { BackupTargetTypePipe } from './backup-target-type.pipe';
import { HumanizeDurationPipe } from './humanize-duration.pipe';
import { HumanizeFromDaysPipe } from './humanize-from-days.pipe';
import { LargeNumberPipe } from './large-number.pipe';
import { PrincipalNamePipe } from './principal-name.pipe';
import { RecoveryActionIconPipe } from './recovery-action-icon.pipe';
import { RecoveryObjectIconPipe } from './recovery-object-icon.pipe';
import { SnapshotLocationPipe } from './snapshot-location.pipe';
import { TimeObjectToDatePipe } from './time-object-to-date.pipe';
import { UserPrivilegesPipe } from './user-privileges.pipe';
import { NgViewBoxCompressionPipe } from './view-box-compression.pipe';
import { NgViewBoxEncryptionPipe } from './view-box-encryption.pipe';
import { NgViewBoxRedundancyPipe } from './view-box-redundancy.pipe';

const PIPES = [
  BackupTargetIconPipe,
  BackupTargetTypePipe,
  BytesFractionPipe,
  CdpIoFilterIconPipe,
  DatePipeWrapper,
  DayCountPipe,
  DurationPipe,
  FileIconPipe,
  FilterByPipe,
  HighlightTextPipe,
  HumanizeDurationPipe,
  HumanizeFromDaysPipe,
  IsJobRunFinishedPipe,
  LabelPipe,
  LargeNumberPipe,
  NgViewBoxCompressionPipe,
  NgViewBoxDedupeStatusPipe,
  NgViewBoxEncryptionPipe,
  NgViewBoxRedundancyPipe,
  ObjectIconPipe,
  OrgIdSplitPipe,
  OrgIdDistilledPipe,
  PrincipalIconPipe,
  PrincipalNamePipe,
  QosPolicyPipe,
  RecoveryActionIconPipe,
  RecoveryObjectIconPipe,
  S3KeyMappingPipe,
  SimpleListPipe,
  SnapshotLocationPipe,
  SourceGroupIconPipe,
  StatusIconReducePipe,
  TenantIdFormatterPipe,
  TimeObjectToDatePipe,
  UserPrivilegesPipe,
  ValueChangeTrendPipe
];

const IMPORT_EXPORT_MODULES = [
  UtilPipesModule,
];

/**
 * Pipes Module.
 */
@NgModule({
  imports: [IMPORT_EXPORT_MODULES, TranslateModule.forChild(), StatusIconMapPipe],
  declarations: PIPES,
  exports: [PIPES, IMPORT_EXPORT_MODULES, StatusIconMapPipe],
  providers: PIPES,
})
export class PipesModule {}
