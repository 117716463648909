import { Injectable } from '@angular/core';
import { ClusterIdentifier, Role, SessionUser, User } from '@cohesity/api/v1';
import { HeliosAccountsServiceApi, ImpersonationAction } from '@cohesity/api/v2';
import { AuthService, AuthType } from '@cohesity/shared/login';
import { ClearSubscriptions } from '@cohesity/utils';
import { get, intersection } from 'lodash-es';
import { Observable, from, of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { ClusterService } from 'src/app/core/services/cluster.service';
import { BifrostCapabilities } from 'src/app/shared/constants';
import { UserType } from 'src/app/shared/constants';

import { AjsUpgradeService } from './ajs-upgrade.service';
import { AppStateService } from './app-state.service';
import { FeatureFlagsService } from './feature-flags.service';
import { TenantService } from './tenant.service';
import { AppUser, UserStoreService } from './user-store.service';
import { WorkflowAccessContextService } from './workflow-access-context.service';

/**
 * Angular replacement for legacy UserService service.
 * Some methods are pass-through for now and will be replaced.
 */
@Injectable({
  providedIn: 'root',
})
export class UserService extends ClearSubscriptions {
  /**
   * Legacy UserService AngularJS service.
   */
  private ajsUserService: any;

  /**
   * Legacy TenantService AngularJS service.
   */
  private ajsTenantService: any;

  constructor(
    ajsUpgrade: AjsUpgradeService,
    private appStateService: AppStateService,
    private clusterService: ClusterService,
    private heliosAccountsService: HeliosAccountsServiceApi,
    private tenantService: TenantService,
    private workflowAccessContextService: WorkflowAccessContextService,
    private featureFlags: FeatureFlagsService,
    private userStoreService: UserStoreService,
    private authService: AuthService
  ) {
    super();
    this.ajsUserService = ajsUpgrade.get('UserService');
    this.ajsTenantService = ajsUpgrade.get('TenantService');

    this.subscriptions.push(
      this.userStoreService.loginData$.pipe(filter((data) => !!data)).subscribe((data) => {
        this.ajsUserService.updateLocalStorage(data);
        this.ajsUserService.updateUserOnRootScope(data);
      })
    );
  }

  /**
   * Returns login data.
   */
  get loginData() {
    return this.userStoreService.loginData;
  }

  /**
   * Returns user data.
   */
  get user(): AppUser {
    return get(this.loginData, 'user');
  }

  /**
   * Gets the user value as an observable.
   */
  get user$(): Observable<AppUser> {
    return this.userStoreService.user$;
  }

  /**
   * Returns privs for current user.
   */
  get privs(): any {
    return this.userStoreService.getUserPrivs();
  }

  /**
   * Returns bifrostCapabilities for current user.
   */
  get bifrostCapabilities(): BifrostCapabilities {
    return get(this.loginData, 'user._tenant._bifrostCapabilities', {});
  }

  /**
   * Returns all capabilities supported by the current cluster.
   */
  get clusterCapabilities(): BifrostCapabilities {
    return get(this.loginData, 'user._clusterCapabilities', {});
  }

  /**
   * Returns true is user is impersonated via different tenant.
   */
  get isImpersonated(): boolean {
    return Boolean(this.tenantService.impersonatedTenantId);
  }

  /**
   * Returns true is user has switchable accounts
   */
  get isAccountSwitchable(): boolean {
    return this.ajsTenantService.isTenantAccountSwitchable();
  }

  /**
   * Return true if the user is logged in.
   */
  get isLoggedIn(): boolean {
    return this.ajsUserService.isLoggedIn();
  }

  /**
   * Saves state name and params so user can be returned
   * to the requested state upon successful login.
   *
   * @param toState The state name being transitioned to.
   * @param toParams There params for the state being transitioned to.
   */
  capturePreLoginRequest(toState, toParams) {
    this.ajsUserService.capturePreLoginRequest(toState, toParams);
  }

  /**
   * Contructs the redirect url using prelogin request details.
   *
   * @returns returns prelogin requested redirect url.
   */
  getPreLoginRequestUrl() {
    return this.ajsUserService.getPreLoginRequestUrl();
  }

  /**
   * Pass through to the AJS IDP redirect method.
   */
  authenticateIDP() {
    this.ajsUserService.authenticateIDP();
  }

  /**
   * Pass through to AJS authenticate method.
   *
   * @param credentials The login credentials.
   */
  authenticateLocal(credentials) {
    return this.ajsUserService.authenticate(credentials);
  }

  /**
   * This method uses the authentication type
   * to determine which authentication method to call.
   *
   * @param credentials The authenticate credentials to use.
   * @param authType authentication type of this request.
   */
  authenticate(credentials, authType: AuthType = 'local') {
    switch (authType) {
      case 'local':
        return this.authenticateLocal(credentials);
      case'sso':
        return this.authService.authenticateSso(credentials,'sso', this.getPreLoginRequestUrl());
      case 'ad':
        return this.authenticateAd(credentials);
      case 'salesforce':
      default:
        throw new Error('unsupported Auth Type');
    }
  }

  /**
   * This function is a pass through to the AJS authenticate method.
   *
   * @param credentials The login credentials.
   */
  authenticateAd(credentials) {
    return this.ajsUserService.authenticate(credentials);
  }

  /**
   * Initiates session termination.
   *
   * @returns  Promise resolving logout state.
   */
  logout(): Promise<any> {
    this.workflowAccessContextService.clearWorkflowAccessContext();
    this.appStateService.bustCache();
    return this.ajsUserService.logout();
  }

  /**
   * Determines if viewing as tenant user either by impersonating a tenant
   * user or tenant user is logged in.
   *
   * @returns  True if tenant user, False otherwise.
   */
  isTenantUser(): boolean {
    return this.ajsUserService.isTenantUser();
  }

  /**
   * Determines whether the user is a Bifrost Tenant User.
   *
   * @returns  True if Bifrost tenant user, False otherwise.
   */
  isBifrostTenantUser(): boolean {
    return this.ajsUserService.isBifrostTenantUser();
  }

  /**
   * Determines whether the logged-in user organization is owning the provided
   * entity like vlan, user, viewboxes, remote cluster, source etc
   *
   * @param    entityOwnerTenantIds    A list of tenantIds or tenantId.
   * @return   Return true if logged-in user organization is the owner of the
   *           provided entity else false.
   */
  isEntityOwner(entityOwnerTenantIds: string | string[]): boolean {
    // Since DMaaS user is a tenant user and in helios we assume that the entities that are
    // returned are for the tenant. There is no SP use-case yet.
    if (this.isDmsUser) {
      return true;
    }

    return this.ajsUserService.isEntityOwner(entityOwnerTenantIds);
  }

  /**
   * Determines if logged in user is restricted user or not.
   *
   * @method   isRestrictedUser
   * @returns  True if restricted user, False otherwise.
   */
  isRestrictedUser(): boolean {
    return this.userStoreService.isRestrictedUser();
  }

  /**
   * Determines whether the user is a Helios Tenant User.
   * - Currently this only applies to GCPBaaS User, When multi-tenancy
   *   on Helios is designed. We will redesign this.
   *
   * @returns  True if Helios tenant user, False otherwise.
   */
  isHeliosTenantUser(): boolean {
    // TODO(Sam): When Helios MultiTenancy is designed. Make sure the user
    // can indicate what type of profile the user belongs to.
    // Currently only the helios user has the property googleAccount filed.
    return get(this.loginData, 'user.profiles[0].tenantType') === 'Mcm' ||
      (get(this.loginData, 'user.googleAccount') && this.isTenantUser());
  }

  /**
   * Determines whether to show special SSO/IDP logout page on user logout.
   *
   * @return  True if we can show special SSO/IDP logout page else
   *                      return false.
   */
  isIdpLogoutPageEnabled(): boolean {
    return this.ajsUserService.isIdpLogoutPageEnabled();
  }

  /**
   * Determines whether the cluster is configured for Certificate only.
   *
   * @return   True if the cluster is set to certificate only
   *                       Authentication.
   */
  isCertificateOnlyAuthentication(): boolean {
    return this.ajsUserService.isCertificateOnlyAuthentication();
  }

  /**
   * Determines whether login banner is enabled of the cluster.
   *
   * @return   True if login banner is enabled else false
   */
  isLoginBannerEnabled(): boolean {
    return this.ajsUserService.isLoginBannerEnabled();
  }

  /**
   * Function which internally calls AJS user service to update user info.
   *
   * @param user Modified user info to update.
   * @returns Observable of user.
   */
  updateUser(user: User): Observable<User> {
    return from(this.ajsUserService.updateUser(user));
  }

  /**
   * Calls http get users endpoints and returns observable of response.
   *
   * @returns Observable of users.
   */
  getUser(params: any): Observable<User> {
    return from(this.ajsUserService.getUser(params));
  }

  /**
   * Calls http get users endpoints and returns observable of response.
   *
   * @param   Ajs getAllUsers api param.
   * @returns Observable of all users.
   */
  getAllUsers(params: any) {
    return from(this.ajsUserService.getAllUsers(params));
  }

  /**
   * Returns the tenantId of the logged in user or impersonated organization
   * tenantId or if the tenant user is a part of multiple organization, then
   * get the switched tenantId.
   *
   * @returns Observable of Tenant ID.
   */
  getUserTenantId() {
    return this.userStoreService.getUserTenantId();
  }

  /**
   * Fetch all roles.
   *
   * @param   Ajs getAllRoles api param.
   * @returns Observable of all roles.
   */
  getAllRoles(params: any) {
    return this.ajsUserService.getAllRoles(params);
  }

  /**
   * Returns if current user is local admin.
   *
   * @returns boolean True if user is local admin.
   */
  isLocalAdminUser(): boolean {
    return this.user && this.user.username === 'admin' && this.user.domain === 'LOCAL';
  }

  /**
   * Determines whether the current user is a Self-Service user.
   *
   * @returns True iff the current user is MSFT based Self Service user.
   */
  isSelfServiceUser(): boolean {
    return this.userStoreService.isSelfServiceUser();
  }

  /**
   * Creates a new login session for the current user.
   *
   * @param   newLoginData        The current user information
   * @param   [isSessionResuming] Is the session resuming
   * @returns An observable with the logged in data.
   */
  createSession(newLoginData: SessionUser, isSessionResuming = false): Observable<SessionUser> {
    return from(this.ajsUserService.createSession(newLoginData, isSessionResuming));
  }

  /**
   * Sets Inactivity Timeout
   *
   * @param inactivityTimeout custom inactivity timeout provided by user
   */
  setInactivityTimeout(inactivityTimeout: number) {
    this.ajsUserService.setInactivityTimeout(inactivityTimeout);
  }

  /**
   * Redirect to user accessible state.
   */
  redirectPostLogin() {
    this.ajsUserService.redirectPostLogin();
  }

  /**
   * Broadcasts login success message so that nav service and workflow context
   * are initialized.
   */
  loginSuccess() {
    this.ajsUserService.loginSuccess();
  }

  /**
   * Called on new User activity. Updates localStorage value, and cancels any
   * existing inactivity timeout promise before establishing a new one.
   */
  registerNewActivity() {
    this.ajsUserService.registerNewActivity();
  }

  /**
   * Update Impersonation data in local storage after impersonation.
   */
  updateImpersonationUser(oldAccountId: string, newAccountId: string): Observable<any> {
    return this.logStopImpersonation(oldAccountId).pipe(
      // the sessionUser API will always return -1, -1 for clusterIndentifiers which will
      // cause problem in API calls that requires explicit ids like v1/public/mcm/stats/storage.
      // So we need to call the following API to get all clusters ids for impersonation.
      switchMap(() => this.clusterService.getAllClusters()),
      map(response => response?.upgradeInfo?.map(
        ({clusterId, clusterIncarnationId}) => ({clusterId, clusterIncarnationId}))
      ),
      switchMap(clusters => from(this.ajsUserService.updateImpersonationUser(clusters))),
      switchMap(() => this.logStartImpersonation(newAccountId)),
    );
  }

  /**
   * Returns true if the current user is a DMaaS configured user.
   *
   * @returns boolean True if user is DMaaS user.
   */
  get isDmsUser(): boolean {
    return !!(this.user && this.user.profiles);
  }

  /**
   * Returns the tenant ID for DMaaS User.
   *
   * @returns string The tenantID of the DMaaS user.
   */
  get dmsTenantId(): string {
    if (!this.isDmsUser) {
      return '';
    }

    return this.user.profiles[0].tenantId;
  }

  /**
   * DMaaS tenant ID is of the format '<sf_account_id>:<tenant_id>'.
   * This function returns the 'tenant_id' only.
   * TODO(tauseef): Rename dmsTenantId to convey it contains SfAccountId.
   *
   * @returns string tenant ID excluding account ID prefix.
   */
  get dmsTenantIdWithoutAccountId(): string {
    if (!this.isDmsUser) {
      return '';
    }

    const tenantId = this.dmsTenantId;
    if (!tenantId) {
      return '';
    }

    return tenantId.substring(tenantId.indexOf(':') + 1);
  }

  /**
   * Return type of logged in User
   *
   * @returns User type of logged in User
   */
  get loggedInUserType(): UserType {
    return this.isHeliosTenantUser() ? UserType.organizationUser : UserType.heliosUser;
  }

  /**
   * Function to check if loggedIn user type is same as given user type
   *
   * @param userType User Type Object
   *
   * @returns Boolean if same type returns true else false
   */
  isUserTypeSameAsLoggedInUser(userType: UserType) {
    return this.loggedInUserType === userType;
  }

  /**
   * Function to check whether loggedIn user privileges are superior than
   * other list of user. If loggedIn user privileges are superior he'll be
   * allowed to edit, delete or reset password of a user.
   *
   * @param role Role assigned to user
   *
   * @returns true if role exist with mentioned privileges, false otherwise
   */
  canCurrentUserModifyRole(role: Role): boolean {
    const privs = this.privs;

    switch (true) {
      case privs.MCM_MODIFY_SUPER_ADMIN:
        return true;

      case privs.MCM_MODIFY_COHESITY_ADMIN: {
        const adminPrivData = intersection(role?.privileges,
          ['MCM_MODIFY_SUPER_ADMIN', 'MCM_MODIFY_COHESITY_ADMIN']).length > 0;

        if (!adminPrivData) {
          return true;
        }
        break;
      }

      case privs.PRINCIPAL_MODIFY: {
        const principalPriv = intersection(role?.privileges,
          ['MCM_MODIFY_SUPER_ADMIN', 'MCM_MODIFY_COHESITY_ADMIN', 'PRINCIPAL_MODIFY']).length > 0;

        if (!principalPriv) {
          return true;
        }
        break;
      }

      default: return false;
    }
  }

  /**
   * Function to determine whether user is editable
   *
   * @param user User data for each row in the user table
   * @param idpIdList List of IDP IDs belonging to the logged-in user
   * @returns true if user Idp is present in idpIdList, false otherwise
   */
  isUserBelongsToLoggedInUserSSO(user: any, idpIdList: number[]) {
    const idpIds = Array.isArray(idpIdList) ? idpIdList : [];
    return idpIds.includes(user?.idpUserInfo?.idpId || user?.idpUserInfo?.oidcIdpId);
  }

  /**
   * Function to determine whether user is editable
   *
   * @param user User data for each row in the user table
   * @param role Role assigned to user
   * @param userType User Type Object
   * @param idpIdList List of IDP IDs belonging to the logged-in user
   * @returns true if user is editable, false otherwise
   */
  isUserEditable(user: any, role: Role, userType: UserType, idpIdList?: number[]): boolean {
    const hasModifyPrivilege = this.privs.PRINCIPAL_MODIFY || this.privs.MCM_MODIFY_SUPER_ADMIN;
    const isUserTypeSameAsLoggedInUser = this.isUserTypeSameAsLoggedInUser(userType);
    const canCurrentUserModifyRole = this.canCurrentUserModifyRole(role);
    const isSameSid = this.user.sid === user.sid;

    if (!hasModifyPrivilege) {
      return false;
    }

    if (isUserTypeSameAsLoggedInUser) {
      return isSameSid || canCurrentUserModifyRole;
    } else {
      return canCurrentUserModifyRole && this.isUserBelongsToLoggedInUserSSO(user, idpIdList);
    }
  }

  /**
   * Function to determine whether user is deletable
   *
   * @param user User data for each row in the user table
   * @param role Role assigned to user
   * @param userType User Type Object
   * @param idpIdList List of IDP IDs belonging to the logged-in user
   * @returns true if user is deletable, false otherwise
   */
  isUserDeletable(user: any, role: Role, userType: UserType, idpIdList?: number[]): boolean {
    // To delete a Data Security user the consent for the user is needed before moving
    // ahead with deletion.  Or the current user is the support user.
    if (this.featureFlags.featureFlags.dsoContentNeeded &&
      role?.name === 'COHESITY_DATA_SECURITY' && !user.allowDsoModify &&
      !this.user.salesforceAccount?.isSupportUser) {
      return false;
    }

      const hasModifyPrivilege = this.privs.PRINCIPAL_MODIFY || this.privs.MCM_MODIFY_SUPER_ADMIN;
      const isUserTypeSameAsLoggedInUser = this.isUserTypeSameAsLoggedInUser(userType);
      const canCurrentUserModifyRole = this.canCurrentUserModifyRole(role);
      const isDifferentSid = this.user.sid !== user.sid;

      if (!hasModifyPrivilege) {
        return false;
      }

      if (isUserTypeSameAsLoggedInUser) {
        return canCurrentUserModifyRole && isDifferentSid;
      } else {
        return canCurrentUserModifyRole && this.isUserBelongsToLoggedInUserSSO(user, idpIdList);
      }
  }

  /**
   * Logs stop impersonation.
   *
   * @param    id  Currently impersonated Salesforce account ID.
   * @returns  Observable of impersonation action.
   */
  logStopImpersonation(id): Observable<ImpersonationAction | null> {
    if (id) {
      return this.heliosAccountsService.PerformImpersonationAction({
        sfAccountId: id,
        action: 'StopImpersonation',
      }).pipe(
        catchError(() => of(null))
      );
    }
    return of(null);
  }

  /**
   * Logs start impersonation.
   *
   * @param    id  Newly impersonated Salesforce account ID.
   * @returns  Observable of impersonation action.
   */
  logStartImpersonation(id): Observable<ImpersonationAction> {
    return this.heliosAccountsService.PerformImpersonationAction({
      sfAccountId: id,
      action: 'StartImpersonation',
    }).pipe(
      catchError(() => of(null))
    );
  }

  /**
   * This method gets the default bifrost connection id for the logged in
   * user.
   *
   * @returns default bifrost connection id if the user is
   * bifrost enabled tenant user.
   */
  getDefaultBifrostConnectionId(): number {
    return this.ajsUserService.getDefaultBifrostConnectionId();
  }

  /**
   * This method removes references to cluster ID from login data
   * in UserService and localstorage
   *
   * @param unclaimedClusterIds List of cluster IDs to be removed
   */
  removeUnclaimedClusters(unclaimedClusterIds: number[]): void {
    const clusterIdentifiers = this.user.clusterIdentifiers.filter(
      clusterIdentifier => !unclaimedClusterIds.includes(clusterIdentifier.clusterId)
    );
    return this.updateClusterIdentifiers(clusterIdentifiers);
  }

  /**
   * Update cluster identifiers in login data.
   *
   * @param clusterIdentifiers updated clusterIdentifiers.
   */
  updateClusterIdentifiers(clusterIdentifiers: ClusterIdentifier[]) {
    this.userStoreService.updateLogindataUser({ ...this.user, clusterIdentifiers });
  }
}
