<h1 mat-dialog-title>{{ 'dg.td.addYaraRule' | translate }}</h1>
<mat-dialog-content>
  <div>
    <!-- hidden native input -->
    <label>
      <input
        class="yara-input"
        (click)="yaraFileRef.value = null"
        (change)="fileInputChanged($event)"
        #yaraFileRef
        type="file"
        cogDataId="yara-file-input"
        [accept]="supportedExtensions"
        required />
    </label>

    <!-- Select button -->
    <button
      mat-stroked-button
      cogDataId="yara-rule-upload"
      class="upload-btn margin-sm"
      type="button"
      color="primary"
      *ngIf="!selectedFile"
      (click)="yaraFileRef.click()">
      <cog-icon shape="helix:upload" size="md"></cog-icon>
      {{ 'dg.td.uploadYaraFiles' | translate }}
    </button>
  </div>

  <div class="margin-md flex-row-md vertical-align flex-justify-space-between" [class.hidden]="!selectedFile">
    <div class="flex-row-xs vertical-align">
      <cog-icon shape="helix:insert_drive_file!outline" size="md"></cog-icon>
      <span class="user-data-lg auto-truncate" [matTooltip]="selectedFile?.name">{{ selectedFile?.name }}</span>
    </div>
    <mat-form-field>
      <mat-label>{{ 'ruleName' | translate }}</mat-label>
      <input matInput type="text" [formControl]="threatNameControl" />
    </mat-form-field>
    <button mat-icon-button (click)="clearSelectedFile()">
      <cog-icon shape="helix:delete" size="md"></cog-icon>
    </button>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close cdkfocusinitial cogDataId="yara-upload-cancel">
    {{ 'cancel' | translate }}
  </button>
  <button
    mat-flat-button
    cogDataId="yara-validate-and-submit"
    cogSubmitButton
    color="primary"
    [loading]="isLoading"
    [disabled]="!canSubmit"
    (click)="validateAndSubmit()">
    {{ 'dg.td.validateAndSave' | translate }}
  </button>
</mat-dialog-actions>
