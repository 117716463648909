import { Pipe, PipeTransform } from '@angular/core';
import { ScheduleType } from '@cohesity/api/argus';
import { TranslateService } from '@ngx-translate/core';

/**
 * This pipe returns translated scan type label.
 */
@Pipe({
  name: 'scheduleScanType',
  standalone: true,
})
export class ScheduleScanTypePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  /**
   * Return the translated schedule scan type.
   *
   * @param scheduleType Schedule scan type.
   *
   * @returns The translated schedule type.
   */
  transform(scheduleType: ScheduleType): string {
    return this.translateService.instant(`dg.td.schedule.type.${scheduleType}`);
  }
}
