import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import {
  ApplyPoliciesRequest,
  BulkCreateDataPoolRequest,
  BulkUpdateDataPoolRequest,
  BulkUpdateShieldsRequest,
  DataPool,
  DataPoolsApiService,
  DlpApiService,
  ShieldsApiService,
} from '@cohesity/api/argus';
import { AjaxHandlerService, AutoDestroyable } from '@cohesity/utils';
import { combineLatest, of } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { splitByNewView } from '../data-pool-list';
import { DataPoolUpdateDialogInput } from './data-pool-update-dialog.model';

/**
 * Update Data Pool Dialog.
 */
@Component({
  selector: 'dg-dc-data-pool-update-dialog',
  templateUrl: './data-pool-update-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataPoolUpdateDialogComponent extends AutoDestroyable {
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: DataPoolUpdateDialogInput,
    private ajaxHandlerService: AjaxHandlerService,
    private cdr: ChangeDetectorRef,
    private dataPoolApiService: DataPoolsApiService,
    private dialogRef: MatDialogRef<DataPoolUpdateDialogComponent>,
    private dlpApiService: DlpApiService,
    private shieldsApiService: ShieldsApiService,
  ) {
    super();
  }

  /** The actions to perform on the provided data pools */
  get action() {
    return this.data.action;
  }

  /** getter function for private property data.dataPools */
  get dataPools(): DataPool[] {
    return this.data.dataPools || [];
  }

  /**
   * Indicates whether we are submitting the data or not.
   */
  isSubmitting = false;

  /**
   * Update data pools.
   */
  updateDataPools() {
    switch (this.action) {
      case 'govern':
      case 'notGovern':
        this.toggleGovernance();
        break;
    }
  }

  /**
   * Toggle governance by enabling or enabling or disabling data pools.
   */
  toggleGovernance() {
    const targetEnableValue = this.action === 'govern';
    const updatedList = this.dataPools.map(dataPool => ({ ...dataPool, enabled: targetEnableValue }));
    const { dataPools, newDataPools } = splitByNewView(updatedList);

    // parameters to be sent for api call
    const updateBody: BulkUpdateDataPoolRequest = { dataPools };
    const createBody: BulkCreateDataPoolRequest = { dataPoolParamsList: newDataPools };

    // making the api calls to update/create dataPools
    this.isSubmitting = true;
    this.cdr.detectChanges();
    combineLatest([
      // in case there are no new dataPools do not make create call
      newDataPools.length ? this.dataPoolApiService.bulkCreateDataPools({ body: createBody }) : of(null),

      // in case there are no existing dataPools do not make update call
      dataPools.length ? this.dataPoolApiService.bulkUpdateDataPools({ body: updateBody }) : of(null),
    ]).pipe(
      this.untilDestroy(),
      finalize(() => {
        this.isSubmitting = false;
        this.cdr.detectChanges();
      }),
    ).subscribe(
      () => this.dialogRef.close(true),
      this.ajaxHandlerService.handler,
    );
  }

  /**
   * Update Shield's data pool association.
   */
  toggleGovernanceForShield() {
    const modifiedDataPoolIds = this.dataPools.map(({ id }) => id);
    const body: BulkUpdateShieldsRequest = {
      shields: this.data.shields.map(shield => {
        const dataPoolIdSet = new Set(shield.dataPoolIds);

        modifiedDataPoolIds.forEach(dataPoolId => {
          switch (this.action) {
            case 'govern':
              dataPoolIdSet.add(dataPoolId);
              break;
            case 'notGovern':
              dataPoolIdSet.delete(dataPoolId);
              break;
          }
        });

        return { ...shield, dataPoolIds: [...dataPoolIdSet] };
      }),
    };

    this.isSubmitting = true;
    this.cdr.detectChanges();
    this.shieldsApiService.bulkUpdateShields({ body }).pipe(
      this.untilDestroy(),
      finalize(() => {
        this.isSubmitting = false;
        this.cdr.detectChanges();
      }),
    ).subscribe(
      () => this.dialogRef.close(true),
      this.ajaxHandlerService.handler,
    );
  }

  /**
   * Update Policies data pool association.
   */
  toggleClassification() {
    const modifiedDataPoolIds = this.dataPools.map(({ id }) => id);
    const body: ApplyPoliciesRequest = {
      policies: this.data.policies.map(policy => {
        const dataPoolIdSet = new Set(policy.dataPoolIds);

        modifiedDataPoolIds.forEach(dataPoolId => {
          switch (this.action) {
            case 'classify':
              dataPoolIdSet.add(dataPoolId);
              break;
            case 'notClassify':
              dataPoolIdSet.delete(dataPoolId);
              break;
          }
        });

        return { ...policy, dataPoolIds: [...dataPoolIdSet] };
      }),
    };

    this.isSubmitting = true;
    this.cdr.detectChanges();
    this.dlpApiService.applyPolicies({ body }).pipe(
      this.untilDestroy(),
      finalize(() => {
        this.isSubmitting = false;
        this.cdr.detectChanges();
      }),
    ).subscribe(
      () => this.dialogRef.close(true),
      this.ajaxHandlerService.handler,
    );
  }
}
