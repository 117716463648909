<h2 mat-dialog-title>
  {{ 'updateDataPools' | translate }}
</h2>

<mat-dialog-content class="flex-column margin-top">
  <cog-banner status="info" class="margin-top">
    <p>
      {{ 'argus.dataPool.updateDataPoolsConfirm' | translate: {
        updateMessage: (('argus.dataPool.' + action) | translate)
      } }}
    </p>

    <ul>
      <li class="flex-row-sm" *ngFor="let dataPool of dataPools; index as i">
        <span>{{ i + 1 }}.</span>
        <strong>{{ dataPool.name }}</strong>
      </li>
    </ul>

  </cog-banner>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button
    mat-button
    mat-dialog-close
    cogCancelButton
    [disabled]="isSubmitting">
    {{'cancel' | translate }}
  </button>
  <button mat-flat-button
    cogDataId="update-data-pools-btn"
    cdkFocusInitial
    cogSubmitButton
    [disabled]="isSubmitting"
    [loading]="isSubmitting"
    (click)="updateDataPools()">
    {{ 'confirm' | translate }}
  </button>
</mat-dialog-actions>
