import { getConfigByKey } from '@cohesity/iris-core';
import { AppModuleConfig, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';

// Omitting feature flag check from access check as state guards will make
// feature flag based determination.
const stateAccessFn = (ctx: StateAccessContext) => ctx.PROTECTION_VIEW && getConfigByKey(ctx.irisContext, 'protection-groups.features.allow', true);
const cdpStateAccessFn = (ctx: StateAccessContext) => ctx.FEATURE_FLAGS.cdpEnabled && ctx.PROTECTION_VIEW;

export const ProtectionGroupConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'protection-group.**',
      url: '/protection/group/',
      loadChildren: () => import('./protection-group.module').then(m => m.ProtectionGroupModule),
    }
  ],
  allClusterMap: {
    'protection-group.group.audit': false,
    'protection-group.group.cdp': false,
    'protection-group.group.cdpObject': false,
    'protection-group.group.consumption': false,
    'protection-group.group.details': {
      singleClusterState: 'protection-group.group.details',
      allClustersState: 'protection-group.group.details',
      globalContext: true,
    },
    'protection-group.group.settings': true,
    'protection-group.group.trend': false,
    'protection-group.group': true,
    'protection-group.groups': true,
    'protection-group.run.archive': false,
    'protection-group.run.backup': true,
    'protection-group.run.cloudspin': false,
    'protection-group.run.cloudvault': false,
    'protection-group.run.vaultCluster': false,
    'protection-group.run.direct-archive': true,
    'protection-group.run.indexing': false,
    'protection-group.run.replication': false,
    'protection-group.run.tape': false,
    'protection-group.run': true,
  },
  getStateAccessMap(): StateAccessMap {
    return {
      'protection-group.group.audit': stateAccessFn,
      'protection-group.group.cdp': cdpStateAccessFn,
      'protection-group.group.consumption': stateAccessFn,
      'protection-group.group.details': stateAccessFn,
      'protection-group.group.settings': stateAccessFn,
      'protection-group.group.trend': stateAccessFn,
      'protection-group.group': stateAccessFn,
      'protection-group.groups': stateAccessFn,
      'protection-group.run.archive': stateAccessFn,
      'protection-group.run.backup': stateAccessFn,
      'protection-group.run.cloudspin': stateAccessFn,
      'protection-group.run.cloudvault': stateAccessFn,
      'protection-group.run.vaultCluster': stateAccessFn,
      'protection-group.run.direct-archive': stateAccessFn,
      'protection-group.run.indexing': stateAccessFn,
      'protection-group.run.replication': stateAccessFn,
      'protection-group.run.tape': stateAccessFn,
      'protection-group.run': stateAccessFn,
      'protection-group': stateAccessFn,
    };
  },
};
