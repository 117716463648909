import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { PatternId, Policy, ResourceType } from '@cohesity/api/argus';
import { Controls, NgxSubFormComponent, subformComponentProviders, takeUntilDestroyed } from 'ngx-sub-form';
import { tap } from 'rxjs/operators';

/**
 * The policy form interface.
 */
export interface PolicyForm {
  /** The policy name */
  name: string;

  /** The selected policy patterns */
  patternIds: PatternId[];

  /** The updated policy info */
  policy: Policy;
}

/**
 * Data classification policy modify component.
 */
@Component({
  selector: 'dg-dc-policy-modify',
  templateUrl: './policy-modify.component.html',
  styleUrls: ['./policy-modify.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: subformComponentProviders(PolicyModifyComponent),
})
export class PolicyModifyComponent extends NgxSubFormComponent<Policy, PolicyForm> implements OnChanges {
  /**
   * Form will be validated when value is true
   */
  @Input() isTouched = false;

  constructor(
    private cdr: ChangeDetectorRef,
  ) {
    super();
    /**
     * Below is the temporary fix to overcome the shortcomings of NgxSubFormComponent.
     * It should be removed and permanent fixed should be done in the NgxSubFormComponent.
     * More on issue could be found on the following link: https://github.com/cloudnc/ngx-sub-form/issues/93
     */
    this.formGroup.valueChanges.pipe(takeUntilDestroyed(this), tap(() => this.cdr.detectChanges())).subscribe();
  }

  /**
   * Check for the validation of the form
   */
  ngOnChanges(changes: SimpleChanges) {
    const { isTouched } = changes;

    if (isTouched && isTouched?.currentValue && isTouched?.currentValue !== isTouched?.previousValue) {
      this.formGroup.markAllAsTouched();
      this.cdr.detectChanges();
    }
  }

  /**
   * Return the policy default form value.
   *
   * @returns The policy form value.
   */
  getDefaultValues(): PolicyForm {
    const policy: Policy = {
      name: '',
      patternIds: [],
      buildType: ResourceType.custom,
    };

    return {
      policy,
      name: policy.name,
      patternIds: policy.patternIds,
    };
  }

  /**
   * Get the form controls.
   *
   * @returns The policy form controls.
   */
  getFormControls(): Controls<PolicyForm> {
    return {
      name: new UntypedFormControl('', [Validators.required]),
      patternIds: new UntypedFormControl([], [Validators.required]),
      policy: new UntypedFormControl(null),
    };
  }

  /**
   * Transforms api model to form group model.
   *
   * @param policy The policy value.
   * @param defaultValues The policy default value.
   * @returns The policy form value.
   */
  transformToFormGroup(policy: Policy, defaultValues: PolicyForm): PolicyForm {
    const newPolicy = { ...defaultValues.policy, ...policy };

    if (newPolicy.buildType === ResourceType.builtIn) {
      this.formGroupControls.name.disable();
    }

    return {
      policy: newPolicy,
      name: newPolicy.name,
      patternIds: newPolicy.patternIds,
    };
  }

  /**
   * Transform form group model to api model.
   *
   * @param formValue The policy form value.
   * @returns The policy value.
   */
  transformFromFormGroup(formValue: PolicyForm): Policy {
    const { policy, name, patternIds } = formValue || {};

    return { ...policy, name: name?.trim(), patternIds };
  }

}
