/**
 * A list of QoS policy names for Backup Target use cases.
 */
export const backupTargetQoSPolicyNames = Object.freeze([
  'BackupTargetHigh',
  'BackupTargetLow',
  'BackupTargetSSD',
  'BackupTargetCommvault',
  'BackupTargetAuto'
]);
