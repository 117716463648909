/**
 * Enum of type of objects in kubernetes source.
 */
 export enum KubernetesProtectionTypes {
  kCluster = 'kCluster',
  kNamespace = 'kNamespace',
  kPersistentVolumeClaim = 'kPersistentVolumeClaim',
  kLabel = 'kLabel',
}

/**
 * Enum for exclude include pvcs
 */
export const enum KubernetesPvcFilterType {
  Include = 'include',
  Exclude = 'exclude',
}

// The type of sections in each rule.
export enum TypeSectionRule {
  labels = 'labels',
  preScript = 'preScript',
  postScript = 'postScript',
};

// Id of pvc related resource
export const PvcResourceId = 'pvcResources';

// Default list of resources to be included/excluded
export const ResourceList = [
  { id: 'daemonSet', name: 'Daemon set' },
  { id: 'deployment', name: 'Deployment' },
  { id: 'pvcResources', name: 'PVC related resources' },
  { id: 'pod', name: 'Pod' },
  { id: 'replicaSet', name: 'Replica set' },
  { id: 'secret', name: 'Secret' },
  { id: 'service', name: 'Service' },
  { id: 'statefulSet', name: 'Stateful set' },
];
