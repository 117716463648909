import { Injectable } from '@angular/core';
import { NavItem } from '@cohesity/helix';
import {
  AppType,
  flagEnabled,
  hasCompanyContext,
  IrisContext,
  isMcmOnPrem,
  isTenantUser,
  PillarType
} from '@cohesity/iris-core';
import { TranslateService } from '@ngx-translate/core';
import { Routes } from 'src/app/shared/constants';

import { ServiceNavProvider } from '../service-nav-provider';

/**
 * This Service configures the global view navigation menu with iris.
 */
@Injectable({ providedIn: 'root' })
export class GlobalNavService implements ServiceNavProvider {
  pillar: PillarType = 'global';

  app: AppType = 'global';

  get serviceNavTitle() {
    return this.translate.instant('global');
  }

  serviceNavTitleIcon = 'helix:logo-global';

  serviceNavTitleClass = 'global';

  showNavBranding = true;

  getNavItems(irisContext: IrisContext): NavItem[] {
    return [
      flagEnabled(irisContext, 'servicesLandingPage') ? {
        displayName: 'home',
        state: 'servicesListing.list',
        icon: 'home!outline',
      } : {
        displayName: 'dashboard',
        state: 'dashboards.global',
        icon: 'dashboard!outline',
      },
      {
        displayName: 'marketplace',
        state: 'apps-management',
        icon: 'storefront!outline',
        subNavList: [
          {
            displayName: 'myApps',
            state: 'apps-management',
          },
          isTenantUser(irisContext) || isMcmOnPrem(irisContext) ? null : {
            displayName: 'allApps',
            state: undefined,
            disabled: !hasCompanyContext(irisContext),
            href: Routes.marketplace
          },
        ],
      },
      {
        displayName: 'system',
        state: 'cluster',
        icon: 'helix:system',
        subNavList: [
          {
            displayName: 'health',
            state: 'health',
          },
        ],
      },
      flagEnabled(irisContext, 'ngReportMcm') && {
        displayName: 'reports',
        state: 'reporting.list',
        icon: 'assessment!outline',
        activeStates: ['reporting.detail', 'reporting.schedule'],
      },
      flagEnabled(irisContext, 'globalLicenseDashboardEnabled') && {
        displayName: 'licenseConsumption',
        state: 'license.global-license-dashboard',
        icon: 'assessment!outline',
      },
      {
        displayName: 'settings',
        state: 'cluster-upgrades',
        icon: 'settings!outline',
        subNavList: [
          {
            displayName: 'accessManagement',
            state: 'helios-access-management',
          },
          {
            displayName: 'cloudEdition.dataPlatformForCloud',
            state: 'cloud-edition-manager',
          },
          {
            displayName: 'heliosBackup',
            state: 'helios-backup',
          },
        ],
      },
    ];
  }

  constructor(private translate: TranslateService) {}
}
