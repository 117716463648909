import { AggregatedSubtreeInfo, ProtectionSourceNode } from '@cohesity/api/v1';
import { DataTreeSelection } from '@cohesity/helix';
import { Environment, HostType } from 'src/app/shared/constants';

import { PhysicalSourceDataNode } from '../physical-source-data-node';
import { TooltipContext } from '../../shared/protection-source-data-node';

/**
 * Represents an Physical block source node and job tree selection behavior.
 */
export class PhysicalBlockSourceDataNode extends PhysicalSourceDataNode {

  /**
   * This indicates whether the node is part of the job currently being edited or viewed.
   */
  inCurrentJob = false;

  /**
   * Disable host checks for selection
   */
  disableHostCheck = false;

  constructor(data: ProtectionSourceNode, readonly level: number) {
    super(Environment.kPhysical, data, level);
  }

  /**
   * Returns if node is selectable.
   */
  get isSelectable(): boolean {
    return [
      HostType.kLinux,
      HostType.kWindows,
      HostType.kSolaris,
      HostType.kHPUX,
      HostType.kVOS,
    ].includes(HostType[this.nodeHostType]);
  }

  /**
   * Returns volumes of a node.
   */
  get nodeVolumes(): any {
    return ['kHost', 'kWindowsCluster'].includes(this.type) ? this.envSource.volumes : [];
  }

  /**
   * Returns true if node also protects oracle databases.
   */
  get isOracleHost(): boolean {
    const registrationInfo = this.data.registrationInfo || { };
    return (registrationInfo.environments || []).includes(Environment.kOracle);
  }

  /**
   * Gets the block-based protection summary for the tree's environment.
   */
  get protectedBlockBasedSummary(): AggregatedSubtreeInfo {
    return (this.data.protectedSourcesSummary || [])
      .find(summary => summary.environment === Environment.kPhysical) || {};
  }

  /**
   * Gets the block-based unprotection summary for the tree's environment.
   */
  get unprotectedBlockBasedSummary(): AggregatedSubtreeInfo {
    return (this.data.unprotectedSourcesSummary || [])
      .find(summary => summary.environment === Environment.kPhysical) || {};
  }

  /**
   * Gets a count of protected block-based objects for block-based environment.
   */
  get protectedBlockBasedCount(): number {
    return this.protectedBlockBasedSummary.leavesCount || 0;
  }

  /**
   * Gets a count of unprotected block-based objects for block-based environment.
   */
  get unprotectedBlockBasedCount(): number {
    return this.unprotectedBlockBasedSummary.leavesCount || 0;
  }

  /**
   * True if all block-based objects are protected or the object has an object protection spec applied to it.
   */
  get protectedBlockBased(): boolean {
    return (!!this.protectedBlockBasedCount && !this.unprotectedBlockBasedCount) || this.isObjectProtected;
  }

  /**
   * Variable to keep tract of host type of selected nodes.
   */
  selectedHostType: string = null;


  /**
   * Checks if the node can be selected. If the host type of the node matches
   * with the host type in the selected nodes, then the node can be selected.
   *
   * @param    currentSelection   Current Selection
   * @return   Returns true if node can be selected, false otherwise
   */
  canSelect(currentSelection: DataTreeSelection<any>): boolean {
    if (this.type === 'kGroup') {
      return false;
    } else if (this.protectedBlockBased && !this.inCurrentJob) {
      return false;
    } else if ((this.envSource?.agents || []).map(agent => agent.agentType).includes('kJava')){
      return false;
    }

    this.updateSelectedHostType(currentSelection);

    if (this.selectedHostType && !this.disableHostCheck) {
      return this.selectedHostType === this.nodeHostType;
    }

    return true;
  }

  /**
   * Return tooltip TranslationContext for node.
   */
  getCheckboxTooltip() {
    if (this.selectedHostType) {
      if (this.selectedHostType !== this.nodeHostType) {
        return { translateKey: 'cSourceTree.differentHostType' };
      }
    } else if (this.type === 'kGroup') {
      return null;
    } else if (this.protected) {
      return { translateKey: 'cSourceTreeNode.messages.alreadyProtected' };
    } else if (this.isOracleHost) {
      return { translateKey: 'cSourceTree.tooltips.oracleOnlyDatabases' };
    }
  }

  /**
   * Updates the selected host type based on current selection
   *
   * @param currentSelection current selection
   */
  updateSelectedHostType(currentSelection: DataTreeSelection<any>) {
    if (currentSelection.selected[0]) {
      this.selectedHostType = currentSelection.selected[0].nodeHostType;
    } else {
      this.selectedHostType = null;
    }
  }

  /**
   * Return tooltip TranslationContext for node.
   */
  getCheckBoxToolTip(): TooltipContext {
    if ((this.envSource?.agents || []).map(a => a.agentType).includes('kJava')){
      return { translateKey: 'cSourceTree.tooltips.blockbasedNotForJavaAgent' };
    }
  }
}
