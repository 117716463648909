import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatSortModule } from '@angular/material/sort';
import { DataIdDirective } from '@cohesity/helix';
import { TranslateModule } from '@ngx-translate/core';

import { IrisRouterModule } from '../routing';
import { TenantColumnComponent } from './tenant-column.component';

@NgModule({
  imports: [
    DataIdDirective,
    CommonModule,
    IrisRouterModule,
    MatMenuModule,
    MatSortModule,
    MatTableModule,
    TranslateModule.forChild(),
  ],
  exports: [TenantColumnComponent],
  declarations: [TenantColumnComponent],
})
export class TenantColumnModule {}
