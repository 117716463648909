/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityApiConfiguration as __Configuration } from '../cohesity-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { SmbActiveFileOpensResponse } from '../models/smb-active-file-opens-response';
import { CloseSmbFileOpenParameters } from '../models/close-smb-file-open-parameters';
@Injectable({
  providedIn: 'root',
})
class SMBFileOpensService extends __BaseService {
  static readonly GetSmbFileOpensPath = '/public/smbFileOpens';
  static readonly CloseSmbFileOpenPath = '/public/smbFileOpens';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * List the active SMB file opens that match the filter criteria specified using
   * parameters.
   *
   * If no parameters are specified, all active SMB file opens currently on the
   * Cohesity Cluster are returned. Specifying parameters filters the results that
   * are returned.
   * @param params The `SMBFileOpensService.GetSmbFileOpensParams` containing the following parameters:
   *
   * - `viewName`: Specifies the name of the View in which to search. If a view name is not
   *   specified, all the views in the Cluster is searched.
   *   This field is mandatory if filePath field is specified.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `pageCount`: Specifies the maximum number of active opens to return in the response.
   *   This field cannot be set above 1000. If this is not set, maximum of 1000
   *   entries are returned.
   *
   * - `filePath`: Specifies the filepath in the view relative to the root filesystem.
   *   If this field is specified, viewName field must also be specified.
   *
   * - `cookie`: Specifies the opaque string returned in the previous response.
   *   If this is set, next set of active opens just after the previous
   *   response are returned. If this is not set, first set of active opens
   *   are returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetSmbFileOpensResponse(params: SMBFileOpensService.GetSmbFileOpensParams): __Observable<__StrictHttpResponse<SmbActiveFileOpensResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.viewName != null) __params = __params.set('viewName', params.viewName.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.pageCount != null) __params = __params.set('pageCount', params.pageCount.toString());
    if (params.filePath != null) __params = __params.set('filePath', params.filePath.toString());
    if (params.cookie != null) __params = __params.set('cookie', params.cookie.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/smbFileOpens`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SmbActiveFileOpensResponse>;
      })
    );
  }
  /**
   * List the active SMB file opens that match the filter criteria specified using
   * parameters.
   *
   * If no parameters are specified, all active SMB file opens currently on the
   * Cohesity Cluster are returned. Specifying parameters filters the results that
   * are returned.
   * @param params The `SMBFileOpensService.GetSmbFileOpensParams` containing the following parameters:
   *
   * - `viewName`: Specifies the name of the View in which to search. If a view name is not
   *   specified, all the views in the Cluster is searched.
   *   This field is mandatory if filePath field is specified.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `pageCount`: Specifies the maximum number of active opens to return in the response.
   *   This field cannot be set above 1000. If this is not set, maximum of 1000
   *   entries are returned.
   *
   * - `filePath`: Specifies the filepath in the view relative to the root filesystem.
   *   If this field is specified, viewName field must also be specified.
   *
   * - `cookie`: Specifies the opaque string returned in the previous response.
   *   If this is set, next set of active opens just after the previous
   *   response are returned. If this is not set, first set of active opens
   *   are returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetSmbFileOpens(params: SMBFileOpensService.GetSmbFileOpensParams): __Observable<SmbActiveFileOpensResponse> {
    return this.GetSmbFileOpensResponse(params).pipe(
      __map(_r => _r.body as SmbActiveFileOpensResponse)
    );
  }

  /**
   * Closes an active SMB file open.
   *
   * Returns nothing upon success.
   * @param params The `SMBFileOpensService.CloseSmbFileOpenParams` containing the following parameters:
   *
   * - `body`: Request to close an active SMB file open.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  CloseSmbFileOpenResponse(params: SMBFileOpensService.CloseSmbFileOpenParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/smbFileOpens`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Closes an active SMB file open.
   *
   * Returns nothing upon success.
   * @param params The `SMBFileOpensService.CloseSmbFileOpenParams` containing the following parameters:
   *
   * - `body`: Request to close an active SMB file open.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  CloseSmbFileOpen(params: SMBFileOpensService.CloseSmbFileOpenParams): __Observable<null> {
    return this.CloseSmbFileOpenResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module SMBFileOpensService {

  /**
   * Parameters for GetSmbFileOpens
   */
  export interface GetSmbFileOpensParams {

    /**
     * Specifies the name of the View in which to search. If a view name is not
     * specified, all the views in the Cluster is searched.
     * This field is mandatory if filePath field is specified.
     */
    viewName?: string;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Specifies the maximum number of active opens to return in the response.
     * This field cannot be set above 1000. If this is not set, maximum of 1000
     * entries are returned.
     */
    pageCount?: number;

    /**
     * Specifies the filepath in the view relative to the root filesystem.
     * If this field is specified, viewName field must also be specified.
     */
    filePath?: string;

    /**
     * Specifies the opaque string returned in the previous response.
     * If this is set, next set of active opens just after the previous
     * response are returned. If this is not set, first set of active opens
     * are returned.
     */
    cookie?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CloseSmbFileOpen
   */
  export interface CloseSmbFileOpenParams {

    /**
     * Request to close an active SMB file open.
     */
    body: CloseSmbFileOpenParameters;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { SMBFileOpensService }
