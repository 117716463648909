<cog-filters [showFilterLabel]="true" (valueChange)="applyFilters($event)">
  <cog-search-property-filter
    *cogFilterDef="let filter;
      key: 'name';
      label: 'name' | translate;
      quickFilter: true;
      filterType: 'searchProperty'"
    [filter]="filter"
    [placeholder]="'dg.td.scan.threatName' | translate">
  </cog-search-property-filter>
</cog-filters>

<cog-table
  class="margin-top"
  cogDataId="threat-list"
  name="dg.th.customYaraRules"
  [selection]="enableSelection ? threatSection : null"
  [persistSelection]="true"
  [source]="threats"
  >
  <table matSort mat-table>
    <!-- table footer with loader spinner -->
    <tr mat-footer-row class="loading-row" [class.hidden]="!isLoading" *matFooterRowDef="['loading']"></tr>
    <ng-container matColumnDef="loading">
      <td colspan="50" mat-footer-cell *matFooterCellDef>
        <cog-spinner></cog-spinner>
      </td>
    </ng-container>

     <!-- table footer with no data found -->
     <tr mat-footer-row [class.hidden]="isLoading || threats.length > 0" *matFooterRowDef="['noData']"></tr>
     <ng-container matColumnDef="noData">
       <td colspan="50" mat-footer-cell *matFooterCellDef>
         <cog-blank-card message="{{ 'noDataFound' | translate }}"></cog-blank-card>
       </td>
     </ng-container>

    <!-- Threat Name -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'dg.td.scan.threatName' | translate }}
      </th>
      <td mat-cell class="cog-table-object-name" *matCellDef="let threat">
        <div class="flex-row-xs vertical-align">
            <span>{{ threat.name }}</span>
            <span *ngIf="threat.isDeleted"
              class="threat-deleted-tag"
              cogDataId="deleted"
              [inheritDataId]="true">
              {{ 'deleted' | translate }}
            </span>
        </div>
      </td>
    </ng-container>

    <!-- Threat Created date time -->
    <ng-container matColumnDef="createdAt">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'dg.td.creationDate' | translate }}
      </th>
      <td mat-cell *matCellDef="let threat">
        <span>
          {{ threat.createdAt / 1000 | cogDate | naLabel }}
        </span>
      </td>
    </ng-container>

    <!-- Definition for actions column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let threat;" class="show-on-hover-cell">
        <button mat-icon-button
          [matMenuTriggerFor]="menu">
          <cog-icon shape="more_vert"></cog-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item
            (click)="deleteThreat(threat)">
            {{ 'delete' | translate }}
          </button>
        </mat-menu>
      </td>
    </ng-container>

    <!-- Header and row definition -->
    <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
    <tr mat-row *matRowDef="let threat; columns: displayColumns" [class.hidden]="isLoading"></tr>
  </table>
  <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]"></mat-paginator>
</cog-table>
