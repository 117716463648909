<ng-template cohFormSectionEditMode>
  <cog-settings-list [title]="'dg.dc.scan.moreOptions' | translate" [settingsVisible]="true">
    <!-- option: Scan Start Time -->
    <cog-settings-list-item
      *ngIf="isScheduleScanEnabled && showScanStartTime"
      [label]="'dg.dc.scan.startTime' | translate"
      [invalid]="startTimeCtrl.invalid"
      [resetToDefault]="'reset' | translate"
      [valueChanged]="startTimeChanged"
      (resetAction)="resetStartTime()"
      cogDataId="scan-start-time">
      <cog-settings-list-item-value-display>
        <div class="flex-row-xs vertical-align">
          <span>{{ startTime }}</span>
          <span>•</span>
          <span>{{ startTimeZoneCtrl.value }}</span>
        </div>
      </cog-settings-list-item-value-display>
      <cog-settings-list-item-value-editor class="flex-row-md vertical-align">
        <coh-time-picker
          [formControl]="startTimeCtrl"
          [label]="'dg.dc.scan.startTime' | translate"
          cogDataId="start-time-selector">
        </coh-time-picker>
        <mat-form-field>
          <mat-label>{{ 'timeZone' | translate }}</mat-label>
          <mat-select [formControl]="startTimeZoneCtrl">
            <input type="text" matInput (ngModelChange)="filterTimeZones($event)" />
            <mat-option *ngFor="let zone of filteredTimeZones" [value]="zone">
              {{ zone }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </cog-settings-list-item-value-editor>
    </cog-settings-list-item>

    <!-- option: Scan Name -->
    <cog-settings-list-item
      [label]="'scanName' | translate"
      [invalid]="scanNameCtrl.hasError('required')"
      cogDataId="scan-name"
      [inheritDataId]="true">
      <cog-settings-list-item-value-display>
        {{ scanNameCtrl.value | naLabel }}
      </cog-settings-list-item-value-display>
      <cog-settings-list-item-value-editor class="flex-row">
        <mat-form-field class="flex-grow">
          <mat-label>{{ 'name' | translate }}</mat-label>
          <input matInput type="text" [formControl]="scanNameCtrl" cogDataId="input" [inheritDataId]="true" />
          <mat-error cogDataId="scan-name-required" *ngIf="scanNameCtrl.hasError('required')">
            {{ 'errors.required' | translate }}
          </mat-error>
        </mat-form-field>
      </cog-settings-list-item-value-editor>
    </cog-settings-list-item>

    <!-- option: enable OCR -->
    <cog-settings-list-item
      *ngIf="isOCREnabled"
      [label]="'dg.dc.enableOCR' | translate"
      [invalid]="enableOCRCtrl.hasError('required')"
      cogDataId="enable-ocr"
      [inheritDataId]="true">
      <cog-settings-list-item-value-display>
        {{ enableOCRCtrl.value | yesNo | translate }}
      </cog-settings-list-item-value-display>
      <cog-settings-list-item-value-editor class="flex-row-md vertical-align">
        <mat-slide-toggle cogDataId="enable-ocr-option" [formControl]="enableOCRCtrl">
          <div class="flex-column">
            <span>{{ enableOCRCtrl.value | yesNo | translate }}</span>
            <small>{{ 'dg.dc.enableOCR.helpText' | translate }}</small>
          </div>
        </mat-slide-toggle>
      </cog-settings-list-item-value-editor>
    </cog-settings-list-item>

    <!-- option: Scan Location -->
    <cog-settings-list-item
      *ngIf="isReplicaScansEnabled"
      [label]="'dg.scanLocation' | translate"
      cogDataId="scan-location"
      [inheritDataId]="true">
      <cog-settings-list-item-value-display>
        <mat-progress-bar mode="indeterminate" *ngIf="isClusterPreferenceLoading" class="margin-bottom">
        </mat-progress-bar>
        {{ 'dg.scanLocation.' + scan.scanPreference | translate }}
      </cog-settings-list-item-value-display>
      <cog-settings-list-item-value-editor>
        <mat-progress-bar mode="indeterminate" *ngIf="isClusterPreferenceLoading" class="margin-bottom">
        </mat-progress-bar>
        <mat-radio-group
          class="flex-column-sm"
          [value]="scan.scanPreference"
          (change)="selectScanLocation($event.value)">
          <mat-radio-button [value]="scanPreferences.scanOnLocal" cogDataId="local" [inheritDataId]="true">
            {{ 'dg.scanLocation.scanOnLocal' | translate }}
          </mat-radio-button>
          <div class="flex-column-xs">
            <span class="flex-row baseline-align">
              <mat-radio-button
                class="margin-bottom-xs"
                [value]="scanPreferences.clusterScanOrder"
                [disabled]="disableScanOnReplica || isClusterPreferenceLoading"
                cogDataId="scan-order"
                [inheritDataId]="true">
                {{ 'dg.scanLocation.clusterScanOrder' | translate }}
              </mat-radio-button>
              <a
                class="argus-card-anchor margin-left"
                cogDataId="view-scan-order"
                [inheritDataId]="true"
                (click)="openViewScanOrderDialog()">
                {{ 'dg.scanLocation.clusterScanOrder.viewScanOrder' | translate }}
              </a>
            </span>
            <small class="margin-left-lg">
              {{ 'dg.scanLocation.clusterScanOrder.helpText' | translate }}
            </small>
            <cog-banner class="margin-left-lg" status="warn" *ngIf="isClusterScanOrderOptionSelected">
              {{ 'dg.scanLocation.clusterScanOrder.warningMessage' | translate }}
            </cog-banner>
          </div>
        </mat-radio-group>
      </cog-settings-list-item-value-editor>
    </cog-settings-list-item>

    <!-- option: File Extension Inclusions and exclusions -->
    <cog-settings-list-item
      [label]="'dg.dc.scan.excludedExtensions' | translate"
      [isReadOnly]="true"
      cogDataId="scan-name"
      [inheritDataId]="true">
      <cog-settings-list-item-value-display>
        <div class="flex-row-xs vertical-align">
          <span>{{ 'dg.dc.scan.excludedExtensionSummary' | translate: { n: excludeFileExtension.length } }}</span>
          <span>•</span>
          <a (click)="viewExcludedExtensionSummary = !viewExcludedExtensionSummary">
            {{ (viewExcludedExtensionSummary ? 'hideDetails' : 'viewDetails') | translate }}
          </a>
        </div>

        <div class="margin-top" *ngIf="viewExcludedExtensionSummary">
          <mat-chip-list>
            <mat-chip *ngFor="let extension of excludeFileExtension">{{ extension }}</mat-chip>
          </mat-chip-list>
        </div>
      </cog-settings-list-item-value-display>
      <cog-settings-list-item-value-editor>
        <mat-chip-list>
          <mat-chip *ngFor="let extension of excludeFileExtension">{{ extension }}</mat-chip>
        </mat-chip-list>
      </cog-settings-list-item-value-editor>
    </cog-settings-list-item>

    <!-- option: Exclude File Paths -->
    <cog-settings-list-item
      [label]="'dg.dc.scan.excludedPaths' | translate"
      [isReadOnly]="true"
      cogDataId="scan-name"
      [inheritDataId]="true">
      <cog-settings-list-item-value-display>
        <div class="flex-row-xs vertical-align">
          <span>{{ 'dg.dc.scan.excludedPathsSummary' | translate: { n: excludeFilePaths.length } }}</span>
          <span>•</span>
          <a (click)="viewExcludedPathsSummary = !viewExcludedPathsSummary">
            {{ (viewExcludedPathsSummary ? 'hideDetails' : 'viewDetails') | translate }}
          </a>
        </div>

        <div class="margin-top" *ngIf="viewExcludedPathsSummary">
          <mat-chip-list>
            <mat-chip *ngFor="let filePath of excludeFilePaths">{{ filePath }}</mat-chip>
          </mat-chip-list>
        </div>
      </cog-settings-list-item-value-display>
      <cog-settings-list-item-value-editor>
        <mat-chip-list>
          <mat-chip *ngFor="let filePath of excludeFilePaths">{{ filePath }}</mat-chip>
        </mat-chip-list>
      </cog-settings-list-item-value-editor>
    </cog-settings-list-item>

    <!-- option: Skip files larger than -->
    <cog-settings-list-item
      [label]="'dg.td.scan.skipFilesGreaterThan' | translate"
      cogDataId="skip-files-greater-than"
      [inheritDataId]="true"
      [isReadOnly]="true">
      <cog-settings-list-item-value-display>
        {{ maxFileSizeBytes | byteSize }}
      </cog-settings-list-item-value-display>
    </cog-settings-list-item>
  </cog-settings-list>
</ng-template>
