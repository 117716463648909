import { UntypedFormControl } from '@angular/forms';
import {
  periodicityTypeOptions,
  PolicyScheduleUnit,
  TimePeriodOptions,
  TimePeriodSelectorForm,
  TimePeriodValue,
  TypedFormGroup,
} from 'src/app/shared';

/**
 * List of all possible periodicity type options.
 */
export const dataLockPeriodicityTypeOptions: TimePeriodOptions[] = [
  {
    value: PolicyScheduleUnit.Days,
    label: 'day',
    labelPlural: 'days',
  },
  {
    value: PolicyScheduleUnit.Weeks,
    label: 'week',
    hideValue: true,
  },
  {
    value: PolicyScheduleUnit.Months,
    label: 'month',
    hideValue: true,
  },
  {
    value: PolicyScheduleUnit.Years,
    label: 'year',
    labelPlural: 'years',
  },
];

export interface DataLockConfigValue {
  dataLockConfigEnabled: boolean;
  dataLockConfigDuration: TimePeriodValue;
}

/**
 * Default value of Schedule Selector.
 */
const defaultValue: DataLockConfigValue = {
  dataLockConfigEnabled: true,
  dataLockConfigDuration: { value: 14, type: PolicyScheduleUnit.Days },
};

export class DataLockConfigForm extends TypedFormGroup<DataLockConfigValue> {

  /**
   * Gets the dataLockConfigEnabled form control.
   *
   * @return   The dataLockConfigEnabled form control.
   */
  public get dataLockConfigEnabled(): UntypedFormControl {
    return this.get('dataLockConfigEnabled') as UntypedFormControl;
  }

  /**
   * Gets the dataLockConfigDuration form control.
   *
   * @return   The dataLockConfigDuration form control.
   */
  public get dataLockConfigDuration(): TimePeriodSelectorForm {
    return this.get('dataLockConfigDuration') as TimePeriodSelectorForm;
  }

  /**
   * Constructs new Schedule Selector FormGroup.
   *
   * @param  initialValue   Value to initialize the form to.
   * @param  typeOptions    Time period type options list.
   * @param  isDisabled     True if dataLock should be disabled by default.
   */
  constructor(
    initialValue: DataLockConfigValue = defaultValue,
    typeOptions: TimePeriodOptions[] = periodicityTypeOptions,
    isDisabled = false
  ) {
    super({
      dataLockConfigEnabled: new UntypedFormControl(isDisabled ? false : initialValue.dataLockConfigEnabled),
      dataLockConfigDuration: new TimePeriodSelectorForm(initialValue.dataLockConfigDuration, typeOptions),
    });
  }

}
