import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { CohesityHelixModule } from '@cohesity/helix';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { DynamicComponentLoaderModule } from 'src/app/dynamic-component-loader';
import { FolderBrowserModule, SharedModule } from 'src/app/shared';

import { RestoreSharedModule } from '../restore/restore-shared';
import {
  AclPermissionsComponent,
  AntivirusScanFilterComponent,
  CreateViewBasicFormComponent,
  CreateViewButtonComponent,
  CreateViewDialogComponent,
  DeleteDirectoryQuotaDialogComponent,
  DeleteNetgroupWhitelistDialogComponent,
  FileExtensionListComponent,
  ModifyNetgroupWhitelistDialogComponent,
  ModifyViewDetailsComponent,
  MostSecureSettingsComponent,
  NetgroupWhitelistComponent,
  NfsAllSquashOptionsComponent,
  NfsRootPermissionsComponent,
  NtfsRootPermissionsComponent,
  ObjectKeyPatternSelectorComponent,
  PinViewToSsdComponent,
  PrincipalDialogComponent,
  ProtocolSelectorComponent,
  RecommendedStorageDomainSelectorComponent,
  RuleScopeFilterComponent,
  S3AclDialogComponent,
  SelectProtectionGroupComponent,
  SmbSuperUsersComponent,
  SwiftOwnershipComponent,
  TemplateSelectorComponent,
  ViewCategoryComponent,
  ViewDataLockExpiryComponent,
  ViewDialogComponent,
  ViewFileBrowserComponent,
  ViewMountPathsComponent,
  ViewNameComponent,
  ViewObjectKeysComponent,
  ViewProtocolComponent,
  ViewsGlancebarComponent,
  ViewSnapshotPermissionsComponent,
  WhitelistDialogComponent,
  WhitelistTableComponent,
} from './components';
import { ViewsService } from './services';

// eslint-disable-next-line @typescript-eslint/no-restricted-imports
const COMPONENTS = [
  AclPermissionsComponent,
  AntivirusScanFilterComponent,
  CreateViewBasicFormComponent,
  CreateViewButtonComponent,
  CreateViewDialogComponent,
  DeleteDirectoryQuotaDialogComponent,
  DeleteNetgroupWhitelistDialogComponent,
  FileExtensionListComponent,
  ModifyNetgroupWhitelistDialogComponent,
  ModifyViewDetailsComponent,
  MostSecureSettingsComponent,
  NetgroupWhitelistComponent,
  NfsAllSquashOptionsComponent,
  NfsRootPermissionsComponent,
  NtfsRootPermissionsComponent,
  ObjectKeyPatternSelectorComponent,
  PinViewToSsdComponent,
  PrincipalDialogComponent,
  ProtocolSelectorComponent,
  RecommendedStorageDomainSelectorComponent,
  RuleScopeFilterComponent,
  S3AclDialogComponent,
  SelectProtectionGroupComponent,
  SwiftOwnershipComponent,
  SmbSuperUsersComponent,
  TemplateSelectorComponent,
  ViewCategoryComponent,
  ViewDataLockExpiryComponent,
  ViewDialogComponent,
  ViewFileBrowserComponent,
  ViewMountPathsComponent,
  ViewNameComponent,
  ViewFileBrowserComponent,
  ViewObjectKeysComponent,
  ViewProtocolComponent,
  ViewsGlancebarComponent,
  ViewSnapshotPermissionsComponent,
  WhitelistDialogComponent,
  WhitelistTableComponent,
];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [
    CohesityHelixModule,
    CommonModule,
    DynamicComponentLoaderModule.forChild(AclPermissionsComponent, 'share-level-permission'),
    DynamicComponentLoaderModule.forChild(NetgroupWhitelistComponent, 'netgroup-whitelist'),
    DynamicComponentLoaderModule.forChild(SmbSuperUsersComponent, 'smb-super-users'),
    DynamicComponentLoaderModule.forChild(S3AclDialogComponent, 's3-acl-dialog'),
    DynamicComponentLoaderModule.forChild(TemplateSelectorComponent, 'template-selector'),
    DynamicComponentLoaderModule.forChild(ViewDialogComponent, 'modify-view-dialog'),
    DynamicComponentLoaderModule.forChild(CreateViewDialogComponent, 'create-view-dialog-new'),
    DynamicComponentLoaderModule.forChild(WhitelistDialogComponent, 'whitelist-dialog'),
    DynamicComponentLoaderModule.forChild(WhitelistTableComponent, 'whitelist-table'),
    NgxMatSelectSearchModule,
    FolderBrowserModule,
    RestoreSharedModule,
    SharedModule,
  ],
  providers: [ViewsService],
  exports: [...COMPONENTS],
})
export class ViewsSharedModule {}
