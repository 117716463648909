import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { Environment } from '@cohesity/iris-shared-constants';
import {
  ArrayPropertyKey,
  ArrayPropertyValue,
  Controls,
  NgxFormWithArrayControls,
  NgxSubFormComponent,
  subformComponentProviders,
} from 'ngx-sub-form';
import { objectPropertiesMap } from 'src/app/shared/constants';

import { NoSqlObjectProperty } from '../../../model/nosql-form-options';
import { ObjectPropertyDetails } from '../settings-list-object-property/recovery-property.model';

/**
 * Default Property value.
 */
const defaultProperty: NoSqlObjectProperty = {
  key: '',
  value: '',
};

@Component({
  selector: 'coh-settings-list-object-property-details',
  templateUrl: './settings-list-object-property-details.component.html',
  styleUrls: ['./settings-list-object-property-details.component.scss'],
  providers: subformComponentProviders(SettingsListObjectPropertyDetailsComponent),
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsListObjectPropertyDetailsComponent extends NgxSubFormComponent<ObjectPropertyDetails>
  implements NgxFormWithArrayControls<ObjectPropertyDetails>, AfterViewInit {

  constructor(private cdr: ChangeDetectorRef) {
    super();
  }

  /**
   * Environment Type of the object.
   */
  @Input() environment: Environment;

  /**
   * Type of the object.
   */
  @Input() type: string;

  /**
   * Gets the form array of object properties.
   */
  get propertiesFormArray(): UntypedFormArray {
    return this.formGroup.get('objectProperties') as UntypedFormArray;
  }

  /**
   * Initialize the component
   */
  initFormControl() {
    this.propertiesFormArray.clear();
    this.propertiesFormArray.push(
      this.createFormArrayControl('objectProperties', defaultProperty),
    );
    this.cdr.detectChanges();
  }

  ngAfterViewInit() {
    this.initFormControl();
  }

  /**
   * Add property info to formArray.
   */
  addProp() {
    if (this.propertiesFormArray.length < objectPropertiesMap[this.environment][this.formGroup.value.type].length) {
      this.propertiesFormArray.push(
        this.createFormArrayControl('objectProperties', defaultProperty),
      );
      this.cdr.detectChanges();
    }
  }

  /**
   * Add property info to formArray.
   *
   * @param  index index of the object property.
   */
  removeProp(index: number) {
    if (this.propertiesFormArray.length > 1) {
      this.propertiesFormArray.removeAt(index);
    } else {
      this.initFormControl();
    }
  }

  /**
   * Fetch object properties based on object type.
   *
   * @param type  Object type eg. kTable.
   * @param index   Control index of form array.
   */
  getPropertyKeys(type: string, index?: number): string[] {
    if (!type) {
      return [];
    }
    const result: string[] = objectPropertiesMap[this.environment][type];
    if (result && result.length > 1) {
      const selectedProperties: string[] = this.propertiesFormArray.controls
        .map((control, i) => {
          if (i !== index && control.value.key !== '') {
            return control.value.key;
          }
        });
      return result.filter(item => !selectedProperties.includes(item));
    } else {
      return result;
    }
  }

  getDefaultValues(): Partial<ObjectPropertyDetails> {
    return {
      objectProperties: []
    };
  }

  protected getFormControls(): Controls<ObjectPropertyDetails> {
    return {
      name: new UntypedFormControl(),
      objectProperties: new UntypedFormArray([]),
      icon: new UntypedFormControl(),
      type: new UntypedFormControl(),
    };
  }

  public createFormArrayControl(
    key: ArrayPropertyKey<ObjectPropertyDetails> | undefined,
    value: ArrayPropertyValue<ObjectPropertyDetails>
  ): UntypedFormControl {
    return new UntypedFormControl(value);
  }
}
