<cog-statlist valueSize="md" itemSpacing="md" class="margin-bottom-lg"
  *ngIf="!isLoading && showStats && dataPools?.length > 0">
  <cog-statlist-item [label]="'dataPools' | translate">
    {{ metadata.totalDataPools | number }}
  </cog-statlist-item>
  <cog-statlist-item [label]="'governed' | translate">
    {{ metadata.dataPoolsToGovern | number }}
  </cog-statlist-item>
  <cog-statlist-item *ngIf="showPolicies" [label]="'classification' | translate">
    {{ metadata.dataPoolsToClassify | number }}
  </cog-statlist-item>
  <cog-statlist-item [label]="'shields' | translate" *ngIf="showShields">
    {{ metadata.totalShields | number }}
  </cog-statlist-item>
  <cog-statlist-item [label]="'dataSources' | translate">
    {{ metadata.totalDataSources | number }}
  </cog-statlist-item>
  <!-- (ENG-464234): commented now, when needed enable it back
  <cog-statlist-item [label]="'data' | translate">
    <span [innerHtml]="metadata.totalDataSize | byteSize: true"></span>
  </cog-statlist-item> -->
  <cog-statlist-item *ngIf="displayedColumns.includes('objects')" [label]="'objects' | translate">
    {{ metadata.totalObjects | number }}
  </cog-statlist-item>
</cog-statlist>

<cog-filters [class.hidden]="isLoading || dataPools?.length === 0" class="margin-bottom">
  <cog-value-property-filter
    [class.hidden]="!showAuditLogging"
    *cogFilterDef="
      let params = params;
      let filter;
      quickFilter: true;
      key: 'auditLogging';
      label: 'auditLogging' | translate;
      filterType: 'valueProperty'
    "
    [filterDefParams]="params"
    [preLabel]="'auditLogging' | translate"
    [filter]="filter"
    [allowMultiple]="false"
    [alphaSort]="false"
    [filterValues]="auditLoggingFilter || []">
  </cog-value-property-filter>

  <cog-value-property-filter
    [class.hidden]="!showGovernance"
    *cogFilterDef="
      let params = params;
      let filter;
      quickFilter: true;
      key: 'governed';
      label: 'governed' | translate;
      filterType: 'valueProperty'
    "
    [filterDefParams]="params"
    [filter]="filter"
    [allowMultiple]="false"
    [alphaSort]="false"
    [filterValues]="governanceFilterValues || []">
  </cog-value-property-filter>

  <cog-value-property-filter
    [class.hidden]="!showDataSources"
    *cogFilterDef="
      let params = params;
      let filter;
      quickFilter: true;
      key: 'dataSource';
      label: 'dataSource' | translate;
      filterType: 'valueProperty'
    "
    [filterDefParams]="params"
    [filter]="filter"
    [allowMultiple]="true"
    [filterValues]="dataSourceFilterValues || []">
  </cog-value-property-filter>

  <cog-value-property-filter
    [class.hidden]="!showShields"
    *cogFilterDef="
      let params = params;
      let filter;
      quickFilter: true;
      key: 'shield';
      label: 'shield' | translate;
      filterType: 'valueProperty'
    "
    [filterDefParams]="params"
    [filter]="filter"
    [allowMultiple]="true"
    [filterValues]="shieldFilterValues || []">
  </cog-value-property-filter>

  <cog-value-property-filter
    [class.hidden]="!showPolicies"
    *cogFilterDef="
      let params = params;
      let filter;
      quickFilter: true;
      key: 'policy';
      label: 'policy' | translate;
      filterType: 'valueProperty'
    "
    [filterDefParams]="params"
    [filter]="filter"
    [allowMultiple]="true"
    [filterValues]="policyFilterValues || []">
  </cog-value-property-filter>

  <cog-search-property-filter
    cogDataId="data-pool-list-filter-search"
    *cogFilterDef="let filter; key: 'name'; label: 'name' | translate; quickFilter: true; filterType: 'searchProperty'"
    [filter]="filter"
    [placeholder]="'placeholderSearch' | translate">
  </cog-search-property-filter>
</cog-filters>

<ng-container *ngIf="!isLoading; else spinner">
  <cog-table
    cogDataId="data-pools-table"
    [inheritDataId]="true"
    *ngIf="filteredDataPools.length; else noDataFound"
    name="{{ 'dataPool_' + uuid }}"
    [source]="filteredDataPools"
    [selection]="enableSelection ? selectedDataPools : null"
    [persistSelection]="true"
    [canSelectRowFn]="canSelectRow"
    [canSelectAnyRowsFn]="canSelectAnyRow"
    [selectRowTooltipFn]="selectRowTooltip">
    <cog-table-multi-action
      #tableActions
      *ngIf="!hideBulkActions"
      allSelectedLabel="{{ 'argus.dataPool.allSelectedLabel' | translate: { items: tableActions.selectedCount } }}"
      anySelectedLabel="{{ 'argus.dataPool.anySelectedLabel' | translate: { items: tableActions.selectedCount } }}"
      selectAllLabel="{{ 'argus.dataPool.selectAllLabel' | translate: { items: tableActions.sourceLength } }}"
      clearLabel="{{ 'unselectAll' | translate }}">
      <button
        mat-button
        color="primary"
        *ngFor="let action of bulkActions"
        (click)="groupActionHandler(action)"
        [cogDataId]="'data-pool-list-bulk-action-btn-' + (action | lowercase)">
        {{ 'argus.dataPool.' + action | translate }}
      </button>
    </cog-table-multi-action>

    <table mat-table matSort matSortActive="name">
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell mat-sort-header="name" *matHeaderCellDef>{{ 'dataPool' | translate }}</th>
        <td mat-cell *matCellDef="let dataPool">{{ dataPool?.name }}</td>
      </ng-container>

      <!-- data source name -->
      <ng-container matColumnDef="dataSource">
        <th mat-header-cell mat-sort-header="dataSourcesMap.get(dataPool.id).name" *matHeaderCellDef>
          {{ 'dataSource' | translate }}
        </th>
        <td mat-cell *matCellDef="let dataPool">
          {{ dataSourcesMap.get(dataPool.id)?.name | naLabel }}
        </td>
      </ng-container>

      <!-- data source name -->
      <ng-container matColumnDef="policy">
        <th mat-header-cell mat-sort-header="policiesMap.get(dataPool.id).name" *matHeaderCellDef>
          {{ 'policy' | translate }}
        </th>
        <td mat-cell *matCellDef="let dataPool">
          <dg-dc-policy-cell [policies]="policiesMap.get(dataPool.id)"></dg-dc-policy-cell>
        </td>
      </ng-container>

      <!-- Shield Security Column -->
      <ng-container matColumnDef="shieldName">
        <th mat-header-cell mat-sort-header="shieldsMap.get(dataPool.id)" *matHeaderCellDef>
          {{ 'shield' | translate }}
        </th>
        <td mat-cell *matCellDef="let dataPool">
          <!-- Since we move out from shields -->
          <!-- <argus-shield-cell [shields]="shieldsMap.get(dataPool.id)"></argus-shield-cell> -->
        </td>
      </ng-container>

      <!-- Users Column -->
      <ng-container matColumnDef="data">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'data' | translate }}
        </th>
        <td mat-cell *matCellDef="let dataPool">
          {{ dataPoolMetadataMap.get(dataPool.id).totalDataSize || null | byteSize }}
        </td>
      </ng-container>

      <!-- Data Column -->
      <ng-container matColumnDef="objects">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'objects' | translate }}
        </th>
        <td mat-cell *matCellDef="let dataPool">
          {{ dataPoolMetadataMap.get(dataPool.id).totalObjects || null | number | naLabel }}
        </td>
      </ng-container>

      <!-- Audit Logging Column -->
      <ng-container matColumnDef="auditLogging">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'auditLogging' | translate }}
        </th>
        <td mat-cell *matCellDef="let dataPool">
          {{ (dataPoolIdToViewMap.get(dataPool.id)?.enableFilerAuditLogging ? 'enabled' : 'disabled') | translate }}
        </td>
      </ng-container>

      <!-- Governed Column -->
      <ng-container matColumnDef="governed">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'governed' | translate }}
        </th>
        <td mat-cell *matCellDef="let dataPool">
          <dg-yes-no-indicator [status]="dataPool.enabled"></dg-yes-no-indicator>
        </td>
      </ng-container>

      <!-- Action Menu Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let dataPool" class="show-on-hover-cell">
          <button
            mat-icon-button
            cogDataId="edit-data-pools"
            [inheritDataId]="true"
            (click)="editDataPool.emit(dataPool)">
            <cog-icon shape="edit"></cog-icon>
          </button>
        </td>
      </ng-container>
    </table>
    <mat-paginator></mat-paginator>
  </cog-table>
</ng-container>

<ng-template #spinner>
  <cog-spinner></cog-spinner>
</ng-template>

<ng-template #noDataFound>
  <cog-blank-card size="sm2" message="{{ 'noDataPoolsFound' | translate }}"></cog-blank-card>
</ng-template>
