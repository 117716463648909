import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NavItem } from '@cohesity/helix';
import { IrisContextService, isDmsScope } from '@cohesity/iris-core';
import { getIconTooltipKey } from '@cohesity/iris-shared-constants';
import { Memoize } from '@cohesity/utils';
import { Environment, Office365MailboxItemType } from 'src/app/shared';

import { Office365ObjectSearchResult } from '../../../recover-office365/model';
import { RestoreSearchResult } from '../../model/restore-search-result';

/**
 * This renders a separate line inside of a search item detail component
 */
@Component({
  selector: 'coh-object-search-item-detail-line',
  template: '<ng-content></ng-content>',
})
export class ObjectSearchItemDetailLineComponent {}

/**
 * This defines a type that can be used to build the metadata list in the search
 * results.
 */
export interface ObjectSearchItemMetadata {
  /**
   * Message key for the label. The component will append a ':'
   */
  label: string;

  /**
   * Metadata value
   */
  value: string;

  /**
   * Metadata type. The component will use a pipe appropriate to the selected type.
   */
  type?: 'text' | 'date';

  /**
   * True if the metadata needs to display a tooltip to show truncated data.
   * Only applicable when metadata type = text.
   */
  hasTooltip?: boolean;
}

/**
 * This renders a detail column for a Protected Object in the search results table.
 * This includes commonly used metadata for search results, but can be replaced with
 * a custom implementation as well depending on requirements.
 * Usage:
 * <coh-object-search-form [detailTemplate]="detailTemplate"></coh-object-search-form>
 * <ng-template #detailTemplate let-row>
 *   <coh-object-search-item-detail [object]="row" [source]="source" ...</coh-object-search-item-detail>
 * </ng-template>
 */
@Component({
  selector: 'coh-object-search-item-detail',
  templateUrl: './object-search-item-detail.component.html',
  styleUrls: ['./object-search-item-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ObjectSearchItemDetailComponent {
  /**
   * An optional list of actions to show to the far right of the component.
   */
  @Input() actions: NavItem[] = [];

  /**
   * This is an already translated string indicating the type of the object's parent source.
   * This is not included in the ProtectedObject api result, so must be supplied externally.
   */
  @Input() sourceType: string;

  /**
   * This is the name of the object's parent source.
   * This is not included in the ProtectedObject api result, so must be supplied externally.
   */
  @Input() source: string;

  /**
   * This is the name of the object's latest snapshot storage domain
   * This is not included in the ProtectedObject api result, so must be supplied externally.
   */
  @Input() storageDomain: string;

  /**
   * This is the protected object information returned by the search results.
   */
  @Input() object: RestoreSearchResult;

  /**
   * Currently, the icon for the row is determined by the type of item being recover
   * (ie, vm, storage volume, etc...), rather than the object environment. Eventually
   * the ProtectedObject value should include enough information to give a more specific
   * icon.
   */
  @Input() objectIcon: string;

  /**
   * This can be used to provide custom metadata information for the search item result.
   * if this is set, the default metadata list will be hidden.
   */
  @Input() metadata: ObjectSearchItemMetadata[];

  /**
   * Gets protection group name from the latest snapshot info.
   */
  get protectionGroupName(): string {
    return this.object.protectionGroupName;
  }

  /**
   * Gets channel name for a teams object.
   */
  get channelName(): string {
    return this.object.channelName;
  }

  /**
   * Gets owner name for a teams object.
   */
  get ownerName(): string {
    return this.object.ownerName;
  }

  /**
   * Gets the backup time from the latest snapshot info.
   */
  get lastBackupUsecs(): number {
    return this.object.restoreTimestampUsecs;
  }

  /**
   * Gets the object size from the latest snapshot info.
   */
  get objectSize(): number {
    if (isDmsScope(this.irisContextService.irisContext) && !this.isO365GranularItem) {
      // The size for dms objects are stored within archival latest snapshot info.
      return this.object.latestSnapshotsInfo?.[0]?.archivalSnapshotsInfo?.[0]?.logicalSizeBytes;
    }

    return this.object.objectSizeBytes;
  }

  /**
   * If the object is a continuous data protected object
   */
  get isContinuousProtected(): boolean {
    return !!this.object.cdpObjectInfo;
  }

  /**
   * Returns true if the object is an Office365 Granular Item.
   */
  get isO365GranularItem(): boolean {
    return this.object.environment === Environment.kO365 &&
      (this.object as Office365ObjectSearchResult).isGranularItem;
  }

  /**
   * Is the source configured to be a Oracle Host.
   *
   * @return boolean to specify whether oracle configured source or not.
   */
  get isOracleSource(): boolean {
    return this.object.environment === Environment.kOracle;
  }

  /**
   * Indicates if user is in DMaaS Scope or not.
   */
  get isPresentInDmsScope(): boolean {
    return isDmsScope(this.irisContextService.irisContext);
  }

  /**
   * Returns Office 365 indexed item of class type Office365ObjectSearchResult.
   */
  get office365IndexedItem(): Office365ObjectSearchResult {
    return this.object as Office365ObjectSearchResult;
  }

  /**
   * Indicates whether the item is an email or folder.
   */
  get isEmailOrMailboxFolder(): boolean {
    return this.office365IndexedItem.isMailboxFolder ||
    this.office365IndexedItem.objectType === Office365MailboxItemType.kEmail;
  }

  constructor(private irisContextService: IrisContextService) {}

  /**
   * Gets tooltip from icon.
   *
   * @param   icon  Icon used in cog-icon.
   * @return  Tooltip string.
   */
  @Memoize()
  getIconTooltip(icon): string {
    return getIconTooltipKey(icon);
  }

  /**
   *
   * @returns label to be transalted for email or folder item type.
   */
  getLabelText(): string {
    switch(this.office365IndexedItem.objectType) {
      case Office365MailboxItemType.kEmail:
        return 'subject';
      case Office365MailboxItemType.kFolder:
        return 'folderName';
      default:
        return '';
    }
  }
}
