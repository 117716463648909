import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

/**
 * Yes no indicator component.
 *
 * @example
 * <dg-yes-no-indicator [status]="statusValue"></dg-yes-no-indicator>
 */
@Component({
  selector: 'dg-yes-no-indicator',
  templateUrl: './yes-no-indicator.component.html',
  styleUrls: ['./yes-no-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class YesNoIndicatorComponent {
  /**
   * The status value.
   */
  @Input() status: boolean;
}
