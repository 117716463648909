import { includes } from 'lodash-es';
import { filter } from 'lodash-es';
import { sortBy } from 'lodash-es';
import { concat } from 'lodash-es';
// Service: Network Service
import { getConfigByKey } from '@cohesity/iris-core';
import { executeWithFallbackPromise } from '@cohesity/utils';

;(function (angular, undefined) {

  'use strict';

  angular
    .module('C')
    .service('NetworkService', networkServiceFn);

  function networkServiceFn(_, $http, API, NETWORK, NETWORK_INTERFACE_TYPE,
    NgPassthroughOptionsService, FEATURE_FLAGS, NgIrisContextService, ClusterService) {

    return {
      getClusterSubnets: getClusterSubnets,
      getAthenaClusterSubnets: getAthenaClusterSubnets,
      createClusterSubnets: createClusterSubnets,
      getInterfaces: getInterfaces,
      ifaceNameToVlan: ifaceNameToVlan,
      vlanToIfaceName: vlanToIfaceName,
      getInterfaceGroups: getInterfaceGroups,
      getClusterAirgapStatus: getClusterAirgapStatus,
      updateClusterAirgapStatus: updateClusterAirgapStatus,
      isIpV6Supported: isIpV6Supported,
      isAirgapSupported: isAirgapSupported
    };


    /**
     * Fetch network interfaces
     * API is expected to return bond and vlan interfaces
     *
     * @method    getInterfaces
     * @param     {array}   [types=['bond', 'vlan']]   filter by interface type
     * @param     {object}  headers Any headers to pass in the request
     * @returns   {array}   network interfaces
     */
    function getInterfaces(types, headers) {
      const v1Endpoint = API.private('nexus/node/list_network_interfaces?cache=true');
      const v2Endpoint = API.privateV2('network-interfaces?cache=true');
      const isPlatformBucket1v2ApiMigration = FEATURE_FLAGS['platformBucket1v2ApiMigration'];

      types = types || ['bond', 'vlan'];

      const getInterfacesFn = (url, data) => {
        const params = {
          method: 'get',
          url,
          headers: Object.assign({}, headers, NgPassthroughOptionsService.requestHeaders),
        };
        return () => $http(params);
      };

      // Check if the newly added ip field exists on the all nodes in the response.
      const evaluateFn = (response) => !response.nodes?.every(node => node.ip);

      const getInterfacesPromise = isPlatformBucket1v2ApiMigration
        ? executeWithFallbackPromise(getInterfacesFn(v2Endpoint), getInterfacesFn(v1Endpoint), evaluateFn)
        : executeWithFallbackPromise(getInterfacesFn(v1Endpoint));

      return getInterfacesPromise.then(function gotInterfaces(resp) {
        var ifaces = [];
        var filteredInterfaces = [];

        if (resp.data) {
          ifaces =
            resp.data?.networkInterfaces ||
            _.uniqBy(
              resp.data?.nodes?.flatMap(node => node.interfaces.map(iface => _convertV2InterfaceToV1(iface))),
              'ifaceGroup'
            ).filter(iface => iface.ifaceGroup);
        }

        filteredInterfaces = _filterInterfaces(ifaces ?? [], types);

        return sortBy(filteredInterfaces, ['ifaceType', 'ifaceName']);
      });
    }

    /**
     * Fetch Airgap Status
     *
     * @method    getClusterAirgapStatus
     * @returns   {boolean}  airgap status
     */
    function getClusterAirgapStatus() {
      return ClusterService.getClusterStatus().then(function gotDataSuccess(response) {
        const currentStatus = response.data?.airgapConfigStats?.airgapStatus;
        return currentStatus === 'Enable';
      });
    }

    /**
     * Update Airgap Status
     *
     * @method    updateClusterAirgapStatus
     * @param     {status}   [values=['enable', 'disable']]   update status to provided one
     * @returns   {object}  returns updated status cluster data
     */
    function updateClusterAirgapStatus(status) {
      return $http({
        method: 'put',
        url: '/v2/clusters/airgap',
        data: {"airgapStatus": status}
      }).then(function getUpdatedStatus(response) {
        return response.data || [];
      });
    }

    /**
     * Flag indicating whether IPv6 support controls to be displayed in
     * VLAN and VIPs page.
     *
     * It depends on feature flag enableIpv6, and also whether the
     * cluster is not a OneHelios appliance manager which does not support
     * Ipv6 yet.
     *
     * @method    isIpV6Supported
     * @param     None
     * @returns   {boolean}  true if supported, else false
     */
    function isIpV6Supported() {
       return FEATURE_FLAGS.enableIpv6 && getConfigByKey(NgIrisContextService.irisContext, 'networking.ipv6', true);
    }

    function isAirgapSupported() {
      return FEATURE_FLAGS.airgapUpdate && getConfigByKey(NgIrisContextService.irisContext, 'networking.airgap', true);
    }

    /**
     * filter list of interfaces as per input
     *
     * @method    _filterInterfaces
     * @param     {array}   interfaces                 network interfaces
     * @param     {array}   [types=['bond', 'vlan']]   filter by interface type
     * @returns   {array}   network interfaces
     */
    function _filterInterfaces(interfaces, types) {
      var needBonds = includes(types, 'bond');
      var needVlans = includes(types, 'vlan');
      var filteredInterfaces = [];

      if (needBonds) {
        filteredInterfaces = filter(interfaces,
          ['ifaceType', NETWORK_INTERFACE_TYPE.KIfBondMaster]);
      }

      if (needVlans) {
        filteredInterfaces = concat(filteredInterfaces, filter(interfaces,
          ['ifaceType', NETWORK_INTERFACE_TYPE.KIfVlanTagged]));
      }

      // If empty, return a default type bond0 for backwards compatibility
      if (needBonds && !filteredInterfaces.length) {
        filteredInterfaces = [
          {'ifaceName': NETWORK.defaultInterface,
           'ifaceType': NETWORK_INTERFACE_TYPE.KIfBondMaster,
           'speed': 'UNKNOWN' }];
      }

      return filteredInterfaces;
    }

    /**
     * Convert vlan interface Name to separate parent interface name and vlanId
     *
     * @method    ifaceNameToVlan
     * @param     {string}   ifaceName   vlan interface name.
     *                                   assumes <inteface>.<vlanId>
     * @returns   {object}   object with ifaceName and vlanId
     */
    function ifaceNameToVlan(ifaceName) {
      var ifaceNameVec = ifaceName.split('.');

      // Use default interface if name not present
      if (ifaceNameVec.length === 1) {
        return {'ifaceName': NETWORK.defaultInterface,
          'vlanId': Number(ifaceNameVec[0])};
      }

      return {'ifaceName': ifaceNameVec[0], 'vlanId': Number(ifaceNameVec[1])};
    }

    /**
     * Builds vlan interface name from vlanId and parent interface
     *
     * @method    vlanToIfaceName
     * @param     {string}   parentIfaceName   parent interface name of vlan
     * @param     {number}   vlanId            vlan ID
     * @returns   {string}   vlan interface name
     */
    function vlanToIfaceName(parentIfaceName, vlanId) {

      // If invalid, use vlanId for backwards compatibility
      if (!parentIfaceName) {
        return vlanId.toString();
      }

      return parentIfaceName + '.' + vlanId;
    }

    /**
     * Get the list of subnets for the cluster
     *
     * @method    getClusterSubnets
     * @returns   {object}   Promise to return list of subnets or error
     */
    function getClusterSubnets() {
      const clusterSubnetsURL = FEATURE_FLAGS.platformBucket2v2ApiMigration
        ? API.privateV2('clusters/subnets')
        : API.private('clusterSubnets');
      return $http({
        method: 'get',
        url: clusterSubnetsURL,
      }).then(function gotSubnets(resp) {
        return resp.data || [];
      });
    }

    /**
     * Get athena subnet from the list of the cluster subnets
     *
     * @method    getAthenaClusterSubnets
     * @param     {array}   subnets   List of cluster subnets
     * @returns   {array}   Athena cluster subnets
     */
    function getAthenaClusterSubnets(subnets) {
      return subnets.filter(subnet => subnet.component === 'athena');
    }

    /**
     * Create cluster subnets.
     * Make sure you do a get before put since this api removes subnets that
     * are not in the list.
     *
     * @method   createClusterSubnets
     * @param    {object}   subnets   subnets that needs to be added
     * @return   {object}   promise to return success or error
     */
    function createClusterSubnets(subnets) {
      return $http({
        method: 'put',
        url: API.private('clusterSubnets'),
        data: subnets
      }).then(function createClusterSubnetSuccess(response) {
        return response.data || [];
      });
    }

    /**
     * Get network interface groups.
     * The response data include bond and vlan interfaces.
     *
     * @method  getInterfaceGroups
     * @return  {array} An array of interface groups
     */
    function getInterfaceGroups() {
      return $http({
        method: 'get',
        url: API.public('interfaceGroups'),
      }).then(function gotInterfaceGroupsSuccess(resp) {
        return resp.data || [];
      });
    }
  }

  /**
   * Converts a v2 network interface object to the v1 format.
   *
   * This function takes a network interface object from the v2 API response format
   * and maps its properties to match the v1 API response format. It performs renaming,
   * type mapping, and default value handling where necessary.
   *
   * @param {Object} v2Interface - The v2 network interface object.
   * @returns {Object} The converted v1 network interface object.
   */
  function _convertV2InterfaceToV1(v2Interface) {
    const interfaceTypeMap = {
      Physical: 1,
      Bond: 2,
      Bridge: 3,
      Group: 4,
      Vlan: 5,
      VlanPhysical: 6,
      VlanBond: 7,
      VlanGroup: 8,
      VlanBridge: 9,
      Invalid: 10,
    };

    const bondingModeMap = {
      ActiveBackup: 1,
      '802_3ad': 4,
      BalanceAlb: 6,
      Invalid: 10,
    };

    return {
      id: _.get(v2Interface, 'id', -1),
      ifaceName: v2Interface.name || '',
      ifaceType: interfaceTypeMap[v2Interface.type] || 0,
      staticIp: v2Interface.staticIP || '',
      virtualIp: v2Interface.virtualIP || '',
      gateway: v2Interface.gateway || '',
      mtu: v2Interface.mtu,
      subnet: v2Interface.subnet || '',
      isUp: v2Interface.isUp,
      services: v2Interface.services || [],
      ifaceGroup: v2Interface.group || '',
      ifaceRole: _.get(v2Interface, 'role', '').toLowerCase(),
      isDefaultRoute: v2Interface.defaultRoute || false,
      bondSlave: v2Interface.bondSlaveNames || [],
      bondSlaveSlot: v2Interface.bondSlaveSlots || [],
      bondSlavesDetails: v2Interface.bondSlavesDetails || [],
      bondingMode: bondingModeMap[v2Interface.bondingMode] || 0,
      activeBondSlave: v2Interface.activeBondSlave || '',
      macAddr: v2Interface.macAddress || 'not set',
      isConnected: v2Interface.isConnected,
      speed: v2Interface.speed || 'UNKNOWN',
      staticIp6: v2Interface.staticIpV6 || '',
      subnet6: v2Interface.subnetV6 || '',
      stats: null,
    };
  }
})(angular);
