import { Injectable } from '@angular/core';
import { NavItem } from '@cohesity/helix';
import { getConfigByKey, IrisContextService, isDmsScope } from '@cohesity/iris-core';
import { SourceSelection } from '@cohesity/iris-source-tree';
import { StateService } from '@uirouter/core';
import { Environment } from 'src/app/shared';

import { ProtectionGroupService } from '../protection-group-shared';
import { ObjectActionCreator } from './object-action-creator';
import { SimpleObjectInfo } from './object-menu-provider';
import { ProtectedObjectsService } from './protected-objects.service';

@Injectable({
  providedIn: 'root',
})
export class PhysicalObjectActionCreator extends ObjectActionCreator {
  constructor(
    private irisContextService: IrisContextService,
    objectsService: ProtectedObjectsService,
    protectionGroupService: ProtectionGroupService,
    stateService: StateService,
  ) {
    super(objectsService, protectionGroupService, stateService);
  }

  /**
   * Function to return protection action for the supplied objects.
   *
   * @param object The objects.
   * @param objectOptions The object's passthrough options.
   * @returns The protection NavItem objects.
   */
  createPhysicalProtectAction(objects: SimpleObjectInfo[], selection?: SourceSelection): NavItem[] {
    if (!objects[0]) {
      return [];
    }

    const actions = [];
    const { environment, sourceId: selectedSourceId } = objects[0];

    if (!selection) {
      selection = {
        sourceIds: objects.map(object => object.id),
      };
    }

    if (!isDmsScope(this.irisContextService.irisContext) &&
      !getConfigByKey(this.irisContextService.irisContext, 'protection.hideProtectBlockedBased', false)) {
      actions.push({
        displayName: 'protectBlockBased',
        icon: 'helix:protect',
        state: 'protection-builder',
        stateParams: {
          environments: [Environment.kPhysical],
          parentSourceEnvironment: environment,
          protectSources: objects.map(object => ({ id: object.id })),
          excludeFilters: selection?.excludeFilters,
          cid: objects[0].accessClusterId,
          regionId: objects[0].regionId,
          protectionGroup: {
            selectedSourceId,
            selection,
            environment,
          }
        },
      });
    }
    actions.push({
      displayName: 'protectFileBased',
      icon: 'helix:protect',
      state: 'protection-builder',
      stateParams: {
        environments: [Environment.kPhysicalFiles],
        parentSourceEnvironment: environment,
        protectSources: objects.map(object => ({ id: object.id })),
        excludeFilters: selection?.excludeFilters,
        cid: objects[0].accessClusterId,
        regionId: objects[0].regionId,
        protectionGroup: {
          selectedSourceId,
          selection,
          environment,
        }
      },
    });

    return actions;
  }
}
