import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Observable } from 'rxjs';

import { DataPoolUpdateDialogComponent } from './data-pool-update-dialog.component';
import { DataPoolUpdateDialogInput } from './data-pool-update-dialog.model';

/**
 * Data pool update dialog service.
 */
@Injectable({
  providedIn: 'root',
})
export class DataPoolUpdateDialogService {
  constructor(private dialog: MatDialog) {}

  /**
   * Launch the data pool update dialog.
   *
   * @param dataPools the list of data pools.
   */
  launchDataPoolUpdateDialog(dialogInput: DataPoolUpdateDialogInput): Observable<boolean> {
    const config = { data: dialogInput, width: '50rem' };
    return this.dialog.open(DataPoolUpdateDialogComponent, config).afterClosed();
  }
}
