import { Injectable } from '@angular/core';
import { MetricDataBlock, StatisticsServiceApi as StatsServiceV1 } from '@cohesity/api/v1';
import { StatsServiceApi as StatsServiceV2, TimeSeriesStats, TimeSeriesStatsForMetric } from '@cohesity/api/v2';
import { capitalize } from 'lodash-es';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IrisContextService, flagEnabled } from '../iris-context';

export { DataPoint, TimeSeriesStats, TimeSeriesStatsForMetric } from '@cohesity/api/v2';
export type GetTimeSeriesStatsParams = StatsServiceV2.GetTimeSeriesStatsParams;

/**
 * Service for retrieving time series statistics.
 * It determines whether to use the v1 or v2 API based on the 'useV2APITimeSeriesStats' feature flag.
 */
@Injectable({
  providedIn: 'root'
})
export class StatsService {
  constructor(
    private irisCtx: IrisContextService,
    private statsServiceV1: StatsServiceV1,
    private statsServiceV2: StatsServiceV2,
  ) { }

  /**
   * Returns true if feature flag 'useV2APITimeSeriesStats' is enabled.
   *
   * @returns boolean
   */
  private useV2Api(): boolean {
    return flagEnabled(this.irisCtx.irisContext, 'useV2APITimeSeriesStats');
  }

  /**
   * Converts V1 API params to v2
   */
  private convertParamToV1(params: GetTimeSeriesStatsParams): StatsServiceV1.GetTimeSeriesStatsParams {
    const p = {
      ...params,
      metricName: params.metricNames[0],
      rollupFunction: params.rollupFunction ? params.rollupFunction.replace(/^k/, '').toLocaleLowerCase() : undefined,
    };
    return p;
  }

  /**
   * Converts type enum to v2 format
   */
  private convertV1Type(type: MetricDataBlock['type']): TimeSeriesStatsForMetric['type'] {
    const types = ['kInt64', 'kDouble', 'kString', 'kBytes'];
    return type ? types[type] as TimeSeriesStatsForMetric['type'] : null;
  }

  /**
   * Converts v1 response to v2 format
   */
  private convertV1Response(resp: MetricDataBlock): TimeSeriesStats {
    const r: TimeSeriesStats = {};
    if (resp) {
      r.timeSeriesStats = [{
        metricName: resp.metricName ?? undefined,
        type: this.convertV1Type(resp.type),
        dataPoints: resp?.dataPointVec?.map(d => ({
          timestampMsecs: d.timestampMsecs,
          ...(d?.data || {}),
        })) || null,
      }];
    }
    return r;
  }

  /**
   * Converts rollup function enum to v2 format
   */
  convertV1RollupFunctionEnum(val: StatsServiceV1.GetTimeSeriesStatsParams['rollupFunction']): GetTimeSeriesStatsParams['rollupFunction'] {
    if (val.startsWith('k')) {
      return val as GetTimeSeriesStatsParams['rollupFunction'];
    }
    return `k${capitalize(val)}` as GetTimeSeriesStatsParams['rollupFunction'];
  };

  /**
   * Get Time Series Stats.
   */
  getTimeseriesStats = (params: GetTimeSeriesStatsParams): Observable<TimeSeriesStats> => {
    // v2 api call
    if (this.useV2Api()) {
      if (params.rollupFunction) {
        params.rollupFunction = this.convertV1RollupFunctionEnum(params.rollupFunction);
      }
      return this.statsServiceV2.GetTimeSeriesStats(params);
    };
    // v1 api call
    return this.statsServiceV1.GetTimeSeriesStats(this.convertParamToV1(params))
      .pipe(
        map(r => this.convertV1Response(r)),
      );
  };
}
