import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { IoCsThreatsApiService, Threat } from '@cohesity/api/argus';
import { SnackBarService } from '@cohesity/helix';
import { AjaxHandlerService, AutoDestroyable } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'dg-td-threat-delete-dialog',
  templateUrl: './threat-delete-dialog.component.html',
  styleUrls: ['./threat-delete-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThreatDeleteDialogComponent extends AutoDestroyable {

  /**
   * Indicates whether we are submitting the data or not.
   */
  isSubmitting = false;

  get threat(): Threat {
    return this.data;
  }

  confirmControl = new FormControl('', [Validators.required, Validators.pattern('^([yY][eE][sS])$')]);

  constructor(
    private ajaxService: AjaxHandlerService,
    private cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) private data: Threat,
    private dialogRef: MatDialogRef<ThreatDeleteDialogComponent>,
    private snackBarService: SnackBarService,
    private threatService: IoCsThreatsApiService,
    private translate: TranslateService,
  ) {
    super();
  }

  /**
   * Delete Threat
   */
  deleteThreat() {
    // showing the loader.
    this.isSubmitting = true;
    this.cdr.detectChanges();

    this.threatService.deleteThreat({ id: this.threat.id })
      .pipe(
        this.untilDestroy(),
        finalize(() => {
          // resetting the loader.
          this.isSubmitting = false;
          this.cdr.detectChanges();
        }),
      )
      .subscribe({
        next: () => {
          this.snackBarService.open(this.translate.instant('successfullyDeleted'), 'success');
          this.dialogRef.close(this.threat);
        },
        error: this.ajaxService.handler,
      });
  }
}
