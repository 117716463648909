import { getConfigByKey, isVaultCluster } from '@cohesity/iris-core';
import { AppModuleConfig, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';

/**
 * Show navigation if this is Rx Vault Cluster
 */
const rxVaultClusterAccess = (ctx: StateAccessContext) => isVaultCluster(ctx.irisContext) &&
  getConfigByKey(ctx.irisContext, 'vault.features.allow', true);

/**
 * Show navigation if this is Tx Vault Cluster and feature flag is enabled
 */
const txVaultClusterAccess = (ctx: StateAccessContext) => !isVaultCluster(ctx.irisContext) &&
  ctx.FEATURE_FLAGS.onPremVaultClusters &&
  getConfigByKey(ctx.irisContext, 'vault.features.allow', true);

export const VaultClusterConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'vault-cluster.**',
      url: '/vault-cluster',
      loadChildren: () => import('./vault-cluster.module').then(m => m.VaultClusterModule),
    },
  ],
  allClusterMap: {
    'vault-cluster.activities.protection': false,
    'vault-cluster.activities.recovery': false,
    'vault-cluster.activities': false,
    'vault-cluster.infra-clusters': false,
    'vault-cluster.paired-clusters': false,
  },
  getStateAccessMap(): StateAccessMap {
    return {
      'vault-cluster.activities.protection': rxVaultClusterAccess,
      'vault-cluster.activities.recovery': rxVaultClusterAccess,
      'vault-cluster.activities': rxVaultClusterAccess,
      'vault-cluster.infra-clusters': txVaultClusterAccess,
      'vault-cluster.paired-clusters': rxVaultClusterAccess,
    };
  }
};
