import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Policy } from '@cohesity/api/argus';

/**
 * Policy cell Component
 * This component contains all the shields associated with data pools
 *
 * @example
 * <dg-dc-policy-cell
 *  [shields]="shieldsData">
 * </dg-dc-policy-cell>
 */
@Component({
  selector: 'dg-dc-policy-cell',
  templateUrl: './policy-cell.component.html',
  styleUrls: ['./policy-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PolicyCellComponent {

  /**
   * Get Shields
   */
  @Input() policies: Policy[] = [];

  /**
   * List of Shields to show in tooltip
   */
  get tooltipMsg(): string {
    return this.policies.map(policy => policy.name).slice(1).join(', ');
  }
}
