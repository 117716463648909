import { sumBy } from 'lodash-es';
import { filter } from 'lodash-es';
import { find } from 'lodash-es';
// MODULE: Nodes Summary Page

;(function(angular, undefined) {
  'use strict';

  angular.module('C.nodes', [])
    .config(nodesConfigFn)
    .controller('nodesController', nodesControllerFn);

  function nodesConfigFn($stateProvider, $urlRouterProvider) {
    $stateProvider
      .state('cluster.nodes', {
        name: 'Cohesity Nodes Summary',
        url: '^/platform/nodes',
        help: 'platform_nodes',
        title: 'Manage Nodes',
        canAccess: 'CLUSTER_VIEW',
        parentState: 'cluster',
        views: {
          'cluster-content@cluster': {
            templateUrl: 'app/platform/nodes/nodes.html',
            controller: 'nodesController'
          }
        }
      });
  }

  function nodesControllerFn(_, $scope, $rootScope, $state,
    ClusterService, NodeService, cModal, cMessage, evalAJAX,
    NODE_REMOVAL_STATE_CLASS, NODE_REMOVAL_STATE_LABEL, FEATURE_FLAGS,
    ngDialogService, NgClusterService) {

    $scope.allNodes = [];
    $scope.assignedNodes = [];
    $scope.unassignedNodes = [];
    $scope.upgradeInProgress = false;
    $scope.clusterNodesReady = false;
    $scope.clusterPlatforms = [];
    $scope.isAddNodeEnabled = ClusterService.clusterInfo._isPhysicalInstall ||
      ClusterService.clusterInfo._isVirtualEditionCluster;

    $scope.NODE_REMOVAL_STATE_CLASS = NODE_REMOVAL_STATE_CLASS;
    $scope.NODE_REMOVAL_STATE_LABEL = NODE_REMOVAL_STATE_LABEL;
    $scope.text = $rootScope.text['nodes.landing'];
    $scope.isCloudInstall = ClusterService.clusterInfo._isCloudInstall;

    // Enable Custom Failure Domain selection if
    $scope.customFailureDomainsEnabled =
      // Feature Flag is enabled AND
      FEATURE_FLAGS.customFailureDomain &&

      // Add Node functionality is enabled AND
      $scope.isAddNodeEnabled &&

      // Cluster is physical. It needs to have chassis and racks.
      ClusterService.clusterInfo._isPhysicalInstall &&

      // User has CLUSTER_MODIFY privilege.
      $rootScope.user.privs.CLUSTER_MODIFY;

    // Node serial number is displayed only for physical platforms.
    $scope._isPhysicalPlatform = ClusterService.clusterInfo._isPhysicalInstall;
    /**
     * Gets the nexus cluster information.
     *
     * @method     getNexusClusterInfo
     */
    function getNexusClusterInfo() {
      ClusterService.getClusterStatus().then(
        function getClusterStatusSuccess(resp) {
          var clusterInfo = resp.data;
          for (var i = 0, len = clusterInfo.nodeStatuses.length; i < len; i++) {
            loop2: for (var x = 0, lenX = $scope.assignedNodes.length; x < lenX; x++) {
              if (clusterInfo.nodeStatuses[i].id === $scope.assignedNodes[x].id) {
                angular.extend($scope.assignedNodes[x], clusterInfo.nodeStatuses[i]);
                if (!clusterInfo.nodeStatuses[i].services) {
                  $scope.assignedNodes[x]._unreachable = true;
                }
                break loop2;
              }
            }
          }
          if (['kUpgrade', 'Upgrade'].includes(clusterInfo.currentOperation)) {
            $scope.upgradeInProgress = true;
          }
        },
        evalAJAX.errorMessage
      ).finally(function getNexusStatusFinally() {
        $scope.minNodes = $scope.clusterInfo.supportedConfig.minNodesAllowed;
        $scope.nodeStatusReady = true;
      });
    }

    /**
     * Gets the cluster nodes.
     *
     * @method     getClusterNodes
     */
    function getClusterNodes() {
      var params = {
        fetchStats: true,
        includeMarkedForRemoval: true
      };
      NodeService.getClusterNodes(params).then(
        function getClusterNodesSuccess(r) {
          Array.prototype.push.apply($scope.assignedNodes, r.data);
          Array.prototype.push.apply($scope.allNodes, r.data);
          getNexusClusterInfo();
          getRacksInfo();
          getClusterPlatforms();
        },
        evalAJAX.errorMessage
      ).finally(
        function getClusterNodesFinally() {
          $scope.clusterNodesReady = true;
        }
      );
    }

    /**
     * Get cluster platforms and map nodes to the platform by node ID.
     *
     * @method  getClusterPlatforms
     */

    function getClusterPlatforms() {
      ClusterService.getClusterPlatforms().then(
        function getClusterPlatformsSuccess(r) {
          $scope.clusterPlatforms = r.data.Body;
        }, evalAJAX.errorMessage)
        .finally(
          function getClusterPlatformsFinally() {
            angular.forEach($scope.clusterPlatforms,
              function iteratePlatforms(platform) {
                platform.nodes = {};
                angular.forEach(platform.nodeIds,
                  function eachNodes(nodeId) {
                    var assignedNode = find($scope.assignedNodes, ['id', nodeId]);
                    var chassisName = assignedNode.chassisInfo.chassisName ||
                      assignedNode.chassisInfo.chassisSerial;
                    var sataSsdCount = $scope.diskStatsSumUp(
                      assignedNode.diskCountByTier, 'SATA-SSD',
                      'diskCount');
                    var hddCount = $scope.diskStatsSumUp(
                      assignedNode.diskCountByTier, 'SATA-HDD', 'diskCount'
                    );
                    var ssdCount = $scope.diskStatsSumUp(
                      assignedNode.diskCountByTier, 'PCIeSSD', 'diskCount'
                    );

                    // Assign tier and disk count accordingly.
                    if (hddCount === 0 && ssdCount > 2) {
                      assignedNode._nodeStorageTier = 'PCIeSSD';
                      assignedNode.diskCount = ssdCount;
                    } else if (hddCount === 0 && sataSsdCount > 0) {
                      assignedNode._nodeStorageTier = 'SATA-SSD';
                      assignedNode.diskCount = sataSsdCount;
                    } else {
                      assignedNode._nodeStorageTier = 'SATA-HDD';
                      assignedNode.diskCount = hddCount;
                    }
                    assignedNode.diskCapacity = $scope.diskStatsSumUp(
                      assignedNode.capacityByTier,
                      assignedNode._nodeStorageTier,
                      'tierMaxPhysicalCapacityBytes');
                    platform.nodes[chassisName] =
                      platform.nodes[chassisName] || [];
                    platform.nodes[chassisName].push(assignedNode);
                });
            });
          }
        );
    }

    /**
     * Get nodes rack info.
     *
     * @method  getRacksInfo
     */
    function getRacksInfo() {
      NgClusterService.getRacks().toPromise().then(
        function getRacksSuccess(racksInfo) {
          if (!racksInfo) {
            return;
          }
          for (var i = 0, len = $scope.assignedNodes.length; i < len; i++) {
            var rackId = $scope.assignedNodes[i].chassisInfo.rackId;
            if (rackId) {
              var rack = find(racksInfo.racks, ['id', rackId]);
              $scope.assignedNodes[i].chassisInfo.rackName = rack ? rack.name: '';
            }
          }
        }, evalAJAX.errorMessage);
    }

    /**
     * Mark node for removal
     *
     * @method     markForRemoval
     * @param      {number}  id      The identifier
     */
    $scope.markForRemoval = function markForRemoval(id) {

      // Prepare the modal.
      var contentString = $scope.text.markForRemoval1 + id + $scope.text.markForRemoval2;
      var options = {
        closeButtonText: $scope.text.buttons.cancel,
        actionButtonText: $scope.text.buttons.markForRemoval,
        title: $scope.text.markForRemovalTitle,
        content: contentString
      };

      // Show the modal.
      cModal.showModal({}, options).then(function cModalConfirmed(r) {
        NodeService.markNodeForRemoval(id).then(
          function markForRemovalSuccess(r) {
            // On success, refresh node lists.
            getNodes();
            cMessage.success({
              textKey: 'nodes.landing.successfullyMarkedForRemoval',
            });
          },
          evalAJAX.errorMessage
        );
      });
    };

    /**
     * Sum up a property value in an array by storage tier.
     *
     * @method  diskStatsSumUp
     * @param  {array}   arrObj   node object on which stats should be summed up
     * @param  {string}  storageTier  type of storage. Ex: SATA-HDD, PCIeSSD
     * @param  {string}  prop name of the property to use. Ex: diskCount,
     *                           tierMaxPhysicalCapacityBytes
     * @return  {integer}  The sum of prop values
     */
    $scope.diskStatsSumUp = function diskStatsSumUp(arrObj, storageTier, prop) {
      return sumBy(filter(arrObj, {'storageTier': storageTier}), prop);
    };

    /**
     * Gets the nodes.
     *
     * @method     getNodes
     */
    function getNodes() {
      $scope.allNodes = [];
      $scope.assignedNodes = [];
      $scope.unassignedNodes = [];
      $scope.upgradeInProgress = ClusterService.clusterInfo._isUpgrading;
      getClusterNodes();

      // Add node button is disabled for physical robo cluster.
      ClusterService.getHardwareInfo().then(
        function getHardwareInfoSuccess() {
          $scope.isPhysicalRobo = $rootScope.isPhysicalRobo;
          $scope.isAddNodeEnabled = !$scope.isPhysicalRobo && $scope.isAddNodeEnabled;
        }, evalAJAX.errorMessage);
    }

    getNodes();

    /**
     * Opens the rack assignment dialog.
     */
    $scope.configureRack = function configureRack() {
      ngDialogService.assignRacks()
      .toPromise()
      .then(function afterClose(response){
        // Determine whether  all chassis are assigned to racks.
        $scope._allChassisAssigned = response.allChassisAssigned;
      });
    };
  }

})(angular);
