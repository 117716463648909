import { BasePortalOutlet, CdkPortalOutlet, ComponentPortal, TemplatePortal } from '@angular/cdk/portal';
import {
  Component,
  ComponentRef,
  EmbeddedViewRef,
  Inject,
  Injector,
  TemplateRef,
  Type,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { AjaxHandlerService } from '@cohesity/utils';
import { isUndefined } from 'lodash-es';
import { Observable, of } from 'rxjs';
import { finalize } from 'rxjs/operators';

/**
 * Options for configuring this simple dialog.
 *
 * TODO: Make sure arbitrary values can be added.
 * TODO: Expand to accept templates instead of components too.
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface SimpleDialogData<T> {
  title?: string;
  copy?: string;
  confirmButtonLabel?: string;
  confirmResult?: any;
  confirmHandler?: () => Observable<any>;
  declineButtonLabel?: string;
  declineResult?: any;
  component?: Type<any>;
  componentInputs?: any;
  hideDeclineButton?: boolean;
}

/**
 * A simple dialog component for challenge modals and non-complex interactions.
 * Forces a cancel + ok button with customizable labels.
 *
 * NOTE: This is not intended to be used in a template on it's own.
 *
 * @example
 *   const data = {
 *     confirmButtonLabel: 'Make it so',
 *     confirmHandler: () => ApiCall()
 *     copy: 'translationKey or copy to display in the dialog.',
 *     component: MyComponentReference,
 *     componentInputs: {
 *       hash: 'of',
 *       compoennt: 'inputs'
 *     },
 *   };
 *
 *   dialogService.simpleDialog(null, data)
 *     .subscribe(result => console.log(result));
 */
@Component({
  selector: 'coh-simple-dialog',
  templateUrl: './simple-dialog.component.html',
  styleUrls: ['./simple-dialog.component.scss'],
})
export class SimpleDialogComponent extends BasePortalOutlet {
  portalObject: ComponentPortal<any> | TemplatePortal<any>;

  /**
   * A references to the dynamically loaded component.
   */
  private componentRef: ComponentRef<any>;

  /**
   * To show the loader on the submitting button.
   */
  isSubmitting = false;

  /**
   * Creates an instance of SimpleDialogComponent.
   *
   * @param   dialogRef          Dialog reference.
   * @param   translateService   Translation service.
   * @param   loader             Component loader service.
   * @param   data               Optional data apssed to this modal instance.
   */
  constructor(
    private ajaxHandlerService: AjaxHandlerService,
    public dialogRef: MatDialogRef<SimpleDialogComponent>,
    private injector: Injector,
    @Inject(MAT_DIALOG_DATA) private _data: any) {
    super();

    // TODO (spencer): Add lazy-loaded components support.
    if (this.data.component) {
      this.portalObject = new ComponentPortal(this.data.component);
    }
  }

  /**
   * PortalOutlet where component will be rendered.
   */
  @ViewChild(CdkPortalOutlet, { static: true }) private _portalOutlet: CdkPortalOutlet;

  /**
   * Reference to child component host.
   */
  @ViewChild('cohDialogComponentHost', { static: true, read: ViewContainerRef })
    componentHost: ViewContainerRef | undefined;

  /**
   * Gets the data passed to the dialog.
   */
  get data(): SimpleDialogData<any> {
    return this._data || {};
  }

  /**
   * Gets the confirmation button label passed to the dialog, or returns the
   * default.
   */
  get copy(): string {
    return this.data.copy;
  }

  /**
   * Gets the confirmation button label passed to the dialog, or returns the
   * default.
   */
  get dialogConfirmButtonLabel(): string {
    return this.data.confirmButtonLabel || 'ok';
  }

  /**
   * Gets the decline/cancel button label passed to the dialog, or returns the
   * default.
   */
  get dialogDeclineButtonLabel(): string {
    return this.data.declineButtonLabel || 'cancel';
  }

  /**
   * Gets the dialog title passed to the dialog, or returns the default.
   */
  get title(): string {
    return this.data.title || 'areYouSureQuestion';
  }

  /**
   * Attaches a portal component to AppPanelComponent.
   *
   * @param  portal  ComponentOrTemplate.
   */
  attach(portal: any): any {
    if (portal instanceof TemplateRef) {
      return this.attachTemplatePortal(
        new TemplatePortal(portal, undefined, <any>{
          dialogRef: this.dialogRef,
        }));
    } else {
      const injector = this.createInjector();
      return this.attachComponentPortal(new ComponentPortal(portal, undefined, injector));
    }
  }

  /**
   * Whether AppPanelComponent has an attached portal.
   */
  hasAttached(): boolean {
    if (this._portalOutlet) {
      return this._portalOutlet.hasAttached();
    }

    return false;
  }

  /**
   * Attaches the given TemplatePortal to this PortlHost as an embedded View.
   *
   * @param    portal   Portal to be attached.
   * @returns           Reference to the created embedded view.
   */
  attachTemplatePortal<T>(portal: TemplatePortal<T>): EmbeddedViewRef<T> {
    if (!this.hasAttached()) {
      return this._portalOutlet.attachTemplatePortal(portal);
    }
  }

  /**
   * Attach the given ComponentPortal to this PortalOutlet using the ComponentFactoryResolver.
   *
   * @param    portal  Portal to be attached to the portal outlet.
   * @returns          Reference to the created component.
   */
  attachComponentPortal<C>(portal: ComponentPortal<C>): ComponentRef<C> {
    if (!this.hasAttached()) {
      return this._portalOutlet.attachComponentPortal(portal);
    }
  }

  /**
   * Returns injector that will be passed to AppPanelComponent and a component that it attaches.
   */
  private createInjector(): Injector {
    return Injector.create({
      parent: this.injector,
      providers: [{ provide: {}, useValue: this.data.componentInputs }],
    });
  }

  /**
   * Decline click handler.
   */
  declineHandler() {
    this.dialogRef.close(!isUndefined(this.data.declineResult) ? this.data.declineResult : false);
  }

  /**
   * Confirm click handler.
   */
  confirmHandler() {
    this.isSubmitting = !!this.data.confirmHandler;

    (this.data.confirmHandler ? this.data.confirmHandler() : of(true))
      .pipe(
        finalize(() => this.isSubmitting = false),
        this.ajaxHandlerService.catchAndHandleError(),
      )
      .subscribe(() => {
        this.dialogRef.close(!isUndefined(this.data.confirmResult) ? this.data.confirmResult : true);
      });
  }
}
