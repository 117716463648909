import { Injectable } from '@angular/core';
import { ProtectionSourceNode } from '@cohesity/api/v1';
import { DataTreeSelection } from '@cohesity/helix';
import { SourceSelection, SourceTreeFilters } from '@cohesity/iris-source-tree';
import { UIRouterGlobals } from '@uirouter/core';

import { BaseProtectionSourceService } from '../shared/base-protection-source.service';
import { MongoDBPhysicalSourceDataNode } from './mongodb-physical-data-node';
import { MongoDBPhysicalViewFilters } from './mongodb-physical-view-filters';

/**
 * Source tree service for MongoDB Physical.
 */
@Injectable({
  providedIn: 'root',
})
export class MongoDBPhysicalSourceTreeService extends BaseProtectionSourceService<MongoDBPhysicalSourceDataNode> {

  /**
   * A reference to azure view filters, to show a physical, flat list view.
   */
  viewFilters: MongoDBPhysicalViewFilters<MongoDBPhysicalSourceDataNode>;

  constructor(
    private uiRouterGlobals: UIRouterGlobals,
  ) {
    super();
    this.viewFilters = new MongoDBPhysicalViewFilters(
      this.filters,
      this.treeControl,
      this.treeTransformer,
    );
  }

  /**
   * Transforms the node object from the api into a Protection Source Tree node to pass to the tree.
   *
   * @param   node   The original node.
   * @param   level  The level in the tree.
   * @return  A UdaSourceDataNode that can be displayed in the tree.
   */
  transformData(node: ProtectionSourceNode, level: number): MongoDBPhysicalSourceDataNode {
    return new MongoDBPhysicalSourceDataNode(node, level);
  }

  /**
   * Convert source selection to the data tree selection model.
   * source ids can be either selected items or auto selected items, nodes with children are
   * assumed to be auto selected. Nodes can be in the tree multiple times, and should not be
   * duplicated in the selection.
   *
   * @param   allNodes         The unfiltered list of tree nodes.
   * @param   sourceSelection  The job selection.
   * @return  A data tree selection model.
   */
  transformToDataTreeSelection(
    allNodes: MongoDBPhysicalSourceDataNode[],
    sourceSelection: SourceSelection
  ): DataTreeSelection<MongoDBPhysicalSourceDataNode> {
    const selection: DataTreeSelection<MongoDBPhysicalSourceDataNode> = {
      autoSelected: [],
      excluded: [],
      selected: [],
      options: {},
    };

    const sourceMap: any = {};
    if (!sourceSelection) {
      return selection;
    }

    const nodesLength = allNodes.length;
    for (let i = 0; i < nodesLength; i++) {
      const node = allNodes[i];

      if ((sourceSelection.sourceIds || []).includes(Number(node.id)) && !sourceMap[node.id]) {
        sourceMap[node.id] = node;
        if (!node.isLeaf) {
          selection.autoSelected.push(node);
        } else {
          selection.selected.push(node);
        }
        node.inCurrentGroup = true;
      } else if ((sourceSelection.excludeSourceIds || []).includes(Number(node.id)) && !sourceMap[node.id]) {
        sourceMap[node.id] = node;
        selection.excluded.push(node);
      }
    }

    if (sourceSelection.sourceSpecialParameters) {
      selection.options = {};
      sourceSelection.sourceSpecialParameters.forEach(params => {
        selection.options[params.sourceId] = params;
      });
    }
    return selection;
  }

  /**
   * Re-initializes view filter defaults based on current environment workloadType
   *
   * @param     workloadType     The environment job type
   * */
  initViewFilterDefaults(): SourceTreeFilters<MongoDBPhysicalSourceDataNode> {
    return this.filters;
  }

  /**
   * Attaches a filterChange listener to the component instance to apply context based changes
   * based on the updated filter value.
   *
   * @param workloadType     The environment job type
   */
  attachFilterValueChangeListener() {
    this.initViewFilterDefaults();
  }
}
