import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { DcScan, DcScanId, DcScanParams, DcScansApiService } from '@cohesity/api/argus';
import { ClusterInfoService } from '@cohesity/data-govern/cluster-replication';
import { getDefaultStartTime, getDefaultTaskName } from '@cohesity/data-govern/scans';
import { MomentDatePipe, SnackBarService } from '@cohesity/helix';
import { BuilderConfig, ControlFlow, FormBuilderComponent } from '@cohesity/shared-forms';
import { AjaxHandlerService, AutoDestroyable, Memoize } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import { isEmpty } from 'lodash-es';
import { combineLatest, of } from 'rxjs';
import { finalize, take, tap } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';

import { AdvancedSettingsComponent } from './advanced-settings';
import { DcScanFormModel } from './dc-scan.model';
import { ObjectSearchComponent } from './object-search';
import { SelectPolicyComponent } from './select-policy';
import { SettingsComponent } from './settings';

/**
 * Returns a hash function for memoizing `getScanParams` based on the `scanParams` object.
 */
const getDcScanParamsHashFn = (scanParams: DcScanParams) => JSON.stringify(scanParams || {});

@Component({
  selector: 'dg-dc-scan',
  templateUrl: './dc-scan.component.html',
  styleUrls: ['./dc-scan.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DcScanComponent extends AutoDestroyable implements OnInit, AfterViewInit {
  @Input() scanId: DcScanId;

  @Input() scan?: Partial<DcScan>;

  @Output() goBack = new EventEmitter<null>();

  @ViewChild(FormBuilderComponent, { static: true }) scanBuilder: FormBuilderComponent<DcScanFormModel, DcScan>;

  builderConfig: BuilderConfig = {
    sections: [
      ObjectSearchComponent,
      SelectPolicyComponent,
      SettingsComponent,
      AdvancedSettingsComponent,
    ],
    saveForm: this.saveForm.bind(this),
    goBack: () => this.goBack.next(),
    canShowSaveForm: this.canShowSaveForm.bind(this),
  };

  constructor(
    private ajaxService: AjaxHandlerService,
    private cdr: ChangeDetectorRef,
    private clusterInfoService: ClusterInfoService,
    private dcScansApiService: DcScansApiService,
    private momentDatePipe: MomentDatePipe,
    private snackBarService: SnackBarService,
    private translateService: TranslateService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.getScanDetails();
  }

  ngAfterViewInit(): void {
    const advancedSettingsControlFlow = new ControlFlow(
      this.scanBuilder.formSectionRefByName.advancedSettings,
      () => isEmpty(this.scanBuilder?.formGroup?.value?.advancedSettings),
    );
    const settingsControlFlow = new ControlFlow(
      this.scanBuilder.formSectionRefByName.settings,
      () => isEmpty(this.scanBuilder?.formGroup?.value?.settings),
    );
    const policyControlFlow = new ControlFlow(
      this.scanBuilder.formSectionRefByName.policy,
      () => Boolean(this.scanBuilder?.formGroup?.value?.policy),
      [advancedSettingsControlFlow, settingsControlFlow],
    );
    const objectsControlFlow = new ControlFlow(
      this.scanBuilder.formSectionRefByName.objects,
      () => Boolean(this.scanBuilder?.formGroup?.value?.objects?.length),
      [policyControlFlow],
    );

    this.scanBuilder.initControlFlow(objectsControlFlow).pipe(this.untilDestroy()).subscribe();
  }

  @Memoize(getDcScanParamsHashFn)
  getScanRequestKey(_scanParams: DcScanParams): string {
    return uuid();
  }

  getScanDetails() {
    this.scanBuilder.isLoading = true;
    this.cdr.detectChanges();
    combineLatest([
      this.scanId
        ? this.dcScansApiService.getDcScanById({ id: this.scanId })
        : of(this.generateDefaultScan()).pipe(take(1)),
      this.clusterInfoService.loadData(),
    ])
      .pipe(
        this.untilDestroy(),
        finalize(() => {
          this.scanBuilder.isLoading = false;
          this.cdr.detectChanges();
          this.scanBuilder.initFormSection();
        }),
        tap(([scan]) => (this.scan = scan))
      )
      .subscribe({ error: this.ajaxService.handler });
  }

  saveForm() {
    const body = Object.values(this.scanBuilder.formSectionRefByName).reduce((out, { componentRef }) => {
      out = { ...out, ...(componentRef.instance.toDataModel() || {}) };
      return out;
    }, {} as DcScanParams);

    if (this.scanId) {
      delete body.scanRequestKey;
    } else {
      body.scanRequestKey = this.getScanRequestKey(body);
    }

    this.scanBuilder.isSubmitting = true;
    this.cdr.detectChanges();
    this.scanBuilder.cdr.detectChanges();
    (this.scanId ? this.dcScansApiService.updateDcScan : this.dcScansApiService.createDcScan)
      .call(this.dcScansApiService, { id: this.scanId || undefined, body })
      .pipe(
        this.untilDestroy(),
        finalize(() => {
          this.scanBuilder.isSubmitting = false;
          this.cdr.detectChanges();
          this.scanBuilder.cdr.detectChanges();
        }),
        tap(scan => {
          this.scan = scan;
          this.builderConfig.goBack();
        })
      )
      .subscribe(
        () => this.snackBarService.open(this.translateService.instant('dg.dc.scan.created', { scanName: this.scan.name })),
        this.ajaxService.handler
      );
  }

  canShowSaveForm() {
    return Object.values(this.scanBuilder.formGroup.controls).every(({ disabled }) => disabled === false);
  }

  private generateDefaultScan(): Partial<DcScan> {
    return {
      ...this.scan,
      name: getDefaultTaskName(this.translateService, this.momentDatePipe, 'dg.dc.scan.defaultTaskName'),
      schedule: {
        ...(this.scan?.schedule ?? {}),
        time: getDefaultStartTime(),
      }
    };
  }
}
