<ng-template cohFormSectionEditMode>
  <h2 class="margin-bottom-lg">{{ 'settings' | translate }}</h2>

  <ng-container *ngIf="isScheduleScansEnabled">
    <h3 class="margin-bottom-sm">{{ 'dg.dc.scan.scanSchedule' | translate }}</h3>
    <div class="flex-row-md">
      <mat-form-field cogDataId="scan-schedule-type">
        <mat-label>{{ 'dg.dc.scan.scheduled' | translate }}</mat-label>
        <mat-select
          [value]="selectedScheduleType"
          (selectionChange)="selectScheduleType($event.value)"
          cogDataId="input"
          [inheritDataId]="true">
          <mat-option
            *ngFor="let schedule of scheduleTypes"
            [value]="schedule"
            [cogDataId]="'type-option-' + schedule"
            [inheritDataId]="true">
            {{ 'dg.dc.scan.schedule.' + schedule | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <ng-container [ngSwitch]="selectedScheduleType">
        <ng-container *ngSwitchCase="scheduleTypeEnum.Weeks">
          <ng-container *ngTemplateOutlet="weeklySchedule"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="scheduleTypeEnum.Months">
          <ng-container *ngTemplateOutlet="monthlySchedule"></ng-container>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>

  <h3 class="margin-bottom-sm margin-top-xs">{{ 'dg.dc.scanMethod' | translate }}</h3>
  <div *ngIf="!isIncrementalScansEnabled || selectedScheduleType !== scheduleTypeEnum.Once">
    {{ dcScanMethodEnum.full | dcScanMethod }}
  </div>

  <div class="flex-column-sm" *ngIf="isIncrementalScansEnabled && selectedScheduleType === scheduleTypeEnum.Once">
    <mat-radio-group class="flex-row-lg" [value]="selectedScanMethod" (change)="selectScanMethod($event.value)">
      <mat-radio-button
        [value]="dcScanMethodEnum.full"
        cogDataId="scan-method-{{ dcScanMethodEnum.full }}-option"
        [inheritDataId]="true">
        {{ dcScanMethodEnum.full | dcScanMethod }}
      </mat-radio-button>
      <mat-radio-button
        [value]="dcScanMethodEnum.incremental"
        cogDataId="scan-method-{{ dcScanMethodEnum.incremental }}-option"
        [inheritDataId]="true">
        {{ dcScanMethodEnum.incremental | dcScanMethod }}
      </mat-radio-button>
      <mat-radio-button
        [value]="dcScanMethodEnum.incremental_with_full"
        cogDataId="scan-method-{{ dcScanMethodEnum.incremental_with_full }}-option"
        [inheritDataId]="true">
        {{ dcScanMethodEnum.incremental_with_full | dcScanMethod }}
      </mat-radio-button>
    </mat-radio-group>

    <div class="flex-column-xs" *ngIf="selectedScanMethod === dcScanMethodEnum.incremental">
      <cog-banner>{{ 'dg.dc.enum.dcScanMethod.banner.incremental.info' | translate }}</cog-banner>
      <cog-banner status="critical">{{ 'dg.dc.enum.dcScanMethod.banner.incremental.critical' | translate }}</cog-banner>
    </div>

    <div *ngIf="selectedScanMethod === dcScanMethodEnum.incremental_with_full">
      <cog-banner>{{ 'dg.dc.enum.dcScanMethod.banner.incremental_with_full.info' | translate }}</cog-banner>
    </div>
  </div>

  <h3 class="margin-bottom-xs margin-top-lg">{{ 'dg.dc.enum.dcScanMethod.hyperscan' | translate }}</h3>
  <mat-checkbox
    *ngIf="isHyperScanEnabled"
    class="margin-top-sm"
    [checked]="isHyperScanChecked"
    (change)="selectHyperScanMethod($event.checked)"
    cogDataId="scan-method-hyper-scan">
    {{ 'dg.dc.scan.performHyperScan' | translate }}
  </mat-checkbox>

  <h3 class="margin-bottom-sm margin-top-lg">{{ 'dg.dc.snapshotWindow' | translate }}</h3>
  <span>{{ 'dg.dc.scan.mostRecent' | translate }}</span>
  <mat-radio-group
    class="flex-column-sm hidden"
    [value]="selectedSnapshotWindow"
    (change)="selectSnapshotWindow($event.value)">
    <mat-radio-button [value]="snapshotSelectionTypeEnum.mostRecent" cogDataId="most-recent" [inheritDataId]="true">
      {{ 'dg.dc.scan.mostRecent' | translate }}
    </mat-radio-button>

    <div class="flex-row baseline-align" cogDataId="date-range" [inheritDataId]="true">
      <mat-radio-button
        [value]="snapshotSelectionTypeEnum.dateRange"
        [disabled]="true"
        cogDataId="button"
        [inheritDataId]="true">
        <span class="margin-right">
          {{ 'dg.td.scan.dataRange' | translate }}
        </span>
      </mat-radio-button>
      <!-- <cog-date-range-input
        formControlName="dateRange"
        cogDataId="picker"
        [inheritDataId]="true"
        [useTimeframe]="true"
        [enableGuessTimeframe]="true"
        [enablePreciseDateRangeSelection]="true"
        >
      </cog-date-range-input> -->
    </div>
  </mat-radio-group>
</ng-template>

<ng-template #monthlySchedule>
  <mat-form-field cogDataId="scan-monthly-schedule-week">
    <mat-label>{{ 'on' | translate }}</mat-label>
    <mat-select
      [value]="selectedWeekOfMonth"
      (selectionChange)="selectWeekOfMonth($event.value)"
      cogDataId="input"
      [inheritDataId]="true">
      <mat-option
        *ngFor="let week of weeksOfMonth"
        [value]="week"
        [cogDataId]="'option-' + week"
        [inheritDataId]="true">
        {{ week | translate }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field cogDataId="scan-monthly-schedule-week-number">
    <mat-select
      [value]="selectedWeekDay"
      (selectionChange)="selectWeekDay($event.value)"
      cogDataId="input"
      [inheritDataId]="true">
      <mat-option *ngFor="let day of weekDays" [value]="day" [cogDataId]="'option-' + day" [inheritDataId]="true">
        {{ day | translate }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</ng-template>

<ng-template #weeklySchedule>
  <mat-form-field cogDataId="scan-weekly-schedule">
    <mat-select
      multiple
      [formControl]="selectedWeekDaysCtrl"
      (selectionChange)="selectWeekDays($event.value)"
      cogDataId="input"
      [inheritDataId]="true">
      <mat-select-trigger>
        {{selectedWeekDaysCtrl?.value?.[0] || ''}}
        <span *ngIf="(selectedWeekDaysCtrl?.value?.length || 0) > 1">
          {{ 'dg.dc.scan.schedule.weekdays.others' | translate: { n: selectedWeekDaysCtrl?.value?.length - 1 } }}
        </span>
      </mat-select-trigger>
      <mat-option *ngFor="let day of weekDays" [value]="day" [cogDataId]="'input-option-' + day" [inheritDataId]="true">
        {{ day | translate }}
      </mat-option>
    </mat-select>
    <mat-error *ngIf="selectedWeekDaysCtrl.hasError('required')" cogDataId="scan-weekly-day-required">
      {{ 'errors.required' | translate }}
    </mat-error>
  </mat-form-field>
</ng-template>
