/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityApiConfiguration as __Configuration } from '../cohesity-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { AgentDeploymentStatusResponse } from '../models/agent-deployment-status-response';
import { DataTransferFromVaultsSummaryResponse } from '../models/data-transfer-from-vaults-summary-response';
import { DataTransferToVaultsSummaryResponse } from '../models/data-transfer-to-vaults-summary-response';
import { ObjectInformation } from '../models/object-information';
import { ProtectionTrend } from '../models/protection-trend';
import { ProtectionSourcesJobRunsReportElement } from '../models/protection-sources-job-runs-report-element';
import { ProtectionSourcesJobsSummaryReportResponseWrapper } from '../models/protection-sources-jobs-summary-report-response-wrapper';
@Injectable({
  providedIn: 'root',
})
class ReportsService extends __BaseService {
  static readonly GetAgentDeploymentReportPath = '/public/reports/agents';
  static readonly GetDataTransferFromVaultsReportRequestPath = '/public/reports/dataTransferFromVaults';
  static readonly GetDataTransferToVaultsReportRequestPath = '/public/reports/dataTransferToVaults';
  static readonly GetGdprReportPath = '/public/reports/gdpr';
  static readonly GetProtectedObjectsTrendsReportRequestPath = '/public/reports/protectedObjectsTrends';
  static readonly GetProtectionSourcesJobRunsReportRequestPath = '/public/reports/protectionSourcesJobRuns';
  static readonly GetProtectionSourcesJobsSummaryReportRequestPath = '/public/reports/protectionSourcesJobsSummary';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get the list of all the installed agents which includes the health status and
   * upgradability of the agent.
   * @param params The `ReportsService.GetAgentDeploymentReportParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `outputFormat`: Specifies the format for the output such as 'csv' or 'json'.
   *   If not specified, the json format is returned.
   *   If 'csv' is specified, a comma-separated list with a heading
   *   row is returned.
   *
   * - `hostOsType`: Specifies the host type on which the Cohesity agent is installed.
   *   Setting this parameter will filter the response based on host OS type on
   *   which agent is running.
   *   'kLinux' indicates the Linux operating system.
   *   'kWindows' indicates the Microsoft Windows operating system.
   *   'kAix' indicates the IBM AIX operating system.
   *   'kSolaris' indicates the Oracle Solaris operating system.
   *   'kSapHana' indicates the Sap Hana database system developed by SAP SE.
   *   'kOther' indicates the other types of operating system.
   *
   * - `healthStatus`: Specifies the health status of the Cohesity agent. Setting this parameter
   *   will filter the response based on agent health status.
   *   Specifies the status of the agent running on a physical source.
   *   'kUnknown' indicates the Agent is not known. No attempt to connect
   *   to the Agent has occurred.
   *   'kUnreachable' indicates the Agent is not reachable.
   *   'kHealthy' indicates the Agent is healthy.
   *   'kDegraded' indicates the Agent is running but in a degraded state.
   *
   * - `compactVersion`: Specifies the compact version of Cohesity agent. For example, 6.0.1.
   *   Setting this parameter will filter the response based on installed agent
   *   version.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetAgentDeploymentReportResponse(params: ReportsService.GetAgentDeploymentReportParams): __Observable<__StrictHttpResponse<Array<AgentDeploymentStatusResponse>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.outputFormat != null) __params = __params.set('outputFormat', params.outputFormat.toString());
    (params.hostOsType || []).forEach(val => {if (val != null) __params = __params.append('hostOsType', val.toString())});
    (params.healthStatus || []).forEach(val => {if (val != null) __params = __params.append('healthStatus', val.toString())});
    if (params.compactVersion != null) __params = __params.set('compactVersion', params.compactVersion.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/reports/agents`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AgentDeploymentStatusResponse>>;
      })
    );
  }
  /**
   * Get the list of all the installed agents which includes the health status and
   * upgradability of the agent.
   * @param params The `ReportsService.GetAgentDeploymentReportParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `outputFormat`: Specifies the format for the output such as 'csv' or 'json'.
   *   If not specified, the json format is returned.
   *   If 'csv' is specified, a comma-separated list with a heading
   *   row is returned.
   *
   * - `hostOsType`: Specifies the host type on which the Cohesity agent is installed.
   *   Setting this parameter will filter the response based on host OS type on
   *   which agent is running.
   *   'kLinux' indicates the Linux operating system.
   *   'kWindows' indicates the Microsoft Windows operating system.
   *   'kAix' indicates the IBM AIX operating system.
   *   'kSolaris' indicates the Oracle Solaris operating system.
   *   'kSapHana' indicates the Sap Hana database system developed by SAP SE.
   *   'kOther' indicates the other types of operating system.
   *
   * - `healthStatus`: Specifies the health status of the Cohesity agent. Setting this parameter
   *   will filter the response based on agent health status.
   *   Specifies the status of the agent running on a physical source.
   *   'kUnknown' indicates the Agent is not known. No attempt to connect
   *   to the Agent has occurred.
   *   'kUnreachable' indicates the Agent is not reachable.
   *   'kHealthy' indicates the Agent is healthy.
   *   'kDegraded' indicates the Agent is running but in a degraded state.
   *
   * - `compactVersion`: Specifies the compact version of Cohesity agent. For example, 6.0.1.
   *   Setting this parameter will filter the response based on installed agent
   *   version.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetAgentDeploymentReport(params: ReportsService.GetAgentDeploymentReportParams): __Observable<Array<AgentDeploymentStatusResponse>> {
    return this.GetAgentDeploymentReportResponse(params).pipe(
      __map(_r => _r.body as Array<AgentDeploymentStatusResponse>)
    );
  }

  /**
   * Get summary statistics about transferring data from Vaults
   * (External Targets) to this Cohesity Cluster.
   *
   * A Vault can provide an additional Cloud Tier where cold data of the
   * Cohesity Cluster is stored in the Cloud.
   * A Vault can also provide archive storage for backup data.
   * This archive data is stored on Tapes and in Cloud Vaults.
   * @param params The `ReportsService.GetDataTransferFromVaultsReportRequestParams` containing the following parameters:
   *
   * - `vaultIds`: Filter by a list of Vault ids.
   *
   * - `startTimeMsecs`: Filter by a start time. Specify the start time as a Unix epoch Timestamp
   *   (in milliseconds).
   *   If startTimeMsecs and endTimeMsecs are not specified,
   *   the time period is the last 7 days.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `outputFormat`: Specifies the format for the output such as 'csv' or 'json'.
   *   If not specified, the json format is returned.
   *   If 'csv' is specified, a comma-separated list with a heading
   *   row is returned.
   *
   * - `groupBy`: Specifies wheather the report should be grouped by target when scheduled
   *   or downloaded. If not set or set to false, report is grouped by protection
   *   jobs. It is ignored if outformat is not "csv" and response contains whole
   *   report.
   *
   * - `endTimeMsecs`: Filter by end time. Specify the end time as a Unix epoch Timestamp
   *   (in milliseconds).
   *   If startTimeMsecs and endTimeMsecs are not specified,
   *   the time period is the last 7 days.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetDataTransferFromVaultsReportRequestResponse(params: ReportsService.GetDataTransferFromVaultsReportRequestParams): __Observable<__StrictHttpResponse<DataTransferFromVaultsSummaryResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.vaultIds || []).forEach(val => {if (val != null) __params = __params.append('vaultIds', val.toString())});
    if (params.startTimeMsecs != null) __params = __params.set('startTimeMsecs', params.startTimeMsecs.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.outputFormat != null) __params = __params.set('outputFormat', params.outputFormat.toString());
    if (params.groupBy != null) __params = __params.set('groupBy', params.groupBy.toString());
    if (params.endTimeMsecs != null) __params = __params.set('endTimeMsecs', params.endTimeMsecs.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/reports/dataTransferFromVaults`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DataTransferFromVaultsSummaryResponse>;
      })
    );
  }
  /**
   * Get summary statistics about transferring data from Vaults
   * (External Targets) to this Cohesity Cluster.
   *
   * A Vault can provide an additional Cloud Tier where cold data of the
   * Cohesity Cluster is stored in the Cloud.
   * A Vault can also provide archive storage for backup data.
   * This archive data is stored on Tapes and in Cloud Vaults.
   * @param params The `ReportsService.GetDataTransferFromVaultsReportRequestParams` containing the following parameters:
   *
   * - `vaultIds`: Filter by a list of Vault ids.
   *
   * - `startTimeMsecs`: Filter by a start time. Specify the start time as a Unix epoch Timestamp
   *   (in milliseconds).
   *   If startTimeMsecs and endTimeMsecs are not specified,
   *   the time period is the last 7 days.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `outputFormat`: Specifies the format for the output such as 'csv' or 'json'.
   *   If not specified, the json format is returned.
   *   If 'csv' is specified, a comma-separated list with a heading
   *   row is returned.
   *
   * - `groupBy`: Specifies wheather the report should be grouped by target when scheduled
   *   or downloaded. If not set or set to false, report is grouped by protection
   *   jobs. It is ignored if outformat is not "csv" and response contains whole
   *   report.
   *
   * - `endTimeMsecs`: Filter by end time. Specify the end time as a Unix epoch Timestamp
   *   (in milliseconds).
   *   If startTimeMsecs and endTimeMsecs are not specified,
   *   the time period is the last 7 days.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetDataTransferFromVaultsReportRequest(params: ReportsService.GetDataTransferFromVaultsReportRequestParams): __Observable<DataTransferFromVaultsSummaryResponse> {
    return this.GetDataTransferFromVaultsReportRequestResponse(params).pipe(
      __map(_r => _r.body as DataTransferFromVaultsSummaryResponse)
    );
  }

  /**
   * Get summary statistics about transferring data from the
   * Cohesity Cluster to Vaults (External Targets).
   *
   * A Vault can provide an additional Cloud Tier where cold data of the
   * Cohesity Cluster can be stored in the Cloud.
   * A Vault can also provide archive storage for backup data.
   * This archive data is stored on Tapes and in Cloud Vaults.
   * @param params The `ReportsService.GetDataTransferToVaultsReportRequestParams` containing the following parameters:
   *
   * - `vaultIds`: Filter by a list of Vault ids.
   *
   * - `startTimeMsecs`: Filter by a start time. Specify the start time as a Unix epoch Timestamp
   *   (in milliseconds).
   *   If startTimeMsecs and endTimeMsecs are not specified,
   *   the time period is the last 7 days.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `outputFormat`: Specifies the format for the output such as 'csv' or 'json'.
   *   If not specified, the json format is returned.
   *   If 'csv' is specified, a comma-separated list with a heading
   *   row is returned.
   *
   * - `groupBy`: Specifies wheather the report should be grouped by target when scheduled
   *   or downloaded. If not set or set to false, report is grouped by protection
   *   jobs. It is ignored if outformat is not "csv" and response contains whole
   *   report.
   *
   * - `endTimeMsecs`: Filter by end time. Specify the end time as a Unix epoch Timestamp
   *   (in milliseconds).
   *   If startTimeMsecs and endTimeMsecs are not specified,
   *   the time period is the last 7 days.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetDataTransferToVaultsReportRequestResponse(params: ReportsService.GetDataTransferToVaultsReportRequestParams): __Observable<__StrictHttpResponse<DataTransferToVaultsSummaryResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.vaultIds || []).forEach(val => {if (val != null) __params = __params.append('vaultIds', val.toString())});
    if (params.startTimeMsecs != null) __params = __params.set('startTimeMsecs', params.startTimeMsecs.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.outputFormat != null) __params = __params.set('outputFormat', params.outputFormat.toString());
    if (params.groupBy != null) __params = __params.set('groupBy', params.groupBy.toString());
    if (params.endTimeMsecs != null) __params = __params.set('endTimeMsecs', params.endTimeMsecs.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/reports/dataTransferToVaults`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DataTransferToVaultsSummaryResponse>;
      })
    );
  }
  /**
   * Get summary statistics about transferring data from the
   * Cohesity Cluster to Vaults (External Targets).
   *
   * A Vault can provide an additional Cloud Tier where cold data of the
   * Cohesity Cluster can be stored in the Cloud.
   * A Vault can also provide archive storage for backup data.
   * This archive data is stored on Tapes and in Cloud Vaults.
   * @param params The `ReportsService.GetDataTransferToVaultsReportRequestParams` containing the following parameters:
   *
   * - `vaultIds`: Filter by a list of Vault ids.
   *
   * - `startTimeMsecs`: Filter by a start time. Specify the start time as a Unix epoch Timestamp
   *   (in milliseconds).
   *   If startTimeMsecs and endTimeMsecs are not specified,
   *   the time period is the last 7 days.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `outputFormat`: Specifies the format for the output such as 'csv' or 'json'.
   *   If not specified, the json format is returned.
   *   If 'csv' is specified, a comma-separated list with a heading
   *   row is returned.
   *
   * - `groupBy`: Specifies wheather the report should be grouped by target when scheduled
   *   or downloaded. If not set or set to false, report is grouped by protection
   *   jobs. It is ignored if outformat is not "csv" and response contains whole
   *   report.
   *
   * - `endTimeMsecs`: Filter by end time. Specify the end time as a Unix epoch Timestamp
   *   (in milliseconds).
   *   If startTimeMsecs and endTimeMsecs are not specified,
   *   the time period is the last 7 days.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetDataTransferToVaultsReportRequest(params: ReportsService.GetDataTransferToVaultsReportRequestParams): __Observable<DataTransferToVaultsSummaryResponse> {
    return this.GetDataTransferToVaultsReportRequestResponse(params).pipe(
      __map(_r => _r.body as DataTransferToVaultsSummaryResponse)
    );
  }

  /**
   * Get gdpr report related information.
   *
   * Returns the GDPR report information.
   * @param params The `ReportsService.GetGdprReportParams` containing the following parameters:
   *
   * - `startTimeUsecs`: Specifies the start time for the audit logs as a Unix epoch Timestamp
   *   (in microseconds).
   *
   * - `search`: Specifies the search string for the audit logs.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `parentSourceId`: Specifies the parent sources of objects for which to get info for.
   *
   * - `outputFormat`: Specifies the format for the output such as 'csv' or 'json'.
   *   If not specified, the json format is returned.
   *   If 'csv' is specified, a comma-separated list with a heading
   *   row is returned.
   *
   * - `id`: Specifies the objects for which to get the gdpr information.
   *
   * - `endTimeUsecs`: Specifies the end time for the audit logsas a Unix epoch Timestamp
   *   (in microseconds).
   *
   * - `actions`: Specifies the action for the audit logs.
   *
   * - `accessibleUsers`: Specifies the users for which to get the accessible objects.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return GetGdprReportResponse defines the response for GetGdprReport.
   */
  GetGdprReportResponse(params: ReportsService.GetGdprReportParams): __Observable<__StrictHttpResponse<Array<ObjectInformation>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startTimeUsecs != null) __params = __params.set('startTimeUsecs', params.startTimeUsecs.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.parentSourceId || []).forEach(val => {if (val != null) __params = __params.append('parentSourceId', val.toString())});
    if (params.outputFormat != null) __params = __params.set('outputFormat', params.outputFormat.toString());
    (params.id || []).forEach(val => {if (val != null) __params = __params.append('id', val.toString())});
    if (params.endTimeUsecs != null) __params = __params.set('endTimeUsecs', params.endTimeUsecs.toString());
    (params.actions || []).forEach(val => {if (val != null) __params = __params.append('actions', val.toString())});
    (params.accessibleUsers || []).forEach(val => {if (val != null) __params = __params.append('accessibleUsers', val.toString())});
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/reports/gdpr`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ObjectInformation>>;
      })
    );
  }
  /**
   * Get gdpr report related information.
   *
   * Returns the GDPR report information.
   * @param params The `ReportsService.GetGdprReportParams` containing the following parameters:
   *
   * - `startTimeUsecs`: Specifies the start time for the audit logs as a Unix epoch Timestamp
   *   (in microseconds).
   *
   * - `search`: Specifies the search string for the audit logs.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `parentSourceId`: Specifies the parent sources of objects for which to get info for.
   *
   * - `outputFormat`: Specifies the format for the output such as 'csv' or 'json'.
   *   If not specified, the json format is returned.
   *   If 'csv' is specified, a comma-separated list with a heading
   *   row is returned.
   *
   * - `id`: Specifies the objects for which to get the gdpr information.
   *
   * - `endTimeUsecs`: Specifies the end time for the audit logsas a Unix epoch Timestamp
   *   (in microseconds).
   *
   * - `actions`: Specifies the action for the audit logs.
   *
   * - `accessibleUsers`: Specifies the users for which to get the accessible objects.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return GetGdprReportResponse defines the response for GetGdprReport.
   */
  GetGdprReport(params: ReportsService.GetGdprReportParams): __Observable<Array<ObjectInformation>> {
    return this.GetGdprReportResponse(params).pipe(
      __map(_r => _r.body as Array<ObjectInformation>)
    );
  }

  /**
   * Get protected objects trends grouped by days/weeks/months.
   *
   * This gives a summary of protection trend for protected resources during the
   * given time range.
   * If no roll up is specified, then the trends will be grouped by days.
   * @param params The `ReportsService.GetProtectedObjectsTrendsReportRequestParams` containing the following parameters:
   *
   * - `timezone`: Specifies the timezone to use when calculating day/week/month
   *   Specify the timezone in the following format: "Area/Location",
   *   for example: "America/New_York".
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which objects are to be
   *   returned.
   *
   * - `startTimeUsecs`: Filter by a start time. Snapshot summary statistics
   *   for Job Runs that started after the specified time are reported.
   *   Specify the start time as a Unix epoch Timestamp (in microseconds).
   *
   * - `rollup`: Roll up type for grouping. Valid values are day, week, month
   *
   * - `registeredSourceId`: Specifies an id of a top level Registered Source such as
   *   a vCenter Server. If specified, Snapshot summary statistics for all
   *   the leaf Protection Sources (such as VMs) that are children of this
   *   Registered Source are reported.
   *   NOTE: If specified, filtering by other fields is not supported.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `protectedObjectIds`: Filter by a list of leaf Protection Sources Objects (such as VMs).
   *   Snapshot summary statistics for the listed Protection Source Objects
   *   are reported.
   *
   * - `jobIds`: Filter by a list of Job ids. Snapshots summary statistics
   *   for the specified Protection Jobs are reported.
   *
   * - `environments`: Filter by a list of environment types such as 'kVMware', 'kView', etc.
   *   Supported environment types such as 'kView', 'kSQL', 'kVMware', etc.
   *   NOTE: 'kPuppeteer' refers to Cohesity's Remote Adapter.
   *   'kVMware' indicates the VMware Protection Source environment.
   *   'kHyperV' indicates the HyperV Protection Source environment.
   *   'kSQL' indicates the SQL Protection Source environment.
   *   'kView' indicates the View Protection Source environment.
   *   'kPuppeteer' indicates the Cohesity's Remote Adapter.
   *   'kPhysical' indicates the physical Protection Source environment.
   *   'kPure' indicates the Pure Storage Protection Source environment.
   *   'Nimble' indicates the Nimble Storage Protection Source environment.
   *   'kHpe3Par' indicates the Hpe 3Par Storage Protection Source environment.
   *   'kAzure' indicates the Microsoft's Azure Protection Source environment.
   *   'kNetapp' indicates the Netapp Protection Source environment.
   *   'kAgent' indicates the Agent Protection Source environment.
   *   'kGenericNas' indicates the Generic Network Attached Storage Protection
   *   Source environment.
   *   'kAcropolis' indicates the Acropolis Protection Source environment.
   *   'kPhsicalFiles' indicates the Physical Files Protection Source environment.
   *   'kIsilon' indicates the Dell EMC's Isilon Protection Source environment.
   *   'kGPFS' indicates IBM's GPFS Protection Source environment.
   *   'kKVM' indicates the KVM Protection Source environment.
   *   'kAWS' indicates the AWS Protection Source environment.
   *   'kExchange' indicates the Exchange Protection Source environment.
   *   'kHyperVVSS' indicates the HyperV VSS Protection Source
   *   environment.
   *   'kOracle' indicates the Oracle Protection Source environment.
   *   'kGCP' indicates the Google Cloud Platform Protection Source environment.
   *   'kFlashBlade' indicates the Flash Blade Protection Source environment.
   *   'kAWSNative' indicates the AWS Native Protection Source environment.
   *   'kO365' indicates the Office 365 Protection Source environment.
   *   'kO365Outlook' indicates Office 365 outlook Protection Source environment.
   *   'kHyperFlex' indicates the Hyper Flex Protection Source environment.
   *   'kGCPNative' indicates the GCP Native Protection Source environment.
   *   'kAzureNative' indicates the Azure Native Protection Source environment.
   *   'kKubernetes' indicates a Kubernetes Protection Source environment.
   *   'kElastifile' indicates Elastifile Protection Source environment.
   *   'kAD' indicates Active Directory Protection Source environment.
   *   'kRDSSnapshotManager' indicates AWS RDS Protection Source environment.
   *   'kCassandra' indicates Cassandra Protection Source environment.
   *   'kMongoDB' indicates MongoDB Protection Source environment.
   *   'kCouchbase' indicates Couchbase Protection Source environment.
   *   'kHdfs' indicates Hdfs Protection Source environment.
   *   'kHive' indicates Hive Protection Source environment.
   *   'kHBase' indicates HBase Protection Source environment.
   *
   * - `endTimeUsecs`: Filter by end time. Snapshot summary statistics
   *   for Job Runs that ended before the specified time are returned.
   *   Specify the end time as a Unix epoch Timestamp (in microseconds).
   *
   * - `allUnderHierarchy`: AllUnderHierarchy specifies if objects of all the tenants under the
   *   hierarchy of the logged in user's organization should be returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetProtectedObjectsTrendsReportRequestResponse(params: ReportsService.GetProtectedObjectsTrendsReportRequestParams): __Observable<__StrictHttpResponse<Array<ProtectionTrend>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.timezone != null) __params = __params.set('timezone', params.timezone.toString());
    (params.tenantIds || []).forEach(val => {if (val != null) __params = __params.append('tenantIds', val.toString())});
    if (params.startTimeUsecs != null) __params = __params.set('startTimeUsecs', params.startTimeUsecs.toString());
    if (params.rollup != null) __params = __params.set('rollup', params.rollup.toString());
    if (params.registeredSourceId != null) __params = __params.set('registeredSourceId', params.registeredSourceId.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.protectedObjectIds || []).forEach(val => {if (val != null) __params = __params.append('protectedObjectIds', val.toString())});
    (params.jobIds || []).forEach(val => {if (val != null) __params = __params.append('jobIds', val.toString())});
    (params.environments || []).forEach(val => {if (val != null) __params = __params.append('environments', val.toString())});
    if (params.endTimeUsecs != null) __params = __params.set('endTimeUsecs', params.endTimeUsecs.toString());
    if (params.allUnderHierarchy != null) __params = __params.set('allUnderHierarchy', params.allUnderHierarchy.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/reports/protectedObjectsTrends`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ProtectionTrend>>;
      })
    );
  }
  /**
   * Get protected objects trends grouped by days/weeks/months.
   *
   * This gives a summary of protection trend for protected resources during the
   * given time range.
   * If no roll up is specified, then the trends will be grouped by days.
   * @param params The `ReportsService.GetProtectedObjectsTrendsReportRequestParams` containing the following parameters:
   *
   * - `timezone`: Specifies the timezone to use when calculating day/week/month
   *   Specify the timezone in the following format: "Area/Location",
   *   for example: "America/New_York".
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which objects are to be
   *   returned.
   *
   * - `startTimeUsecs`: Filter by a start time. Snapshot summary statistics
   *   for Job Runs that started after the specified time are reported.
   *   Specify the start time as a Unix epoch Timestamp (in microseconds).
   *
   * - `rollup`: Roll up type for grouping. Valid values are day, week, month
   *
   * - `registeredSourceId`: Specifies an id of a top level Registered Source such as
   *   a vCenter Server. If specified, Snapshot summary statistics for all
   *   the leaf Protection Sources (such as VMs) that are children of this
   *   Registered Source are reported.
   *   NOTE: If specified, filtering by other fields is not supported.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `protectedObjectIds`: Filter by a list of leaf Protection Sources Objects (such as VMs).
   *   Snapshot summary statistics for the listed Protection Source Objects
   *   are reported.
   *
   * - `jobIds`: Filter by a list of Job ids. Snapshots summary statistics
   *   for the specified Protection Jobs are reported.
   *
   * - `environments`: Filter by a list of environment types such as 'kVMware', 'kView', etc.
   *   Supported environment types such as 'kView', 'kSQL', 'kVMware', etc.
   *   NOTE: 'kPuppeteer' refers to Cohesity's Remote Adapter.
   *   'kVMware' indicates the VMware Protection Source environment.
   *   'kHyperV' indicates the HyperV Protection Source environment.
   *   'kSQL' indicates the SQL Protection Source environment.
   *   'kView' indicates the View Protection Source environment.
   *   'kPuppeteer' indicates the Cohesity's Remote Adapter.
   *   'kPhysical' indicates the physical Protection Source environment.
   *   'kPure' indicates the Pure Storage Protection Source environment.
   *   'Nimble' indicates the Nimble Storage Protection Source environment.
   *   'kHpe3Par' indicates the Hpe 3Par Storage Protection Source environment.
   *   'kAzure' indicates the Microsoft's Azure Protection Source environment.
   *   'kNetapp' indicates the Netapp Protection Source environment.
   *   'kAgent' indicates the Agent Protection Source environment.
   *   'kGenericNas' indicates the Generic Network Attached Storage Protection
   *   Source environment.
   *   'kAcropolis' indicates the Acropolis Protection Source environment.
   *   'kPhsicalFiles' indicates the Physical Files Protection Source environment.
   *   'kIsilon' indicates the Dell EMC's Isilon Protection Source environment.
   *   'kGPFS' indicates IBM's GPFS Protection Source environment.
   *   'kKVM' indicates the KVM Protection Source environment.
   *   'kAWS' indicates the AWS Protection Source environment.
   *   'kExchange' indicates the Exchange Protection Source environment.
   *   'kHyperVVSS' indicates the HyperV VSS Protection Source
   *   environment.
   *   'kOracle' indicates the Oracle Protection Source environment.
   *   'kGCP' indicates the Google Cloud Platform Protection Source environment.
   *   'kFlashBlade' indicates the Flash Blade Protection Source environment.
   *   'kAWSNative' indicates the AWS Native Protection Source environment.
   *   'kO365' indicates the Office 365 Protection Source environment.
   *   'kO365Outlook' indicates Office 365 outlook Protection Source environment.
   *   'kHyperFlex' indicates the Hyper Flex Protection Source environment.
   *   'kGCPNative' indicates the GCP Native Protection Source environment.
   *   'kAzureNative' indicates the Azure Native Protection Source environment.
   *   'kKubernetes' indicates a Kubernetes Protection Source environment.
   *   'kElastifile' indicates Elastifile Protection Source environment.
   *   'kAD' indicates Active Directory Protection Source environment.
   *   'kRDSSnapshotManager' indicates AWS RDS Protection Source environment.
   *   'kCassandra' indicates Cassandra Protection Source environment.
   *   'kMongoDB' indicates MongoDB Protection Source environment.
   *   'kCouchbase' indicates Couchbase Protection Source environment.
   *   'kHdfs' indicates Hdfs Protection Source environment.
   *   'kHive' indicates Hive Protection Source environment.
   *   'kHBase' indicates HBase Protection Source environment.
   *
   * - `endTimeUsecs`: Filter by end time. Snapshot summary statistics
   *   for Job Runs that ended before the specified time are returned.
   *   Specify the end time as a Unix epoch Timestamp (in microseconds).
   *
   * - `allUnderHierarchy`: AllUnderHierarchy specifies if objects of all the tenants under the
   *   hierarchy of the logged in user's organization should be returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetProtectedObjectsTrendsReportRequest(params: ReportsService.GetProtectedObjectsTrendsReportRequestParams): __Observable<Array<ProtectionTrend>> {
    return this.GetProtectedObjectsTrendsReportRequestResponse(params).pipe(
      __map(_r => _r.body as Array<ProtectionTrend>)
    );
  }

  /**
   * Get protection details about the specified list of leaf
   * Protection Source Objects (such as a VMs).
   *
   * Returns the Snapshots that contain backups of the specified
   * Protection Source Objects and match the specified filter criteria.
   * @param params The `ReportsService.GetProtectionSourcesJobRunsReportRequestParams` containing the following parameters:
   *
   * - `protectionSourceIds`: Filter by a list of leaf Protection Sources Objects (such as VMs).
   *   Snapshots of the specified Protection Source Objects are returned.
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which objects are to be
   *   returned.
   *
   * - `startTimeUsecs`: Filter by a start time. Snapshots that started after the
   *   specified time are returned.
   *   Specify the start time as a Unix epoch Timestamp (in microseconds).
   *
   * - `runStatus`: Filter by a list of run statuses such as 'kRunning',
   *   'kSuccess', 'kFailure' etc.
   *   Snapshots of Job Runs with the specified run statuses are reported.
   *   'kSuccess' indicates that the Job Run was successful.
   *   'kRunning' indicates that the Job Run is currently running.
   *   'kWarning' indicates that the Job Run was successful but warnings were
   *   issued.
   *   'kCancelled' indicates that the Job Run was canceled.
   *   'kError' indicates the Job Run encountered an error and did not run to
   *   completion.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `pageCount`: Specifies the number of Snapshots to return in the response for
   *   pagination purposes. Used in combination with the paginationCookie in
   *   the response to return multiple sets of Snapshots.
   *
   * - `outputFormat`: Specifies the format for the output such as 'cvs' or 'json'.
   *   If not specified, the json format is returned.
   *   If 'csv' is specified, a comma-separated list with a heading
   *   row is returned.
   *
   * - `jobIds`: Filter by a list of Job ids. Snapshots for the specified
   *   Protection Jobs are listed.
   *
   * - `environments`: Filter by a list of environment types such as 'kVMware', 'kView', etc.
   *   NOTE: 'kPuppeteer' refers to Cohesity's Remote Adapter.
   *
   * - `endTimeUsecs`: Filter by a end time. Snapshots that ended before the
   *   specified time are returned.
   *   Specify the end time as a Unix epoch Timestamp (in microseconds).
   *
   * - `allUnderHierarchy`: AllUnderHierarchy specifies if objects of all the tenants under the
   *   hierarchy of the logged in user's organization should be returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetProtectionSourcesJobRunsReportRequestResponse(params: ReportsService.GetProtectionSourcesJobRunsReportRequestParams): __Observable<__StrictHttpResponse<Array<ProtectionSourcesJobRunsReportElement>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.protectionSourceIds || []).forEach(val => {if (val != null) __params = __params.append('protectionSourceIds', val.toString())});
    (params.tenantIds || []).forEach(val => {if (val != null) __params = __params.append('tenantIds', val.toString())});
    if (params.startTimeUsecs != null) __params = __params.set('startTimeUsecs', params.startTimeUsecs.toString());
    (params.runStatus || []).forEach(val => {if (val != null) __params = __params.append('runStatus', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.pageCount != null) __params = __params.set('pageCount', params.pageCount.toString());
    if (params.outputFormat != null) __params = __params.set('outputFormat', params.outputFormat.toString());
    (params.jobIds || []).forEach(val => {if (val != null) __params = __params.append('jobIds', val.toString())});
    (params.environments || []).forEach(val => {if (val != null) __params = __params.append('environments', val.toString())});
    if (params.endTimeUsecs != null) __params = __params.set('endTimeUsecs', params.endTimeUsecs.toString());
    if (params.allUnderHierarchy != null) __params = __params.set('allUnderHierarchy', params.allUnderHierarchy.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/reports/protectionSourcesJobRuns`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ProtectionSourcesJobRunsReportElement>>;
      })
    );
  }
  /**
   * Get protection details about the specified list of leaf
   * Protection Source Objects (such as a VMs).
   *
   * Returns the Snapshots that contain backups of the specified
   * Protection Source Objects and match the specified filter criteria.
   * @param params The `ReportsService.GetProtectionSourcesJobRunsReportRequestParams` containing the following parameters:
   *
   * - `protectionSourceIds`: Filter by a list of leaf Protection Sources Objects (such as VMs).
   *   Snapshots of the specified Protection Source Objects are returned.
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which objects are to be
   *   returned.
   *
   * - `startTimeUsecs`: Filter by a start time. Snapshots that started after the
   *   specified time are returned.
   *   Specify the start time as a Unix epoch Timestamp (in microseconds).
   *
   * - `runStatus`: Filter by a list of run statuses such as 'kRunning',
   *   'kSuccess', 'kFailure' etc.
   *   Snapshots of Job Runs with the specified run statuses are reported.
   *   'kSuccess' indicates that the Job Run was successful.
   *   'kRunning' indicates that the Job Run is currently running.
   *   'kWarning' indicates that the Job Run was successful but warnings were
   *   issued.
   *   'kCancelled' indicates that the Job Run was canceled.
   *   'kError' indicates the Job Run encountered an error and did not run to
   *   completion.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `pageCount`: Specifies the number of Snapshots to return in the response for
   *   pagination purposes. Used in combination with the paginationCookie in
   *   the response to return multiple sets of Snapshots.
   *
   * - `outputFormat`: Specifies the format for the output such as 'cvs' or 'json'.
   *   If not specified, the json format is returned.
   *   If 'csv' is specified, a comma-separated list with a heading
   *   row is returned.
   *
   * - `jobIds`: Filter by a list of Job ids. Snapshots for the specified
   *   Protection Jobs are listed.
   *
   * - `environments`: Filter by a list of environment types such as 'kVMware', 'kView', etc.
   *   NOTE: 'kPuppeteer' refers to Cohesity's Remote Adapter.
   *
   * - `endTimeUsecs`: Filter by a end time. Snapshots that ended before the
   *   specified time are returned.
   *   Specify the end time as a Unix epoch Timestamp (in microseconds).
   *
   * - `allUnderHierarchy`: AllUnderHierarchy specifies if objects of all the tenants under the
   *   hierarchy of the logged in user's organization should be returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetProtectionSourcesJobRunsReportRequest(params: ReportsService.GetProtectionSourcesJobRunsReportRequestParams): __Observable<Array<ProtectionSourcesJobRunsReportElement>> {
    return this.GetProtectionSourcesJobRunsReportRequestResponse(params).pipe(
      __map(_r => _r.body as Array<ProtectionSourcesJobRunsReportElement>)
    );
  }

  /**
   * Get Job Run (Snapshot) summary statistics about the leaf Protection Sources
   * Objects that match the specified filter criteria.
   *
   * For example, if two Job ids are passed in, Snapshot summary statistics about
   * all the leaf Objects that have been protected by the two specified
   * Jobs are reported.
   * For example, if a top level registered Source id is passed in, summary
   * statistics about all the Snapshots backing up leaf Objects in
   * the specified Source are reported.
   * @param params The `ReportsService.GetProtectionSourcesJobsSummaryReportRequestParams` containing the following parameters:
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which objects are to be
   *   returned.
   *
   * - `statuses`: Filter by a list of run statuses.
   *   'kSuccess' indicates that the Job Run was successful.
   *   'kRunning' indicates that the Job Run is currently running.
   *   'kWarning' indicates that the Job Run was successful but warnings were
   *   issued.
   *   'kCancelled' indicates that the Job Run was canceled.
   *   'kError' indicates the Job Run encountered an error and did not run to
   *   completion.
   *
   * - `startTimeUsecs`: Filter by a start time. Snapshot summary statistics
   *   for Job Runs that started after the specified time are reported.
   *   Specify the start time as a Unix epoch Timestamp (in microseconds).
   *
   * - `reportType`: Specifies the report type that will be used to set the right label &
   *   subject line for the report when downloaded or emailed because same API is
   *   used for 3 reports currently
   *   1. kAvailableLocalSnapshotsReport
   *   2. kFailedObjectsReport
   *   3. kProtectionSummaryByObjectTypeReport
   *
   * - `reportName`: Specifies the custom report name the user wants to set for this report.
   *
   * - `registeredSourceId`: Specifies an id of a top level Registered Source such as
   *   a vCenter Server. If specified, Snapshot summary statistics for all
   *   the leaf Protection Sources (such as VMs) that are children of this
   *   Registered Source are reported.
   *   NOTE: If specified, filtering by other fields is not supported.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `protectionSourceIds`: Filter by a list of leaf Protection Sources Objects (such as VMs).
   *   Snapshot summary statistics for the listed Protection Source Objects
   *   are reported.
   *
   * - `outputFormat`: Specifies the format for the output such as 'csv' or 'json'.
   *   If not specified, the json format is returned.
   *   If 'csv' is specified, a comma-separated list with a heading
   *   row is returned.
   *
   * - `jobIds`: Filter by a list of Job ids. Snapshots summary statistics
   *   for the specified Protection Jobs are reported.
   *
   * - `environments`: Filter by a list of environment types such as 'kVMware', 'kView', etc.
   *   NOTE: 'kPuppeteer' refers to Cohesity's Remote Adapter.
   *
   * - `endTimeUsecs`: Filter by end time. Snapshot summary statistics
   *   for Job Runs that ended before the specified time are returned.
   *   Specify the end time as a Unix epoch Timestamp (in microseconds).
   *
   * - `consecutiveFailures`: Filters out those jobs which have number of consecutive run failures less
   *   than consecutiveFailures.
   *
   * - `allUnderHierarchy`: AllUnderHierarchy specifies if objects of all the tenants under the
   *   hierarchy of the logged in user's organization should be returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetProtectionSourcesJobsSummaryReportRequestResponse(params: ReportsService.GetProtectionSourcesJobsSummaryReportRequestParams): __Observable<__StrictHttpResponse<ProtectionSourcesJobsSummaryReportResponseWrapper>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.tenantIds || []).forEach(val => {if (val != null) __params = __params.append('tenantIds', val.toString())});
    (params.statuses || []).forEach(val => {if (val != null) __params = __params.append('statuses', val.toString())});
    if (params.startTimeUsecs != null) __params = __params.set('startTimeUsecs', params.startTimeUsecs.toString());
    if (params.reportType != null) __params = __params.set('reportType', params.reportType.toString());
    if (params.reportName != null) __params = __params.set('reportName', params.reportName.toString());
    if (params.registeredSourceId != null) __params = __params.set('registeredSourceId', params.registeredSourceId.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.protectionSourceIds || []).forEach(val => {if (val != null) __params = __params.append('protectionSourceIds', val.toString())});
    if (params.outputFormat != null) __params = __params.set('outputFormat', params.outputFormat.toString());
    (params.jobIds || []).forEach(val => {if (val != null) __params = __params.append('jobIds', val.toString())});
    (params.environments || []).forEach(val => {if (val != null) __params = __params.append('environments', val.toString())});
    if (params.endTimeUsecs != null) __params = __params.set('endTimeUsecs', params.endTimeUsecs.toString());
    if (params.consecutiveFailures != null) __params = __params.set('consecutiveFailures', params.consecutiveFailures.toString());
    if (params.allUnderHierarchy != null) __params = __params.set('allUnderHierarchy', params.allUnderHierarchy.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/reports/protectionSourcesJobsSummary`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ProtectionSourcesJobsSummaryReportResponseWrapper>;
      })
    );
  }
  /**
   * Get Job Run (Snapshot) summary statistics about the leaf Protection Sources
   * Objects that match the specified filter criteria.
   *
   * For example, if two Job ids are passed in, Snapshot summary statistics about
   * all the leaf Objects that have been protected by the two specified
   * Jobs are reported.
   * For example, if a top level registered Source id is passed in, summary
   * statistics about all the Snapshots backing up leaf Objects in
   * the specified Source are reported.
   * @param params The `ReportsService.GetProtectionSourcesJobsSummaryReportRequestParams` containing the following parameters:
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which objects are to be
   *   returned.
   *
   * - `statuses`: Filter by a list of run statuses.
   *   'kSuccess' indicates that the Job Run was successful.
   *   'kRunning' indicates that the Job Run is currently running.
   *   'kWarning' indicates that the Job Run was successful but warnings were
   *   issued.
   *   'kCancelled' indicates that the Job Run was canceled.
   *   'kError' indicates the Job Run encountered an error and did not run to
   *   completion.
   *
   * - `startTimeUsecs`: Filter by a start time. Snapshot summary statistics
   *   for Job Runs that started after the specified time are reported.
   *   Specify the start time as a Unix epoch Timestamp (in microseconds).
   *
   * - `reportType`: Specifies the report type that will be used to set the right label &
   *   subject line for the report when downloaded or emailed because same API is
   *   used for 3 reports currently
   *   1. kAvailableLocalSnapshotsReport
   *   2. kFailedObjectsReport
   *   3. kProtectionSummaryByObjectTypeReport
   *
   * - `reportName`: Specifies the custom report name the user wants to set for this report.
   *
   * - `registeredSourceId`: Specifies an id of a top level Registered Source such as
   *   a vCenter Server. If specified, Snapshot summary statistics for all
   *   the leaf Protection Sources (such as VMs) that are children of this
   *   Registered Source are reported.
   *   NOTE: If specified, filtering by other fields is not supported.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `protectionSourceIds`: Filter by a list of leaf Protection Sources Objects (such as VMs).
   *   Snapshot summary statistics for the listed Protection Source Objects
   *   are reported.
   *
   * - `outputFormat`: Specifies the format for the output such as 'csv' or 'json'.
   *   If not specified, the json format is returned.
   *   If 'csv' is specified, a comma-separated list with a heading
   *   row is returned.
   *
   * - `jobIds`: Filter by a list of Job ids. Snapshots summary statistics
   *   for the specified Protection Jobs are reported.
   *
   * - `environments`: Filter by a list of environment types such as 'kVMware', 'kView', etc.
   *   NOTE: 'kPuppeteer' refers to Cohesity's Remote Adapter.
   *
   * - `endTimeUsecs`: Filter by end time. Snapshot summary statistics
   *   for Job Runs that ended before the specified time are returned.
   *   Specify the end time as a Unix epoch Timestamp (in microseconds).
   *
   * - `consecutiveFailures`: Filters out those jobs which have number of consecutive run failures less
   *   than consecutiveFailures.
   *
   * - `allUnderHierarchy`: AllUnderHierarchy specifies if objects of all the tenants under the
   *   hierarchy of the logged in user's organization should be returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetProtectionSourcesJobsSummaryReportRequest(params: ReportsService.GetProtectionSourcesJobsSummaryReportRequestParams): __Observable<ProtectionSourcesJobsSummaryReportResponseWrapper> {
    return this.GetProtectionSourcesJobsSummaryReportRequestResponse(params).pipe(
      __map(_r => _r.body as ProtectionSourcesJobsSummaryReportResponseWrapper)
    );
  }
}

module ReportsService {

  /**
   * Parameters for GetAgentDeploymentReport
   */
  export interface GetAgentDeploymentReportParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Specifies the format for the output such as 'csv' or 'json'.
     * If not specified, the json format is returned.
     * If 'csv' is specified, a comma-separated list with a heading
     * row is returned.
     */
    outputFormat?: string;

    /**
     * Specifies the host type on which the Cohesity agent is installed.
     * Setting this parameter will filter the response based on host OS type on
     * which agent is running.
     * 'kLinux' indicates the Linux operating system.
     * 'kWindows' indicates the Microsoft Windows operating system.
     * 'kAix' indicates the IBM AIX operating system.
     * 'kSolaris' indicates the Oracle Solaris operating system.
     * 'kSapHana' indicates the Sap Hana database system developed by SAP SE.
     * 'kOther' indicates the other types of operating system.
     */
    hostOsType?: Array<'kLinux' | 'kWindows' | 'kAix' | 'kSolaris' | 'kSapHana' | 'kOther'>;

    /**
     * Specifies the health status of the Cohesity agent. Setting this parameter
     * will filter the response based on agent health status.
     * Specifies the status of the agent running on a physical source.
     * 'kUnknown' indicates the Agent is not known. No attempt to connect
     * to the Agent has occurred.
     * 'kUnreachable' indicates the Agent is not reachable.
     * 'kHealthy' indicates the Agent is healthy.
     * 'kDegraded' indicates the Agent is running but in a degraded state.
     */
    healthStatus?: Array<'kUnknown' | 'kUnreachable' | 'kHealthy' | 'kDegraded'>;

    /**
     * Specifies the compact version of Cohesity agent. For example, 6.0.1.
     * Setting this parameter will filter the response based on installed agent
     * version.
     */
    compactVersion?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetDataTransferFromVaultsReportRequest
   */
  export interface GetDataTransferFromVaultsReportRequestParams {

    /**
     * Filter by a list of Vault ids.
     */
    vaultIds: Array<number>;

    /**
     * Filter by a start time. Specify the start time as a Unix epoch Timestamp
     * (in milliseconds).
     * If startTimeMsecs and endTimeMsecs are not specified,
     * the time period is the last 7 days.
     */
    startTimeMsecs?: number;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Specifies the format for the output such as 'csv' or 'json'.
     * If not specified, the json format is returned.
     * If 'csv' is specified, a comma-separated list with a heading
     * row is returned.
     */
    outputFormat?: string;

    /**
     * Specifies wheather the report should be grouped by target when scheduled
     * or downloaded. If not set or set to false, report is grouped by protection
     * jobs. It is ignored if outformat is not "csv" and response contains whole
     * report.
     */
    groupBy?: number;

    /**
     * Filter by end time. Specify the end time as a Unix epoch Timestamp
     * (in milliseconds).
     * If startTimeMsecs and endTimeMsecs are not specified,
     * the time period is the last 7 days.
     */
    endTimeMsecs?: number;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetDataTransferToVaultsReportRequest
   */
  export interface GetDataTransferToVaultsReportRequestParams {

    /**
     * Filter by a list of Vault ids.
     */
    vaultIds: Array<number>;

    /**
     * Filter by a start time. Specify the start time as a Unix epoch Timestamp
     * (in milliseconds).
     * If startTimeMsecs and endTimeMsecs are not specified,
     * the time period is the last 7 days.
     */
    startTimeMsecs?: number;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Specifies the format for the output such as 'csv' or 'json'.
     * If not specified, the json format is returned.
     * If 'csv' is specified, a comma-separated list with a heading
     * row is returned.
     */
    outputFormat?: string;

    /**
     * Specifies wheather the report should be grouped by target when scheduled
     * or downloaded. If not set or set to false, report is grouped by protection
     * jobs. It is ignored if outformat is not "csv" and response contains whole
     * report.
     */
    groupBy?: number;

    /**
     * Filter by end time. Specify the end time as a Unix epoch Timestamp
     * (in milliseconds).
     * If startTimeMsecs and endTimeMsecs are not specified,
     * the time period is the last 7 days.
     */
    endTimeMsecs?: number;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetGdprReport
   */
  export interface GetGdprReportParams {

    /**
     * Specifies the start time for the audit logs as a Unix epoch Timestamp
     * (in microseconds).
     */
    startTimeUsecs?: number;

    /**
     * Specifies the search string for the audit logs.
     */
    search?: string;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Specifies the parent sources of objects for which to get info for.
     */
    parentSourceId?: Array<number>;

    /**
     * Specifies the format for the output such as 'csv' or 'json'.
     * If not specified, the json format is returned.
     * If 'csv' is specified, a comma-separated list with a heading
     * row is returned.
     */
    outputFormat?: string;

    /**
     * Specifies the objects for which to get the gdpr information.
     */
    id?: Array<number>;

    /**
     * Specifies the end time for the audit logsas a Unix epoch Timestamp
     * (in microseconds).
     */
    endTimeUsecs?: number;

    /**
     * Specifies the action for the audit logs.
     */
    actions?: Array<string>;

    /**
     * Specifies the users for which to get the accessible objects.
     */
    accessibleUsers?: Array<string>;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetProtectedObjectsTrendsReportRequest
   */
  export interface GetProtectedObjectsTrendsReportRequestParams {

    /**
     * Specifies the timezone to use when calculating day/week/month
     * Specify the timezone in the following format: "Area/Location",
     * for example: "America/New_York".
     */
    timezone: string;

    /**
     * TenantIds contains ids of the tenants for which objects are to be
     * returned.
     */
    tenantIds?: Array<string>;

    /**
     * Filter by a start time. Snapshot summary statistics
     * for Job Runs that started after the specified time are reported.
     * Specify the start time as a Unix epoch Timestamp (in microseconds).
     */
    startTimeUsecs?: number;

    /**
     * Roll up type for grouping. Valid values are day, week, month
     */
    rollup?: string;

    /**
     * Specifies an id of a top level Registered Source such as
     * a vCenter Server. If specified, Snapshot summary statistics for all
     * the leaf Protection Sources (such as VMs) that are children of this
     * Registered Source are reported.
     * NOTE: If specified, filtering by other fields is not supported.
     */
    registeredSourceId?: number;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Filter by a list of leaf Protection Sources Objects (such as VMs).
     * Snapshot summary statistics for the listed Protection Source Objects
     * are reported.
     */
    protectedObjectIds?: Array<number>;

    /**
     * Filter by a list of Job ids. Snapshots summary statistics
     * for the specified Protection Jobs are reported.
     */
    jobIds?: Array<number>;

    /**
     * Filter by a list of environment types such as 'kVMware', 'kView', etc.
     * Supported environment types such as 'kView', 'kSQL', 'kVMware', etc.
     * NOTE: 'kPuppeteer' refers to Cohesity's Remote Adapter.
     * 'kVMware' indicates the VMware Protection Source environment.
     * 'kHyperV' indicates the HyperV Protection Source environment.
     * 'kSQL' indicates the SQL Protection Source environment.
     * 'kView' indicates the View Protection Source environment.
     * 'kPuppeteer' indicates the Cohesity's Remote Adapter.
     * 'kPhysical' indicates the physical Protection Source environment.
     * 'kPure' indicates the Pure Storage Protection Source environment.
     * 'Nimble' indicates the Nimble Storage Protection Source environment.
     * 'kHpe3Par' indicates the Hpe 3Par Storage Protection Source environment.
     * 'kAzure' indicates the Microsoft's Azure Protection Source environment.
     * 'kNetapp' indicates the Netapp Protection Source environment.
     * 'kAgent' indicates the Agent Protection Source environment.
     * 'kGenericNas' indicates the Generic Network Attached Storage Protection
     * Source environment.
     * 'kAcropolis' indicates the Acropolis Protection Source environment.
     * 'kPhsicalFiles' indicates the Physical Files Protection Source environment.
     * 'kIsilon' indicates the Dell EMC's Isilon Protection Source environment.
     * 'kGPFS' indicates IBM's GPFS Protection Source environment.
     * 'kKVM' indicates the KVM Protection Source environment.
     * 'kAWS' indicates the AWS Protection Source environment.
     * 'kExchange' indicates the Exchange Protection Source environment.
     * 'kHyperVVSS' indicates the HyperV VSS Protection Source
     * environment.
     * 'kOracle' indicates the Oracle Protection Source environment.
     * 'kGCP' indicates the Google Cloud Platform Protection Source environment.
     * 'kFlashBlade' indicates the Flash Blade Protection Source environment.
     * 'kAWSNative' indicates the AWS Native Protection Source environment.
     * 'kO365' indicates the Office 365 Protection Source environment.
     * 'kO365Outlook' indicates Office 365 outlook Protection Source environment.
     * 'kHyperFlex' indicates the Hyper Flex Protection Source environment.
     * 'kGCPNative' indicates the GCP Native Protection Source environment.
     * 'kAzureNative' indicates the Azure Native Protection Source environment.
     * 'kKubernetes' indicates a Kubernetes Protection Source environment.
     * 'kElastifile' indicates Elastifile Protection Source environment.
     * 'kAD' indicates Active Directory Protection Source environment.
     * 'kRDSSnapshotManager' indicates AWS RDS Protection Source environment.
     * 'kCassandra' indicates Cassandra Protection Source environment.
     * 'kMongoDB' indicates MongoDB Protection Source environment.
     * 'kCouchbase' indicates Couchbase Protection Source environment.
     * 'kHdfs' indicates Hdfs Protection Source environment.
     * 'kHive' indicates Hive Protection Source environment.
     * 'kHBase' indicates HBase Protection Source environment.
     */
    environments?: Array<'kVMware' | 'kHyperV' | 'kSQL' | 'kView' | 'kPuppeteer' | 'kPhysical' | 'kPure' | 'kIbmFlashSystem' | 'kNimble' | 'kAzure' | 'kNetapp' | 'kAgent' | 'kGenericNas' | 'kAcropolis' | 'kPhysicalFiles' | 'kIsilon' | 'kGPFS' | 'kKVM' | 'kAWS' | 'kExchange' | 'kHyperVVSS' | 'kOracle' | 'kGCP' | 'kFlashBlade' | 'kAWSNative' | 'kO365' | 'kO365Outlook' | 'kHyperFlex' | 'kGCPNative' | 'kAzureNative' | 'kKubernetes' | 'kElastifile' | 'kAD' | 'kRDSSnapshotManager' | 'kCassandra' | 'kMongoDB' | 'kCouchbase' | 'kHdfs' | 'kHive' | 'kHBase' | 'kSAPHANA'>;

    /**
     * Filter by end time. Snapshot summary statistics
     * for Job Runs that ended before the specified time are returned.
     * Specify the end time as a Unix epoch Timestamp (in microseconds).
     */
    endTimeUsecs?: number;

    /**
     * AllUnderHierarchy specifies if objects of all the tenants under the
     * hierarchy of the logged in user's organization should be returned.
     */
    allUnderHierarchy?: boolean;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetProtectionSourcesJobRunsReportRequest
   */
  export interface GetProtectionSourcesJobRunsReportRequestParams {

    /**
     * Filter by a list of leaf Protection Sources Objects (such as VMs).
     * Snapshots of the specified Protection Source Objects are returned.
     */
    protectionSourceIds: Array<number>;

    /**
     * TenantIds contains ids of the tenants for which objects are to be
     * returned.
     */
    tenantIds?: Array<string>;

    /**
     * Filter by a start time. Snapshots that started after the
     * specified time are returned.
     * Specify the start time as a Unix epoch Timestamp (in microseconds).
     */
    startTimeUsecs?: number;

    /**
     * Filter by a list of run statuses such as 'kRunning',
     * 'kSuccess', 'kFailure' etc.
     * Snapshots of Job Runs with the specified run statuses are reported.
     * 'kSuccess' indicates that the Job Run was successful.
     * 'kRunning' indicates that the Job Run is currently running.
     * 'kWarning' indicates that the Job Run was successful but warnings were
     * issued.
     * 'kCancelled' indicates that the Job Run was canceled.
     * 'kError' indicates the Job Run encountered an error and did not run to
     * completion.
     */
    runStatus?: Array<'kSuccess' | 'kRunning' | 'kWarning' | 'kCancelled' | 'kError'>;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Specifies the number of Snapshots to return in the response for
     * pagination purposes. Used in combination with the paginationCookie in
     * the response to return multiple sets of Snapshots.
     */
    pageCount?: number;

    /**
     * Specifies the format for the output such as 'cvs' or 'json'.
     * If not specified, the json format is returned.
     * If 'csv' is specified, a comma-separated list with a heading
     * row is returned.
     */
    outputFormat?: string;

    /**
     * Filter by a list of Job ids. Snapshots for the specified
     * Protection Jobs are listed.
     */
    jobIds?: Array<number>;

    /**
     * Filter by a list of environment types such as 'kVMware', 'kView', etc.
     * NOTE: 'kPuppeteer' refers to Cohesity's Remote Adapter.
     */
    environments?: Array<'kVMware' | 'kHyperV' | 'kSQL' | 'kView' | 'kPuppeteer' | 'kPhysical' | 'kPure' | 'kIbmFlashSystem' | 'kNimble' | 'kAzure' | 'kNetapp' | 'kAgent' | 'kGenericNas' | 'kAcropolis' | 'kPhysicalFiles' | 'kIsilon' | 'kGPFS' | 'kKVM' | 'kAWS' | 'kExchange' | 'kHyperVVSS' | 'kOracle' | 'kGCP' | 'kFlashBlade' | 'kAWSNative' | 'kVCD' | 'kO365' | 'kO365Outlook' | 'kHyperFlex' | 'kGCPNative' | 'kKubernetes' | 'kElastifile' | 'kSAPHANA' | 'kMongoDBPhysical'>;

    /**
     * Filter by a end time. Snapshots that ended before the
     * specified time are returned.
     * Specify the end time as a Unix epoch Timestamp (in microseconds).
     */
    endTimeUsecs?: number;

    /**
     * AllUnderHierarchy specifies if objects of all the tenants under the
     * hierarchy of the logged in user's organization should be returned.
     */
    allUnderHierarchy?: boolean;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetProtectionSourcesJobsSummaryReportRequest
   */
  export interface GetProtectionSourcesJobsSummaryReportRequestParams {

    /**
     * TenantIds contains ids of the tenants for which objects are to be
     * returned.
     */
    tenantIds?: Array<string>;

    /**
     * Filter by a list of run statuses.
     * 'kSuccess' indicates that the Job Run was successful.
     * 'kRunning' indicates that the Job Run is currently running.
     * 'kWarning' indicates that the Job Run was successful but warnings were
     * issued.
     * 'kCancelled' indicates that the Job Run was canceled.
     * 'kError' indicates the Job Run encountered an error and did not run to
     * completion.
     */
    statuses?: Array<'kSuccess' | 'kRunning' | 'kWarning' | 'kCancelled' | 'kError'>;

    /**
     * Filter by a start time. Snapshot summary statistics
     * for Job Runs that started after the specified time are reported.
     * Specify the start time as a Unix epoch Timestamp (in microseconds).
     */
    startTimeUsecs?: number;

    /**
     * Specifies the report type that will be used to set the right label &
     * subject line for the report when downloaded or emailed because same API is
     * used for 3 reports currently
     * 1. kAvailableLocalSnapshotsReport
     * 2. kFailedObjectsReport
     * 3. kProtectionSummaryByObjectTypeReport
     */
    reportType?: number;

    /**
     * Specifies the custom report name the user wants to set for this report.
     */
    reportName?: string;

    /**
     * Specifies an id of a top level Registered Source such as
     * a vCenter Server. If specified, Snapshot summary statistics for all
     * the leaf Protection Sources (such as VMs) that are children of this
     * Registered Source are reported.
     * NOTE: If specified, filtering by other fields is not supported.
     */
    registeredSourceId?: number;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Filter by a list of leaf Protection Sources Objects (such as VMs).
     * Snapshot summary statistics for the listed Protection Source Objects
     * are reported.
     */
    protectionSourceIds?: Array<number>;

    /**
     * Specifies the format for the output such as 'csv' or 'json'.
     * If not specified, the json format is returned.
     * If 'csv' is specified, a comma-separated list with a heading
     * row is returned.
     */
    outputFormat?: string;

    /**
     * Filter by a list of Job ids. Snapshots summary statistics
     * for the specified Protection Jobs are reported.
     */
    jobIds?: Array<number>;

    /**
     * Filter by a list of environment types such as 'kVMware', 'kView', etc.
     * NOTE: 'kPuppeteer' refers to Cohesity's Remote Adapter.
     */
    environments?: Array<'kVMware' | 'kHyperV' | 'kSQL' | 'kView' | 'kPuppeteer' | 'kPhysical' | 'kPure' | 'kIbmFlashSystem' | 'kNimble' | 'kAzure' | 'kNetapp' | 'kAgent' | 'kGenericNas' | 'kAcropolis' | 'kPhysicalFiles' | 'kIsilon' | 'kGPFS' | 'kKVM' | 'kAWS' | 'kExchange' | 'kHyperVVSS' | 'kOracle' | 'kGCP' | 'kFlashBlade' | 'kAWSNative' | 'kVCD' | 'kO365' | 'kO365Outlook' | 'kHyperFlex' | 'kGCPNative' | 'kKubernetes' | 'kElastifile' | 'kSAPHANA' | 'kMongoDBPhysical'>;

    /**
     * Filter by end time. Snapshot summary statistics
     * for Job Runs that ended before the specified time are returned.
     * Specify the end time as a Unix epoch Timestamp (in microseconds).
     */
    endTimeUsecs?: number;

    /**
     * Filters out those jobs which have number of consecutive run failures less
     * than consecutiveFailures.
     */
    consecutiveFailures?: number;

    /**
     * AllUnderHierarchy specifies if objects of all the tenants under the
     * hierarchy of the logged in user's organization should be returned.
     */
    allUnderHierarchy?: boolean;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { ReportsService }
