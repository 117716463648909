import { Injectable } from '@angular/core';
import {
  McmAgentImage,
  McmAgentImagesResponse,
  PlatformSubTypeAgentImageInfo,
  AgentServiceApi,
} from '@cohesity/api/v2';
import { IrisContextService, flagEnabled, isDmsScope, isMcm } from '@cohesity/iris-core';
import { ENUM_HOST_TYPE } from 'src/app/shared/constants';

/**
 * This interface represents the packages available to be downloaded for the agent.
 */
export interface AgentPackage {
  /**
   * Represents the host environment.
   */
  hostEnv: string;

  /**
   * Represents the host type.
   */
  hostType: string;

  /**
   * Represents the host OS type.
   */
  hostOSType?: string;

  /**
   * The list of packages that are supported to download.
   */
  pkgTypeList?: string[];

  /**
   * The default value to be selected from the package list.
   */
  pkgType?: string;

  /**
   * The list of solaris packages that are supported to download.
   */
  solarisPkgTypeList?: string[];

  /**
   * The default value to be selected from the package list.
   */
  solarisPkgType?: string;

  /**
   * Agent image info of available platform based package types.
   */
  platformPkgTypeAgentInfo?: PlatformSubTypeAgentImageInfo[];

  /**
   * The type of agent available to download.
   */
  agentType?: string;

  /**
   * Description for the host agent.
   */
  hostEnvText?: string;

  /**
   * Url to download the agent.
   */
  url?: string;

  /**
   * Specifies whether host is supported on DMaaS
   */
  dms?: boolean;

  /**
   * Specifies the Esxi host version of CDP Vmware IO filter.
   */
  esxiVersion?: string;

  /**
   * Specifies the list of available Esxi host versions for CDP Vmware IO filter.
   */
  esxiVersionsList?: string[];
}

/**
 * Specifies a platform for which agent information need to be fetched.
 */
export type PlatformType = 'Windows' | 'Linux' | 'Solaris';

@Injectable({
  providedIn: 'root'
})
export class AgentsService {

  /**
   * List of agents available for download.
   */
  agents: AgentPackage[] = [];

  constructor(
    private agentServiceApi: AgentServiceApi,
    private irisContextService: IrisContextService
  ) {
    this.agents = [
      {
        hostEnv: 'windows',
        hostType: 'kWindows',
        dms: true,
      },
      {
        hostEnv: 'linux',
        hostType: 'kLinux',
        pkgTypeList:
          isDmsScope(this.irisContextService.irisContext) &&
          flagEnabled(this.irisContextService.irisContext, 'downloadDmsLinuxAgent')
            ? ['kScript', 'kRPM', 'kDEB', 'kSuseRPM']
            : ['kScript', 'kRPM', 'kDEB', 'kSuseRPM', 'kPowerPCRPM'],
        pkgType: 'kRPM',
        dms: flagEnabled(this.irisContextService.irisContext, 'downloadDmsLinuxAgent'),
      },
      flagEnabled(this.irisContextService.irisContext, 'downloadGoAIXAgent') && {
        hostEnv: 'aix',
        hostType: 'kAix',
        agentType: 'kGo',
        hostEnvText: 'aix7_2',
      },
      flagEnabled(this.irisContextService.irisContext, 'downloadJavaAIXAgent') && {
        hostEnv: 'aix',
        hostType: 'kAix',
        agentType: 'kJava',
      },
      flagEnabled(this.irisContextService.irisContext, 'solarisEnabled') && {
        hostEnv: 'solaris',
        hostType: 'kSolaris',
        hostEnvText: 'solaris',
        agentType: 'kJava',
        solarisPkgTypeList: ['kSolaris10x86', 'kSolaris10Sparc', 'kSolaris11x86', 'kSolaris11Sparc'],
        solarisPkgType: 'kSolaris10x86',
      },
      flagEnabled(this.irisContextService.irisContext, 'hpuxEnabled') && {
        hostEnv: 'hpux',
        hostType: 'kHPUX',
        hostEnvText: 'HPUX',
      },
      flagEnabled(this.irisContextService.irisContext, 'cdpVMwareIOFilterEnabled') && {
        hostEnv: 'cdpVMwareIOFilter',
        hostType: 'kCdpVMwareIOFilter',
        esxiVersionsList: ['6.7', '7.0'],
        esxiVersion: '6.7'
      },
      flagEnabled(this.irisContextService.irisContext, 'downloadSapHanaAgentEnabled') && {
        hostEnv: 'saphana',
        hostType: 'kSapHana',
        hostEnvText: 'sapHana',
        pkgTypeList: ['kRPM'],
        pkgType: 'kRPM',
        agentType: 'kLegacy',
        dms: false
      },
    ].filter(Boolean);
  }

  /**
   * Transforms the agent and adds the appropriate URL to download for DMaaS
   *
   * @param  platform     The platform for which agent needs to be downloaded.
   */
  dmsTranformHostAgent(platform: PlatformType) {
    this.agentServiceApi.McmGetAgentImageDetails({
      platform
    }).subscribe((agentImages: McmAgentImagesResponse) => {
      // Creating agent info map based on platform type.
      const agentMapbyHostType: {[key: string]: McmAgentImage} = {};
      agentImages.agents.forEach(image => agentMapbyHostType[image.platform] = image);

      this.agents.forEach(agent => {
        if (platform === ENUM_HOST_TYPE[agent.hostType]) {
          agent.platformPkgTypeAgentInfo = agentMapbyHostType[ENUM_HOST_TYPE[agent.hostType]]?.PlatformSubTypes;
          agent.url = agent.pkgType ? this.fetchAgentPkgTypeDownloadUrl(agent) :
            agentMapbyHostType[ENUM_HOST_TYPE[agent.hostType]]?.downloadURL;
        }
      });
    });
  }

  /**
   * Returns the agent download url based on package type.
   *
   * @param  hostAgent  The agent which needs to be downloaded.
   * @return Agent download url
   */
  fetchAgentPkgTypeDownloadUrl(hostAgent: AgentPackage): string {
    if (!hostAgent?.platformPkgTypeAgentInfo?.length) {
      return;
    }

    // Dropping k prefix from package type.
    const packageType = hostAgent.pkgType.slice(1);
    return hostAgent.platformPkgTypeAgentInfo.find(val => val.packageType === packageType)?.downloadURL;
  }

  /**
   * Return true if download agent is allowed (not allowed in MCM with feature flag enabled).
   */
  canDownload(): boolean {
    return isDmsScope(this.irisContextService.irisContext) ||
      !isMcm(this.irisContextService.irisContext) ||
      !flagEnabled(this.irisContextService.irisContext, 'hideDownloadAgentDialogMcm');
  }
}
