import { Pipe, PipeTransform } from '@angular/core';
import { JobRunFinishedStates, ProtectionStatusType } from '../constants';

/**
 * Return true if provided job run is finished else return false.
 *
 * Usage:
 *   {{ 'Succeeded' | isJobRunFinished }}
 *
 * Example:
 *   {{ 'Succeeded'            | isJobRunFinished }}         // true
 *   {{ 'Failed'               | isJobRunFinished }}         // true
 *   {{ 'Missed'               | isJobRunFinished }}         // true
 *   {{ 'Canceled'             | isJobRunFinished }}         // true
 *   {{ 'SucceededWithWarning' | isJobRunFinished }}         // true
 *
 *   {{ 'Running'   | isJobRunFinished }}                    // false
 *   {{ 'Canceling' | isJobRunFinished }}                    // false
 *   {{ 'OnHold'    | isJobRunFinished }}                    // false
 */
@Pipe({
  name: 'isJobRunFinished'
})
export class IsJobRunFinishedPipe implements PipeTransform {
  /**
   * Set of job run status which are consider as finished.
   */
  private jobFinishedStatesSet = new Set(JobRunFinishedStates);

  /**
   * Return true if provided job run is finished else return false.
   *
   * @param jobRunStatus The job run status.
   * @returns True if provided job run is finished else return false.
   */
  transform(jobRunStatus: ProtectionStatusType): boolean {
    return this.jobFinishedStatesSet.has(jobRunStatus);
  }
}
