import { GetAppDisplayName } from '@cohesity/helix';
import { TranslateService } from '@ngx-translate/core';

import { isSizerScope, isSizerUser } from '../iris-context/sizer-utils';
import {
  flagEnabled,
  hasClusters,
  IrisContext,
  isAllClustersScope,
  isClusterScope,
  isDGaaSUser,
  isDmsScope,
  isDmsUser,
  isDraasScope,
  isDraasUser,
  isGaiaScope,
  isGaiaUser,
  isGlobalScope,
  isHybridUser,
  isOneHeliosAppliance,
  isPiaasScope,
  isRpaasScope,
  isRpaasUser,
  isSecurityCenterScope,
  isSecurityCenterUser,
  isSmartFilesScope,
} from './../iris-context';
import { appConfigMap } from './configs';
import { AppConfig, AppName, CanAccessApp, PluginName } from './models';
import { pluginConfigMap } from './plugins';

/**
 * Return the get app display name callback function.
 *
 * @param translate The TranslateService.
 * @returns Return the get app display name callback function.
 */
export const translateAppName =
  (translate: TranslateService): GetAppDisplayName => appName => translate.instant(`app.${appName}`);

/**
 * Provides the list of accessable apps by the logged-in user.
 *
 * @param irisCtx Iris context
 * @returns The list of accessable apps by the logged-in user.
 */
export const getAccessibleAppConfigs = (irisCtx: IrisContext): AppConfig[] =>
  Object.values(appConfigMap)
    .filter(config => canAccessAppFnMap[config.name](irisCtx))
    .sort((first, second) => AppSortOrder[first.name] - AppSortOrder[second.name])
    .map(config => ({
      ...config,
      isActive: isAppActiveFnMap[config.name](irisCtx),
    }));

/**
 * The apps sorting order and lower number will be shown 1st.
 */
export const AppSortOrder: Record<AppName, number> = {
  clusterManager: 0,
  global: 1,
  dataProtect: 2,
  siteContinuity: 3,
  siteContinuityV2: 3,
  fortKnox: 4,
  securityCenter: 5,
  gaia: 6,
  platformInsights: 7,
  smartFiles: 8,
  sizer: 9,
  applianceManager: 10,
};

/**
 * The map of methods for each app used to determine wheather the logged-in user can access the app or not.
 */
export const canAccessAppFnMap: Record<AppName, CanAccessApp> = {
  clusterManager: ctx => (
    hasClusters(ctx) ||
    isDGaaSUser(ctx) ||
    isGaiaUser(ctx) ||
    isSizerUser(ctx) ||
    isRpaasUser(ctx) ||
    isDraasUser(ctx) ||
    // Cluster Manager is to be shown in all cases except for DMS only user.
    // control should reach here only for the case of users w/o clusters and
    // any other subscription other than DMS. Now,
    // Case A: user does not even has DMS subscription => user only has access to Helios - could
    // be the case of fresh account etc.
    // Case B: user has DMS subscription => since there are no clusters this COULD be Dms
    // only account.
    !isDmsUser(ctx)
  ),
  dataProtect: ctx => isDmsUser(ctx),
  fortKnox: ctx => flagEnabled(ctx, 'rpaasEnabled') && isRpaasUser(ctx),
  gaia: ctx => isGaiaUser(ctx),
  global: ctx => flagEnabled(ctx, 'appPillarsGlobalScopeEnabled') || isHybridUser(ctx),

  // Ensure at least one cluster is claimed since BaaS only customers dont
  // need Security Center
  securityCenter: ctx => isSecurityCenterUser(ctx),
  platformInsights: ctx => flagEnabled(ctx, 'appPillarsEnabled'),
  sizer: ctx => isSizerUser(ctx),

  /**
   * The following logic allows V1 access of the product only if:
   * - V2 is not yet enabled using the flag 'siteCon2App', or
   * - V2 is enabled but user needs an intermediary stage to transition from
   *   V1 to V2. The flag 'siteCon2Transition' allows the users to switch
   *   back and forth between the V1 and V2 of the product.
   *
   * If only siteCon2App flag is enabled, then user will always see the V2 of the product.
   */
  siteContinuity: ctx => isDraasUser(ctx) &&
    (!flagEnabled(ctx, 'siteCon2App') || (flagEnabled(ctx, 'siteCon2App') && flagEnabled(ctx, 'siteCon2Transition'))),
  siteContinuityV2: ctx => isDraasUser(ctx) &&
    flagEnabled(ctx, 'siteCon2App') && !flagEnabled(ctx, 'siteCon2Transition'),
  smartFiles: ctx => flagEnabled(ctx, 'smartFilesHeliosV1Hybrid'),
  applianceManager: ctx => isOneHeliosAppliance(ctx),
};

/**
 * The map of methods for each app used to determine wheather the app is active currenlty or not.
 */
export const isAppActiveFnMap: Record<AppName, (irisContext: IrisContext) => boolean> = {
  clusterManager: ctx => (isClusterScope(ctx) || isAllClustersScope(ctx)),
  dataProtect: ctx => isDmsScope(ctx),
  fortKnox: ctx => flagEnabled(ctx, 'rpaasEnabled') && isRpaasScope(ctx),
  gaia: ctx => isGaiaScope(ctx),
  global: ctx => isGlobalScope(ctx),
  platformInsights: ctx => isPiaasScope(ctx),
  securityCenter: ctx => isSecurityCenterScope(ctx),
  siteContinuity: ctx => isDraasScope(ctx),
  siteContinuityV2: ctx => isDraasScope(ctx),
  smartFiles: ctx => isSmartFilesScope(ctx),
  sizer: ctx => isSizerScope(ctx),
  applianceManager: ctx => isOneHeliosAppliance(ctx)
};

/**
 * The map of methods for each app used to determine wheather the logged-in user can access the plugin or not.
 */
export const canAccessPluginFnMap: Record<PluginName, CanAccessApp> = {
  argusRecoveryPluginConfig: ctx => flagEnabled(ctx, 'dataHawkRemoteSnapshotRecoveryPluginEnabled'),
  aiBotPluginConfig: ctx => flagEnabled(ctx, 'aiBotPluginEnabled') && isSecurityCenterScope(ctx),
  // TODO: Improve the condition to show the turing plugin.
  turingPluginConfig: ctx => flagEnabled(ctx, 'turingPluginEnabled'),
};

/**
 * Provides the list of active plugins by the logged-in user.
 *
 * @param irisCtx Iris context
 * @returns The list of active plugins by the logged-in user.
 */
export const getActivePluginConfigs = (irisCtx: IrisContext) =>
  Object.values(pluginConfigMap).filter(config => canAccessPluginFnMap[config.name](irisCtx)).filter(Boolean);

/**
 * Utility function to provide custom app configs.
 *
 * @returns The app configs for the app based on current base href in urlSegmentsMap.
 */
export const getEffectiveAppConfig = (appConfig: AppConfig): AppConfig => {
  const base = document.querySelector('base')?.getAttribute('href')?.replace(/\/$/, '') || '';
  Object.keys(appConfig.urlSegmentsMap || {})?.forEach(urlSegment => {
    if(appConfig.urlSegmentsMap[urlSegment] === base) {
      appConfig.urlSegment = base;
      return;
    }
  });
  return  appConfig;
};
