import { SelectionModel } from '@angular/cdk/collections';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  DataPool,
  DataPoolId,
  DataPoolsApiService,
  DataSource,
  DataSourceId,
  DataSourcesApiService,
  DlpApiService,
  Environment,
  Policy,
  PolicyStatus,
  Shield,
  ShieldsApiService,
  ShieldType,
  View,
  ViewsApiService,
} from '@cohesity/api/argus';
import {
  CanSelectAnyRowsFn,
  CanSelectRowFn,
  DataFilterValue,
  FiltersComponent,
  ValueFilterSelection,
} from '@cohesity/helix';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { AutoDestroyable } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { combineLatest, iif, of } from 'rxjs';
import { finalize, map, switchMap } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';

import { BulkActionType, DataPoolUpdateDialogInput } from '../data-pool-update-dialog/data-pool-update-dialog.model';
import { DataPoolUpdateDialogService } from '../data-pool-update-dialog/data-pool-update-dialog.service';
import {
  clusterIdentifierIdToDataSourceMap,
  dataPoolIdToPoliciesMap,
  dataPoolIdToShieldsMap,
  dataPoolIdToSourceMap,
  dataPoolIdToViewMap,
  dataPoolIdToViewUuidMap,
  DataPoolsMetadataSummary,
  getDataPoolsMetadataMap,
  getDataPoolsMetadataSummary,
  getViewUuid,
  sourceIdToClusterIdentifierIdMap,
  transformViewToDataPool,
  ViewUuid,
} from './data-pools.utils';

export interface InitSelection {
  dataPools: DataPool[];
}

/**
 * interface for emitting bulk action event
 */
export interface BulkActionEvent {
  /**
   * bulk action to be taken
   */
  action: BulkActionType;

  /**
   * data pools on which bulk action is to be taken
   */
  dataPools: DataPool[];
}

/**
 * The list of data pool component.
 *
 * @example
 * <dg-dc-data-pool-list [selection]="selectedDataPool"></dg-dc-data-pool-list>
 */
@Component({
  selector: 'dg-dc-data-pool-list',
  templateUrl: './data-pool-list.component.html',
  styleUrls: ['./data-pool-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataPoolListComponent extends AutoDestroyable implements OnChanges, AfterViewInit {
  /**
   * uuid helps us to generate unique cog-table name which makes row selection
   * mutually exclusive if multiple cog-table instances are present on the DOM simultaneously.
   */
  uuid = uuid();

  /** Optional if present the show data pools for the given shield only */
  @Input() shield?: Shield;

  /** Optional if present the show data pools for the given data source only */
  @Input() dataSourceId?: DataSourceId;

  /** The selected data pools. */
  @Input() selection?: SelectionModel<DataPool>;

  /** The selected data pools for bulk actions. */
  selectedDataPools = new SelectionModel<DataPool>(true, []);

  /** Disabled the Data Pool selection. */
  @Input() disabled = false;

  /** Indicates whether to hide the actions column or not. */
  @Input() hideActions = true;

  /** Indicates whether to hide the bulk actions column or not. */
  @Input() hideBulkActions = true;

  /** Indicates whether to show the metadata stats */
  @Input() showStats = false;

  /** Indicates whether to show data sources column and filter */
  @Input() showDataSources = false;

  /** Indicates whether to show shields column and filter */
  @Input() showShields = false;

  /** Indicates whether to show policies column and filter */
  @Input() showPolicies = false;

  /** Indicates whether to show governance column and filter */
  @Input() showGovernance = false;

  /** Indicates whether to show audit logging column and filter */
  @Input() showAuditLogging = false;

  /**
   * The list of supported shields for argus.
   */
  supportedShields: ShieldType[] = [];

  /** The list of data sources. */
  dataSources: DataSource[] = [];

  /** Data pool id to data sources mapping. */
  dataSourcesMap = new Map<DataPoolId, DataSource>();

  /** The list of shields. */
  shields: Shield[] = [];

  /** Data pool id to shields mapping. */
  shieldsMap?: Map<DataPoolId, Shield[]>;

  /** The list of policies. */
  policies: Policy[] = [];

  /** Data pool id to policies mapping. */
  policiesMap?: Map<DataPoolId, Policy[]>;

  /** A map from dataPool name to whether audit logging is enabled for that dataPool */
  dataPoolIdToViewMap: Map<string, View>;

  /** Indicate whether we are fetch data. */
  isLoading = false;

  /** The data pools to show. */
  dataPools: DataPool[];

  /** filtered list of data pools */
  filteredDataPools: DataPool[] = [];

  /** Data pool id to data pools metadata mapping. */
  dataPoolMetadataMap = new Map<DataPoolId, DataPoolsMetadataSummary>();

  /** The data pools metadata summary to be shown at glacebar */
  metadata: DataPoolsMetadataSummary = {
    totalDataPools: 0,
    dataPoolsToGovern: 0,
    dataPoolsToClassify: 0,
    totalShields: 0,
    totalDataSources: 0,
    totalDataSize: 0,
    totalObjects: 0,
  };

  /** Emit updated data pool associated event. */
  @Output() editDataPool = new EventEmitter<DataPool>();

  /** Emitted event to initialize the selection model.  */
  @Output() initSelection = new EventEmitter<InitSelection>();

  /** Audit Logging filter */
  auditLoggingFilter = this.getEnableDisableFilterValues();

  /** governance filter values */
  governanceFilterValues = ['yes', 'no'].map(value => ({
    label: this.translateService.instant(value === 'yes' ? 'governed' : 'notGoverned'),
    value: value === 'yes',
  }));

  /** Data Sources filter values. */
  dataSourceFilterValues: ValueFilterSelection[] = [];

  /** Shields filter values. */
  shieldFilterValues: ValueFilterSelection[] = [];

  /** Policies filter values. */
  policyFilterValues: ValueFilterSelection[] = [];

  /** cog-filters component reference. */
  @ViewChild(FiltersComponent) filtersComponent: FiltersComponent;

  /** Columns For Table. */
  displayedColumns = this.getColDef();

  /** Bulk actions list. */
  bulkActions = this.getBulkActions();

  /** Indicates whether the data pools selection is enabled or not. */
  get enableSelection() {
    return !!this.selection || !this.hideBulkActions;
  }


  /** Callback Fn to cog-table to disable selection of views with disabled audit logging */
  @Input() canSelectDataPool: CanSelectRowFn<DataPool> = dataPool =>
    this.showAuditLogging ? this.dataPoolIdToViewMap.get(dataPool.id)?.enableFilerAuditLogging : true;

  constructor(
    private cdr: ChangeDetectorRef,
    private dataPoolsApiService: DataPoolsApiService,
    private dataPoolUpdateDialogService: DataPoolUpdateDialogService,
    private dataSourcesApiService: DataSourcesApiService,
    private dlpApiService: DlpApiService,
    private irisCtx: IrisContextService,
    private shieldsApiService: ShieldsApiService,
    private translateService: TranslateService,
    private viewsApiService: ViewsApiService
  ) {
    super();

    this.supportedShields = [
      flagEnabled(this.irisCtx.irisContext, 'dataGovernWideAccessShieldEnabled') ? ShieldType.WIDE_ACCESS : null,
      flagEnabled(this.irisCtx.irisContext, 'dataGovernInsiderThreatShieldEnabled') ? ShieldType.INSIDER_THREAT : null,
      ShieldType.ANTI_RANSOMWARE,
      ShieldType.SMARTFILES,
      flagEnabled(this.irisCtx.irisContext, 'dataHawkThreatDetectionEnabled') ? ShieldType.THREAT_DETECTION : null,
    ].filter(Boolean) as ShieldType[];
  }

  /**
   * Initialization after DOM is updated.
   */
  ngAfterViewInit() {
    if (this.selection) {
      // using the provided selection model instead of default one if the component is being used to get selected
      // data pools.
      this.selectedDataPools = this.selection;
    }

    this.getData();

    this.filtersComponent.filterValues$.pipe(this.untilDestroy()).subscribe(filters => {
      this.selectedDataPools.clear();
      this.applyFilters(filters);
      this.cdr.detectChanges();
    });
  }

  /**
   * Callback Fn to cog-table to disable selection of views with disabled audit logging
   */
  canSelectRow: CanSelectRowFn<DataPool> = dataPool => !this.disabled && this.canSelectDataPool(dataPool);

  /**
   * Callback Fn to cog-table to disable selection of views with disabled audit logging
   */
  canSelectAnyRow: CanSelectAnyRowsFn = () => !this.disabled;

  /**
   * Callback Fn to cog-table to show tooltip for views with disabled audit logging
   */
  selectRowTooltip = (dataPool: DataPool) => {
    if (this.canSelectRow(dataPool)) {
      return null;
    }
    return this.translateService.instant('argus.dataPools.selectTooltip');
  };

  /**
   * Return the enable/disable filter values.
   *
   * @returns The filter values.
   */
  getEnableDisableFilterValues(): ValueFilterSelection[] {
    return ['enabled', 'disabled'].map(value => ({
      label: this.translateService.instant(value),
      value: value === 'enabled',
    }));
  }

  /**
   * A callback called when input binding changes.
   *
   * @param changes Input binding changes
   */
  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.hideActions ||
      changes.showAuditLogging ||
      changes.showDataSources ||
      changes.showGovernance ||
      changes.showPolicies ||
      changes.showShields
    ) {
      this.displayedColumns = this.getColDef();
      this.bulkActions = this.getBulkActions();
    }
  }

  /**
   * Return the list of bulk actions for the data pools table.
   *
   * @returns The list of data pools table bulk action to show.
   */
  getBulkActions(): BulkActionType[] {
    return (
      [this.showGovernance ? 'govern' : null, this.showGovernance ? 'notGovern' : null] as BulkActionType[]
    ).filter(Boolean);
  }

  /**
   * Return the list of columns to show.
   *
   * @returns The column to show.
   */
  getColDef(): string[] {
    return [
      'name',
      this.showDataSources ? 'dataSource' : null,
      this.showShields ? 'shieldName' : null,
      this.showPolicies ? 'policy' : null,

      // (ENG-464234): commented now, when needed enable it back
      // 'data',

      // Hiding the objects count column because argus backend fetches this once during
      // onboarding which never get updated in future hence result in stale value shown in the UI.
      // 'objects',
      this.showAuditLogging ? 'auditLogging' : null,
      this.showGovernance ? 'governed' : null,
      this.hideActions ? null : 'actions',
    ].filter(Boolean);
  }

  /**
   * Get Data source associated Data pools
   */
  getData() {
    const dataSourceIds = this.dataSourceId ? [this.dataSourceId] : undefined;

    this.isLoading = true;
    this.cdr.detectChanges();
    combineLatest([
      this.dataPoolsApiService.getDataPools({ dataSourceIds }),
      iif(
        () => !this.showDataSources && !this.showGovernance,
        of({ sources: [] }),
        this.dataSourcesApiService.getRegisteredDataSources({ ids: dataSourceIds })
      ),
      iif(
        () => !this.showShields,
        of([]),
        this.shieldsApiService
          .getShields()
          .pipe(map(({ shields }) => (shields || []).filter(({ type }) => this.supportedShields.includes(type))))
      ),
      iif(
        () => !this.showPolicies,
        of({ policies: [] }),
        this.dlpApiService.getPolicies({ status: [PolicyStatus.active] })
      ),
    ])
      .pipe(
        this.untilDestroy(),
        map(([{ dataPools }, { sources }, shields, { policies }]) => ({ dataPools, sources, shields, policies })),
        switchMap(({ dataPools, sources, shields, policies }) => {
          const shouldFetchViews = (sources || []).some(source => source.environment === Environment.kCohesity);

          if (this.showGovernance && shouldFetchViews) {
            const clusterIdentifierIdToDataSource = clusterIdentifierIdToDataSourceMap(sources);
            const clusterIdentifierIds = [...sourceIdToClusterIdentifierIdMap(sources).values()];

            // Call api to get list of all views and cross reference with existing data pool names
            return this.viewsApiService.getViews({ clusterIdentifierIds }).pipe(
              map(({ views }) => {
                // Create a set of data pool viewUuids
                const existingViewUuids = new Set<ViewUuid>([...dataPoolIdToViewUuidMap(dataPools, sources).values()]);
                const newDataPools = (views || [])
                  .filter(view => !existingViewUuids.has(getViewUuid(view)))
                  .map(view =>
                    transformViewToDataPool(view, clusterIdentifierIdToDataSource.get(view.clusterIdentifierId).id)
                  );

                return { views, dataPools: dataPools.concat(newDataPools), sources, shields, policies };
              })
            );
          }
          return of({ views: [], dataPools, sources, shields, policies });
        }),
        map(({ views, dataPools, sources, shields, policies }) => {
          this.filteredDataPools = this.dataPools = dataPools || [];
          this.dataSources = sources || [];
          this.policies = policies || [];
          this.shields = shields || [];

          this.dataPoolMetadataMap = getDataPoolsMetadataMap(this.dataPools);
          this.shieldsMap = dataPoolIdToShieldsMap(this.shields, this.dataPools);
          this.policiesMap = dataPoolIdToPoliciesMap(this.policies);
          this.dataSourcesMap = dataPoolIdToSourceMap(this.dataSources, this.dataPools);
          this.dataPoolIdToViewMap = dataPoolIdToViewMap(views, this.dataPools, this.dataSources);

          this.dataSourceFilterValues = this.dataSources.map(({ id, name }) => ({ label: name, value: id }));
          // this.shieldFilterValues = this.shields.map(({ type }) => ({
          //   label: this.shieldTypePipe.transform(type),
          //   value: type,
          // }));
          this.policyFilterValues = this.policies.map(({ id, name }) => ({ label: name, value: id }));

          const { totalDataSize, totalObjects } = getDataPoolsMetadataSummary(this.dataPools);

          this.metadata.totalDataSize = totalDataSize;
          this.metadata.totalObjects = totalObjects;
          this.metadata.totalDataPools = this.dataPools.length;
          this.metadata.totalShields = this.shields.length;
          this.metadata.totalDataSources = this.dataSources.length;

          //  shieldsMap contains all entries of dataPoolID corresponding to its shield
          //  Every protected pool and its corresponding shield has an entry in the map
          this.metadata.dataPoolsToGovern = this.dataPools.filter(({ enabled }) => enabled).length;
          this.metadata.dataPoolsToClassify = this.policiesMap.size;

          this.initSelection.emit({ dataPools });
        }),
        finalize(() => {
          this.isLoading = false;
          this.cdr.detectChanges();
        })
      )
      .subscribe();
  }

  /**
   * Apply the selected filters
   *
   * @param filters The applied filter value .
   */
  private applyFilters(filters: DataFilterValue<any, any>[]) {
    // updating filtered data Pools
    this.filteredDataPools = [...(this.dataPools || [])];

    // applying the selected filters.
    filters.forEach(({ key, value, predicate }) => {
      // skip null or empty value filters.
      if (!value?.length) {
        return;
      }

      // apply filter by name.
      if (key === 'name') {
        this.filteredDataPools = this.filteredDataPools.filter(dataPool => predicate(dataPool, value, 'name'));
      }

      // apply filter by data pool governed filter
      if (key === 'governed') {
        this.filteredDataPools = this.filteredDataPools.filter(dataPool => predicate(dataPool, value, 'enabled'));
      }

      // apply filter by audit logging
      if (key === 'auditLogging') {
        this.filteredDataPools = this.filteredDataPools.filter(dataPool =>
          predicate(this.dataPoolIdToViewMap.get(dataPool.id), value, 'enableFilerAuditLogging')
        );
      }

      // apply filter by data pool enabled/disabled filter
      if (key === 'dataSource') {
        this.filteredDataPools = this.filteredDataPools.filter(dataPool =>
          predicate(this.dataSourcesMap.get(dataPool.id), value, 'id')
        );
      }

      if (key === 'shield') {
        this.filteredDataPools = this.filteredDataPools.filter(dataPool =>
          predicate(
            this.shieldsMap
              .get(dataPool.id)
              ?.find(shield => Boolean(value.find(filter => shield.type === filter.value))),
            value,
            'type'
          )
        );
      }

      if (key === 'policy') {
        this.filteredDataPools = this.filteredDataPools.filter(dataPool =>
          predicate(this.policiesMap.get(dataPool.id), value, 'id')
        );
      }
    });
  }

  /**
   * Handle the data pool bulk action.
   *
   * @param   action   The action.
   */
  groupActionHandler(action: BulkActionType) {
    const dataPools = this.selectedDataPools.selected;
    const dialogInput: DataPoolUpdateDialogInput = {
      action,
      policies: this.policies,
      shields: this.shields.filter(shield => (this.shield ? this.shield.type === shield.type : true)),
      dataPools: cloneDeep(dataPools),
    };

    // Launch update data pools dialog.
    this.dataPoolUpdateDialogService
      .launchDataPoolUpdateDialog(dialogInput)
      .pipe(this.untilDestroy())
      .subscribe((result: boolean) => {
        if (result) {
          this.selectedDataPools.clear();
          this.getData();
        }
      });
  }
}
