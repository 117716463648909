/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityApiConfiguration as __Configuration } from '../cohesity-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { VaultEncryptionKey } from '../models/vault-encryption-key';
import { RemoteVaultRestoreTaskStatus } from '../models/remote-vault-restore-task-status';
import { UniversalId } from '../models/universal-id';
import { CreateRemoteVaultRestoreTaskParameters } from '../models/create-remote-vault-restore-task-parameters';
import { RemoteVaultSearchJobResults } from '../models/remote-vault-search-job-results';
import { RemoteVaultSearchJobInformation } from '../models/remote-vault-search-job-information';
import { CreatedRemoteVaultSearchJobUid } from '../models/created-remote-vault-search-job-uid';
import { CreateRemoteVaultSearchJobParameters } from '../models/create-remote-vault-search-job-parameters';
import { StopRemoteVaultSearchJobParameters } from '../models/stop-remote-vault-search-job-parameters';
@Injectable({
  providedIn: 'root',
})
class RemoteRestoreService extends __BaseService {
  static readonly UploadVaultEncryptionKeysPath = '/public/remoteVaults/encryptionKeys/{id}';
  static readonly ListRemoteVaultRestoreTasksPath = '/public/remoteVaults/restoreTasks';
  static readonly CreateRemoteVaultRestoreTaskPath = '/public/remoteVaults/restoreTasks';
  static readonly GetRemoteVaultSearchJobResultsPath = '/public/remoteVaults/searchJobResults';
  static readonly ListRemoteVaultSearchJobsPath = '/public/remoteVaults/searchJobs';
  static readonly CreateRemoteVaultSearchJobPath = '/public/remoteVaults/searchJobs';
  static readonly StopRemoteVaultSearchJobPath = '/public/remoteVaults/searchJobs';
  static readonly ListRemoteVaultSearchJobByIdPath = '/public/remoteVaults/searchJobs/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Upload the encryption keys required to restore data from a remote Vault.
   * (CloudRetrieve)
   *
   * This request contains multiple files stored as multipart mime data.
   * Each file has a key used to encrypt data between a remote Cluster and the
   * remote Vault.
   * Content of the file should be same as the file downloaded from the remote
   * Cluster.
   * @param params The `RemoteRestoreService.UploadVaultEncryptionKeysParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Vault.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: Request to upload encryption keys of a remote Vault.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  UploadVaultEncryptionKeysResponse(params: RemoteRestoreService.UploadVaultEncryptionKeysParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    __body = params.body;
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/public/remoteVaults/encryptionKeys/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Upload the encryption keys required to restore data from a remote Vault.
   * (CloudRetrieve)
   *
   * This request contains multiple files stored as multipart mime data.
   * Each file has a key used to encrypt data between a remote Cluster and the
   * remote Vault.
   * Content of the file should be same as the file downloaded from the remote
   * Cluster.
   * @param params The `RemoteRestoreService.UploadVaultEncryptionKeysParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Vault.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: Request to upload encryption keys of a remote Vault.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  UploadVaultEncryptionKeys(params: RemoteRestoreService.UploadVaultEncryptionKeysParams): __Observable<null> {
    return this.UploadVaultEncryptionKeysResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * List the remote Vault restore tasks that have completed or are running on
   * this Cohesity Cluster. (CloudRetrieve)
   *
   * A remote Vault restore task can restore archived data from a Vault
   * (External Target) to this local Cluster.
   * This is part of the CloudRetrieve functionality for finding and restoring
   * archived data from remote Vaults to an alternative (non-original) Cluster.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  ListRemoteVaultRestoreTasksResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<Array<RemoteVaultRestoreTaskStatus>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/remoteVaults/restoreTasks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<RemoteVaultRestoreTaskStatus>>;
      })
    );
  }
  /**
   * List the remote Vault restore tasks that have completed or are running on
   * this Cohesity Cluster. (CloudRetrieve)
   *
   * A remote Vault restore task can restore archived data from a Vault
   * (External Target) to this local Cluster.
   * This is part of the CloudRetrieve functionality for finding and restoring
   * archived data from remote Vaults to an alternative (non-original) Cluster.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  ListRemoteVaultRestoreTasks(regionId?: string,
    accessClusterId?: number): __Observable<Array<RemoteVaultRestoreTaskStatus>> {
    return this.ListRemoteVaultRestoreTasksResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as Array<RemoteVaultRestoreTaskStatus>)
    );
  }

  /**
   * Create a remote Vault restore task. (CloudRetrieve)
   *
   * Returns the id of the remote Vault restore Task that was created.
   *
   * After a Vault is searched by a search Job, this operation can be
   * called to create a task that restores the indexes and/or the Snapshots
   * of a Protection Job, which were archived on a remote Vault (External Target).
   * This is part of the CloudRetrieve functionality for finding and restoring
   * archived data from remote Vaults to an alternative (non-original) Cluster.
   * @param params The `RemoteRestoreService.CreateRemoteVaultRestoreTaskParams` containing the following parameters:
   *
   * - `body`: Request to create a remote Vault restore task.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateRemoteVaultRestoreTaskResponse(params: RemoteRestoreService.CreateRemoteVaultRestoreTaskParams): __Observable<__StrictHttpResponse<UniversalId>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/remoteVaults/restoreTasks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UniversalId>;
      })
    );
  }
  /**
   * Create a remote Vault restore task. (CloudRetrieve)
   *
   * Returns the id of the remote Vault restore Task that was created.
   *
   * After a Vault is searched by a search Job, this operation can be
   * called to create a task that restores the indexes and/or the Snapshots
   * of a Protection Job, which were archived on a remote Vault (External Target).
   * This is part of the CloudRetrieve functionality for finding and restoring
   * archived data from remote Vaults to an alternative (non-original) Cluster.
   * @param params The `RemoteRestoreService.CreateRemoteVaultRestoreTaskParams` containing the following parameters:
   *
   * - `body`: Request to create a remote Vault restore task.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateRemoteVaultRestoreTask(params: RemoteRestoreService.CreateRemoteVaultRestoreTaskParams): __Observable<UniversalId> {
    return this.CreateRemoteVaultRestoreTaskResponse(params).pipe(
      __map(_r => _r.body as UniversalId)
    );
  }

  /**
   * List details about the Job Runs of Protection Jobs found by a single search
   * of a remote Vault. (CloudRetrieve)
   *
   * Specify a unique id of the search Job using a combination of the
   * searchJobId, clusterId, and clusterIncarnationId parameters,
   * which are all required.
   *
   * The results can be optionally filtered by the remote Cluster name.
   * This is part of the CloudRetrieve functionality for finding and restoring
   * archived data from a remote Vault.
   * @param params The `RemoteRestoreService.GetRemoteVaultSearchJobResultsParams` containing the following parameters:
   *
   * - `searchJobId`: Specifies the id of the remote Vault search Job assigned
   *   by the Cohesity Cluster. Used in combination with the clusterId
   *   and clusterIncarnationId to uniquely identify the search Job.
   *
   * - `clusterIncarnationId`: Specifies the incarnation id of the Cohesity Cluster where the
   *   search Job was created.
   *   Used in combination with the searchJobId and clusterId to uniquely
   *   identify the search Job.
   *
   * - `clusterId`: Specifies the Cohesity Cluster id where the search Job was created.
   *   Used in combination with the searchJobId and clusterIncarnationId
   *   to uniquely identify the search Job.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `pageCount`: Specifies the number of Protection Jobs to return in the response
   *   to support pagination.
   *
   * - `cookie`: Specifies the opaque string cookie returned by the previous response, to
   *   get next set of results. Used in combination with pageCount to support
   *   pagination.
   *
   * - `clusterName`: Optionally filter the result by the remote Cohesity Cluster name.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetRemoteVaultSearchJobResultsResponse(params: RemoteRestoreService.GetRemoteVaultSearchJobResultsParams): __Observable<__StrictHttpResponse<RemoteVaultSearchJobResults>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.searchJobId != null) __params = __params.set('searchJobId', params.searchJobId.toString());
    if (params.clusterIncarnationId != null) __params = __params.set('clusterIncarnationId', params.clusterIncarnationId.toString());
    if (params.clusterId != null) __params = __params.set('clusterId', params.clusterId.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.pageCount != null) __params = __params.set('pageCount', params.pageCount.toString());
    if (params.cookie != null) __params = __params.set('cookie', params.cookie.toString());
    if (params.clusterName != null) __params = __params.set('clusterName', params.clusterName.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/remoteVaults/searchJobResults`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RemoteVaultSearchJobResults>;
      })
    );
  }
  /**
   * List details about the Job Runs of Protection Jobs found by a single search
   * of a remote Vault. (CloudRetrieve)
   *
   * Specify a unique id of the search Job using a combination of the
   * searchJobId, clusterId, and clusterIncarnationId parameters,
   * which are all required.
   *
   * The results can be optionally filtered by the remote Cluster name.
   * This is part of the CloudRetrieve functionality for finding and restoring
   * archived data from a remote Vault.
   * @param params The `RemoteRestoreService.GetRemoteVaultSearchJobResultsParams` containing the following parameters:
   *
   * - `searchJobId`: Specifies the id of the remote Vault search Job assigned
   *   by the Cohesity Cluster. Used in combination with the clusterId
   *   and clusterIncarnationId to uniquely identify the search Job.
   *
   * - `clusterIncarnationId`: Specifies the incarnation id of the Cohesity Cluster where the
   *   search Job was created.
   *   Used in combination with the searchJobId and clusterId to uniquely
   *   identify the search Job.
   *
   * - `clusterId`: Specifies the Cohesity Cluster id where the search Job was created.
   *   Used in combination with the searchJobId and clusterIncarnationId
   *   to uniquely identify the search Job.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `pageCount`: Specifies the number of Protection Jobs to return in the response
   *   to support pagination.
   *
   * - `cookie`: Specifies the opaque string cookie returned by the previous response, to
   *   get next set of results. Used in combination with pageCount to support
   *   pagination.
   *
   * - `clusterName`: Optionally filter the result by the remote Cohesity Cluster name.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetRemoteVaultSearchJobResults(params: RemoteRestoreService.GetRemoteVaultSearchJobResultsParams): __Observable<RemoteVaultSearchJobResults> {
    return this.GetRemoteVaultSearchJobResultsResponse(params).pipe(
      __map(_r => _r.body as RemoteVaultSearchJobResults)
    );
  }

  /**
   * List all the searches of remote Vaults. (CloudRetrieve)
   *
   * List all the searches of remote Vaults (External Targets) that
   * have run or are running on this Cohesity Cluster.
   * A search finds Protection Jobs that have archived to a
   * Vault (External Target).
   * This is part of the CloudRetrieve functionality for finding and restoring
   * archived data from remote Vaults to an alternative (non-original) Cluster.
   *
   * NOTE: A Vault is equivalent to an External Target in the Cohesity Dashboard.
   * A search Job is equivalent to a search task in the Cohesity Dashboard.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  ListRemoteVaultSearchJobsResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<Array<RemoteVaultSearchJobInformation>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/remoteVaults/searchJobs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<RemoteVaultSearchJobInformation>>;
      })
    );
  }
  /**
   * List all the searches of remote Vaults. (CloudRetrieve)
   *
   * List all the searches of remote Vaults (External Targets) that
   * have run or are running on this Cohesity Cluster.
   * A search finds Protection Jobs that have archived to a
   * Vault (External Target).
   * This is part of the CloudRetrieve functionality for finding and restoring
   * archived data from remote Vaults to an alternative (non-original) Cluster.
   *
   * NOTE: A Vault is equivalent to an External Target in the Cohesity Dashboard.
   * A search Job is equivalent to a search task in the Cohesity Dashboard.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  ListRemoteVaultSearchJobs(regionId?: string,
    accessClusterId?: number): __Observable<Array<RemoteVaultSearchJobInformation>> {
    return this.ListRemoteVaultSearchJobsResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as Array<RemoteVaultSearchJobInformation>)
    );
  }

  /**
   * Create a search of a remote Vault. (CloudRetrieve)
   *
   * A search Job finds Protection Jobs that archived data to a
   * Vault (External Target) which also match the specified search criteria.
   * The results can be optionally filtered by specifying a Cluster match string,
   * a Protection Job match string, a start time and an end time.
   * This is part of the CloudRetrieve functionality for finding and restoring
   * archived data from remote Vaults to an alternative (non-original) Cluster.
   *
   * NOTE: A Vault is equivalent to an External Target in the Cohesity Dashboard.
   * A search Job is equivalent to a search task in the Cohesity Dashboard.
   * @param params The `RemoteRestoreService.CreateRemoteVaultSearchJobParams` containing the following parameters:
   *
   * - `body`: Request to create a search of a remote Vault.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateRemoteVaultSearchJobResponse(params: RemoteRestoreService.CreateRemoteVaultSearchJobParams): __Observable<__StrictHttpResponse<CreatedRemoteVaultSearchJobUid>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/remoteVaults/searchJobs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CreatedRemoteVaultSearchJobUid>;
      })
    );
  }
  /**
   * Create a search of a remote Vault. (CloudRetrieve)
   *
   * A search Job finds Protection Jobs that archived data to a
   * Vault (External Target) which also match the specified search criteria.
   * The results can be optionally filtered by specifying a Cluster match string,
   * a Protection Job match string, a start time and an end time.
   * This is part of the CloudRetrieve functionality for finding and restoring
   * archived data from remote Vaults to an alternative (non-original) Cluster.
   *
   * NOTE: A Vault is equivalent to an External Target in the Cohesity Dashboard.
   * A search Job is equivalent to a search task in the Cohesity Dashboard.
   * @param params The `RemoteRestoreService.CreateRemoteVaultSearchJobParams` containing the following parameters:
   *
   * - `body`: Request to create a search of a remote Vault.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateRemoteVaultSearchJob(params: RemoteRestoreService.CreateRemoteVaultSearchJobParams): __Observable<CreatedRemoteVaultSearchJobUid> {
    return this.CreateRemoteVaultSearchJobResponse(params).pipe(
      __map(_r => _r.body as CreatedRemoteVaultSearchJobUid)
    );
  }

  /**
   * Stop a search of a remote Vault (External Target). (CloudRetrieve)
   *
   * This is part of the CloudRetrieve functionality for finding and restoring
   * archived data from remote Vaults to an alternative (non-original) Cluster.
   * @param params The `RemoteRestoreService.StopRemoteVaultSearchJobParams` containing the following parameters:
   *
   * - `body`: Request to stop a Remote Vault Search Job.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  StopRemoteVaultSearchJobResponse(params: RemoteRestoreService.StopRemoteVaultSearchJobParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/public/remoteVaults/searchJobs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Stop a search of a remote Vault (External Target). (CloudRetrieve)
   *
   * This is part of the CloudRetrieve functionality for finding and restoring
   * archived data from remote Vaults to an alternative (non-original) Cluster.
   * @param params The `RemoteRestoreService.StopRemoteVaultSearchJobParams` containing the following parameters:
   *
   * - `body`: Request to stop a Remote Vault Search Job.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  StopRemoteVaultSearchJob(params: RemoteRestoreService.StopRemoteVaultSearchJobParams): __Observable<null> {
    return this.StopRemoteVaultSearchJobResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * List details about a single search Job of a remote Vault. (CloudRetrieve)
   *
   * Specify an id for a completed or running search Job.
   * A search Job finds data that has been archived to a Vault (External Target).
   * The returned results do not include Job Run (Snapshot) information.
   * It is part of the CloudRetrieve functionality for finding and restoring
   * archived data from remote Vaults to an alternative (non-original) Cluster.
   * @param params The `RemoteRestoreService.ListRemoteVaultSearchJobByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the remote Vault search Job to return.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  ListRemoteVaultSearchJobByIdResponse(params: RemoteRestoreService.ListRemoteVaultSearchJobByIdParams): __Observable<__StrictHttpResponse<RemoteVaultSearchJobInformation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/remoteVaults/searchJobs/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RemoteVaultSearchJobInformation>;
      })
    );
  }
  /**
   * List details about a single search Job of a remote Vault. (CloudRetrieve)
   *
   * Specify an id for a completed or running search Job.
   * A search Job finds data that has been archived to a Vault (External Target).
   * The returned results do not include Job Run (Snapshot) information.
   * It is part of the CloudRetrieve functionality for finding and restoring
   * archived data from remote Vaults to an alternative (non-original) Cluster.
   * @param params The `RemoteRestoreService.ListRemoteVaultSearchJobByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the remote Vault search Job to return.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  ListRemoteVaultSearchJobById(params: RemoteRestoreService.ListRemoteVaultSearchJobByIdParams): __Observable<RemoteVaultSearchJobInformation> {
    return this.ListRemoteVaultSearchJobByIdResponse(params).pipe(
      __map(_r => _r.body as RemoteVaultSearchJobInformation)
    );
  }
}

module RemoteRestoreService {

  /**
   * Parameters for UploadVaultEncryptionKeys
   */
  export interface UploadVaultEncryptionKeysParams {

    /**
     * Specifies a unique id of the Vault.
     */
    id: number;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Request to upload encryption keys of a remote Vault.
     */
    body?: Array<VaultEncryptionKey>;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreateRemoteVaultRestoreTask
   */
  export interface CreateRemoteVaultRestoreTaskParams {

    /**
     * Request to create a remote Vault restore task.
     */
    body: CreateRemoteVaultRestoreTaskParameters;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetRemoteVaultSearchJobResults
   */
  export interface GetRemoteVaultSearchJobResultsParams {

    /**
     * Specifies the id of the remote Vault search Job assigned
     * by the Cohesity Cluster. Used in combination with the clusterId
     * and clusterIncarnationId to uniquely identify the search Job.
     */
    searchJobId: number;

    /**
     * Specifies the incarnation id of the Cohesity Cluster where the
     * search Job was created.
     * Used in combination with the searchJobId and clusterId to uniquely
     * identify the search Job.
     */
    clusterIncarnationId: number;

    /**
     * Specifies the Cohesity Cluster id where the search Job was created.
     * Used in combination with the searchJobId and clusterIncarnationId
     * to uniquely identify the search Job.
     */
    clusterId: number;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Specifies the number of Protection Jobs to return in the response
     * to support pagination.
     */
    pageCount?: number;

    /**
     * Specifies the opaque string cookie returned by the previous response, to
     * get next set of results. Used in combination with pageCount to support
     * pagination.
     */
    cookie?: string;

    /**
     * Optionally filter the result by the remote Cohesity Cluster name.
     */
    clusterName?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreateRemoteVaultSearchJob
   */
  export interface CreateRemoteVaultSearchJobParams {

    /**
     * Request to create a search of a remote Vault.
     */
    body: CreateRemoteVaultSearchJobParameters;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for StopRemoteVaultSearchJob
   */
  export interface StopRemoteVaultSearchJobParams {

    /**
     * Request to stop a Remote Vault Search Job.
     */
    body: StopRemoteVaultSearchJobParameters;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for ListRemoteVaultSearchJobById
   */
  export interface ListRemoteVaultSearchJobByIdParams {

    /**
     * Specifies the id of the remote Vault search Job to return.
     */
    id: number;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { RemoteRestoreService }
