/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityApiConfiguration as __Configuration } from '../cohesity-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ListNlmLocksResponse } from '../models/list-nlm-locks-response';
import { ClearNlmLocksParameters } from '../models/clear-nlm-locks-parameters';
import { QoSPolicy } from '../models/qo-spolicy';
import { GetViewsByShareNameResult } from '../models/get-views-by-share-name-result';
import { SmbConnection } from '../models/smb-connection';
import { ViewAlias } from '../models/view-alias';
import { UpdateViewAliasParam } from '../models/update-view-alias-param';
import { ActivateViewAliasesResult } from '../models/activate-view-aliases-result';
import { DirQuotaInfo } from '../models/dir-quota-info';
import { UpdateDirQuotaArgs } from '../models/update-dir-quota-args';
import { ViewUserQuotas } from '../models/view-user-quotas';
import { UserQuotaAndUsage } from '../models/user-quota-and-usage';
import { ViewUserQuotaParameters } from '../models/view-user-quota-parameters';
import { DeleteViewUsersQuotaParameters } from '../models/delete-view-users-quota-parameters';
import { UserQuotaSettings } from '../models/user-quota-settings';
import { UpdateUserQuotaSettingsForView } from '../models/update-user-quota-settings-for-view';
import { GetViewsResult } from '../models/get-views-result';
import { View } from '../models/view';
import { UpdateViewParam } from '../models/update-view-param';
import { CreateViewRequest } from '../models/create-view-request';
import { CloneViewRequest } from '../models/clone-view-request';
import { CloneDirectoryParams } from '../models/clone-directory-params';
import { FileLockStatus } from '../models/file-lock-status';
import { LockFileParams } from '../models/lock-file-params';
import { OverwriteViewParam } from '../models/overwrite-view-param';
import { RenameViewParam } from '../models/rename-view-param';
@Injectable({
  providedIn: 'root',
})
class ViewsService extends __BaseService {
  static readonly ListNlmLocksPath = '/public/nlmLocks';
  static readonly ClearNlmLocksPath = '/public/nlmLocks';
  static readonly GetQoSPoliciesPath = '/public/qosPolicies';
  static readonly GetViewsByShareNamePath = '/public/shares';
  static readonly GetSmbConnectionsPath = '/public/smbConnections';
  static readonly UpdateViewAliasPath = '/public/viewAliases';
  static readonly CreateViewAliasPath = '/public/viewAliases';
  static readonly DeleteViewAliasPath = '/public/viewAliases/{name}';
  static readonly ActivateViewAliasesPath = '/public/viewAliases/{name}/activate';
  static readonly GetViewDirQuotaInfoPath = '/public/viewDirectoryQuotas';
  static readonly UpdateViewDirQuotaPath = '/public/viewDirectoryQuotas';
  static readonly GetViewUserQuotasPath = '/public/viewUserQuotas';
  static readonly UpdateViewUserQuotaPath = '/public/viewUserQuotas';
  static readonly CreateViewUserQuotaPath = '/public/viewUserQuotas';
  static readonly DeleteViewUsersQuotaPath = '/public/viewUserQuotas';
  static readonly UpdateUserQuotaSettingsPath = '/public/viewUserQuotasSettings';
  static readonly GetViewsPath = '/public/views';
  static readonly UpdateViewPath = '/public/views';
  static readonly CreateViewPath = '/public/views';
  static readonly CloneViewPath = '/public/views/clone';
  static readonly CloneDirectoryPath = '/public/views/cloneDirectory';
  static readonly GetViewByIdPath = '/public/views/id/{id}';
  static readonly DeleteViewByIdPath = '/public/views/id/{id}';
  static readonly GetFileLockStatusByIdPath = '/public/views/id/{id}/fileLocks';
  static readonly LockFileByIdPath = '/public/views/id/{id}/fileLocks';
  static readonly OverwriteViewPath = '/public/views/overwrite';
  static readonly RenameViewByIdPath = '/public/views/rename/id/{id}';
  static readonly RenameViewPath = '/public/views/rename/{name}';
  static readonly GetViewByNamePath = '/public/views/{name}';
  static readonly UpdateViewByNamePath = '/public/views/{name}';
  static readonly DeleteViewPath = '/public/views/{name}';
  static readonly GetFileLockStatusPath = '/public/views/{name}/fileLocks';
  static readonly LockFilePath = '/public/views/{name}/fileLocks';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * List the NLM locks that match the filter criteria specified using parameters.
   *
   * If no parameters are specified, all NLM locks currently on the Cohesity Cluster
   * are returned. Specifying parameters filters the results that are returned.
   * @param params The `ViewsService.ListNlmLocksParams` containing the following parameters:
   *
   * - `viewName`: Specifies the name of the View in which to search. If a view name is not
   *   specified, all the views in the Cluster is searched.
   *   This field is mandatory if filePath field is specified.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `pageCount`: Specifies the maximum number of NLM locks to return in the response.
   *   This field cannot be set above 1000. If this is not set, maximum of 1000
   *   entries are returned.
   *
   * - `filePath`: Specifies the filepath in the view relative to the root filesystem.
   *   If this field is specified, viewName field must also be specified.
   *
   * - `cookie`: Specifies the opaque string returned in the previous response.
   *   If this is set, next set of active opens just after the previous
   *   response are returned. If this is not set, first set of NLM locks
   *   are returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  ListNlmLocksResponse(params: ViewsService.ListNlmLocksParams): __Observable<__StrictHttpResponse<ListNlmLocksResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.viewName != null) __params = __params.set('viewName', params.viewName.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.pageCount != null) __params = __params.set('pageCount', params.pageCount.toString());
    if (params.filePath != null) __params = __params.set('filePath', params.filePath.toString());
    if (params.cookie != null) __params = __params.set('cookie', params.cookie.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/nlmLocks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListNlmLocksResponse>;
      })
    );
  }
  /**
   * List the NLM locks that match the filter criteria specified using parameters.
   *
   * If no parameters are specified, all NLM locks currently on the Cohesity Cluster
   * are returned. Specifying parameters filters the results that are returned.
   * @param params The `ViewsService.ListNlmLocksParams` containing the following parameters:
   *
   * - `viewName`: Specifies the name of the View in which to search. If a view name is not
   *   specified, all the views in the Cluster is searched.
   *   This field is mandatory if filePath field is specified.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `pageCount`: Specifies the maximum number of NLM locks to return in the response.
   *   This field cannot be set above 1000. If this is not set, maximum of 1000
   *   entries are returned.
   *
   * - `filePath`: Specifies the filepath in the view relative to the root filesystem.
   *   If this field is specified, viewName field must also be specified.
   *
   * - `cookie`: Specifies the opaque string returned in the previous response.
   *   If this is set, next set of active opens just after the previous
   *   response are returned. If this is not set, first set of NLM locks
   *   are returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  ListNlmLocks(params: ViewsService.ListNlmLocksParams): __Observable<ListNlmLocksResponse> {
    return this.ListNlmLocksResponse(params).pipe(
      __map(_r => _r.body as ListNlmLocksResponse)
    );
  }

  /**
   * Clear NLM locks that match the filter criteria specified using parameters.
   *
   * Returns nothing upon success.
   * @param params The `ViewsService.ClearNlmLocksParams` containing the following parameters:
   *
   * - `body`: Request to clear NLM locks.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  ClearNlmLocksResponse(params: ViewsService.ClearNlmLocksParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/public/nlmLocks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Clear NLM locks that match the filter criteria specified using parameters.
   *
   * Returns nothing upon success.
   * @param params The `ViewsService.ClearNlmLocksParams` containing the following parameters:
   *
   * - `body`: Request to clear NLM locks.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  ClearNlmLocks(params: ViewsService.ClearNlmLocksParams): __Observable<null> {
    return this.ClearNlmLocksResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Fetches QoS Policies of a view.
   *
   * Returns error if op fails.
   * @param params The `ViewsService.GetQoSPoliciesParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `names`: Specifies the names of QoS Policies to filter by.
   *
   * - `ids`: Specifies the Ids of QoS Policies to filter by.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetQoSPoliciesResponse(params: ViewsService.GetQoSPoliciesParams): __Observable<__StrictHttpResponse<Array<QoSPolicy>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.names || []).forEach(val => {if (val != null) __params = __params.append('names', val.toString())});
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/qosPolicies`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<QoSPolicy>>;
      })
    );
  }
  /**
   * Fetches QoS Policies of a view.
   *
   * Returns error if op fails.
   * @param params The `ViewsService.GetQoSPoliciesParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `names`: Specifies the names of QoS Policies to filter by.
   *
   * - `ids`: Specifies the Ids of QoS Policies to filter by.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetQoSPolicies(params: ViewsService.GetQoSPoliciesParams): __Observable<Array<QoSPolicy>> {
    return this.GetQoSPoliciesResponse(params).pipe(
      __map(_r => _r.body as Array<QoSPolicy>)
    );
  }

  /**
   * List shares filtered by name.
   *
   * If no parameters are specified, all shares on the Cohesity Cluster are
   * returned. Specifying share name/prefix filters the results that are returned.
   * NOTE: If maxCount is set and the number of Views returned exceeds the maxCount,
   * there are more Views to return.
   * To get the next set of Views, send another request and specify the pagination
   * cookie from the previous response.
   * @param params The `ViewsService.GetViewsByShareNameParams` containing the following parameters:
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which objects are to be
   *   returned.
   *
   * - `shareName`: The share name(substring) that needs to be searched against existing views
   *   and aliases.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `paginationCookie`: Expected to be empty in the first call to GetViewsByShareName.
   *   To get the next set of results, set this value to the pagination cookie
   *   value returned  in the response of the previous call.
   *
   * - `maxCount`: Specifies a limit on the number of Views returned.
   *
   * - `matchPartialNames`: If true, the names in viewNames are matched by prefix rather than
   *   exactly matched.
   *
   * - `allUnderHierarchy`: AllUnderHierarchy specifies if objects of all the tenants under the
   *   hierarchy of the logged in user's organization should be returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetViewsByShareNameResponse(params: ViewsService.GetViewsByShareNameParams): __Observable<__StrictHttpResponse<GetViewsByShareNameResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.tenantIds || []).forEach(val => {if (val != null) __params = __params.append('tenantIds', val.toString())});
    if (params.shareName != null) __params = __params.set('shareName', params.shareName.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.paginationCookie != null) __params = __params.set('paginationCookie', params.paginationCookie.toString());
    if (params.maxCount != null) __params = __params.set('maxCount', params.maxCount.toString());
    if (params.matchPartialNames != null) __params = __params.set('matchPartialNames', params.matchPartialNames.toString());
    if (params.allUnderHierarchy != null) __params = __params.set('allUnderHierarchy', params.allUnderHierarchy.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/shares`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetViewsByShareNameResult>;
      })
    );
  }
  /**
   * List shares filtered by name.
   *
   * If no parameters are specified, all shares on the Cohesity Cluster are
   * returned. Specifying share name/prefix filters the results that are returned.
   * NOTE: If maxCount is set and the number of Views returned exceeds the maxCount,
   * there are more Views to return.
   * To get the next set of Views, send another request and specify the pagination
   * cookie from the previous response.
   * @param params The `ViewsService.GetViewsByShareNameParams` containing the following parameters:
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which objects are to be
   *   returned.
   *
   * - `shareName`: The share name(substring) that needs to be searched against existing views
   *   and aliases.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `paginationCookie`: Expected to be empty in the first call to GetViewsByShareName.
   *   To get the next set of results, set this value to the pagination cookie
   *   value returned  in the response of the previous call.
   *
   * - `maxCount`: Specifies a limit on the number of Views returned.
   *
   * - `matchPartialNames`: If true, the names in viewNames are matched by prefix rather than
   *   exactly matched.
   *
   * - `allUnderHierarchy`: AllUnderHierarchy specifies if objects of all the tenants under the
   *   hierarchy of the logged in user's organization should be returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetViewsByShareName(params: ViewsService.GetViewsByShareNameParams): __Observable<GetViewsByShareNameResult> {
    return this.GetViewsByShareNameResponse(params).pipe(
      __map(_r => _r.body as GetViewsByShareNameResult)
    );
  }

  /**
   * Fetches Smb Connection Status of a view.
   *
   * Returns error if op fails.
   * @param params The `ViewsService.GetSmbConnectionsParams` containing the following parameters:
   *
   * - `viewNames`: Parameters to get Smb Connections.
   *   List of names of views whose connections are to be fetched.
   *
   * - `viewIds`: List of ids of views whose connections are to be fetched.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `maxCount`: Maximum number of results to return.
   *
   * - `includeSid`: Specifies whether to include list of sids in the result.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetSmbConnectionsResponse(params: ViewsService.GetSmbConnectionsParams): __Observable<__StrictHttpResponse<Array<SmbConnection>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.viewNames || []).forEach(val => {if (val != null) __params = __params.append('viewNames', val.toString())});
    (params.viewIds || []).forEach(val => {if (val != null) __params = __params.append('viewIds', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.maxCount != null) __params = __params.set('maxCount', params.maxCount.toString());
    if (params.includeSid != null) __params = __params.set('includeSid', params.includeSid.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/smbConnections`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<SmbConnection>>;
      })
    );
  }
  /**
   * Fetches Smb Connection Status of a view.
   *
   * Returns error if op fails.
   * @param params The `ViewsService.GetSmbConnectionsParams` containing the following parameters:
   *
   * - `viewNames`: Parameters to get Smb Connections.
   *   List of names of views whose connections are to be fetched.
   *
   * - `viewIds`: List of ids of views whose connections are to be fetched.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `maxCount`: Maximum number of results to return.
   *
   * - `includeSid`: Specifies whether to include list of sids in the result.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetSmbConnections(params: ViewsService.GetSmbConnectionsParams): __Observable<Array<SmbConnection>> {
    return this.GetSmbConnectionsResponse(params).pipe(
      __map(_r => _r.body as Array<SmbConnection>)
    );
  }

  /**
   * Update a View Alias. A View Alias allows a directory inside the view to be
   * mounted without specifying the entire path.
   *
   * Returns the updated View Alias.
   * @param params The `ViewsService.UpdateViewAliasParams` containing the following parameters:
   *
   * - `body`: Request to update a View.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateViewAliasResponse(params: ViewsService.UpdateViewAliasParams): __Observable<__StrictHttpResponse<ViewAlias>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/public/viewAliases`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ViewAlias>;
      })
    );
  }
  /**
   * Update a View Alias. A View Alias allows a directory inside the view to be
   * mounted without specifying the entire path.
   *
   * Returns the updated View Alias.
   * @param params The `ViewsService.UpdateViewAliasParams` containing the following parameters:
   *
   * - `body`: Request to update a View.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateViewAlias(params: ViewsService.UpdateViewAliasParams): __Observable<ViewAlias> {
    return this.UpdateViewAliasResponse(params).pipe(
      __map(_r => _r.body as ViewAlias)
    );
  }

  /**
   * Create a View Alias. A View Alias allows a directory inside the view to be
   * mounted without specifying the entire path.
   *
   * Returns the created View Alias.
   * @param params The `ViewsService.CreateViewAliasParams` containing the following parameters:
   *
   * - `body`: Request to create a View Alias.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateViewAliasResponse(params: ViewsService.CreateViewAliasParams): __Observable<__StrictHttpResponse<ViewAlias>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/viewAliases`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ViewAlias>;
      })
    );
  }
  /**
   * Create a View Alias. A View Alias allows a directory inside the view to be
   * mounted without specifying the entire path.
   *
   * Returns the created View Alias.
   * @param params The `ViewsService.CreateViewAliasParams` containing the following parameters:
   *
   * - `body`: Request to create a View Alias.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateViewAlias(params: ViewsService.CreateViewAliasParams): __Observable<ViewAlias> {
    return this.CreateViewAliasResponse(params).pipe(
      __map(_r => _r.body as ViewAlias)
    );
  }

  /**
   * Delete a View Alias.
   *
   * Returns delete status upon completion.
   * @param params The `ViewsService.DeleteViewAliasParams` containing the following parameters:
   *
   * - `name`: Specifies the View Alias name.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteViewAliasResponse(params: ViewsService.DeleteViewAliasParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/public/viewAliases/${encodeURIComponent(params.name)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete a View Alias.
   *
   * Returns delete status upon completion.
   * @param params The `ViewsService.DeleteViewAliasParams` containing the following parameters:
   *
   * - `name`: Specifies the View Alias name.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteViewAlias(params: ViewsService.DeleteViewAliasParams): __Observable<null> {
    return this.DeleteViewAliasResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Activates the view aliases of a view from it's source view.
   *
   * Returns error if op fails.
   * @param params The `ViewsService.ActivateViewAliasesParams` containing the following parameters:
   *
   * - `name`: Specifies the View name.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  ActivateViewAliasesResponse(params: ViewsService.ActivateViewAliasesParams): __Observable<__StrictHttpResponse<ActivateViewAliasesResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/viewAliases/${encodeURIComponent(params.name)}/activate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ActivateViewAliasesResult>;
      })
    );
  }
  /**
   * Activates the view aliases of a view from it's source view.
   *
   * Returns error if op fails.
   * @param params The `ViewsService.ActivateViewAliasesParams` containing the following parameters:
   *
   * - `name`: Specifies the View name.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  ActivateViewAliases(params: ViewsService.ActivateViewAliasesParams): __Observable<ActivateViewAliasesResult> {
    return this.ActivateViewAliasesResponse(params).pipe(
      __map(_r => _r.body as ActivateViewAliasesResult)
    );
  }

  /**
   * Gets directory quota info for a view. Returns error if op fails.
   * @param params The `ViewsService.GetViewDirQuotaInfoParams` containing the following parameters:
   *
   * - `viewName`: The name of the view.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `pageCount`: Specifies the max entries that should be returned in the result.
   *
   * - `outputFormat`: Specifies the format for the output such as 'csv' or 'json'.
   *   If not specified, the json format is returned.
   *   If 'csv' is specified, a comma-separated list with a heading
   *   row is returned.
   *
   * - `cookie`: Cookie should be used from previous call to list user quota overrides. It
   *   resumes (or gives the next set of values) from the result of the previous
   *   call.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetViewDirQuotaInfoResponse(params: ViewsService.GetViewDirQuotaInfoParams): __Observable<__StrictHttpResponse<DirQuotaInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.viewName != null) __params = __params.set('viewName', params.viewName.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.pageCount != null) __params = __params.set('pageCount', params.pageCount.toString());
    if (params.outputFormat != null) __params = __params.set('outputFormat', params.outputFormat.toString());
    if (params.cookie != null) __params = __params.set('cookie', params.cookie.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/viewDirectoryQuotas`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DirQuotaInfo>;
      })
    );
  }
  /**
   * Gets directory quota info for a view. Returns error if op fails.
   * @param params The `ViewsService.GetViewDirQuotaInfoParams` containing the following parameters:
   *
   * - `viewName`: The name of the view.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `pageCount`: Specifies the max entries that should be returned in the result.
   *
   * - `outputFormat`: Specifies the format for the output such as 'csv' or 'json'.
   *   If not specified, the json format is returned.
   *   If 'csv' is specified, a comma-separated list with a heading
   *   row is returned.
   *
   * - `cookie`: Cookie should be used from previous call to list user quota overrides. It
   *   resumes (or gives the next set of values) from the result of the previous
   *   call.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetViewDirQuotaInfo(params: ViewsService.GetViewDirQuotaInfoParams): __Observable<DirQuotaInfo> {
    return this.GetViewDirQuotaInfoResponse(params).pipe(
      __map(_r => _r.body as DirQuotaInfo)
    );
  }

  /**
   * Updates a directory quota policy for a view. Returns error if op fails.
   * @param params The `ViewsService.UpdateViewDirQuotaParams` containing the following parameters:
   *
   * - `body`: Request to update view directory quota policy.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateViewDirQuotaResponse(params: ViewsService.UpdateViewDirQuotaParams): __Observable<__StrictHttpResponse<DirQuotaInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/public/viewDirectoryQuotas`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DirQuotaInfo>;
      })
    );
  }
  /**
   * Updates a directory quota policy for a view. Returns error if op fails.
   * @param params The `ViewsService.UpdateViewDirQuotaParams` containing the following parameters:
   *
   * - `body`: Request to update view directory quota policy.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateViewDirQuota(params: ViewsService.UpdateViewDirQuotaParams): __Observable<DirQuotaInfo> {
    return this.UpdateViewDirQuotaResponse(params).pipe(
      __map(_r => _r.body as DirQuotaInfo)
    );
  }

  /**
   * Get the quota policies, usage and summary for a view for all its users. It
   * can also fetch the quota policies, usage and summary for a user in all his
   * views.
   *
   * Returns error if op fails.
   * @param params The `ViewsService.GetViewUserQuotasParams` containing the following parameters:
   *
   * - `viewNamesForUser`: While making a query for a user, this specifies a list of specific views
   *   for the result.
   *
   * - `viewName`: Specifies the name of the input view. If given, there could be three
   *   scenarios with the viewName input parameter:
   *   It gives the user quota overrides for this view, and the user quota
   *   settings. Returns 'usersQuotaAndUsage'.
   *   If given along with the user id, it returns the quota policy for this
   *   user on this view. Returns 'usersQuotaAndUsage'.
   *   If given along with SummaryOnly as true, a user quota summary for
   *   this view would be returned. Returns 'summaryForView'.
   *   If not given, then the user id is checked.
   *
   * - `userUnixIdsForView`: While making a query for a view, this specifies a list of specific users
   *   with their unix uid for the result.
   *
   * - `userSidsForView`: While making a query for a view, this specifies a list of specific users
   *   with their Sid for the result.
   *
   * - `unixUid`: If interested in a user via unix-identifier, include UnixUid.
   *   Otherwise, If a valid unix-id to SID mappings are available (i.e., when
   *   mixed mode is enabled) the server will perform the necessary id mapping
   *   and return the correct usage irrespective of whether the unix id / SID is
   *   provided.
   *
   * - `topQuotas`: TopQuotas is the quotas sorted by quota usage in descending order.
   *   This parameter defines number of results returned. No pagination cookie is
   *   returned if this is set to true.
   *
   * - `summaryOnly`: Specifies a flag to just return a summary. If set to true, and if ViewName
   *   is not nil, it returns the summary of users for a view. Otherwise if
   *   UserId not nil, and ViewName is nil then it fetches the summary for a
   *   user in his views.  By default, it is set to false.
   *
   * - `sid`: If interested in a user via smb_client, include SID.
   *   Otherwise, If a valid unix-id to SID mappings are available (i.e., when
   *   mixed mode is enabled) the server will perform the necessary id mapping
   *   and return the correct usage irrespective of whether the unix id / SID is
   *   provided.
   *   The string is of following format -
   *   S-1-IdentifierAuthority-SubAuthority1-SubAuthority2-...-SubAuthorityn.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `pageCount`: Specifies the max entries that should be returned in the result.
   *
   * - `outputFormat`: OutputFormat is the Output format for the output. If it is not specified,
   *   default is json.
   *
   * - `maxViewId`: Related to fetching a particular user's quota and usage in all his views.
   *   It only pertains to the scenario where either UnixUid or Sid is specified,
   *   and ViewName is nil. Specify the maxViewId for All the views returned
   *   would have view_id's less than or equal to the given MaxViewId if it is
   *   >= 0.
   *
   * - `includeUserWithQuotaOnly`: If set to true, the result will only contain user with user quota enabled.
   *   By default, this field is set to false, and it's only in effect when
   *   'SummaryOnly' is set to false and 'ViewName' is specified.
   *
   * - `includeUsage`: If set to true, the logical usage info is included only for users with
   *   quota overrides. By default, it is set to false.
   *
   * - `excludeUsersWithinAlertThreshold`: This field can be set only when includeUsage is set to true.
   *   By default, all the users with logical usage > 0 will be returned in the
   *   result. If this field is set to true, only the list of users who has
   *   exceeded the alert threshold will be returned.
   *
   * - `cookie`: Cookie should be used from previous call to list user quota overrides. It
   *   resumes (or gives the next set of values) from the result of the previous
   *   call.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetViewUserQuotasResponse(params: ViewsService.GetViewUserQuotasParams): __Observable<__StrictHttpResponse<ViewUserQuotas>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.viewNamesForUser || []).forEach(val => {if (val != null) __params = __params.append('viewNamesForUser', val.toString())});
    if (params.viewName != null) __params = __params.set('viewName', params.viewName.toString());
    (params.userUnixIdsForView || []).forEach(val => {if (val != null) __params = __params.append('userUnixIdsForView', val.toString())});
    (params.userSidsForView || []).forEach(val => {if (val != null) __params = __params.append('userSidsForView', val.toString())});
    if (params.unixUid != null) __params = __params.set('unixUid', params.unixUid.toString());
    if (params.topQuotas != null) __params = __params.set('topQuotas', params.topQuotas.toString());
    if (params.summaryOnly != null) __params = __params.set('summaryOnly', params.summaryOnly.toString());
    if (params.sid != null) __params = __params.set('sid', params.sid.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.pageCount != null) __params = __params.set('pageCount', params.pageCount.toString());
    if (params.outputFormat != null) __params = __params.set('outputFormat', params.outputFormat.toString());
    if (params.maxViewId != null) __params = __params.set('maxViewId', params.maxViewId.toString());
    if (params.includeUserWithQuotaOnly != null) __params = __params.set('includeUserWithQuotaOnly', params.includeUserWithQuotaOnly.toString());
    if (params.includeUsage != null) __params = __params.set('includeUsage', params.includeUsage.toString());
    if (params.excludeUsersWithinAlertThreshold != null) __params = __params.set('excludeUsersWithinAlertThreshold', params.excludeUsersWithinAlertThreshold.toString());
    if (params.cookie != null) __params = __params.set('cookie', params.cookie.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/viewUserQuotas`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ViewUserQuotas>;
      })
    );
  }
  /**
   * Get the quota policies, usage and summary for a view for all its users. It
   * can also fetch the quota policies, usage and summary for a user in all his
   * views.
   *
   * Returns error if op fails.
   * @param params The `ViewsService.GetViewUserQuotasParams` containing the following parameters:
   *
   * - `viewNamesForUser`: While making a query for a user, this specifies a list of specific views
   *   for the result.
   *
   * - `viewName`: Specifies the name of the input view. If given, there could be three
   *   scenarios with the viewName input parameter:
   *   It gives the user quota overrides for this view, and the user quota
   *   settings. Returns 'usersQuotaAndUsage'.
   *   If given along with the user id, it returns the quota policy for this
   *   user on this view. Returns 'usersQuotaAndUsage'.
   *   If given along with SummaryOnly as true, a user quota summary for
   *   this view would be returned. Returns 'summaryForView'.
   *   If not given, then the user id is checked.
   *
   * - `userUnixIdsForView`: While making a query for a view, this specifies a list of specific users
   *   with their unix uid for the result.
   *
   * - `userSidsForView`: While making a query for a view, this specifies a list of specific users
   *   with their Sid for the result.
   *
   * - `unixUid`: If interested in a user via unix-identifier, include UnixUid.
   *   Otherwise, If a valid unix-id to SID mappings are available (i.e., when
   *   mixed mode is enabled) the server will perform the necessary id mapping
   *   and return the correct usage irrespective of whether the unix id / SID is
   *   provided.
   *
   * - `topQuotas`: TopQuotas is the quotas sorted by quota usage in descending order.
   *   This parameter defines number of results returned. No pagination cookie is
   *   returned if this is set to true.
   *
   * - `summaryOnly`: Specifies a flag to just return a summary. If set to true, and if ViewName
   *   is not nil, it returns the summary of users for a view. Otherwise if
   *   UserId not nil, and ViewName is nil then it fetches the summary for a
   *   user in his views.  By default, it is set to false.
   *
   * - `sid`: If interested in a user via smb_client, include SID.
   *   Otherwise, If a valid unix-id to SID mappings are available (i.e., when
   *   mixed mode is enabled) the server will perform the necessary id mapping
   *   and return the correct usage irrespective of whether the unix id / SID is
   *   provided.
   *   The string is of following format -
   *   S-1-IdentifierAuthority-SubAuthority1-SubAuthority2-...-SubAuthorityn.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `pageCount`: Specifies the max entries that should be returned in the result.
   *
   * - `outputFormat`: OutputFormat is the Output format for the output. If it is not specified,
   *   default is json.
   *
   * - `maxViewId`: Related to fetching a particular user's quota and usage in all his views.
   *   It only pertains to the scenario where either UnixUid or Sid is specified,
   *   and ViewName is nil. Specify the maxViewId for All the views returned
   *   would have view_id's less than or equal to the given MaxViewId if it is
   *   >= 0.
   *
   * - `includeUserWithQuotaOnly`: If set to true, the result will only contain user with user quota enabled.
   *   By default, this field is set to false, and it's only in effect when
   *   'SummaryOnly' is set to false and 'ViewName' is specified.
   *
   * - `includeUsage`: If set to true, the logical usage info is included only for users with
   *   quota overrides. By default, it is set to false.
   *
   * - `excludeUsersWithinAlertThreshold`: This field can be set only when includeUsage is set to true.
   *   By default, all the users with logical usage > 0 will be returned in the
   *   result. If this field is set to true, only the list of users who has
   *   exceeded the alert threshold will be returned.
   *
   * - `cookie`: Cookie should be used from previous call to list user quota overrides. It
   *   resumes (or gives the next set of values) from the result of the previous
   *   call.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetViewUserQuotas(params: ViewsService.GetViewUserQuotasParams): __Observable<ViewUserQuotas> {
    return this.GetViewUserQuotasResponse(params).pipe(
      __map(_r => _r.body as ViewUserQuotas)
    );
  }

  /**
   * Update a new quota policy for a user in a view.
   *
   * Returns error if op fails.
   * @param params The `ViewsService.UpdateViewUserQuotaParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: update user quota params.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateViewUserQuotaResponse(params: ViewsService.UpdateViewUserQuotaParams): __Observable<__StrictHttpResponse<UserQuotaAndUsage>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    __body = params.body;
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/public/viewUserQuotas`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserQuotaAndUsage>;
      })
    );
  }
  /**
   * Update a new quota policy for a user in a view.
   *
   * Returns error if op fails.
   * @param params The `ViewsService.UpdateViewUserQuotaParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: update user quota params.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateViewUserQuota(params: ViewsService.UpdateViewUserQuotaParams): __Observable<UserQuotaAndUsage> {
    return this.UpdateViewUserQuotaResponse(params).pipe(
      __map(_r => _r.body as UserQuotaAndUsage)
    );
  }

  /**
   * Create a new quota policy for a user in a view.
   *
   * Returns error if op fails.
   * @param params The `ViewsService.CreateViewUserQuotaParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: update user quota params.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateViewUserQuotaResponse(params: ViewsService.CreateViewUserQuotaParams): __Observable<__StrictHttpResponse<UserQuotaAndUsage>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    __body = params.body;
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/viewUserQuotas`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserQuotaAndUsage>;
      })
    );
  }
  /**
   * Create a new quota policy for a user in a view.
   *
   * Returns error if op fails.
   * @param params The `ViewsService.CreateViewUserQuotaParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: update user quota params.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateViewUserQuota(params: ViewsService.CreateViewUserQuotaParams): __Observable<UserQuotaAndUsage> {
    return this.CreateViewUserQuotaResponse(params).pipe(
      __map(_r => _r.body as UserQuotaAndUsage)
    );
  }

  /**
   * Delete the quota policy overrides for users in a view.
   *
   * Returns error if op fails.
   * @param params The `ViewsService.DeleteViewUsersQuotaParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: update user quota params.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteViewUsersQuotaResponse(params: ViewsService.DeleteViewUsersQuotaParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    __body = params.body;
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/public/viewUserQuotas`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete the quota policy overrides for users in a view.
   *
   * Returns error if op fails.
   * @param params The `ViewsService.DeleteViewUsersQuotaParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: update user quota params.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteViewUsersQuota(params: ViewsService.DeleteViewUsersQuotaParams): __Observable<null> {
    return this.DeleteViewUsersQuotaResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Update the user quota settings in a view.
   *
   * Returns error if op fails.
   * @param params The `ViewsService.UpdateUserQuotaSettingsParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: update user quota metadata params.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return The User Quota settings applied to a view.
   */
  UpdateUserQuotaSettingsResponse(params: ViewsService.UpdateUserQuotaSettingsParams): __Observable<__StrictHttpResponse<UserQuotaSettings>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    __body = params.body;
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/public/viewUserQuotasSettings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserQuotaSettings>;
      })
    );
  }
  /**
   * Update the user quota settings in a view.
   *
   * Returns error if op fails.
   * @param params The `ViewsService.UpdateUserQuotaSettingsParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: update user quota metadata params.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return The User Quota settings applied to a view.
   */
  UpdateUserQuotaSettings(params: ViewsService.UpdateUserQuotaSettingsParams): __Observable<UserQuotaSettings> {
    return this.UpdateUserQuotaSettingsResponse(params).pipe(
      __map(_r => _r.body as UserQuotaSettings)
    );
  }

  /**
   * List Views filtered by some parameters.
   *
   * If no parameters are specified, all Views on the Cohesity Cluster are returned.
   * Specifying parameters filters the results that are returned.
   * NOTE: If maxCount is set and the number of Views returned exceeds the maxCount,
   * there are more Views to return.
   * To get the next set of Views, send another request and specify the id of the
   * last View returned in viewList from the previous response.
   * @param params The `ViewsService.GetViewsParams` containing the following parameters:
   *
   * - `viewNames`: Filter by a list of View names.
   *
   * - `viewIds`: Filter by a list of View ids.
   *
   * - `viewBoxNames`: Filter by a list of View Box names.
   *
   * - `viewBoxIds`: Filter by a list of Storage Domains (View Boxes) specified by id.
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which objects are to be
   *   returned.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `maxViewId`: If the number of Views to return exceeds the maxCount specified in the
   *   original request, specify the id of the last View
   *   from the viewList in the previous response
   *   to get the next set of Views.
   *
   * - `maxCount`: Specifies a limit on the number of Views returned.
   *
   * - `matchPartialNames`: If true, the names in viewNames are matched by prefix rather than
   *   exactly matched.
   *
   * - `matchAliasNames`: If true, view aliases are also matched with the names in viewNames.
   *
   * - `jobIds`: Filter by Protection Job ids. Return Views that are being protected by
   *   listed Jobs, which are specified by ids.
   *
   * - `includeViewsWithDataLockEnabledOnly`: If set to true, the list will contain only the views for which either
   *   file level data lock is enabled or view level data lock is enabled.
   *
   * - `includeViewsWithAntivirusEnabledOnly`: If set to true, the list will contain only the views for which antivirus
   *   scan is enabled.
   *
   * - `includeStats`: If set to true, stats of views will be returned. By default this parameter
   *   is set to false.
   *
   * - `includeProtectionJobs`: Specifies if Protection Jobs information needs to be returned along with
   *   view metadata. By default, if not set or set to true, Job information is
   *   returned.
   *
   * - `includeInactive`: Specifies if inactive Views on this Remote Cluster (which have
   *   Snapshots copied by replication) should also be returned.
   *   Inactive Views are not counted towards the maxCount.
   *   By default, this field is set to false.
   *
   * - `allUnderHierarchy`: AllUnderHierarchy specifies if objects of all the tenants under the
   *   hierarchy of the logged in user's organization should be returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * - `SortByLogicalUsage`: If set to true, the list is sorted descending by logical usage.
   *
   * @return Success
   */
  GetViewsResponse(params: ViewsService.GetViewsParams): __Observable<__StrictHttpResponse<GetViewsResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.viewNames || []).forEach(val => {if (val != null) __params = __params.append('viewNames', val.toString())});
    (params.viewIds || []).forEach(val => {if (val != null) __params = __params.append('viewIds', val.toString())});
    (params.viewBoxNames || []).forEach(val => {if (val != null) __params = __params.append('viewBoxNames', val.toString())});
    (params.viewBoxIds || []).forEach(val => {if (val != null) __params = __params.append('viewBoxIds', val.toString())});
    (params.tenantIds || []).forEach(val => {if (val != null) __params = __params.append('tenantIds', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.maxViewId != null) __params = __params.set('maxViewId', params.maxViewId.toString());
    if (params.maxCount != null) __params = __params.set('maxCount', params.maxCount.toString());
    if (params.matchPartialNames != null) __params = __params.set('matchPartialNames', params.matchPartialNames.toString());
    if (params.matchAliasNames != null) __params = __params.set('matchAliasNames', params.matchAliasNames.toString());
    (params.jobIds || []).forEach(val => {if (val != null) __params = __params.append('jobIds', val.toString())});
    if (params.includeViewsWithDataLockEnabledOnly != null) __params = __params.set('includeViewsWithDataLockEnabledOnly', params.includeViewsWithDataLockEnabledOnly.toString());
    if (params.includeViewsWithAntivirusEnabledOnly != null) __params = __params.set('includeViewsWithAntivirusEnabledOnly', params.includeViewsWithAntivirusEnabledOnly.toString());
    if (params.includeStats != null) __params = __params.set('includeStats', params.includeStats.toString());
    if (params.includeProtectionJobs != null) __params = __params.set('includeProtectionJobs', params.includeProtectionJobs.toString());
    if (params.includeInactive != null) __params = __params.set('includeInactive', params.includeInactive.toString());
    if (params.allUnderHierarchy != null) __params = __params.set('allUnderHierarchy', params.allUnderHierarchy.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    if (params.SortByLogicalUsage != null) __params = __params.set('SortByLogicalUsage', params.SortByLogicalUsage.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/views`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetViewsResult>;
      })
    );
  }
  /**
   * List Views filtered by some parameters.
   *
   * If no parameters are specified, all Views on the Cohesity Cluster are returned.
   * Specifying parameters filters the results that are returned.
   * NOTE: If maxCount is set and the number of Views returned exceeds the maxCount,
   * there are more Views to return.
   * To get the next set of Views, send another request and specify the id of the
   * last View returned in viewList from the previous response.
   * @param params The `ViewsService.GetViewsParams` containing the following parameters:
   *
   * - `viewNames`: Filter by a list of View names.
   *
   * - `viewIds`: Filter by a list of View ids.
   *
   * - `viewBoxNames`: Filter by a list of View Box names.
   *
   * - `viewBoxIds`: Filter by a list of Storage Domains (View Boxes) specified by id.
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which objects are to be
   *   returned.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `maxViewId`: If the number of Views to return exceeds the maxCount specified in the
   *   original request, specify the id of the last View
   *   from the viewList in the previous response
   *   to get the next set of Views.
   *
   * - `maxCount`: Specifies a limit on the number of Views returned.
   *
   * - `matchPartialNames`: If true, the names in viewNames are matched by prefix rather than
   *   exactly matched.
   *
   * - `matchAliasNames`: If true, view aliases are also matched with the names in viewNames.
   *
   * - `jobIds`: Filter by Protection Job ids. Return Views that are being protected by
   *   listed Jobs, which are specified by ids.
   *
   * - `includeViewsWithDataLockEnabledOnly`: If set to true, the list will contain only the views for which either
   *   file level data lock is enabled or view level data lock is enabled.
   *
   * - `includeViewsWithAntivirusEnabledOnly`: If set to true, the list will contain only the views for which antivirus
   *   scan is enabled.
   *
   * - `includeStats`: If set to true, stats of views will be returned. By default this parameter
   *   is set to false.
   *
   * - `includeProtectionJobs`: Specifies if Protection Jobs information needs to be returned along with
   *   view metadata. By default, if not set or set to true, Job information is
   *   returned.
   *
   * - `includeInactive`: Specifies if inactive Views on this Remote Cluster (which have
   *   Snapshots copied by replication) should also be returned.
   *   Inactive Views are not counted towards the maxCount.
   *   By default, this field is set to false.
   *
   * - `allUnderHierarchy`: AllUnderHierarchy specifies if objects of all the tenants under the
   *   hierarchy of the logged in user's organization should be returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * - `SortByLogicalUsage`: If set to true, the list is sorted descending by logical usage.
   *
   * @return Success
   */
  GetViews(params: ViewsService.GetViewsParams): __Observable<GetViewsResult> {
    return this.GetViewsResponse(params).pipe(
      __map(_r => _r.body as GetViewsResult)
    );
  }

  /**
   * Update a View.
   *
   * Returns the updated View.
   * @param params The `ViewsService.UpdateViewParams` containing the following parameters:
   *
   * - `body`: Request to update a view.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateViewResponse(params: ViewsService.UpdateViewParams): __Observable<__StrictHttpResponse<View>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/public/views`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<View>;
      })
    );
  }
  /**
   * Update a View.
   *
   * Returns the updated View.
   * @param params The `ViewsService.UpdateViewParams` containing the following parameters:
   *
   * - `body`: Request to update a view.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateView(params: ViewsService.UpdateViewParams): __Observable<View> {
    return this.UpdateViewResponse(params).pipe(
      __map(_r => _r.body as View)
    );
  }

  /**
   * Create a View.
   *
   * Returns the created View.
   * @param params The `ViewsService.CreateViewParams` containing the following parameters:
   *
   * - `body`: Request to create a View.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateViewResponse(params: ViewsService.CreateViewParams): __Observable<__StrictHttpResponse<View>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/views`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<View>;
      })
    );
  }
  /**
   * Create a View.
   *
   * Returns the created View.
   * @param params The `ViewsService.CreateViewParams` containing the following parameters:
   *
   * - `body`: Request to create a View.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateView(params: ViewsService.CreateViewParams): __Observable<View> {
    return this.CreateViewResponse(params).pipe(
      __map(_r => _r.body as View)
    );
  }

  /**
   * Clone a View.
   *
   * Returns the cloned View.
   * @param params The `ViewsService.CloneViewParams` containing the following parameters:
   *
   * - `body`: Request to clone a View.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CloneViewResponse(params: ViewsService.CloneViewParams): __Observable<__StrictHttpResponse<View>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/views/clone`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<View>;
      })
    );
  }
  /**
   * Clone a View.
   *
   * Returns the cloned View.
   * @param params The `ViewsService.CloneViewParams` containing the following parameters:
   *
   * - `body`: Request to clone a View.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CloneView(params: ViewsService.CloneViewParams): __Observable<View> {
    return this.CloneViewResponse(params).pipe(
      __map(_r => _r.body as View)
    );
  }

  /**
   * Clone a directory of a view.
   *
   * Returns error if op fails.
   * @param params The `ViewsService.CloneDirectoryParams` containing the following parameters:
   *
   * - `body`: Request to clone a directory.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  CloneDirectoryResponse(params: ViewsService.CloneDirectoryParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/views/cloneDirectory`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Clone a directory of a view.
   *
   * Returns error if op fails.
   * @param params The `ViewsService.CloneDirectoryParams` containing the following parameters:
   *
   * - `body`: Request to clone a directory.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  CloneDirectory(params: ViewsService.CloneDirectoryParams): __Observable<null> {
    return this.CloneDirectoryResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * List details about a single View.
   *
   * Returns the View corresponding to the specified View id.
   * @param params The `ViewsService.GetViewByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the View id.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetViewByIdResponse(params: ViewsService.GetViewByIdParams): __Observable<__StrictHttpResponse<View>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/views/id/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<View>;
      })
    );
  }
  /**
   * List details about a single View.
   *
   * Returns the View corresponding to the specified View id.
   * @param params The `ViewsService.GetViewByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the View id.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetViewById(params: ViewsService.GetViewByIdParams): __Observable<View> {
    return this.GetViewByIdResponse(params).pipe(
      __map(_r => _r.body as View)
    );
  }

  /**
   * Delete a View.
   *
   * Returns delete status upon completion.
   * @param params The `ViewsService.DeleteViewByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the View id.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteViewByIdResponse(params: ViewsService.DeleteViewByIdParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/public/views/id/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete a View.
   *
   * Returns delete status upon completion.
   * @param params The `ViewsService.DeleteViewByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the View id.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteViewById(params: ViewsService.DeleteViewByIdParams): __Observable<null> {
    return this.DeleteViewByIdResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Fetches the lock status of a file in a view.
   *
   * Returns error if op fails.
   * @param params The `ViewsService.GetFileLockStatusByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the View id.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `path`: Specifies the file path that needs to be locked.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Get lock file status response
   */
  GetFileLockStatusByIdResponse(params: ViewsService.GetFileLockStatusByIdParams): __Observable<__StrictHttpResponse<FileLockStatus>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.path != null) __params = __params.set('path', params.path.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/views/id/${encodeURIComponent(params.id)}/fileLocks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FileLockStatus>;
      })
    );
  }
  /**
   * Fetches the lock status of a file in a view.
   *
   * Returns error if op fails.
   * @param params The `ViewsService.GetFileLockStatusByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the View id.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `path`: Specifies the file path that needs to be locked.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Get lock file status response
   */
  GetFileLockStatusById(params: ViewsService.GetFileLockStatusByIdParams): __Observable<FileLockStatus> {
    return this.GetFileLockStatusByIdResponse(params).pipe(
      __map(_r => _r.body as FileLockStatus)
    );
  }

  /**
   * Lock a file in a view and optionally update its expiry timestamp.
   *
   * Returns error if op fails.
   * @param params The `ViewsService.LockFileByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the View id.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: Request to lock a file.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Get lock file status response
   */
  LockFileByIdResponse(params: ViewsService.LockFileByIdParams): __Observable<__StrictHttpResponse<FileLockStatus>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    __body = params.body;
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/views/id/${encodeURIComponent(params.id)}/fileLocks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FileLockStatus>;
      })
    );
  }
  /**
   * Lock a file in a view and optionally update its expiry timestamp.
   *
   * Returns error if op fails.
   * @param params The `ViewsService.LockFileByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the View id.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: Request to lock a file.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Get lock file status response
   */
  LockFileById(params: ViewsService.LockFileByIdParams): __Observable<FileLockStatus> {
    return this.LockFileByIdResponse(params).pipe(
      __map(_r => _r.body as FileLockStatus)
    );
  }

  /**
   * Overwrites a Target view with contents of a Source view.
   *
   * Specifies source and target view names as params.
   * Returns the modified Target View.
   * @param params The `ViewsService.OverwriteViewParams` containing the following parameters:
   *
   * - `body`: Request to overwrite a Target view with contents of a Source view.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  OverwriteViewResponse(params: ViewsService.OverwriteViewParams): __Observable<__StrictHttpResponse<View>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/views/overwrite`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<View>;
      })
    );
  }
  /**
   * Overwrites a Target view with contents of a Source view.
   *
   * Specifies source and target view names as params.
   * Returns the modified Target View.
   * @param params The `ViewsService.OverwriteViewParams` containing the following parameters:
   *
   * - `body`: Request to overwrite a Target view with contents of a Source view.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  OverwriteView(params: ViewsService.OverwriteViewParams): __Observable<View> {
    return this.OverwriteViewResponse(params).pipe(
      __map(_r => _r.body as View)
    );
  }

  /**
   * Rename a View.
   *
   * Specify unique id of the View in the 'id' parameter.
   * Returns the renamed View.
   * @param params The `ViewsService.RenameViewByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the View id.
   *
   * - `body`: Request to rename a View.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  RenameViewByIdResponse(params: ViewsService.RenameViewByIdParams): __Observable<__StrictHttpResponse<View>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/views/rename/id/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<View>;
      })
    );
  }
  /**
   * Rename a View.
   *
   * Specify unique id of the View in the 'id' parameter.
   * Returns the renamed View.
   * @param params The `ViewsService.RenameViewByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the View id.
   *
   * - `body`: Request to rename a View.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  RenameViewById(params: ViewsService.RenameViewByIdParams): __Observable<View> {
    return this.RenameViewByIdResponse(params).pipe(
      __map(_r => _r.body as View)
    );
  }

  /**
   * Rename a View.
   *
   * Specify original name of the View in the 'name' parameter.
   * Returns the renamed View.
   * @param params The `ViewsService.RenameViewParams` containing the following parameters:
   *
   * - `name`: Specifies the View name.
   *
   * - `body`: Request to rename a View.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  RenameViewResponse(params: ViewsService.RenameViewParams): __Observable<__StrictHttpResponse<View>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/views/rename/${encodeURIComponent(params.name)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<View>;
      })
    );
  }
  /**
   * Rename a View.
   *
   * Specify original name of the View in the 'name' parameter.
   * Returns the renamed View.
   * @param params The `ViewsService.RenameViewParams` containing the following parameters:
   *
   * - `name`: Specifies the View name.
   *
   * - `body`: Request to rename a View.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  RenameView(params: ViewsService.RenameViewParams): __Observable<View> {
    return this.RenameViewResponse(params).pipe(
      __map(_r => _r.body as View)
    );
  }

  /**
   * List details about a single View.
   *
   * Returns the View corresponding to the specified View name.
   * @param params The `ViewsService.GetViewByNameParams` containing the following parameters:
   *
   * - `name`: Specifies the View name.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetViewByNameResponse(params: ViewsService.GetViewByNameParams): __Observable<__StrictHttpResponse<View>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/views/${encodeURIComponent(params.name)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<View>;
      })
    );
  }
  /**
   * List details about a single View.
   *
   * Returns the View corresponding to the specified View name.
   * @param params The `ViewsService.GetViewByNameParams` containing the following parameters:
   *
   * - `name`: Specifies the View name.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetViewByName(params: ViewsService.GetViewByNameParams): __Observable<View> {
    return this.GetViewByNameResponse(params).pipe(
      __map(_r => _r.body as View)
    );
  }

  /**
   * Update a View.
   *
   * Returns the updated View.
   * @param params The `ViewsService.UpdateViewByNameParams` containing the following parameters:
   *
   * - `name`: Specifies the View name.
   *
   * - `body`: Request to update a view.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateViewByNameResponse(params: ViewsService.UpdateViewByNameParams): __Observable<__StrictHttpResponse<View>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/public/views/${encodeURIComponent(params.name)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<View>;
      })
    );
  }
  /**
   * Update a View.
   *
   * Returns the updated View.
   * @param params The `ViewsService.UpdateViewByNameParams` containing the following parameters:
   *
   * - `name`: Specifies the View name.
   *
   * - `body`: Request to update a view.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateViewByName(params: ViewsService.UpdateViewByNameParams): __Observable<View> {
    return this.UpdateViewByNameResponse(params).pipe(
      __map(_r => _r.body as View)
    );
  }

  /**
   * Delete a View.
   *
   * Returns delete status upon completion.
   * @param params The `ViewsService.DeleteViewParams` containing the following parameters:
   *
   * - `name`: Specifies the View name.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteViewResponse(params: ViewsService.DeleteViewParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/public/views/${encodeURIComponent(params.name)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete a View.
   *
   * Returns delete status upon completion.
   * @param params The `ViewsService.DeleteViewParams` containing the following parameters:
   *
   * - `name`: Specifies the View name.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteView(params: ViewsService.DeleteViewParams): __Observable<null> {
    return this.DeleteViewResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Fetches the lock status of a file in a view.
   *
   * Returns error if op fails.
   * @param params The `ViewsService.GetFileLockStatusParams` containing the following parameters:
   *
   * - `name`: Specifies the View name.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `path`: Specifies the file path that needs to be locked.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Get lock file status response
   */
  GetFileLockStatusResponse(params: ViewsService.GetFileLockStatusParams): __Observable<__StrictHttpResponse<FileLockStatus>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.path != null) __params = __params.set('path', params.path.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/views/${encodeURIComponent(params.name)}/fileLocks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FileLockStatus>;
      })
    );
  }
  /**
   * Fetches the lock status of a file in a view.
   *
   * Returns error if op fails.
   * @param params The `ViewsService.GetFileLockStatusParams` containing the following parameters:
   *
   * - `name`: Specifies the View name.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `path`: Specifies the file path that needs to be locked.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Get lock file status response
   */
  GetFileLockStatus(params: ViewsService.GetFileLockStatusParams): __Observable<FileLockStatus> {
    return this.GetFileLockStatusResponse(params).pipe(
      __map(_r => _r.body as FileLockStatus)
    );
  }

  /**
   * Lock a file in a view and optionally update its expiry timestamp.
   *
   * Returns error if op fails.
   * @param params The `ViewsService.LockFileParams` containing the following parameters:
   *
   * - `name`: Specifies the View name.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: Request to lock a file.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Get lock file status response
   */
  LockFileResponse(params: ViewsService.LockFileParams): __Observable<__StrictHttpResponse<FileLockStatus>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    __body = params.body;
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/views/${encodeURIComponent(params.name)}/fileLocks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FileLockStatus>;
      })
    );
  }
  /**
   * Lock a file in a view and optionally update its expiry timestamp.
   *
   * Returns error if op fails.
   * @param params The `ViewsService.LockFileParams` containing the following parameters:
   *
   * - `name`: Specifies the View name.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: Request to lock a file.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Get lock file status response
   */
  LockFile(params: ViewsService.LockFileParams): __Observable<FileLockStatus> {
    return this.LockFileResponse(params).pipe(
      __map(_r => _r.body as FileLockStatus)
    );
  }
}

module ViewsService {

  /**
   * Parameters for ListNlmLocks
   */
  export interface ListNlmLocksParams {

    /**
     * Specifies the name of the View in which to search. If a view name is not
     * specified, all the views in the Cluster is searched.
     * This field is mandatory if filePath field is specified.
     */
    viewName?: string;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Specifies the maximum number of NLM locks to return in the response.
     * This field cannot be set above 1000. If this is not set, maximum of 1000
     * entries are returned.
     */
    pageCount?: number;

    /**
     * Specifies the filepath in the view relative to the root filesystem.
     * If this field is specified, viewName field must also be specified.
     */
    filePath?: string;

    /**
     * Specifies the opaque string returned in the previous response.
     * If this is set, next set of active opens just after the previous
     * response are returned. If this is not set, first set of NLM locks
     * are returned.
     */
    cookie?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for ClearNlmLocks
   */
  export interface ClearNlmLocksParams {

    /**
     * Request to clear NLM locks.
     */
    body: ClearNlmLocksParameters;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetQoSPolicies
   */
  export interface GetQoSPoliciesParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Specifies the names of QoS Policies to filter by.
     */
    names?: Array<string>;

    /**
     * Specifies the Ids of QoS Policies to filter by.
     */
    ids?: Array<number>;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetViewsByShareName
   */
  export interface GetViewsByShareNameParams {

    /**
     * TenantIds contains ids of the tenants for which objects are to be
     * returned.
     */
    tenantIds?: Array<string>;

    /**
     * The share name(substring) that needs to be searched against existing views
     * and aliases.
     */
    shareName?: string;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Expected to be empty in the first call to GetViewsByShareName.
     * To get the next set of results, set this value to the pagination cookie
     * value returned  in the response of the previous call.
     */
    paginationCookie?: string;

    /**
     * Specifies a limit on the number of Views returned.
     */
    maxCount?: number;

    /**
     * If true, the names in viewNames are matched by prefix rather than
     * exactly matched.
     */
    matchPartialNames?: boolean;

    /**
     * AllUnderHierarchy specifies if objects of all the tenants under the
     * hierarchy of the logged in user's organization should be returned.
     */
    allUnderHierarchy?: boolean;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetSmbConnections
   */
  export interface GetSmbConnectionsParams {

    /**
     * Parameters to get Smb Connections.
     * List of names of views whose connections are to be fetched.
     */
    viewNames?: Array<string>;

    /**
     * List of ids of views whose connections are to be fetched.
     */
    viewIds?: Array<number>;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Maximum number of results to return.
     */
    maxCount?: number;

    /**
     * Specifies whether to include list of sids in the result.
     */
    includeSid?: boolean;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateViewAlias
   */
  export interface UpdateViewAliasParams {

    /**
     * Request to update a View.
     */
    body: UpdateViewAliasParam;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreateViewAlias
   */
  export interface CreateViewAliasParams {

    /**
     * Request to create a View Alias.
     */
    body: ViewAlias;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DeleteViewAlias
   */
  export interface DeleteViewAliasParams {

    /**
     * Specifies the View Alias name.
     */
    name: string;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for ActivateViewAliases
   */
  export interface ActivateViewAliasesParams {

    /**
     * Specifies the View name.
     */
    name: string;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetViewDirQuotaInfo
   */
  export interface GetViewDirQuotaInfoParams {

    /**
     * The name of the view.
     */
    viewName: string;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Specifies the max entries that should be returned in the result.
     */
    pageCount?: number;

    /**
     * Specifies the format for the output such as 'csv' or 'json'.
     * If not specified, the json format is returned.
     * If 'csv' is specified, a comma-separated list with a heading
     * row is returned.
     */
    outputFormat?: string;

    /**
     * Cookie should be used from previous call to list user quota overrides. It
     * resumes (or gives the next set of values) from the result of the previous
     * call.
     */
    cookie?: number;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateViewDirQuota
   */
  export interface UpdateViewDirQuotaParams {

    /**
     * Request to update view directory quota policy.
     */
    body: UpdateDirQuotaArgs;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetViewUserQuotas
   */
  export interface GetViewUserQuotasParams {

    /**
     * While making a query for a user, this specifies a list of specific views
     * for the result.
     */
    viewNamesForUser?: Array<string>;

    /**
     * Specifies the name of the input view. If given, there could be three
     * scenarios with the viewName input parameter:
     * It gives the user quota overrides for this view, and the user quota
     * settings. Returns 'usersQuotaAndUsage'.
     * If given along with the user id, it returns the quota policy for this
     * user on this view. Returns 'usersQuotaAndUsage'.
     * If given along with SummaryOnly as true, a user quota summary for
     * this view would be returned. Returns 'summaryForView'.
     * If not given, then the user id is checked.
     */
    viewName?: string;

    /**
     * While making a query for a view, this specifies a list of specific users
     * with their unix uid for the result.
     */
    userUnixIdsForView?: Array<number>;

    /**
     * While making a query for a view, this specifies a list of specific users
     * with their Sid for the result.
     */
    userSidsForView?: Array<string>;

    /**
     * If interested in a user via unix-identifier, include UnixUid.
     * Otherwise, If a valid unix-id to SID mappings are available (i.e., when
     * mixed mode is enabled) the server will perform the necessary id mapping
     * and return the correct usage irrespective of whether the unix id / SID is
     * provided.
     */
    unixUid?: number;

    /**
     * TopQuotas is the quotas sorted by quota usage in descending order.
     * This parameter defines number of results returned. No pagination cookie is
     * returned if this is set to true.
     */
    topQuotas?: number;

    /**
     * Specifies a flag to just return a summary. If set to true, and if ViewName
     * is not nil, it returns the summary of users for a view. Otherwise if
     * UserId not nil, and ViewName is nil then it fetches the summary for a
     * user in his views.  By default, it is set to false.
     */
    summaryOnly?: boolean;

    /**
     * If interested in a user via smb_client, include SID.
     * Otherwise, If a valid unix-id to SID mappings are available (i.e., when
     * mixed mode is enabled) the server will perform the necessary id mapping
     * and return the correct usage irrespective of whether the unix id / SID is
     * provided.
     * The string is of following format -
     * S-1-IdentifierAuthority-SubAuthority1-SubAuthority2-...-SubAuthorityn.
     */
    sid?: string;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Specifies the max entries that should be returned in the result.
     */
    pageCount?: number;

    /**
     * OutputFormat is the Output format for the output. If it is not specified,
     * default is json.
     */
    outputFormat?: string;

    /**
     * Related to fetching a particular user's quota and usage in all his views.
     * It only pertains to the scenario where either UnixUid or Sid is specified,
     * and ViewName is nil. Specify the maxViewId for All the views returned
     * would have view_id's less than or equal to the given MaxViewId if it is
     * >= 0.
     */
    maxViewId?: number;

    /**
     * If set to true, the result will only contain user with user quota enabled.
     * By default, this field is set to false, and it's only in effect when
     * 'SummaryOnly' is set to false and 'ViewName' is specified.
     */
    includeUserWithQuotaOnly?: boolean;

    /**
     * If set to true, the logical usage info is included only for users with
     * quota overrides. By default, it is set to false.
     */
    includeUsage?: boolean;

    /**
     * This field can be set only when includeUsage is set to true.
     * By default, all the users with logical usage > 0 will be returned in the
     * result. If this field is set to true, only the list of users who has
     * exceeded the alert threshold will be returned.
     */
    excludeUsersWithinAlertThreshold?: boolean;

    /**
     * Cookie should be used from previous call to list user quota overrides. It
     * resumes (or gives the next set of values) from the result of the previous
     * call.
     */
    cookie?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateViewUserQuota
   */
  export interface UpdateViewUserQuotaParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * update user quota params.
     */
    body?: ViewUserQuotaParameters;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreateViewUserQuota
   */
  export interface CreateViewUserQuotaParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * update user quota params.
     */
    body?: ViewUserQuotaParameters;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DeleteViewUsersQuota
   */
  export interface DeleteViewUsersQuotaParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * update user quota params.
     */
    body?: DeleteViewUsersQuotaParameters;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateUserQuotaSettings
   */
  export interface UpdateUserQuotaSettingsParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * update user quota metadata params.
     */
    body?: UpdateUserQuotaSettingsForView;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetViews
   */
  export interface GetViewsParams {

    /**
     * Filter by a list of View names.
     */
    viewNames?: Array<string>;

    /**
     * Filter by a list of View ids.
     */
    viewIds?: Array<number>;

    /**
     * Filter by a list of View Box names.
     */
    viewBoxNames?: Array<string>;

    /**
     * Filter by a list of Storage Domains (View Boxes) specified by id.
     */
    viewBoxIds?: Array<number>;

    /**
     * TenantIds contains ids of the tenants for which objects are to be
     * returned.
     */
    tenantIds?: Array<string>;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * If the number of Views to return exceeds the maxCount specified in the
     * original request, specify the id of the last View
     * from the viewList in the previous response
     * to get the next set of Views.
     */
    maxViewId?: number;

    /**
     * Specifies a limit on the number of Views returned.
     */
    maxCount?: number;

    /**
     * If true, the names in viewNames are matched by prefix rather than
     * exactly matched.
     */
    matchPartialNames?: boolean;

    /**
     * If true, view aliases are also matched with the names in viewNames.
     */
    matchAliasNames?: boolean;

    /**
     * Filter by Protection Job ids. Return Views that are being protected by
     * listed Jobs, which are specified by ids.
     */
    jobIds?: Array<number>;

    /**
     * If set to true, the list will contain only the views for which either
     * file level data lock is enabled or view level data lock is enabled.
     */
    includeViewsWithDataLockEnabledOnly?: boolean;

    /**
     * If set to true, the list will contain only the views for which antivirus
     * scan is enabled.
     */
    includeViewsWithAntivirusEnabledOnly?: boolean;

    /**
     * If set to true, stats of views will be returned. By default this parameter
     * is set to false.
     */
    includeStats?: boolean;

    /**
     * Specifies if Protection Jobs information needs to be returned along with
     * view metadata. By default, if not set or set to true, Job information is
     * returned.
     */
    includeProtectionJobs?: boolean;

    /**
     * Specifies if inactive Views on this Remote Cluster (which have
     * Snapshots copied by replication) should also be returned.
     * Inactive Views are not counted towards the maxCount.
     * By default, this field is set to false.
     */
    includeInactive?: boolean;

    /**
     * AllUnderHierarchy specifies if objects of all the tenants under the
     * hierarchy of the logged in user's organization should be returned.
     */
    allUnderHierarchy?: boolean;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;

    /**
     * If set to true, the list is sorted descending by logical usage.
     */
    SortByLogicalUsage?: boolean;
  }

  /**
   * Parameters for UpdateView
   */
  export interface UpdateViewParams {

    /**
     * Request to update a view.
     */
    body: UpdateViewParam;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreateView
   */
  export interface CreateViewParams {

    /**
     * Request to create a View.
     */
    body: CreateViewRequest;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CloneView
   */
  export interface CloneViewParams {

    /**
     * Request to clone a View.
     */
    body: CloneViewRequest;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CloneDirectory
   */
  export interface CloneDirectoryParams {

    /**
     * Request to clone a directory.
     */
    body: CloneDirectoryParams;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetViewById
   */
  export interface GetViewByIdParams {

    /**
     * Specifies the View id.
     */
    id: number;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DeleteViewById
   */
  export interface DeleteViewByIdParams {

    /**
     * Specifies the View id.
     */
    id: number;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetFileLockStatusById
   */
  export interface GetFileLockStatusByIdParams {

    /**
     * Specifies the View id.
     */
    id: number;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Specifies the file path that needs to be locked.
     */
    path?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for LockFileById
   */
  export interface LockFileByIdParams {

    /**
     * Specifies the View id.
     */
    id: number;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Request to lock a file.
     */
    body?: LockFileParams;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for OverwriteView
   */
  export interface OverwriteViewParams {

    /**
     * Request to overwrite a Target view with contents of a Source view.
     */
    body: OverwriteViewParam;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for RenameViewById
   */
  export interface RenameViewByIdParams {

    /**
     * Specifies the View id.
     */
    id: number;

    /**
     * Request to rename a View.
     */
    body: RenameViewParam;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for RenameView
   */
  export interface RenameViewParams {

    /**
     * Specifies the View name.
     */
    name: string;

    /**
     * Request to rename a View.
     */
    body: RenameViewParam;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetViewByName
   */
  export interface GetViewByNameParams {

    /**
     * Specifies the View name.
     */
    name: string;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateViewByName
   */
  export interface UpdateViewByNameParams {

    /**
     * Specifies the View name.
     */
    name: string;

    /**
     * Request to update a view.
     */
    body: UpdateViewParam;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DeleteView
   */
  export interface DeleteViewParams {

    /**
     * Specifies the View name.
     */
    name: string;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetFileLockStatus
   */
  export interface GetFileLockStatusParams {

    /**
     * Specifies the View name.
     */
    name: string;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Specifies the file path that needs to be locked.
     */
    path?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for LockFile
   */
  export interface LockFileParams {

    /**
     * Specifies the View name.
     */
    name: string;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Request to lock a file.
     */
    body?: LockFileParams;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { ViewsService }
