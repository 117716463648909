// A list of built-in Groups which may not be deleted from the Cluster.
export const BUILT_IN_GROUPS = [
  // Administrators
  'S-1-5-32-544',

  // Users
  'S-1-5-32-545',

  // Guests
  'S-1-5-32-546',

  // Power Users
  'S-1-5-32-547',

  // Backup Operators
  'S-1-5-32-551',

  // Everyone
  'S-1-1-0',
];

// A list of built-in Users which may not be deleted from the Cluster.
export const BUILT_IN_USERS = ['admin'];
