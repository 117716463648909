<ng-container [formGroup]="formGroup">
  <mat-card cogFormGroup>
    <h2 *ngIf="!readOnly">{{'recoverTo' | translate}}</h2>
    <ng-content></ng-content>
  </mat-card>

  <mat-card cogFormGroup>
    <cog-settings-list cogDataId="recover-ivm-common-settings"
      [title]="readOnly ? undefined : ('recoveryOptions' | translate)"
      [alwaysOpen]="true"
      [readOnly]="readOnly"
      [invalid]="formGroup.invalid">

      <coh-settings-list-read-only-mount
        *ngIf="showReadOnlyMount"
        [control]="formGroupControls.readOnlyMount">
      </coh-settings-list-read-only-mount>

      <coh-settings-list-cluster-interface
        cohIrisHide="isDmsScope"
        [formControlName]="formControlNames.clusterInterface">
      </coh-settings-list-cluster-interface>

      <coh-settings-list-authorised-user
        *ngIf="isPhysicalSMBCredentialsForRecoveryEnabled && isPhysicalWindowsBlockVolumeRecovery"
        [formControlName]="formControlNames.authorisedUser"
        [isResubmit]="isResubmit">
      </coh-settings-list-authorised-user>

      <coh-settings-list-task-name
        *ngIf="!readOnly"
        [control]="formGroupControls.taskName">
      </coh-settings-list-task-name>
    </cog-settings-list>
  </mat-card>
</ng-container>
