<mat-form-field class="form-field form-field-full" *ngIf="disabled || integrations?.length > 0">
  <mat-label>{{ 'dg.td.scan.selectIntegrations' | translate }}</mat-label>
  <mat-select
    class="select-integration"
    [formControl]="integrationsControl"
    cogDataId="selected-integrations"
    [inheritDataId]="true"
    multiple>
    <mat-select-trigger>
      <mat-chip-list>
        <mat-chip
          *ngFor="let id of integrationsControl.value"
          cogDataId="selected-integration-{{ getIntegrationName(id) }}-option"
          [removable]="true">
          {{ getIntegrationName(id) }}
          <mat-icon matChipRemove (click)="removeIntegration(id)">cancel</mat-icon>
        </mat-chip>
      </mat-chip-list>
    </mat-select-trigger>
    <mat-option
      class="integration-option"
      cogDataId="integration-{{ getIntegrationName(integration.integrationId) }}-option"
      *ngFor="let integration of integrations"
      [value]="integration.integrationId"
      [disabled]="!canSelectIntegration(integration.integrationId)">
      <div class="margin-left">
        <span cogDataId="name" [inheritDataId]="true">
          {{ getIntegrationName(integration.integrationId) | titlecase }}
        </span>
        <ng-container *ngIf="getIntegrationStat(integration.integrationId) as stats">
          <ul
            *ngIf="stats.lastSyncTimeUsecs && stats.rulesCount && stats.syncStatus === syncStatus.Success"
            class="c-ul-inline c-ul-dot-separator no-margin mat-caption">
            <li class="no-padding-right" cogDataId="last-sync" [inheritDataId]="true">
              {{ 'dg.td.scan.integrations.lastSync' | translate: { date: stats.lastSyncTimeUsecs | cogDate } }}
            </li>
            <li cogDataId="rule-count" [inheritDataId]="true">
              {{ 'dg.td.scan.integrations.ruleCount' | translate: { count: stats.rulesCount } }}
            </li>
          </ul>
          <div
            cogDataId="sync-in-progress"
            [inheritDataId]="true"
            class="mat-caption"
            *ngIf="stats.syncStatus === syncStatus.InProgress || stats.syncStatus === syncStatus.NotStarted">
            {{ 'dg.td.scan.integrations.sync.' + stats.syncStatus | translate }}
          </div>
          <span
            class="mat-error mat-caption"
            cogDataId="error"
            [inheritDataId]="true"
            *ngIf="stats.syncStatus === syncStatus.Error && stats.errorMessage">
            {{ stats.errorMessage }}
          </span>
        </ng-container>
        <ng-container *ngIf="!getIntegrationStat(integration.integrationId) && !loading">
          <span class="mat-error mat-caption" cogDataId="unknown" [inheritDataId]="true">
            {{ 'dg.td.scan.integrations.sync.status.unknown' | translate }}
          </span>
        </ng-container>
        <ng-container *ngIf="loading">
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </ng-container>
      </div>
    </mat-option>
    <mat-divider></mat-divider>
    <div class="margin-sm">
      <button mat-button (click)="configureIntegration()">
        <cog-icon shape="add"></cog-icon>
        {{ 'dg.td.scan.integrations.add' | translate }}
      </button>
    </div>
  </mat-select>
</mat-form-field>

<mat-form-field class="form-field form-field-full" *ngIf="!disabled && !integrations?.length">
  <mat-label>{{ 'dg.td.scan.selectIntegrations' | translate }}</mat-label>
  <mat-select class="select-integration">
    <mat-divider></mat-divider>
    <mat-option>
      <button mat-button (click)="configureIntegration()">
        <cog-icon shape="add"></cog-icon>
        {{ 'dg.td.scan.integrations.add' | translate }}
      </button>
    </mat-option>
  </mat-select>
</mat-form-field>
