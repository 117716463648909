/**
 * Generic runbook status for DR and Test & Dev Runbook.
 */
export enum RunbookStatus {
  kRunning = 'kRunning',
  kSuccess = 'kSuccess',
  kFailed = 'kFailed',
  kFailoverReady  = 'kFailoverReady',
  kRunningFailover = 'kRunningFailover',
  kTeardownReady = 'kTeardownReady',
  kRunningTeardown = 'kRunningTeardown',
  kPreparingForFailback = 'kPreparingForFailback',
  kFailbackReady = 'kFailbackReady',
  kRunningFailback = 'kRunningFailback',
}


/**
 * List of generic runbook status for DR and Test & Dev Runbook.
 */
export const runbookStatusList = Object.values(RunbookStatus);
