import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef } from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';
import { IocIntegrationId, Scan, ThreatId } from '@cohesity/api/argus';
import { Integration } from '@cohesity/api/v2';
import { WindowRef } from '@cohesity/helix';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { FormSectionComponent } from '@cohesity/shared-forms';
import { IntegrationName, TiIntegrationService, TiVendor } from '@cohesity/shared/integrations';
import { AjaxHandlerService } from '@cohesity/utils';
import { finalize } from 'rxjs/operators';

import { FormSectionName, ScanFormModel } from '../scan.model';
import { DetectionTypeFormValue, DetectionTypeModel, SelectedObjectStats } from './detection-type.models';
import {
  SelectUserDefinedThreatsDialogService,
} from './select-user-defined-threats-dialog/select-user-defined-threats-dialog.service';

@Component({
  selector: 'dg-td-detection-type',
  templateUrl: './detection-type.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: FormSectionComponent,
      useExisting: forwardRef(() => DetectionTypeComponent),
    }
  ],
})
export class DetectionTypeComponent
  extends FormSectionComponent<DetectionTypeFormValue, ScanFormModel, Scan> {

  /**
   * Section name for form builder
   */
  formSectionName = 'detectionType' as FormSectionName;

  /** The external help docs link */
  readonly helpLink = 'https://docs.cohesity.com/data-security/datahawk/threat-protection/threat-detection.htm?tocpath=Threat%20Protection%7C_____1';

  /**
   * Property associated with built-in threats radio button
   */
  enableBuiltInThreats = false;

  /**
   * Property associated with custom threats radio button
   */
  enableCustomThreats = false;

  /**
   * Custom threats selected by user
   */
  customThreatIds: ThreatId[] = [];

  /**
   * Property associated with integrations radio button
   */
  enableIntegrations = false;

  /**
   * Integration selected by user
   */
  selectedIntegrationIds: IocIntegrationId[] = [];

  /**
   * flag to indicate if ioc integrations are loading
   */
  iocIntegrationsLoading = false;

  /**
   * List of available ioc integrations
   */
  integrations: Integration[] = [];

  /**
   * returns a string containing selected ioc integrations (comma separated).
   */
  get selectedIntegrationsNames(): string {
    return this.selectedIntegrationIds
      .map(id => {
        const integration = this.integrations.find(int => int.integrationId === id);
        if (!integration) {
          return id;
        }
        return integration.integrationInfo.tiInfo.vendorName === TiVendor.CrowdStrike
          ? IntegrationName.CrowdStrikeFalconIntelligence
          : integration.integrationInfo.tiInfo;
      })
      .join();
  }


  /**
   * Flag to show built threats applies to only windows when a non-windows object is selected
   */
  get showBuiltInScanLimitations(): boolean {
    return this.formControl?.value?.enableBuiltInThreats && this.objectSelectionStats()?.kLinux > 0;
  }

  /**
   * Indicates if the custom threats feature flag is enabled or not
   */
  get isCustomThreatsEnabled(): boolean {
    return flagEnabled(this.irisCtx.irisContext, 'dataHawkThreatDetectionCustomThreatsEnabled');
  }

  /**
   * Indicates if the integrations feature flag is enabled or not
   */
  get isIntegrationsEnabled(): boolean {
    return flagEnabled(this.irisCtx.irisContext, 'dataHawkThreatDetectionIntegrationsEnabled');
  }

  get errorToolTip(): string {
    if (this.formControl.errors?.requireCheckboxesToBeChecked) {
      return 'dg.td.scan.selectAtLeastOneDetectionType';
    }
    if (this.formControl.errors?.noRulesSelected) {
      return 'dg.td.scan.selectAtLeastOneRule';
    }
    if (this.formControl.errors?.noIntegrationsSelected) {
      return 'dg.td.scan.selectAtLeastOneIntegration';
    }
    return null;
  }

  constructor(
    private ajaxHandlerService: AjaxHandlerService,
    private cd: ChangeDetectorRef,
    private irisCtx: IrisContextService,
    private selectThreatsDialog: SelectUserDefinedThreatsDialogService,
    private iocIntegrations: TiIntegrationService,
    private windowRef: WindowRef
  ) {
    super();
  }

  /**
   * Used to initialize the section. This function will be called by FormBuilderComponent.
   */
  initFormSection() {
    super.initFormSection();

    if (this.isIntegrationsEnabled) {
      this.fetchIntegrations();
    }

    this.formControl.addValidators([
      Validators.required,
      // At least one checkbox should be checked
      this.requireCheckboxesToBeCheckedValidator,
      // At least one threat should be selected when custom threats is checked
      this.requireSelectedRulesForCustomThreats,
      // At least one integration should be selected when integrations is checked
      this.requireSelectedIntegrations,
    ]);
    const formData = this.fromDataModel(this.builder.dataModel);
    this.enableBuiltInThreats = formData.enableBuiltInThreats;
    this.enableCustomThreats = formData.enableCustomThreats;
    this.customThreatIds = formData.customThreatIds;
    this.enableIntegrations = formData.enableIntegrations;
    this.selectedIntegrationIds = formData.integrationIds;
    this.formControl.patchValue({ ...formData });
    this.formControl.updateValueAndValidity();
  }

  /**
   * Open dialog to select custom threats
   */
  selectCustomThreats() {
    this.selectThreatsDialog.launch(this.customThreatIds ?? [])
      .pipe(
        this.untilDestroy(),
        finalize(() => this.cd.detectChanges())
      )
      .subscribe((threats) => {
        if (threats) {
          this.customThreatIds = threats;
          this.formControl.patchValue({
            customThreatIds: this.customThreatIds
          });
        }
      });
  }

  /**
   * Opens built in threat scan limitations doc
   */
  openHelpLink() {
    this.windowRef.openExternalLink(this.helpLink);
  }

  /**
   * Update current section value to parent Form value
   */
  updateValue() {
    this.next({
      enableBuiltInThreats: this.enableBuiltInThreats,
      enableCustomThreats: this.enableCustomThreats,
      customThreats: this.customThreatIds,
      enableIntegrations: this.enableIntegrations,
      integrationIds: this.selectedIntegrationIds,
    });
  }

  /**
   * Shows edit view of the section
   */
  editDetectionType() {
    this.formSectionViewCta.viewEditMode();
  }

  /**
   * Converts current form value to scan detection type model
   *
   * @returns Scan Detection type value for the scan
   */
  toDataModel(): Partial<Scan> {
    const detectionType: DetectionTypeModel = {
      builtInThreats: this.enableBuiltInThreats
    };

    if (this.enableCustomThreats && this.isCustomThreatsEnabled) {
      detectionType.customThreatIds = this.customThreatIds;
    }

    if (this.isIntegrationsEnabled && this.enableIntegrations) {
      detectionType.integrationIds = this.selectedIntegrationIds;
    }

    return {
      detectionType
    };
  }

  /**
   * Creates section value form the given scan
   *
   * @param dataModel Scan
   * @returns Section Value
   */
  fromDataModel(dataModel: Scan): DetectionTypeFormValue {
    const val = {
      enableBuiltInThreats: dataModel.detectionType?.builtInThreats
        || dataModel.detectionType?.customThreatIds?.length > 0 ? false : true,
      enableCustomThreats: dataModel.detectionType?.customThreatIds?.length > 0 || false,
      customThreatIds: dataModel.detectionType?.customThreatIds || [],
      enableIntegrations: dataModel.detectionType?.integrationIds?.length > 0 || false,
      integrationIds: dataModel.detectionType?.integrationIds || [],
    };
    return val;
  }

  /**
   * updates form value when built-in threats checkbox state changes
   *
   * @param checked boolean indicating weather built-in threats checked or not
   */
  toggleBuiltInThreats(checked: boolean) {
    this.enableBuiltInThreats = checked;
    this.formControl.patchValue({
      enableBuiltInThreats: this.enableBuiltInThreats
    });
  }

  /**
   * updates form value when custom threats checkbox state changes
   *
   * @param checked boolean indicating weather custom threats checked or not
   */
  toggleCustomThreats(checked: boolean) {
    this.enableCustomThreats = checked;
    this.formControl.patchValue({
      enableCustomThreats: this.enableCustomThreats
    });
  }

  /**
   * updates form value when custom threats checkbox state changes
   *
   * @param checked boolean indicating weather custom threats checked or not
   */
  toggleIntegrations(checked: boolean) {
    this.enableIntegrations = checked;
    this.formControl.patchValue({
      enableIntegrations: this.enableIntegrations
    });
  }

  /**
   * selects given integrations
   *
   * @param ids list of ioc integration ids
   */
  selectIntegrations(ids: IocIntegrationId[]) {
    this.selectedIntegrationIds = ids;
    this.formControl.patchValue({
      integrationIds: this.selectedIntegrationIds
    });
  }

  /**
   * Fetches integrations from backend
   */
  private fetchIntegrations() {
    this.iocIntegrationsLoading = true;
    this.cd.detectChanges();
    this.iocIntegrations.getTiIntegrations()
      .pipe(
        this.untilDestroy(),
        finalize(() => {
          this.iocIntegrationsLoading = false;
          this.cd.detectChanges();
        })
      ).subscribe(
        response => {
          this.integrations = response;
        },
        this.ajaxHandlerService.handler
      );
  }

  /**
   * custom validator to invalidate the detection type form if non of the detection types are selected
   *
   * @returns custom validator that can be added to a form group
   */
  private requireCheckboxesToBeCheckedValidator: ValidatorFn = () =>
    this.enableBuiltInThreats || this.enableCustomThreats || this.enableIntegrations
      ? null
      : { requireCheckboxesToBeChecked: true };

  /**
   * custom validator to invalidate the detection type form group
   * when custom Threats detection type is selected but no custom rules are selected
   *
   * @returns custom validator that can be added to a form group
   */
  private requireSelectedRulesForCustomThreats: ValidatorFn = () => {
    if (!this.enableCustomThreats) {
      return null;
    }
    return this.customThreatIds.length ? null : { noRulesSelected: true };
  };

  /**
   * custom validator to invalidate the detection type form group
   * when integration detection type is selected but no integrationss are selected
   *
   * @returns custom validator that can be added to a form group
   */
  private requireSelectedIntegrations: ValidatorFn = () => {
    if (!this.enableIntegrations) {
      return null;
    }
    return this.selectedIntegrationIds.length ? null : { noIntegrationsSelected: true };
  };

  /**
   * Gives an object with os as key and number objects of that os selected by user
   *
   * @returns object count by os
   */
  private objectSelectionStats(): SelectedObjectStats {
    return this.builder.formGroup.value?.objects?.reduce((stats, obj) => ({
      ...stats,
      [obj.object.osType]: (stats[obj.object.osType] ?? 0) + 1
    }), {});
  }
}
