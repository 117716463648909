import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { DlpApiService, Pattern, PatternId, ResourceType } from '@cohesity/api/argus';
import { Controls, NgxSubFormComponent, subformComponentProviders, takeUntilDestroyed } from 'ngx-sub-form';
import { finalize } from 'rxjs/operators';

import { maxBuildInPatterns, maxCustomPatterns } from './pattern-selector.util';

/**
 * The policy pattern form interface.
 */
export interface PatternSelectorForm {
  /** The pattern ids associated with the policy */
  patternIds: PatternId[];
}

/**
 * Data classification policy pattern selector component.
 */
@Component({
  selector: 'dg-dc-patterns-selector',
  templateUrl: './patterns-selector.component.html',
  styleUrls: ['./patterns-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: subformComponentProviders(PatternsSelectorComponent),
})
export class PatternsSelectorComponent extends NgxSubFormComponent<PatternId[], PatternSelectorForm> implements OnInit {
  /** The maximum number of build-in pattern can be selected */
  readonly maxBuildInPatterns = maxBuildInPatterns;

  /** The maximum number of custom pattern can be selected */
  readonly maxCustomPatterns = maxCustomPatterns;

  /**
   * Indicates whether we are loading data.
   */
  isLoading = false;

  /**
   * The available list of patterns.
   */
  patterns: Pattern[] = [];

  /**
   * The available list of custom patterns.
   */
  customPatterns: Pattern[] = [];

  /**
   * The available list of builtIn patterns.
   */
  builtInPatterns: Pattern[] = [];

  /**
   * The selected builtIn pattern ids.
   */
  builtInPatternIds = new Set<PatternId>();

  /**
   * The selected custom pattern ids.
   */
  customPatternIds = new Set<PatternId>();

  /**
   * The selected pattern ids.
   */
  get patternIds(): PatternId[] {
    return this.formGroupValues.patternIds || [];
  }

  /**
   * The resourceType to compare the type of pattern
   */
  readonly resourceType = ResourceType;

  constructor(private cdr: ChangeDetectorRef, private dlpApiService: DlpApiService) {
    super();
  }

  /**
   * Initialize the component.
   */
  ngOnInit() {
    this.isLoading = true;
    this.cdr.detectChanges();
    this.dlpApiService
      .getPatterns()
      .pipe(
        takeUntilDestroyed(this),
        finalize(() => {
          this.isLoading = false;
          this.cdr.detectChanges();
        })
      )
      .subscribe(({ patterns }) => {
        // Populating patterns (custom, predefined and union of both)
        this.patterns = patterns;

        patterns.forEach(pattern => {
          if (pattern.buildType === ResourceType.builtIn) {
            this.builtInPatterns.push(pattern);
          } else {
            this.customPatterns.push(pattern);
          }
        });

        // Sorting patterns for the user convenience on selection
        this.builtInPatterns.sort(this.alphaSortPatterns);
        this.customPatterns.sort(this.alphaSortPatterns);

        const patternIdSet = new Set<PatternId>([...this.patternIds]);
        // populating custom patterns for initial load (use case: edit policy)
        this.customPatterns.forEach(customPattern => {
          if (patternIdSet.has(customPattern.id)) {
            this.customPatternIds.add(customPattern.id);
          }
        });

        // populating built in patterns for initial load (use case: edit policy)
        this.builtInPatterns.forEach(builtInPattern => {
          if (patternIdSet.has(builtInPattern.id)) {
            this.builtInPatternIds.add(builtInPattern.id);
          }
        });

        this.formGroupControls.patternIds.setValue([...patternIdSet]);
      });
  }

  /**
   * Toggle the pattern selection.
   *
   * @param pattern The pattern.
   */
  toggleSelection(pattern: Pattern) {
    switch (pattern.buildType) {
      case ResourceType.builtIn: {
        const isSelected = this.builtInPatternIds.has(pattern.id);

        if (!isSelected && this.builtInPatternIds.size >= this.maxBuildInPatterns) {
          return;
        }

        if (isSelected) {
          // remove the pattern if already selected.
          this.builtInPatternIds.delete(pattern.id);
        } else {
          // add the pattern if not already selected.
          this.builtInPatternIds.add(pattern.id);
        }
        break;
      }

      default:
      case ResourceType.custom: {
        const isSelected = this.customPatternIds.has(pattern.id);

        if (!isSelected && this.customPatternIds.size >= this.maxCustomPatterns) {
          return;
        }

        if (isSelected) {
          // remove the pattern if already selected.
          this.customPatternIds.delete(pattern.id);
        } else {
          // add the pattern if not already selected.
          this.customPatternIds.add(pattern.id);
        }
        break;
      }
    }

    this.formGroupControls.patternIds.setValue([...this.builtInPatternIds, ...this.customPatternIds]);
  }

  /**
   * Handler for toggle on group chips (builtIn and custom)
   *
   * @param patternType Pattern type of toggle button (custom or builtIn)
   */
  toggleGroup(patternType: string) {
    const patternIdSet = new Set<PatternId>([...this.patternIds]);

    const patternIds: Set<PatternId> =
      patternType === ResourceType.custom ? this.customPatternIds : this.builtInPatternIds;
    const patterns: Pattern[] = patternType === ResourceType.custom ? this.customPatterns : this.builtInPatterns;

    if (patternIds.size >= 1) {
      // remove all builtIn patterns if one or more is already selected.
      patternIds.forEach(patternId => patternIdSet.delete(patternId));
      patternIds.clear();
    } else {
      // select all builtIn patterns if none is already selected.
      patterns.forEach(pattern => {
        patternIdSet.add(pattern.id);
        patternIds.add(pattern.id);
      });
    }

    if (patternType === ResourceType.custom) {
      this.customPatternIds = patternIds;
    } else {
      this.builtInPatternIds = patternIds;
    }

    this.formGroupControls.patternIds.setValue([...patternIdSet]);
  }

  /**
   * Return the policy pattern default form value.
   *
   * @returns The policy pattern form value.
   */
  getDefaultValues(): PatternSelectorForm {
    return { patternIds: [] };
  }

  /**
   * Custom validator for max pattern for the policy.
   *
   * @returns The ValidatorFn.
   */
  maxPatternValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isForbidden =
        this.builtInPatternIds?.size > this.maxBuildInPatterns || this.customPatternIds?.size > this.maxCustomPatterns;

      return isForbidden ? { maxPatterns: { value: control.value } } : null;
    };
  }

  /**
   * Get the form controls.
   *
   * @returns The policy pattern form controls.
   */
  getFormControls(): Controls<PatternSelectorForm> {
    return { patternIds: new UntypedFormControl([], [Validators.required, this.maxPatternValidator()]) };
  }

  /**
   * Transforms api model to form group model.
   *
   * @param patternIds The selected pattern ids value.
   * @param defaultValues The default pattern ids value.
   * @returns The pattern ids form value.
   */
  transformToFormGroup(patternIds: PatternId[], defaultValues: PatternSelectorForm): PatternSelectorForm {
    return { patternIds: patternIds || defaultValues.patternIds };
  }

  /**
   * Transform form group model to api model.
   *
   * @param formValue The pattern ids form value.
   * @returns The pattern ids value.
   */
  transformFromFormGroup(formValue: PatternSelectorForm): PatternId[] {
    const { patternIds } = formValue || {};

    return patternIds;
  }

  /**
   * Returns number based on comparison
   *
   * @param a 1st pattern
   * @param b 2nd pattern
   * @returns comparison number
   */
  alphaSortPatterns = (a: Pattern, b: Pattern): number => a.name.localeCompare(b.name);
}
