import { Component, ElementRef, HostListener, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySelectionList as MatSelectionList, MatLegacySelectionListChange as MatSelectionListChange } from '@angular/material/legacy-list';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { ReflowService, SnackBarService, ViewportSize } from '@cohesity/helix';
import {
  clusterIdentifiers,
  IrisContextService,
  isAllClustersScope,
  isClusterScope, isDmsOnlyUser,
  isDmsScope,
  isDmsUser,
  isGlobalScope,
  isMcm
} from '@cohesity/iris-core';
import { IS_IBM_AQUA_ENV } from '@cohesity/shared/core';
import { AutoDestroyable } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import { StateService, Transition, TransitionService, UIRouterGlobals } from '@uirouter/core';
import { combineLatest } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { GlobalSearchFilters } from '../../models/global-search-filters.model';
import { GlobalSearchResultType } from '../../models/global-search-result-type.model';
import { GlobalSearchFiltersService } from '../../services/global-search-filters.service';
import { GlobalSearchInfoService } from '../../services/global-search-info.service';
import { GlobalSearchResultsTypeService } from '../../services/global-search-results-type.service';
import { GlobalSearchResultsService } from '../../services/global-search-results.service';

@Component({
  selector: 'coh-global-search-input',
  templateUrl: './global-search-input.component.html',
  styleUrls: ['./global-search-input.component.scss']
})
export class GlobalSearchInputComponent extends AutoDestroyable implements OnInit, OnDestroy {
  /**
   * Reference to search text input element.
   */
  @ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement>;

  /**
   * Reference to the search results list.
   */
  @ViewChild(MatSelectionList) searchResults: MatSelectionList;

  /**
   * Reference to the search results type menu.
   */
  @ViewChild(MatMenuTrigger) resultsTypeMenu: MatMenuTrigger;

  /**
   * Element Reference to the search results type menu.
   */
  @ViewChild(MatMenuTrigger, { read: ElementRef }) resultsTypeMenuElement: ElementRef;

  /**
   * Whether the input is focused currently.
   */
  inputFocused = false;

  /**
   * Whether the search results list is enabled.
   */
  searchResultsEnabled = false;

  /**
   * Form control for the search field.
   */
  searchControl = new UntypedFormControl();

  /**
   * Array of present transition hooks.
   */
  transitionHooks: Function[] = [];

  /**
   * Whether the search field tip has been shown. This is only shown once.
   */
  searchSlashTipShown = false;

  /**
   * The enum of available search result types.
   */
  globalSearchResultType = GlobalSearchResultType;

  /**
   * The currently selected search results type.
   */
  selectedType: GlobalSearchResultType;

  /**
   * Whether the viewport is extra small so we
   * can adjust some of the view to accomodate
   */
  isReflowModeXs = false;

  /**
   * Determines whether the UI is running in IBM Aqua mode.
   */
  isIBMAquaEnv = inject(IS_IBM_AQUA_ENV);

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private irisContextService: IrisContextService,
    private matDialog: MatDialog,
    private snackBarService: SnackBarService,
    private stateService: StateService,
    private transitionService: TransitionService,
    private translateService: TranslateService,
    private uiRouterGlobals: UIRouterGlobals,
    private reflowService: ReflowService,
    readonly globalSearchFiltersService: GlobalSearchFiltersService,
    readonly globalSearchInfoService: GlobalSearchInfoService,
    readonly globalSearchResultsService: GlobalSearchResultsService,
    readonly globalSearchResultsTypeService: GlobalSearchResultsTypeService,
  ) {
    super();
  }

  /**
   * Whether the search wrapper is currently focused.
   */
  get searchWrapperFocused(): boolean {
    return this.inputFocused || this.resultsTypeMenu?.menuOpen || this.currentStateSearch || this.searchResultsActive;
  }

  /**
   * Whether the current state is global search.
   */
  get currentStateSearch(): boolean {
    return this.uiRouterGlobals.current?.name === 'ng-search';
  }

  /**
   * Whether the search results list should be visible.
   */
  get showSearchResults() {
    return this.searchControl.value && !this.resultsTypeMenu?.menuOpen && this.searchResultsEnabled;
  }

  /**
   * Whether currently one of the search results item is  highlighted.
   */
  get searchResultsActive(): boolean {
    return this.elementRef.nativeElement.contains(document.activeElement);
  }

  /**
   * Depending on the available viewport space
   * Adjust the input placeholder
   */
  get focusedSearchTranslationKey() {
    return this.isReflowModeXs ? 'typeToSearch' : 'typeToStartSearching';
  }

  /**
   * Listen to whenever there's '/' keystroke.
   */
  @HostListener('document:keydown./') onKeydownSlashHandler() {
    this.focusInput(true);
  }

  /**
   * Listen to whenever there's a click.
   */
  @HostListener('document:mousedown', ['$event.target']) onClick(eventTarget: HTMLElement) {
    this.hideSearchResults(eventTarget);
  }

  ngOnInit() {
    this.searchControl.valueChanges.pipe(
      this.untilDestroy(),
    ).subscribe(value => {
      // Update searchString$ and load results for input results.
      this.globalSearchResultsService.searchString = value;
      this.globalSearchResultsService.loadInputSearchResults();
    });

    this.globalSearchResultsTypeService.selectedType$.pipe(
      this.untilDestroy(),
    ).subscribe(value => {
      // Update the selected type value in the component
      this.selectedType = value;

      if (this.currentStateSearch) {
        // If currently on the results list page, then update the filters.
        this.setDefaultFilters(true);
      }
    });

    this.reflowService.currentViewport$.pipe(this.untilDestroy()).subscribe((size: ViewportSize) => {
      this.isReflowModeXs = (ViewportSize.xs === size);
    });

    this.setupTransitionHooks();
  }

  ngOnDestroy() {
    while (this.transitionHooks.length) {
      // Unregister all transition hooks.
      this.transitionHooks.pop()?.();
    }
  }

  /**
   * Function to setup various transition hooks global search uses.
   */
  setupTransitionHooks() {
    // When these params are changed, the search results don't need to be
    // refetched as they're only used in UI and not for API filtering.
    const noRefetchParams = ['openedResultId', 'view'];

    this.transitionHooks.push(

      // When entering search state (i.e. on loading app directly on search state
      // or when visiting the search state for the first time).
      this.transitionService.onSuccess({entering: 'ng-search'}, (transition: Transition) => {
        const params = transition.params();
        const {q} = params;


        // Copy the search string from state parameter to search control.
        this.searchControl.setValue(q);

        // Update searchString$ and load results for input results.
        this.globalSearchResultsService.searchString = q;
        this.globalSearchResultsService.loadPageSearchResults();

        // Apply MCM scope filters, if applicable.
        this.setDefaultFilters(false, params);
      }),

      // When transitioning to search state. This is generally called by the
      // different filters and search input within the results page. The state
      // parameters are the single source of truth for all filter options and
      // search query, so whenever there's a change in state params, reload the
      // search results page.
      this.transitionService.onSuccess({to: 'ng-search'}, (transition: Transition) => {
        if (noRefetchParams.some(param => Object.prototype.hasOwnProperty.call(transition.paramsChanged(), param))
          && Object.keys(transition.paramsChanged()).length === 1) {
          // If only openedResultId param is updated, don't make any API calls
          // as that is used to just reflect the currently opened result.
          return;
        }

        const {
          q,
          selectResultGlobalIds,
          openedResultId,
          resultType,
          ...filters
        } = transition.params();

        // Apply the filter params from the state parameters and load results for
        // results page.
        this.globalSearchFiltersService.filters = filters;
        this.globalSearchResultsTypeService.selectedType = resultType;
        this.globalSearchResultsService.loadInputSearchResults();
        this.globalSearchResultsService.loadPageSearchResults();
      }),

      // When exiting the search results state.
      this.transitionService.onSuccess({exiting: 'ng-search'}, (transition: Transition) => {
        if (transition.to().name === 'ng-search') {
          return;
        }

        // Clear the search control and filters value when navigating out of
        // global search.
        this.searchControl.setValue('');
        this.globalSearchFiltersService.filters = {};
        this.inputFocused = false;
      }),
    );
  }

  /**
   * Function to pre apply filters based on a scope. This function is called to
   * select filters initially on page load and whenever the result type is
   * changed (Object -> File, or File -> Object).
   *
   * @param resultTypeChanged Whether the function is called when the result
   *                          type is changed.
   * @param params Currently applied transition params.
   */
  setDefaultFilters(resultTypeChanged: boolean, params?: Record<string, any>) {
    const irisContext = this.irisContextService.irisContext;

    if (!resultTypeChanged) {
      if (!isMcm(irisContext) || isGlobalScope(irisContext)) {
        // If in global context, no filter needs to be applied as all results
        // are returned.
        return;
      }

      if (params?.region?.length || params?.cluster?.length) {
        // If region or cluster transition params are already applied, do not
        // apply automatic scope filters.
        return;
      }
    }

    combineLatest([
      this.globalSearchInfoService.regions$,
      this.globalSearchInfoService.clusters$,
      this.globalSearchResultsTypeService.selectedType$,
    ]).pipe(
      this.untilDestroy(),
      filter(([regions, clusters]) => Boolean(regions && clusters)),
      take(1),
    ).subscribe(([regions, clusters, selectedType]) => {
      const isFileSearch = selectedType === GlobalSearchResultType.File;
      const filters: GlobalSearchFilters = {};

      if (isMcm(irisContext)) {
        switch (true) {
          case isAllClustersScope(irisContext) && isDmsUser(irisContext):
            // Sending No cluster is equivalent to sending all clusters.
            // For accounts having large clusters, sending all clusters has the potential to break the URI
            // limit. So, do not send any cluster at all in the filter.

            // File search requires client to send only connected cluster.
            if (isFileSearch) {
              filters.cluster = clusters.filter(
                // Only select connected clusters if in file search.
                cluster => cluster.connectedToCluster,
              ).map(
                cluster => `${cluster.clusterId}:${cluster.clusterIncarnationId}`
              );
            }

            break;
          case isClusterScope(irisContext):
            // If in cluster scope, select the current scope selected cluster.
            filters.cluster = clusterIdentifiers(irisContext);

            break;
          case isDmsScope(irisContext) && !isDmsOnlyUser(irisContext):
            // If in DMS scope and user is not a dms only user,
            // preselect all regions.
            filters.region = regions.map(region => region.id);

            break;
        }
      }

      if (resultTypeChanged) {
        // If the result type is changed, then do not inherit the state as
        // existing filters may not be applicable.
        this.stateService.transitionTo('ng-search', {
          ...filters,
          q: this.searchControl.value,
          resultType: selectedType,
        });

        this.globalSearchFiltersService.filters = filters;
      } else {
        this.stateService.transitionTo('ng-search', filters, {
          inherit: true,
          location: 'replace',
        });
      }
    });
  }

  /**
   * Handle keydown event on search text input.
   *
   * @param event Keyboard event.
   */
  onInputKeyDown(event: KeyboardEvent) {
    const target = event.target as HTMLInputElement;

    switch (event.key) {
      case 'Escape':
        // Unfocus and unhighlight the search text input.
        target.blur();
        this.searchResultsEnabled = false;

        return;
      case 'ArrowDown':
        if (this.searchResults) {
          // If there is a search results list, focus on that to allow
          // keyboard navigation there.
          this.searchResults.focus();
        }

        return;
      case 'Enter':
        // Go to the search results page with this string.
        this.selectSearchString(target.value);

        return;
      case 'Tab':
        // Follow default behavior and do not make the search objects API call.
        return;
    }
  }

  /**
   * Handle keydown event on search results list.
   *
   * @param event Keyboard event.
   */
  onSearchResultsKeyDown(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.searchResultsEnabled = false;

      return;
    }

    if (!['ArrowUp', 'ArrowDown', 'Enter', 'Tab'].includes(event.key)) {
      // If any other key is typed, focus input again and update the value
      // there.
      this.searchInput.nativeElement.focus();
    }
  }

  /**
   * Function which is called whenever there's a '/' keystroke.
   *
   * @param hasSlashBeenUsed Whether slash key was used to focus the input.
   */
  focusInput(hasSlashBeenUsed = false) {
    if (document.activeElement instanceof HTMLInputElement ||
      document.activeElement instanceof HTMLTextAreaElement) {
      // If an input or textarea element is already focussed, do not focus
      // the search text input element.
      return;
    }

    if (this.matDialog.openDialogs.length ||
      document.querySelector('.modal-backdrop') ||
      document.querySelector('.app-frame-hidden')) {
      // If a dialog is opened, or if the app frame is hidden, do not focus
      // the search text input element.
      return;
    }

    // Without setTimeout, the search text input element will be focussed with
    // '/' value in it.
    window.setTimeout(() => {
      this.searchInput.nativeElement.focus();
      this.searchInput.nativeElement.select();

      if (hasSlashBeenUsed) {
        // Do not show the slash tip if slash was used to highlight search in
        // the first place.
        this.searchSlashTipShown = true;
      }
    });
  }

  /**
   * Function to unfocus the search wrapper by marking input and search type
   * menu is inactive.
   */
  unfocusSearchWrapper() {
    window.setTimeout(() => {
      if (this.searchResultsActive || this.resultsTypeMenu?.menuOpen) {
        return;
      }

      // If the active element is outside of search input, then the input
      // and type menu are inactive.
      this.inputFocused = false;
    });
  }

  /**
   * Function to hide search results list.
   */
  hideSearchResults(eventTarget: HTMLElement) {
    if (!this.elementRef.nativeElement.contains(eventTarget)) {
      // Unfocus input when clicking outside of global search input.
      this.inputFocused = false;
    }

    if (!this.searchControl.value) {
      // Search results list will already be hidden
      return;
    }

    window.setTimeout(() => {
      // Set correct search results enabled value based on where clicked
      if (this.currentStateSearch) {
        if (!this.elementRef.nativeElement.contains(eventTarget)) {
          this.searchResultsEnabled = this.searchResultsActive;
        }
      } else {
        if (!this.resultsTypeMenuElement?.nativeElement.contains(eventTarget)) {
          this.searchResultsEnabled = this.searchResultsActive;
        }
      }
    });
  }

  /**
   * Function to handle when a result from search results list is selected.
   *
   * @param event Mat Selection List Change event.
   */
  onSearchResultsSelectionChange(event: MatSelectionListChange) {
    this.searchResultsEnabled = false;

    if (!event.options.length) {
      // If there is no value on selection, for example when clicking
      // "See All Results" anchor, exit early.
      return;
    }

    const {object, navItem = {}} = event.options[0].value;

    if (object) {
      this.stateService.transitionTo('ng-search', {
        q: this.searchControl.value,
        selectResultGlobalIds: [object.globalId],
        openedResultId: object.globalId,
      }, {inherit: true});

      return;
    }

    if (navItem.href) {
      // If the option has an href, open that in a window.
      window.open(navItem.href, '_blank', 'noopener');
    }

    if (navItem.action) {
      // If the option has an action, call that.
      navItem.action();
    }

    if (navItem.state) {
      // If the option has a state, go the that state directly.
      this.stateService.transitionTo(navItem.state, navItem.stateParams);
    }
  }

  /**
   * On search form submit.
   */
  selectSearchString(searchString) {
    this.searchResultsEnabled = false;
    (document.activeElement as HTMLElement).blur();

    if (!this.searchSlashTipShown) {
      this.snackBarService.open(this.translateService.instant('typeSlashToSearch'));
      this.searchSlashTipShown = true;
    }

    this.stateService.transitionTo('ng-search', {
      q: searchString || this.globalSearchResultsService.searchString || '*',
      resultType: this.selectedType,
    }, {inherit: true});
  }

  /**
   * Handle blur of search button.
   */
  selectSearchStringBlur() {
    this.searchResultsEnabled = false;
    this.inputFocused = false;
  }
}
