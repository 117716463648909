import { FilterConfig } from '@cohesity/utils';

/**
 * Alerts Filters list
 */
export const HealthAlertsFilterSettings: FilterConfig[] = [
  {
    name: 'alertTypeBucketList',
    prefix: 'enum.alertTypeBucket.',
    values: ['kHardware', 'kSoftware', 'kMaintenance', 'kDataService'],
  },
  {
    name: 'alertCategoryList',
    prefix: 'enum.alertCategory.',
    values: [
      'kAgent',
      'kDisk',
      'kNode',
      'kCluster',
      'kChassis',
      'kDataSourceConnector',
      'kGeneralSoftwareFailure',
      'kPowerSupply',
      'kCPU',
      'kMemory',
      'kTemperature',
      'kFan',
      'kNIC',
      'kFirmware',
      'kNodeHealth',
      'kOperatingSystem',
      'kDataPath',
      'kMetadata',
      'kIndexing',
      'kHelios',
      'kAppMarketPlace',
      'kSystemService',
      'kLicense',
      'kSecurity',
      'kUpgrade',
      'kClusterManagement',
      'kAuditLog',
      'kNetworking',
      'kConfiguration',
      'kStorageDevice',
      'kStoragePool',
      'kStorageUsage',
      'kFaultTolerance',
      'kBackupRestore',
      'kArchivalRestore',
      'kRemoteReplication',
      'kQuota',
      'kCDP',
      'kViewFailover',
      'kDisasterRecovery',
    ],
  },
  {
    name: 'alertSeverityList',
    prefix: 'enum.alertSeverity.',
    values: ['kCritical', 'kWarning', 'kInfo'],
  },
  {
    name: 'alertStateList',
    prefix: 'enum.alertState.',
    values: ['kOpen', 'kResolved', 'kNote'],
  },
  {
    name: 'tenantIds',
    prefix: '',
    values: [],
  },
  {
    name: 'regions',
    prefix: '',
    values: [],
  },
];

export const HealthNotificationFilterSettings: FilterConfig[] = [
  {
    name: 'alertTypeBucketList',
    prefix: 'enum.alertTypeBucket.',
    values: ['kHardware', 'kSoftware', 'kMaintenance', 'kDataService'],
  },
  {
    name: 'alertCategoryList',
    prefix: 'enum.alertCategory.',
    nestedValues: [
      {
        name: 'kHardware',
        subValues: [
          'kDisk',
          'kNode',
          'kCluster',
          'kChassis',
          'kPowerSupply',
          'kCPU',
          'kMemory',
          'kTemperature',
          'kFan',
          'kNIC',
          'kFirmware'
        ]
      },
      {
        name: 'kSoftware',
        subValues: [
          'kNodeHealth',
          'kOperatingSystem',
          'kDataPath',
          'kMetadata',
          'kIndexing',
          'kHelios',
          'kAppMarketPlace',
          'kSystemService'
        ]
      },
      {
        name: 'kMaintenance',
        subValues: [
          'kLicense',
          'kSecurity',
          'kUpgrade',
          'kClusterManagement',
          'kAuditLog',
          'kNetworking',
          'kConfiguration',
          'kStorageUsage',
          'kFaultTolerance'
        ]
      },
      {
        name: 'kDataService',
        subValues: [
          'kBackupRestore',
          'kArchivalRestore',
          'kRemoteReplication',
          'kQuota',
          'kCDP',
          'kViewFailover'
        ]
      }
    ]
  },
  {
    name: 'alertSeverityList',
    prefix: 'enum.alertSeverity.',
    values: ['kCritical', 'kWarning', 'kInfo'],
  },
  {
    name: 'alertStateList',
    prefix: 'enum.alertState.',
    values: ['kOpen', 'kResolved', 'kNote'],
  },
  {
    name: 'tenantIds',
    prefix: '',
    values: [],
  },
  {
    name: 'regionIds',
    prefix: '',
    values: [],
  }
];

/**
 * Components Filters list
 */
export const HealthComponentsFilterSettings: FilterConfig[] = [
  {
    name: 'tenantIds',
    prefix: '',
    values: [],
  },
];

/**
 * Alerts Table columns list
 */
export const HealthAlertsTableColumns = [
  'severity',
  'id',
  'name',
  'occurrences',
  'lastOccurrence',
  'category',
  'typeBucket',
  'status',
];

// Severity order to sort the components cards
export enum HealthSeverityRank {
  kCritical,
  kWarning,
  kInfo,
  kSuccess,
}

// Icons replacement for the unavailable category icons removed with letter k
export const HealthIconsNames = {
  kAntivirus: 'health-antivirus',
  kAppMarketPlace: 'health-marketPlace',
  kAppsInfra: 'health-appsInfra',
  kArchivalRestore: 'health-archivalRestore',
  kBackupRestore: 'health-backupRestore',
  kCDP: 'health-cdp',
  kChassis: 'health-chassis',
  kCluster: 'health-cluster',
  kClusterManagement: 'health-clusterManagement',
  kConfiguration: 'health-configuration',
  kCPU: 'health-cpu',
  kDataPath: 'health-dataPath',
  kDisasterRecovery: 'health-disasterRecovery',
  kDisk: 'health-disk',
  kEncryption: 'health-encryption',
  kFan: 'health-fan',
  kFaultTolerance: 'health-faultTolerance',
  kFirmware: 'health-firmware',
  kFlashblade: 'health-flashblade',
  kIndexing: 'health-indexing',
  kLicense: 'health-license',
  kMemory: 'health-memory',
  kMetadata: 'health-metadata',
  kNetworking: 'health-networking',
  kNIC: 'health-nic',
  kNode: 'health-node',
  kNodeHealth: 'health-nodeHealth',
  kOperatingSystem: 'health-operatingSystem',
  kPowerSupply: 'health-powerSupply',
  kQuota: 'health-quota',
  kRemoteReplication: 'health-replication',
  kSecurity: 'health-security',
  kStorageUsage: 'health-storageUsage',
  kSystemService: 'health-systemService',
  kTemperature: 'health-temperature',
  kUpgrade: 'health-upgrade',
  kViewFailover: 'health-failover',
};

/**
 * Alerts Resolutions Table columns list
 */
export const HealthResolutionsTableColumns = ['resolutionSummary', 'userName', 'creationDate', 'alertsResolved'];
/**
 * Alerts Resolutions Table columns list for Mcm
 */
export const McmHealthResolutionsTableColumns = ['resolutionSummary', 'creationDate', 'alertsResolved'];

/**
 * Resolutions Filters list
 */
export const HealthResolutionsFilterSettings: FilterConfig[] = [
  {
    name: 'tenantIds',
    prefix: '',
    values: [],
  },
];

/*
 * Resolution Details Columns
 */
export const HealthResolutionDetailsTableColumns = ['alertId', 'alertName', 'date'];

/**
 * Alert types for Sunburst categorizing
 */
export const HealthAlertTypes = ['kSoftware', 'kHardware', 'kService', 'kOther'];

/**
 * Alert types that are deprecated in the newer list.
 * Helios needs to maintain backward compatibility to filter across deprecated alerts
 * as well.
 */
export const DeprecatedAlertTypes = {
  'alertTypeBucketList' : [],
  'alertCategoryList': ['kClusterHealth', 'kEncryption', 'kHeliosProActiveWellness',
    'kHeliosAnalyticsJobs', 'kHeliosSignatureJobs', 'kAppsInfra', 'kAntivirus', 'kArchivalCopy'],
  'alertSeverityList': [],
  'alertStateList': [],
  'tenantIds': [],
};

/**
 * Alerts Filters list
 */
export const DmsHealthAlertsFilterSettings: FilterConfig[] = [
  {
    name: 'alertTypeBucketList',
    prefix: 'enum.alertTypeBucket.',
    values: ['kDataService'],
  },
  {
    name: 'alertCategoryList',
    prefix: 'enum.alertCategory.',
    values: [
      'kBackupRestore',
      'kSecurity',
    ],
  },
  {
    name: 'alertSeverityList',
    prefix: 'enum.alertSeverity.',
    values: ['kCritical', 'kWarning', 'kInfo'],
  },
  {
    name: 'alertStateList',
    prefix: 'enum.alertState.',
    values: ['kOpen', 'kResolved', 'kNote'],
  },
];

/**
 * Alerts Filters list for DRAAS
 */
export const DraasHealthAlertsFilterSettings: FilterConfig[] = [
  {
    name: 'alertTypeBucketList',
    prefix: 'enum.alertTypeBucket.',
    values: ['kSoftware'],
  },
  {
    name: 'alertCategoryList',
    prefix: 'enum.alertCategory.',
    values: [
      'kDisasterRecovery',
    ],
  },
  {
    name: 'alertSeverityList',
    prefix: 'enum.alertSeverity.',
    values: ['kCritical', 'kWarning', 'kInfo'],
  },
  {
    name: 'alertStateList',
    prefix: 'enum.alertState.',
    values: ['kOpen', 'kResolved', 'kNote'],
  },
  {
    name: 'tenantIds',
    prefix: '',
    values: [],
  },
  {
    name: 'regionIds',
    prefix: '',
    values: [],
  }
];

/**
 * Categories of Alerts for SmartFiles clusters.
 */
export const SmartFilesHealthAlertCategories = [
  'kDataPath',
  'kRemoteReplication',
  'kDisk',
  'kSecurity',
  'kNetworking',
  'kViewFailover',
];

/**
 * Filter params for Alerts in SmartFiles scope.
 */
export const SmartFilesHealthAlertsFilterSettings: FilterConfig[] = [
  ...HealthAlertsFilterSettings,
  {
    name: 'alertCategoryList',
    prefix: 'enum.alertCategory.',
    values: [...SmartFilesHealthAlertCategories],
  },
  {
    name: 'alertSeverityList',
    prefix: 'enum.alertSeverity.',
    values: ['kCritical', 'kWarning', 'kInfo'],
  },
  {
    name: 'alertStateList',
    prefix: 'enum.alertState.',
    values: ['kOpen', 'kResolved', 'kNote'],
  },
  {
    name: 'tenantIds',
    prefix: '',
    values: [],
  },
  {
    name: 'regionIds',
    prefix: '',
    values: [],
  }
];
