<coh-source-special-parameters [form]="form" [nodeContext]="nodeContext"
  [label]="filePaths?.length ? ('cSourceTreeNode.customized' | translate) : ''"
  (dialogOpened)="updateForm()"
  (saved)="onSaved()">
  <ng-container *ngIf="form" [formGroup]="form">

    <div *ngIf="isDmsScope" class="margin-bottom">
      <h2>{{'objectsProtected' | translate}}</h2>
      {{(node?.isWindows ? 'cFilePaths.allLocalVolumesInServer' : 'cFilePaths.allLocalVolumesAndSelectedMountPoints')
        | translate}}
      <mat-divider class="margin-top"></mat-divider>
    </div>

    <div class="row no-margin-left margin-top-lg margin-bottom-sm">
      <mat-radio-group formControlName="protectionType" (change)="handleProtectionTypeChange($event.value)">
        <!-- Protect specific directories -->
        <mat-radio-button value="specifyDirectories" class="margin-right-xl"
          *ngIf="!isDmsScope" cogDataId="is-specify-dir-backup">
          <div>
            <span>
              {{'cFilePaths.specifyDirectories' | translate}}
            </span>
          </div>
        </mat-radio-button>
        <!-- Protect all local volumes -->
        <mat-radio-button value="allVolumes" class="margin-right-xl"
          *ngIf="flagEnabled('enablePhysicalFileAllDriveBackup') && !isDmsScope" [cogDataId]="should-protect-all-volumes">
          <div>
            <span>
              {{'cFilePaths.allVolumes' | translate}}
            </span>
          </div>
        </mat-radio-button>
        <!-- Protect through filelist -->
        <mat-radio-button value="metadataFile"
          *ngIf="flagEnabled('enablePhysicalFileMetaFileBackup') && !isDmsScope
            && (!flagEnabled('unixClusterBackupEnabled') || !node.isUnixCluster)" cogDataId="is-metadata-file-backup"
          [matTooltip]="'cFilePaths.metadataFilePathTooltip' | translate">
          <div>
            <span>
              {{'cFilePaths.directiveFile' | translate}}
            </span>
          </div>
        </mat-radio-button>
      </mat-radio-group >
    </div>

    <!-- Protect nested mount points selection section -->
    <div *ngIf="showSkipNestedDropdown && isDmsScope">
      <mat-checkbox cogDataId="select-nested-mount-points"
        formControlName="protectNetworkMountPoints"
        (change)="selectAllMountTypes($event.checked)">
        <span>{{'protectNetworkMountPoints' | translate}}</span>
      </mat-checkbox>
    </div>

    <!-- Skip nested mount point types dropdown -->
    <div class="row margin-top-lg" *ngIf="showSkipNestedDropdown && form.value.protectionType !== 'allVolumes' &&
      form.value.protectionType !== 'metadataFile' && !isDmsScope">
      <mat-form-field class="col-xs-8">
        <mat-label>
          {{'cFilePaths.skipNestedMountPointsPlaceholder' | translate}}
        </mat-label>
        <mat-select *ngIf="!showSkipUnmountedFS"
          formControlName="skipNestedVolumesVec" cogDataId="skip-nested-mount-volume"
          multiple>
          <mat-option *ngFor="let mountType of node.volumeMountTypes"
            [cogDataId]="'option-' + mountType" [inheritDataId]="true" [value]="mountType">
            {{mountType}}
          </mat-option>
        </mat-select>

        <!-- Allow skip nested mount point types even when FS is not mounted -->
        <mat-chip-list *ngIf="showSkipUnmountedFS" #chipList>
          <mat-chip *ngFor="let skippedMountType of form.value.skipNestedVolumesVec"
            [cogDataId]="skippedMountType" [inheritDataId]="true"
            (removed)="removeMountPoint(skippedMountType)">
            {{skippedMountType}}
            <cog-icon shape="cancel" matChipRemove></cog-icon>
          </mat-chip>
          <input #mountPointInput
            [placeholder]="'addNew' | translate"
            [matAutocomplete]="auto"
            [matChipInputFor]="chipList"
            [matChipInputAddOnBlur]="true"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            (matChipInputTokenEnd)="addMountPoint($event)"
            cogDataId="skip-nested-mount-volume-input">
        </mat-chip-list>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectMountPoint($event)">
          <mat-option *ngFor="let mountType of node.volumeMountTypes"
            [cogDataId]="'option-' + mountType" [inheritDataId]="true" [value]="mountType">
            {{mountType}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>

    <!-- Include path for metadata file backup -->
    <div *ngIf="form.value.protectionType === 'metadataFile'" cogDataId="metadata-file-path">
      <div class="margin-top-lg">
        <h3 class="margin-bottom-sm">
          {{'cFilePaths.metadataFilePath' | translate}}
        </h3>
        <mat-form-field>
          <input formControlName="metadataPathInfo" cogDataId="include-input" [inheritDataId]="true"
            [placeholder]="'filePath' | translate" matInput>
          <mat-error *ngIf="form.get('metadataPathInfo').hasError('required')">
            {{'errors.required' | translate}}
          </mat-error>
          <mat-error *ngIf="form.get('metadataPathInfo').hasError('pattern')">
            {{'errors.pattern' | translate}}
          </mat-error>
        </mat-form-field>
        <cog-banner status="warn"
          *ngIf="(form.get('metadataPathInfo').value || '').startsWith(' ') || (form.get('metadataPathInfo').value || '').endsWith(' ')">
          {{ 'filePathSpacesWarning' | translate }}
        </cog-banner>
      </div>
    </div>

    <div *ngIf="isDmsScope" formArrayName="filePaths" cogDataId="dms-file-paths">
      <h3 class="no-margin-bottom margin-top">{{'filePaths' | translate}}</h3>
      <mat-card *ngFor="let filePath of form.get('filePaths').controls; let pathIndex = index;"
        [formGroupName]="pathIndex"
        class="margin-top">
        <mat-checkbox
          *ngIf="!showSkipNestedDropdown"
          [checked]="!filePath?.value?.skipNestedVolumes"
          class="margin-top"
          (change)="selectNestedMountTypes($event.checked, filePath)"
          cogDataId="protect-nested-volumes-checkbox"
          [matTooltip]="'cFilePaths.protectNestedVolumesTooltip' | translate">
          <span>
            {{'protectNestedMountPoints' | translate}}
          </span>
        </mat-checkbox>
        <mat-form-field class="margin-top">
          <mat-label>{{ 'fileInclusions' | translate }}</mat-label>
          <input
            formControlName="path"
            [cogDataId]="'include-input-' + pathIndex"
            [inheritDataId]="true"
            placeholder="{{'includeFilePath' | translate}}"
            matInput required>
            <mat-error *ngIf="filePath.get('path').hasError('required')">
              {{'errors.required' | translate}}
            </mat-error>
            <mat-error *ngIf="filePath.get('path').hasError('pattern')">
              {{'errors.pattern' | translate}}
            </mat-error>
        </mat-form-field>
        <button (click)="addPath()"
          cogDataId="add-include-input" [inheritDataId]="true" mat-icon-button type="button">
          <cog-icon shape="add_circle_outline"></cog-icon>
        </button>
        <button *ngIf="form.get('filePaths')?.length > 1"  class="margin-right" cogDataId="remove-include-input" [inheritDataId]="true"
          (click)="removePath(pathIndex)" mat-icon-button type="button">
          <cog-icon shape="remove_circle_outline"></cog-icon>
        </button>
        <div class="margin-top">
          <!-- List of exclude paths for each include path -->
          <div formArrayName="excludePaths">
            <div class="margin-top-lg" *ngFor="let excludePath of filePath.get('excludePaths').controls;
              let excludeIndex = index; let last=last; let first=first;">
              <span class="physical-files-exclude-box"> {{'exclude' | translate}} </span>
              <mat-form-field class="exclusion-inputs-container">
                <input [formControl]="excludePath"
                  floatLabel="never"
                  placeholder="{{'typePath' | translate}}"
                  [cogDataId]="'exclude-input-' + excludeIndex" [inheritDataId]="true"
                  matInput>
                <mat-error *ngIf="excludePath.hasError('required')">
                  {{'errors.required' | translate}}
                </mat-error>
                <mat-error *ngIf="excludePath.hasError('pattern')">
                  {{'errors.pattern' | translate}}
                </mat-error>
              </mat-form-field>
              <button (click)="addExcludePath(filePath.controls.excludePaths)"
                cogDataId="add-exclude-path-input" [inheritDataId]="true" mat-icon-button type="button">
                <cog-icon shape="add_circle_outline"></cog-icon>
              </button>
              <button *ngIf="filePath.controls?.excludePaths?.length > 1" (click)="removeExcludePath(filePath.controls.excludePaths, excludeIndex)"
                cogDataId="remove-exclude-path-input" [inheritDataId]="true" mat-icon-button type="button">
                <cog-icon shape="remove_circle_outline"></cog-icon>
              </button>
            </div>
          </div>
        </div>
      </mat-card>
    </div>

    <!-- List of include paths -->
    <div *ngIf="form.value.protectionType !== 'allVolumes' && form.value.protectionType !== 'metadataFile' && !isDmsScope"
      formArrayName="filePaths" cogDataId="file-paths" class="margin-top-lg">
      <h3 class="margin-bottom-sm">
        {{'inclusions' | translate}}
      </h3>
      <div class="row no-margin-left" *ngFor="let filePath of form.get('filePaths').controls; let pathIndex = index;">
        <div color="accent" [formGroupName]="pathIndex" class="include-exclude-path-card margin-top col-sm-10">
          <mat-form-field>
            <input formControlName="path" [cogDataId]="'include-input-' + pathIndex" [inheritDataId]="true" matInput required
              [placeholder]="'includePath' | translate" [matAutocomplete]="includePath">
            <mat-autocomplete #includePath="matAutocomplete">
              <mat-option *ngFor="let option of includePathOptions; let i=index" [value]="option" cogDataId="include-path-{{option}}">
                {{option}}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="filePath.get('path').hasError('required')">
              {{'errors.required' | translate}}
            </mat-error>
            <mat-error *ngIf="filePath.get('path').hasError('pattern')">
              {{'errors.pattern' | translate}}
            </mat-error>
          </mat-form-field>
          <cog-banner status="warn" class="margin-top"
            *ngIf="(filePath.get('path').value || '').startsWith(' ') || (filePath.get('path').value || '').endsWith(' ')">
            {{ 'includePathSpacesWarning' | translate }}
          </cog-banner>

          <div>
            <mat-checkbox formControlName="skipNestedVolumes"
              *ngIf="!showSkipNestedDropdown"
              [cogDataId]="skip-nested-volumes-checkbox"
              [matTooltip]="'cFilePaths.skipNestedVolumesTooltip' | translate">
              <div>
                <span>
                  {{'skipNestedMountPoints' | translate}}
                </span>
              </div>
            </mat-checkbox>
          </div>

          <!-- List of exclude paths for each include path -->
          <div formArrayName="excludePaths" class="margin-top">
            <div *ngFor="let excludePath of filePath.get('excludePaths').controls; let excludeIndex = index;">
              <mat-form-field>
                <input [formControl]="excludePath" [placeholder]="'excludePath' | translate"
                  [cogDataId]="'exclude-input-' + excludeIndex" [inheritDataId]="true" matInput required>
                <mat-hint *ngIf="excludeIndex === ((filePath.get('excludePaths').controls || []).length - 1)">
                  {{'includePathHelpText' | translate}}
                </mat-hint>
              </mat-form-field>
              <button (click)="removeExcludePath(filePath.controls.excludePaths, excludeIndex)"
                cogDataId="remove-exclude-input" [inheritDataId]="true" mat-icon-button type="button">
                <cog-icon shape="clear"></cog-icon>
              </button>
              <cog-banner status="warn" class="margin-top"
                *ngIf="(excludePath.value || '').startsWith(' ') || (excludePath.value || '').endsWith(' ')">
                {{ 'excludePathSpacesWarning' | translate}}
              </cog-banner>
            </div>
          </div>

          <button class="margin-top-lg"
            cogDataId="add-exclude-input" [inheritDataId]="true"
            (click)="addExcludePath(filePath.controls.excludePaths)" color="accent"
            mat-stroked-button type="button">
            {{'addExclusion' | translate}}
          </button>
        </div>
        <div class="margin-top-xl col-sm-2">
          <button [ngClass]="{'invisible': (form.get('filePaths').controls || []).length === 1}"
            cogDataId="remove-include-input" [inheritDataId]="true" (click)="removePath(pathIndex)" mat-icon-button
            type="button">
            <cog-icon shape="remove_circle_outline"></cog-icon>
          </button>
          <button *ngIf="(form.get('filePaths').controls || []).length - 1 === pathIndex"
            cogDataId="add-include-input" [inheritDataId]="true" (click)="addPath()" type="button" mat-icon-button>
            <cog-icon shape="add_circle_outline"></cog-icon>
          </button>
        </div>
      </div>
    </div>

    <!-- List of exclude paths for protect all volumes-->
    <div *ngIf="form.value.protectionType === 'allVolumes' && !isDmsScope" formGroupName="protectAllPathInfo"
      class="margin-top-lg" cogDataId="protect-all-local-exclude-paths">
      <div class="margin-bottom-sm">
        <span>{{ 'cFilePaths.allLocalDrivesButExclusions' | translate }}</span>
        <h3 class="margin-top-lg">{{ 'exclusions' | translate }}</h3>
      </div>
      <div formArrayName="excludePaths">
        <div *ngFor="let excludePath of form.get('protectAllPathInfo').get('excludePaths').controls; let excludeIndex = index;">
          <mat-form-field>
            <input [formControl]="excludePath"
              placeholder="{{'exclusionPath' | translate}}"
              cogDataId="exclude-input" [inheritDataId]="true"
              matInput>
            <mat-error *ngIf="excludePath.hasError('required')">
              {{'errors.required' | translate}}
            </mat-error>
            <mat-error *ngIf="excludePath.hasError('pattern')">
              {{'errors.pattern' | translate}}
            </mat-error>
          </mat-form-field>
          <button (click)="removeExcludePath(form.get('protectAllPathInfo').controls.excludePaths, excludeIndex)"
            cogDataId="protect-all-local-remove-exclude-input" [inheritDataId]="true" mat-icon-button type="button">
            <cog-icon shape="remove_circle_outline"></cog-icon>
          </button>
          <button *ngIf="(form.get('protectAllPathInfo').get('excludePaths').controls || []).length - 1 === excludeIndex"
            (click)="addExcludePath(form.get('protectAllPathInfo').controls.excludePaths)"
            cogDataId="protect-all-local-add-exclude-input" [inheritDataId]="true" mat-icon-button type="button">
            <cog-icon shape="add_circle_outline"></cog-icon>
          </button>
          <cog-banner status="warn" class="margin-bottom-sm"
            *ngIf="(excludePath.value || '').startsWith(' ') || (excludePath.value || '').endsWith(' ')">
            {{ 'exclusionPathSpacesWarning' | translate }}
          </cog-banner>
        </div>
      </div>
      <button *ngIf="(form.get('protectAllPathInfo').get('excludePaths').controls || []).length === 0"
        class="margin-top" cogDataId="protect-all-local-add-exclude-input" [inheritDataId]="true"
        (click)="addExcludePath(form.get('protectAllPathInfo').controls.excludePaths)" color="accent" mat-stroked-button
        type="button">
        {{'addExclusion' | translate}}
      </button>
    </div>

    <!-- Follow NAS symlink target checkbox -->
    <mat-card class="margin-top">
      <div class="row" *ngIf="node.isWindows" class="margin-top-sm margin-bottom-sm">
        <mat-checkbox formControlName="followNasSymlinkTarget"
          [cogDataId]="follow-nas-symlin-tTarget">
          <div>
            <span>
              {{'cFilePaths.followNasSymlinkTarget' | translate}}
            </span>
          </div>
        </mat-checkbox>
      </div>
    </mat-card>
  </ng-container>
</coh-source-special-parameters>
