import { APP_BASE_HREF, CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
  inject,
} from '@angular/core';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { CohesityHelixModule, LineChartComponent } from '@cohesity/helix';
import { DatePipeWrapper } from '@cohesity/shared/integrations';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Options, Point, SeriesAreasplineOptions, SeriesLineOptions, YAxisOptions, merge } from 'highcharts';
import { get } from 'lodash-es';

import { ChartDataPoint, StatlistMultiSeriesInfo } from './model';

/**
 * Map of series names to translation keys.
 */
const seriesNameKeyMap = {
  'total': 'total',
  'usable': 'threshold',
  'storage-consumed': 'consumed',
  'required': 'required',
  'forecast-required': 'forecast',
  'available': 'available',
  'load-consumed': 'required',
  'forecast-consumed': 'forecast',
};

@Component({
  standalone: true,
  selector: 'sizer-muti-series-charts',
  templateUrl: './charts.component.html',
  styleUrls: ['./charts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [
    CommonModule,
    TranslateModule,
    CohesityHelixModule
  ],
  providers: [DatePipeWrapper],
})
export class ChartsComponent implements OnChanges {
  /**
   * Indicates whether the chart is being hovered on or not.
   */
  isChartActive = false;

  /**
   * Chart name is used to generate unique gradient url name.
   */
  @Input() name? = '';

  /**
   * Custom colorset className
   */
  @Input() colorSetClass: string;

  /**
   * Optional classname for the chart.
   */
  @Input() className: string;

  /**
   * Chart height in px.
   */
  @Input() chartHeight = 180;

  /**
   * Chart series info containing series name and label legend class for stat-list.
   */
  @Input() chartSeriesInfo: StatlistMultiSeriesInfo[] = [];

  /**
   * Chart data.
   */
  @Input() chartSeriesData: (SeriesAreasplineOptions | SeriesLineOptions | any)[] = [];

  /**
   * Optionally provide custom chart config.
   */
  @Input() customChartConfig: Options;

  /**
   * Indicates whether it's shared tooltip across all series.
   */
  @Input() sharedTooltip = false;

  /**
   * Indicated whether to show yAxis or not. Default to false.
   */
  @Input() showYAxis = false;

  /**
   * Date format for tooltip.
   */
  @Input() tooltipDateFormat = 'longTime';

  /**
   * Name of the chart.
   */
  @Input() chartName: string;

  /**
   * App base href to get assets.
   */
  baseHref =  inject(APP_BASE_HREF);

  /**
   * Tooltip content for supporting accessibility.
   */
  tooltipContent: string;

  /**
   * Highcharts YAxis config object.
   */
  yAxisConfig: YAxisOptions = {
    min: 0,
    minRange : 0.1,
    visible: true,
    title: {
      text: null,
    },
  };

  /**
   * Multi Series Highcharts options.
   */
  chartConfig: Options = {
    defs: {},
    chart: {
      spacing: [0, 0, 15, 0],
      styledMode: true,
      marginTop: 24,
      zoomType: 'x',
    },
    accessibility: {
      point: {
        descriptionFormatter: (p) => [this.datePipe.transform(p.x, 'longTime'), p.series.name,
          (p as ChartDataPoint).yFormatted].join(' ')
      },
      series: {
        pointDescriptionEnabledThreshold: false,
      },
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      area: {
        marker: {
          enabled: false,
        }
      },
      line: {
        marker: {
          enabled: false,
        }
      },
      areaspline: {
        marker: {
          enabled: false,
        },
        softThreshold: false,
      },
      series: {
        turboThreshold: 1000,
        events: {
          mouseOver: () => {
            if (!this.isChartActive) {
              this.isChartActive = true;
            }
          },
          mouseOut: () => {
            this.isChartActive = false;
          },
        },
        states: {
          hover: {
            enabled: false,
          },
          inactive: {
            opacity: 1,
          },
        },
      },
    },
    tooltip: {
      className: 'statlist-multi-service-tooltip',
      borderRadius: 10,
      enabled: true,
      hideDelay: 100,
      shadow: false,
      shared: true,
      useHTML: true,
      ...this.formatTooltip(),
    },
    xAxis: {
      crosshair: true,
    },
  };

  /**
   * Instance of line chart component.
   */
  @ViewChild('lineChart') lineChart: LineChartComponent;

  constructor(
    private datePipe: DatePipeWrapper,
    private changeDetector: ChangeDetectorRef,
    private translate: TranslateService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    const { customChartConfig } = changes;
    if (customChartConfig) {
      this.setChartConfigs();
    }
  }

  /**
   * Set chart configs.
   */
  setChartConfigs() {
    this.chartConfig = merge(this.chartConfig, this.customChartConfig);
    this.yAxisConfig = merge(this.yAxisConfig, this.customChartConfig?.yAxis);
    this.chartSeriesInfo?.forEach((seriesInfo: StatlistMultiSeriesInfo, i: number) => {
      const id = `${this.name}gradient-${i}`;
      // Define linearGradient SVG structure for each chart series.
      if (this.chartConfig) {
        this.chartConfig.defs[id] = {
          tagName: 'linearGradient',
          id,
          x1: 0,
          y1: 0,
          x2: 0,
          y2: 1,
          children: [{
            tagName: 'stop',
            offset: 0,
          }, {
            tagName: 'stop',
            offset: 1,
          }],
        };
        this.chartConfig.tooltip.shared = this.sharedTooltip;
      }
      // If custom labelLegend is not set, default to higcharts-color set.
      if (!seriesInfo.labelLegend) {
        seriesInfo.labelLegend = `highcharts-color-${i}`;
      }
    });
  }

  /**
   * On hovering over the chart, updates the current statlist.
   *
   * @param   points   Array of hovered points of all series in the chart.
   */
  onHoveringChart(points: Point[]) {
    // Pick formatted or raw for y axis value for showing stats list value.
    points.forEach((point: Point) => {
      // Exit if unable to find the point series info.
      if (!get(point, 'series')) {
        return;
      }
    });
  }

  /**
   * Set screen reader tooltip content.
   *
   * @param contents string content in an array.
   * @param timeout  Timeout in ms to reset content.
   */
  setScreenReaderTooltipContent(contents: string[], timeout = 100) {
    if (!contents || contents.length === 0) {
      contents = ['naNotavailable'];
    }
    this.tooltipContent = contents.join(' ');
    this.changeDetector.detectChanges();

    // For some unknown reason, screen reader is reading the previous change.
    // This is a hack to make the screen reader to read the above content.
    setTimeout(() => {
      this.tooltipContent = 'naNotavailable';
      this.changeDetector.detectChanges();
    }, timeout);
  }

  /**
   * Custom tooltip formatter for highcharts series.
   */
  formatTooltip() {
    // Cache component context for value formatting.
    const self = this;

    return {
      formatter(): string {
        let content = `<div class="statlist-multi-service-tooltip-inner ${self.chartName}-tooltip">
          <div><strong>${self.datePipe.transform(this.x, self.tooltipDateFormat)}</strong></div>`;
        // if required is present, hide forecast-required legend
        if (this.points.find(dataPoint => dataPoint.point.series.name === 'required')) {
          this.points = this.points.filter(dataPoint => dataPoint.point.series.name !== 'forecast-required');
        }
        // if load-consumed is present, hide forecast-consumed legend
        if (this.points.find(dataPoint => dataPoint.point.series.name === 'load-consumed')) {
          this.points = this.points.filter(dataPoint => dataPoint.point.series.name !== 'forecast-consumed');
        }

        /**
         * Returns translated series name, given a point.
         *
         * @param dataPoint One Highcharts datapoint.
         * @returns Translated string for the series name or empty string.
         */
        function getSeriesName(dataPoint) {
          const seriesName = seriesNameKeyMap[dataPoint.point?.series?.name];
          return seriesName ? self.translate.instant(seriesName) : '';
        }

        /**
         * Returns error description after trimming it.
         *
         * @param errorText Error description for tooltip.
         * @returns Trimmed error description.
         */
        function formatErrorText(errorText: string) {
          if (errorText.length > 180) {
            errorText = errorText.slice(0, 180) + '...';
          }
          if (errorText.length > 100) {
            const arr: string[] = errorText.split(' ');
            let flag = false;
            errorText = arr.reduce((prev, curr) => {
              if (prev.length + curr.length >= 100 && !flag) {
                prev += '<br/>';
                flag = true;
              }
              prev += ' ' + curr;
              return prev;
            }, '');
          }
          return errorText;
        }
        const referToOurDocumentation = self.translate.instant('referToOurDocumentation');

        content += `${this.points.map(dataPoint => {
          if(dataPoint.series.name === 'error'){
            const errorIconPath = `..${self.baseHref === '/' ? '' : self.baseHref}/assets/i/error.svg`;
            return `<div class="tooltip-error flex-column">
              <div class="flex-row vertical-align">
                <img class="margin-right-xs" src="${errorIconPath}"/>
                <span class="error-code">Error Code: ${dataPoint.point.errorCode}</span>
              </div>
              <div class="error-description">${formatErrorText(dataPoint.point.errorText)}</div>
              <span>${referToOurDocumentation}</span>
            </div>`;
          }
          return `<div class="flex-row-center">
          <div class="label-legend ${dataPoint.point.series.name} ${dataPoint.point.series.index}"></div>
          <span class="tooltip-series-name">${getSeriesName(dataPoint)}</span>
          <span class="tooltip-y-data pull-right">${dataPoint.point.yFormatted}</span>
          </div>`;
        }
        ).join('')}
          </div>`;

        self.setScreenReaderTooltipContent(
          [
            self.datePipe.transform(this.x, self.tooltipDateFormat),
            ...[].concat(...this.points.map(dataPoint => [
              dataPoint.point.series.name,
              dataPoint.point.yFormatted,
            ])),
          ], 300
        );

        return content;
      },
    };
  }

}
