import { merge } from 'lodash-es';
import { find } from 'lodash-es';
import { assign } from 'lodash-es';
// Cluster Service
import { getConfigByKey, isOneHeliosAppliance } from '@cohesity/iris-core';
import { executeWithFallbackPromise, removeKPrefix } from '@cohesity/utils';
import { map } from 'rxjs/operators';
import {Observable} from "rxjs";
import {Api} from "@cohesity/api/private";
import { environment } from 'src/environments/environment';

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C')
    .service('ClusterService', ClusterServiceFn);

  function ClusterServiceFn(
    _, $http, $q, $rootScope, $translate, $httpParamSerializer, $filter,
    $timeout, $window, $transitions, DateTimeService, evalAJAX,
    HARDWARE_TYPES, FEATURE_FLAGS, NgEulaService, API,
    FORMATS, ClusterServiceFormatter, LocaleService,
    cMessage, HeliosService, $state, localStorageService,
    NgLicenseNotificationService, NgClusterService, NgLicenseService,
    NgPassthroughOptionsService, $injector, featureFlagsService) {

    var taskPathVecPrefix = 'Nexus_Upgrade';

    // Number of miliseconds in a day
    var dayMSecs = 86400 * 1000;

    var clusterService = {
      acceptLicense: acceptLicense,
      acknowledgeUpgrades: acknowledgeUpgrades,
      availableUpgrades: [],
      get basicClusterInfo() {
        return NgClusterService.basicClusterInfo;
      },
      bringupCluster: bringupCluster,
      bringupStatus: bringupStatus,
      changeLocale: changeLocale,
      checkLicenseOverUsageMsg: checkLicenseOverUsageMsg,
      checkForNewPackages: checkForNewPackages,
      configureExternalKms: configureExternalKms,
      clusterInfo: undefined,
      clusterInfoWithStats: undefined,
      deleteExternalKmsKey: deleteExternalKmsKey,
      deletePackages: deletePackages,
      destroyCluster: destroyCluster,
      expandCluster: expandCluster,
      getApolloThrottlingInfo: getApolloThrottlingInfo,
      getAuditFilters: getAuditFilters,
      getAuditLogs: getAuditLogs,
      getExternalKms: getExternalKms,

      // will be populated with info when getBasicClusterInfo is called
      getBasicClusterInfo: getBasicClusterInfo,

      getBasicClusterInfoWithLocale: getBasicClusterInfoWithLocale,

      // will be populated with info when getClusterInfo is called
      getClusterInfo: getClusterInfo,
      getClusterInfoWithLocale: getClusterInfoWithLocale,

      getCapacityPredictionStats: getCapacityPredictionStats,
      getRemoteClusterInfo: getRemoteClusterInfo,
      isClusterCreateInProgress: isClusterCreateInProgress,
      getClusterNodes: getClusterNodes,
      getClusterStats: getClusterStats,
      getClusterPlatforms: getClusterPlatforms,
      getClusterRecipes: getClusterRecipes,
      getEula: getEula,
      getHardwareInfo: getHardwareInfo,
      getLoginBannerInfo: getLoginBannerInfo,
      getMcmClusters: getMcmClusters,
      getClusterStatus: getClusterStatus,
      getNTPservers: getNTPservers,
      getPublicKey: getPublicKey,
      getPulseTasks: getPulseTasks,
      getTieringInfo: getTieringInfo,
      getUpgradeTaskPath: getUpgradeTaskPath,
      getWhitelist: getWhitelist,
      getVlans: getVlans,
      hardwareInfo: undefined,
      isEulaNeeded: isEulaNeeded,
      isLicenseAcceptanceNeeded: isLicenseAcceptanceNeeded,
      updateRootWithLicenseSuccess: updateRootWithLicenseSuccess,
      isNewCluster: isNewCluster,
      isUserAcceptanceNeeded: isUserAcceptanceNeeded,
      isHpEulaNeeded: isHpEulaNeeded,
      listPackages: listPackages,
      loadLocale: loadLocale,
      getLicenseExpiryTime: getLicenseExpiryTime,
      parsePackageResponse: parsePackageResponse,
      parseSoftwareVersionString: ClusterServiceFormatter.parseSoftwareVersionString,
      removePatch: removePatch,
      restartService: restartService,
      showLicenseNotDeployedWarning: showLicenseNotDeployedWarning,
      showLicenseExpiryMsg: showLicenseExpiryMsg,
      skipLicensing: skipLicensing,
      updateApolloThrottlingInfo: updateApolloThrottlingInfo,
      updateClusterHash: updateClusterHash,
      updateClusterInfo: updateClusterInfo,
      updateClusterInfoInAjsService: updateClusterInfoInAjsService,
      updateHardwareEncryption: updateHardwareEncryption,
      updateEula: updateEula,
      updateKmsConfig: updateKmsConfig,
      updateLicenseState: updateLicenseState,
      updateLoginBannerInfo: updateLoginBannerInfo,
      updateNTPServers: updateNTPServers,
      updateSupportServerInfo: updateSupportServerInfo,
      updateSubnetWhitelist: updateSubnetWhitelist,
      updateTieringInfo: updateTieringInfo,
      upgradeCluster: upgradeCluster,
      uploadLicenseFile: uploadLicenseFile,
      uploadPackage: uploadPackage,
      getUpgradeCheckResults: getUpgradeCheckResults,
      runUpgradeCheck: runUpgradeCheck,
    };

    /**
     * TODO: Mock object which will be replaced by an API. Used by
     * getAuditFilters(). When the API is created, need to move the string
     * properties to iris.json for the backend to consume.
     *
     * @type       {object}
     */
    var auditFilterOptions = {
      actions: {
        'Activate': 'audit.filters.actions.activate',
        'Assign': 'audit.filters.actions.assign',
        'Cancel': 'audit.filters.actions.cancel',
        'Clone': 'audit.filters.actions.clone',
        'Close': 'audit.filters.actions.close',
        'CloudSpin': 'audit.filters.actions.cloudSpin',
        'Create': 'audit.filters.actions.create',
        'Deactivate': 'audit.filters.actions.deactivate',
        'Delete': 'audit.filters.actions.delete',
        'Disjoin': 'audit.filters.actions.disjoin',
        'Download': 'audit.filters.actions.download',
        'Install': 'audit.filters.actions.install',
        'Join': 'audit.filters.actions.join',
        'Login': 'audit.filters.actions.login',
        'Logout': 'audit.filters.actions.logout',
        'Mark': 'audit.filters.actions.mark',
        'Modify': 'audit.filters.actions.modify',
        'Overwrite': 'audit.filters.actions.overwrite',
        'Pause': 'audit.filters.actions.pause',
        'Recover': 'audit.filters.actions.recover',
        'Refresh': 'audit.filters.actions.refresh',
        'Register': 'audit.filters.actions.register',
        'Rename': 'audit.filters.actions.rename',
        'Resume': 'audit.filters.actions.resume',
        'RunNow': 'audit.filters.actions.runnow',
        'Unassign': 'audit.filters.actions.unassign',
        'Uninstall': 'audit.filters.actions.uninstall',
        'Unregister': 'audit.filters.actions.unregister',
        'Upgrade': 'audit.filters.actions.upgrade',
      },
      categories: {
        'Access Token': 'audit.filters.categories.accessToken',
        'Active Directory': 'audit.filters.categories.activeDirectory',
        'Alert Notification Rule': 'audit.filters.categories.alertNotificationRule',
        'Alert': 'audit.filters.categories.alert',
        'App': 'audit.filters.categories.app',
        'Chassis': 'audit.filters.categories.chassis',
        'Clone Task': 'audit.filters.categories.cloneTask',
        'Cloud Spin': 'audit.filters.categories.cloudSpin',
        'Cluster Partition': 'audit.filters.categories.clusterPartition',
        'Cluster': 'audit.filters.categories.cluster',
        'Disk': 'audit.filters.categories.disk',
        'Group': 'audit.filters.categories.group',
        'IdP Configuration': 'audit.filters.categories.idpConfiguration',
        'KMS Configuration': 'audit.filters.categories.KMS',
        'LDAP Provider': 'audit.filters.categories.LDAPProvider',
        'Network': 'audit.filters.categories.network',
        'Node': 'audit.filters.categories.node',
        'Organization': 'audit.filters.categories.organization',
        'Physical Agent': 'audit.filters.categories.physicalAgent',
        'Preferred Domain Controller': 'audit.filters.categories.preferredDomainController',
        'Protection Group Run': 'audit.filters.categories.protectionJobRun',
        'Protection Group': 'audit.filters.categories.protectionJob',
        'Protection Policy': 'audit.filters.categories.protectionPolicy',
        'Protection Source': 'audit.filters.categories.protectionSource',
        'ProxyServer': 'audit.filters.categories.proxyServer',
        'QoS Principal': 'audit.filters.categories.QoSPrincipal',
        'Recover Task': 'audit.filters.categories.recoverTask',
        'Remote Cluster': 'audit.filters.categories.remoteCluster',
        'Resolution': 'audit.filters.categories.resolution',
        'Restore Task': 'audit.filters.categories.restoreTask',
        'Role': 'audit.filters.categories.role',
        'Scheduler': 'audit.filters.categories.scheduler',
        'Service Flag': 'audit.filters.categories.serviceFlag',
        'SMTP Server': 'audit.filters.categories.SMTPServer',
        'SSL Certificate': 'audit.filters.categories.SSLCertificate',
        'Static Route': 'audit.filters.categories.staticRoute',
        'Storage Domain': 'audit.filters.categories.viewBox',
        'User': 'audit.filters.categories.user',
        'Vault': 'audit.filters.categories.vault',
        'View Alias': 'audit.filters.categories.viewAlias',
        'View': 'audit.filters.categories.view',
        'Vlan': 'audit.filters.categories.vlan',
      },
    };

    // Ensure root scope's value of clusterInfo is in sync with NgClusterService' value.
    NgClusterService.clusterInfo$.subscribe(clusterInfo => $rootScope.clusterInfo = ClusterServiceFormatter.transformClusterInfo(clusterInfo));

    // @type       {object} A hash of known clusters. Updated on-demand for
    //                      now.
    var knownClusters = {};

    /**
      * Method to Create a new cluster
      * @param  {Object}     params
      * @return {Cookie, Object}     Session Cookie, User Object
      */
    function bringupCluster(params) {
      var endpoint = ($rootScope.isPhysicalCluster ||
        $rootScope.isPhysicalRobo) ? 'nexus/cluster/bringup' :
          'nexus/cluster/virtual_robo_create';

      return $http({
        method: 'post',
        url: API.private(endpoint),
        data: params
      });
    }

    /**
     * AJAX poll to get cluster setup status.
     *
     * @method   bringupStatus
     * @param    {integer}   [interval=30000]   Interval of milliseconds between
     *                                          API calls, default is 30000,
     *                                          minimum is 1000
     * @param    {integer}   [maxRetries=10]    How many times to retry after
     *                                          fail, defaults to 10
     * @return   {object}    Promise to resolve with polled responses.
     */
    function bringupStatus(interval, maxRetries) {
      var url;
      var poll;
      var polling;
      var pollTimeoutPromise;
      var data = {};
      var failCount = 0;
      var deferred = $q.defer();
      interval = interval || 30000;
      maxRetries = maxRetries || 10;
      interval = (interval < 1000) ? 1000 : interval;

      poll = function poll() {
        polling = true;
        $http.get(API.private('nexus/cluster/bringup_status'), {
          timeout: interval
        })
        .then(function successPoll(response) {
          data = response.data;
          if (!data.inProgress) {
            // Bringup is complete, let's cancel the poll
            polling = false;
            $timeout.cancel(pollTimeoutPromise);

            if (data.errorMessage) {
              // Nexus returned an errorMessage. We will treat this response as a
              // valid error. Reject the promise and pass back the data.
              deferred.reject(data);
            } else {
              deferred.resolve(data);
            }

          } else {
            // If Cluster setup is still in progress, please notify the
            // controller.
            deferred.notify(data);
          }

        }, function errorPoll(response) {
          if (response && response.status === 500) {
            // If the response.status is 500, we will continue to poll.
            // Presumably Nexus is cleaning up after an internal error.
            deferred.notify(null);
          } else {
            /*
            If the response.status is not 500, we assume that Nexus has
            reassigned the IP address of the this node. We must reload this
            window to restart the cluster status poll.

            This works when a Node's IP changes because at the stage this is
            being used (cluster-setup.confirm), the user is using a hostname,
            like XXX.local. So a simple refresh is sufficient.

            NOTE: If Nexus becomes unreachable for any reason, the above logic
            will refresh the parent window.

            TODO: Determine how to distinguish between nexus errors and node IP
            reassignment.
            */
            $window.location.reload();
          }
        })
        .finally(function afterPoll() {
          // if our poll is not yet complete,
          // let's kick off another timeout
          if (polling) {
            pollTimeoutPromise = $timeout(poll, interval);
          }
        });
      };

      // instantiate our poll immediately
      poll();

      $transitions.onStart({}, function cancelFn(trans) {
        $timeout.cancel(pollTimeoutPromise);
      }, { invokeLimit: 1 });

      return deferred.promise;
    }

    /**
     * restarts service(s) on the cluster
     * @param     {Object}    data    { services: []string, clusterId: Number}
     * @return    {Object}             Promise
     */
    function restartService(data) {
      return $http({
        method: 'post',
        url: API.private('nexus/cluster/restart'),
        data: data,
      });
    }

    /**
     * expand cluster
     * @param     {object}    data    {
     *                                  nodes: [],
     *                                  clusterPartitionId,
     *                                  autoUpdate,
     *                                  ignoreSwIncompatibility
     *                                }
     *
     * @return    {object}            promise to resolve the API query
     */
    function expandCluster(data) {
      return $http({
        method: 'post',
        url: API.private('nexus/cluster/expand'),
        data: data,
      });
    }

    /**
     * get cluster nodes
     *
     * @param     {object}    opts    {
     *                                  includeMarkedForRemoval: true/false,
     *                                  fetchStats: true/false,
     *                                 }
     *
     * @return {object} promise to resolve the API query
     */
    function getClusterNodes(opts) {
      const v1Endpoint = API.private('nodes');
      const v2Endpoint = API.privateV2('clusters/nodes');
      const isPlatformBucket2v2ApiMigration = featureFlagsService.enabled('platformBucket2v2ApiMigration');

      const fetchNodesFn = url => {
        const params = {
          method: 'get',
          url,
          params: opts ? opts : null,
        };
        return () => $http(params);
      };

      const fetchNodes = isPlatformBucket2v2ApiMigration
        ? executeWithFallbackPromise(fetchNodesFn(v2Endpoint), fetchNodesFn(v1Endpoint))
        : executeWithFallbackPromise(fetchNodesFn(v1Endpoint));

      return fetchNodes;
    }

    /**
     * Get the public key for the cluster.
     *
     * @method     getPublicKey
     * @return     {object}  returns a promise to resolve the request for public key.
     *                        on success, resolves with public key string
     *                        on failure, rejects with server response
     */
    function getPublicKey() {
      return $http({
        method: 'post',
        url:'/v2/clusters/ssh-public-key',
        data: { 'workflowType': 'DataProtection'}
      }).then(
        function getKeySuccess(response) {
          if (response && response.data && response.data.public_key) {
            return response.data.public_key;
          }
          return '';
        }
      );
    }

    /**
     * Fetch the cluster info of remote cluster.
     *
     * @method   getRemoteClusterInfo
     * @param    {Object|String}   cluster/clusterId   The new remote cluster or
     *                                                 remote cluster id.
     * @return   {Object}   promise resolved with remote cluster info else
     *                      rejected with error.
     */
    function getRemoteClusterInfo(cluster) {
      var clusterId;
      var headers = {};

      if (angular.isObject(cluster)) {
        // cluster id Zero indicates backend to go and fetch remote cluster view
        // boxes list by using credentials given in header.
        clusterId = 0;
        angular.extend(headers, {
          hostname: cluster.remoteIps[0],
          username: cluster.userName,
          password: cluster.password,
        });
      } else {
        clusterId = cluster;
      }

      return $http({
        method: 'GET',
        url: API.public(
          'remoteClusterApi', clusterId, 'public/cluster'),
        headers: headers,
      }).then(function gotRemoteClusterInfo(response) {
        return ClusterServiceFormatter.transformClusterInfo(response.data);
      });
    }

    /**
     * Returns all the Helios Cluster registered to the logged in users account.
     *
     * @method   getMcmClusters
     * @return   {object}   A promise with list of cluster or error.
     */
    function getMcmClusters() {
      var opts = {
        method: 'GET',
        url: API.mcm('clusters'),
      }

      return $http(opts).then(function handleResponse(res) {
        return res.data || [];
      });
    }

    /**
     * Fetch the cluster info and cache it on this.basicClusterInfo.
     * NOTE: All of these calls will resolve without login.
     *
     * @method     getBasicClusterInfo
     * @param      {boolean}  [forceQuery=false]  force an API query even if the
     *                                            cache is populated
     * @return     {Object}  Q promise carrying the server's response
     */
    function getBasicClusterInfo(forceQuery) {
      return NgClusterService.getBasicClusterInfo(forceQuery).toPromise();
    }

    /**
     * Fetch the cluster info and cache it on this.clusterInfo
     *
     * @method   getClusterInfo
     * @param    {Object}   [params]            Optional query params.
     * @param    {Object}   [clusterDefaults]   Optional cluster defaults state
     *                                          used for all-cluster state which
     *                                          is a local cluster with one
     *                                          extra _allClusters flag
     * @param    {Boolean}   [forceFetch]       If true, make api request.
     * @return   {Object}                       Promise to resolve with the
     *                                          server's raw response.
     */
    function getClusterInfo(params, clusterDefaults, forceFetch = true) {
      clusterDefaults = clusterDefaults || {};
      var getClusterInfoPromise;

      // If forceFetch is set to false and cache is set, return cache data.
      if (params && params.fetchStats && clusterService.clusterInfoWithStats && !forceFetch) {
        getClusterInfoPromise = Promise.resolve(clusterService.clusterInfoWithStats);
      } else {
        getClusterInfoPromise =
          NgClusterService.getClusterInfo(true, params).pipe(map(resp => ({data: resp}))).toPromise();
      }

      return getClusterInfoPromise.then(function promiseSuccess(clusterInfoResp) {
          var clusterInfo = assign(
            clusterDefaults,
            ClusterServiceFormatter.transformClusterInfo(clusterInfoResp.data)
          );

        // Save to cache only if fetchStats is set to true.
          if (params && params.fetchStats) {
            clusterService.clusterInfoWithStats = clusterInfoResp;
          }

          $rootScope.clusterInfo = clusterService.clusterInfo = clusterInfo;

          // setup Date.clusterNow() now that clusterInfo is available
          DateTimeService.setupClusterNow(clusterService.clusterInfo);

          // On first getClusterInfo only, cache this as the
          // `originClusterInfo` for comparison as we change SPOG clusters,
          // etc.
          if (!$rootScope.originClusterInfo) {
            $rootScope.originClusterInfo =
              merge({}, $rootScope.clusterInfo);
          }

          return clusterInfoResp;
        });
    }

    /**
     * Fetch the login banner info
     *
     * @method   getLoginBannerInfo
     * @return   {Object}                  Promise to resolve with the
     *                                     server's raw response.
     */
    function getLoginBannerInfo() {
      return $http({
        method: 'get',
        url: API.public('banners'),
      }).then(
        function getLoginBannerInfoSuccess(resp) {
          return resp && resp.data;
        }
      );
    }

    /**
     * Update login banner info
     *
     * @method   updateLoginBannerInfo
     * @param    {Object}    data       banner object
     * @return   {Object}               Promise to resolve with the
     *                                  server's raw response.
     */
    function updateLoginBannerInfo(data) {
      return $http({
        method: 'put',
        url: API.public('banners/0'),
        data: data,
      });
    }

    /**
     * Gets the basic cluster info along with the locales and feature flags.
     * Called when the app is bootstrapped.
     *
     * @method   getBasicClusterInfoWithLocale
     * @param    {boolean}  [forceQuery=false]  force an API query even if the
     *                                          cache is populated
     * @return   {Object}   Promise resolved with the basicClusterInfo
     */
    function getBasicClusterInfoWithLocale(forceQuery) {
      return getBasicClusterInfo(forceQuery)
        .then(function gotBasicClusterInfo(basicClusterInfo) {
          return loadLocale(basicClusterInfo.languageLocale)
            .then(function loadLanuageFinally() {
              $rootScope.isAppBootstrapped = true;
              return clusterService.basicClusterInfo;
            });
        });
    }

    /**
     * Gets the cluster info along with the locales and feature flags.
     * Called when the app is bootstrapped to make sure the locale doesn't get
     * loaded again.
     *
     * @method   getClusterInfoAndLocale
     * @param    {Object}   [params]            Optional query params.
     * @param    {Object}   [clusterDefaults]   Optional cluster defaults state
     *                                          used for all-cluster state which
     *                                          is a local cluster with one
     *                                          extra _allClusters flag
     * @return   {Object}   Promise resolved with the clusterInfo
     */
    function getClusterInfoWithLocale(params, clusterDefaults) {
      var promises = {
        cluster: getClusterInfo(params, clusterDefaults),
      };

      // If feature flag is disabled then return 'en-us' by default.
      promises.locale = FEATURE_FLAGS.localizationEnabled ?
        LocaleService.getUserLocale().toPromise() : $q.resolve('en-us');

      return $q.all(promises)
        .then(function getClusterInfoAndLocale(resp) {
          return loadLocale(resp.locale)
            .then(function gotClusterInfoFinally() {
              return resp.cluster;
            });
        });
    }

    /**
     * Function which changes the locale by loading the ui.json corresponding to
     * the locale provided.
     *
     * @method    changeLocale
     * @param     {String}   languageLocale  The key of the locale to be loaded.
     */
    function changeLocale(languageLocale) {
      if (!languageLocale) {
        return;
      }

      // Leverage the isAppBootstrapped to show the loader and change the locale
      // afterwards.
      $rootScope.isAppBootstrapped = false;

      loadLocale(languageLocale).finally(function localeChangedFinally() {
        $rootScope.isAppBootstrapped = true;
      });
    }

    /**
     * Fetch capacity prediction stats
     * TODO (Nilesh) : remove additional (.data) once backend is fixed.
     *
     * @method   getCapacityPredictionStats
     * @return   {Object}                       Promise to resolve with the
     *                                          server's raw response.
     */
    function getCapacityPredictionStats() {
      var clusterInfo = $rootScope.clusterInfo;
      return $http({
        method: 'GET',
        url: API.mcm('capacityPrediction'),
        params: {
          clusterIdentifiers:
            [clusterInfo.id, ':', clusterInfo.incarnationId].join('')
        },
      }).then(function onSuccess(response) {
        return transformCapacityPredictionData(response.data.data) || {};
      }, evalAJAX.errorMessage);
    }

    /**
     * Transform capacity prediction data into chart format.
     *
     * @method    transformCapacityPredictionData
     *
     * @param     {object}    data    response object.
     * @returns   {object}            returns transformed object.
     */
    function transformCapacityPredictionData(data) {
      // transform data for area graph
      var transformedDataPointVec = (data.dataPointVec || []).map(
        function dataTransformer(dataPoint) {
          return [dataPoint.timestampMsecs, dataPoint.physicalUsedBytes];
        }).sort(function compareTimeStamps(a, b) {
          return a[0] - b[0];
        });

      // transform data for arearange
      var transformedRangeVec = (data.dataPointVec || []).filter(
        function filterRange(dataPoint) {
          return dataPoint.lowerBoundBytes;
        }).map(
        function rangeDataTransformer(dataPoint) {
          return [dataPoint.timestampMsecs, dataPoint.lowerBoundBytes, dataPoint.upperBoundBytes];
        }).sort(function compareTimeStamps(a, b) {
          return a[0] - b[0];
        });

      assign(data, { dataPointVec: transformedDataPointVec, rangePointVec: transformedRangeVec});
      return data;
    }

    /**
     * Update the support server details.
     * @method     updateSupportServerInfo
     *
     * @param      {object}  data   ReverseTunnel enable details.
     *
     * @return     {object}         returns updated support server details.
     */
    function updateSupportServerInfo(data) {
      return $http({
        method: 'put',
        url: API.private('reverseTunnel'),
        data: data
      });
    }

    /**
     * TODO: call this function from
     * ActiveDirectoryService.getActiveDirectories() so language will get loaded
     * for login page.
     *
     * @function loadLocale
     * @param      {string}  The languageLocale to load
     * @return     {object}  Returns a promise to resolve the request to load a
     *                       language file. Resolves with true on success, rejects
     *                       with raw server response.
     */
    function loadLocale(locale) {
      return LocaleService.loadLocale(locale).toPromise();
    }

    /**
     * function to get cluster expiry time
     *
     * @method getLicenseExpiryTime
     * @return {Object} Promise object
     */
    function getLicenseExpiryTime() {
      if ($rootScope.isTenantUser()) {
        return $q.when($rootScope.isTenantUser);
      } else {
        return NgLicenseNotificationService.getLicenseExpiryTime()
          .toPromise()
          .then(function checkLicenseState(response) {
            // if (clusterService.clusterInfo.licenseState.state !== 'kClaimed' && response.licenseDeployed) {
            //   updateLicenseState('claimed');
            // }

            // if (clusterService.clusterInfo.licenseState.state !== 'kSkipped' && response.isTrial) {
            //   updateLicenseState('skipped');
            // }
            return response;
          });
      }

    }

    /**
     * function to call on login to check license over usage
     *
     * @method checkLicenseOverUsageMsg
     */
    function checkLicenseOverUsageMsg() {
      var listOfFeatureOverUsed = [];
      var allFeatureOverused;
      var exceptLastFeature;
      var licenseOveruse;

      $http({
        method: 'get',
        url: API.private('nexus/license/account_overusage'),
      }).then( function evaluateLicenseNotification(response) {

        // Remove all consumption type feature from overusage notification
        licenseOveruse = response.data.LicenseOveruse.filter((feature) => {
          return !feature.hasConsumptionSKU;
        });

        if (licenseOveruse != null && licenseOveruse.length) {
          licenseOveruse.map(function formatMsg(feature) {
            listOfFeatureOverUsed.push(
              $translate.instant('overUsedFeature', feature));
          });

          if (listOfFeatureOverUsed.length > 1) {
            exceptLastFeature = listOfFeatureOverUsed
              .splice(0,listOfFeatureOverUsed.length-1).join(', ');
            allFeatureOverused = [exceptLastFeature, listOfFeatureOverUsed]
              .join($translate.instant('and'));
          } else {
            allFeatureOverused = listOfFeatureOverUsed[0];
          }

          _showLicenseNotification('warn', {
            titleKey: 'purcahseAdditionalCapacity',
            textKey: 'license.overUsageWarningNote',
            textKeyContext: {
              overUsedList: allFeatureOverused,
            },
            persist: true,
          });
        }
      });
    }

    /**
     * Internal fn to show license notification only once a session.
     *
     * @method _showLicenseNotification
     * @param {String} notificationType
     * @param {Object} notificationObject
     */
    function _showLicenseNotification(notificationType, notificationObject) {
      var lastNotificationTime = localStorageService.get(
        notificationObject.titleKey);

      // If user is tenant user, dont show him license notification
      if ($rootScope.isTenantUser() || FEATURE_FLAGS.forceLicensing) {
        return;
      }

      // If last showed time is more than 1 day before then show the
      // notification
      var messageNotShown = Date.clusterNow() - lastNotificationTime > dayMSecs;

      if (messageNotShown) {
        localStorageService.set(notificationObject.titleKey, Date.clusterNow());
        cMessage[notificationType](notificationObject);
      }
    }

    /**
     * method to handles case where user skips licensing for now
     *
     * @method   skipLicensing
     */
    function skipLicensing() {
      updateLicenseState('skipped').then(function changeStateAndUpdateRoot() {
        updateRootWithLicenseSuccess();
        $state.goHome();
        getLicenseExpiryTime()
          .then(function showPopUp(response) {
            _showLicenseNotification('warn', {
              titleKey: 'licenseNotDeployed',
              textKey: 'clusterConnect.darkSite.trialPeriodNote',
              textKeyContext: {
                date: response.licenseExpiryTimestamp * 1000,
              },
            });
          });
      });
    };

    /**
     * fn to call on login for checking license expiry date
     *
     * @method showLicenseExpiryMsg
     */
    function showLicenseExpiryMsg() {
      getLicenseExpiryTime().then(function getExpiryDate(response) {
        var expiryTime = response.licenseExpiryTimestamp * 1000;
        var timeNow = Date.clusterNow();
        var lasttimeMsg =
          localStorageService.get('nextTimeLicenseExpireWarning');
        var showExpiryMsg = lasttimeMsg ? timeNow >= lasttimeMsg : true;
        var isClassified = response.isClassified;
        var licenseDeployed = response.licenseDeployed;

        // show License Expiry soon msg only when license is deployed,
        // helios is not connected, account should not be classified one
        // and License expiry time must be in next 3 days from now
        var showLicenseExpirySoonMsg = licenseDeployed &&
          !HeliosService.heliosStatus._isRegistered &&
          !isClassified && DateTimeService.getNDaysFromNow(3) > expiryTime &&
          timeNow <= expiryTime;

        // isTrial signifies that user is using POC License
	// licenseDeployed: false cases only should check for below
	// licenseNotDeployed cMessage.
        if (!licenseDeployed) {
          if (timeNow <= expiryTime && showExpiryMsg &&
            !HeliosService.heliosStatus._isRegistered &&
            !($rootScope.isTenantUser() || FEATURE_FLAGS.forceLicensing)) {
            cMessage.warn({
              titleKey: 'licenseNotDeployed',
              textKey: 'license.uploadLicenseForSkipCase',
              textKeyContext: {
                expiryDate: expiryTime,
              },
              acknowledgeTextKey: 'deployLicense',
              dismissTextKey: 'skip',
              persist: true,
            }).then(function changeState() {
              $state.go('cluster-connect-dark-site');
            },function setLocalStorage() {
              // if user skips, note the time cookies
              // and show notification again after 7 days
              localStorageService
                .set('nextTimeLicenseExpireWarning',
                  Date.parse(DateTimeService.getNDaysFromNow(7)));
            });
          }
        } else if (clusterService.clusterInfo.licenseState.state === 'kClaimed') {
          // checkLicenseOverUsageMsg only if disableLicensingOverusage flag is false.
          if (!FEATURE_FLAGS.disableLicensingOverusage) {
              checkLicenseOverUsageMsg();
          }
        }

        if (showLicenseExpirySoonMsg) {
          _showLicenseNotification('warn', {
            titleKey: 'licenseWillExpireSoon',
            textKey: 'license.licenseRenewMsg',
            persist: true,
            textKeyContext: {
              expiryTime: expiryTime
            },
          });
        }
      });
    }

    /**
     * Check if EULA is needed to be signed
     * or licensing server is to be claimed by user.
     * If either of isEulaNeeded or !isLicenseServerClaimed is true
     * the user will be logged out and asked to
     * go through the eula agreement page
     *
     * @method isUserAcceptanceNeeded
     * @return {Boolean} True if userAcceptance is needed. False otherwise.
     */
    function isUserAcceptanceNeeded () {
      return isEulaNeeded() || FEATURE_FLAGS.licensing ?
        isLicenseAcceptanceNeeded() : false;
    }

    /**
     * This function determines whether to show license flow to user or not
     *
     * @method isLicenseAcceptanceNeeded
     *
     * @return {boolean} if license flow to be shown then true, otherwise false
     */
    function isLicenseAcceptanceNeeded() {
      // Using $injector since injecting this normally causes circular dependency error.
      var ctx = $injector.get('NgIrisContextService')?.irisContext;
      if (getConfigByKey(ctx, 'cluster.licenseNeeded', true)) {
        if (clusterService.clusterInfo.licenseState.state === 'kClaimed') {
          return false;
        }
        if (clusterService.clusterInfo.licenseState.state === 'kSkipped') {
          // TODO Manthan: integrate with licenseServer to check, when to show
          // user the license flow again
          return false;
        }

        return true;
      }
      return false;
    }

    /**
     * function to be called to update RootScope with isLicenseActivated
     * for Ng UI we are going to update NgLicenseService, and will subscribe to
     * its behavioral object isLicenseActivated
     *
     * @method updateRootWithLicenseSuccess
     */
    function updateRootWithLicenseSuccess() {
      // we will use NgLicenseService instead of rootscope
      NgLicenseService.isLicenseActivated = true;
    }

    /**
     * Checks if the assigned cluster is a new cluster.
     *
     * @return {Boolean} True is its a new Cluster. False otherwise.
     */
    function isNewCluster() {
      // Using $injector since injecting this normally causes circular dependency error.
      var ctx = $injector.get('NgIrisContextService')?.irisContext;
      var isEulaRequired = getConfigByKey(ctx, 'cluster.eulaNeeded', true);
      var isLicenseAcceptanceRequired = getConfigByKey(ctx, 'cluster.licenseNeeded', true);

      // If a cluster dont have eula config, its definitely a new cluster
      if (isEulaRequired && !clusterService.clusterInfo.eulaConfig) {
        return true;
      }

      // If a new cluster user accepted the eula config, and logout with out
      // accepting licensing, its licenseState.state will be started
      if (isLicenseAcceptanceRequired &&
        clusterService.clusterInfo.eulaConfig.signedVersion &&
        clusterService.clusterInfo.licenseState.state === 'kStarted') {
        return true;
      }
      return false;
    }

    /**
     * For old customer, license not deployed, show user warning
     *
     * @method showLicenseNotDeployedWarning
     */
    function showLicenseNotDeployedWarning() {
      _showLicenseNotification('warn', {
        titleKey: 'licenseNotDeployed',
        textKey: 'license.ConnectUsingHeliosIcon',
        persist: true,
      });
    }

    /**
     * function to update license state in clusterinfo
     *
     * @method updateLicenseState
     *
     * @param {String} state new state of licensing
     * @param {Integer} failedAttempts no of times user has tried connecting to
     *                                helios
     *
     * @returns {Object} Promise Object containing result from
     *                   UpdateClusterInfo api
     */
    function updateLicenseState(state, failedAttempts) {
      // TODO: ENG-76344, Due to security vulnerability, this logic will be
      // moved to backend.
      var statesMapping = {
        started: 'kStarted',
        claimed: 'kClaimed',
        skipped: 'kSkipped',
        inProgressNC: 'kInProgressNewCluster',
        inProgressOC: 'kInProgressOldCluster',
      }

      var data = angular.copy(clusterService.clusterInfo);

      data.licenseState.state = statesMapping[state];

      if (failedAttempts) {
        data.licenseState.failedAttempts = failedAttempts;
      }

      return updateClusterInfo(data);
    }

    /**
     * indicates if the EULA needs to be displayed to the user for acceptance
     *
     * @return     {boolean}  True if eula should be displayed, False otherwise.
     */
    function isEulaNeeded() {
      // Using $injector since injecting this normally causes circular dependency error.
      var ctx = $injector.get('NgIrisContextService')?.irisContext;
      if (getConfigByKey(ctx, 'cluster.eulaNeeded', true)) {
        var clusterInfo = clusterService.clusterInfo;
        return !clusterInfo.eulaConfig ||
          (clusterInfo.eulaConfig.signedVersion &&
          clusterInfo.eulaConfig.signedVersion < NgEulaService.version);
      }
      return false;
    }

    /**
     * give the text respone to be shown for user agreement
     *
     * @method getEula
     * @returns {Object} promis object containing the api response
     */
    function getEula(path) {
      return $http.get(path).then(function getEulatText(response) {
        return response.text;
      });

    }

    /**
     * Checks if cluster is using HP Hardware.
     *
     * @return {Boolean} True if HP Eula should be displayed
     */
    function isHpEulaNeeded() {
      var clusterInfo = clusterService.clusterInfo;

      return HARDWARE_TYPES.HP.includes(clusterInfo.hardwareInfo.hardwareModels[0]);
    }

    function getClusterStatus() {
      const v1Endpoint = API.public('cluster/status');
      const v2Endpoint = API.publicV2('clusters/status');
      const isPlatformBucket1v2ApiMigration = featureFlagsService.enabled('platformBucket1v2ApiMigration');

      const fetchClusterStatusFn = (url, converterFn = null) => {
        const params = {
          method: 'get',
          url,
        };
        return () => $http(params).then(response => converterFn ? converterFn(response) : response);
      };

      const converterFn = (response) => {
        return {
          ...response,
          data: {
            ...response.data,
            currentOperation: removeKPrefix(response.data?.currentOperation),
            airgapConfigStats: {
              airgapStatus: removeKPrefix(response.data?.airgapConfig?.airgapStatus),
              exceptionProfiles: response.data?.airgapConfig?.exceptionProfiles,
            }
          }
        };
      }

      const fetchClusterStatus = isPlatformBucket1v2ApiMigration
        ? executeWithFallbackPromise(fetchClusterStatusFn(v2Endpoint), fetchClusterStatusFn(v1Endpoint, converterFn))
        : executeWithFallbackPromise(fetchClusterStatusFn(v1Endpoint, converterFn));

      return fetchClusterStatus;
    }

    /**
     * Returns list of cluster recipes.
     *
     * @param {string[]} clusterIdentifiers list of cluster identifiers
     * @returns {Object} recipes
     */
    function getClusterRecipes(clusterIdentifiers) {
      return $http.get('/v2/mcm/stark/gflagrecipemgmt/recipes', { params: clusterIdentifiers });
    }


     /**
     * Returns upgrade check results.
     *
     */
    function getUpgradeCheckResults() {
      return $http.get('/v2/clusters/upgrade-checks/-1');
    }

    /**
     * Returns upgrade check results.
     *
     */
    function runUpgradeCheck() {
      return $http({
        method: 'put',
        url: '/v2/clusters/upgrade-checks',
        data: { "requestType" : "PreUpgrade" }
      }).then(
        function runUpgradeCheckSuccess(response) {
          return response;
        }
      );
    }
    /**
     * Get cluster's statistics.
     *
     * @method  getClusterStats
     * @return  {Object}  Data of the cluster's statistics.
     */
    function getClusterStats() {
      return $http({
        method: 'get',
        url: API.private('clusterStats'),
      });
    }

    /**
     * Get all platforms of this cluster.
     *
     * @method  getClusterPlatforms
     * @return  {object} Data of platforms details.
     */
    function getClusterPlatforms() {
      return $http({
        method: 'get',
        url: API.private('clusterPlatforms'),
      });
    }

    /**
     * Update the cluster info.
     *
     * @method   updateClusterInfo
     * @param    {object}   data   Data to update the cluster with.
     * @return   {object}          Promise to resolve with the server's
     *                             raw response.
     */
    function updateClusterInfo(data) {
      if (environment.heliosInFrame && (data.clusterId === 1 || data.id === 1)) {
        // This request is not valid due to following:
        // 1. user is inside iFrame (cluster in Helios) and is trying to modify clusterId: 1
        // 2. clusterId = 1 belongs to Helios. (backend sends dummy cluster information for Helios)
        return Promise.reject({ status: 400, response: { errorMessage: null }});
      }
      return $http({
        method: 'put',
        url: API.public('cluster'),
        data: data
      }).then(
        function updateClusterInfoSuccess(response) {
          $rootScope.clusterInfo = clusterService.clusterInfo =
            ClusterServiceFormatter.transformClusterInfo(response.data);
          return response;
        }
      );
    }

    /**
     * Updates clusterInfo object in the cluster service
     * Use this method on need basis to keep AJS cluster info in sync with Angular cluster info
     *
     * @param {*} clusterInfo Updated cluster info
     */
    function updateClusterInfoInAjsService(clusterInfo) {
      clusterService.clusterInfo = ClusterServiceFormatter.transformClusterInfo(clusterInfo);
    }

    /**
     * Update the hardware encryption.
     *
     * @method   updateHardwareEncryption
     * @param    {object}   data   Data to update the cluster with.
     * @return   {object}          Promise to resolve with the server's
     *                             raw response.
     */
    function updateHardwareEncryption(data) {
      return $http({
        method: 'post',
        url: API.private('nexus/cluster/update_hardware_encryption'),
        data: data
      }).then(
        function updateHardwareEncryptionSuccess(response) {
          return response.data;
        }
      );
    }

    /**
     * Configure an external KMS.
     *
     * @method   configureExternalKms
     * @param    {object}   data   Data to configure a KMS.
     * @return   {object}          Promise to resolve with the server's
     *                             raw response.
     */
    function configureExternalKms(data) {
      return $http({
        method: 'post',
        url: API.public('kmsConfig'),
        data: data,
        headers: NgPassthroughOptionsService.requestHeaders,
      }).then(function configureExternalKmsSuccess(response) {
          return response.data;
        }
      );
    }

    /**
     * Updates the KMS config.
     *
     * @method   updateKmsConfig
     * @param    {object}   data   Data to update the KMS config.
     * @return   {object}          Promise to resolve with the server's
     *                             raw response.
     */
    function updateKmsConfig(data) {
      return $http({
        method: 'put',
        url: API.public('kmsConfig'),
        data: data,
        headers: NgPassthroughOptionsService.requestHeaders,
      }).then(function configureExternalKmsSuccess(response) {
          return response.data;
        }
      );
    }

    /**
     * Delete a key from external KMS.
     *
     * @method   deleteExternalKmsKey
     * @param    {id}   number     Id of key to be deleted.
     * @return   {object}          Promise to resolve with the server's
     *                             raw response.
     */
    function deleteExternalKmsKey(id) {
      return $http({
        method: 'delete',
        url: API.public('kmsConfig/' + id),
        headers: NgPassthroughOptionsService.requestHeaders,
      }).then(function deleteExternalKmsKeySuccess(response) {
          return response.data;
        }
      );
    }

    /**
     * Gets the Tiering info
     *
     * @method   getTieringInfo
     * @returns  {Object}    promise to get tiering info.
     */
    function getTieringInfo() {
      return $http({
        method: 'get',
        url: API.public('clusters/ioPreferentialTier'),
      }).then(function transformTieringInfo(response) {
          return response.data || {};
        }
      );
    }

    /**
     * Updates the tiering info
     *
     * @method   updateTieringInfo
     * @param    {Object}    data     tiering data object
     * @returns  {Object}    promise to update tiering info.
     */
    function updateTieringInfo(data) {
      return $http({
        method: 'put',
        url: API.public('clusters/ioPreferentialTier'),
        data: data,
      }).then(function transformTieringInfo(response) {
          return response.data || {};
        }
      );
    }

    /**
     * Gets external KMS configs.
     *
     * @method   configureExternalKms
     * @param    {object}   data   Data to configure a KMS.
     * @return   {object}          Promise to resolve with the server's
     *                             raw response.
     */
    function getExternalKms() {
      return $http({
        method: 'get',
        url: API.public('kmsConfig'),
        headers: NgPassthroughOptionsService.requestHeaders,
      }).then(function getExternalKmsSuccess(response) {
          return response.data.filter(key => key.removalState !== 'kMarkedForRemoval');
        }
      );
    }

    /**
     * get NTP servers
     *
     * @return    {object}    promise to resolve API query
     */
    function getNTPservers() {
      return $http({
        method: 'get',
        url: API.private('ntpServers'),
      });
    }

    /**
     * update NTP servers
     *
     * @param     {object}    data    object to be passed to endpoint
     *
     * @return    {object}            promise to resolve API query
     */
    function updateNTPServers(data) {
      return $http({
        method: 'put',
        url: API.private('ntpServers'),
        data: data
      });
    }

    /**
     * get Apollo Throttling Schedule
     *
     * @method   getApolloThrottlingInfo
     * @return    {object}    promise to resolve API query
     */
    function getApolloThrottlingInfo() {
      return $http({
        method: 'get',
        url: API.public('cluster/backgroundActivitySchedule'),
      });
    }

    /**
     * update Apollo Throttling Schedule
     *
     * @param     {object}    data    object to be passed to endpoint
     *
     * @return    {object}            promise to resolve API query
     */
    function updateApolloThrottlingInfo(data) {
      return $http({
        method: 'put',
        url: API.public('cluster/backgroundActivitySchedule'),
        data: data
      });
    }

    /**
     * get HardwareInfo from nexus, for use in cluster setup only
     * @return {object}        A promise to resolve
     */
    function getHardwareInfo() {
      // Return from local cache if hardware info is already fetched.
      if (clusterService.hardwareInfo) {
        return Promise.resolve(clusterService.hardwareInfo);
      }
      return $http({
        method: 'get',
        url: API.private('nexus/node/hardware_info'),
      }).then(function getHardwareInfoSuccess(response) {
        clusterService.hardwareInfo = response.data;

        // Transforms the Hardware info specifically for the setup flow
        // because the clusterInfo is not available.
        $rootScope.isVirtualRobo =
          FORMATS.virtualRobo.test(response.data.productModel);

        $rootScope.isVirtualEditionCluster =
          FORMATS.virtualEditionCluster.test(response.data.chassisModel);

        $rootScope.isPhysicalRobo =
          FORMATS.physicalRobo.test(response.data.productModelType);

        $rootScope.isPhysicalCluster = !($rootScope.isVirtualRobo ||
          $rootScope.isVirtualEditionCluster ||  $rootScope.isPhysicalRobo);

        return response.data;
      });
    }

    /**
     * allows user to upload a package to the server (via url)
     * @param  {Object} params object based on API documentation
     * @return {object}        A promise to resolve
     */
    function uploadPackage(params) {
      return $http({
        method: 'post',
        url: API.private('nexus/node/upload_package'),
        data: params
      });
    }

    /**
     * allows user to delete an upgrade packages from a cluster
     *
     * @param  {Object} params object based on API documentation
     *
     * @return {object}        A promise to resolve API query
     */
    function deletePackages(params) {
      return $http({
        method: 'post',
        url: API.private('nexus/cluster/delete_packages'),
        data: params
      });
    }

    /**
     * list packages
     *
     * @return    {object}              a promise to resolve API query
     */
    function listPackages() {
      const url = featureFlagsService.enabled('legacyPatchWithUpgrade')
        ? 'list_packages?includePatch=true'
        : 'list_packages';
      return $http({
        method: 'get',
        url: API.private(`nexus/cluster/${url}`),
      });
    }

    /**
     * Remove the patch in the cluster.
     *
     * @method removePatch
     * @returns {string}
     */
    function removePatch(packagename){
      return $http({
        method: 'post',
        url: '/v2/patch-management/patch-remove',
        data: {
          patchName: packagename
        }
      })
    }

    /**
     * upgrade cluster
     *
     * @param     {object}    data
     *
     * @return    {object}            a promise to resolve API query
     */
    function upgradeCluster(data) {
      return $http({
        method: 'post',
        url: API.private('nexus/cluster/upgrade'),
        data: data
      });
    }

    /**
     * destroy cluster
     *
     * @param     {number}    id     id of the cluster to destroy
     *
     * @return    {object}          a promise to resolve API query
     */
    function destroyCluster(id) {
      return $http({
        method: 'post',
        url: API.private('nexus/node/remove_from_cluster'),
        data: {
          destroyNodeReq: {
            clusterId: id
          }
        }
      });
    }

    /**
     * get the whitelist from the API,
     *
     * @return {object} promise to resolve the API query
     */
    function getWhitelist() {
      var deferred = $q.defer();
      $http({
        method: 'get',
        url: API.public('externalClientSubnets'),
      }).then(
        function getWhitelistSuccess(response) {
          deferred.resolve(response.data.clientSubnets || []);
        },
        function getWhitelistFail(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    }

    /**
     * get vlans from the API
     *
     * @return {object} promise to resolve the API query
     */
    function getVlans() {
      return $http({
        method: 'get',
        url: API.public('vlans'),
      }).then(function vlanSuccess(res) {
        return clusterService.clusterInfo._vlans = res.data || [];
      }, evalAJAX.errorMessage);
    }

    /**
     * Updates the External Client Subnets via API call
     *
     * @method   updateSubnetWhitelist
     * @param    {Array}    data    array of whitelisted Subnets
     * @return   {object}           promise to resolve api call
     */
    function updateSubnetWhitelist(data) {
      return $http({
        method: 'put',
        url: API.public('externalClientSubnets'),
        data: {
          clientSubnets: data
        }
      }).then(function updateSubnetWhitelistSuccess(response) {
        return response.data.clientSubnets || [];
      });
    }

    /**
     * checkForNewPackages method to check nexus if there are any package upgrades available.
     *
     * @method     checkForNewPackages
     * @param      {Object}  opts  $http config object
     * @return     {object}       Promise of the request
     */
    function checkForNewPackages(opts) {
      var deferred = $q.defer();
      opts = angular.extend({
        method: 'get',
        url: API.private('nexus/cluster/has_new_packages'),
      }, opts);

      function upgradeCheckSuccess(response) {
        var data = response.data || {};
        deferred.resolve(data);
      }

      function upgradeCheckFailure(response) {
        deferred.reject(response);
      }

      $http(opts)
      .then(upgradeCheckSuccess, upgradeCheckFailure);

      return deferred.promise;
    }

    /**
     * Tells nexus we've acknowledged that there are upgrades available.
     * The user has seen the notice.
     *
     * @method     acknowledgeUpgrades
     * @param      {Object}  opts  $http config object
     * @return     {object}       Promise of the response
     */
    function acknowledgeUpgrades(opts) {
      var deferred = $q.defer();
      opts = angular.extend({
        method: 'get',
        url: API.private('nexus/cluster/ack_new_packages'),
      }, opts);

      function ackUpgradeSuccess(response) {
        deferred.resolve(response.data || {});
      }

      function ackUpgradeFailure(response) {
        deferred.reject(response);
      }

      $http(opts)
      .then(ackUpgradeSuccess, ackUpgradeFailure);

      return deferred.promise;
    }

    /**
     * This function parses a server response for list_packages and
     * generates a UI-friendly flat list of packages.
     *
     * @method  parsePackageResponse
     * @param   {Object} data  Response.data object from Nexus
     * @returns {Array}        Array of available packages and their statuses
     */
    function parsePackageResponse(data) {
      var out = [];
      var existing;
      var fauxpackage;
      var installed;
      // Bail early if data is falsey
      if (!data) {
        return out;
      }

      /**
       * Submethod for determining the package's status based on nonsense
       * rules
       *
       * @method     detectStatus
       * @param      {Integer}            index   Index of the data.package
       *                                          we're evaluating
       * @return     {(boolean|string)}           String (or false) of the
       *                                          package's status
       */
      function detectStatus(index) {
        switch (true) {
          // This condition won't ever exist because it's a separate
          // object and not part of the package+nodeIds mapping. But
          // leaving it here for informational purposes. The detection
          // is handled lower in parsePackageResponse
          // case (angular.isObject(data.uploadStatus)):
          //     return 'downloading';
          case (clusterService.clusterInfo.targetSoftwareVersion === data.packages[index] && ['kUpgrade', 'Upgrade'].includes(clusterService.clusterInfo.currentOperation)):
            return 'upgrading';

          // PatchVersion is returned from the cluster API as a substring of the patch version, like p20240605.
          case (clusterService.clusterInfo.clusterSoftwareVersion === data.packages[index]) || data.packages[index].includes(clusterService.clusterInfo?.patchVersion):
            return 'current';

          // This index has a corresponding nodeIds list, and it's NOT
          // empty. This means the package HAS been uploaded to
          // the cluster and installed.
          // Is this the same as 'current'??
          case (data.nodeIds[index] && data.nodeIds[index].length > 0):
          return 'installed';

          // This index has a corresponding nodeIds list, and it's
          // empty. This means the package has NOT been uploaded
          // yet.
          case (data.nodeIds[index] && data.nodeIds[index].length < 1):
          return 'available';
        }
        // This is the default if no detection matches
        return false;
      }

      /**
       * Extracts the package version from the name string
       *
       * @method     extractVersionNumber
       * @param      {string}            packageName  Name of the package
       * @return     {(boolean|string)}               Version string (or
       *                                              false)
       */
      function extractVersionNumber(packageName) {
        // RegEx to capture the anything before the first _ (which is the
        // package version)
        var rxPkgVersion = /([^_]+)_.*/i;
        // Bail early if the name is falsey
        if (!packageName) {
          return false;
        }
        return packageName.replace(rxPkgVersion, "$1") || false;
      }

      /**
       * Is target version upgradable.
       *
       * @method     isUpgradableVersion
       * @param      {string}            currentVersion  Current version (Ex: 6.4.1c)
       * @param      {number}            index  Index of the package in packages
       *                                        list.
       * @return     {boolean}           True if target version is upgradable.
       */
      function isUpgradableVersion(currentVersion, index) {
        var targetVersion = extractVersionNumber(data.packages[index]);
        var versionRegex = /^\d+(\.\d+){0,2}$/;
        if (!currentVersion || !targetVersion ||
          currentVersion.length === 0 || targetVersion.length === 0) {
          return false;
        }

        // Return true if versions match.
        if(currentVersion === targetVersion) {
          return true;
        }

        // Test versions are of form {{major.minor.patch}}
        if (versionRegex.test(currentVersion) && versionRegex.test(targetVersion)) {
          var currentVersionParts = currentVersion.split('.');
          var targetVersionParts = targetVersion.split('.');

          // Add '0' if version has less than 3 parts.
          while(currentVersionParts.length < 3) {
            currentVersionParts.push("0");
          }
          while (targetVersionParts.length < 3) {
            targetVersionParts.push("0");
          }
          for (var i=0; i<3; i++) {
            if (currentVersionParts[i] === targetVersionParts[i]) {
              continue;
            }
            return currentVersionParts[i] < targetVersionParts[i];
          }

          // Return true by default.
          return true;
        } else {
          // Do regular string comparision if regex test doesn't pass.
          return currentVersion < targetVersion;
        }

      }
      if (data.packages && data.packages.length) {
        // Map the funky data object to a UI-friendly flat list
        out = data.packages.map(function packageMap(pkg, pp) {
          var relDate = (data.releaseDates && data.releaseDates[pp] !== '') ? new Date(data.releaseDates[pp]) : '';
          return {
            progressEndpoint: getUpgradeTaskPath(pkg),
            releaseDate: +relDate || undefined,
            version: extractVersionNumber(pkg),
            isUpgradableVersion: isUpgradableVersion(extractVersionNumber(
              clusterService.clusterInfo.clusterSoftwareVersion), pp),
            status: detectStatus(pp),
            packageType: data?.packageTypes && data?.packageTypes[pp],
            progress: false,
            downTime: (data.isDowntimeRequiredList) ? data.isDowntimeRequiredList[pp] : false,
            name: pkg
          };
        });
      }

      // If the `data.uploadStatus` key is an object, lets translate that
      // thing into another package entry for interfacing with cPulse
      if (angular.isObject(data.uploadStatus)) {
        existing = out.filter(function(pkg) {
          return pkg.name === data.uploadStatus.swVersion;
        });

        if (existing[0]) {
          // We're showing uploads for a known package
          angular.extend(existing[0], {
            progress: +parseFloat(data.uploadStatus.percentage || 0).toFixed(2),
            status: 'uploading'
          });
          if (data.uploadStatus.errMessage) {
            existing[0].error = data.uploadStatus.errMessage;
          }
        } else {
          // This package that's uploading is not known to our list
          // from the server. Add it in.
          fauxpackage = {
            version: extractVersionNumber(data.uploadStatus.swVersion),
            progress: +parseFloat(data.uploadStatus.percentage || 0).toFixed(2),
            releaseDate: false,
            status: 'uploading',
            name: data.uploadStatus.swVersion || false
          };
          if (data.uploadStatus.errMessage) {
            fauxpackage.error = data.uploadStatus.errMessage;
          }
          out.unshift(fauxpackage);
        }
      }

      // Return the list sorted by version (desc), then by releaseDate (desc)
      //
      // NOTE: Unfortunately this sorts the hex hash opposite as intended, which
      // means multiple releases on the same day are sorted in the opposite
      // direction as the list per day. Fortunately, this is not an issue for
      // the customer since they will never see multiple releases tagged on the
      // same day. But it would be nice to solve this for internal use. One
      // possibility is to ask backend to assemble the list in the expected
      // order.
      return $filter('orderBy')(out, ['-version', '-releaseDate']);
    }

    /**
     * Gets the URL for getting pulse data for an upgrade to a specific
     * version.
     *
     * @method     getUpgradeTaskPath
     * @param      {string}  packageName  The package name (version).
     * @return     {string}  The upgrade task path.
     */
    function getUpgradeTaskPath(packageName) {
      if (!packageName || !clusterService.clusterInfo.currentOpScheduledTimeSecs) {
        return;
      }

      var startTimeSecs = clusterService.clusterInfo.currentOpScheduledTimeSecs;
      var params = {
        taskPathVec: [taskPathVecPrefix, packageName, startTimeSecs].join('_'),
        includeFinishedTasks: true,
        excludeSubTasks: false
      };
      return ['progressMonitors', $httpParamSerializer(params)].join('?');
    }

    /**
     * Transforms an upgrade Pulse response into a hash map of upgrade tasks
     * by Node.
     *
     * ex.
     *
     * { 'nodeId': [ {subTasks}, ... ] }
     *
     * @method     getPulseTasks
     * @param      {object}  pulse   The Pulse response.
     * @param      {object}  nodesHash object with node id as key
     * @return     {object}  Hash of subTasks by Node id.
     */
    function getPulseTasks(pulse, nodesHash) {
      var tasks = {};
      if (pulse && Array.isArray(pulse.subTaskVec)) {
        pulse.subTaskVec.forEach(function eachSubTaskFn(subTask) {
          var node = subTask.taskPath.replace(/^node/, '');
          tasks[node] = [];
          tasks[node].status = subTask.progress && subTask.progress.status;
          tasks[node].hasError = (2 === tasks[node].status.type);
          tasks[node].percentFinished = subTask.progress && subTask.progress.percentFinished;
          tasks[node].errorMessage = tasks[node].hasError ?
            tasks[node].status.errorMsg : undefined;
          if (nodesHash) {
            tasks[node].nodeIp = nodesHash[node].ip;
          }

          // Look at attribute vec, If present, check if upgradeTicketHolderNodeId
          // key is present.
          if (subTask.progress && find(subTask.progress.attributeVec,
              {'key': 'upgradeTicketHolderNodeId'})) {
            tasks[node].currentlyUpgrading = true;
          }

          // Sort sub-tasks events by timestampSecs field value
          if (subTask.progress && Array.isArray(subTask.progress.eventVec)) {
            subTask.progress.eventVec.sort(function compareTimeStamp(event1, event2) {
              return event2.timestampSecs - event1.timestampSecs;
            });
            [].push.apply(tasks[node], subTask.progress.eventVec);

            // If progress percentage is 100%, Look if node upgrade is done
            // by looking for substring match in eventVec messages.
            if (tasks[node].percentFinished === 100){
              subTask.progress.eventVec.forEach(function eachEventMessage(event) {
                if (event.eventMsg &&
                  event.eventMsg.toLowerCase().includes('finished upgrading node')) {
                  tasks[node].upgradeFinished = true;
                }
              });
            }
          }


        });
      }
      return tasks;
    }

    /**
     * Updates the hash of known Clusters and returns it
     *
     * @method     updateClusterHash
     * @param      {Array|Object}  freshClusters  One or a list of cluster
     *                                            info objects
     * @return     {Object}        A copy of the updated hash
     */
    function updateClusterHash(freshClusters) {
      if (!freshClusters) {
        return;
      }
      freshClusters = [].concat(freshClusters);
      freshClusters.forEach(function eachClusterUpdaterFn(cluster) {
        knownClusters[cluster.id] = cluster;
      });
      return angular.copy(knownClusters);
    }

    /**
     * submits EULA/license key for acceptance
     *
     * @param      {object}   eulaConfig  The eula configuration
     * @return     {promise}  to resolve API request
     */
    function acceptLicense(eulaConfig) {

      var deferred = $q.defer();

      $http({
        method: 'post',
        url: API.private('licenseAgreement'),
        data: eulaConfig
      }).then(
        function acceptSuccess(response) {
          // on success, cluster info will be changed.
          // fire getClusterInfo() so ClusterService.clusterInfo
          // will be updated with the latest details.
          getClusterInfo().finally(
            function acceptLicenseFinally() {
              deferred.resolve(clusterService.clusterInfo);
            }
          );
        },
        deferred.reject
      );

      return deferred.promise;
    }

    /**
     * Get audit logs
     *
     * @method     getAuditLogs
     * @param      {Object}   params  to constrain query
     * @return     {Promise}  to resolve the request, resolves with server
     *                        response
     */
    function getAuditLogs(params) {
      // get audit logs API support tenantId filter not tenantIds hence passing
      // a special UI only param below so that interceptor like
      // outputFormatInterceptor can make the necessary switch eg.
      // getAuditLogs() is used to download CSV audit report by service provider
      // during impersonation where we need to send the tenantId either in the
      // request headers or in the query params.
      params = assign({ _useSingularTenantFilter: true }, params);

      return $http({
        method: 'get',
        url: API.public('auditLogs/cluster'),
        params: params,
      }).then(function gotAuditLogs(response) {
        return transformAuditLogs(response.data || {});
      });
    }

    /**
     * transform audit logs and resolve user name and impersonation logs for
     * both tenant user and local user.
     *
     * @method   transformAuditLogs
     * @param    {Object}   audit   The audit logs to transform
     * @return   {Object}   transformed audit logs
     */
    function transformAuditLogs(audit) {
      // tenant id of logged in tenant user or impersonated tenant.
      var tenantId = $rootScope.getUserTenantId();

      audit.clusterAuditLogs = (audit.clusterAuditLogs || []).map(
        function transformLog(log) {
          // get user info who caused this log
          assign(log, getLogUserInfo(log, tenantId ? log.tenant : null));

          // local user (service provider) will get impersonated to tenant log.
          // tenant user will get impersonated by tenant log that can be service
          // provider or and organization above him.
          if (log.impersonation) {
            if (log.tenant.tenantId === tenantId) {
              log._impersonatedBy = log.originalTenant;
              assign(log, getLogUserInfo(log, log.originalTenant));

            } else {
              log._impersonatedTo = log.tenant;
            }
          }

          return log;
        }
      );

      return audit;
    }

    /**
     * Returns the user info for provided log & tenant details
     * local user         admin
     *                    local
     *
     * AD user            AD user name
     *                    AD domain name
     *
     * tenant user        admin@organizationName
     *
     * tenant AD user     admin@organizationName
     *                    AD domain name
     * NOTE: AD is not supported for multi-tenancy MVP
     *
     * @method   getLogUserInfo
     * @param    {Object}   log      The log
     * @param    {Object}   tenant   The tenant
     * @return   {Object}   The user info for provided log & tenant
     */
    function getLogUserInfo(log, tenant) {
      var tenantName;
      var domainName = log.domain;
      var isAssignmentLog = ['Assign', 'Unassign'].includes(log.action);

      if (tenant && tenant.tenantId && !isAssignmentLog) {
        // In multi-tenancy MVP we are not allowing active-directory for
        // tenant user
        domainName = undefined;
        tenantName = $filter('tenantId')(tenant.tenantId);
      }

      return {
        _userName: log.userName,
        _tenantName: tenantName,
        _domainName: domainName,
      };
    }

    /**
     * Temp service which will be replaced by a real API which returns keys and
     * translated labels.
     *
     * @method     getAuditFilters
     * @return     {object}  The audit filters object
     */
    function getAuditFilters() {
      for (var filterKey in auditFilterOptions) {
        for (var optionKey in auditFilterOptions[filterKey]) {
          // Translation ideally should happen once, in the parent scope, but
          // the translate service is not ready at that point.
          auditFilterOptions[filterKey][optionKey] =
            $translate.instant(auditFilterOptions[filterKey][optionKey]);
        }
      }

      return $q.when(auditFilterOptions);
    }

    /**
     * Used to clear cached cluster info (basic and full) on logout. The
     * $rootScope value becomes incorrectly populated (though expected) via
     * AltClusterSelector.
     *
     * @method   _clearClusterInfo
     */
    function _clearClusterInfo() {
      $rootScope.clusterInfo = clusterService.clusterInfo = undefined;
      NgClusterService.clearBasicClusterInfo();
      $rootScope.basicClusterInfo = undefined;
    }

    /**
     * Update the EULA configuration.
     *
     * @param   {object}   eulaConfig   The EULA configuration to be updated.
     * @return  {object}   A promise with success or failure of the EULA update
     */
    function updateEula(eulaConfig) {
      return $http({
        method: 'put',
        url: API.private('eulaConfig'),
        data: eulaConfig
      });
    }

    /**
     * Sends the License file to the Nexus for cluster
     *
     * @param file  file content of the License file
     * @param xManual header value
     * @returns observable for API response for upload License file
     */
    function uploadLicenseFile(file, xManual = true) {
      const headers = {
        'Content-Type': 'application/json;charset=utf-8'
      };
      if (xManual) {
        headers['X-Manual'] = 'true';
      }
      return $http({
        method: 'post',
        url: API.private('nexus/license/upload'),
        data: file,
        headers: headers
      });
    }

    /**
     * Return whether cluster create is in progress.
     *
     * @return {object} promise that resolves with a boolean indicating if the cluster creation is in progress.
     */
    function isClusterCreateInProgress() {
      var ctx = $injector.get('NgIrisContextService')?.irisContext;
      const heliosSetupPromise = isOneHeliosAppliance(ctx) ? isOneHeliosSetupInProgress() : $q.resolve(false);

      const clusterBringupStatusPromise = $http.get(API.private('nexus/cluster/bringup_status'));

      return $q.all([heliosSetupPromise, clusterBringupStatusPromise]).then(function (results) {
        const [heliosInProgress, clusterInProgress] = results;
        return heliosInProgress || !!clusterInProgress.data.inProgress;
      });
    }

    /**
     * Determines if the setup Helios and Kubernetes is still in progress.
     *
     * @returns An observable that emits true if the setup is still in progress, otherwise false.
     */
    function isOneHeliosSetupInProgress() {
      return $q.all([
        $http.get('/v2/helios/services/install/logs'),
        $http.get('/v2/kubernetes/status')
      ]).then(function(responses) {
        const heliosLogs = responses[0].data;
        const kubernetesStatus = responses[1].data;

        const isHeliosInstallationIncomplete = heliosLogs.heliosInstallStatus !== 'Success';
        const isKubernetesSetupInProgress =
          (
            kubernetesStatus.overallK8SState === 'Pending' ||
            kubernetesStatus.overallK8SState === 'Initializing' ||
            kubernetesStatus.overallK8SState === 'Unknown'
          ) || (
            !!kubernetesStatus.overallK8SHealthStatus &&
            kubernetesStatus.overallK8SHealthStatus !== 'Healthy'
          );

        return isHeliosInstallationIncomplete || isKubernetesSetupInProgress;
      }).catch(function() {
        return true;
      });
    }

    $rootScope.$on('logout.initiated', _clearClusterInfo);

    return clusterService;
  }

})(angular);
