/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityApiConfiguration as __Configuration } from '../cohesity-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ProtectionPolicy } from '../models/protection-policy';
import { ProtectionPolicyRequest } from '../models/protection-policy-request';
import { ProtectionPolicySummary } from '../models/protection-policy-summary';
@Injectable({
  providedIn: 'root',
})
class ProtectionPoliciesService extends __BaseService {
  static readonly GetProtectionPoliciesPath = '/public/protectionPolicies';
  static readonly CreateProtectionPolicyPath = '/public/protectionPolicies';
  static readonly GetProtectionPolicyByIdPath = '/public/protectionPolicies/{id}';
  static readonly UpdateProtectionPolicyPath = '/public/protectionPolicies/{id}';
  static readonly DeleteProtectionPolicyPath = '/public/protectionPolicies/{id}';
  static readonly GetProtectionPolicySummaryPath = '/public/protectionPolicySummary';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * List Protection Policies filtered by some parameters.
   *
   * If no parameters are specified, all Protection Policies currently on the
   * Cohesity Cluster are returned.
   * Specifying parameters filters the results that are returned.
   * @param params The `ProtectionPoliciesService.GetProtectionPoliciesParams` containing the following parameters:
   *
   * - `vaultIds`: Filter by a list of Vault ids. Policies archiving to any of the
   *   specified vaults will be returned.
   *
   * - `types`: Specifies the type of the protection policy.
   *   'kRegular' means a regular Protection Policy.
   *   'kRPO' means an RPO Protection Policy.
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which objects are to be
   *   returned.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `origin`: Specifies the origin of the protection policy.
   *   'kHelios' means a global policy which was created on Helios.
   *   'kLocal' means a local policy which was created on the cluster.
   *
   * - `names`: Filter by a list of Protection Policy names.
   *
   * - `ids`: Filter by a list of Protection Policy ids.
   *
   * - `environments`: Filter by Environment type such as 'kVMware', 'kView', etc.
   *   Only Policies protecting the specified environment type are returned.
   *   NOTE: 'kPuppeteer' refers to Cohesity's Remote Adapter.
   *
   * - `allUnderHierarchy`: AllUnderHierarchy specifies if objects of all the tenants under the
   *   hierarchy of the logged in user's organization should be returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetProtectionPoliciesResponse(params: ProtectionPoliciesService.GetProtectionPoliciesParams): __Observable<__StrictHttpResponse<Array<ProtectionPolicy>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.vaultIds || []).forEach(val => {if (val != null) __params = __params.append('vaultIds', val.toString())});
    (params.types || []).forEach(val => {if (val != null) __params = __params.append('types', val.toString())});
    (params.tenantIds || []).forEach(val => {if (val != null) __params = __params.append('tenantIds', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.origin != null) __params = __params.set('origin', params.origin.toString());
    (params.names || []).forEach(val => {if (val != null) __params = __params.append('names', val.toString())});
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    (params.environments || []).forEach(val => {if (val != null) __params = __params.append('environments', val.toString())});
    if (params.allUnderHierarchy != null) __params = __params.set('allUnderHierarchy', params.allUnderHierarchy.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/protectionPolicies`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ProtectionPolicy>>;
      })
    );
  }
  /**
   * List Protection Policies filtered by some parameters.
   *
   * If no parameters are specified, all Protection Policies currently on the
   * Cohesity Cluster are returned.
   * Specifying parameters filters the results that are returned.
   * @param params The `ProtectionPoliciesService.GetProtectionPoliciesParams` containing the following parameters:
   *
   * - `vaultIds`: Filter by a list of Vault ids. Policies archiving to any of the
   *   specified vaults will be returned.
   *
   * - `types`: Specifies the type of the protection policy.
   *   'kRegular' means a regular Protection Policy.
   *   'kRPO' means an RPO Protection Policy.
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which objects are to be
   *   returned.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `origin`: Specifies the origin of the protection policy.
   *   'kHelios' means a global policy which was created on Helios.
   *   'kLocal' means a local policy which was created on the cluster.
   *
   * - `names`: Filter by a list of Protection Policy names.
   *
   * - `ids`: Filter by a list of Protection Policy ids.
   *
   * - `environments`: Filter by Environment type such as 'kVMware', 'kView', etc.
   *   Only Policies protecting the specified environment type are returned.
   *   NOTE: 'kPuppeteer' refers to Cohesity's Remote Adapter.
   *
   * - `allUnderHierarchy`: AllUnderHierarchy specifies if objects of all the tenants under the
   *   hierarchy of the logged in user's organization should be returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetProtectionPolicies(params: ProtectionPoliciesService.GetProtectionPoliciesParams): __Observable<Array<ProtectionPolicy>> {
    return this.GetProtectionPoliciesResponse(params).pipe(
      __map(_r => _r.body as Array<ProtectionPolicy>)
    );
  }

  /**
   * Create a Protection Policy.
   *
   * Returns the created Protection Policy.
   * @param params The `ProtectionPoliciesService.CreateProtectionPolicyParams` containing the following parameters:
   *
   * - `body`: Request to create a Protection Policy.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateProtectionPolicyResponse(params: ProtectionPoliciesService.CreateProtectionPolicyParams): __Observable<__StrictHttpResponse<ProtectionPolicy>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/protectionPolicies`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ProtectionPolicy>;
      })
    );
  }
  /**
   * Create a Protection Policy.
   *
   * Returns the created Protection Policy.
   * @param params The `ProtectionPoliciesService.CreateProtectionPolicyParams` containing the following parameters:
   *
   * - `body`: Request to create a Protection Policy.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateProtectionPolicy(params: ProtectionPoliciesService.CreateProtectionPolicyParams): __Observable<ProtectionPolicy> {
    return this.CreateProtectionPolicyResponse(params).pipe(
      __map(_r => _r.body as ProtectionPolicy)
    );
  }

  /**
   * List details about a single Protection Policy.
   *
   * Returns the Protection Policy corresponding to the specified Policy Id.
   * @param params The `ProtectionPoliciesService.GetProtectionPolicyByIdParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Protection Policy to return.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetProtectionPolicyByIdResponse(params: ProtectionPoliciesService.GetProtectionPolicyByIdParams): __Observable<__StrictHttpResponse<ProtectionPolicy>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/protectionPolicies/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ProtectionPolicy>;
      })
    );
  }
  /**
   * List details about a single Protection Policy.
   *
   * Returns the Protection Policy corresponding to the specified Policy Id.
   * @param params The `ProtectionPoliciesService.GetProtectionPolicyByIdParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Protection Policy to return.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetProtectionPolicyById(params: ProtectionPoliciesService.GetProtectionPolicyByIdParams): __Observable<ProtectionPolicy> {
    return this.GetProtectionPolicyByIdResponse(params).pipe(
      __map(_r => _r.body as ProtectionPolicy)
    );
  }

  /**
   * Update a Protection Policy.
   *
   * Returns the updated Protection Policy.
   * @param params The `ProtectionPoliciesService.UpdateProtectionPolicyParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Protection Policy to return.
   *
   * - `body`: Request to update a Protection Policy.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateProtectionPolicyResponse(params: ProtectionPoliciesService.UpdateProtectionPolicyParams): __Observable<__StrictHttpResponse<ProtectionPolicy>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/public/protectionPolicies/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ProtectionPolicy>;
      })
    );
  }
  /**
   * Update a Protection Policy.
   *
   * Returns the updated Protection Policy.
   * @param params The `ProtectionPoliciesService.UpdateProtectionPolicyParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Protection Policy to return.
   *
   * - `body`: Request to update a Protection Policy.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateProtectionPolicy(params: ProtectionPoliciesService.UpdateProtectionPolicyParams): __Observable<ProtectionPolicy> {
    return this.UpdateProtectionPolicyResponse(params).pipe(
      __map(_r => _r.body as ProtectionPolicy)
    );
  }

  /**
   * Delete a Protection Policy.
   *
   * Returns Success if the Protection Policy is deleted.
   * @param params The `ProtectionPoliciesService.DeleteProtectionPolicyParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Protection Policy to return.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteProtectionPolicyResponse(params: ProtectionPoliciesService.DeleteProtectionPolicyParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/public/protectionPolicies/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete a Protection Policy.
   *
   * Returns Success if the Protection Policy is deleted.
   * @param params The `ProtectionPoliciesService.DeleteProtectionPolicyParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Protection Policy to return.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteProtectionPolicy(params: ProtectionPoliciesService.DeleteProtectionPolicyParams): __Observable<null> {
    return this.DeleteProtectionPolicyResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * List Protection Policy Summary.
   * @param params The `ProtectionPoliciesService.GetProtectionPolicySummaryParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the policy whose summary should be retrieved. If this is not
   *   set, the API will return error.
   *
   * - `startTimeUsecs`: Filter by a start time specified as a Unix epoch Timestamp
   *   (in microseconds). Only Job Runs that started after the specified
   *   time are included in the aggregated runs summary result.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `paginationCookie`: If set, i.e. there are more results to display, use this value to get
   *   the next set of results, by using this value in paginationCookie param
   *   for the next request to GetProtectionPolicySummary.
   *
   * - `pageCount`: Specifies the limit of the number of Protection Sources or Protection Jobs
   *   to be returned as a part of the Protection Policy Summary.
   *
   * - `includeAggregatedRunsSummary`: Specifies whether to include summary of all Protection Runs of the
   *   Protection Source or Protection Jobs. If this is set to true, then
   *   only the Protection Runs from the provided 'startTimeUsecs' and
   *   'endTimeUsecs' are processed.
   *
   * - `includeAggregatedLastRunSummary`: Specifies whether to include summary of the last Protection Run of
   *   each Protection Source.
   *
   * - `endTimeUsecs`: Filter by a end time specified as a Unix epoch Timestamp
   *   (in microseconds). Only Job Runs that completed before the specified
   *   end time are included int he aggregated runs summary result.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetProtectionPolicySummaryResponse(params: ProtectionPoliciesService.GetProtectionPolicySummaryParams): __Observable<__StrictHttpResponse<ProtectionPolicySummary>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.id != null) __params = __params.set('id', params.id.toString());
    if (params.startTimeUsecs != null) __params = __params.set('startTimeUsecs', params.startTimeUsecs.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.paginationCookie != null) __params = __params.set('paginationCookie', params.paginationCookie.toString());
    if (params.pageCount != null) __params = __params.set('pageCount', params.pageCount.toString());
    if (params.includeAggregatedRunsSummary != null) __params = __params.set('includeAggregatedRunsSummary', params.includeAggregatedRunsSummary.toString());
    if (params.includeAggregatedLastRunSummary != null) __params = __params.set('includeAggregatedLastRunSummary', params.includeAggregatedLastRunSummary.toString());
    if (params.endTimeUsecs != null) __params = __params.set('endTimeUsecs', params.endTimeUsecs.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/protectionPolicySummary`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ProtectionPolicySummary>;
      })
    );
  }
  /**
   * List Protection Policy Summary.
   * @param params The `ProtectionPoliciesService.GetProtectionPolicySummaryParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the policy whose summary should be retrieved. If this is not
   *   set, the API will return error.
   *
   * - `startTimeUsecs`: Filter by a start time specified as a Unix epoch Timestamp
   *   (in microseconds). Only Job Runs that started after the specified
   *   time are included in the aggregated runs summary result.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `paginationCookie`: If set, i.e. there are more results to display, use this value to get
   *   the next set of results, by using this value in paginationCookie param
   *   for the next request to GetProtectionPolicySummary.
   *
   * - `pageCount`: Specifies the limit of the number of Protection Sources or Protection Jobs
   *   to be returned as a part of the Protection Policy Summary.
   *
   * - `includeAggregatedRunsSummary`: Specifies whether to include summary of all Protection Runs of the
   *   Protection Source or Protection Jobs. If this is set to true, then
   *   only the Protection Runs from the provided 'startTimeUsecs' and
   *   'endTimeUsecs' are processed.
   *
   * - `includeAggregatedLastRunSummary`: Specifies whether to include summary of the last Protection Run of
   *   each Protection Source.
   *
   * - `endTimeUsecs`: Filter by a end time specified as a Unix epoch Timestamp
   *   (in microseconds). Only Job Runs that completed before the specified
   *   end time are included int he aggregated runs summary result.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetProtectionPolicySummary(params: ProtectionPoliciesService.GetProtectionPolicySummaryParams): __Observable<ProtectionPolicySummary> {
    return this.GetProtectionPolicySummaryResponse(params).pipe(
      __map(_r => _r.body as ProtectionPolicySummary)
    );
  }
}

module ProtectionPoliciesService {

  /**
   * Parameters for GetProtectionPolicies
   */
  export interface GetProtectionPoliciesParams {

    /**
     * Filter by a list of Vault ids. Policies archiving to any of the
     * specified vaults will be returned.
     */
    vaultIds?: Array<number>;

    /**
     * Specifies the type of the protection policy.
     * 'kRegular' means a regular Protection Policy.
     * 'kRPO' means an RPO Protection Policy.
     */
    types?: Array<'kRegular' | 'kRPO'>;

    /**
     * TenantIds contains ids of the tenants for which objects are to be
     * returned.
     */
    tenantIds?: Array<string>;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Specifies the origin of the protection policy.
     * 'kHelios' means a global policy which was created on Helios.
     * 'kLocal' means a local policy which was created on the cluster.
     */
    origin?: 'kHelios' | 'kLocal';

    /**
     * Filter by a list of Protection Policy names.
     */
    names?: Array<string>;

    /**
     * Filter by a list of Protection Policy ids.
     */
    ids?: Array<string>;

    /**
     * Filter by Environment type such as 'kVMware', 'kView', etc.
     * Only Policies protecting the specified environment type are returned.
     * NOTE: 'kPuppeteer' refers to Cohesity's Remote Adapter.
     */
    environments?: Array<'kVMware' | 'kHyperV' | 'kSQL' | 'kView' | 'kPuppeteer' | 'kPhysical' | 'kPure' | 'kIbmFlashSystem' | 'kNimble' | 'kAzure' | 'kNetapp' | 'kAgent' | 'kGenericNas' | 'kAcropolis' | 'kPhysicalFiles' | 'kIsilon' | 'kGPFS' | 'kKVM' | 'kAWS' | 'kExchange' | 'kHyperVVSS' | 'kOracle' | 'kGCP' | 'kFlashBlade' | 'kAWSNative' | 'kVCD' | 'kO365' | 'kO365Outlook' | 'kHyperFlex' | 'kGCPNative' | 'kKubernetes' | 'kElastifile' | 'kSAPHANA'>;

    /**
     * AllUnderHierarchy specifies if objects of all the tenants under the
     * hierarchy of the logged in user's organization should be returned.
     */
    allUnderHierarchy?: boolean;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreateProtectionPolicy
   */
  export interface CreateProtectionPolicyParams {

    /**
     * Request to create a Protection Policy.
     */
    body: ProtectionPolicyRequest;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetProtectionPolicyById
   */
  export interface GetProtectionPolicyByIdParams {

    /**
     * Specifies a unique id of the Protection Policy to return.
     */
    id: string;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateProtectionPolicy
   */
  export interface UpdateProtectionPolicyParams {

    /**
     * Specifies a unique id of the Protection Policy to return.
     */
    id: string;

    /**
     * Request to update a Protection Policy.
     */
    body: ProtectionPolicyRequest;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DeleteProtectionPolicy
   */
  export interface DeleteProtectionPolicyParams {

    /**
     * Specifies a unique id of the Protection Policy to return.
     */
    id: string;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetProtectionPolicySummary
   */
  export interface GetProtectionPolicySummaryParams {

    /**
     * Specifies the id of the policy whose summary should be retrieved. If this is not
     * set, the API will return error.
     */
    id: string;

    /**
     * Filter by a start time specified as a Unix epoch Timestamp
     * (in microseconds). Only Job Runs that started after the specified
     * time are included in the aggregated runs summary result.
     */
    startTimeUsecs?: number;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * If set, i.e. there are more results to display, use this value to get
     * the next set of results, by using this value in paginationCookie param
     * for the next request to GetProtectionPolicySummary.
     */
    paginationCookie?: string;

    /**
     * Specifies the limit of the number of Protection Sources or Protection Jobs
     * to be returned as a part of the Protection Policy Summary.
     */
    pageCount?: number;

    /**
     * Specifies whether to include summary of all Protection Runs of the
     * Protection Source or Protection Jobs. If this is set to true, then
     * only the Protection Runs from the provided 'startTimeUsecs' and
     * 'endTimeUsecs' are processed.
     */
    includeAggregatedRunsSummary?: boolean;

    /**
     * Specifies whether to include summary of the last Protection Run of
     * each Protection Source.
     */
    includeAggregatedLastRunSummary?: boolean;

    /**
     * Filter by a end time specified as a Unix epoch Timestamp
     * (in microseconds). Only Job Runs that completed before the specified
     * end time are included int he aggregated runs summary result.
     */
    endTimeUsecs?: number;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { ProtectionPoliciesService }
