import { ProtectionSourceNode } from '@cohesity/api/v1';
import { DataTreeControl, DataTreeFilter, DataTreeFilterUtils, DataTreeTransformer } from '@cohesity/helix';
import { SourceTreeFilters, ViewFilterOption, ViewFilterType } from '@cohesity/iris-source-tree';

import { MongoDBPhysicalSourceDataNode } from './mongodb-physical-data-node';

/**
 * Provide view filters for MongoDB Physical sources. This includes options to filter for
 * clusters.
 */
export class MongoDBPhysicalViewFilters<T extends MongoDBPhysicalSourceDataNode> {

  /**
   * These are the default view filters.
   */
  defaultViewFilters: ViewFilterOption[];

  /**
   * This will be added/removed conditionally to viewFilters
   */
  tagFilter: ViewFilterOption;

  /**
   * Flat view filter which would added/removed conditionally to viewFilters
   */
  flatViewFilter: ViewFilterOption;

  /**
   * Specifies the default filter view applicable to all workloads within M365.
   */
  defaultViewFilter: ViewFilterOption;

  constructor(
    private filters: SourceTreeFilters<T>,
    private treeControl: DataTreeControl<T>,
    private dataTransformer: DataTreeTransformer<ProtectionSourceNode>,
  ) {
    this.defaultViewFilter = {
      id: 'default',
      tooltip: 'sourceTreePub.tooltips.defaultView',
      filter: this.filterdefaultView,
      icon: 'helix:hierarchy-physical',
    };

    this.flatViewFilter = {
      id: ViewFilterType.Flat,
      tooltip: 'sourceTreePub.tooltips.flatClusterView',
      filter: this.filterMongodbClusters,
      icon: 'helix:hierarchy-flat',
      isFlatView: true,
    };
    this.defaultViewFilters = [
      this.defaultViewFilter,
      this.flatViewFilter,
    ].filter(Boolean);

    this.filters.setViewFilters(this.defaultViewFilters);
  }

  /**
   * Filter callback to show a flat list of vms from a vcenter.
   */
  filterMongodbClusters: DataTreeFilter<any> = (nodes: MongoDBPhysicalSourceDataNode[]) => {
    const seenNodes = new Set<number | string>();
    return nodes
      .filter(node => {
        const matched = node.envSource.type === 'kCluster';
        if (!matched || seenNodes.has(node.id)) {
          return false;
        }
        seenNodes.add(node.id);
        return true;
      })
      .map(node => this.dataTransformer.transformData(node.data, 0));
  };

  /**
   * Filter callback function to show NFS and SMB objects
   */
  filterdefaultView: DataTreeFilter<any> = (nodes: any[]) =>
    // Filter the group entities and leaf nodes which support NFS
    DataTreeFilterUtils.searchFilter(
      nodes,
      this.treeControl,
      () => true,
      true,
      true
    );

  /**
   * Remove Flat view filter from list of filters.
   */
  removeFlatViewFilter() {
    this.defaultViewFilters = this.defaultViewFilters.filter(filter => !filter.isFlatView);

    this.filters.setViewFilters(this.defaultViewFilters);
  }

  /**
   * Add Flat view filter from list of filters.
   */
  addFlatViewFilter() {
    if (!this.defaultViewFilters.find(filter => filter.isFlatView)) {
      this.defaultViewFilters.push(this.flatViewFilter);
    }
    this.filters.setViewFilters(this.defaultViewFilters);
  }
}
