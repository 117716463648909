// Module: Discover Nodes

;(function(angular, undefined) {
  'use strict';

  angular.module('C.addNodes', [])
    .config(addNodesConfigFn)
    .controller('addNodesController', AddNodesControllerFn);

  function addNodesConfigFn($stateProvider, $urlRouterProvider) {
    var modifyAccess = 'CLUSTER_MODIFY';

    $stateProvider
      .state('add-nodes', {
        url: '^/platform/nodes/add',
        help: 'platform_nodes_add',
        canAccess: modifyAccess,
        parentState: 'cluster',
        views: {
          '': {
            templateUrl: 'app/views/page-layouts/ls.html'
          },
          'col-l@add-nodes': {
            templateUrl: 'app/platform/nodes/add/add.html',
            controller: 'addNodesController'
          },
          'nodes-content@add-nodes': {
            templateUrl: 'app/platform/nodes/add/discover/discover.html',
            controller: 'addNodesDiscoverController'
          }
        }
      })
      .state('add-nodes.discover', {
        url: '^/platform/nodes/add/discover',
        help: 'platform_nodes_add',
        title: 'Discover Nodes',
        canAccess: modifyAccess,
        parentState: 'add-nodes',
        views: {
          'nodes-content@add-nodes': {
            templateUrl: 'app/platform/nodes/add/discover/discover.html',
            controller: 'addNodesDiscoverController'
          }
        }
      })
      .state('add-nodes.node-setup', {
        url: '^/platform/nodes/add/setup',
        help: 'platform_nodes_add_setup',
        title: 'Set Up Nodes',
        canAccess: modifyAccess,
        parentState: 'add-nodes',
        views: {
          'nodes-content@add-nodes': {
            templateUrl: 'app/admin/cluster/setup/nodes.html',
            controller: 'clusterSetupNodesController'
          }
        }
      })
      .state('add-nodes.vips', {
        url: '^/platform/nodes/add/vips',
        help: 'platform_nodes_add_vips',
        title: 'Add Vips',
        canAccess: modifyAccess,
        parentState: 'add-nodes',
        views: {
          'nodes-content@add-nodes': {
            templateUrl: 'app/platform/nodes/add/vips/vips.html',
            controller: 'addVipsController'
          }
        }
      });
  }

  function AddNodesControllerFn($rootScope, $scope, $state, $q, $transitions,
    NodeService, evalAJAX, cMessage, PartitionService, ClusterService, _) {

    var _cleanupTransition;
    var promiseArray = [];

    /**
     * initialization/activation function
     */
    function activate() {
      getClusterInfo();
      getPartitions();
      getNexusClusterInfo();
    }

    $scope.text = $rootScope.text.platformNodesAdd;
    $scope.errorText = $rootScope.text.platformNodesAdd.errorText;
    $scope.isExpanding = false;

    // Flag indicating user is not in setupFlow
    $scope.setupMode = false;

    $scope.noNodesFound = false;

    $scope.clusterFound = $rootScope.clusterInfo.isNodeInCluster;
    $scope.clusterSoftwareVersion = null;

    $scope.partitions = null;
    $scope.partitionListOptions = [{
      name: $scope.text.unassigned,
      value: null
    }];

    // Configuration object for expandCluster API call
    $scope.expandClusterData = {
      nodes: [],
      clusterPartitionId: -1,
      autoUpdate: true,
      ignoreSwIncompatibility: true,
      vips: []
    };

    // Configuration object for values shared across child states
    $scope.shared = {
      discoveredNodes: [],
      discoveredChassis: [],
      selectedPartitionDetails: null,
      vips: [],
      clusterSWVersion: null
    };

    // Step Configuration for c-stepper
    $scope.addNodeSteps = [{
      label: $scope.text.addNodeStepOne,
      active: true
    }, {
      label: $scope.text.addNodeStepTwo,
      active: false
    }, {
      label: $scope.text.addNodeStepThree,
      active: false
    }];

    // Remove the second step if it is a type of virtual edition cluster.
    if ($rootScope.clusterInfo._isVirtualEditionCluster) {
      $scope.addNodeSteps.splice(1, 1);
    }

    /**
     * Gets the nexus cluster information.
     *
     * @method     getNexusClusterInfo
     */
    function getNexusClusterInfo() {
      ClusterService.getClusterStatus().then(
        function getClusterStatusSuccess(r) {
          $scope.nexusClusterInfo = r.data;

          if (['kUpgrade', 'Upgrade'].includes($scope.nexusClusterInfo.currentOperation)) {
            $scope.shared.upgradeInProgress = true;
            cMessage.info({
              titleKey: 'updating',
              textKey: 'platformNodesAdd.updateInProgress',
              persist: true,
              timeout: 6000,
            });
            $state.go('cluster.nodes');
          }

          // Set Cluster SW Version,
          // We assumme that if cluster is not upgrading
          // all nodes will have the same version
          if (r.data.nodeStatuses.length) {
            $scope.shared.clusterSWVersion = r.data.nodeStatuses[0].softwareVersion;
          }

        },
        evalAJAX.errorMessage
      );
    }

    /**
     * Generates a promise array for multiple posts: expandCluster and
     * updatePartition (if applicable)
     *
     * @method     expandCluster
     */
    $scope.expandCluster = function expandCluster() {
      $scope.isExpanding = true;
      // rebuild promise array
      $scope.expandClusterData.vips = $scope.expandClusterData.vips.concat($scope.shared.vips);
      promiseArray = [ClusterService.expandCluster($scope.expandClusterData)];

      // Fire all AJAX calls
      $q.all(promiseArray).then(
        function promiseArraySuccess(responses) {
          cMessage.success({
            titleKey: 'requestAccepted',
            textKey: 'platformNodesAdd.addRequestSuccess',
          });
        },
        function promiseArrayError(r) {
          evalAJAX.errorMessage(r, {
            persist: true,
          });
        }
      ).finally(
        function promiseArrayFinally() {
          $scope.isExpanding = false;
          $state.go('cluster.nodes');
        }
      );

    };

    /**
     * Gets the Cluster Info so we can compare software versions of
     * discovered nodes. Get ip preference of cluster to set ip validators.
     *
     * @method     getClusterInfo
     */
    function getClusterInfo() {
      ClusterService.getClusterInfo().then(
        function getClusterInfoSuccess(r) {
          $scope.clusterSoftwareVersion = r.data.clusterSoftwareVersion;
          $scope.shared.ipFamily = r.data.ipPreference;
        }
      );
    }

    /**
     * get all available partitions on this cluster adds them to
     * $scope.partitions object and setup the paritition selection
     * list
     *
     * @method     getPartitions
     */
    function getPartitions() {
      PartitionService.getPartitions().then(
        function getPartitionsSuccess(partitions) {

          var option = {};

          $scope.partitions = partitions;

          if (!partitions.length) {
            cMessage.info({
              titleKey: 'noPartitions',
              textKey: 'platformNodesAdd.noPartitionsFound',
            });
          } else {
            // get details for the first partition returned
            // so it will be set as the selected partition
            $scope.getPartitionDetails(partitions[0].id);
            // Update expandClusterData object with Partiion ID
            $scope.expandClusterData.clusterPartitionId = partitions[0].id;

            // loop through partitions and setup partition c-select object
            $scope.partitionListOptions = partitions.map(function partitionListMapper(partition) {
              return {
                name: partition.name,
                value: partition.id
              };
            }).concat($scope.partitionListOptions);
          }
        },
        evalAJAX.errorMessage
      );
    }

    /**
     * Gets the partition details.
     *
     * @method     getPartitionDetails
     * @param      {number}  id      The partition id
     */
    $scope.getPartitionDetails = function getPartitionDetails(id) {
      PartitionService.getPartition(id).then(
        function getPartitionSuccess(r) {
          $scope.shared.selectedPartitionDetails = angular.extend({}, r.data);
        },
        evalAJAX.errorMessage
      );
    };


    /**
     * Listen for state change, assign active step accordingly
     */
    _cleanupTransition = $transitions.onSuccess({}, function evalState(trans) {

      // Reset all steps to inactive before marking the correct one as active.
      angular.forEach($scope.addNodeSteps, function parseStep(step) {
          step.active = false;
      });

      switch (trans.$to().name) {
        case 'add-nodes':
          $scope.addNodeSteps[0].active = true;
          break;
        case 'add-nodes.discover':
          $scope.addNodeSteps[0].active = true;
          break;
        case 'add-nodes.node-setup':
          $scope.addNodeSteps[1].active = true;
          break;
        case 'add-nodes.vips':
          // As the second element is removed in the case of virtual edition
          // cluster, these checks have to be made.
          if ($rootScope.clusterInfo._isVirtualEditionCluster) {
            $scope.addNodeSteps[1].active = true;
          } else {
            $scope.addNodeSteps[2].active = true;
          }
          break;
      }
    });

    // Cleans up stepper when $scope is destroyed
    $scope.$on('$destroy', _cleanupTransition);

    /**
     * Validation function for the Add button which ensures at least one node is
     * selected
     *
     * @method     minNodesSelected
     * @return     {boolean}  true if at least one node selected
     */
    $scope.minNodesSelected = function minNodesSelected() {
      var count = 0;
      angular.forEach($scope.shared.discoveredNodes, function isSelected(node) {
        count += node.selected ? 1 : 0;
      });
      return count >= 1;
    };

    activate();

  }

})(angular);
