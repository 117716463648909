import { ChangeDetectionStrategy, Component, forwardRef } from '@angular/core';
import { FormControl, ValidatorFn, Validators } from '@angular/forms';
import {
  DcScan,
  DcScanId,
  DcScanMethod,
  DcScanMode,
  MonthSchedule,
  NumWeekInMonth,
  ScanSchedule,
  ScheduleUnit,
  WeekDays,
  WeekSchedule,
} from '@cohesity/api/argus';
import { SnapshotSelectionType } from '@cohesity/data-govern/scans';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { FormSectionComponent } from '@cohesity/shared-forms';

import { DcScanFormModel, FormSectionName } from '../dc-scan.model';
import { ActivatedRoute } from '@angular/router';

/**
 * A function to validate whether the setting form control value is valid or not.
 *
 * @param control The form control.
 * @return null if the control is valid, error object otherwise.
 */
export const settingsFormModelValidator: ValidatorFn = (control: FormControl<Pick<DcScan, 'schedule'>>) => {
  switch (control?.value?.schedule?.unit) {
    case ScheduleUnit.Weeks:
      return control?.value?.schedule?.weekSchedule?.dayOfWeek?.length > 0 ? null : { required: true };
    case ScheduleUnit.Months:
      return control?.value?.schedule?.monthSchedule?.weekOfMonth && control?.value?.schedule?.monthSchedule?.dayOfWeek
        ? null
        : { required: true };
    default:
      return null;
  }
};

@Component({
  selector: 'dg-dc-scan-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: FormSectionComponent,
      useExisting: forwardRef(() => SettingsComponent),
    },
  ],
})
export class SettingsComponent extends FormSectionComponent<
  Pick<DcScan, 'schedule' | 'scanMethod' | 'snapshotSelection'>,
  DcScanFormModel,
  DcScan
> {
  /**
   * Form section name
   */
  formSectionName = 'settings' as FormSectionName;

  /**
   * Schedule unit enum
   */
  scheduleTypeEnum = ScheduleUnit;

  /**
   * Weeks of month enum values
   * Note: Last month option skipped here, as temporal doessn't have this option
   */
  weeksOfMonth = Object.values(NumWeekInMonth).filter(week => week !== NumWeekInMonth.Last);

  /**
   * Week days enum values
   */
  weekDays = Object.values(WeekDays);

  /**
   * Dc scan method enum
   */
  readonly dcScanMethodEnum = DcScanMethod;

  /**
   * Array of dc scan method
   */
  readonly scanMethods = Object.values(DcScanMethod);

  /**
   * Snapshot selection type enum
   */
  snapshotSelectionTypeEnum = SnapshotSelectionType;

  /**
   * Selected schedule type
   */
  selectedScheduleType = ScheduleUnit.Once;

  /**
   * Selected week of month
   */
  selectedWeekOfMonth = NumWeekInMonth.First;

  /**
   * Selected week day
   */
  selectedWeekDay = WeekDays.Sunday;

  /**
   * Selected week days
   */
  selectedWeekDaysCtrl = new FormControl<WeekDays[] | null>([WeekDays.Sunday], [Validators.required]);

  /**
   * Selected scan method
   */
  selectedScanMethod = DcScanMethod.full;

  /**
   * Selected scan mode
   */
  selectedScanMode = DcScanMode.default;

  /**
   * Selected snapshot window
   */
  selectedSnapshotWindow = SnapshotSelectionType.mostRecent;

  /**
   * Whether HyperScan is enabled
   */
  readonly isHyperScanEnabled = flagEnabled(
    this.irisContextService.irisContext,
    'dataHawkDataClassificationCreateHyperScanEnabled'
  );

  /**
   * Whether schedule scans are enabled
   */
  readonly isScheduleScansEnabled = flagEnabled(
    this.irisContextService.irisContext,
    'dataHawkDataClassificationScheduledScan'
  );

  /**
   * Whether incremental scans enabled or not
   */
  readonly isIncrementalScansEnabled = flagEnabled(
    this.irisContextService.irisContext,
    'dataHawkDataClassificationIncrementalScansEnabled'
  );

  /**
   * DC Scan ID
   */
  dcScanId = this.activeRoute.snapshot.paramMap.get('id') as DcScanId;

  /**
   * Schedule unit enum values
   */
  get scheduleTypes(): ScheduleUnit[] {
    return [!this.dcScanId ? ScheduleUnit.Once : null, ScheduleUnit.Weeks, ScheduleUnit.Months].filter(Boolean);
  }

  /**
   * Indicated whether hyperscan checkbox is selected or not
   */
  get isHyperScanChecked(): boolean {
    if (this.isIncrementalScansEnabled) {
      return this.selectedScanMode === DcScanMode.hyperscan;
    } else {
      return this.selectedScanMethod === DcScanMethod.hyperscan;
    }
  }

  constructor(private activeRoute: ActivatedRoute, private irisContextService: IrisContextService) {
    super();
  }

  /**
   * Initialize form section
   */
  initFormSection() {
    super.initFormSection();
    this.formControl.addValidators([Validators.required, settingsFormModelValidator]);
    this.formControl.updateValueAndValidity();

    const { scanMode, scanMethod, snapshotSelection, schedule } = this.fromDataModel(this.builder.dataModel);

    if (schedule?.unit) {
      this.selectedScheduleType = schedule.unit;
      switch (schedule.unit) {
        case ScheduleUnit.Weeks:
          this.selectedWeekDaysCtrl.setValue(schedule.weekSchedule?.dayOfWeek ?? [WeekDays.Sunday]);
          break;
        case ScheduleUnit.Months:
          this.selectedWeekOfMonth = schedule.monthSchedule?.weekOfMonth ?? NumWeekInMonth.First;
          this.selectedWeekDay = schedule.monthSchedule?.dayOfWeek ?? WeekDays.Sunday;
          break;
      }
    }

    if (scanMethod) {
      this.selectedScanMethod = scanMethod;
    }

    if (scanMode) {
      this.selectedScanMode = scanMode;
    }

    if (snapshotSelection?.mostRecent) {
      this.selectedSnapshotWindow = SnapshotSelectionType.mostRecent;
    }

    this.selectSetting();
  }

  /**
   * Convert to data model
   *
   * @returns A partial DcScan object.
   */
  toDataModel(): Partial<DcScan> {
    return {
      ...(this.isScheduleScansEnabled && {
        schedule: this.builder.formGroup.value.settings.schedule,
      }),
      scanMethod: this.builder.formGroup.value.settings.scanMethod,
      scanMode: this.builder.formGroup.value.settings.scanMode,
      snapshotSelection: this.builder.formGroup.value.settings.snapshotSelection,
    };
  }

  /**
   * Returns a partial DcScan object based on the given data model.
   *
   * @param dataModel - The DcScan data model to convert.
   * @returns A partial DcScan object.
   */
  fromDataModel(dataModel: DcScan): Pick<DcScan, 'schedule' | 'scanMethod' | 'scanMode' | 'snapshotSelection'> {
    return {
      schedule: dataModel?.schedule || undefined,
      scanMethod: dataModel?.scanMethod || undefined,
      scanMode: dataModel?.scanMode || undefined,
      snapshotSelection: dataModel?.snapshotSelection || undefined,
    };
  }

  /**
   * Selects the schedule type based on the given schedule type.
   *
   * @param scheduleType - The schedule type to select.
   */
  selectScheduleType(scheduleType: ScheduleUnit) {
    this.selectedScheduleType = scheduleType;
    this.selectSetting();
  }

  /**
   * Selects the week days for the weekly schedule.
   *
   * @param days - The week days to select.
   */
  selectWeekDays(days: WeekDays[]) {
    this.selectedWeekDaysCtrl.setValue(days);
    this.selectSetting();
  }

  /**
   * Selects the week day for the month schedule.
   *
   * @param day - The week day to select.
   */
  selectWeekDay(day: WeekDays) {
    this.selectedWeekDay = day;
    this.selectSetting();
  }

  /**
   * Selects the week of month based on the given value.
   *
   * @param week - The week of month to select.
   */
  selectWeekOfMonth(week: NumWeekInMonth) {
    this.selectedWeekOfMonth = week;
    this.selectSetting();
  }

  /**
   * Selects the snapshot window based on the given boolean value.
   *
   * @param newSnapshotWindow - A boolean value indicating whether the most recent snapshot should be used.
   */
  selectSnapshotWindow(newSnapshotWindow: SnapshotSelectionType) {
    this.selectedSnapshotWindow = newSnapshotWindow;
    this.selectSetting();
  }

  /**
   * Selects the HyperScan method based on the given boolean value.
   *
   * @param checked - A boolean value indicating whether HyperScan should be used.
   */
  selectHyperScanMethod(checked: boolean) {
    if (this.isIncrementalScansEnabled) {
      this.selectedScanMode = checked ? DcScanMode.hyperscan : DcScanMode.default;
    } else {
      this.selectedScanMethod = checked ? DcScanMethod.hyperscan : DcScanMethod.full;
    }
    this.selectSetting();
  }

  /**
   * Selects the scan method based on the given value.
   */
  selectScanMethod(scanMethod: DcScanMethod) {
    this.selectedScanMethod = scanMethod;
    this.selectSetting();
  }

  selectSetting() {
    if (this.selectedSnapshotWindow && this.selectedScanMethod) {
      this.next({
        schedule: this.generateSchedule(),
        scanMethod: this.selectedScanMethod,
        snapshotSelection: {
          mostRecent: this.selectedSnapshotWindow === SnapshotSelectionType.mostRecent,
        },
        ...(this.isIncrementalScansEnabled && {
          scanMode: this.selectedScanMode,
        }),
      });
    }
  }

  /**
   * Generate a schedule based on the selected schedule type.
   *
   * @returns ScanSchedule
   */
  private generateSchedule(): ScanSchedule {
    const schedule: ScanSchedule = {
      unit: this.selectedScheduleType,
    };
    switch (this.selectedScheduleType) {
      case ScheduleUnit.Months:
        schedule.monthSchedule = this.generateMonthSchedule();
        break;
      case ScheduleUnit.Weeks:
        schedule.weekSchedule = this.generateWeekSchedule();
        break;
    }
    return schedule;
  }

  /**
   * Generate a month schedule based on the selected week day and week of month.
   *
   * @returns MonthSchedule
   */
  private generateMonthSchedule(): MonthSchedule {
    return {
      dayOfWeek: this.selectedWeekDay,
      weekOfMonth: this.selectedWeekOfMonth,
    };
  }

  /**
   * Generate a week schedule based on the selected week days.
   *
   * @returns WeekSchedule
   */
  private generateWeekSchedule(): WeekSchedule {
    return {
      dayOfWeek: this.selectedWeekDaysCtrl.value,
    };
  }
}
