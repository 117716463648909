/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityApiConfiguration as __Configuration } from '../cohesity-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { DashboardResponse } from '../models/dashboard-response';
@Injectable({
  providedIn: 'root',
})
class DashboardService extends __BaseService {
  static readonly GetDashboardPath = '/public/dashboard';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Returns the dashboard that match the filter criteria specified using parameters.
   *
   * If no parameters are specified, dashboard for the local cluster is returned.
   * @param params The `DashboardService.GetDashboardParams` containing the following parameters:
   *
   * - `tileTypes`: Specifies the types of the tiles to be returned. If this is not
   *   specified, all the tiles are returned. This is ignored when allClusters
   *   is set to true.
   *   'kHealthTile' is the tile that shows health of the cluster and the alerts in
   *   the past 24 hours.
   *   'kJobRunsTile' is the tile that shows job runs in the past 24 hours.
   *   'kRecoveriesTile' is the tile that shows recoveries done in the past 30 days.
   *   'kProtectedObjectsTile' is the tile that shows the protected objects details.
   *   'kProtectionTile' is the tile that shows the protection information in the
   *   past 24 hours.
   *   'kAuditLogsTile' is the tile that shows the recent audit logs.
   *   'kIopsTile' is the tile that shows IP performance in the past 24 hours.
   *   'kThroughputTile' is the tile that shows job runs in the past 24 hours.
   *   'kStorageEfficiencyTile' is the tile that shows job runs in the past 7 days.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `refresh`: Specifies whether to refresh the tiles selected.
   *
   * - `clusterId`: Id of the remote cluster for which to fetch the data. If value is not
   *   specified, it is assumed to be local cluster.
   *
   * - `allClusters`: Summary data for all clusters. If this is set to true, all other
   *   parameters will be ignored.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetDashboardResponse(params: DashboardService.GetDashboardParams): __Observable<__StrictHttpResponse<DashboardResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.tileTypes || []).forEach(val => {if (val != null) __params = __params.append('tileTypes', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.refresh != null) __params = __params.set('refresh', params.refresh.toString());
    if (params.clusterId != null) __params = __params.set('clusterId', params.clusterId.toString());
    if (params.allClusters != null) __params = __params.set('allClusters', params.allClusters.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/dashboard`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DashboardResponse>;
      })
    );
  }
  /**
   * Returns the dashboard that match the filter criteria specified using parameters.
   *
   * If no parameters are specified, dashboard for the local cluster is returned.
   * @param params The `DashboardService.GetDashboardParams` containing the following parameters:
   *
   * - `tileTypes`: Specifies the types of the tiles to be returned. If this is not
   *   specified, all the tiles are returned. This is ignored when allClusters
   *   is set to true.
   *   'kHealthTile' is the tile that shows health of the cluster and the alerts in
   *   the past 24 hours.
   *   'kJobRunsTile' is the tile that shows job runs in the past 24 hours.
   *   'kRecoveriesTile' is the tile that shows recoveries done in the past 30 days.
   *   'kProtectedObjectsTile' is the tile that shows the protected objects details.
   *   'kProtectionTile' is the tile that shows the protection information in the
   *   past 24 hours.
   *   'kAuditLogsTile' is the tile that shows the recent audit logs.
   *   'kIopsTile' is the tile that shows IP performance in the past 24 hours.
   *   'kThroughputTile' is the tile that shows job runs in the past 24 hours.
   *   'kStorageEfficiencyTile' is the tile that shows job runs in the past 7 days.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `refresh`: Specifies whether to refresh the tiles selected.
   *
   * - `clusterId`: Id of the remote cluster for which to fetch the data. If value is not
   *   specified, it is assumed to be local cluster.
   *
   * - `allClusters`: Summary data for all clusters. If this is set to true, all other
   *   parameters will be ignored.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetDashboard(params: DashboardService.GetDashboardParams): __Observable<DashboardResponse> {
    return this.GetDashboardResponse(params).pipe(
      __map(_r => _r.body as DashboardResponse)
    );
  }
}

module DashboardService {

  /**
   * Parameters for GetDashboard
   */
  export interface GetDashboardParams {

    /**
     * Specifies the types of the tiles to be returned. If this is not
     * specified, all the tiles are returned. This is ignored when allClusters
     * is set to true.
     * 'kHealthTile' is the tile that shows health of the cluster and the alerts in
     * the past 24 hours.
     * 'kJobRunsTile' is the tile that shows job runs in the past 24 hours.
     * 'kRecoveriesTile' is the tile that shows recoveries done in the past 30 days.
     * 'kProtectedObjectsTile' is the tile that shows the protected objects details.
     * 'kProtectionTile' is the tile that shows the protection information in the
     * past 24 hours.
     * 'kAuditLogsTile' is the tile that shows the recent audit logs.
     * 'kIopsTile' is the tile that shows IP performance in the past 24 hours.
     * 'kThroughputTile' is the tile that shows job runs in the past 24 hours.
     * 'kStorageEfficiencyTile' is the tile that shows job runs in the past 7 days.
     */
    tileTypes?: Array<'kHealthTile' | 'kJobRunsTile' | 'kRecoveriesTile' | 'kProtectedObjectsTile' | 'kProtectionTile' | 'kAuditLogsTile' | 'kIopsTile' | 'kThroughputTile' | 'kStorageEfficiencyTile'>;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Specifies whether to refresh the tiles selected.
     */
    refresh?: boolean;

    /**
     * Id of the remote cluster for which to fetch the data. If value is not
     * specified, it is assumed to be local cluster.
     */
    clusterId?: number;

    /**
     * Summary data for all clusters. If this is set to true, all other
     * parameters will be ignored.
     */
    allClusters?: boolean;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { DashboardService }
