<h3 mat-dialog-title class="margin-bottom-lg">
  {{'delete' | translate}} {{'policy' | translate}}
</h3>

<mat-dialog-content>
  <p *ngIf="!isSubmitting; else spinner"
    [innerHtml]="'argus.policy.warningText' | translate: { policyName: policy?.name }">
  </p>
</mat-dialog-content>

<ng-template #spinner>
  <cog-spinner></cog-spinner>
</ng-template>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close cogDataId="cancel-delete-policy-dialog">
    {{'cancel' | translate}}
  </button>
  <button mat-flat-button
    cogDataId="delete-policy-btn"
    cdkFocusInitial
    color="primary"
    [disabled]="isSubmitting"
    (click)=deletePolicy()>
    {{'delete' | translate}}
  </button>
</mat-dialog-actions>
