import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, OnInit } from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';
import { ClusterConfig, ClusterConfigParams, ClusterName, ObjectType, Scan } from '@cohesity/api/argus';
import { McmProtectionSourcesServiceApi, RegisteredSource } from '@cohesity/api/private';
import { SearchServiceApi } from '@cohesity/api/v2';
import { ClusterInfoService } from '@cohesity/data-govern/cluster-replication';
import {
  ConcatenatedObjectId,
  extractObjectId,
  multiplySearchObjectBySource,
  SearchObjectPerSource,
  SupportedOsTypes,
} from '@cohesity/data-govern/scans';
import { alphaSortValueFilterSelection, DataFilterValue, KeyedSelectionModel, ValueFilterSelection } from '@cohesity/helix';
import { FormSectionComponent } from '@cohesity/shared-forms';
import { AjaxHandlerService, ClusterIdentifierId, getClusterIdentifier } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import { flatten, groupBy } from 'lodash-es';
import { combineLatest, forkJoin, Observable, of } from 'rxjs';
import { finalize, map, take, tap } from 'rxjs/operators';

import { ThAdapterAccessService } from '../../../th-adapter-access';
import { FormSectionName, ScanFormModel } from '../scan.model';

/**
 * TODO: revert back once testing completed, revert back to 20 count limit
 *
 * Used to limit number of objects that can be selected for a single scan.
 */
export const MAX_OBJECTS_LIMIT = 250;

@Component({
  selector: 'dg-td-scan-object-search',
  templateUrl: './object-search.component.html',
  styleUrls: ['./object-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: FormSectionComponent,
      useExisting: forwardRef(() => ObjectSearchComponent),
    }
  ],
})
export class ObjectSearchComponent
  extends FormSectionComponent<SearchObjectPerSource[], ScanFormModel, Scan> implements OnInit {

  /**
   * Section name for form builder
   */
  formSectionName = 'objects' as FormSectionName;

  /**
   * Searching for objects for the current search query
   */
  searchPending = false;

  /**
   * Loading prerequisites for the search
   */
  isLoadingPrerequisite = true;

  // search results
  searchObjects: SearchObjectPerSource[];

  /**
   * Clusters associated with the account
   */
  clusters: ClusterConfig[];

  // selected objects
  cart: SearchObjectPerSource[] = [];

  // Object search query
  query: string;

  // filters for search results
  filters: Partial<SearchServiceApi.SearchObjectsParams> = {};

  /**
   * Supported OS types
   */
  readonly supportedOsTypes = [SupportedOsTypes.linux, SupportedOsTypes.windows];

  // supported os type filters to use when osType filter is not selected
  readonly defaultOsTypeFilters = this.supportedOsTypes.join();

  // alias for max object selection limit const
  readonly maxObjectsAllowed = MAX_OBJECTS_LIMIT;

  // selection model for protected objects
  selectionModel = new KeyedSelectionModel<SearchObjectPerSource>(obj => obj.id, true);

  // osTypes used for object filters
  osTypes: ValueFilterSelection[] = [
    {
      label: this.translateService.instant('linux'),
      value: 'linux'
    },
    {
      label: this.translateService.instant('windows'),
      value: 'windows'
    },
  ];

  /**
   * filter values for system filter
   */
  systemFilterValues: ValueFilterSelection[] = [];

  /**
   * filter values for source filter
   */
  sourceFilterValues: ValueFilterSelection[] = [];

  /**
   * TODO(banner-exceptions): Below banner should be removed/updated based on s3/swift supportability
   */
  get showBanner(): boolean {
    return Boolean(this.selectionModel.selected.find(item => item.object.environment === ObjectType.kView));
  }

  /**
   * Evaluates the system filter option for allowing selection
   *
   * @param item system filter option
   * @returns true, if system filter option can be selected
   */
  canSelectSystemFilter = (item: ValueFilterSelection) =>
    this.clusters?.find((cluster) => getClusterIdentifier(cluster) === item.value)?.isConnectedToHelios === true;

  /**
   * Returns true if max number of allowed objects per scan are selected
   */
  get ifMaxObjectsSelected(): boolean {
    return this.cart.length >= MAX_OBJECTS_LIMIT;
  }

  constructor(
    private ajaxHandlerService: AjaxHandlerService,
    private cdr: ChangeDetectorRef,
    private clusterInfoService: ClusterInfoService,
    private searchServiceApi: SearchServiceApi,
    private sourceService: McmProtectionSourcesServiceApi,
    public thAdapterAccessService: ThAdapterAccessService,
    private translateService: TranslateService,
  ) {
    super();
  }

  ngOnInit() {
    this.cartInit();
  }

  /**
   * Used to initialize the section. This function will be called by FormBuilderComponent.
   */
  initFormSection() {
    super.initFormSection();
    this.formControl.addValidators([Validators.required, this.maxObjectsValidator]);
    this.formControl.updateValueAndValidity();

    this.getPrerequisite()
      .pipe(
        this.untilDestroy(),
        tap(() => {
          const searchObjectPerSource = this.fromDataModel(this.builder.dataModel);
          const objectIds = searchObjectPerSource.map(object => extractObjectId(object.id));

          combineLatest(
            objectIds.map(objectId => this.getSearchObjects(undefined, { objectIds: [objectId] }, true).pipe(
              map(objects => objects?.find(object => object.objectProtectionInfo.objectId === objectId)),
            )),
          ).pipe(
            this.untilDestroy(),
            finalize(() => {
              this.cdr.detectChanges();
            }),
            tap(objects => {
              if (objects?.length) {
                this.selectionModel.select(...objects);
                this.updateValue();
              }
            }),
          ).subscribe();
        }),
      )
      .subscribe();
  }

  /**
   * Converts current form value to scan objects model
   *
   * @returns Scan objects value for the scan
   */
  toDataModel(): Partial<Scan> {
    return {
      objects: this.builder.formGroup.value?.objects?.map(object => ({ object: { id: object.id } })),
    };
  }

  /**
   * Creates section value form the given scan
   *
   * @param dataModel Scan
   * @returns Section Value
   */
  fromDataModel(dataModel: Scan): SearchObjectPerSource[] {
    return (dataModel?.objects || []).map(({ object }) => ({
      id: object.id,
      object: null,
    } as SearchObjectPerSource));
  }

  /**
   * get all prerequisites
   *
   * @returns combined observable of all pre-requisites
   */
  getPrerequisite() {
    return forkJoin([this.getClusters(), this.getSources()]).pipe(finalize(() => this.isLoadingPrerequisite = false));
  }

  /**
   * gets all the clusters associated with the account
   *
   * @returns cluster data
   */
  getClusters(): Observable<ClusterConfigParams> {
    return this.clusterInfoService.clusterConfig$.pipe(
      take(1),
      tap((clusterConfig) => {
        this.clusters = [
          ...(clusterConfig.orderedClusters ?? []),
          ...(clusterConfig.unorderedClusters ?? []),
        ];

        // move the disconnected clusters to the bottom and disable those
        const groups = groupBy(this.clusters, cluster => !!cluster.isConnectedToHelios);
        const groupedClusterOptions = [groups['true'] || [], groups['false'] || []].map((groupClusters) => {
          const options = groupClusters.map(cluster => ({
            label: String(cluster.clusterName || cluster.clusterId),
            value: getClusterIdentifier(cluster),
            customTooltip: !cluster.isConnectedToHelios
              ? this.translateService.instant('dg.cluster.disconnected') : undefined,
          }));

          options.sort(alphaSortValueFilterSelection);
          return options;
        });

        this.systemFilterValues = flatten(groupedClusterOptions);
      })
    );
  }

  /**
   * gets all kVMware sources
   *
   * @returns list of sources
   */
  getSources(): Observable<RegisteredSource[]> {
    return this.sourceService.getRegisteredSources().pipe(
      tap(sources => this.sourceFilterValues = sources.map(source => ({
        label: source.name,
        value: source.uuid
      })))
    );
  }

  /**
   * applies filters selected by user
   *
   * @param filters filter values
   */
  applyFilters(filters: DataFilterValue<any, any>[]) {
    const params: Partial<SearchServiceApi.SearchObjectsParams> = {};
    filters?.forEach(({ key, value }) => {
      const filterValues = value.map(filterVal => filterVal.value);
      switch (key) {
        case 'environments':
          params.environments = filterValues;
          break;
        case 'osTypes':
          params.osTypes = filterValues.map(osType => SupportedOsTypes[osType]);
          break;
        case 'system':
          params.clusterIdentifiers = filterValues;
          break;
        case 'source':
          params.sourceUuids = filterValues;
          break;
      }
    });
    this.filters = params;
    this.getObjects(this.query);
  }

  /**
   * Initiate search for objects and set results
   *
   * @param searchString search string
   */
  getObjects(searchString: string) {
    this.query = searchString;
    this.searchPending = true;

    combineLatest([
      this.getSearchObjects(searchString, this.filters),
      this.clusterInfoService.clusterConfig$.pipe(take(1)),
    ])
      .pipe(
        this.untilDestroy(),
        finalize(() => {
          this.searchPending = false;
          this.cdr.detectChanges();
        }),
        tap(([objs, clusterConfig]) => {
          const clustersMap = new Map<number, ClusterConfig>();
          [...(clusterConfig?.orderedClusters ?? []), ...(clusterConfig?.unorderedClusters ?? [])].forEach(cluster =>
            clustersMap.set(cluster.clusterId, cluster)
          );
          this.selectionModel.clear();
          this.searchObjects = objs.map(obj => {
            obj.objectProtectionInfo.name =
              obj.objectProtectionInfo.name ?? clustersMap.get(obj.objectProtectionInfo.clusterId)?.clusterName;
            return obj;
          }).filter(obj => clustersMap.get(obj.objectProtectionInfo.clusterId)?.isConnectedToHelios);
          this.initialSelection();
        })
      )
      .subscribe({ error: this.ajaxHandlerService.handler });
  }

  /**
   * Searches protected objects for the given query
   *
   * @param query search query
   * @returns search results
   */
  getSearchObjects(
    query: string,
    filters: SearchServiceApi.SearchObjectsParams,
    force = false,
  ): Observable<SearchObjectPerSource[]> {
    if (!query && !force) {
      return of([]);
    }

    // Below hack is to override global search environment params to support multiple env filter
    const environments = filters.environments?.length ? [filters.environments.join(',') as any] : null;

    /**
     * Filtering search results to contain only objects of type 'kVMware',
     * since we are supporting scans for vmware objects only (currently)
     */
    return this.searchServiceApi
      .SearchObjects({
        searchString: query,
        isProtected: true,
        ...filters,
        environments,
      })
      .pipe(
        map(({ objects }) => objects.reduce((searchResults: SearchObjectPerSource[], object) => {
          const clusterNameMap = new Map<ClusterIdentifierId, ClusterName>();
          this.clusters.forEach(cluster => clusterNameMap.set(getClusterIdentifier(cluster), cluster.clusterName));
          searchResults.push(...multiplySearchObjectBySource(object, clusterNameMap));
          return searchResults;
        }, [])),

        // We show a search result per each cluster if object is present in multiple clusters.
        // When filtered by cluster, api returns objects present in that clusters.
        // However for each object there may be multiple clusters associated.
        // so we filtering the search results after multiplying results per cluster.
        map(searchResults => {
          if (!filters.clusterIdentifiers?.length) {
            return searchResults;
          }
          return searchResults.filter(result => filters.clusterIdentifiers
            .includes(getClusterIdentifier(result.objectProtectionInfo))
          );
        })
      );
  }

  /**
   * Updates cart according selection changes in the selection model
   */
  private cartInit() {
    this.selectionModel.changed.pipe(this.untilDestroy()).subscribe(changes => {
      // ignoring changed events triggered by clearing selection model due to query changes
      if (!this.searchPending) {
        changes.added.forEach(obj => {
          if (!this.isPresentInCart(obj)) {
            this.cart.push(obj);
          }
        });
        this.cart = this.cart.filter(
          selectedObject => !changes.removed.some(removedObj => removedObj.id === selectedObject.id)
        );
        // this.updateValue();
      }
    });
  }

  /**
   * Update current section value to parent Form value
   */
  updateValue() {
    this.next([...this.cart]);
  }

  /**
   * Shows edit view of the section
   */
  editObjects() {
    this.formSectionViewCta.viewEditMode();
  }

  /**
   * Selects objects from the search results that are present in the cart
   */
  private initialSelection() {
    this.selectionModel.select(
      ...this.searchObjects.filter(searchObj => this.isPresentInCart(searchObj)
      )
    );
  }

  /**
   * Determines if the given obj is present in the cart or not
   *
   * @param obj obj to check for presence
   * @returns true if the obj is present in cart else it will return false
   */
  private isPresentInCart(obj: SearchObjectPerSource): boolean {
    return this.cart.some(cartObj => obj.id === cartObj.id);
  }

  // Used to remove items from the cart
  removeFromCart(id: ConcatenatedObjectId) {
    const objectToRemove = this.cart.find(obj => obj.id === id);
    this.cart = this.cart.filter(obj => obj.id !== id);
    this.selectionModel.deselect(objectToRemove);
  }

  /**
   * Creates validator function to validate number objects that can be selected for a single scan
   *
   * @returns max number of objects per single scan validator fn
   */
  private maxObjectsValidator: ValidatorFn = () => {
    if (this.cart.length <= MAX_OBJECTS_LIMIT) {
      return null;
    }
    return { maxObjectsReached: true };
  };
}
