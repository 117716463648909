import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { CopyStats } from '@cohesity/api/v2';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { SnapshotDetailsDialogComponent } from './snapshot-details-dialog.component';
import { SearchObjectPerSource } from '@cohesity/data-govern/scans';

@Injectable({
  providedIn: 'root',
})
export class SnapshotDetailsDialogService {
  constructor(private dialog: MatDialog) {}

  /**
   * Launches Snapshot summary Dialog
   *
   * @returns dialog observable
   */
  launch(copyStats: CopyStats[], objects: SearchObjectPerSource[]): Observable<any> {
    const dialogRef = this.dialog.open(SnapshotDetailsDialogComponent, {
      data: {
        copyStats,
        objects,
      },
      maxWidth: 'unset',
      width: '60rem',
    });
    return dialogRef.afterClosed().pipe(filter(Boolean));
  }
}
