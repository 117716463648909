import { Pipe, PipeTransform } from '@angular/core';
import { PolicyRunStatus } from '@cohesity/api/argus';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'policyRunStatusPipe' })
export class PolicyRunStatusPipe implements PipeTransform {
  constructor(private translateService: TranslateService) { }

  transform(status: PolicyRunStatus): string {
    return this.translateService.instant(status ? `argus.enum.policy.status.${status}` : 'naNotAvailableLetters');
  }
}
