import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { IoCsThreatsApiService, Threat } from '@cohesity/api/argus';
import { SnackBarService } from '@cohesity/helix';
import { AjaxHandlerService, AutoDestroyable } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import { Buffer } from 'buffer';
import { finalize } from 'rxjs/operators';

import { SelectedYaraFile } from './yara-rule-upload-dialog.model';

@Component({
  selector: 'dg-td-yara-rule-upload-dialog',
  templateUrl: './yara-rule-upload-dialog.component.html',
  styleUrls: ['./yara-rule-upload-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class YaraRuleUploadDialogComponent extends AutoDestroyable {
  /**
   * Holds loading
   */
  isLoading = false;

  /**
   * Selected YARA file details
   */
  selectedFile: SelectedYaraFile;

  /**
   * form control for threat name input
   */
  threatNameControl = new FormControl('', [Validators.required]);

  /**
   * Supported YARA file extensions
   */
  supportedExtensions = ['yara', 'yar'].map(ext => `.${ext}`).join(', ');

  /**
   * Conclude whether we can submit
   */
  get canSubmit(): boolean {
    return this.selectedFile?.base64 && this.threatNameControl.valid;
  }

  constructor(
    private ajaxHandlerService: AjaxHandlerService,
    private cdr: ChangeDetectorRef,
    private dialogRef: MatDialogRef<YaraRuleUploadDialogComponent, Threat>,
    private ioCsThreatsApiService: IoCsThreatsApiService,
    private snackBarService: SnackBarService,
    private translate: TranslateService,
  ) {
    super();
  }

  /**
   * Handler for file input change event.
   *
   * @param   event    Change event
   */
  fileInputChanged(event: Event) {
    const fileInput = event.target as HTMLInputElement;
    const files = fileInput.files || new FileList();
    const file = files[0];
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = () => {
      this.selectedFile = {
        name: file.name,
        base64: Buffer.from(reader.result as string).toString('base64'),
      };
      this.cdr.detectChanges();
    };
  }

  /**
   * Creates new YARA rule
   */
  validateAndSubmit() {
    if (this.canSubmit) {
      this.isLoading = true;
      this.ioCsThreatsApiService
        .createThreat({
          body: {
            name: this.threatNameControl.value,
            customParams: {
              yaraRule: this.selectedFile.base64,
            },
          },
        })
        .pipe(
          this.untilDestroy(),
          finalize(() => {
            this.isLoading = false;
            this.cdr.detectChanges();
          })
        )
        .subscribe(result => {
          this.snackBarService.open(this.translate.instant('successfullyAdded'), 'success');
          this.dialogRef.close(result);
        }, this.ajaxHandlerService.handler);
    }
  }

  /**
   * Clears selected YARA file
   */
  clearSelectedFile() {
    this.selectedFile = null;
    this.threatNameControl.reset();
  }
}
