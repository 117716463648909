/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityApiConfiguration as __Configuration } from '../cohesity-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { KmsConfigurationResponse } from '../models/kms-configuration-response';
@Injectable({
  providedIn: 'root',
})
class KmsConfigurationResponseService extends __BaseService {
  static readonly GetKmsConfigPath = '/public/kmsConfig';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * List KMS configurations in the cluster.
   * @param params The `KmsConfigurationResponseService.GetKmsConfigParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `id`: The Id of a KMS server.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Specifies a list of KMS configurations.
   */
  GetKmsConfigResponse(params: KmsConfigurationResponseService.GetKmsConfigParams): __Observable<__StrictHttpResponse<Array<KmsConfigurationResponse>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.id != null) __params = __params.set('id', params.id.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/kmsConfig`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<KmsConfigurationResponse>>;
      })
    );
  }
  /**
   * List KMS configurations in the cluster.
   * @param params The `KmsConfigurationResponseService.GetKmsConfigParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `id`: The Id of a KMS server.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Specifies a list of KMS configurations.
   */
  GetKmsConfig(params: KmsConfigurationResponseService.GetKmsConfigParams): __Observable<Array<KmsConfigurationResponse>> {
    return this.GetKmsConfigResponse(params).pipe(
      __map(_r => _r.body as Array<KmsConfigurationResponse>)
    );
  }
}

module KmsConfigurationResponseService {

  /**
   * Parameters for GetKmsConfig
   */
  export interface GetKmsConfigParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * The Id of a KMS server.
     */
    id?: number;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { KmsConfigurationResponseService }
