import { ChangeDetectionStrategy, Component, forwardRef } from '@angular/core';
import { FormControl, ValidatorFn, Validators } from '@angular/forms';
import { DcScan, ScanPreference, ScanSchedule, ScanTrigger, ScheduleUnit, TimeOfDay } from '@cohesity/api/argus';
import { ClusterInfoService, ViewClusterPreferenceDialogComponent } from '@cohesity/data-govern/cluster-replication';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { FormSectionComponent } from '@cohesity/shared-forms';
import { AjaxHandlerService, DialogService } from '@cohesity/utils';
import moment from 'moment/moment';
import { finalize, skip, take, tap } from 'rxjs/operators';

import { excludeFileExtension, excludeFilePaths, maxFileSizeBytes } from '../dc-scan.constants';
import { DcScanFormModel, FormSectionName } from '../dc-scan.model';

/**
 * A function to validate whether the scan name is valid or not.
 *
 * @param control The form control.
 * @return null if the control is valid, error object otherwise.
 */
export const nameValidator: ValidatorFn =
  (control: FormControl<Pick<DcScan, 'name'>>) => control?.value?.name ? null : { required: true };

@Component({
  selector: 'dg-dc-scan-advanced-settings',
  templateUrl: './advanced-settings.component.html',
  styleUrls: ['./advanced-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: FormSectionComponent,
      useExisting: forwardRef(() => AdvancedSettingsComponent),
    },
  ],
})
export class AdvancedSettingsComponent extends FormSectionComponent<Pick<DcScan, 'name' | 'schedule'>, DcScanFormModel, DcScan> {
  formSectionName = 'advancedSettings' as FormSectionName;

  scan: Pick<DcScan, 'name' | 'schedule' | 'scanPreference' | 'isOCREnabled'> = {
    name: null,
    scanPreference: null,
    schedule: null,
  };

  scanNameCtrl = new FormControl<string | null>(null, [Validators.required]);

  enableOCRCtrl = new FormControl<boolean>(false);

  viewExcludedExtensionSummary = false;

  viewExcludedPathsSummary = false;

  defaultTime: TimeOfDay;

  startTimeCtrl = new FormControl();

  disableScanOnReplica = false;

  get startTime(): string {
    const time24 = `${this.startTimeCtrl.value.hour}:${this.startTimeCtrl.value.minute}`;
    return moment(time24, 'HH:mm').format('h:mm a');
  }

  startTimeZoneCtrl = new FormControl();

  private timezones = moment.tz.names();

  filteredTimeZones = this.timezones;

  startTimeChanged = false;

  /** Enum values for scan location options */
  readonly scanPreferences = ScanPreference;

  /** Specifies the maximum size of the file which will be scaned.  */
  readonly maxFileSizeBytes = maxFileSizeBytes;

  /** The list of hard coded exclusion list */
  readonly excludeFileExtension = excludeFileExtension;

  /** The list of hard coded file path exclusion list */
  readonly excludeFilePaths = excludeFilePaths;

  /**
   * Whether schedule scans are enabled
   */
  readonly isScheduleScanEnabled = flagEnabled(this.irisContextService.irisContext, 'dataHawkDataClassificationScheduledScan');

  /**
   * Whether OCR scans are enabled
   */
  readonly isOCREnabled = flagEnabled(this.irisContextService.irisContext, 'datahawkDataClassificationkOCRScanEnabled');

  /**
   * Whether replica scans are enabled
   */
  readonly isReplicaScansEnabled = flagEnabled(this.irisContextService.irisContext, 'dataHawkClusterReplication');

  /**
   * flag to show or hide scanStartTime
   */
  showScanStartTime = false;

  /**
   * flag to track cluster preference loading
   */
  isClusterPreferenceLoading = false;

  /**
   * Indicates whether scan is on-demand scan or not
   */
  get isOnDemandScan(): boolean {
    return this.builder.formGroup.value?.settings?.schedule?.unit === ScheduleUnit.Once;
  }

  /**
   * Indicates whether scan order preference option is selected or not
   */
  get isClusterScanOrderOptionSelected(): boolean {
    return this.scan.scanPreference === ScanPreference.clusterScanOrder;
  }

  constructor(
    private ajaxHandler: AjaxHandlerService,
    private clusterInfoService: ClusterInfoService,
    private dialog: DialogService,
    private irisContextService: IrisContextService,
  ) {
    super();
  }

  initFormSection() {
    super.initFormSection();
    this.startTimeValidatorsInit();
    this.formControl.addValidators([Validators.required, nameValidator, this.scanPreferenceValidator]);
    this.formControl.updateValueAndValidity();

    const scan = this.fromDataModel(this.builder.dataModel);

    if (scan?.name) {
      this.scan.name = scan.name;
      this.scanNameCtrl.setValue(this.scan.name);
    }

    if (scan?.isOCREnabled) {
      this.scan.isOCREnabled = scan.isOCREnabled;
      this.enableOCRCtrl.setValue(this.scan.isOCREnabled);
    }

    if (scan?.scanPreference) {
      this.scan.scanPreference = scan.scanPreference;
    }

    if (scan?.schedule) {
      this.scan.schedule = scan.schedule;
      this.setDefaultStartTime(scan.schedule);
      this.resetStartTime();
    }

    if (this.isReplicaScansEnabled) {
      this.checkClusterConfigStatus();
    }

    this.scanNameCtrl.valueChanges.pipe(
      this.untilDestroy(),
      tap(() => this.updateSetting()),
    ).subscribe();

    this.enableOCRCtrl.valueChanges.pipe(
      this.untilDestroy(),
      tap(() => this.updateSetting()),
    ).subscribe();

    this.startTimeCtrl.valueChanges.pipe(
      skip(1),
      this.untilDestroy(),
      tap(() => this.startTimeChanged = true),
      tap(() => this.updateSetting())
    ).subscribe();

    this.startTimeZoneCtrl.valueChanges.pipe(
      this.untilDestroy(),
      tap(() => this.startTimeChanged = true),
      tap(() => this.updateSetting()),
    ).subscribe();


    this.updateSetting();
  }

  toDataModel(): Partial<DcScan> {
    return {
      name: this.builder.formGroup.value.advancedSettings.name,
      ...(this.isReplicaScansEnabled && {
        scanPreference: this.scan.scanPreference
      }),
      ...(this.isScheduleScanEnabled && this.showScanStartTime && {
        schedule: {
          ...this.builder.formGroup.value.settings.schedule,
          isPaused: this.scan?.schedule?.isPaused,
          time: this.builder.formGroup.value.advancedSettings.schedule.time
        },
      }),
      ...(this.isOCREnabled && {
        isOCREnabled: this.builder.formGroup.value.advancedSettings.isOCREnabled
      }),
      scanTrigger: this.isOnDemandScan ? ScanTrigger.manually : ScanTrigger.schedule,
    };
  }

  fromDataModel(dataModel: DcScan): Pick<DcScan, 'name' | 'schedule' | 'scanTrigger' | 'scanPreference' | 'isOCREnabled'> {
    return {
      name: dataModel?.name || undefined,
      scanPreference: dataModel?.scanPreference || ScanPreference.scanOnLocal,
      schedule: dataModel?.schedule || undefined,
      scanTrigger: dataModel?.scanTrigger || undefined,
      isOCREnabled: dataModel?.isOCREnabled || undefined,
    };
  }

  updateSetting() {
    this.next({
      name: this.scanNameCtrl.value,
      scanPreference: this.scan.scanPreference,
      schedule: {
        ...this.builder.formGroup.value.settings?.schedule,
        time: {
          hour: this.startTimeCtrl.value.hour,
          minute: this.startTimeCtrl.value.minute,
          timeZone: this.startTimeZoneCtrl.value,
        }
      },
      isOCREnabled: this.enableOCRCtrl.value,
      scanTrigger: this.isOnDemandScan ? ScanTrigger.manually : ScanTrigger.schedule,
    });
  }

  selectScanLocation(scanLocation: ScanPreference) {
    this.scan.scanPreference = scanLocation;
    this.updateSetting();
  }

  resetStartTime() {
    this.startTimeCtrl.setValue({
      hour: this.defaultTime.hour,
      minute: this.defaultTime.minute,
    });
    this.startTimeZoneCtrl.setValue(this.defaultTime.timeZone);
    this.startTimeChanged = false;
  }

  filterTimeZones(value: string) {
    this.filteredTimeZones = this.timezones.filter(zone => zone.toLowerCase().includes(value.toLowerCase()));
  }

  private setDefaultStartTime(schedule: ScanSchedule) {
    const currentTime = moment();
    this.defaultTime = {
      hour: schedule.time?.hour || currentTime.hours(),
      minute: schedule.time?.minute || currentTime.minutes(),
      timeZone: schedule.time?.timeZone || currentTime.zone.name
    };
  }

  /**
   * Validator function for scan preference option
   *
   * @returns invalid scan preference error if scan preference is loading
   */
  private scanPreferenceValidator = () => this.isClusterPreferenceLoading ? { invalidScanPreference: true } : null;

  private startTimeValidatorsInit() {
    const startCtrlValidator = () => this.startTimeCtrl.valid ? null : { invalidStartTime: true };
    this.builder.formGroup.controls.settings.valueChanges.subscribe(settings => {
      if (settings.schedule.unit !== ScheduleUnit.Once) {
        this.formControl.addValidators([startCtrlValidator]);
        this.showScanStartTime = true;
      } else {
        this.formControl.removeValidators([startCtrlValidator]);
        this.showScanStartTime = false;
      }
    });
  }

  /**
   * Checks cluster config detail and sets scan preference option accordingly
   */
  private checkClusterConfigStatus() {
    this.isClusterPreferenceLoading = true;
    this.clusterInfoService.clusterConfig$.pipe(
      take(1),
      this.untilDestroy(),
      finalize(() => {
        this.isClusterPreferenceLoading = false;
        this.updateSetting();
      }),
    ).subscribe((config) => {
      if (!config?.orderedClusters?.length) {
        this.disableScanOnReplica = true;
      }
    }, (err) => {
      this.disableScanOnReplica = true;
      this.ajaxHandler.handler(err);
    });
  }

  /**
   * Opens Cluster scanning preference dialog
   */
  openViewScanOrderDialog() {
    ViewClusterPreferenceDialogComponent.launchDialog(this.dialog).pipe(this.untilDestroy()).subscribe();
  }
}
