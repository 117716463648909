import { Pipe, PipeTransform } from '@angular/core';
import { iconSettings, StatusObject, statusPriority } from '../constants';

/**
 * Contains the count and type of status.
 */
interface CountObject {
  /**
   * Number of object with the same status.
   */
  value: number;

  /**
   * Type of first object. Mainly used for archival object when it can be cloud or tape.
   */
  iconType?: string;
}

/**
 * Converts a status object to an icon name
 *
 * @example
 *   <cog-icon shape="{{ status | statusIconMap }}"></cog-icon>
 *   <cog-icon [shape]="status | statusIconMap"></cog-icon>
 */
@Pipe({
  name: 'statusIconMap',
  standalone: true
})
export class StatusIconMapPipe implements PipeTransform {
  /**
   * Converts a status object to an icon name.
   *
   * @param   statusObject  Status object
   * @returns The full icon name used in cog-icon
   */
  transform(statusObject: StatusObject): string {
    const { type, status } = statusObject;

    if (iconSettings[type]) {
      const { iconType, mapping } = iconSettings[type];

      if (iconType) {
        if (mapping && status && mapping[status]) {
          return `helix:${iconType}-${mapping[status]}`;
        }
        return `helix:${iconType}`;
      }
    }
  }
}

/**
 * Converts a string value to an icon name
 *
 * @example
 *   <cog-icon [shape]="statusList | statusIconReduce"></cog-icon>
 */
@Pipe({
  name: 'statusIconReduce'
})
export class StatusIconReducePipe implements PipeTransform {
  /**
   * Converts a status list to an icon name.
   *
   * @param   statusList  List of status objects
   * @returns The full icon name used in cog-icon
   */
  transform(statusList: StatusObject[]): string {
    if (statusList && statusList.length) {
      const counts: { [index: string]: CountObject } = {};

      // Count the number of object for each status and remember its type.
      for (let i = 0; i < statusList.length; i++) {
        const { type, status } = statusList[i];
        const { iconType, mapping } = iconSettings[type];
        const mappedStatus = mapping[status];

        if (mappedStatus === statusPriority[0]) {
          return `helix:${iconType}-${mappedStatus}`;
        }
        if (!counts[mappedStatus]) {
          counts[mappedStatus] = { iconType, value: 0 };
        }
        counts[mappedStatus].value++;
      }

      // Return the type and highest priority status with count > 0
      for (let j = 1; j < statusPriority.length; j++) {
        const countObject = counts[statusPriority[j]];

        if (countObject) {
          return `helix:${countObject.iconType}-${statusPriority[j]}`;
        }
      }

      // Just show icon without status when all objects in the list does not have status.
      const { type: defaultType } = statusList[0];

      if (defaultType && iconSettings[defaultType]) {
        return `helix:${iconSettings[defaultType].iconType}`;
      }
    }
  }
}
