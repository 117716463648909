/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityApiConfiguration as __Configuration } from '../cohesity-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { IdpServiceConfiguration } from '../models/idp-service-configuration';
import { CreateIdpConfigurationRequest } from '../models/create-idp-configuration-request';
import { AddedIdpPrincipal } from '../models/added-idp-principal';
import { IdpPrincipalsAddParameters } from '../models/idp-principals-add-parameters';
import { UpdateIdpConfigurationRequest } from '../models/update-idp-configuration-request';
@Injectable({
  providedIn: 'root',
})
class IdpsService extends __BaseService {
  static readonly GetIdpsPath = '/public/idps';
  static readonly CreateIdpPath = '/public/idps';
  static readonly IdpLoginPath = '/public/idps/login';
  static readonly AddIdpDirectoryPrincipalsPath = '/public/idps/principals';
  static readonly UpdateIdpPath = '/public/idps/{id}';
  static readonly DeleteIdpPath = '/public/idps/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * List the IdPs configured on the Cluster.
   *
   * Returns the Idps configured on the Cohesity Cluster corresponding to the filter
   * parameters. If no filter is given, all Idp configurations are returned.
   * @param params The `IdpsService.GetIdpsParams` containing the following parameters:
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which objects are to be
   *   returned.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `names`: Specifies the names of the IdP vendors like Okta. If specified, returns
   *   IdP configurations of the vendors matching the names in the parameters.
   *
   * - `ids`: Specifies the Ids of the IdP configuration. If specified, returns IdP
   *   configurations of the matching Ids in the IdP configuration.
   *
   * - `domains`: Specifies the domains of the IdP configurations. If specified, returns
   *   IdP configurations matching the domains in the parameters.
   *
   * - `allUnderHierarchy`: AllUnderHierarchy specifies if objects of all the tenants under the
   *   hierarchy of the logged in user's organization should be returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetIdpsResponse(params: IdpsService.GetIdpsParams): __Observable<__StrictHttpResponse<Array<IdpServiceConfiguration>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.tenantIds || []).forEach(val => {if (val != null) __params = __params.append('tenantIds', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.names || []).forEach(val => {if (val != null) __params = __params.append('names', val.toString())});
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    (params.domains || []).forEach(val => {if (val != null) __params = __params.append('domains', val.toString())});
    if (params.allUnderHierarchy != null) __params = __params.set('allUnderHierarchy', params.allUnderHierarchy.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/idps`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<IdpServiceConfiguration>>;
      })
    );
  }
  /**
   * List the IdPs configured on the Cluster.
   *
   * Returns the Idps configured on the Cohesity Cluster corresponding to the filter
   * parameters. If no filter is given, all Idp configurations are returned.
   * @param params The `IdpsService.GetIdpsParams` containing the following parameters:
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which objects are to be
   *   returned.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `names`: Specifies the names of the IdP vendors like Okta. If specified, returns
   *   IdP configurations of the vendors matching the names in the parameters.
   *
   * - `ids`: Specifies the Ids of the IdP configuration. If specified, returns IdP
   *   configurations of the matching Ids in the IdP configuration.
   *
   * - `domains`: Specifies the domains of the IdP configurations. If specified, returns
   *   IdP configurations matching the domains in the parameters.
   *
   * - `allUnderHierarchy`: AllUnderHierarchy specifies if objects of all the tenants under the
   *   hierarchy of the logged in user's organization should be returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetIdps(params: IdpsService.GetIdpsParams): __Observable<Array<IdpServiceConfiguration>> {
    return this.GetIdpsResponse(params).pipe(
      __map(_r => _r.body as Array<IdpServiceConfiguration>)
    );
  }

  /**
   * Create an IdP configuration.
   *
   * Returns the newly created IdP configuration.
   * @param params The `IdpsService.CreateIdpParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: Request to create a new IdP Configuration.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateIdpResponse(params: IdpsService.CreateIdpParams): __Observable<__StrictHttpResponse<IdpServiceConfiguration>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    __body = params.body;
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/idps`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<IdpServiceConfiguration>;
      })
    );
  }
  /**
   * Create an IdP configuration.
   *
   * Returns the newly created IdP configuration.
   * @param params The `IdpsService.CreateIdpParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: Request to create a new IdP Configuration.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateIdp(params: IdpsService.CreateIdpParams): __Observable<IdpServiceConfiguration> {
    return this.CreateIdpResponse(params).pipe(
      __map(_r => _r.body as IdpServiceConfiguration)
    );
  }

  /**
   * Login to Cohesity Cluster using an IdP.
   *
   * Redirects the client to the IdP site with the URI to login.
   * @param params The `IdpsService.IdpLoginParams` containing the following parameters:
   *
   * - `tenantId`: Specifies an optional tenantId for which the SSO login should be done.
   *   If this is not specified, Cluster SSO login is done.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  IdpLoginResponse(params: IdpsService.IdpLoginParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.tenantId != null) __params = __params.set('tenantId', params.tenantId.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/idps/login`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Login to Cohesity Cluster using an IdP.
   *
   * Redirects the client to the IdP site with the URI to login.
   * @param params The `IdpsService.IdpLoginParams` containing the following parameters:
   *
   * - `tenantId`: Specifies an optional tenantId for which the SSO login should be done.
   *   If this is not specified, Cluster SSO login is done.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  IdpLogin(params: IdpsService.IdpLoginParams): __Observable<null> {
    return this.IdpLoginResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Add multiple groups or users on the Cohesity Cluster for the specified
   * Idp principals. In addition, assign Cohesity roles to the users or groups
   * to define their Cohesity privileges.
   *
   * After a group or user has been added to a Cohesity Cluster,
   * the referenced Idp principal can be used by the Cohesity Cluster.
   * In addition, this operation maps Cohesity roles with a group or user and
   * this mapping defines the privileges allowed on the Cohesity Cluster for the
   * group or user.
   * For example if an 'management' group is created on the Cohesity Cluster
   * for the Idp 'management' principal group and is associated with the
   * Cohesity 'View' role, all users in the referenced Idp
   * 'management' principal group can log in to the Cohesity Dashboard but
   * will only have view-only privileges. These users cannot create new
   * Protection Jobs, Policies, Views, etc.
   *
   * NOTE: Local Cohesity users and groups cannot be created by this operation.
   * Local Cohesity users or groups do not have an associated Idp
   * principals and are created directly in the default LOCAL domain.
   * @param params The `IdpsService.AddIdpDirectoryPrincipalsParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: Request to add groups or users to the Cohesity Cluster.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  AddIdpDirectoryPrincipalsResponse(params: IdpsService.AddIdpDirectoryPrincipalsParams): __Observable<__StrictHttpResponse<Array<AddedIdpPrincipal>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    __body = params.body;
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/idps/principals`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AddedIdpPrincipal>>;
      })
    );
  }
  /**
   * Add multiple groups or users on the Cohesity Cluster for the specified
   * Idp principals. In addition, assign Cohesity roles to the users or groups
   * to define their Cohesity privileges.
   *
   * After a group or user has been added to a Cohesity Cluster,
   * the referenced Idp principal can be used by the Cohesity Cluster.
   * In addition, this operation maps Cohesity roles with a group or user and
   * this mapping defines the privileges allowed on the Cohesity Cluster for the
   * group or user.
   * For example if an 'management' group is created on the Cohesity Cluster
   * for the Idp 'management' principal group and is associated with the
   * Cohesity 'View' role, all users in the referenced Idp
   * 'management' principal group can log in to the Cohesity Dashboard but
   * will only have view-only privileges. These users cannot create new
   * Protection Jobs, Policies, Views, etc.
   *
   * NOTE: Local Cohesity users and groups cannot be created by this operation.
   * Local Cohesity users or groups do not have an associated Idp
   * principals and are created directly in the default LOCAL domain.
   * @param params The `IdpsService.AddIdpDirectoryPrincipalsParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: Request to add groups or users to the Cohesity Cluster.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  AddIdpDirectoryPrincipals(params: IdpsService.AddIdpDirectoryPrincipalsParams): __Observable<Array<AddedIdpPrincipal>> {
    return this.AddIdpDirectoryPrincipalsResponse(params).pipe(
      __map(_r => _r.body as Array<AddedIdpPrincipal>)
    );
  }

  /**
   * Update an IdP configuration.
   *
   * Returns the updated IdP configuration.
   * @param params The `IdpsService.UpdateIdpParams` containing the following parameters:
   *
   * - `id`: Specifies the Id assigned for the IdP Service by the Cluster.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: Request to update an Idp Configuration.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateIdpResponse(params: IdpsService.UpdateIdpParams): __Observable<__StrictHttpResponse<IdpServiceConfiguration>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    __body = params.body;
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/public/idps/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<IdpServiceConfiguration>;
      })
    );
  }
  /**
   * Update an IdP configuration.
   *
   * Returns the updated IdP configuration.
   * @param params The `IdpsService.UpdateIdpParams` containing the following parameters:
   *
   * - `id`: Specifies the Id assigned for the IdP Service by the Cluster.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: Request to update an Idp Configuration.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateIdp(params: IdpsService.UpdateIdpParams): __Observable<IdpServiceConfiguration> {
    return this.UpdateIdpResponse(params).pipe(
      __map(_r => _r.body as IdpServiceConfiguration)
    );
  }

  /**
   * Delete one IdP configuration.
   *
   * Returns Success if the IdP configuration is deleted.
   * @param params The `IdpsService.DeleteIdpParams` containing the following parameters:
   *
   * - `id`: Specifies the Id assigned for the IdP Service by the Cluster.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteIdpResponse(params: IdpsService.DeleteIdpParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/public/idps/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete one IdP configuration.
   *
   * Returns Success if the IdP configuration is deleted.
   * @param params The `IdpsService.DeleteIdpParams` containing the following parameters:
   *
   * - `id`: Specifies the Id assigned for the IdP Service by the Cluster.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteIdp(params: IdpsService.DeleteIdpParams): __Observable<null> {
    return this.DeleteIdpResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module IdpsService {

  /**
   * Parameters for GetIdps
   */
  export interface GetIdpsParams {

    /**
     * TenantIds contains ids of the tenants for which objects are to be
     * returned.
     */
    tenantIds?: Array<string>;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Specifies the names of the IdP vendors like Okta. If specified, returns
     * IdP configurations of the vendors matching the names in the parameters.
     */
    names?: Array<string>;

    /**
     * Specifies the Ids of the IdP configuration. If specified, returns IdP
     * configurations of the matching Ids in the IdP configuration.
     */
    ids?: Array<number>;

    /**
     * Specifies the domains of the IdP configurations. If specified, returns
     * IdP configurations matching the domains in the parameters.
     */
    domains?: Array<string>;

    /**
     * AllUnderHierarchy specifies if objects of all the tenants under the
     * hierarchy of the logged in user's organization should be returned.
     */
    allUnderHierarchy?: boolean;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreateIdp
   */
  export interface CreateIdpParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Request to create a new IdP Configuration.
     */
    body?: CreateIdpConfigurationRequest;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for IdpLogin
   */
  export interface IdpLoginParams {

    /**
     * Specifies an optional tenantId for which the SSO login should be done.
     * If this is not specified, Cluster SSO login is done.
     */
    tenantId?: string;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for AddIdpDirectoryPrincipals
   */
  export interface AddIdpDirectoryPrincipalsParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Request to add groups or users to the Cohesity Cluster.
     */
    body?: Array<IdpPrincipalsAddParameters>;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateIdp
   */
  export interface UpdateIdpParams {

    /**
     * Specifies the Id assigned for the IdP Service by the Cluster.
     */
    id: number;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Request to update an Idp Configuration.
     */
    body?: UpdateIdpConfigurationRequest;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DeleteIdp
   */
  export interface DeleteIdpParams {

    /**
     * Specifies the Id assigned for the IdP Service by the Cluster.
     */
    id: number;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { IdpsService }
