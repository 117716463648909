import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

/**
 * The Argus stepper actions component.
 *
 * @example
 * <dg-stepper-actions
 *   [selectedStepIndex]="selectedStepIndex"
 *   [totalSteps]="steps.length"
 *   (cancel)="closeDialog()"
 *   (goBack)="navigate(-1)"
 *   (goNext)="navigate(+1)"
 *   (save)="closeDialog(true)">
 * </dg-stepper-actions>
 */
@Component({
  selector: 'dg-stepper-actions',
  templateUrl: './stepper-actions.component.html',
  styleUrls: ['./stepper-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepperActionsComponent {
  /**
   * The selected step index.
   */
  @Input() selectedStepIndex = 0;

  /**
   * The total number of steps.
   */
  @Input() totalSteps = 0;

  /**
   * Loader/Spinner for Button
   */
  @Input() isSubmitting = false;

  /**
   * Actions disabled
   */
  @Input() isDisabled = false;

  /**
   * Optional save label which will replace the default `save` label.
   */
  @Input() saveLabel?: string;

  /**
   * Cancel event will be trigged on cancel button click.
   */
  @Output() cancel = new EventEmitter<void>();

  /**
   * Go back event will be trigged on go back button click.
   */
  @Output() goBack = new EventEmitter<void>();

  /**
   * Go next event will be trigged on go next button click.
   */
  @Output() goNext = new EventEmitter<void>();

  /**
   * Save event will be trigged on save button click.
   */
  @Output() save = new EventEmitter<void>();
}
