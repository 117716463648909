import { Injectable } from '@angular/core';
import { ProtectionSourceNode } from '@cohesity/api/v1';
import { ProtectdObjectsActionRequest, ProtectedObject, ProtectedObjectInfo } from '@cohesity/api/v2';
import { DataTreeNode, DataTreeSelection, NavItem } from '@cohesity/helix';
import { flagEnabled, IrisContextService, isDmsScope } from '@cohesity/iris-core';
import { SourceSelection } from '@cohesity/iris-source-tree';
import { StateService } from '@uirouter/core';
import { camelCase } from 'lodash-es';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map, catchError } from 'rxjs/operators';
import { StateManagementService } from 'src/app/core/services';
import { RestoreConfigService } from 'src/app/modules/restore/restore-shared';
import { CloudJobType, envGroups, Environment, RecoveryAction } from 'src/app/shared';
import { ProtectionSourceDataNode } from 'src/app/shared/source-tree/protection-source';
import { ObjectActionCreator } from './object-action-creator';
import { ObjectActionOptions } from './object-action-options.model';
import { checkObjectsForBulkAction, DeletedProtectedObjectActions } from './object-action-utils';
import { ObjectInfoService, ObjectInfoServiceOptions } from './object-info.service';
import { ObjectMenuProvider, SimpleObjectInfo } from './object-menu-provider';
import { ObjectProtectAction } from './object-protect-action.constants';
import { ObjectProtectionGroupAction } from './object-protection-group-action.constants';


/**
 * This is a simple implementation for construction object menu actions. It has some simple
 * logic around auto protection, and can be configured to restrict items to only be protected
 * by a single job.
 */
@Injectable()
export class ObjectActionProvider implements ObjectMenuProvider {
  /**
   * The provider for this service is manually set up in object-actions-menu.service, which must provide
   * the list of providers as an array in the correct order. In order to maintain some kind of sanity,
   * the providers are listed here, they should match the order of the constructor args.
   */
  static objectActionProviderDependencies = [
    ObjectInfoService,
    RestoreConfigService,
    StateManagementService,
    StateService,
    ObjectActionCreator,
    IrisContextService,
  ];

  exclusiveProtection = true;

  supportsBulkActions = true;

  /**
   * Maximum number of objects that can be recovered at one time. If more objects are selected,
   * the recover button will be disabled.
   */
  maxBulkRecoverObjects = 5000;

  constructor(
    readonly objectStore: ObjectInfoService,
    readonly restoreConfig: RestoreConfigService,
    readonly stateManagementService: StateManagementService,
    readonly stateService: StateService,
    readonly actionCreator: ObjectActionCreator,
    readonly irisContextService: IrisContextService,
  ) {}

  /**
   * Determines whether an item can be protected, based on whether it's currently protected and
   * whether the type allows an object to be protected by multiple jobs.
   *
   * @param object The object.
   * @returns Whether it can be protected.
   */
  canProtect(object: SimpleObjectInfo): boolean {
    if (!this.irisContextService.irisContext.privs.PROTECTION_MODIFY) {
      return false;
    }

    // A deleted object cannot be protected
    if (object.isDeleted) {
      return false;
    }

    // NetApp DataProtection volumes protection is not supported in DMaaS.
    if (this.isDMSandDPVolumeSelected([object])) {
      return false;
    }

    // Assume that if we don't have objectInfo here then the object is not protected
    if (!object.isProtected) {
      return true;
    }

    // If it's already protected and we only support one protection group and
    // the object's environment is not nas, return false;
    if (this.exclusiveProtection && !envGroups.nas.includes(object.environment)) {
      return false;
    }

    if (object.isObjectProtected !== undefined) {
      return !object.isObjectProtected;
    }

    // The v1 api now includes object protection information, if this flag is true, then we can return false, since it
    // should not be possible to protect it again. The v1 object is not available in all contexts though,
    // so the below check still exists as a fallback.
    if (object.v1Object?.objectProtectionInfo?.hasActiveObjectProtectionSpec) {
      return false;
    }

    // This method should return false if an object is object protected, and true if it is group protected. Since dms
    // only supports object protection, and on prem only supports group protection, we can base this on the current
    // scope, which does not require an api call.
    // When object protection is added to on prem, we will need to look up the object's info in order to tell what kind
    // of protection is used.
    return !object.regionId;
  }

  /**
   * Return whether the environment supports a specific action type.
   *
   * @param actionType The recovery action type.
   * @param environment The environment.
   * @param protectionType The object protection type.
   * @returns True if the config is set.
   */
  isRecoveryConfigSet(actionType: RecoveryAction, environment: string, protectionType?: string) {
    const config = this.restoreConfig.recoveryFormOptions[environment];

    // This is required to handle different action type for AWS based on the
    // protection type.
    switch (environment) {
      case Environment.kAWS:
        return this.handleAwsObjectRecoveryAction(actionType, protectionType);
      // Add cases for other environment as well if required.
    }

    return Boolean(config?.[actionType]);
  }

  /**
   * Return whether the environment supports a specific action type.
   *
   * @param actionType The recovery action type.
   * @param protectionType The object protection type.
   * @returns boolean True if the protection supports action type.
   */
  handleAwsObjectRecoveryAction(actionType: RecoveryAction, protectionType?: string): boolean {
    switch (protectionType) {
      case CloudJobType.kNative:
        return actionType === RecoveryAction.RecoverVMs || actionType === RecoveryAction.RecoverFiles;
      case CloudJobType.kSnapshotManager:
        return actionType === RecoveryAction.RecoverVMs;
      case CloudJobType.kRDSSnapshotManager:
        return actionType === RecoveryAction.RecoverRDS;
      case CloudJobType.kAwsRDSPostgresBackup:
      case CloudJobType.kAwsAuroraPostgres:
      case CloudJobType.kAwsRDSPostgres:
        return actionType === RecoveryAction.RecoverRDSPostgres;
      case CloudJobType.kAwsDynamoDB:
        return actionType === RecoveryAction.RecoverAwsDynamoDB;
      case CloudJobType.kAuroraSnapshotManager:
        return actionType === RecoveryAction.RecoverAurora;
      case CloudJobType.kAwsS3:
        return actionType === RecoveryAction.RecoverS3;
      default:
        return false;
    }
  }

  /**
   * Checks a protected object and recovery action against the available restore configuration
   * to determine if the provided action is applicable.
   *
   * @param object The protected object
   * @param simpleObject The simple object
   * @param actionType A recovery action type
   * @returns True if the action can be applied to this object.
   */
  canRecover(
    actionType: RecoveryAction,
    object?: ProtectedObject,
    simpleObject?: SimpleObjectInfo
  ): boolean {
    // Ensure that the environment supports the action type
    if (!this.isRecoveryConfigSet(
      actionType,
      simpleObject?.environment || object?.environment,
      simpleObject?.protectionType || object?.protectionType)) {
      return false;
    }

    // If there is a preconfigured restore point selection, we can only recover if there is a valid snapshot id.
    // Not sure what happens here if the snapshot has expired or been deleted
    if (object && simpleObject?.restorePointSelection) {
      return !!simpleObject.restorePointSelection.restorePointId;
    }

    // If there's no preconfigured restore point, check the latest info.
    return !!object?.latestSnapshotsInfo;
  }

  /**
   * Returns true if the action exists and the user can access its state. It will also return true
   * if there is no state associated with the action.
   *
   * @param action  The action to check.
   * @returns True if the user can access the item.
   */
  filterActionByAccess(action: NavItem): boolean {
    if (!action) {
      return false;
    }
    if (!action.state) {
      // If no state present, guess the privilege needed
      const {PROTECTION_JOB_OPERATE, PROTECTION_MODIFY} = this.irisContextService.irisContext.privs;

      if (['pauseFutureRuns', 'resumeFutureRuns', 'runNow'].includes(action.displayName)) {
        // These actions call into PerformActionOnProtectObjects method,
        // which requires the PROTECTION_JOB_OPERATE privilege.
        return PROTECTION_JOB_OPERATE;
      }

      // Otherwise fallback to checking for PROTECTION_MODIFY privilege.
      // Note: this may not encompass all privileges required for all the action types.
      // However, PROTECTION_MODIFY is a more elevated privilege, so if a user has
      // PROTECTION_MODIFY, they should have privileges for other actions as well.
      return PROTECTION_MODIFY;
    }

    return this.stateManagementService.canUserAccessState(action.state, action.stateParams);
  }

  /**
   * Sort array of actions to a specific order.
   *
   * @param actions The actions to sort.
   * @return Array of sorted actions.
   */
  sortActions(actions: NavItem[]): NavItem[] {
    const actionsBuckets = [
      // Put all protect actions in this bucket.
      // They are displayed first.
      // Typically either protect or recover actions are displayed, not both.
      [],

      // Put all recover actions in this bucket.
      // They are display second.
      // Typically either protect or recover actions are displayed, not both.
      [],

      // Put secondary actions such as "Unprotect" here.
      // They are displayed after protect and recover actions.
      [],

      // Put other actions here.
      // They are displayed after secondary actions.
      [],

      // Put disabled actions here.
      // They are displayed in the end.
      []
    ];

    // Filter out null NavItems.
    actions = actions.filter(action => !!action && action.displayName);

    // Map of all lowercase actions. Convert all display names to lower
    const camelCaseNameMap = actions.reduce((result, action) =>
      (result[action.displayName] = camelCase(action.displayName), result),
      {}
    );

    for (const action of actions) {
      const actionName = camelCaseNameMap[action.displayName];

      // Put actions in their corresponding buckets.
      // TODO: Use translateService for labels.
      if (actionName.startsWith('protect')) {
        actionsBuckets[0].push(action);
      } else if (actionName.startsWith('recover') || actionName.includes('recovery')) {
        actionsBuckets[1].push(action);
      } else if (actionName === 'unprotect') {
        actionsBuckets[2].push(action);
      } else if (!action.disabled) {
        actionsBuckets[3].push(action);
      } else {
        actionsBuckets[4].push(action);
      }
    }

    // Returned a flattened list of sorted actions
    return actionsBuckets.map(actionsBucket => actionsBucket.sort((actionA, actionB) =>
      camelCaseNameMap[actionA.displayName].localeCompare(camelCaseNameMap[actionB.displayName])
    )).flat();
  }

  /**
   * Creates and returns the protect action for the specified object.
   *
   * @param object The object the protect.
   * @returns An observable, which yields the NavItem or null.
   */
  getProtectAction(object: SimpleObjectInfo): Observable<NavItem> {
    if (!this.objectStore.getObjectInfo) {
      return of(null);
    }
    const action = this.canProtect(object) ? this.actionCreator.createProtectAction([object]) : null;
    return of(action);
  }

  /**
   * Creates and returns the protect action for multiple objects.
   *
   * @param objects The objects to protect.
   * @param sourceSelection  The source selection info if any.
   * @returns An observable, which yields the NavItem or null.
   */
  getBulkProtectAction(objects: SimpleObjectInfo[], sourceSelection?: SourceSelection): Observable<NavItem> {
    if (!this.objectStore.getObjectInfo) {
      return of(null);
    }

    return this.canProtectAll(objects).pipe(
      map(canProtect => (canProtect ? this.actionCreator.createProtectAction(objects, sourceSelection) : null))
    );
  }

  /**
   * Creates and returns the edit protection action for the specified object, only if it is protected
   * as an object.
   *
   * @param object The object to edit protection settings for.
   * @returns An observable, which yields the NavItem or Null.
   */
  getEditObjectProtectionAction(object: SimpleObjectInfo): Observable<NavItem> {
    if (!this.objectStore.getObjectInfo || !object.isProtected || object.isDeleted) {
      return of(null);
    }

    // If no object's have region ids, we are dealing with a cluster rather than on prem, and the
    // protected object actions do not apply.
    if (!object.regionId && !object.isObjectProtected) {
      return of(null);
    }

    // If the v1 object is set, use it to check the object protection params rather than make a new api call.
    // This is generally set for the source tree, but not for global search.
    const { v1Object } = object;
    if (v1Object?.objectProtectionInfo) {
      if (!v1Object.objectProtectionInfo?.hasActiveObjectProtectionSpec) {
        return null;
      }

      // Whether to force edit on the child for auto protection instead of going to parent object
      const forceEditOnChildObject =
        flagEnabled(this.irisContextService.irisContext, 'vmwareDmsExcludeDiskAutoProtect') &&
        object.environment === Environment.kVMware;

      const autoProtectId = v1Object.objectProtectionInfo.autoProtectParentId;
      const id = autoProtectId || object.id;
      return of(this.actionCreator.createEditObjectProtectionAction(
        object.environment as any,
        forceEditOnChildObject ? object.id : id,
        { accessClusterId: object.accessClusterId, regionId: object.regionId },
        !!autoProtectId,
        forceEditOnChildObject
      ));

    }

    return this.objectStore.getObjectInfo(
      object.id,
      this.getObjectInfoOptions(object)
    ).pipe(
      filter(entry => !entry.loading),
      map(entry => entry.item),
      map(item => {
        if (!item?.objectBackupConfiguration) {
          return null;
        }

        // Whether to force edit on the child for auto protection instead of going to parent object
        const forceEditOnChildObject =
          flagEnabled(this.irisContextService.irisContext, 'vmwareDmsExcludeDiskAutoProtect') &&
          object.environment === Environment.kVMware;

        const isAutoProtect = item.objectBackupConfiguration?.isAutoProtectConfig;
        const id = isAutoProtect ? item.objectBackupConfiguration.autoProtectParentId : item.id;
        return this.actionCreator.createEditObjectProtectionAction(
          item.objectBackupConfiguration.environment as any,
          forceEditOnChildObject ? item.id : id,
          { accessClusterId: object.accessClusterId, regionId: object.regionId },
          isAutoProtect,
          forceEditOnChildObject
        );
      })
    );
  }

  /**
   * Creates and returns the recovery action for the specified object.
   *
   * @param object The protected object.
   * @param type   The type of recovery action to check for.
   * @returns An observable, which yields the NavItem or null.
   */
  getRecoveryAction(object: SimpleObjectInfo, type: RecoveryAction): Observable<NavItem> {
    if (!this.objectStore.getProtectedObject) {
      return of(null);
    }

    if (object.useRestorePointSelection) {
      // No need to fetch protected object if the object only wants
      // recovery actions created using restore point selection.
      if (!this.isRecoveryConfigSet(type, object.environment, object.protectionType)) {
        return of(null);
      }

      // Only allow download files to be shown if recover files is not included already
      // These resolve to the same actions, and we don't want to show both actions in the
      // menu. This is a slightly different check from the one below, since we already know
      // that we have a restore point selection.
      if (type === RecoveryAction.DownloadFilesAndFolders &&
        this.isRecoveryConfigSet(RecoveryAction.RecoverFiles, object.environment, object.protectionType)) {
        return of(null);
      }

      return of(this.actionCreator.createRecoverAction(
        type,
        object,
        null,
        object.restorePointSelection,
        { accessClusterId: object.accessClusterId, regionId: object.regionId }
      ));
    }

    return this.objectStore.getProtectedObject(
      object.id,
      this.getObjectInfoOptions(object)
    ).pipe(
      map(storeEntry => storeEntry.item),
      map(protectedObject => {
        if (!this.canRecover(type, protectedObject, object)) {
          return null;
        }

        // Only allow download files to be shown if recover files is not included already
        // These resolve to the same actions, and we don't want to show both actions in the
        // menu.
        if (type === RecoveryAction.DownloadFilesAndFolders &&
          this.canRecover(RecoveryAction.RecoverFiles, protectedObject, object)) {
          return;
        }

        return this.actionCreator.createRecoverAction(
          type,
          object,
          protectedObject,
          object.restorePointSelection,
          { accessClusterId: object.accessClusterId, regionId: object.regionId }
        );
      })
    );
  }

  /**
   * Creates and returns the clone recovery for the specified object.
   *
   * @param object The protected object.
   * @returns An observable, which yields the NavItem or null.
   */
  getRecoveryCloneAction(object: SimpleObjectInfo): Observable<NavItem> {
    if (!this.objectStore.getProtectedObject) {
      return of(null);
    }
    return this.getProtectedObject(object).pipe(
      map(protectedObject => {
        if (!protectedObject) {
          return this.actionCreator.createCloneAction(object, {
            accessClusterId: object.accessClusterId,
            regionId: object.regionId
          });
        }

        // Call createCloneActionFunction with the appropriate arguments.
        return this.actionCreator.createCloneAction(object, {
          accessClusterId: object.accessClusterId,
          regionId: object.regionId
        }, [protectedObject]);
      }),
      catchError(() => of(null))
    );
  }

  /**
   * Refactored function to fetch and process the protected object
   */
  getProtectedObject(object: SimpleObjectInfo): Observable<any> {
    return this.objectStore.getProtectedObject(object.id, this.getObjectInfoOptions(object)).pipe(
      filter(response => !response.loading),
      map(storeEntry => storeEntry.item)
    );
  }

  /**
   * Creates and returns the recovery action for the specified objects.
   *
   * @param objects The protected objects array.
   * @param type   The type of recovery action to check for.
   * @returns An observable, which yields the NavItem or null.
   */
  getBulkRecoveryAction(objects: SimpleObjectInfo[], type: RecoveryAction): Observable<NavItem> {
    if (!checkObjectsForBulkAction(objects, type)) {
      return of(null);
    }

    if (!this.objectStore.getProtectedObject) {
      return of(null);
    }

    if (objects.length > this.maxBulkRecoverObjects) {
      return of(null);
    }

    return this.getProtectedObjectSearch(objects).pipe(
      map(infos => {
        if (!infos.length || !infos.every(info => info && this.canRecover(type, info, null))) {
          // Don't return the recovery action if not every object can perform it.
          return null;
        }

        return this.actionCreator.createBulkRecoverAction(
          type,
          infos,
          { accessClusterId: objects[0].accessClusterId, regionId: objects[0].regionId }
        );
      }),
    );
  }

  /**
   * Creates and returns the protected object action for the specified object.
   *
   * @param objects The protected object.
   * @param type    The type of protection to create
   * @returns An observable, which yields the NavItem or null.
   */
  getProtectedObjectAction(objects: SimpleObjectInfo[], type: ObjectProtectAction): Observable<NavItem> {
    if (!checkObjectsForBulkAction(objects, type)) {
      return of(null);
    }

    if (!this.objectStore.getObjectInfo) {
      return of(null);
    }

    // If no objects have region ids, we are dealing with a cluster rather than on prem, and the
    // protected object actions do not apply.
    // TODO: Support object protection for OnPrem
    if (objects.every(object => !object.regionId && !object.isObjectProtected)) {
      return of(null);
    }

    // Given a single or multiple objects, it is a fair assumption that actions
    // taken will have a common intent for all and a common backup
    // type(objectActionKey). Hence, it is safe to read just the 1st object.
    const objectActionKey: ProtectdObjectsActionRequest['objectActionKey'] = objects[0]?.objectActionKey;
    const objectOptions = this.getObjectInfoOptions(objects[0]);

    if (objects.every(object => object.isDeleted)) {
      // If the objects are deleted, do not rely on V2 Protected Objects to
      // return that object.
      if (DeletedProtectedObjectActions.includes(type)
        && objects.every(object => object.isObjectProtected)) {
        return of(
          this.actionCreator.createDeletedProtectedObjectAction(objects, type, objectOptions, objectActionKey)
        );
      }

      return of(null);
    }

    // TODO: In MCM, we can potentially use the v1 api to get the list of object protected objects.
    // Then, use MCM apis to get the last/current run status

    return this.getProtectedObjectInfo(objects).pipe(
      map(infos => {
        // This filters out any objects which are unprotected, or protected by protection
        // groups only. Depending on UX, we may eventually, need to notify the user that the
        // actions will not be applied to these objects.
        const protectedObjects = infos.filter(info => !!info && info.objectBackupConfiguration);
        return this.actionCreator.createProtectedObjectAction(protectedObjects, type, () => {
          // This callback executes after the action is completed and refreshes the cached object
          // info. This will cause a refresh of the available object actions.
          setTimeout(() => {
            protectedObjects.forEach(object => this.objectStore.refreshObjectInfo(
              object.id,
              this.getObjectInfoOptions(objects[0])
            ));
          }, 2000);
        }, objectOptions, objectActionKey);
      })
    );
  }

  getObjectActions(object: SimpleObjectInfo): Observable<NavItem[]> {
    return combineLatest([
      this.getProtectAction(object),
      this.getRecoveryAction(object, RecoveryAction.RecoverVMs),
      this.getRecoveryAction(object, RecoveryAction.RecoverAzureSQL),
      this.getRecoveryAction(object, RecoveryAction.RecoverAzureEntraID),
      this.getRecoveryAction(object, RecoveryAction.RecoverNasVolume),
      this.getRecoveryAction(object, RecoveryAction.RecoverSanGroup),
      this.getRecoveryAction(object, RecoveryAction.RecoverSanVolumes),
      this.getRecoveryAction(object, RecoveryAction.RecoverFiles),
      this.getRecoveryAction(object, RecoveryAction.DownloadFilesAndFolders),
      this.getRecoveryAction(object, RecoveryAction.RecoverApps),
      this.getRecoveryAction(object, RecoveryAction.InstantVolumeMount),
      this.getRecoveryAction(object, RecoveryAction.RecoverObjects),
      this.getRecoveryAction(object, RecoveryAction.RecoverMongodbClusters),
      this.getRecoveryCloneAction(object),
      this.getEditObjectProtectionAction(object),
      this.getProtectedObjectAction([object], ObjectProtectAction.ProtectNow),
      this.getProtectedObjectAction([object], ObjectProtectAction.UnProtect),
      this.getProtectedObjectAction([object], ObjectProtectAction.Pause),
      this.getProtectedObjectAction([object], ObjectProtectAction.Resume),
      this.getProtectedObjectAction([object], ObjectProtectAction.CancelRun),
      this.getProtectionGroupObjectAction(object, ObjectProtectionGroupAction.Run),
      this.getProtectionGroupObjectAction(object, ObjectProtectionGroupAction.Edit),
    ]).pipe(
      map(actions => actions.filter(action => this.filterActionByAccess(action))),
      map(actions => this.sortActions(actions))
    );
  }

  /**
   * Function to return protection group actions for an object.
   *
   * @param object The protected object.
   * @param type   The type of protection group action
   * @returns An observable, which yields the NavItem or null.
   */
  getProtectionGroupObjectAction(
    object: SimpleObjectInfo,
    action: ObjectProtectionGroupAction,
  ): Observable<NavItem> {
    if (!this.objectStore.getObjectInfo) {
      return of(null);
    }

    return this.getProtectedObjectInfo([object]).pipe(
      map(infos =>
        this.actionCreator.createProtectionGroupObjectAction(infos?.[0], object, action)
      )
    );
  }

  getBulkObjectActions(objects: SimpleObjectInfo[]): Observable<NavItem[]> {
    return combineLatest([
      this.getBulkProtectAction(objects),
      this.getBulkRecoveryAction(objects, RecoveryAction.RecoverVMs),
      this.getBulkRecoveryAction(objects, RecoveryAction.RecoverAzureSQL),
      this.getBulkRecoveryAction(objects, RecoveryAction.RecoverAzureEntraID),
      this.getBulkRecoveryAction(objects, RecoveryAction.RecoverApps),
      this.getBulkRecoveryAction(objects, RecoveryAction.RecoverObjects),
      this.getProtectedObjectAction(objects, ObjectProtectAction.ProtectNow),
      this.getProtectedObjectAction(objects, ObjectProtectAction.UnProtect),
      this.getProtectedObjectAction(objects, ObjectProtectAction.Pause),
      this.getProtectedObjectAction(objects, ObjectProtectAction.Resume),
    ]).pipe(
      map(actions => actions.filter(action => this.filterActionByAccess(action))),
      map(actions => this.sortActions(actions))
    );
  }

  getBulkTreeObjectActions(
    selection: DataTreeSelection<DataTreeNode<ProtectionSourceNode>>,
    sourceSelection: SourceSelection,
    objectOptions: ObjectActionOptions = {}
  ): Observable<NavItem[]> {
    if (!selection || (!selection.selected.length && !selection.autoSelected.length)) {
      return of([]);
    }

    const toObjectInfo = (object: ProtectionSourceDataNode) => ({
      id: object.protectionSource.id,
      environment: object.environment,

      // If a root node is selected, it will not habve a parent source id, so we can just use its own id instead.
      sourceId: object.protectionSource.parentId || object.protectionSource.id,
      isProtected: object.protected,
      objectType: object.type,
      accessClusterId: objectOptions.accessClusterId,
      regionId: objectOptions.regionId,
    });

    // For protection and recovery, we should remove the auto selected nodes from our list and include the specfic
    // auto protecte nodes instead. Otherwise, a selected non-leaf node will end up beingg auto protected in the tree
    // even though that is not the user's intent.
    // However, for protected object actions, we should include the selected nodes and _not_ the auto selected nodes,
    // since the user can perform actions directly on these nodes.
    const selectedLeafNodes = selection.selected.filter(node => !node.canAutoSelect(selection));
    const objects = [...selectedLeafNodes, ...selection.autoSelected];
    const objectInfos: SimpleObjectInfo[] = objects.map(toObjectInfo);
    const allInfos = selection.selected.map(toObjectInfo);

    return combineLatest([
      this.getBulkProtectAction(objectInfos, sourceSelection),
      this.getBulkRecoveryAction(objectInfos, RecoveryAction.RecoverVMs),
      this.getBulkRecoveryAction(objectInfos, RecoveryAction.RecoverApps),
      this.getBulkRecoveryAction(objectInfos, RecoveryAction.RecoverNasVolume),
      this.getBulkRecoveryAction(objectInfos, RecoveryAction.RecoverObjects),
      this.getProtectedObjectAction(allInfos, ObjectProtectAction.ProtectNow),
      this.getProtectedObjectAction(allInfos, ObjectProtectAction.UnProtect),
      this.getProtectedObjectAction(allInfos, ObjectProtectAction.Pause),
      this.getProtectedObjectAction(allInfos, ObjectProtectAction.Resume),
    ]).pipe(
      map(actions => actions.filter(action => this.filterActionByAccess(action))),
      map(actions => this.sortActions(actions))
    );
  }

  /**
   * Fetch v2 protected object info for a set of objects.
   *
   * @param objects The objects to look up.
   * @param objectActionKey Specifies the backup up type for the objects
   * @returns An observable of array of items. If an item in the list is null, it is not protected.
   *          the return value will include both object and group protected objects.
   */
  getProtectedObjectSearch(objects: SimpleObjectInfo[]): Observable<ProtectedObject[]> {
    const objectInfos = objects.filter(object => object.isProtected).map(object => ({
      id: object.id,
      options: this.getObjectInfoOptions(object)
    }));

    return this.objectStore.getProtectedObjects(objectInfos).pipe(
      filter(entries => entries.every(entry => !entry.loading)),
      map(entries => entries.map(entry => entry.item)),
    );
  }

  /**
   * Determine if a set of objects can all be protected or not.
   * TODO: This will likely change as the protected object api is updated, and we will want
   * to enable the protect button even for already-protected objects.
   *
   * @param objects the objects to look up
   * @returns An observables of the object info - unprotected items will show as null in resulting array.
   */
  canProtectAll(objects: SimpleObjectInfo[]): Observable<boolean> {
    if (!checkObjectsForBulkAction(objects, 'Protect') &&
      !this.isDMSandDPVolumeSelected(objects)) {
      return of(false);
    }
    return of(objects.every(object => this.canProtect(object)));
  }

  /**
   * Extract the additional lookup options for an object from the simple object
   *
   * @param object The current object
   * @returns An options object with the accessClusster or regionId set and the action key if any.
   */
  protected getObjectInfoOptions(object: SimpleObjectInfo): ObjectInfoServiceOptions {
    return {
      accessClusterId: object.accessClusterId,
      regionId: object.regionId,
      actionKey: object.objectActionKey
    };
  }

  /**
   * Fetch v2 protected object info for a set of objects.
   *
   * @param objects The objects to look up.
   * @returns An observable of array of items. If an item in the list is null, it is not protected.
   *          the return value will include both object and group protected objects.
   */
  private getProtectedObjectInfo(objects: SimpleObjectInfo[]): Observable<ProtectedObjectInfo[]> {
    const objectInfos = objects.filter(object => object.isProtected).map(object => ({
      id: object.id,
      options: this.getObjectInfoOptions(object)
    }));

    return this.objectStore.getObjectInfos(objectInfos).pipe(
      filter(entries => entries.every(entry => !entry.loading)),
      map(entries => entries.map(entry => entry.item)),
    );
  }

  /**
   * Check if env is DMaaS and if any of the volume selected is of type
   * kDataProtection
   *
   * @param objects The objects to look up.
   * @return boolean
   */
  private isDMSandDPVolumeSelected(objects: SimpleObjectInfo[]) {
    if (isDmsScope(this.irisContextService.irisContext) &&
      objects.some((object: SimpleObjectInfo) =>
        object.environment === Environment.kNetapp &&
        object.volumeType === 'kDataProtection'
      )) {
      return true;
    }
    return false;
  }
}
