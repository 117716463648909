import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ApplyPoliciesRequest, DataPoolId, DlpApiService, Policy, PolicyList } from '@cohesity/api/argus';
import { DataPool } from '@cohesity/api/inventory-mgr';
import { CanSelectRowFn, NavItem, SnackBarService } from '@cohesity/helix';
import { AjaxHandlerService, AutoDestroyable } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import { flatten } from 'lodash-es';
import { finalize } from 'rxjs/operators';
import { InitSelection } from '../../data-pools/data-pool-list/data-pool-list.component';

/**
 * Argus active policy edit dialog components data inputs.
 */
export interface PolicyApplyDialogInput {
  policy?: Policy;
}

/**
 * Data classification apply policy dialog component.
 */
@Component({
  selector: 'dg-dc-policy-apply-dialog',
  templateUrl: './policy-apply-dialog.component.html',
  styleUrls: ['./policy-apply-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PolicyApplyDialogComponent extends AutoDestroyable implements OnInit {
  /**
   * Indicates whether we are loading data.
   */
  isLoading = false;

  /**
   * Indicates whether we are submitting the data or not.
   */
  isSubmitting = false;

  get isDisabled(): boolean {
    switch (this.selectedStep.displayName) {
      case 'policies':
        return this.selectedPolicies.isEmpty();
      case 'dataPools':
        return this.selectedDataPools.isEmpty();
    }
  }

  /**
   * The list of policies
   */
  policyList: PolicyList = null;

  /**
   * The selected data pools.
   */
  selectedDataPools = new SelectionModel<DataPool>(true, []);

  /**
   * The selected policies.
   */
  selectedPolicies = new SelectionModel<Policy>(true, []);

  /**
   * The currently selected step.
   */
  get selectedStep(): NavItem {
    return this.steps[this.selectedStepIndex];
  }

  /**
   * The currently selected step index.
   */
  selectedStepIndex = 0;

  /**
   * apply policy steps.
   */
  steps: NavItem[] = [
    {
      displayName: 'dataPools',
    },
    {
      displayName: 'policies',
    },
  ];

  /**
   * The provided policy.
   * This is being used specifically for edit policy
   */
  get policy(): Policy {
    return this.data.policy;
  }
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: PolicyApplyDialogInput,
    private ajaxHandler: AjaxHandlerService,
    private cdr: ChangeDetectorRef,
    private dialogRef: MatDialogRef<PolicyApplyDialogComponent>,
    private dlpApiService: DlpApiService,
    private snackBarService: SnackBarService,
    private translate: TranslateService,
  ) {
    super();
  }

  /**
   * Initialize the component
   */
  ngOnInit() {
    // if policy is provided set selected policies
    if (this.policy) {
      this.selectedPolicies.clear();
      this.selectedPolicies.select(this.policy);
    }
    this.navigate(0);
  }

  /**
   * Used to navigate b/w steps.
   *
   * @param magnitude The magnitude by which selected step index should be incremented or decremented.
   */
  navigate(magnitude: number) {
    this.selectedStepIndex = this.selectedStepIndex + magnitude;

    switch (this.selectedStep.displayName) {
      case 'policies': {
        this.fetchPolicyList();
        break;
      }
    }
  }

  /**
   * Indicates whether data pool can be selected or not.
   *
   * @param dataPool The data pool.
   * @returns Return true if data can be selected else it will return false.
   */
  canSelectDataPool: CanSelectRowFn<DataPool> = dataPool => dataPool.enabled;

  /**
   * To clear and initialize the data pools of the running policies
   *
   * @param event The init selection event from data-pool-list component.
   */
  initDataPoolSelection(event: InitSelection) {
    // data pool initialization is needed only in case of edit policy thus only check for preselected data pools
    // in case of edit we do not need to clear and add data pools according to policies in case of apply policy
    if (this.policy) {
      this.selectedDataPools.clear();
      const dataPoolIdSet = new Set(flatten(this.selectedPolicies.selected.map(policy => policy.dataPoolIds)));
      const dataPools = (event.dataPools || []).filter(({ id }) => dataPoolIdSet.has(id));
      this.selectedDataPools.select(...dataPools);
    }
  }

  /**
   * Fetch the list of policies
   */
  fetchPolicyList() {
    // don't fetch policyList again if already fetched.
    if (this.policyList === null) {
      this.isLoading = true;
      this.cdr.detectChanges();

      this.dlpApiService.getPolicies().pipe(
        this.untilDestroy(),
        finalize(() => {
          this.isLoading = false;
          this.cdr.detectChanges();
        })
      )
        .subscribe(({ policies}) => {
          this.policyList = policies;
        },
        this.ajaxHandler.handler
        );
    }
  }

  /**
   * Apply Policy
   */
  applyPolicy() {
    const selectedPolicies = this.selectedPolicies.selected;
    const dataPoolIds = this.selectedDataPools.selected.map(({ id }) => id);
    const body: ApplyPoliciesRequest = {
      policies: selectedPolicies.map(policy => ({
        id: policy.id,
        dataPoolIds: this.policy ?
          dataPoolIds :
          [...new Set<DataPoolId>([...(policy.dataPoolIds ?? []), ...dataPoolIds])]
      }))
    };

    this.isSubmitting = true;
    this.cdr.detectChanges();
    this.dlpApiService.applyPolicies({ body })
      .pipe(
        finalize(() => {
          this.isSubmitting = false;
          this.cdr.detectChanges();
        }),
      )
      .subscribe({
        complete: () => {
          this.snackBarService.open(this.translate.instant('policyAppliedSuccessfully'), 'success');
          this.closeDialog(true);
        },
        error: this.ajaxHandler.handler,
      });
  }

  /**
   * Close the dialog.
   *
   * @param result The result.
   */
  closeDialog = (result?: boolean) => {
    this.dialogRef.close(result);
  };
}
